export const SECURITIES = [
    {
        "symbol": "SPY",
        "label": "SPDR S&P 500",
        "price": 331.95
    }, {
        "symbol": "CMCSA",
        "label": "Comcast Corporation Class A Common Stock",
        "price": 47.5
    }, {
        "symbol": "KMI",
        "label": "Kinder Morgan Inc.",
        "price": 21.36
    }, {
        "symbol": "INTC",
        "label": "Intel Corporation",
        "price": 59.6
    }, {
        "symbol": "MU",
        "label": "Micron Technology Inc.",
        "price": 57.66
    }, {
        "symbol": "GDX",
        "label": "VanEck Vectors Gold Miners",
        "price": 23.71
    }, {
        "symbol": "GE",
        "label": "General Electric Company",
        "price": 11.81
    }, {
        "symbol": "BAC",
        "label": "Bank of America Corporation",
        "price": 34.71
    }, {
        "symbol": "EEM",
        "label": "iShares MSCI Emerging Index Fund",
        "price": 41.94
    }, {
        "symbol": "XLF",
        "label": "SPDR Select Sector Fund - Financial",
        "price": 27.34
    }, {
        "symbol": "AAPL",
        "label": "Apple Inc.",
        "price": 318.73
    }, {
        "symbol": "MSFT",
        "label": "Microsoft Corporation",
        "price": 167.1
    }, {
        "symbol": "SIRI",
        "label": "Sirius XM Holdings Inc.",
        "price": 7.17
    }, {
        "symbol": "HPQ",
        "label": "HP Inc.",
        "price": 21.97
    }, {
        "symbol": "CX",
        "label": "Cemex S.A.B. de C.V. Sponsored ADR",
        "price": 4.21
    }, {
        "symbol": "EFA",
        "label": "iShares MSCI EAFE",
        "price": 64.65
    }, {
        "symbol": "CZR",
        "label": "Caesars Entertainment Corporation",
        "price": 13.8
    }, {
        "symbol": "QQQ",
        "label": "PowerShares QQQ Trust Series 1",
        "price": 223.38
    }, {
        "symbol": "F",
        "label": "Ford Motor Company",
        "price": 9.16
    }, {
        "symbol": "AMD",
        "label": "Advanced Micro Devices Inc.",
        "price": 50.93
    }, {
        "symbol": "SNAP",
        "label": "Snap Inc. Class A",
        "price": 19.11
    }, {
        "symbol": "FB",
        "label": "Facebook Inc.",
        "price": 222.14
    }, {
        "symbol": "WFC",
        "label": "Wells Fargo & Company",
        "price": 49.18
    }, {
        "symbol": "AIG",
        "label": "American International Group Inc.",
        "price": 52.9
    }, {
        "symbol": "T",
        "label": "AT&T Inc.",
        "price": 38.38
    }, {
        "symbol": "C",
        "label": "Citigroup Inc.",
        "price": 81.12
    }, {
        "symbol": "VALE",
        "label": "VALE S.A. American Depositary Shares Each Representing one",
        "price": 13.63
    }, {
        "symbol": "MS",
        "label": "Morgan Stanley",
        "price": 57.51
    }, {
        "symbol": "AKS",
        "label": "AK Steel Holding Corporation",
        "price": 3.12
    }, {
        "symbol": "JPM",
        "label": "JP Morgan Chase & Co.",
        "price": 138.2
    }, {
        "symbol": "ORCL",
        "label": "Oracle Corporation",
        "price": 55.13
    }, {
        "symbol": "NKE",
        "label": "Nike Inc.",
        "price": 104.53
    }, {
        "symbol": "VWO",
        "label": "Vanguard FTSE Emerging Markets",
        "price": 41.59
    }, {
        "symbol": "PG",
        "label": "Procter & Gamble Company (The)",
        "price": 126.41
    }, {
        "symbol": "FXI",
        "label": "iShares China Large-Cap",
        "price": 41.6
    }, {
        "symbol": "IWM",
        "label": "iShares Russell 2000",
        "price": 153.96
    }, {
        "symbol": "GSM",
        "label": "Ferroglobe PLC",
        "price": 1.03
    }, {
        "symbol": "HK",
        "label": "Halcon Resources Corporation",
        "price": 0.19
    }, {
        "symbol": "BBD",
        "label": "Banco Bradesco Sa American Depositary Shares",
        "price": 8.44
    }, {
        "symbol": "PFE",
        "label": "Pfizer Inc.",
        "price": 40.51
    }, {
        "symbol": "JD",
        "label": "JD.com Inc.",
        "price": 40.35
    }, {
        "symbol": "NOK",
        "label": "Nokia Corporation Sponsored American Depositary Shares",
        "price": 4.14
    }, {
        "symbol": "TWTR",
        "label": "Twitter Inc.",
        "price": 34.22
    }, {
        "symbol": "AMLP",
        "label": "Alerian MLP",
        "price": 9.71
    }, {
        "symbol": "AVGO",
        "label": "Broadcom Limited",
        "price": 308.79
    }, {
        "symbol": "AMAT",
        "label": "Applied Materials Inc.",
        "price": 62.85
    }, {
        "symbol": "WFT",
        "label": "Weatherford International plc (Ireland)",
        "price": 0.36
    }, {
        "symbol": "UVXY",
        "label": "ProShares Trust Ultra VIX Short Term Futures",
        "price": 33.18
    }, {
        "symbol": "DWDP",
        "label": "DowDuPont Inc.",
        "price": 30.51
    }, {
        "symbol": "VXX",
        "label": "iPath S&P 500 VIX Short Term Futures TM ETN",
        "price": 26.85
    }, {
        "symbol": "VEA",
        "label": "Vanguard FTSE Developed Markets",
        "price": 44.66
    }, {
        "symbol": "ZNGA",
        "label": "Zynga Inc.",
        "price": 6.67
    }, {
        "symbol": "XOM",
        "label": "Exxon Mobil Corporation",
        "price": 68.56
    }, {
        "symbol": "QCOM",
        "label": "QUALCOMM Incorporated",
        "price": 95.91
    }, {
        "symbol": "TVIX",
        "label": "VelocityShares Daily 2x VIX Short Term ETN",
        "price": 39.36
    }, {
        "symbol": "VIPS",
        "label": "Vipshop Holdings Limited American Depositary Shares each representing two",
        "price": 14.41
    }, {
        "symbol": "GLD",
        "label": "SPDR Gold Trust",
        "price": 127.07
    }, {
        "symbol": "CSCO",
        "label": "Cisco Systems Inc.",
        "price": 49.02
    }, {
        "symbol": "AXP",
        "label": "American Express Company",
        "price": 131.52
    }, {
        "symbol": "BMY",
        "label": "Bristol-Myers Squibb Company",
        "price": 66.72
    }, {
        "symbol": "V",
        "label": "Visa Inc.",
        "price": 204.7
    }, {
        "symbol": "USO",
        "label": "United States Oil Fund",
        "price": 11.27
    }, {
        "symbol": "GRPN",
        "label": "Groupon Inc.",
        "price": 3.01
    }, {
        "symbol": "WP",
        "label": "Worldpay Inc. Class A",
        "price": 124.37
    }, {
        "symbol": "OIH",
        "label": "VanEck Vectors Oil Services",
        "price": 13.79
    }, {
        "symbol": "GERN",
        "label": "Geron Corporation",
        "price": 1.41
    }, {
        "symbol": "KEY",
        "label": "KeyCorp",
        "price": 19.59
    }, {
        "symbol": "RF",
        "label": "Regions Financial Corporation",
        "price": 16.39
    }, {
        "symbol": "KR",
        "label": "Kroger Company (The)",
        "price": 28.15
    }, {
        "symbol": "HAL",
        "label": "Halliburton Company",
        "price": 23.96
    }, {
        "symbol": "BABA",
        "label": "Alibaba Group Holding Limited",
        "price": 227.43
    }, {
        "symbol": "MRO",
        "label": "Marathon Oil Corporation",
        "price": 13.21
    }, {
        "symbol": "CLNS",
        "label": "Colony NorthStar Inc.",
        "price": 6.41
    }, {
        "symbol": "GILD",
        "label": "Gilead Sciences Inc.",
        "price": 62.98
    }, {
        "symbol": "IEMG",
        "label": "iShares Core MSCI Emerging Markets",
        "price": 50.39
    }, {
        "symbol": "GM",
        "label": "General Motors Company",
        "price": 35.6
    }, {
        "symbol": "FCX",
        "label": "Freeport-McMoRan Inc.",
        "price": 12.87
    }, {
        "symbol": "CRM",
        "label": "Salesforce.com Inc",
        "price": 182.23
    }, {
        "symbol": "ATVI",
        "label": "Activision Blizzard Inc",
        "price": 61.24
    }, {
        "symbol": "SQ",
        "label": "Square Inc. Class A",
        "price": 69.38
    }, {
        "symbol": "P",
        "label": "Pandora Media Inc.",
        "price": 8.4
    }, {
        "symbol": "XLK",
        "label": "SPDR Select Sector Fund - Technology",
        "price": 77.51
    }, {
        "symbol": "TWX",
        "label": "Time Warner Inc.",
        "price": 98.91
    }, {
        "symbol": "NUE",
        "label": "Nucor Corporation",
        "price": 53.81
    }, {
        "symbol": "XOP",
        "label": "SPDR S&P Oil & Gas Explor & Product",
        "price": 26.08
    }, {
        "symbol": "SWN",
        "label": "Southwestern Energy Company",
        "price": 1.86
    }, {
        "symbol": "LOW",
        "label": "Lowe's Companies Inc.",
        "price": 122.36
    }, {
        "symbol": "RAD",
        "label": "Rite Aid Corporation",
        "price": 12.6
    }, {
        "symbol": "VEON",
        "label": "VEON Ltd.",
        "price": 2.67
    }, {
        "symbol": "HYG",
        "label": "iShares iBoxx $ High Yield Corporate Bond",
        "price": 86.76
    }, {
        "symbol": "APC",
        "label": "Anadarko Petroleum Corporation",
        "price": 69.92
    }, {
        "symbol": "JNK",
        "label": "SPDR Bloomberg Barclays High Yield Bond",
        "price": 108.33
    }, {
        "symbol": "EWJ",
        "label": "iShares MSCI Japan Index Fund",
        "price": 54.02
    }, {
        "symbol": "XLU",
        "label": "SPDR Select Sector Fund - Utilities",
        "price": 66.76
    }, {
        "symbol": "ESV",
        "label": "Ensco plc Class A",
        "price": 7.38
    }, {
        "symbol": "SLB",
        "label": "Schlumberger N.V.",
        "price": 38.37
    }, {
        "symbol": "FLEX",
        "label": "Flex Ltd.",
        "price": 13.55
    }, {
        "symbol": "FOXA",
        "label": "Twenty-First Century Fox Inc.",
        "price": 39.34
    }, {
        "symbol": "ABBV",
        "label": "AbbVie Inc.",
        "price": 88.0
    }, {
        "symbol": "GIS",
        "label": "General Mills Inc.",
        "price": 53.85
    }, {
        "symbol": "VZ",
        "label": "Verizon Communications Inc.",
        "price": 60.13
    }, {
        "symbol": "XRX",
        "label": "Xerox Corporation",
        "price": 37.33
    }, {
        "symbol": "CVLT",
        "label": "Commvault Systems Inc.",
        "price": 45.56
    }, {
        "symbol": "IEFA",
        "label": "iShares Core MSCI EAFE",
        "price": 66.12
    }, {
        "symbol": "X",
        "label": "United States Steel Corporation",
        "price": 10.5
    }, {
        "symbol": "MAT",
        "label": "Mattel Inc.",
        "price": 14.28
    }, {
        "symbol": "CTL",
        "label": "CenturyLink Inc.",
        "price": 14.06
    }, {
        "symbol": "MIK",
        "label": "The Michaels Companies Inc.",
        "price": 6.4
    }, {
        "symbol": "DVN",
        "label": "Devon Energy Corporation",
        "price": 25.85
    }, {
        "symbol": "BKLN",
        "label": "PowerShares Exchange-Traded Fund Trust",
        "price": 22.73
    }, {
        "symbol": "IBN",
        "label": "ICICI Bank Limited",
        "price": 14.86
    }, {
        "symbol": "MPC",
        "label": "Marathon Petroleum Corporation",
        "price": 56.89
    }, {
        "symbol": "EZU",
        "label": "iShares MSCI Eurozone",
        "price": 38.82
    }, {
        "symbol": "PM",
        "label": "Philip Morris International Inc",
        "price": 88.69
    }, {
        "symbol": "MSCI",
        "label": "MSCI Inc",
        "price": 279.07
    }, {
        "symbol": "RDN",
        "label": "Radian Group Inc.",
        "price": 25.1
    }, {
        "symbol": "KO",
        "label": "Coca-Cola Company (The)",
        "price": 56.94
    }, {
        "symbol": "AABA",
        "label": "Altaba Inc.",
        "price": 19.63
    }, {
        "symbol": "SRC",
        "label": "Spirit Realty Capital Inc.",
        "price": 51.42
    }, {
        "symbol": "NFX",
        "label": "Newfield Exploration Company",
        "price": 17.03
    }, {
        "symbol": "COP",
        "label": "ConocoPhillips",
        "price": 65.07
    }, {
        "symbol": "CHK",
        "label": "Chesapeake Energy Corporation",
        "price": 0.6613
    }, {
        "symbol": "DIS",
        "label": "The Walt Disney Company",
        "price": 144.33
    }, {
        "symbol": "LEN",
        "label": "Lennar Corporation Class A",
        "price": 63.09
    }, {
        "symbol": "CVX",
        "label": "Chevron Corporation",
        "price": 115.58
    }, {
        "symbol": "PYPL",
        "label": "PayPal Holdings Inc.",
        "price": 116.04
    }, {
        "symbol": "GDXJ",
        "label": "VanEck Vectors Junior Gold Miners",
        "price": 31.99
    }, {
        "symbol": "CTRP",
        "label": "Ctrip.com International Ltd.",
        "price": 35.97
    }, {
        "symbol": "AUY",
        "label": "Yamana Gold Inc. (Canada)",
        "price": 3.68
    }, {
        "symbol": "DBEF",
        "label": "Xtrackers MSCI EAFE Hedged Equity",
        "price": 34.63
    }, {
        "symbol": "WMT",
        "label": "Walmart Inc.",
        "price": 114.96
    }, {
        "symbol": "CLF",
        "label": "Cleveland-Cliffs Inc.",
        "price": 7.89
    }, {
        "symbol": "MRK",
        "label": "Merck & Company Inc. (new)",
        "price": 90.97
    }, {
        "symbol": "MDLZ",
        "label": "Mondelez International Inc.",
        "price": 55.16
    }, {
        "symbol": "RSPP",
        "label": "RSP Permian Inc.",
        "price": 47.93
    }, {
        "symbol": "GG",
        "label": "Goldcorp Inc.",
        "price": 11.22
    }, {
        "symbol": "MGM",
        "label": "MGM Resorts International",
        "price": 34.54
    }, {
        "symbol": "GFI",
        "label": "Gold Fields Limited American Depositary Shares",
        "price": 6.13
    }, {
        "symbol": "KGC",
        "label": "Kinross Gold Corporation",
        "price": 4.5
    }, {
        "symbol": "SCHW",
        "label": "Charles Schwab Corporation (The)",
        "price": 48.34
    }, {
        "symbol": "NBR",
        "label": "Nabors Industries Ltd.",
        "price": 2.91
    }, {
        "symbol": "SRCI",
        "label": "SRC Energy Inc.",
        "price": 4.0
    }, {
        "symbol": "JAG",
        "label": "Jagged Peak Energy Inc.",
        "price": 8.23
    }, {
        "symbol": "NVDA",
        "label": "NVIDIA Corporation",
        "price": 249.28
    }, {
        "symbol": "JNJ",
        "label": "Johnson & Johnson",
        "price": 149.17
    }, {
        "symbol": "WDC",
        "label": "Western Digital Corporation",
        "price": 68.26
    }, {
        "symbol": "BSX",
        "label": "Boston Scientific Corporation",
        "price": 44.28
    }, {
        "symbol": "STI",
        "label": "SunTrust Banks Inc.",
        "price": 70.13
    }, {
        "symbol": "ON",
        "label": "ON Semiconductor Corporation",
        "price": 25.0
    }, {
        "symbol": "ITUB",
        "label": "Itau Unibanco Banco Holding SA American Depositary Shares (Each repstg 500 Prefe" +
                "rred shares)",
        "price": 8.36
    }, {
        "symbol": "BK",
        "label": "Bank of New York Mellon Corporation (The)",
        "price": 46.18
    }, {
        "symbol": "HMY",
        "label": "Harmony Gold Mining Company Limited",
        "price": 3.55
    }, {
        "symbol": "APA",
        "label": "Apache Corporation",
        "price": 32.67
    }, {
        "symbol": "HOME",
        "label": "At Home Group Inc.",
        "price": 6.01
    }, {
        "symbol": "NGD",
        "label": "New Gold Inc.",
        "price": 0.98
    }, {
        "symbol": "KOS",
        "label": "Kosmos Energy Ltd.",
        "price": 6.57
    }, {
        "symbol": "DAL",
        "label": "Delta Air Lines Inc.",
        "price": 62.03
    }, {
        "symbol": "INFY",
        "label": "Infosys Limited American Depositary Shares",
        "price": 10.88
    }, {
        "symbol": "CCL",
        "label": "Carnival Corporation",
        "price": 51.9
    }, {
        "symbol": "BHGE",
        "label": "Baker Hughes a GE company Class A",
        "price": 23.09
    }, {
        "symbol": "MFGP",
        "label": "Micro Focus Intl PLC ADS each representing One Ord Sh",
        "price": 14.64
    }, {
        "symbol": "PBR",
        "label": "Petroleo Brasileiro S.A.- Petrobras",
        "price": 15.16
    }, {
        "symbol": "DISCA",
        "label": "Discovery Communications Inc. Series A Common Stock",
        "price": 32.43
    }, {
        "symbol": "LPI",
        "label": "Laredo Petroleum Inc.",
        "price": 2.4
    }, {
        "symbol": "ABT",
        "label": "Abbott Laboratories",
        "price": 89.0
    }, {
        "symbol": "FITB",
        "label": "Fifth Third Bancorp",
        "price": 29.45
    }, {
        "symbol": "PTEN",
        "label": "Patterson-UTI Energy Inc.",
        "price": 9.95
    }, {
        "symbol": "USB",
        "label": "U.S. Bancorp",
        "price": 55.35
    }, {
        "symbol": "BA",
        "label": "The Boeing Company",
        "price": 324.15
    }, {
        "symbol": "GGB",
        "label": "Gerdau S.A.",
        "price": 5.14
    }, {
        "symbol": "JBLU",
        "label": "JetBlue Airways Corporation",
        "price": 19.21
    }, {
        "symbol": "QEP",
        "label": "QEP Resources Inc.",
        "price": 3.93
    }, {
        "symbol": "EBAY",
        "label": "eBay Inc.",
        "price": 35.82
    }, {
        "symbol": "HBAN",
        "label": "Huntington Bancshares Incorporated",
        "price": 14.71
    }, {
        "symbol": "DB",
        "label": "Deutsche Bank AG",
        "price": 8.42
    }, {
        "symbol": "MRVL",
        "label": "Marvell Technology Group Ltd.",
        "price": 28.49
    }, {
        "symbol": "ABX",
        "label": "Barrick Gold Corporation",
        "price": 13.55
    }, {
        "symbol": "WBA",
        "label": "Walgreens Boots Alliance Inc.",
        "price": 54.41
    }, {
        "symbol": "CDNA",
        "label": "CareDx Inc.",
        "price": 23.61
    }, {
        "symbol": "ETP",
        "label": "Energy Transfer Partners L.P. representing limited partner interests",
        "price": 21.49
    }, {
        "symbol": "RSX",
        "label": "VanEck Vectors Russia",
        "price": 23.24
    }, {
        "symbol": "KNX",
        "label": "Knight-Swift Transportation Holdings Inc.",
        "price": 37.52
    }, {
        "symbol": "KBH",
        "label": "KB Home",
        "price": 36.81
    }, {
        "symbol": "DISCK",
        "label": "Discovery Communications Inc.",
        "price": 30.48
    }, {
        "symbol": "PGR",
        "label": "Progressive Corporation (The)",
        "price": 76.3
    }, {
        "symbol": "XLE",
        "label": "SPDR Select Sector Fund - Energy Select Sector",
        "price": 59.12
    }, {
        "symbol": "MO",
        "label": "Altria Group Inc.",
        "price": 51.02
    }, {
        "symbol": "MDT",
        "label": "Medtronic plc.",
        "price": 119.03
    }, {
        "symbol": "RIG",
        "label": "Transocean Ltd (Switzerland)",
        "price": 5.97
    }, {
        "symbol": "BCS",
        "label": "Barclays PLC",
        "price": 9.12
    }, {
        "symbol": "CDEV",
        "label": "Centennial Resource Development Inc.",
        "price": 4.42
    }, {
        "symbol": "DNR",
        "label": "Denbury Resources Inc.",
        "price": 1.22
    }, {
        "symbol": "EOG",
        "label": "EOG Resources Inc.",
        "price": 85.31
    }, {
        "symbol": "XLP",
        "label": "SPDR Select Sector Fund - Consumer Staples",
        "price": 58.59
    }, {
        "symbol": "HPE",
        "label": "Hewlett Packard Enterprise Company",
        "price": 15.1
    }, {
        "symbol": "TXN",
        "label": "Texas Instruments Incorporated",
        "price": 131.7
    }, {
        "symbol": "OAS",
        "label": "Oasis Petroleum Inc.",
        "price": 2.97
    }, {
        "symbol": "OKE",
        "label": "ONEOK Inc.",
        "price": 77.17
    }, {
        "symbol": "CVS",
        "label": "CVS Health Corporation",
        "price": 76.02
    }, {
        "symbol": "SVXY",
        "label": "ProShares Short VIX Short Term Futures",
        "price": 53.09
    }, {
        "symbol": "HES",
        "label": "Hess Corporation",
        "price": 69.9
    }, {
        "symbol": "GDDY",
        "label": "GoDaddy Inc. Class A",
        "price": 72.06
    }, {
        "symbol": "BX",
        "label": "The Blackstone Group L.P. Representing Limited Partnership Interests",
        "price": 60.76
    }, {
        "symbol": "NLSN",
        "label": "Nielsen N.V.",
        "price": 21.51
    }, {
        "symbol": "TS",
        "label": "Tenaris S.A. American Depositary Shares",
        "price": 22.81
    }, {
        "symbol": "BOX",
        "label": "Box Inc. Class A",
        "price": 16.27
    }, {
        "symbol": "OXY",
        "label": "Occidental Petroleum Corporation",
        "price": 45.83
    }, {
        "symbol": "OCLR",
        "label": "Oclaro Inc.",
        "price": 8.27
    }, {
        "symbol": "CVE",
        "label": "Cenovus Energy Inc",
        "price": 9.26
    }, {
        "symbol": "SYF",
        "label": "Synchrony Financial",
        "price": 35.76
    }, {
        "symbol": "IAU",
        "label": "ishares Gold Trust",
        "price": 14.89
    }, {
        "symbol": "MTG",
        "label": "MGIC Investment Corporation",
        "price": 14.09
    }, {
        "symbol": "LUV",
        "label": "Southwest Airlines Company",
        "price": 55.01
    }, {
        "symbol": "FTI",
        "label": "TechnipFMC plc",
        "price": 19.79
    }, {
        "symbol": "AA",
        "label": "Alcoa Corporation",
        "price": 17.69
    }, {
        "symbol": "ABEV",
        "label": "Ambev S.A. American Depositary Shares (Each representing 1)",
        "price": 4.43
    }, {
        "symbol": "VTI",
        "label": "Vanguard Total Stock Market",
        "price": 168.76
    }, {
        "symbol": "EQR",
        "label": "Equity Residential of Beneficial Interest",
        "price": 82.5
    }, {
        "symbol": "EWG",
        "label": "iShares MSCI Germany Index Fund",
        "price": 27.41
    }, {
        "symbol": "ESRX",
        "label": "Express Scripts Holding Company",
        "price": 92.48
    }, {
        "symbol": "MDR",
        "label": "McDermott International Inc.",
        "price": 0.7032
    }, {
        "symbol": "ETSY",
        "label": "Etsy Inc.",
        "price": 51.44
    }, {
        "symbol": "ANDV",
        "label": "Andeavor",
        "price": 152.35
    }, {
        "symbol": "TAL",
        "label": "TAL Education Group American Depositary Shares",
        "price": 54.02
    }, {
        "symbol": "UNH",
        "label": "UnitedHealth Group Incorporated (DE)",
        "price": 298.47
    }, {
        "symbol": "ECA",
        "label": "Encana Corporation",
        "price": 4.09
    }, {
        "symbol": "GES",
        "label": "Guess? Inc.",
        "price": 23.5
    }, {
        "symbol": "AMZN",
        "label": "Amazon.com Inc.",
        "price": 1864.72
    }, {
        "symbol": "NFLX",
        "label": "Netflix Inc.",
        "price": 339.67
    }, {
        "symbol": "ENTG",
        "label": "Entegris Inc.",
        "price": 56.23
    }, {
        "symbol": "CSX",
        "label": "CSX Corporation",
        "price": 76.4
    }, {
        "symbol": "WYNN",
        "label": "Wynn Resorts Limited",
        "price": 151.62
    }, {
        "symbol": "FOX",
        "label": "Twenty-First Century Fox Inc.",
        "price": 38.575
    }, {
        "symbol": "CNC",
        "label": "Centene Corporation",
        "price": 64.54
    }, {
        "symbol": "ZION",
        "label": "Zions Bancorporation",
        "price": 50.74
    }, {
        "symbol": "CLDR",
        "label": "Cloudera Inc.",
        "price": 11.56
    }, {
        "symbol": "RES",
        "label": "RPC Inc.",
        "price": 4.77
    }, {
        "symbol": "CI",
        "label": "Cigna Corporation",
        "price": 212.52
    }, {
        "symbol": "KRE",
        "label": "SPDR S&P Regional Banking",
        "price": 57.54
    }, {
        "symbol": "WMB",
        "label": "Williams Companies Inc. (The)",
        "price": 23.86
    }, {
        "symbol": "SYNH",
        "label": "Syneos Health Inc.",
        "price": 63.99
    }, {
        "symbol": "HBI",
        "label": "Hanesbrands Inc.",
        "price": 14.32
    }, {
        "symbol": "BBT",
        "label": "BB&T Corporation",
        "price": 54.24
    }, {
        "symbol": "XL",
        "label": "XL Group Ltd.",
        "price": 57.59
    }, {
        "symbol": "PAYX",
        "label": "Paychex Inc.",
        "price": 88.24
    }, {
        "symbol": "CELG",
        "label": "Celgene Corporation",
        "price": 108.24
    }, {
        "symbol": "D",
        "label": "Dominion Energy Inc.",
        "price": 84.05
    }, {
        "symbol": "CHS",
        "label": "Chico's FAS Inc.",
        "price": 4.17
    }, {
        "symbol": "CFG",
        "label": "Citizens Financial Group Inc.",
        "price": 40.87
    }, {
        "symbol": "XLI",
        "label": "SPDR Select Sector Fund - Industrial",
        "price": 76.59
    }, {
        "symbol": "SU",
        "label": "Suncor Energy Inc.",
        "price": 33.61
    }, {
        "symbol": "SLV",
        "label": "iShares Silver Trust",
        "price": 14.06
    }, {
        "symbol": "MMM",
        "label": "3M Company",
        "price": 181.35
    }, {
        "symbol": "BIIB",
        "label": "Biogen Inc.",
        "price": 285.5
    }, {
        "symbol": "JBL",
        "label": "Jabil Inc.",
        "price": 43.22
    }, {
        "symbol": "ACN",
        "label": "Accenture plc Class A (Ireland)",
        "price": 209.2
    }, {
        "symbol": "NTR",
        "label": "Nutrien Ltd.",
        "price": 46.87
    }, {
        "symbol": "PAH",
        "label": "Platform Specialty Products Corporation",
        "price": 11.26
    }, {
        "symbol": "BRFS",
        "label": "BRF S.A.",
        "price": 8.52
    }, {
        "symbol": "SBUX",
        "label": "Starbucks Corporation",
        "price": 93.62
    }, {
        "symbol": "EWU",
        "label": "iShares MSCI United Kingdom",
        "price": 31.96
    }, {
        "symbol": "PE",
        "label": "Parsley Energy Inc. Class A",
        "price": 17.89
    }, {
        "symbol": "LVS",
        "label": "Las Vegas Sands Corp.",
        "price": 74.06
    }, {
        "symbol": "EGO",
        "label": "Eldorado Gold Corporation",
        "price": 7.26
    }, {
        "symbol": "GSK",
        "label": "GlaxoSmithKline PLC",
        "price": 47.89
    }, {
        "symbol": "TEVA",
        "label": "Teva Pharmaceutical Industries Limited American Depositary Shares",
        "price": 10.2
    }, {
        "symbol": "PAA",
        "label": "Plains All American Pipeline L.P.",
        "price": 18.99
    }, {
        "symbol": "SQQQ",
        "label": "ProShares UltraPro Short QQQ",
        "price": 19.26
    }, {
        "symbol": "VLO",
        "label": "Valero Energy Corporation",
        "price": 91.22
    }, {
        "symbol": "CSRA",
        "label": "CSRA Inc.",
        "price": 41.23
    }, {
        "symbol": "IMAX",
        "label": "Imax Corporation",
        "price": 20.42
    }, {
        "symbol": "FNB",
        "label": "F.N.B. Corporation",
        "price": 12.76
    }, {
        "symbol": "ADSK",
        "label": "Autodesk Inc.",
        "price": 193.35
    }, {
        "symbol": "HD",
        "label": "Home Depot Inc. (The)",
        "price": 231.91
    }, {
        "symbol": "BIDU",
        "label": "Baidu Inc.",
        "price": 139.61
    }, {
        "symbol": "AR",
        "label": "Antero Resources Corporation",
        "price": 2.39
    }, {
        "symbol": "ARNC",
        "label": "Arconic Inc.",
        "price": 29.32
    }, {
        "symbol": "VOYA",
        "label": "Voya Financial Inc.",
        "price": 62.71
    }, {
        "symbol": "SPXU",
        "label": "ProShares UltraPro Short S&P500",
        "price": 28.63
    }, {
        "symbol": "MLCO",
        "label": "Melco Resorts & Entertainment Limited",
        "price": 25.02
    }, {
        "symbol": "SJNK",
        "label": "SPDR Bloomberg Barclays Short Term High Yield Bond",
        "price": 27.14
    }, {
        "symbol": "CWB",
        "label": "SPDR Bloomberg Barclays Convertible Securities",
        "price": 57.62
    }, {
        "symbol": "SM",
        "label": "SM Energy Company",
        "price": 11.15
    }, {
        "symbol": "NTRS",
        "label": "Northern Trust Corporation",
        "price": 109.38
    }, {
        "symbol": "DHI",
        "label": "D.R. Horton Inc.",
        "price": 55.71
    }, {
        "symbol": "UAL",
        "label": "United Continental Holdings",
        "price": 89.7
    }, {
        "symbol": "HRB",
        "label": "H&R Block Inc.",
        "price": 24.47
    }, {
        "symbol": "BTU",
        "label": "Peabody Energy Corporation",
        "price": 8.57
    }, {
        "symbol": "JBGS",
        "label": "JBG SMITH Properties",
        "price": 40.6
    }, {
        "symbol": "IPG",
        "label": "Interpublic Group of Companies Inc. (The)",
        "price": 24.15
    }, {
        "symbol": "DE",
        "label": "Deere & Company",
        "price": 176.2
    }, {
        "symbol": "KHC",
        "label": "The Kraft Heinz Company",
        "price": 31.03
    }, {
        "symbol": "COG",
        "label": "Cabot Oil & Gas Corporation",
        "price": 16.81
    }, {
        "symbol": "IBM",
        "label": "International Business Machines Corporation",
        "price": 138.31
    }, {
        "symbol": "WTW",
        "label": "Weight Watchers International Inc",
        "price": 19.04
    }, {
        "symbol": "NYT",
        "label": "New York Times Company (The)",
        "price": 33.86
    }, {
        "symbol": "DBJP",
        "label": "Xtrackers MSCI Japan Hedged Equity",
        "price": 38.95
    }, {
        "symbol": "AAL",
        "label": "American Airlines Group Inc.",
        "price": 28.4
    }, {
        "symbol": "OLN",
        "label": "Olin Corporation",
        "price": 17.85
    }, {
        "symbol": "JCI",
        "label": "Johnson Controls International plc",
        "price": 40.99
    }, {
        "symbol": "VICI",
        "label": "VICI Properties Inc.",
        "price": 26.27
    }, {
        "symbol": "VST",
        "label": "Vistra Energy Corp.",
        "price": 22.94
    }, {
        "symbol": "NWL",
        "label": "Newell Brands Inc.",
        "price": 20.26
    }, {
        "symbol": "EPD",
        "label": "Enterprise Products Partners L.P.",
        "price": 28.81
    }, {
        "symbol": "KMB",
        "label": "Kimberly-Clark Corporation",
        "price": 144.5
    }, {
        "symbol": "AEP",
        "label": "American Electric Power Company Inc.",
        "price": 98.56
    }, {
        "symbol": "IT",
        "label": "Gartner Inc.",
        "price": 161.17
    }, {
        "symbol": "CAT",
        "label": "Caterpillar Inc.",
        "price": 147.78
    }, {
        "symbol": "AU",
        "label": "AngloGold Ashanti Limited",
        "price": 20.39
    }, {
        "symbol": "MYL",
        "label": "Mylan N.V.",
        "price": 21.66
    }, {
        "symbol": "XRAY",
        "label": "DENTSPLY SIRONA Inc.",
        "price": 59.96
    }, {
        "symbol": "KKR",
        "label": "KKR & Co. L.P. Representing Limited Partnership Interest",
        "price": 31.2
    }, {
        "symbol": "IEUR",
        "label": "iShares Core MSCI Europe",
        "price": 45.72
    }, {
        "symbol": "LQD",
        "label": "iShares iBoxx $ Investment Grade Corporate Bond",
        "price": 122.61
    }, {
        "symbol": "CMC",
        "label": "Commercial Metals Company",
        "price": 21.82
    }, {
        "symbol": "JCP",
        "label": "J.C. Penney Company Inc. Holding Company",
        "price": 0.8491
    }, {
        "symbol": "XBI",
        "label": "SPDR Series Trust S&P Biotech",
        "price": 96.27
    }, {
        "symbol": "SYMC",
        "label": "Symantec Corporation",
        "price": 19.98
    }, {
        "symbol": "TMUS",
        "label": "T-Mobile US Inc.",
        "price": 82.15
    }, {
        "symbol": "EWW",
        "label": "iShares Inc MSCI Mexico",
        "price": 48.01
    }, {
        "symbol": "RMP",
        "label": "Rice Midstream Partners LP representing limited partner interests",
        "price": 18.14
    }, {
        "symbol": "NRG",
        "label": "NRG Energy Inc.",
        "price": 38.54
    }, {
        "symbol": "CPB",
        "label": "Campbell Soup Company",
        "price": 48.02
    }, {
        "symbol": "ATHM",
        "label": "Autohome Inc. American Depositary Shares each representing one class A.",
        "price": 87.74
    }, {
        "symbol": "WRB",
        "label": "W.R. Berkley Corporation",
        "price": 70.36
    }, {
        "symbol": "MOMO",
        "label": "Momo Inc.",
        "price": 37.29
    }, {
        "symbol": "TGT",
        "label": "Target Corporation",
        "price": 116.92
    }, {
        "symbol": "TV",
        "label": "Grupo Televisa S.A.",
        "price": 12.41
    }, {
        "symbol": "GLW",
        "label": "Corning Incorporated",
        "price": 30.06
    }, {
        "symbol": "MAS",
        "label": "Masco Corporation",
        "price": 49.09
    }, {
        "symbol": "DOV",
        "label": "Dover Corporation",
        "price": 119.53
    }, {
        "symbol": "HRL",
        "label": "Hormel Foods Corporation",
        "price": 46.3
    }, {
        "symbol": "S",
        "label": "Sprint Corporation",
        "price": 4.82
    }, {
        "symbol": "TECK",
        "label": "Teck Resources Ltd",
        "price": 16.22
    }, {
        "symbol": "AES",
        "label": "The AES Corporation",
        "price": 20.5
    }, {
        "symbol": "PPL",
        "label": "PPL Corporation",
        "price": 36.09
    }, {
        "symbol": "IXUS",
        "label": "iShares Core MSCI Total International Stock ETF",
        "price": 63.03
    }, {
        "symbol": "SEE",
        "label": "Sealed Air Corporation",
        "price": 39.28
    }, {
        "symbol": "NLY",
        "label": "Annaly Capital Management Inc",
        "price": 9.7
    }, {
        "symbol": "FRC",
        "label": "FIRST REPUBLIC BANK",
        "price": 118.18
    }, {
        "symbol": "EWT",
        "label": "iShares Inc MSCI Taiwan",
        "price": 41.54
    }, {
        "symbol": "STX",
        "label": "Seagate Technology PLC",
        "price": 61.08
    }, {
        "symbol": "ADBE",
        "label": "Adobe Systems Incorporated",
        "price": 349.74
    }, {
        "symbol": "K",
        "label": "Kellogg Company",
        "price": 70.88
    }, {
        "symbol": "RTN",
        "label": "Raytheon Company",
        "price": 231.34
    }, {
        "symbol": "M",
        "label": "Macy's Inc",
        "price": 17.57
    }, {
        "symbol": "TSLA",
        "label": "Tesla Inc.",
        "price": 510.5
    }, {
        "symbol": "CY",
        "label": "Cypress Semiconductor Corporation",
        "price": 23.49
    }, {
        "symbol": "KBE",
        "label": "SPDR S&P Bank",
        "price": 42.95
    }, {
        "symbol": "TSM",
        "label": "Taiwan Semiconductor Manufacturing Company Ltd.",
        "price": 58.58
    }, {
        "symbol": "STL",
        "label": "Sterling Bancorp",
        "price": 20.89
    }, {
        "symbol": "NYCB",
        "label": "New York Community Bancorp Inc.",
        "price": 11.7
    }, {
        "symbol": "TRQ",
        "label": "Turquoise Hill Resources Ltd.",
        "price": 0.6913
    }, {
        "symbol": "NBL",
        "label": "Noble Energy Inc.",
        "price": 23.43
    }, {
        "symbol": "FDC",
        "label": "First Data Corporation Class A",
        "price": 26.5
    }, {
        "symbol": "BAH",
        "label": "Booz Allen Hamilton Holding Corporation",
        "price": 79.4
    }, {
        "symbol": "BUFF",
        "label": "Blue Buffalo Pet Products Inc.",
        "price": 40.0
    }, {
        "symbol": "ARI",
        "label": "Apollo Commercial Real Estate Finance Inc",
        "price": 18.39
    }, {
        "symbol": "MET",
        "label": "MetLife Inc.",
        "price": 52.61
    }, {
        "symbol": "AFL",
        "label": "AFLAC Incorporated",
        "price": 53.42
    }, {
        "symbol": "TPR",
        "label": "Tapestry Inc.",
        "price": 28.89
    }, {
        "symbol": "WPM",
        "label": "Wheaton Precious Metals Corp (Canada)",
        "price": 28.22
    }, {
        "symbol": "LNT",
        "label": "Alliant Energy Corporation",
        "price": 57.16
    }, {
        "symbol": "BTG",
        "label": "B2Gold Corp (Canada)",
        "price": 4.12
    }, {
        "symbol": "UTX",
        "label": "United Technologies Corporation",
        "price": 154.4
    }, {
        "symbol": "WLL",
        "label": "Whiting Petroleum Corporation",
        "price": 5.59
    }, {
        "symbol": "LKQ",
        "label": "LKQ Corporation",
        "price": 34.61
    }, {
        "symbol": "PCG",
        "label": "Pacific Gas & Electric Co.",
        "price": 13.08
    }, {
        "symbol": "CRZO",
        "label": "Carrizo Oil & Gas Inc.",
        "price": 7.83
    }, {
        "symbol": "CENX",
        "label": "Century Aluminum Company",
        "price": 7.18
    }, {
        "symbol": "PGNX",
        "label": "Progenics Pharmaceuticals Inc.",
        "price": 4.69
    }, {
        "symbol": "CLR",
        "label": "Continental Resources Inc.",
        "price": 32.66
    }, {
        "symbol": "FCAU",
        "label": "Fiat Chrysler Automobiles N.V.",
        "price": 13.75
    }, {
        "symbol": "IMGN",
        "label": "ImmunoGen Inc.",
        "price": 4.56
    }, {
        "symbol": "HL",
        "label": "Hecla Mining Company",
        "price": 3.1
    }, {
        "symbol": "LULU",
        "label": "lululemon athletica inc.",
        "price": 241.25
    }, {
        "symbol": "UAA",
        "label": "Under Armour Inc. Class A",
        "price": 20.39
    }, {
        "symbol": "CAG",
        "label": "ConAgra Brands Inc.",
        "price": 33.33
    }, {
        "symbol": "STLD",
        "label": "Steel Dynamics Inc.",
        "price": 33.21
    }, {
        "symbol": "PEP",
        "label": "PepsiCo Inc.",
        "price": 141.26
    }, {
        "symbol": "SO",
        "label": "Southern Company (The)",
        "price": 67.26
    }, {
        "symbol": "ETE",
        "label": "Energy Transfer Equity L.P. representing Limited Partnership interests",
        "price": 16.81
    }, {
        "symbol": "COST",
        "label": "Costco Wholesale Corporation",
        "price": 304.68
    }, {
        "symbol": "GKOS",
        "label": "Glaukos Corporation",
        "price": 57.35
    }, {
        "symbol": "CCI",
        "label": "Crown Castle International Corp. (REIT)",
        "price": 145.25
    }, {
        "symbol": "ZTO",
        "label": "ZTO Express (Cayman) Inc. American Depositary Shares each representing one Class" +
                " A.",
        "price": 24.18
    }, {
        "symbol": "VGK",
        "label": "Vanguard FTSEEuropean",
        "price": 54.02
    }, {
        "symbol": "GS",
        "label": "Goldman Sachs Group Inc. (The)",
        "price": 249.46
    }, {
        "symbol": "IJR",
        "label": "iShares Core S&P Small-Cap",
        "price": 85.37
    }, {
        "symbol": "ZAYO",
        "label": "Zayo Group Holdings Inc.",
        "price": 34.77
    }, {
        "symbol": "ADT",
        "label": "ADT Inc.",
        "price": 6.5
    }, {
        "symbol": "ALTR",
        "label": "Altair Engineering Inc.",
        "price": 39.1
    }, {
        "symbol": "GGP",
        "label": "GGP Inc.",
        "price": 22.07
    }, {
        "symbol": "EMR",
        "label": "Emerson Electric Company",
        "price": 77.78
    }, {
        "symbol": "CNP",
        "label": "CenterPoint Energy Inc (Holding Co)",
        "price": 27.05
    }, {
        "symbol": "ALXN",
        "label": "Alexion Pharmaceuticals Inc.",
        "price": 113.27
    }, {
        "symbol": "COTY",
        "label": "Coty Inc. Class A",
        "price": 11.03
    }, {
        "symbol": "MA",
        "label": "Mastercard Incorporated",
        "price": 323.66
    }, {
        "symbol": "AWI",
        "label": "Armstrong World Industries Inc",
        "price": 101.97
    }, {
        "symbol": "IYR",
        "label": "iShares U.S. Real Estate",
        "price": 89.86
    }, {
        "symbol": "ADP",
        "label": "Automatic Data Processing Inc.",
        "price": 176.87
    }, {
        "symbol": "TZA",
        "label": "Direxion Small Cap Bear 3X Shares",
        "price": 9.42
    }, {
        "symbol": "MULE",
        "label": "MuleSoft Inc. Class A",
        "price": 44.72
    }, {
        "symbol": "BBVA",
        "label": "Banco Bilbao Vizcaya Argentaria S.A.",
        "price": 5.33
    }, {
        "symbol": "MNST",
        "label": "Monster Beverage Corporation",
        "price": 67.31
    }, {
        "symbol": "FEZ",
        "label": "SPDR DJ Euro STOXX 50 Etf",
        "price": 38.33
    }, {
        "symbol": "AER",
        "label": "Aercap Holdings N.V.",
        "price": 62.35
    }, {
        "symbol": "GTN",
        "label": "Gray Communications Systems Inc.",
        "price": 22.58
    }, {
        "symbol": "EWY",
        "label": "iShares Inc MSCI South Korea",
        "price": 58.27
    }, {
        "symbol": "CDE",
        "label": "Coeur Mining Inc.",
        "price": 5.86
    }, {
        "symbol": "NCR",
        "label": "NCR Corporation",
        "price": 35.6
    }, {
        "symbol": "AMT",
        "label": "American Tower Corporation (REIT)",
        "price": 233.42
    }, {
        "symbol": "PSTG",
        "label": "Pure Storage Inc. Class A",
        "price": 18.5
    }, {
        "symbol": "VRX",
        "label": "Valeant Pharmaceuticals International Inc.",
        "price": 23.4
    }, {
        "symbol": "SJM",
        "label": "J.M. Smucker Company (The)",
        "price": 106.39
    }, {
        "symbol": "ETN",
        "label": "Eaton Corporation PLC",
        "price": 95.68
    }, {
        "symbol": "DUK",
        "label": "Duke Energy Corporation (Holding Company)",
        "price": 93.72
    }, {
        "symbol": "CL",
        "label": "Colgate-Palmolive Company",
        "price": 71.18
    }, {
        "symbol": "IDRA",
        "label": "Idera Pharmaceuticals Inc.",
        "price": 2.15
    }, {
        "symbol": "KAR",
        "label": "KAR Auction Services Inc",
        "price": 22.01
    }, {
        "symbol": "NEE",
        "label": "NextEra Energy Inc.",
        "price": 253.4
    }, {
        "symbol": "PXD",
        "label": "Pioneer Natural Resources Company",
        "price": 148.79
    }, {
        "symbol": "WPX",
        "label": "WPX Energy Inc.",
        "price": 12.67
    }, {
        "symbol": "ADM",
        "label": "Archer-Daniels-Midland Company",
        "price": 45.25
    }, {
        "symbol": "VTR",
        "label": "Ventas Inc.",
        "price": 58.81
    }, {
        "symbol": "VMW",
        "label": "Vmware Inc. Class A",
        "price": 151.36
    }, {
        "symbol": "RIGL",
        "label": "Rigel Pharmaceuticals Inc.",
        "price": 2.88
    }, {
        "symbol": "DKS",
        "label": "Dick's Sporting Goods Inc",
        "price": 48.24
    }, {
        "symbol": "FG",
        "label": "FGL Holdings",
        "price": 9.99
    }, {
        "symbol": "WTI",
        "label": "W&T Offshore Inc.",
        "price": 5.17
    }, {
        "symbol": "LRCX",
        "label": "Lam Research Corporation",
        "price": 308.0
    }, {
        "symbol": "NXPI",
        "label": "NXP Semiconductors N.V.",
        "price": 133.84
    }, {
        "symbol": "CE",
        "label": "Celanese Corporation Series A",
        "price": 117.59
    }, {
        "symbol": "HUN",
        "label": "Huntsman Corporation",
        "price": 23.35
    }, {
        "symbol": "CSTM",
        "label": "Constellium N.V.",
        "price": 12.59
    }, {
        "symbol": "VER",
        "label": "VEREIT Inc.",
        "price": 9.56
    }, {
        "symbol": "GPK",
        "label": "Graphic Packaging Holding Company",
        "price": 16.59
    }, {
        "symbol": "WELL",
        "label": "Welltower Inc.",
        "price": 86.39
    }, {
        "symbol": "TJX",
        "label": "TJX Companies Inc. (The)",
        "price": 62.68
    }, {
        "symbol": "PLD",
        "label": "ProLogis Inc.",
        "price": 94.27
    }, {
        "symbol": "KSS",
        "label": "Kohl's Corporation",
        "price": 47.0
    }, {
        "symbol": "XEC",
        "label": "Cimarex Energy Co",
        "price": 51.28
    }, {
        "symbol": "TLT",
        "label": "iShares 20+ Year Treasury Bond ETF",
        "price": 138.02
    }, {
        "symbol": "DRI",
        "label": "Darden Restaurants Inc.",
        "price": 113.52
    }, {
        "symbol": "OZRK",
        "label": "Bank of the Ozarks",
        "price": 41.24
    }, {
        "symbol": "ENDP",
        "label": "Endo International plc",
        "price": 6.04
    }, {
        "symbol": "ATI",
        "label": "Allegheny Technologies Incorporated",
        "price": 18.67
    }, {
        "symbol": "IAG",
        "label": "Iamgold Corporation",
        "price": 3.01
    }, {
        "symbol": "VFC",
        "label": "V.F. Corporation",
        "price": 94.27
    }, {
        "symbol": "BDN",
        "label": "Brandywine Realty Trust",
        "price": 15.82
    }, {
        "symbol": "HON",
        "label": "Honeywell International Inc.",
        "price": 183.23
    }, {
        "symbol": "YNDX",
        "label": "Yandex N.V.",
        "price": 45.4
    }, {
        "symbol": "USG",
        "label": "USG Corporation",
        "price": 43.49
    }, {
        "symbol": "GOOGL",
        "label": "Alphabet Inc.",
        "price": 1479.52
    }, {
        "symbol": "ICE",
        "label": "Intercontinental Exchange Inc.",
        "price": 96.77
    }, {
        "symbol": "OMER",
        "label": "Omeros Corporation",
        "price": 13.635
    }, {
        "symbol": "WY",
        "label": "Weyerhaeuser Company",
        "price": 30.98
    }, {
        "symbol": "HEFA",
        "label": "iShares Currency Hedged MSCI EAFE",
        "price": 31.32
    }, {
        "symbol": "BLL",
        "label": "Ball Corporation",
        "price": 69.24
    }, {
        "symbol": "CGNX",
        "label": "Cognex Corporation",
        "price": 58.64
    }, {
        "symbol": "AMGN",
        "label": "Amgen Inc.",
        "price": 241.49
    }, {
        "symbol": "FSLR",
        "label": "First Solar Inc.",
        "price": 53.52
    }, {
        "symbol": "IVZ",
        "label": "Invesco Ltd",
        "price": 18.32
    }, {
        "symbol": "DG",
        "label": "Dollar General Corporation",
        "price": 157.99
    }, {
        "symbol": "SID",
        "label": "Companhia Siderurgica Nacional S.A.",
        "price": 3.5
    }, {
        "symbol": "SLM",
        "label": "SLM Corporation",
        "price": 8.86
    }, {
        "symbol": "SLCA",
        "label": "U.S. Silica Holdings Inc.",
        "price": 5.81
    }, {
        "symbol": "HCP",
        "label": "HCP Inc.",
        "price": 32.25
    }, {
        "symbol": "PF",
        "label": "Pinnacle Foods Inc.",
        "price": 66.69
    }, {
        "symbol": "SGU",
        "label": "Star Group L.P.",
        "price": 9.53
    }, {
        "symbol": "ODP",
        "label": "Office Depot Inc.",
        "price": 2.59
    }, {
        "symbol": "PK",
        "label": "Park Hotels & Resorts Inc.",
        "price": 24.77
    }, {
        "symbol": "TLGT",
        "label": "Teligent Inc.",
        "price": 0.4027
    }, {
        "symbol": "TOL",
        "label": "Toll Brothers Inc.",
        "price": 44.18
    }, {
        "symbol": "PDCO",
        "label": "Patterson Companies Inc.",
        "price": 23.28
    }, {
        "symbol": "ITB",
        "label": "iShares U.S. Home Construction",
        "price": 38.82
    }, {
        "symbol": "Z",
        "label": "Zillow Group Inc.",
        "price": 47.96
    }, {
        "symbol": "BCO",
        "label": "Brinks Company (The)",
        "price": 92.42
    }, {
        "symbol": "CME",
        "label": "CME Group Inc.",
        "price": 206.86
    }, {
        "symbol": "BKD",
        "label": "Brookdale Senior Living Inc.",
        "price": 7.32
    }, {
        "symbol": "PPBI",
        "label": "Pacific Premier Bancorp Inc",
        "price": 31.72
    }, {
        "symbol": "JMEI",
        "label": "Jumei International Holding Limited American Depositary Shares each representing" +
                " one Class A",
        "price": 18.75
    }, {
        "symbol": "ETFC",
        "label": "E*TRADE Financial Corporation",
        "price": 46.74
    }, {
        "symbol": "AIV",
        "label": "Apartment Investment and Management Company",
        "price": 52.75
    }, {
        "symbol": "DPS",
        "label": "Dr Pepper Snapple Group Inc",
        "price": 123.72
    }, {
        "symbol": "EXC",
        "label": "Exelon Corporation",
        "price": 47.39
    }, {
        "symbol": "TAP",
        "label": "Molson Coors Brewing Company Class B",
        "price": 56.57
    }, {
        "symbol": "MON",
        "label": "Monsanto Company",
        "price": 127.96
    }, {
        "symbol": "UNP",
        "label": "Union Pacific Corporation",
        "price": 185.32
    }, {
        "symbol": "IWD",
        "label": "iShares Russell 1000 Value",
        "price": 125.82
    }, {
        "symbol": "BDX",
        "label": "Becton Dickinson and Company",
        "price": 277.08
    }, {
        "symbol": "GME",
        "label": "Gamestop Corporation",
        "price": 4.75
    }, {
        "symbol": "PEGI",
        "label": "Pattern Energy Group Inc.",
        "price": 26.86
    }, {
        "symbol": "MX",
        "label": "MagnaChip Semiconductor Corporation",
        "price": 14.06
    }, {
        "symbol": "RLJ",
        "label": "RLJ Lodging Trust of Beneficial Interest $0.01 par value",
        "price": 16.98
    }, {
        "symbol": "LB",
        "label": "L Brands Inc.",
        "price": 20.16
    }, {
        "symbol": "TIF",
        "label": "Tiffany & Co.",
        "price": 134.25
    }, {
        "symbol": "DLPH",
        "label": "Delphi Technologies PLC",
        "price": 11.23
    }, {
        "symbol": "USMV",
        "label": "iShares Edge MSCI Min Vol USA",
        "price": 67.62
    }, {
        "symbol": "NCLH",
        "label": "Norwegian Cruise Line Holdings Ltd.",
        "price": 59.65
    }, {
        "symbol": "NOV",
        "label": "National Oilwell Varco Inc.",
        "price": 23.5
    }, {
        "symbol": "CNDT",
        "label": "Conduent Incorporated",
        "price": 5.755
    }, {
        "symbol": "FNSR",
        "label": "Finisar Corporation",
        "price": 22.61
    }, {
        "symbol": "LMT",
        "label": "Lockheed Martin Corporation",
        "price": 425.66
    }, {
        "symbol": "MTZ",
        "label": "MasTec Inc.",
        "price": 63.75
    }, {
        "symbol": "TBT",
        "label": "ProShares UltraShort Lehman 20 Year Treasury",
        "price": 29.28
    }, {
        "symbol": "WEN",
        "label": "Wendy's Company (The)",
        "price": 21.69
    }, {
        "symbol": "VNQ",
        "label": "Vanguard Real Estate",
        "price": 94.72
    }, {
        "symbol": "GPS",
        "label": "Gap Inc. (The)",
        "price": 18.53
    }, {
        "symbol": "SYY",
        "label": "Sysco Corporation",
        "price": 82.82
    }, {
        "symbol": "CEF",
        "label": "Sprott Physical Gold and Silver Trust Units",
        "price": 12.76
    }, {
        "symbol": "WDR",
        "label": "Waddell & Reed Financial Inc.",
        "price": 16.83
    }, {
        "symbol": "FTV",
        "label": "Fortive Corporation",
        "price": 78.78
    }, {
        "symbol": "BKI",
        "label": "Black Knight Inc.",
        "price": 65.86
    }, {
        "symbol": "CTSH",
        "label": "Cognizant Technology Solutions Corporation",
        "price": 62.77
    }, {
        "symbol": "HOLX",
        "label": "Hologic Inc.",
        "price": 54.32
    }, {
        "symbol": "EIX",
        "label": "Edison International",
        "price": 76.96
    }, {
        "symbol": "URA",
        "label": "Global X Uranium",
        "price": 11.98
    }, {
        "symbol": "CTXS",
        "label": "Citrix Systems Inc.",
        "price": 117.22
    }, {
        "symbol": "AMTD",
        "label": "TD Ameritrade Holding Corporation",
        "price": 50.53
    }, {
        "symbol": "CPE",
        "label": "Callon Petroleum Company",
        "price": 3.86
    }, {
        "symbol": "XME",
        "label": "SPDR S&P Metals & Mining",
        "price": 27.28
    }, {
        "symbol": "CF",
        "label": "CF Industries Holdings Inc.",
        "price": 46.14
    }, {
        "symbol": "PNC",
        "label": "PNC Financial Services Group Inc. (The)",
        "price": 153.36
    }, {
        "symbol": "SABR",
        "label": "Sabre Corporation",
        "price": 23.115
    }, {
        "symbol": "WIT",
        "label": "Wipro Limited",
        "price": 3.83
    }, {
        "symbol": "LYB",
        "label": "LyondellBasell Industries NV Class A (Netherlands)",
        "price": 92.0
    }, {
        "symbol": "DISH",
        "label": "DISH Network Corporation",
        "price": 36.79
    }, {
        "symbol": "NE",
        "label": "Noble Corporation (UK)",
        "price": 1.06
    }, {
        "symbol": "MGA",
        "label": "Magna International Inc.",
        "price": 54.84
    }, {
        "symbol": "AEO",
        "label": "American Eagle Outfitters Inc.",
        "price": 14.7
    }, {
        "symbol": "XHB",
        "label": "SPDR Series Trust Homebuilders",
        "price": 41.37
    }, {
        "symbol": "BP",
        "label": "BP p.l.c.",
        "price": 38.77
    }, {
        "symbol": "XEL",
        "label": "Xcel Energy Inc.",
        "price": 65.37
    }, {
        "symbol": "NTNX",
        "label": "Nutanix Inc.",
        "price": 35.14
    }, {
        "symbol": "VVV",
        "label": "Valvoline Inc.",
        "price": 21.33
    }, {
        "symbol": "DDR",
        "label": "DDR Corp.",
        "price": 11.99
    }, {
        "symbol": "HQY",
        "label": "HealthEquity Inc.",
        "price": 73.02
    }, {
        "symbol": "ARCC",
        "label": "Ares Capital Corporation",
        "price": 18.96
    }, {
        "symbol": "UPS",
        "label": "United Parcel Service Inc.",
        "price": 118.6
    }, {
        "symbol": "MUR",
        "label": "Murphy Oil Corporation",
        "price": 25.33
    }, {
        "symbol": "OMAM",
        "label": "OM Asset Management plc",
        "price": 15.36
    }, {
        "symbol": "VMC",
        "label": "Vulcan Materials Company (Holding Company)",
        "price": 141.11
    }, {
        "symbol": "SDS",
        "label": "ProShares UltraShort S&P500",
        "price": 31.31
    }, {
        "symbol": "NOMD",
        "label": "Nomad Foods Limited",
        "price": 20.77
    }, {
        "symbol": "ANTM",
        "label": "Anthem Inc.",
        "price": 305.39
    }, {
        "symbol": "PAY",
        "label": "Verifone Systems Inc.",
        "price": 23.04
    }, {
        "symbol": "SFIX",
        "label": "Stitch Fix Inc.",
        "price": 25.22
    }, {
        "symbol": "CCE",
        "label": "Coca-Cola European Partners plc",
        "price": 46.73
    }, {
        "symbol": "GD",
        "label": "General Dynamics Corporation",
        "price": 181.89
    }, {
        "symbol": "KIM",
        "label": "Kimco Realty Corporation",
        "price": 20.27
    }, {
        "symbol": "FNF",
        "label": "FNF Group of Fidelity National Financial Inc.",
        "price": 47.31
    }, {
        "symbol": "BXMT",
        "label": "Blackstone Mortgage Trust Inc.",
        "price": 37.65
    }, {
        "symbol": "NWS",
        "label": "News Corporation",
        "price": 15.23
    }, {
        "symbol": "CHD",
        "label": "Church & Dwight Company Inc.",
        "price": 72.0
    }, {
        "symbol": "XLY",
        "label": "SPDR Select Sector Fund - Consumer Discretionary",
        "price": 127.99
    }, {
        "symbol": "DLR",
        "label": "Digital Realty Trust Inc.",
        "price": 124.44
    }, {
        "symbol": "IP",
        "label": "International Paper Company",
        "price": 45.75
    }, {
        "symbol": "CLI",
        "label": "Mack-Cali Realty Corporation",
        "price": 23.56
    }, {
        "symbol": "SE",
        "label": "Sea Limited American Depositary Shares each representing one Class A",
        "price": 41.28
    }, {
        "symbol": "CC",
        "label": "Chemours Company (The)",
        "price": 17.46
    }, {
        "symbol": "DAN",
        "label": "Dana Incorporated",
        "price": 16.9
    }, {
        "symbol": "ZBH",
        "label": "Zimmer Biomet Holdings Inc.",
        "price": 149.38
    }, {
        "symbol": "NVAX",
        "label": "Novavax Inc.",
        "price": 5.74
    }, {
        "symbol": "LPX",
        "label": "Louisiana-Pacific Corporation",
        "price": 31.88
    }, {
        "symbol": "ALDR",
        "label": "Alder BioPharmaceuticals Inc.",
        "price": 11.71
    }, {
        "symbol": "BBY",
        "label": "Best Buy Co. Inc.",
        "price": 89.94
    }, {
        "symbol": "GRUB",
        "label": "GrubHub Inc.",
        "price": 55.98
    }, {
        "symbol": "BG",
        "label": "Bunge Limited",
        "price": 56.06
    }, {
        "symbol": "LC",
        "label": "LendingClub Corporation",
        "price": 12.72
    }, {
        "symbol": "PWR",
        "label": "Quanta Services Inc.",
        "price": 41.26
    }, {
        "symbol": "TNDM",
        "label": "Tandem Diabetes Care Inc.",
        "price": 68.59
    }, {
        "symbol": "DXCM",
        "label": "DexCom Inc.",
        "price": 230.79
    }, {
        "symbol": "NTCT",
        "label": "NetScout Systems Inc.",
        "price": 25.62
    }, {
        "symbol": "RDC",
        "label": "Rowan Companies plc Class A",
        "price": 10.94
    }, {
        "symbol": "NYMT",
        "label": "New York Mortgage Trust Inc.",
        "price": 6.29
    }, {
        "symbol": "NEM",
        "label": "Newmont Mining Corporation",
        "price": 43.48
    }, {
        "symbol": "BEN",
        "label": "Franklin Resources Inc.",
        "price": 25.74
    }, {
        "symbol": "LUK",
        "label": "Leucadia National Corporation",
        "price": 23.47
    }, {
        "symbol": "SHLX",
        "label": "Shell Midstream Partners L.P. representing Limited Partner Interests",
        "price": 22.23
    }, {
        "symbol": "CIVI",
        "label": "Civitas Solutions Inc.",
        "price": 17.76
    }, {
        "symbol": "NAVI",
        "label": "Navient Corporation",
        "price": 13.86
    }, {
        "symbol": "AET",
        "label": "Aetna Inc.",
        "price": 212.3
    }, {
        "symbol": "PBH",
        "label": "Prestige Brand Holdings Inc.",
        "price": 42.2
    }, {
        "symbol": "SBH",
        "label": "Sally Beauty Holdings Inc. (Name to be changed from Holdings Inc.)",
        "price": 15.28
    }, {
        "symbol": "SEP",
        "label": "Spectra Energy Partners LP representing Limited Partner Interests",
        "price": 35.45
    }, {
        "symbol": "NI",
        "label": "NiSource Inc",
        "price": 29.04
    }, {
        "symbol": "LLY",
        "label": "Eli Lilly and Company",
        "price": 139.58
    }, {
        "symbol": "STNG",
        "label": "Scorpio Tankers Inc.",
        "price": 32.53
    }, {
        "symbol": "TTWO",
        "label": "Take-Two Interactive Software Inc.",
        "price": 130.01
    }, {
        "symbol": "KLAC",
        "label": "KLA-Tencor Corporation",
        "price": 178.73
    }, {
        "symbol": "LADR",
        "label": "Ladder Capital Corp Class A",
        "price": 18.61
    }, {
        "symbol": "XRT",
        "label": "SPDR S&P Retail",
        "price": 42.4
    }, {
        "symbol": "FHN",
        "label": "First Horizon National Corporation",
        "price": 17.27
    }, {
        "symbol": "GOOG",
        "label": "Alphabet Inc.",
        "price": 1480.39
    }, {
        "symbol": "DVMT",
        "label": "Dell Technologies Inc. Class V",
        "price": 79.77
    }, {
        "symbol": "HSIC",
        "label": "Henry Schein Inc.",
        "price": 69.29
    }, {
        "symbol": "CXO",
        "label": "Concho Resources Inc.",
        "price": 90.1
    }, {
        "symbol": "HLX",
        "label": "Helix Energy Solutions Group Inc.",
        "price": 8.91
    }, {
        "symbol": "RSG",
        "label": "Republic Services Inc.",
        "price": 93.46
    }, {
        "symbol": "BIG",
        "label": "Big Lots Inc.",
        "price": 28.3
    }, {
        "symbol": "CYH",
        "label": "Community Health Systems Inc.",
        "price": 3.43
    }, {
        "symbol": "PAYC",
        "label": "Paycom Software Inc.",
        "price": 296.24
    }, {
        "symbol": "GCP",
        "label": "GCP Applied Technologies Inc.",
        "price": 23.74
    }, {
        "symbol": "YUM",
        "label": "Yum! Brands Inc.",
        "price": 105.4
    }, {
        "symbol": "STZ",
        "label": "Constellation Brands Inc.",
        "price": 189.95
    }, {
        "symbol": "EWH",
        "label": "iShares MSCI Hong Kong Index Fund",
        "price": 25.62
    }, {
        "symbol": "LBTYK",
        "label": "Liberty Global plc",
        "price": 20.355
    }, {
        "symbol": "LYG",
        "label": "Lloyds Banking Group Plc American Depositary Shares",
        "price": 3.03
    }, {
        "symbol": "MEOH",
        "label": "Methanex Corporation",
        "price": 40.43
    }, {
        "symbol": "GIII",
        "label": "G-III Apparel Group LTD.",
        "price": 30.49
    }, {
        "symbol": "USFD",
        "label": "US Foods Holding Corp.",
        "price": 41.37
    }, {
        "symbol": "SSO",
        "label": "ProShares Ultra S&P500",
        "price": 124.39
    }, {
        "symbol": "SFM",
        "label": "Sprouts Farmers Market Inc.",
        "price": 17.59
    }, {
        "symbol": "SPXL",
        "label": "Direxion Daily S&P 500 Bull 3X Shares",
        "price": 50.52
    }, {
        "symbol": "THC",
        "label": "Tenet Healthcare Corporation",
        "price": 37.14
    }, {
        "symbol": "DOC",
        "label": "Physicians Realty Trust of Beneficial Interest",
        "price": 19.22
    }, {
        "symbol": "ALLY",
        "label": "Ally Financial Inc.",
        "price": 30.45
    }, {
        "symbol": "QRVO",
        "label": "Qorvo Inc.",
        "price": 114.74
    }, {
        "symbol": "TOPS",
        "label": "TOP Ships Inc.",
        "price": 0.9451
    }, {
        "symbol": "AVP",
        "label": "Avon Products Inc.",
        "price": 5.6
    }, {
        "symbol": "HEDJ",
        "label": "WisdomTree Europe Hedged Equity Fund",
        "price": 71.56
    }, {
        "symbol": "EVR",
        "label": "Evercore Inc. Class A",
        "price": 77.2
    }, {
        "symbol": "CMS",
        "label": "CMS Energy Corporation",
        "price": 65.65
    }, {
        "symbol": "XLB",
        "label": "Materials Select Sector SPDR",
        "price": 61.14
    }, {
        "symbol": "DOX",
        "label": "Amdocs Limited",
        "price": 74.37
    }, {
        "symbol": "RIO",
        "label": "Rio Tinto Plc",
        "price": 60.67
    }, {
        "symbol": "HMHC",
        "label": "Houghton Mifflin Harcourt Company",
        "price": 6.35
    }, {
        "symbol": "NMRK",
        "label": "Newmark Group Inc.",
        "price": 12.19
    }, {
        "symbol": "PDM",
        "label": "Piedmont Office Realty Trust Inc. Class A",
        "price": 23.27
    }, {
        "symbol": "KMT",
        "label": "Kennametal Inc.",
        "price": 36.32
    }, {
        "symbol": "SRNE",
        "label": "Sorrento Therapeutics Inc.",
        "price": 4.26
    }, {
        "symbol": "AMR",
        "label": "Alta Mesa Resources Inc.",
        "price": 0.17
    }, {
        "symbol": "HP",
        "label": "Helmerich & Payne Inc.",
        "price": 45.13
    }, {
        "symbol": "FLR",
        "label": "Fluor Corporation",
        "price": 20.47
    }, {
        "symbol": "BFR",
        "label": "BBVA Banco Frances S.A.",
        "price": 11.06
    }, {
        "symbol": "JHG",
        "label": "Janus Henderson Group plc",
        "price": 25.15
    }, {
        "symbol": "FDX",
        "label": "FedEx Corporation",
        "price": 159.98
    }, {
        "symbol": "EMN",
        "label": "Eastman Chemical Company",
        "price": 77.45
    }, {
        "symbol": "OPK",
        "label": "Opko Health Inc.",
        "price": 1.56
    }, {
        "symbol": "LNG",
        "label": "Cheniere Energy Inc.",
        "price": 66.0
    }, {
        "symbol": "EWZ",
        "label": "iShares Inc MSCI Brazil",
        "price": 42.81
    }, {
        "symbol": "WU",
        "label": "Western Union Company (The)",
        "price": 27.77
    }, {
        "symbol": "BAX",
        "label": "Baxter International Inc.",
        "price": 89.73
    }, {
        "symbol": "AOBC",
        "label": "American Outdoor Brands Corporation",
        "price": 9.44
    }, {
        "symbol": "CARS",
        "label": "Cars.com Inc.",
        "price": 12.96
    }, {
        "symbol": "NUAN",
        "label": "Nuance Communications Inc.",
        "price": 19.73
    }, {
        "symbol": "TEL",
        "label": "TE Connectivity Ltd. New Switzerland Registered Shares",
        "price": 100.18
    }, {
        "symbol": "MLM",
        "label": "Martin Marietta Materials Inc.",
        "price": 266.74
    }, {
        "symbol": "GWW",
        "label": "W.W. Grainger Inc.",
        "price": 340.15
    }, {
        "symbol": "PBF",
        "label": "PBF Energy Inc. Class A",
        "price": 31.01
    }, {
        "symbol": "AGN",
        "label": "Allergan plc",
        "price": 192.68
    }, {
        "symbol": "GT",
        "label": "The Goodyear Tire & Rubber Company",
        "price": 14.93
    }, {
        "symbol": "EA",
        "label": "Electronic Arts Inc.",
        "price": 112.93
    }, {
        "symbol": "GPRO",
        "label": "GoPro Inc.",
        "price": 4.29
    }, {
        "symbol": "BW",
        "label": "Babcock & Wilcox Enterprises Inc.",
        "price": 4.98
    }, {
        "symbol": "CMI",
        "label": "Cummins Inc.",
        "price": 173.8
    }, {
        "symbol": "CNQ",
        "label": "Canadian Natural Resources Limited",
        "price": 31.71
    }, {
        "symbol": "HCA",
        "label": "HCA Healthcare Inc.",
        "price": 148.4
    }, {
        "symbol": "INXN",
        "label": "InterXion Holding N.V. (0.01 nominal value)",
        "price": 89.64
    }, {
        "symbol": "VRTX",
        "label": "Vertex Pharmaceuticals Incorporated",
        "price": 235.83
    }, {
        "symbol": "QTS",
        "label": "QTS Realty Trust Inc. Class A",
        "price": 55.35
    }, {
        "symbol": "SAN",
        "label": "Banco Santander S.A. Sponsored ADR (Spain)",
        "price": 4.08
    }, {
        "symbol": "CNHI",
        "label": "CNH Industrial N.V.",
        "price": 11.07
    }, {
        "symbol": "CNX",
        "label": "CNX Resources Corporation",
        "price": 7.81
    }, {
        "symbol": "INFO",
        "label": "IHS Markit Ltd.",
        "price": 79.27
    }, {
        "symbol": "YY",
        "label": "YY Inc.",
        "price": 66.09
    }, {
        "symbol": "ES",
        "label": "Eversource Energy (D/B/A)",
        "price": 88.73
    }, {
        "symbol": "DXJ",
        "label": "WisdomTree Japan Hedged Equity Fund",
        "price": 48.76
    }, {
        "symbol": "FWONK",
        "label": "Liberty Media Corporation Series C",
        "price": 48.05
    }, {
        "symbol": "ARMK",
        "label": "Aramark",
        "price": 46.42
    }, {
        "symbol": "OII",
        "label": "Oceaneering International Inc.",
        "price": 14.77
    }, {
        "symbol": "NOW",
        "label": "ServiceNow Inc.",
        "price": 312.52
    }, {
        "symbol": "ILG",
        "label": "ILG Inc",
        "price": 34.17
    }, {
        "symbol": "APTV",
        "label": "Aptiv PLC",
        "price": 91.53
    }, {
        "symbol": "HOMB",
        "label": "Home BancShares Inc.",
        "price": 20.43
    }, {
        "symbol": "QD",
        "label": "Qudian Inc. American Depositary Shares each representing one Class A",
        "price": 3.52
    }, {
        "symbol": "NSC",
        "label": "Norfolk Southern Corporation",
        "price": 209.32
    }, {
        "symbol": "NKTR",
        "label": "Nektar Therapeutics",
        "price": 22.48
    }, {
        "symbol": "PSX",
        "label": "Phillips 66",
        "price": 103.31
    }, {
        "symbol": "GAB",
        "label": "Gabelli Equity Trust Inc. (The)",
        "price": 6.21
    }, {
        "symbol": "WIN",
        "label": "Windstream Holdings Inc.",
        "price": 0.34
    }, {
        "symbol": "CERN",
        "label": "Cerner Corporation",
        "price": 75.86
    }, {
        "symbol": "UPRO",
        "label": "ProShares UltraPro S&P 500",
        "price": 53.18
    }, {
        "symbol": "MCHI",
        "label": "iShares MSCI China ETF",
        "price": 67.44
    }, {
        "symbol": "AKAM",
        "label": "Akamai Technologies Inc.",
        "price": 95.31
    }, {
        "symbol": "IEF",
        "label": "iShares 7-10 Year Treasury Bond ETF",
        "price": 111.3
    }, {
        "symbol": "CS",
        "label": "Credit Suisse Group American Depositary Shares",
        "price": 13.87
    }, {
        "symbol": "ARRS",
        "label": "ARRIS International plc",
        "price": 31.64
    }, {
        "symbol": "PNW",
        "label": "Pinnacle West Capital Corporation",
        "price": 93.34
    }, {
        "symbol": "AMH",
        "label": "American Homes 4 Rent of Beneficial Interest",
        "price": 26.95
    }, {
        "symbol": "CDNS",
        "label": "Cadence Design Systems Inc.",
        "price": 73.73
    }, {
        "symbol": "LABD",
        "label": "Direxion Daily S&P Biotech Bear 3X Shares",
        "price": 18.9
    }, {
        "symbol": "FOE",
        "label": "Ferro Corporation",
        "price": 14.99
    }, {
        "symbol": "A",
        "label": "Agilent Technologies Inc.",
        "price": 90.12
    }, {
        "symbol": "MDRX",
        "label": "Allscripts Healthcare Solutions Inc.",
        "price": 9.7
    }, {
        "symbol": "MOS",
        "label": "Mosaic Company (The)",
        "price": 21.87
    }, {
        "symbol": "CTB",
        "label": "Cooper Tire & Rubber Company",
        "price": 28.74
    }, {
        "symbol": "LPL",
        "label": "LG Display Co Ltd AMERICAN DEPOSITORY SHARES",
        "price": 6.83
    }, {
        "symbol": "SKX",
        "label": "Skechers U.S.A. Inc.",
        "price": 41.9
    }, {
        "symbol": "HST",
        "label": "Host Hotels & Resorts Inc.",
        "price": 17.88
    }, {
        "symbol": "UE",
        "label": "Urban Edge Properties of Beneficial Interest",
        "price": 19.44
    }, {
        "symbol": "PHM",
        "label": "PulteGroup Inc.",
        "price": 41.79
    }, {
        "symbol": "MT",
        "label": "Arcelor Mittal NY Registry Shares NEW",
        "price": 16.23
    }, {
        "symbol": "ISBC",
        "label": "Investors Bancorp Inc.",
        "price": 11.78
    }, {
        "symbol": "KLDX",
        "label": "Klondex Mines Ltd.",
        "price": 2.2
    }, {
        "symbol": "CAAP",
        "label": "Corporacion America Airports SA",
        "price": 5.94
    }, {
        "symbol": "WRE",
        "label": "Washington Real Estate Investment Trust",
        "price": 30.64
    }, {
        "symbol": "RRC",
        "label": "Range Resources Corporation",
        "price": 3.96
    }, {
        "symbol": "FL",
        "label": "Foot Locker Inc.",
        "price": 39.6
    }, {
        "symbol": "NTAP",
        "label": "NetApp Inc.",
        "price": 62.42
    }, {
        "symbol": "AGO",
        "label": "Assured Guaranty Ltd.",
        "price": 49.27
    }, {
        "symbol": "DRE",
        "label": "Duke Realty Corporation",
        "price": 35.67
    }, {
        "symbol": "BBBY",
        "label": "Bed Bath & Beyond Inc.",
        "price": 15.39
    }, {
        "symbol": "CCJ",
        "label": "Cameco Corporation",
        "price": 8.88
    }, {
        "symbol": "MAR",
        "label": "Marriott International",
        "price": 150.26
    }, {
        "symbol": "AXTA",
        "label": "Axalta Coating Systems Ltd.",
        "price": 30.32
    }, {
        "symbol": "VIAV",
        "label": "Viavi Solutions Inc.",
        "price": 15.68
    }, {
        "symbol": "SHOO",
        "label": "Steven Madden Ltd.",
        "price": 42.36
    }, {
        "symbol": "CONE",
        "label": "CyrusOne Inc",
        "price": 63.49
    }, {
        "symbol": "LOGM",
        "label": "LogMeIn Inc.",
        "price": 86.55
    }, {
        "symbol": "AEM",
        "label": "Agnico Eagle Mines Limited",
        "price": 60.35
    }, {
        "symbol": "BB",
        "label": "BlackBerry Limited",
        "price": 6.73
    }, {
        "symbol": "CB",
        "label": "Chubb Limited",
        "price": 153.69
    }, {
        "symbol": "PRU",
        "label": "Prudential Financial Inc.",
        "price": 97.1
    }, {
        "symbol": "RUN",
        "label": "Sunrun Inc.",
        "price": 17.18
    }, {
        "symbol": "MNK",
        "label": "Mallinckrodt plc",
        "price": 5.2
    }, {
        "symbol": "PVG",
        "label": "Pretium Resources Inc. (Canada)",
        "price": 10.48
    }, {
        "symbol": "HA",
        "label": "Hawaiian Holdings Inc.",
        "price": 30.05
    }, {
        "symbol": "INDA",
        "label": "Ishares MSCI India",
        "price": 35.0
    }, {
        "symbol": "PBCT",
        "label": "People's United Financial Inc.",
        "price": 16.05
    }, {
        "symbol": "UCBI",
        "label": "United Community Banks Inc.",
        "price": 30.33
    }, {
        "symbol": "LBTYA",
        "label": "Liberty Global plc Class A Ordinary Shares",
        "price": 21.15
    }, {
        "symbol": "AVYA",
        "label": "Avaya Holdings Corp.",
        "price": 12.97
    }, {
        "symbol": "CBSH",
        "label": "Commerce Bancshares Inc.",
        "price": 67.61
    }, {
        "symbol": "SC",
        "label": "Santander Consumer USA Holdings Inc.",
        "price": 23.11
    }, {
        "symbol": "ANF",
        "label": "Abercrombie & Fitch Company",
        "price": 17.79
    }, {
        "symbol": "HIG",
        "label": "Hartford Financial Services Group Inc. (The)",
        "price": 61.22
    }, {
        "symbol": "ITW",
        "label": "Illinois Tool Works Inc.",
        "price": 180.43
    }, {
        "symbol": "AZN",
        "label": "Astrazeneca PLC",
        "price": 51.33
    }, {
        "symbol": "YELP",
        "label": "Yelp Inc.",
        "price": 36.51
    }, {
        "symbol": "MTOR",
        "label": "Meritor Inc.",
        "price": 25.06
    }, {
        "symbol": "ARRY",
        "label": "Array BioPharma Inc.",
        "price": 46.57
    }, {
        "symbol": "EVC",
        "label": "Entravision Communications Corporation",
        "price": 2.27
    }, {
        "symbol": "MITL",
        "label": "Mitel Networks Corporation",
        "price": 11.15
    }, {
        "symbol": "SPXS",
        "label": "Direxion Daily S&P 500 Bear 3X",
        "price": 18.89
    }, {
        "symbol": "IRM",
        "label": "Iron Mountain Incorporated (Delaware)Common Stock REIT",
        "price": 31.38
    }, {
        "symbol": "TSCO",
        "label": "Tractor Supply Company",
        "price": 92.79
    }, {
        "symbol": "EWC",
        "label": "iShares MSCI Canada Index Fund",
        "price": 30.58
    }, {
        "symbol": "NWY",
        "label": "New York & Company Inc.",
        "price": 3.5
    }, {
        "symbol": "POST",
        "label": "Post Holdings Inc.",
        "price": 112.06
    }, {
        "symbol": "EL",
        "label": "Estee Lauder Companies Inc. (The)",
        "price": 219.88
    }, {
        "symbol": "ING",
        "label": "ING Group N.V.",
        "price": 11.81
    }, {
        "symbol": "UBS",
        "label": "UBS Group AG Registered",
        "price": 13.14
    }, {
        "symbol": "KRG",
        "label": "Kite Realty Group Trust",
        "price": 18.25
    }, {
        "symbol": "VSH",
        "label": "Vishay Intertechnology Inc.",
        "price": 23.09
    }, {
        "symbol": "TER",
        "label": "Teradyne Inc.",
        "price": 70.29
    }, {
        "symbol": "EMB",
        "label": "iShares J.P. Morgan USD Emerging Markets Bond ETF",
        "price": 115.21
    }, {
        "symbol": "LHO",
        "label": "LaSalle Hotel Properties",
        "price": 32.06
    }, {
        "symbol": "SPSB",
        "label": "SPDR Portfolio Short Term Corporate Bond",
        "price": 30.86
    }, {
        "symbol": "TWLO",
        "label": "Twilio Inc. Class A",
        "price": 119.36
    }, {
        "symbol": "TRUE",
        "label": "TrueCar Inc.",
        "price": 4.36
    }, {
        "symbol": "RLGY",
        "label": "Realogy Holdings Corp.",
        "price": 10.0
    }, {
        "symbol": "HPP",
        "label": "Hudson Pacific Properties Inc.",
        "price": 37.08
    }, {
        "symbol": "CNO",
        "label": "CNO Financial Group Inc.",
        "price": 18.27
    }, {
        "symbol": "JNPR",
        "label": "Juniper Networks Inc.",
        "price": 24.7
    }, {
        "symbol": "SMH",
        "label": "VanEck Vectors Semiconductor",
        "price": 146.06
    }, {
        "symbol": "URBN",
        "label": "Urban Outfitters Inc.",
        "price": 26.93
    }, {
        "symbol": "ROKU",
        "label": "Roku Inc.",
        "price": 130.38
    }, {
        "symbol": "FEDU",
        "label": "Four Seasons Education (Cayman) Inc. American Depositary Shares each two ADSs re" +
                "presenting one",
        "price": 1.76
    }, {
        "symbol": "ETM",
        "label": "Entercom Communications Corporation",
        "price": 4.62
    }, {
        "symbol": "BANC",
        "label": "Banc of California Inc.",
        "price": 17.45
    }, {
        "symbol": "INVH",
        "label": "Invitation Homes Inc.",
        "price": 30.49
    }, {
        "symbol": "OMF",
        "label": "OneMain Holdings Inc.",
        "price": 43.19
    }, {
        "symbol": "LW",
        "label": "Lamb Weston Holdings Inc.",
        "price": 90.5
    }, {
        "symbol": "CTRE",
        "label": "CareTrust REIT Inc.",
        "price": 21.31
    }, {
        "symbol": "NHI",
        "label": "National Health Investors Inc.",
        "price": 83.9
    }, {
        "symbol": "CBL",
        "label": "CBL & Associates Properties Inc.",
        "price": 1.04
    }, {
        "symbol": "FE",
        "label": "FirstEnergy Corporation",
        "price": 49.06
    }, {
        "symbol": "NOC",
        "label": "Northrop Grumman Corporation",
        "price": 379.97
    }, {
        "symbol": "ASH",
        "label": "Ashland Global Holdings Inc.",
        "price": 76.47
    }, {
        "symbol": "MCHP",
        "label": "Microchip Technology Incorporated",
        "price": 109.41
    }, {
        "symbol": "KLIC",
        "label": "Kulicke and Soffa Industries Inc.",
        "price": 27.63
    }, {
        "symbol": "O",
        "label": "Realty Income Corporation",
        "price": 76.35
    }, {
        "symbol": "TAHO",
        "label": "Tahoe Resources Inc. (Canada)",
        "price": 3.65
    }, {
        "symbol": "XLV",
        "label": "SPDR Select Sector Fund - Health Care",
        "price": 92.43
    }, {
        "symbol": "FET",
        "label": "Forum Energy Technologies Inc.",
        "price": 1.45
    }, {
        "symbol": "TPH",
        "label": "TRI Pointe Group Inc.",
        "price": 16.3
    }, {
        "symbol": "AMX",
        "label": "America Movil S.A.B. de C.V.n Depository Receipt Series L",
        "price": 16.53
    }, {
        "symbol": "FLO",
        "label": "Flowers Foods Inc.",
        "price": 22.11
    }, {
        "symbol": "SPN",
        "label": "Superior Energy Services Inc.",
        "price": 5.19
    }, {
        "symbol": "SHO",
        "label": "Sunstone Hotel Investors Inc.",
        "price": 13.6
    }, {
        "symbol": "TEAM",
        "label": "Atlassian Corporation Plc",
        "price": 132.85
    }, {
        "symbol": "COF",
        "label": "Capital One Financial Corporation",
        "price": 104.13
    }, {
        "symbol": "PFPT",
        "label": "Proofpoint Inc.",
        "price": 123.26
    }, {
        "symbol": "EWL",
        "label": "iShares Inc MSCI Switzerland",
        "price": 41.59
    }, {
        "symbol": "FLT",
        "label": "FleetCor Technologies Inc.",
        "price": 310.04
    }, {
        "symbol": "PLUG",
        "label": "Plug Power Inc.",
        "price": 4.17
    }, {
        "symbol": "HIMX",
        "label": "Himax Technologies Inc.",
        "price": 3.75
    }, {
        "symbol": "IGT",
        "label": "International Game Technology",
        "price": 15.03
    }, {
        "symbol": "IBB",
        "label": "iShares Nasdaq Biotechnology Index Fund",
        "price": 121.89
    }, {
        "symbol": "FANG",
        "label": "Diamondback Energy Inc.",
        "price": 89.54
    }, {
        "symbol": "XLRE",
        "label": "Real Estate Select Sector SPDR Fund (The)",
        "price": 39.59
    }, {
        "symbol": "HRG",
        "label": "HRG Group Inc.",
        "price": 13.42
    }, {
        "symbol": "TROW",
        "label": "T. Rowe Price Group Inc.",
        "price": 132.26
    }, {
        "symbol": "SN",
        "label": "Sanchez Energy Corporation",
        "price": 0.38
    }, {
        "symbol": "ARLP",
        "label": "Alliance Resource Partners L.P.",
        "price": 11.0
    }, {
        "symbol": "RXN",
        "label": "Rexnord Corporation",
        "price": 33.52
    }, {
        "symbol": "OSK",
        "label": "Oshkosh Corporation (Holding Company)Common Stock",
        "price": 91.77
    }, {
        "symbol": "DHR",
        "label": "Danaher Corporation",
        "price": 163.06
    }, {
        "symbol": "EVHC",
        "label": "Envision Healthcare Corporation",
        "price": 45.99
    }, {
        "symbol": "BLMN",
        "label": "Bloomin' Brands Inc.",
        "price": 22.14
    }, {
        "symbol": "UPL",
        "label": "Ultra Petroleum Corp.",
        "price": 0.35
    }, {
        "symbol": "ORBK",
        "label": "Orbotech Ltd.",
        "price": 65.82
    }, {
        "symbol": "BNDX",
        "label": "Vanguard Total International Bond ETF",
        "price": 56.81
    }, {
        "symbol": "AMN",
        "label": "AMN Healthcare Services Inc",
        "price": 66.85
    }, {
        "symbol": "PPC",
        "label": "Pilgrim's Pride Corporation",
        "price": 29.5
    }, {
        "symbol": "GNTX",
        "label": "Gentex Corporation",
        "price": 30.98
    }, {
        "symbol": "RCL",
        "label": "Royal Caribbean Cruises Ltd.",
        "price": 135.05
    }, {
        "symbol": "INTU",
        "label": "Intuit Inc.",
        "price": 282.85
    }, {
        "symbol": "W",
        "label": "Wayfair Inc. Class A",
        "price": 106.79
    }, {
        "symbol": "PCAR",
        "label": "PACCAR Inc.",
        "price": 78.52
    }, {
        "symbol": "IR",
        "label": "Ingersoll-Rand plc (Ireland)",
        "price": 133.92
    }, {
        "symbol": "PEG",
        "label": "Public Service Enterprise Group Incorporated",
        "price": 59.16
    }, {
        "symbol": "FBP",
        "label": "First BanCorp.",
        "price": 10.42
    }, {
        "symbol": "WEC",
        "label": "WEC Energy Group Inc.",
        "price": 96.42
    }, {
        "symbol": "MDC",
        "label": "M.D.C. Holdings Inc.",
        "price": 42.45
    }, {
        "symbol": "TISI",
        "label": "Team Inc.",
        "price": 15.85
    }, {
        "symbol": "YUMC",
        "label": "Yum China Holdings Inc.",
        "price": 49.85
    }, {
        "symbol": "HMSY",
        "label": "HMS Holdings Corp",
        "price": 28.73
    }, {
        "symbol": "IMMU",
        "label": "Immunomedics Inc.",
        "price": 19.005
    }, {
        "symbol": "ALV",
        "label": "Autoliv Inc.",
        "price": 81.24
    }, {
        "symbol": "IWF",
        "label": "iShares Russell 1000 Growth",
        "price": 184.57
    }, {
        "symbol": "BHP",
        "label": "BHP Billiton Limited",
        "price": 56.48
    }, {
        "symbol": "BXP",
        "label": "Boston Properties Inc.",
        "price": 139.56
    }, {
        "symbol": "BYD",
        "label": "Boyd Gaming Corporation",
        "price": 29.26
    }, {
        "symbol": "WGO",
        "label": "Winnebago Industries Inc.",
        "price": 55.58
    }, {
        "symbol": "KSU",
        "label": "Kansas City Southern",
        "price": 166.52
    }, {
        "symbol": "PUMP",
        "label": "ProPetro Holding Corp.",
        "price": 11.17
    }, {
        "symbol": "XLNX",
        "label": "Xilinx Inc.",
        "price": 102.89
    }, {
        "symbol": "NOG",
        "label": "Northern Oil and Gas Inc.",
        "price": 1.89
    }, {
        "symbol": "GLOG",
        "label": "GasLog Ltd.",
        "price": 9.17
    }, {
        "symbol": "FOLD",
        "label": "Amicus Therapeutics Inc.",
        "price": 10.41
    }, {
        "symbol": "JBHT",
        "label": "J.B. Hunt Transport Services Inc.",
        "price": 114.68
    }, {
        "symbol": "AGG",
        "label": "iShares Core U.S. Aggregate Bond",
        "price": 110.84
    }, {
        "symbol": "FBHS",
        "label": "Fortune Brands Home & Security Inc.",
        "price": 69.41
    }, {
        "symbol": "TXT",
        "label": "Textron Inc.",
        "price": 46.12
    }, {
        "symbol": "EXPD",
        "label": "Expeditors International of Washington Inc.",
        "price": 76.82
    }, {
        "symbol": "PDLI",
        "label": "PDL BioPharma Inc.",
        "price": 3.26
    }, {
        "symbol": "SHPG",
        "label": "Shire plc",
        "price": 179.33
    }, {
        "symbol": "EXPE",
        "label": "Expedia Inc.",
        "price": 111.82
    }, {
        "symbol": "TVPT",
        "label": "Travelport Worldwide Limited",
        "price": 15.75
    }, {
        "symbol": "WERN",
        "label": "Werner Enterprises Inc.",
        "price": 38.73
    }, {
        "symbol": "AGI",
        "label": "Alamos Gold Inc. Class A",
        "price": 5.61
    }, {
        "symbol": "ED",
        "label": "Consolidated Edison Inc.",
        "price": 90.24
    }, {
        "symbol": "HCSG",
        "label": "Healthcare Services Group Inc.",
        "price": 27.01
    }, {
        "symbol": "DVAX",
        "label": "Dynavax Technologies Corporation",
        "price": 6.03
    }, {
        "symbol": "DLTR",
        "label": "Dollar Tree Inc.",
        "price": 91.55
    }, {
        "symbol": "GNC",
        "label": "GNC Holdings Inc. Class A",
        "price": 2.32
    }, {
        "symbol": "VIXY",
        "label": "ProShares Trust VIX Short-Term Futures",
        "price": 22.09
    }, {
        "symbol": "OUT",
        "label": "OUTFRONT Media Inc.",
        "price": 28.85
    }, {
        "symbol": "MTSI",
        "label": "MACOM Technology Solutions Holdings Inc.",
        "price": 28.98
    }, {
        "symbol": "DBD",
        "label": "Diebold Nixdorf Incorporated",
        "price": 11.11
    }, {
        "symbol": "CBT",
        "label": "Cabot Corporation",
        "price": 44.8
    }, {
        "symbol": "STOR",
        "label": "STORE Capital Corporation",
        "price": 37.89
    }, {
        "symbol": "VEU",
        "label": "Vanguard FTSE All World Ex US",
        "price": 50.13
    }, {
        "symbol": "FBR",
        "label": "Fibria Celulose S.A.",
        "price": 17.0
    }, {
        "symbol": "BGCP",
        "label": "BGC Partners Inc.",
        "price": 5.95
    }, {
        "symbol": "VCIT",
        "label": "Vanguard Intermediate-Term Corporate Bond ETF",
        "price": 91.92
    }, {
        "symbol": "ABB",
        "label": "ABB Ltd",
        "price": 23.91
    }, {
        "symbol": "GTE",
        "label": "Gran Tierra Energy Inc.",
        "price": 1.17
    }, {
        "symbol": "SWKS",
        "label": "Skyworks Solutions Inc.",
        "price": 124.17
    }, {
        "symbol": "CP",
        "label": "Canadian Pacific Railway Limited",
        "price": 262.7
    }, {
        "symbol": "PNR",
        "label": "Pentair plc.",
        "price": 47.29
    }, {
        "symbol": "PKI",
        "label": "PerkinElmer Inc.",
        "price": 101.32
    }, {
        "symbol": "IIVI",
        "label": "II-VI Incorporated",
        "price": 37.52
    }, {
        "symbol": "CROX",
        "label": "Crocs Inc.",
        "price": 41.58
    }, {
        "symbol": "ASB",
        "label": "Associated Banc-Corp",
        "price": 21.12
    }, {
        "symbol": "UHS",
        "label": "Universal Health Services Inc.",
        "price": 145.03
    }, {
        "symbol": "ENB",
        "label": "Enbridge Inc",
        "price": 40.52
    }, {
        "symbol": "TDC",
        "label": "Teradata Corporation",
        "price": 26.37
    }, {
        "symbol": "AG",
        "label": "First Majestic Silver Corp. (Canada)",
        "price": 10.53
    }, {
        "symbol": "ACHC",
        "label": "Acadia Healthcare Company Inc.",
        "price": 33.48
    }, {
        "symbol": "NAT",
        "label": "Nordic American Tankers Limited",
        "price": 4.63
    }, {
        "symbol": "MLHR",
        "label": "Herman Miller Inc.",
        "price": 41.13
    }, {
        "symbol": "SPG",
        "label": "Simon Property Group Inc.",
        "price": 147.77
    }, {
        "symbol": "EWA",
        "label": "iShares MSCI Australia Index Fund",
        "price": 22.02
    }, {
        "symbol": "ENLK",
        "label": "EnLink Midstream Partners LP Representing Limited Partnership Interests",
        "price": 12.1
    }, {
        "symbol": "QRTEA",
        "label": "Liberty Interactive Corporation Series A QVC Group Common Stock",
        "price": 8.84
    }, {
        "symbol": "TRIP",
        "label": "TripAdvisor Inc.",
        "price": 30.93
    }, {
        "symbol": "TRP",
        "label": "TransCanada Corporation",
        "price": 54.27
    }, {
        "symbol": "FAST",
        "label": "Fastenal Company",
        "price": 36.8
    }, {
        "symbol": "RH",
        "label": "RH",
        "price": 222.39
    }, {
        "symbol": "IVV",
        "label": "iShares Core S&P 500",
        "price": 333.5
    }, {
        "symbol": "CBRE",
        "label": "CBRE Group Inc Class A",
        "price": 61.03
    }, {
        "symbol": "HGV",
        "label": "Hilton Grand Vacations Inc.",
        "price": 34.82
    }, {
        "symbol": "AEG",
        "label": "AEGON N.V.",
        "price": 4.37
    }, {
        "symbol": "BTI",
        "label": "British American Tobacco Industries p.l.c. ADR",
        "price": 45.11
    }, {
        "symbol": "VIAB",
        "label": "Viacom Inc.",
        "price": 24.22
    }, {
        "symbol": "EWP",
        "label": "iShares Inc MSCI Spain",
        "price": 28.66
    }, {
        "symbol": "NVS",
        "label": "Novartis AG",
        "price": 95.93
    }, {
        "symbol": "EWBC",
        "label": "East West Bancorp Inc.",
        "price": 48.08
    }, {
        "symbol": "QSR",
        "label": "Restaurant Brands International Inc.",
        "price": 64.13
    }, {
        "symbol": "NXTM",
        "label": "NxStage Medical Inc.",
        "price": 30.0
    }, {
        "symbol": "GSAT",
        "label": "Globalstar Inc.",
        "price": 0.4999
    }, {
        "symbol": "DBEU",
        "label": "Xtrackers MSCI Europe Hedged Equity",
        "price": 31.78
    }, {
        "symbol": "TTPH",
        "label": "Tetraphase Pharmaceuticals Inc.",
        "price": 2.92
    }, {
        "symbol": "VRAY",
        "label": "ViewRay Inc.",
        "price": 3.12
    }, {
        "symbol": "SGH",
        "label": "SMART Global Holdings Inc.",
        "price": 34.83
    }, {
        "symbol": "ALNY",
        "label": "Alnylam Pharmaceuticals Inc.",
        "price": 118.82
    }, {
        "symbol": "SCHB",
        "label": "Schwab U.S. Broad Market",
        "price": 79.28
    }, {
        "symbol": "BMRN",
        "label": "BioMarin Pharmaceutical Inc.",
        "price": 88.27
    }, {
        "symbol": "CA",
        "label": "CA Inc.",
        "price": 44.45
    }, {
        "symbol": "CASA",
        "label": "Casa Systems Inc.",
        "price": 3.86
    }, {
        "symbol": "CXP",
        "label": "Columbia Property Trust Inc.",
        "price": 22.11
    }, {
        "symbol": "SCZ",
        "label": "iShares MSCI EAFE Small-Cap ETF",
        "price": 62.4
    }, {
        "symbol": "AGNC",
        "label": "AGNC Investment Corp.",
        "price": 18.25
    }, {
        "symbol": "DF",
        "label": "Dean Foods Company",
        "price": 0.8
    }, {
        "symbol": "HRTX",
        "label": "Heron Therapeutics Inc.",
        "price": 21.55
    }, {
        "symbol": "LDOS",
        "label": "Leidos Holdings Inc.",
        "price": 103.39
    }, {
        "symbol": "VSLR",
        "label": "Vivint Solar Inc.",
        "price": 8.85
    }, {
        "symbol": "MPW",
        "label": "Medical Properties Trust Inc.",
        "price": 22.29
    }, {
        "symbol": "TXMD",
        "label": "TherapeuticsMD Inc.",
        "price": 2.31
    }, {
        "symbol": "CIM",
        "label": "Chimera Investment Corporation",
        "price": 21.13
    }, {
        "symbol": "ECYT",
        "label": "Endocyte Inc.",
        "price": 24.0
    }, {
        "symbol": "QCP",
        "label": "Quality Care Properties Inc.",
        "price": 20.74
    }, {
        "symbol": "NYLD",
        "label": "NRG Yield Inc. Class C",
        "price": 20.13
    }, {
        "symbol": "INSM",
        "label": "Insmed Inc.",
        "price": 21.46
    }, {
        "symbol": "ETH",
        "label": "Ethan Allen Interiors Inc.",
        "price": 17.94
    }, {
        "symbol": "KS",
        "label": "KapStone Paper and Packaging Corporation",
        "price": 34.97
    }, {
        "symbol": "NG",
        "label": "Novagold Resources Inc.",
        "price": 8.74
    }, {
        "symbol": "BGFV",
        "label": "Big 5 Sporting Goods Corporation",
        "price": 3.9
    }, {
        "symbol": "AEE",
        "label": "Ameren Corporation",
        "price": 79.75
    }, {
        "symbol": "HCC",
        "label": "Warrior Met Coal Inc.",
        "price": 22.51
    }, {
        "symbol": "LAMR",
        "label": "Lamar Advertising Company",
        "price": 92.08
    }, {
        "symbol": "FIT",
        "label": "Fitbit Inc. Class A",
        "price": 6.68
    }, {
        "symbol": "EXEL",
        "label": "Exelixis Inc.",
        "price": 19.82
    }, {
        "symbol": "HZNP",
        "label": "Horizon Pharma plc",
        "price": 38.4
    }, {
        "symbol": "ADI",
        "label": "Analog Devices Inc.",
        "price": 119.47
    }, {
        "symbol": "FLOT",
        "label": "iShares Floating Rate Bond",
        "price": 50.86
    }, {
        "symbol": "AKBA",
        "label": "Akebia Therapeutics Inc.",
        "price": 8.03
    }, {
        "symbol": "LPNT",
        "label": "LifePoint Health Inc.",
        "price": 65.0
    }, {
        "symbol": "OLED",
        "label": "Universal Display Corporation",
        "price": 212.85
    }, {
        "symbol": "EYE",
        "label": "National Vision Holdings Inc.",
        "price": 32.83
    }, {
        "symbol": "CBS",
        "label": "CBS Corporation Class B",
        "price": 0.0
    }, {
        "symbol": "MBT",
        "label": "Mobile TeleSystems PJSC",
        "price": 10.5
    }, {
        "symbol": "TMO",
        "label": "Thermo Fisher Scientific Inc",
        "price": 339.19
    }, {
        "symbol": "LBRDA",
        "label": "Liberty Broadband Corporation Class A Common Stock",
        "price": 128.64
    }, {
        "symbol": "HCLP",
        "label": "Hi-Crush Partners LP representing limited partner interests",
        "price": 2.31
    }, {
        "symbol": "OFC",
        "label": "Corporate Office Properties Trust",
        "price": 29.99
    }, {
        "symbol": "STT",
        "label": "State Street Corporation",
        "price": 82.56
    }, {
        "symbol": "HLF",
        "label": "Herbalife Ltd.",
        "price": 47.14
    }, {
        "symbol": "WRK",
        "label": "Westrock Company",
        "price": 43.68
    }, {
        "symbol": "GREK",
        "label": "Global X MSCI Greece",
        "price": 9.3
    }, {
        "symbol": "BR",
        "label": "Broadridge Financial Solutions Inc.Common Stock",
        "price": 131.0
    }, {
        "symbol": "ORIG",
        "label": "Ocean Rig UDW Inc.",
        "price": 27.81
    }, {
        "symbol": "MCD",
        "label": "McDonald's Corporation",
        "price": 211.98
    }, {
        "symbol": "PRO",
        "label": "PROS Holdings Inc.",
        "price": 66.03
    }, {
        "symbol": "SSNC",
        "label": "SS&C Technologies Holdings Inc.",
        "price": 63.47
    }, {
        "symbol": "TWO",
        "label": "Two Harbors Investment Corp",
        "price": 15.08
    }, {
        "symbol": "CHTR",
        "label": "Charter Communications Inc.",
        "price": 502.73
    }, {
        "symbol": "AXL",
        "label": "American Axle & Manufacturing Holdings Inc.",
        "price": 9.63
    }, {
        "symbol": "ACAD",
        "label": "ACADIA Pharmaceuticals Inc.",
        "price": 43.43
    }, {
        "symbol": "VLY",
        "label": "Valley National Bancorp",
        "price": 11.46
    }, {
        "symbol": "VOD",
        "label": "Vodafone Group Plc",
        "price": 20.09
    }, {
        "symbol": "EXTR",
        "label": "Extreme Networks Inc.",
        "price": 7.92
    }, {
        "symbol": "BTE",
        "label": "Baytex Energy Corp",
        "price": 1.35
    }, {
        "symbol": "FEYE",
        "label": "FireEye Inc.",
        "price": 17.13
    }, {
        "symbol": "HYH",
        "label": "Halyard Health Inc.",
        "price": 57.29
    }, {
        "symbol": "SUN",
        "label": "Sunoco LP representing limited partner interests",
        "price": 32.58
    }, {
        "symbol": "VECO",
        "label": "Veeco Instruments Inc.",
        "price": 16.69
    }, {
        "symbol": "ZEN",
        "label": "Zendesk Inc.",
        "price": 83.97
    }, {
        "symbol": "ATEN",
        "label": "A10 Networks Inc.",
        "price": 7.13
    }, {
        "symbol": "CRC",
        "label": "California Resources Corporation",
        "price": 8.49
    }, {
        "symbol": "WPZ",
        "label": "Williams Partners L.P. Representing Limited Partner Interests",
        "price": 47.32
    }, {
        "symbol": "ATUS",
        "label": "Altice USA Inc. Class A",
        "price": 28.72
    }, {
        "symbol": "SIG",
        "label": "Signet Jewelers Limited",
        "price": 29.52
    }, {
        "symbol": "BDC",
        "label": "Belden Inc",
        "price": 55.33
    }, {
        "symbol": "ERIC",
        "label": "Ericsson",
        "price": 9.01
    }, {
        "symbol": "XPO",
        "label": "XPO Logistics Inc.",
        "price": 94.64
    }, {
        "symbol": "APO",
        "label": "Apollo Global Management LLC Class A Representing Class A Limitied Liability Com" +
                "pany Interests",
        "price": 50.36
    }, {
        "symbol": "FMSA",
        "label": "Fairmount Santrol Holdings Inc.",
        "price": 5.63
    }, {
        "symbol": "BSTI",
        "label": "BEST Inc. American Depositary Shares each representing one Class A",
        "price": 5.89
    }, {
        "symbol": "CRI",
        "label": "Carter's Inc.",
        "price": 110.81
    }, {
        "symbol": "MANH",
        "label": "Manhattan Associates Inc.",
        "price": 85.24
    }, {
        "symbol": "AGCO",
        "label": "AGCO Corporation",
        "price": 74.76
    }, {
        "symbol": "TILE",
        "label": "Interface Inc.",
        "price": 17.38
    }, {
        "symbol": "MMC",
        "label": "Marsh & McLennan Companies Inc.",
        "price": 114.15
    }, {
        "symbol": "GNW",
        "label": "Genworth Financial Inc",
        "price": 4.3
    }, {
        "symbol": "CAKE",
        "label": "The Cheesecake Factory Incorporated",
        "price": 38.84
    }, {
        "symbol": "CAR",
        "label": "Avis Budget Group Inc.",
        "price": 34.99
    }, {
        "symbol": "TMHC",
        "label": "Taylor Morrison Home Corporation Class A",
        "price": 24.44
    }, {
        "symbol": "URI",
        "label": "United Rentals Inc.",
        "price": 158.72
    }, {
        "symbol": "SPLK",
        "label": "Splunk Inc.",
        "price": 157.96
    }, {
        "symbol": "VRSN",
        "label": "VeriSign Inc.",
        "price": 214.0
    }, {
        "symbol": "MCO",
        "label": "Moody's Corporation",
        "price": 257.63
    }, {
        "symbol": "SLG",
        "label": "SL Green Realty Corporation",
        "price": 91.24
    }, {
        "symbol": "XOG",
        "label": "Extraction Oil & Gas Inc.",
        "price": 1.84
    }, {
        "symbol": "STO",
        "label": "Statoil ASA",
        "price": 27.14
    }, {
        "symbol": "PEIX",
        "label": "Pacific Ethanol Inc.",
        "price": 0.6733
    }, {
        "symbol": "BZUN",
        "label": "Baozun Inc.",
        "price": 35.56
    }, {
        "symbol": "PAGS",
        "label": "PagSeguro Digital Ltd. Class A",
        "price": 36.0
    }, {
        "symbol": "L",
        "label": "Loews Corporation",
        "price": 52.39
    }, {
        "symbol": "NWSA",
        "label": "News Corporation",
        "price": 14.91
    }, {
        "symbol": "MBI",
        "label": "MBIA Inc.",
        "price": 9.33
    }, {
        "symbol": "ARNA",
        "label": "Arena Pharmaceuticals Inc.",
        "price": 46.67
    }, {
        "symbol": "VNO",
        "label": "Vornado Realty Trust",
        "price": 67.2
    }, {
        "symbol": "PDS",
        "label": "Precision Drilling Corporation",
        "price": 1.46
    }, {
        "symbol": "BCC",
        "label": "Boise Cascade L.L.C.",
        "price": 38.54
    }, {
        "symbol": "TCP",
        "label": "TC PipeLines LP representing Limited Partner Interests",
        "price": 43.89
    }, {
        "symbol": "ULTA",
        "label": "Ulta Beauty Inc.",
        "price": 272.89
    }, {
        "symbol": "PRTY",
        "label": "Party City Holdco Inc.",
        "price": 3.08
    }, {
        "symbol": "JACK",
        "label": "Jack In The Box Inc.",
        "price": 80.9
    }, {
        "symbol": "OGE",
        "label": "OGE Energy Corporation",
        "price": 45.64
    }, {
        "symbol": "QGEN",
        "label": "Qiagen N.V.",
        "price": 35.76
    }, {
        "symbol": "CPRT",
        "label": "Copart Inc.",
        "price": 97.12
    }, {
        "symbol": "EAT",
        "label": "Brinker International Inc.",
        "price": 44.21
    }, {
        "symbol": "DXC",
        "label": "DXC Technology Company",
        "price": 35.6
    }, {
        "symbol": "UEC",
        "label": "Uranium Energy Corp.",
        "price": 0.87
    }, {
        "symbol": "CLVS",
        "label": "Clovis Oncology Inc.",
        "price": 9.85
    }, {
        "symbol": "UDR",
        "label": "UDR Inc.",
        "price": 47.45
    }, {
        "symbol": "KBR",
        "label": "KBR Inc.",
        "price": 29.58
    }, {
        "symbol": "PGEM",
        "label": "Ply Gem Holdings Inc.",
        "price": 21.65
    }, {
        "symbol": "STM",
        "label": "STMicroelectronics N.V.",
        "price": 27.89
    }, {
        "symbol": "AQUA",
        "label": "Evoqua Water Technologies Corp.",
        "price": 19.48
    }, {
        "symbol": "SBRA",
        "label": "Sabra Health Care REIT Inc.",
        "price": 22.03
    }, {
        "symbol": "EQT",
        "label": "EQT Corporation",
        "price": 8.24
    }, {
        "symbol": "FISV",
        "label": "Fiserv Inc.",
        "price": 120.68
    }, {
        "symbol": "SPIB",
        "label": "SPDR Portfolio Intermediate Term Corporate Bond",
        "price": 34.67
    }, {
        "symbol": "IDTI",
        "label": "Integrated Device Technology Inc.",
        "price": 48.99
    }, {
        "symbol": "SRE",
        "label": "Sempra Energy",
        "price": 157.2
    }, {
        "symbol": "DIA",
        "label": "SPDR Dow Jones Industrial Average",
        "price": 293.27
    }, {
        "symbol": "SAVE",
        "label": "Spirit Airlines Inc.",
        "price": 42.93
    }, {
        "symbol": "DXLG",
        "label": "Destination XL Group Inc.",
        "price": 1.26
    }, {
        "symbol": "RHT",
        "label": "Red Hat Inc.",
        "price": 186.43
    }, {
        "symbol": "HTA",
        "label": "Healthcare Trust of America Inc. Class A",
        "price": 31.06
    }, {
        "symbol": "NPTN",
        "label": "NeoPhotonics Corporation",
        "price": 8.7
    }, {
        "symbol": "HMNY",
        "label": "Helios and Matheson Analytics Inc",
        "price": 0.01
    }, {
        "symbol": "NSM",
        "label": "Nationstar Mortgage Holdings Inc.",
        "price": 18.25
    }, {
        "symbol": "ARQL",
        "label": "ArQule Inc.",
        "price": 20.0
    }, {
        "symbol": "SQM",
        "label": "Sociedad Quimica y Minera S.A.",
        "price": 30.7
    }, {
        "symbol": "SXT",
        "label": "Sensient Technologies Corporation",
        "price": 65.1
    }, {
        "symbol": "KND",
        "label": "Kindred Healthcare Inc.",
        "price": 9.0
    }, {
        "symbol": "OMC",
        "label": "Omnicom Group Inc.",
        "price": 80.49
    }, {
        "symbol": "CVA",
        "label": "Covanta Holding Corporation",
        "price": 15.58
    }, {
        "symbol": "LXP",
        "label": "Lexington Realty Trust",
        "price": 10.91
    }, {
        "symbol": "ONB",
        "label": "Old National Bancorp",
        "price": 18.48
    }, {
        "symbol": "FMC",
        "label": "FMC Corporation",
        "price": 100.0
    }, {
        "symbol": "KN",
        "label": "Knowles Corporation",
        "price": 20.64
    }, {
        "symbol": "KW",
        "label": "Kennedy-Wilson Holdings Inc.",
        "price": 22.78
    }, {
        "symbol": "SCS",
        "label": "Steelcase Inc.",
        "price": 19.34
    }, {
        "symbol": "CXW",
        "label": "CoreCivic Inc.",
        "price": 16.2
    }, {
        "symbol": "CBI",
        "label": "Chicago Bridge & Iron Company N.V.",
        "price": 16.39
    }, {
        "symbol": "GXP",
        "label": "Great Plains Energy Incorporated",
        "price": 32.18
    }, {
        "symbol": "CGI",
        "label": "Celadon Group Inc.",
        "price": 3.7
    }, {
        "symbol": "DFS",
        "label": "Discover Financial Services",
        "price": 84.44
    }, {
        "symbol": "VTV",
        "label": "Vanguard Value",
        "price": 121.37
    }, {
        "symbol": "PH",
        "label": "Parker-Hannifin Corporation",
        "price": 207.0
    }, {
        "symbol": "USA",
        "label": "Liberty All-Star Equity Fund",
        "price": 7.08
    }, {
        "symbol": "CIEN",
        "label": "Ciena Corporation",
        "price": 42.23
    }, {
        "symbol": "OIS",
        "label": "Oil States International Inc.",
        "price": 14.81
    }, {
        "symbol": "SEMG",
        "label": "Semgroup Corporation Class A",
        "price": 15.02
    }, {
        "symbol": "SCCO",
        "label": "Southern Copper Corporation",
        "price": 43.93
    }, {
        "symbol": "VT",
        "label": "Vanguard Total World Stock Index",
        "price": 74.32
    }, {
        "symbol": "HDS",
        "label": "HD Supply Holdings Inc.",
        "price": 40.3
    }, {
        "symbol": "YEXT",
        "label": "Yext Inc.",
        "price": 15.63
    }, {
        "symbol": "ATU",
        "label": "Actuant Corporation",
        "price": 24.28
    }, {
        "symbol": "NGHC",
        "label": "National General Holdings Corp",
        "price": 22.77
    }, {
        "symbol": "ATSG",
        "label": "Air Transport Services Group Inc",
        "price": 24.05
    }, {
        "symbol": "REVG",
        "label": "REV Group Inc.",
        "price": 10.96
    }, {
        "symbol": "ALLE",
        "label": "Allegion plc",
        "price": 128.05
    }, {
        "symbol": "CCT",
        "label": "Corporate Capital Trust Inc.",
        "price": 12.44
    }, {
        "symbol": "MHK",
        "label": "Mohawk Industries Inc.",
        "price": 139.7
    }, {
        "symbol": "SPWH",
        "label": "Sportsman's Warehouse Holdings Inc.",
        "price": 6.72
    }, {
        "symbol": "TWNK",
        "label": "Hostess Brands Inc.",
        "price": 14.02
    }, {
        "symbol": "BMCH",
        "label": "BMC Stock Holdings Inc.",
        "price": 30.8
    }, {
        "symbol": "EQIX",
        "label": "Equinix Inc.",
        "price": 588.0
    }, {
        "symbol": "GRA",
        "label": "W.R. Grace & Co.",
        "price": 71.33
    }, {
        "symbol": "VNTR",
        "label": "Venator Materials PLC",
        "price": 3.48
    }, {
        "symbol": "SUM",
        "label": "Summit Materials Inc. Class A",
        "price": 23.42
    }, {
        "symbol": "MSCC",
        "label": "Microsemi Corporation",
        "price": 68.75
    }, {
        "symbol": "RDFN",
        "label": "Redfin Corporation",
        "price": 23.52
    }, {
        "symbol": "ERJ",
        "label": "Embraer S.A.",
        "price": 18.76
    }, {
        "symbol": "UNM",
        "label": "Unum Group",
        "price": 29.37
    }, {
        "symbol": "MTU",
        "label": "Mitsubishi UFJ Financial Group Inc.",
        "price": 6.64
    }, {
        "symbol": "NJR",
        "label": "NewJersey Resources Corporation",
        "price": 44.05
    }, {
        "symbol": "ORBC",
        "label": "ORBCOMM Inc.",
        "price": 4.1
    }, {
        "symbol": "NNN",
        "label": "National Retail Properties",
        "price": 55.34
    }, {
        "symbol": "PAGP",
        "label": "Plains Group Holdings L.P. Class A Shares representing limited partner interests",
        "price": 19.27
    }, {
        "symbol": "TNA",
        "label": "Direxion Small Cap Bull 3X Shares",
        "price": 60.84
    }, {
        "symbol": "LIVN",
        "label": "LivaNova PLC",
        "price": 73.28
    }, {
        "symbol": "AMAG",
        "label": "AMAG Pharmaceuticals Inc.",
        "price": 11.84
    }, {
        "symbol": "CTAS",
        "label": "Cintas Corporation",
        "price": 284.23
    }, {
        "symbol": "REGI",
        "label": "Renewable Energy Group Inc.",
        "price": 25.395
    }, {
        "symbol": "VRNT",
        "label": "Verint Systems Inc.",
        "price": 58.24
    }, {
        "symbol": "HAS",
        "label": "Hasbro Inc.",
        "price": 105.13
    }, {
        "symbol": "AOS",
        "label": "A.O. Smith Corporation",
        "price": 46.93
    }, {
        "symbol": "DGX",
        "label": "Quest Diagnostics Incorporated",
        "price": 107.06
    }, {
        "symbol": "CUZ",
        "label": "Cousins Properties Incorporated",
        "price": 41.71
    }, {
        "symbol": "HALO",
        "label": "Halozyme Therapeutics Inc.",
        "price": 19.97
    }, {
        "symbol": "SGMO",
        "label": "Sangamo Therapeutics Inc.",
        "price": 8.37
    }, {
        "symbol": "KTOS",
        "label": "Kratos Defense & Security Solutions Inc.",
        "price": 19.74
    }, {
        "symbol": "FIS",
        "label": "Fidelity National Information Services Inc.",
        "price": 147.09
    }, {
        "symbol": "EEP",
        "label": "Enbridge Energy L.P. Class A",
        "price": 10.48
    }, {
        "symbol": "SCHX",
        "label": "Schwab U.S. Large-Cap",
        "price": 79.28
    }, {
        "symbol": "TRN",
        "label": "Trinity Industries Inc.",
        "price": 20.91
    }, {
        "symbol": "SPGI",
        "label": "S&P Global Inc.",
        "price": 296.79
    }, {
        "symbol": "BHF",
        "label": "Brighthouse Financial Inc.",
        "price": 40.99
    }, {
        "symbol": "RYAAY",
        "label": "Ryanair Holdings plc American Depositary Shares each representing five Ordinary " +
                "Shares",
        "price": 92.74
    }, {
        "symbol": "AN",
        "label": "AutoNation Inc.",
        "price": 45.6
    }, {
        "symbol": "WDAY",
        "label": "Workday Inc.",
        "price": 181.06
    }, {
        "symbol": "ABR",
        "label": "Arbor Realty Trust",
        "price": 14.7
    }, {
        "symbol": "MCK",
        "label": "McKesson Corporation",
        "price": 156.33
    }, {
        "symbol": "VC",
        "label": "Visteon Corporation",
        "price": 89.51
    }, {
        "symbol": "ROIC",
        "label": "Retail Opportunity Investments Corp.",
        "price": 17.235
    }, {
        "symbol": "HTZ",
        "label": "Hertz Global Holdings Inc",
        "price": 16.11
    }, {
        "symbol": "ACXM",
        "label": "Acxiom Corporation",
        "price": 48.46
    }, {
        "symbol": "MUX",
        "label": "McEwen Mining Inc.",
        "price": 1.18
    }, {
        "symbol": "IEX",
        "label": "IDEX Corporation",
        "price": 175.85
    }, {
        "symbol": "MXIM",
        "label": "Maxim Integrated Products Inc.",
        "price": 61.37
    }, {
        "symbol": "ASHR",
        "label": "Xtrackers Harvest CSI 300 China A-Shares",
        "price": 30.61
    }, {
        "symbol": "XCRA",
        "label": "Xcerra Corporation",
        "price": 14.26
    }, {
        "symbol": "IJH",
        "label": "iShares Core S&P Mid-Cap",
        "price": 209.15
    }, {
        "symbol": "GSVC",
        "label": "GSV Capital Corp",
        "price": 6.83
    }, {
        "symbol": "INN",
        "label": "Summit Hotel Properties Inc.",
        "price": 12.11
    }, {
        "symbol": "ITOT",
        "label": "iShares Core S&P Total U.S. Stock Market",
        "price": 74.93
    }, {
        "symbol": "FAZ",
        "label": "Direxion Financial Bear 3X Shares",
        "price": 7.83
    }, {
        "symbol": "SH",
        "label": "ProShares Short S&P500",
        "price": 26.92
    }, {
        "symbol": "EPE",
        "label": "EP Energy Corporation Class A",
        "price": 0.16
    }, {
        "symbol": "TROX",
        "label": "Tronox Limited Class A $0.01 par",
        "price": 10.82
    }, {
        "symbol": "OEC",
        "label": "Orion Engineered Carbons S.A",
        "price": 18.16
    }, {
        "symbol": "ATH",
        "label": "Athene Holding Ltd. Class A",
        "price": 46.9
    }, {
        "symbol": "CSOD",
        "label": "Cornerstone OnDemand Inc.",
        "price": 63.84
    }, {
        "symbol": "ABC",
        "label": "AmerisourceBergen Corporation",
        "price": 91.06
    }, {
        "symbol": "EFX",
        "label": "Equifax Inc.",
        "price": 153.03
    }, {
        "symbol": "DPZ",
        "label": "Domino's Pizza Inc",
        "price": 286.47
    }, {
        "symbol": "ENLC",
        "label": "EnLink Midstream LLC representing Limited Partner Interests",
        "price": 5.92
    }, {
        "symbol": "TTM",
        "label": "Tata Motors Ltd Limited",
        "price": 13.85
    }, {
        "symbol": "OHI",
        "label": "Omega Healthcare Investors Inc.",
        "price": 43.26
    }, {
        "symbol": "VOO",
        "label": "Vanguard S&P 500",
        "price": 305.06
    }, {
        "symbol": "SUI",
        "label": "Sun Communities Inc.",
        "price": 154.92
    }, {
        "symbol": "SIVB",
        "label": "SVB Financial Group",
        "price": 258.4
    }, {
        "symbol": "MPLX",
        "label": "MPLX LP Representing Limited Partner Interests",
        "price": 26.55
    }, {
        "symbol": "EC",
        "label": "Ecopetrol S.A. American Depositary Shares",
        "price": 20.37
    }, {
        "symbol": "FIVE",
        "label": "Five Below Inc.",
        "price": 116.05
    }, {
        "symbol": "LPLA",
        "label": "LPL Financial Holdings Inc.",
        "price": 98.14
    }, {
        "symbol": "SEIC",
        "label": "SEI Investments Company",
        "price": 65.26
    }, {
        "symbol": "LQ",
        "label": "La Quinta Holdings Inc.",
        "price": 21.65
    }, {
        "symbol": "SIX",
        "label": "Six Flags Entertainment Corporation",
        "price": 37.87
    }, {
        "symbol": "PX",
        "label": "Praxair Inc.",
        "price": 164.4
    }, {
        "symbol": "VEEV",
        "label": "Veeva Systems Inc. Class A",
        "price": 148.07
    }, {
        "symbol": "DO",
        "label": "Diamond Offshore Drilling Inc.",
        "price": 6.39
    }, {
        "symbol": "FORM",
        "label": "FormFactor Inc.",
        "price": 27.51
    }, {
        "symbol": "FRAC",
        "label": "Keane Group Inc.",
        "price": 7.3
    }, {
        "symbol": "BRKS",
        "label": "Brooks Automation Inc.",
        "price": 43.61
    }, {
        "symbol": "VIG",
        "label": "Vanguard Div Appreciation",
        "price": 114.44
    }, {
        "symbol": "NVO",
        "label": "Novo Nordisk A/S",
        "price": 61.31
    }, {
        "symbol": "SWK",
        "label": "Stanley Black & Decker Inc.",
        "price": 172.53
    }, {
        "symbol": "SNDR",
        "label": "Schneider National Inc.",
        "price": 23.68
    }, {
        "symbol": "MZOR",
        "label": "Mazor Robotics Ltd.",
        "price": 58.24
    }, {
        "symbol": "SBGL",
        "label": "D/B/A Sibanye-Stillwater Limited American Depositary Shares (Each representing f" +
                "our)",
        "price": 11.15
    }, {
        "symbol": "VNDA",
        "label": "Vanda Pharmaceuticals Inc.",
        "price": 15.11
    }, {
        "symbol": "ANH",
        "label": "Anworth Mortgage Asset Corporation",
        "price": 3.67
    }, {
        "symbol": "HTHT",
        "label": "China Lodging Group Limited",
        "price": 39.29
    }, {
        "symbol": "EW",
        "label": "Edwards Lifesciences Corporation",
        "price": 238.64
    }, {
        "symbol": "SKT",
        "label": "Tanger Factory Outlet Centers Inc.",
        "price": 15.37
    }, {
        "symbol": "ROK",
        "label": "Rockwell Automation Inc.",
        "price": 204.74
    }, {
        "symbol": "PENN",
        "label": "Penn National Gaming Inc.",
        "price": 26.0
    }, {
        "symbol": "AMRN",
        "label": "Amarin Corporation plc",
        "price": 19.91
    }, {
        "symbol": "ALL",
        "label": "Allstate Corporation (The)",
        "price": 117.55
    }, {
        "symbol": "IONS",
        "label": "Ionis Pharmaceuticals Inc.",
        "price": 60.13
    }, {
        "symbol": "APRN",
        "label": "Blue Apron Holdings Inc. Class A",
        "price": 5.02
    }, {
        "symbol": "NEOS",
        "label": "Neos Therapeutics Inc.",
        "price": 1.6
    }, {
        "symbol": "KRNY",
        "label": "Kearny Financial",
        "price": 13.31
    }, {
        "symbol": "SBAC",
        "label": "SBA Communications Corporation",
        "price": 252.04
    }, {
        "symbol": "MFC",
        "label": "Manulife Financial Corporation",
        "price": 21.13
    }, {
        "symbol": "FSIC",
        "label": "FS Investment Corporation",
        "price": 5.36
    }, {
        "symbol": "ZTS",
        "label": "Zoetis Inc. Class A",
        "price": 140.16
    }, {
        "symbol": "BUD",
        "label": "Anheuser-Busch Inbev SA Sponsored ADR (Belgium)",
        "price": 80.03
    }, {
        "symbol": "UA",
        "label": "Under Armour Inc. Class C",
        "price": 18.37
    }, {
        "symbol": "AROC",
        "label": "Archrock Inc.",
        "price": 9.62
    }, {
        "symbol": "THO",
        "label": "Thor Industries Inc.",
        "price": 81.84
    }, {
        "symbol": "BGNE",
        "label": "BeiGene Ltd.",
        "price": 170.36
    }, {
        "symbol": "WB",
        "label": "Weibo Corporation",
        "price": 46.79
    }, {
        "symbol": "KT",
        "label": "KT Corporation",
        "price": 11.15
    }, {
        "symbol": "BOTZ",
        "label": "Global X Robotics & Artificial Intelligence ETF",
        "price": 22.54
    }, {
        "symbol": "TGNA",
        "label": "TEGNA Inc",
        "price": 18.06
    }, {
        "symbol": "CHGG",
        "label": "Chegg Inc.",
        "price": 43.33
    }, {
        "symbol": "HYD",
        "label": "VanEck Vectors High Yield Municipal Index",
        "price": 63.56
    }, {
        "symbol": "WM",
        "label": "Waste Management Inc.",
        "price": 119.65
    }, {
        "symbol": "ASX",
        "label": "Advanced Semiconductor Engineering Inc.",
        "price": 5.24
    }, {
        "symbol": "BDSI",
        "label": "BioDelivery Sciences International Inc.",
        "price": 5.83
    }, {
        "symbol": "THRM",
        "label": "Gentherm Inc",
        "price": 48.155
    }, {
        "symbol": "UMPQ",
        "label": "Umpqua Holdings Corporation",
        "price": 18.41
    }, {
        "symbol": "ALB",
        "label": "Albemarle Corporation",
        "price": 80.9
    }, {
        "symbol": "RHI",
        "label": "Robert Half International Inc.",
        "price": 63.34
    }, {
        "symbol": "AKRX",
        "label": "Akorn Inc.",
        "price": 1.35
    }, {
        "symbol": "HLT",
        "label": "Hilton Worldwide Holdings Inc.",
        "price": 113.92
    }, {
        "symbol": "DNOW",
        "label": "NOW Inc.",
        "price": 11.58
    }, {
        "symbol": "PIR",
        "label": "Pier 1 Imports Inc.",
        "price": 3.48
    }, {
        "symbol": "WPG",
        "label": "Washington Prime Group Inc.",
        "price": 3.72
    }, {
        "symbol": "PNFP",
        "label": "Pinnacle Financial Partners Inc.",
        "price": 63.39
    }, {
        "symbol": "DRH",
        "label": "Diamondrock Hospitality Company",
        "price": 10.54
    }, {
        "symbol": "OC",
        "label": "Owens Corning Inc New",
        "price": 65.65
    }, {
        "symbol": "CUBE",
        "label": "CubeSmart",
        "price": 31.48
    }, {
        "symbol": "GLPI",
        "label": "Gaming and Leisure Properties Inc.",
        "price": 45.55
    }, {
        "symbol": "CBOE",
        "label": "Cboe Global Markets Inc.",
        "price": 116.53
    }, {
        "symbol": "RDNT",
        "label": "RadNet Inc.",
        "price": 20.69
    }, {
        "symbol": "AGRO",
        "label": "Adecoagro S.A.",
        "price": 7.8
    }, {
        "symbol": "AL",
        "label": "Air Lease Corporation Class A",
        "price": 49.42
    }, {
        "symbol": "PHH",
        "label": "PHH Corp",
        "price": 11.0
    }, {
        "symbol": "GNRT",
        "label": "Gener8 Maritime Inc.",
        "price": 6.62
    }, {
        "symbol": "AMJ",
        "label": "JPMorgan Chase Capital XVI JP Morgan Alerian MLP ETN",
        "price": 24.61
    }, {
        "symbol": "EVH",
        "label": "Evolent Health Inc Class A",
        "price": 11.38
    }, {
        "symbol": "UIS",
        "label": "Unisys Corporation",
        "price": 12.33
    }, {
        "symbol": "NCS",
        "label": "NCI Building Systems Inc.",
        "price": 5.71
    }, {
        "symbol": "JELD",
        "label": "JELD-WEN Holding Inc.",
        "price": 24.83
    }, {
        "symbol": "TDG",
        "label": "Transdigm Group Incorporated Inc.",
        "price": 636.66
    }, {
        "symbol": "LITE",
        "label": "Lumentum Holdings Inc.",
        "price": 76.96
    }, {
        "symbol": "STAY",
        "label": "Extended Stay America Inc.",
        "price": 14.32
    }, {
        "symbol": "TCF",
        "label": "TCF Financial Corporation",
        "price": 46.07
    }, {
        "symbol": "RY",
        "label": "Royal Bank Of Canada",
        "price": 81.52
    }, {
        "symbol": "ROST",
        "label": "Ross Stores Inc.",
        "price": 117.68
    }, {
        "symbol": "SYK",
        "label": "Stryker Corporation",
        "price": 212.17
    }, {
        "symbol": "REI",
        "label": "Ring Energy Inc.",
        "price": 2.7
    }, {
        "symbol": "HR",
        "label": "Healthcare Realty Trust Incorporated",
        "price": 34.55
    }, {
        "symbol": "TRV",
        "label": "The Travelers Companies Inc.",
        "price": 140.73
    }, {
        "symbol": "CSL",
        "label": "Carlisle Companies Incorporated",
        "price": 163.95
    }, {
        "symbol": "GWRE",
        "label": "Guidewire Software Inc.",
        "price": 113.02
    }, {
        "symbol": "SIR",
        "label": "Select Income REIT",
        "price": 7.32
    }, {
        "symbol": "ANET",
        "label": "Arista Networks Inc.",
        "price": 221.87
    }, {
        "symbol": "BMS",
        "label": "Bemis Company Inc.",
        "price": 57.29
    }, {
        "symbol": "SFLY",
        "label": "Shutterfly Inc.",
        "price": 50.59
    }, {
        "symbol": "PCRX",
        "label": "Pacira Pharmaceuticals Inc.",
        "price": 43.82
    }, {
        "symbol": "CSIQ",
        "label": "Canadian Solar Inc.",
        "price": 22.405
    }, {
        "symbol": "ICHR",
        "label": "Ichor Holdings",
        "price": 38.58
    }, {
        "symbol": "CAVM",
        "label": "Cavium Inc.",
        "price": 86.27
    }, {
        "symbol": "WSM",
        "label": "Williams-Sonoma Inc. (DE)",
        "price": 76.59
    }, {
        "symbol": "EXP",
        "label": "Eagle Materials Inc",
        "price": 93.2
    }, {
        "symbol": "BWA",
        "label": "BorgWarner Inc.",
        "price": 41.53
    }, {
        "symbol": "SWCH",
        "label": "Switch Inc. Class A",
        "price": 16.04
    }, {
        "symbol": "APLE",
        "label": "Apple Hospitality REIT Inc.",
        "price": 15.97
    }, {
        "symbol": "ADS",
        "label": "Alliance Data Systems Corporation",
        "price": 112.91
    }, {
        "symbol": "DRQ",
        "label": "Dril-Quip Inc.",
        "price": 46.92
    }, {
        "symbol": "AXS",
        "label": "Axis Capital Holdings Limited",
        "price": 61.02
    }, {
        "symbol": "JNUG",
        "label": "Direxion Daily Junior Gold Miners Index Bull 3X Shares",
        "price": 8.42
    }, {
        "symbol": "MTDR",
        "label": "Matador Resources Company",
        "price": 16.93
    }, {
        "symbol": "CARB",
        "label": "Carbonite Inc.",
        "price": 22.98
    }, {
        "symbol": "CAJ",
        "label": "Canon Inc. American Depositary Shares",
        "price": 28.0
    }, {
        "symbol": "CNI",
        "label": "Canadian National Railway Company",
        "price": 96.1
    }, {
        "symbol": "TLND",
        "label": "Talend S.A.",
        "price": 39.8
    }, {
        "symbol": "ILMN",
        "label": "Illumina Inc.",
        "price": 324.72
    }, {
        "symbol": "ISRG",
        "label": "Intuitive Surgical Inc.",
        "price": 600.03
    }, {
        "symbol": "CAL",
        "label": "Caleres Inc.",
        "price": 21.79
    }, {
        "symbol": "SPAR",
        "label": "Spartan Motors Inc.",
        "price": 18.11
    }, {
        "symbol": "SAP",
        "label": "SAP SE ADS",
        "price": 139.96
    }, {
        "symbol": "ESNT",
        "label": "Essent Group Ltd.",
        "price": 51.88
    }, {
        "symbol": "NMFC",
        "label": "New Mountain Finance Corporation",
        "price": 14.22
    }, {
        "symbol": "BLDP",
        "label": "Ballard Power Systems Inc.",
        "price": 12.04
    }, {
        "symbol": "OI",
        "label": "Owens-Illinois Inc.",
        "price": 12.95
    }, {
        "symbol": "DSW",
        "label": "DSW Inc.",
        "price": 22.55
    }, {
        "symbol": "MDU",
        "label": "MDU Resources Group Inc.",
        "price": 29.6
    }, {
        "symbol": "ELLI",
        "label": "Ellie Mae Inc.",
        "price": 99.0
    }, {
        "symbol": "PSXP",
        "label": "Phillips 66 Partners LP representing limited partner interest in the Partnership",
        "price": 63.96
    }, {
        "symbol": "EPI",
        "label": "WisdomTree India Earnings Fund",
        "price": 26.45
    }, {
        "symbol": "CREE",
        "label": "Cree Inc.",
        "price": 52.36
    }, {
        "symbol": "CWH",
        "label": "Camping World Holdings Inc. Class A Commom Stock",
        "price": 16.33
    }, {
        "symbol": "DECK",
        "label": "Deckers Outdoor Corporation",
        "price": 173.52
    }, {
        "symbol": "COHR",
        "label": "Coherent Inc.",
        "price": 173.7
    }, {
        "symbol": "ACWI",
        "label": "iShares MSCI ACWI Index Fund",
        "price": 81.31
    }, {
        "symbol": "SMFG",
        "label": "Sumitomo Mitsui Financial Group Inc Unsponsored American Depositary Shares (Japa" +
                "n)",
        "price": 7.22
    }, {
        "symbol": "FULT",
        "label": "Fulton Financial Corporation",
        "price": 17.32
    }, {
        "symbol": "UNIT",
        "label": "Uniti Group Inc.",
        "price": 7.465
    }, {
        "symbol": "LAZ",
        "label": "Lazard LTD. LTD. Class A",
        "price": 44.24
    }, {
        "symbol": "CASY",
        "label": "Caseys General Stores Inc.",
        "price": 168.82
    }, {
        "symbol": "TEGP",
        "label": "Tallgrass Energy GP LP Class A Shares",
        "price": 22.12
    }, {
        "symbol": "PODD",
        "label": "Insulet Corporation",
        "price": 188.76
    }, {
        "symbol": "NEXA",
        "label": "Nexa Resources S.A.",
        "price": 8.64
    }, {
        "symbol": "HRS",
        "label": "Harris Corporation",
        "price": 196.9
    }, {
        "symbol": "QSII",
        "label": "Quality Systems Inc.",
        "price": 22.37
    }, {
        "symbol": "UCO",
        "label": "ProShares Ultra Bloomberg Crude Oil",
        "price": 15.56
    }, {
        "symbol": "MATW",
        "label": "Matthews International Corporation",
        "price": 40.02
    }, {
        "symbol": "PLNT",
        "label": "Planet Fitness Inc.",
        "price": 79.75
    }, {
        "symbol": "CAH",
        "label": "Cardinal Health Inc.",
        "price": 53.93
    }, {
        "symbol": "AYI",
        "label": "Acuity Brands Inc (Holding Company)",
        "price": 123.28
    }, {
        "symbol": "DVA",
        "label": "DaVita Inc.",
        "price": 79.47
    }, {
        "symbol": "CHUBK",
        "label": "CommerceHub Inc.",
        "price": 22.73
    }, {
        "symbol": "AMPE",
        "label": "Ampio Pharmaceuticals Inc.",
        "price": 0.6488
    }, {
        "symbol": "REGN",
        "label": "Regeneron Pharmaceuticals Inc.",
        "price": 385.94
    }, {
        "symbol": "MELI",
        "label": "MercadoLibre Inc.",
        "price": 673.01
    }, {
        "symbol": "SGMS",
        "label": "Scientific Games Corp",
        "price": 27.15
    }, {
        "symbol": "WCN",
        "label": "Waste Connections Inc.",
        "price": 98.17
    }, {
        "symbol": "IMMR",
        "label": "Immersion Corporation",
        "price": 7.74
    }, {
        "symbol": "VKTX",
        "label": "Viking Therapeutics Inc.",
        "price": 7.18
    }, {
        "symbol": "MYOV",
        "label": "Myovant Sciences Ltd.",
        "price": 13.88
    }, {
        "symbol": "ABM",
        "label": "ABM Industries Incorporated",
        "price": 39.79
    }, {
        "symbol": "NGL",
        "label": "NGL ENERGY PARTNERS LP representing Limited Partner Interests",
        "price": 11.97
    }, {
        "symbol": "ARCO",
        "label": "Arcos Dorados Holdings Inc. Class A Shares",
        "price": 8.07
    }, {
        "symbol": "HEWJ",
        "label": "iShares Currency Hedged MSCI Japan",
        "price": 29.62
    }, {
        "symbol": "BABY",
        "label": "Natus Medical Incorporated",
        "price": 25.2
    }, {
        "symbol": "TYME",
        "label": "Tyme Technologies Inc.",
        "price": 1.93
    }, {
        "symbol": "UNG",
        "label": "United States Natural Gas Fund LP",
        "price": 20.35
    }, {
        "symbol": "GST",
        "label": "Gastar Exploration Inc",
        "price": 0.06
    }, {
        "symbol": "VR",
        "label": "Validus Holdings Ltd.",
        "price": 67.99
    }, {
        "symbol": "MGPI",
        "label": "MGP Ingredients Inc.",
        "price": 38.18
    }, {
        "symbol": "NTES",
        "label": "NetEase Inc.",
        "price": 349.82
    }, {
        "symbol": "LTRPA",
        "label": "Liberty TripAdvisor Holdings Inc. Series A Common Stock",
        "price": 7.36
    }, {
        "symbol": "LNC",
        "label": "Lincoln National Corporation",
        "price": 60.04
    }, {
        "symbol": "NGG",
        "label": "National Grid Transco PLC PLC (NEW) American Depositary Shares",
        "price": 63.54
    }, {
        "symbol": "TQQQ",
        "label": "ProShares UltraPro QQQ",
        "price": 99.9
    }, {
        "symbol": "SND",
        "label": "Smart Sand Inc.",
        "price": 2.22
    }, {
        "symbol": "SCHF",
        "label": "Schwab International Equity",
        "price": 31.07
    }, {
        "symbol": "GTES",
        "label": "Gates Industrial Corporation plc",
        "price": 13.24
    }, {
        "symbol": "CMA",
        "label": "Comerica Incorporated",
        "price": 68.72
    }, {
        "symbol": "ALK",
        "label": "Alaska Air Group Inc.",
        "price": 66.62
    }, {
        "symbol": "CHL",
        "label": "China Mobile Limited",
        "price": 43.1
    }, {
        "symbol": "PFG",
        "label": "Principal Financial Group Inc",
        "price": 57.08
    }, {
        "symbol": "IRET",
        "label": "Investors Real Estate Trust Shares of Beneficial Interest",
        "price": 73.63
    }, {
        "symbol": "HUM",
        "label": "Humana Inc.",
        "price": 366.55
    }, {
        "symbol": "TEX",
        "label": "Terex Corporation",
        "price": 28.01
    }, {
        "symbol": "STFC",
        "label": "State Auto Financial Corporation",
        "price": 31.5
    }, {
        "symbol": "SAND",
        "label": "Sandstorm Gold Ltd. (Canada)",
        "price": 5.54
    }, {
        "symbol": "JWN",
        "label": "Nordstrom Inc.",
        "price": 40.34
    }, {
        "symbol": "HDSN",
        "label": "Hudson Technologies Inc.",
        "price": 0.8621
    }, {
        "symbol": "HFC",
        "label": "HollyFrontier Corporation",
        "price": 46.92
    }, {
        "symbol": "PQG",
        "label": "PQ Group Holdings Inc.",
        "price": 16.69
    }, {
        "symbol": "MBFI",
        "label": "MB Financial Inc.",
        "price": 42.37
    }, {
        "symbol": "EDU",
        "label": "New Oriental Education & Technology Group Inc. Sponsored ADR representing 1 (Cay" +
                "man Islands)",
        "price": 135.75
    }, {
        "symbol": "ESRT",
        "label": "Empire State Realty Trust Inc. Class A",
        "price": 14.07
    }, {
        "symbol": "SCHD",
        "label": "Schwab US Dividend Equity",
        "price": 59.15
    }, {
        "symbol": "LBRT",
        "label": "Liberty Oilfield Services Inc. Class A",
        "price": 10.61
    }, {
        "symbol": "HDP",
        "label": "Hortonworks Inc.",
        "price": 14.68
    }, {
        "symbol": "PEB",
        "label": "Pebblebrook Hotel Trust of Beneficial Interest",
        "price": 25.75
    }, {
        "symbol": "TSN",
        "label": "Tyson Foods Inc.",
        "price": 89.86
    }, {
        "symbol": "HSBC",
        "label": "HSBC Holdings plc.",
        "price": 38.77
    }, {
        "symbol": "OMI",
        "label": "Owens & Minor Inc.",
        "price": 5.51
    }, {
        "symbol": "PTC",
        "label": "PTC Inc.",
        "price": 80.14
    }, {
        "symbol": "CDW",
        "label": "CDW Corporation",
        "price": 136.85
    }, {
        "symbol": "EWI",
        "label": "iShares Inc MSCI Italy",
        "price": 26.71
    }, {
        "symbol": "SBGI",
        "label": "Sinclair Broadcast Group Inc.",
        "price": 33.46
    }, {
        "symbol": "RSP",
        "label": "Guggenheim S&P 500 Equal Weight",
        "price": 118.4
    }, {
        "symbol": "MMP",
        "label": "Magellan Midstream Partners L.P. Limited Partnership",
        "price": 64.37
    }, {
        "symbol": "PLAY",
        "label": "Dave & Buster's Entertainment Inc.",
        "price": 47.37
    }, {
        "symbol": "EGOV",
        "label": "NIC Inc.",
        "price": 22.57
    }, {
        "symbol": "HDB",
        "label": "HDFC Bank Limited",
        "price": 61.74
    }, {
        "symbol": "TSE",
        "label": "Trinseo S.A.",
        "price": 34.98
    }, {
        "symbol": "WAL",
        "label": "Western Alliance Bancorporation (DE)",
        "price": 58.03
    }, {
        "symbol": "SBS",
        "label": "Companhia de saneamento Basico Do Estado De Sao Paulo - Sabesp American Deposita" +
                "ry Shares (Each repstg 250)",
        "price": 14.37
    }, {
        "symbol": "ARE",
        "label": "Alexandria Real Estate Equities Inc.",
        "price": 162.9
    }, {
        "symbol": "EMLC",
        "label": "VanEck Vectors J.P. Morgan EM Local Currency Bond",
        "price": 33.05
    }, {
        "symbol": "EXAS",
        "label": "Exact Sciences Corporation",
        "price": 88.06
    }, {
        "symbol": "TRGP",
        "label": "Targa Resources Inc.",
        "price": 41.28
    }, {
        "symbol": "LYV",
        "label": "Live Nation Entertainment Inc.",
        "price": 75.54
    }, {
        "symbol": "MFA",
        "label": "MFA Financial Inc.",
        "price": 7.88
    }, {
        "symbol": "ECL",
        "label": "Ecolab Inc.",
        "price": 195.58
    }, {
        "symbol": "RESN",
        "label": "Resonant Inc.",
        "price": 2.82
    }, {
        "symbol": "ELY",
        "label": "Callaway Golf Company",
        "price": 22.02
    }, {
        "symbol": "ARES",
        "label": "Ares Management L.P. representing Limited Partner Interests",
        "price": 37.28
    }, {
        "symbol": "DDD",
        "label": "3D Systems Corporation",
        "price": 11.41
    }, {
        "symbol": "LPT",
        "label": "Liberty Property Trust",
        "price": 63.55
    }, {
        "symbol": "MKC",
        "label": "McCormick & Company Incorporated",
        "price": 168.68
    }, {
        "symbol": "WAFD",
        "label": "Washington Federal Inc.",
        "price": 35.605
    }, {
        "symbol": "BSBR",
        "label": "Banco Santander Brasil SA American Depositary Shares each representing one unit",
        "price": 11.08
    }, {
        "symbol": "CQP",
        "label": "Cheniere Energy Partners LP",
        "price": 42.16
    }, {
        "symbol": "PETQ",
        "label": "PetIQ Inc.",
        "price": 30.74
    }, {
        "symbol": "RMD",
        "label": "ResMed Inc.",
        "price": 160.57
    }, {
        "symbol": "GPT",
        "label": "Gramercy Property Trust",
        "price": 27.49
    }, {
        "symbol": "IRDM",
        "label": "Iridium Communications Inc",
        "price": 26.95
    }, {
        "symbol": "BKU",
        "label": "BankUnited Inc.",
        "price": 35.16
    }, {
        "symbol": "H",
        "label": "Hyatt Hotels Corporation Class A",
        "price": 88.19
    }, {
        "symbol": "MDXG",
        "label": "MiMedx Group Inc",
        "price": 3.66
    }, {
        "symbol": "EXPR",
        "label": "Express Inc.",
        "price": 4.58
    }, {
        "symbol": "AVB",
        "label": "AvalonBay Communities Inc.",
        "price": 216.07
    }, {
        "symbol": "FII",
        "label": "Federated Investors Inc.",
        "price": 33.96
    }, {
        "symbol": "INCY",
        "label": "Incyte Corporation",
        "price": 80.5
    }, {
        "symbol": "LBRDK",
        "label": "Liberty Broadband Corporation",
        "price": 129.47
    }, {
        "symbol": "NVCN",
        "label": "Neovasc Inc.",
        "price": 3.33
    }, {
        "symbol": "TRMB",
        "label": "Trimble Inc.",
        "price": 44.14
    }, {
        "symbol": "CVEO",
        "label": "Civeo Corporation (Canada)",
        "price": 1.31
    }, {
        "symbol": "TDOC",
        "label": "Teladoc Inc.",
        "price": 95.6
    }, {
        "symbol": "TRU",
        "label": "TransUnion",
        "price": 92.85
    }, {
        "symbol": "SOGO",
        "label": "Sogou Inc. American Depositary Shares each representing one Class A",
        "price": 5.43
    }, {
        "symbol": "APTI",
        "label": "Apptio Inc.",
        "price": 37.98
    }, {
        "symbol": "XYL",
        "label": "Xylem Inc. New",
        "price": 83.46
    }, {
        "symbol": "MOAT",
        "label": "VanEck Vectors Morningstar Wide Moat",
        "price": 56.15
    }, {
        "symbol": "GPOR",
        "label": "Gulfport Energy Corporation",
        "price": 1.9
    }, {
        "symbol": "ANDX",
        "label": "Andeavor Logistics LP representing Limited Partner Interests",
        "price": 35.21
    }, {
        "symbol": "ACGL",
        "label": "Arch Capital Group Ltd.",
        "price": 45.17
    }, {
        "symbol": "SFUN",
        "label": "Fang Holdings Limited American Depositary Shares (Each representing Four Class A" +
                " HK$1.00 par value)",
        "price": 3.49
    }, {
        "symbol": "SBNY",
        "label": "Signature Bank",
        "price": 139.17
    }, {
        "symbol": "GRFS",
        "label": "Grifols S.A.",
        "price": 23.64
    }, {
        "symbol": "DY",
        "label": "Dycom Industries Inc.",
        "price": 45.84
    }, {
        "symbol": "MGP",
        "label": "MGM Growth Properties LLC Class A representing limited liability company interes" +
                "ts",
        "price": 32.32
    }, {
        "symbol": "SNE",
        "label": "Sony Corporation",
        "price": 72.48
    }, {
        "symbol": "MAA",
        "label": "Mid-America Apartment Communities Inc.",
        "price": 133.99
    }, {
        "symbol": "AEL",
        "label": "American Equity Investment Life Holding Company",
        "price": 28.95
    }, {
        "symbol": "CVG",
        "label": "Convergys Corporation",
        "price": 24.42
    }, {
        "symbol": "GOGO",
        "label": "Gogo Inc.",
        "price": 5.52
    }, {
        "symbol": "TSU",
        "label": "TIM Participacoes S.A. American Depositary Shares (Each representing 5)",
        "price": 19.97
    }, {
        "symbol": "DBC",
        "label": "PowerShares DB Commodity Index Trac",
        "price": 14.86
    }, {
        "symbol": "WES",
        "label": "Western Gas Partners LP Limited Partner Interests",
        "price": 20.62
    }, {
        "symbol": "QUAD",
        "label": "Quad Graphics Inc Class A",
        "price": 4.87
    }, {
        "symbol": "RYN",
        "label": "Rayonier Inc. REIT",
        "price": 32.12
    }, {
        "symbol": "SYNA",
        "label": "Synaptics Incorporated",
        "price": 72.465
    }, {
        "symbol": "VSTO",
        "label": "Vista Outdoor Inc.",
        "price": 7.44
    }, {
        "symbol": "BHVN",
        "label": "Biohaven Pharmaceutical Holding Company Ltd.",
        "price": 56.01
    }, {
        "symbol": "WYN",
        "label": "Wyndham Worldwide Corp Common Stock",
        "price": 108.42
    }, {
        "symbol": "PPG",
        "label": "PPG Industries Inc.",
        "price": 128.49
    }, {
        "symbol": "WAIR",
        "label": "Wesco Aircraft Holdings Inc.",
        "price": 11.04
    }, {
        "symbol": "ORI",
        "label": "Old Republic International Corporation",
        "price": 22.96
    }, {
        "symbol": "SONC",
        "label": "Sonic Corp.",
        "price": 43.48
    }, {
        "symbol": "TPC",
        "label": "Tutor Perini Corporation",
        "price": 11.58
    }, {
        "symbol": "XHR",
        "label": "Xenia Hotels & Resorts Inc.",
        "price": 20.58
    }, {
        "symbol": "WMGI",
        "label": "Wright Medical Group N.V.",
        "price": 30.1
    }, {
        "symbol": "MC",
        "label": "Moelis & Company Class A",
        "price": 34.99
    }, {
        "symbol": "COT",
        "label": "Cott Corporation",
        "price": 14.72
    }, {
        "symbol": "DDS",
        "label": "Dillard's Inc.",
        "price": 71.57
    }, {
        "symbol": "TMST",
        "label": "Timken Steel Corporation",
        "price": 7.4
    }, {
        "symbol": "IPXL",
        "label": "Impax Laboratories Inc.",
        "price": 18.25
    }, {
        "symbol": "FR",
        "label": "First Industrial Realty Trust Inc.",
        "price": 42.88
    }, {
        "symbol": "NMIH",
        "label": "NMI Holdings Inc",
        "price": 33.53
    }, {
        "symbol": "MTCH",
        "label": "Match Group Inc.",
        "price": 91.11
    }, {
        "symbol": "TRHC",
        "label": "Tabula Rasa HealthCare Inc.",
        "price": 54.88
    }, {
        "symbol": "XNET",
        "label": "Xunlei Limited",
        "price": 5.57
    }, {
        "symbol": "CIG",
        "label": "Comp En De Mn Cemig ADS American Depositary Shares",
        "price": 3.69
    }, {
        "symbol": "HAIN",
        "label": "The Hain Celestial Group Inc.",
        "price": 25.78
    }, {
        "symbol": "TIVO",
        "label": "TiVo Corporation",
        "price": 8.505
    }, {
        "symbol": "GSY",
        "label": "Guggenheim Ultra Short Duration",
        "price": 50.45
    }, {
        "symbol": "CUK",
        "label": "Carnival Plc ADS ADS",
        "price": 48.92
    }, {
        "symbol": "ECHO",
        "label": "Echo Global Logistics Inc.",
        "price": 23.28
    }, {
        "symbol": "MSI",
        "label": "Motorola Solutions Inc.",
        "price": 176.12
    }, {
        "symbol": "PDCE",
        "label": "PDC Energy Inc.",
        "price": 24.16
    }, {
        "symbol": "DRYS",
        "label": "DryShips Inc.",
        "price": 3.31
    }, {
        "symbol": "FNV",
        "label": "Franco-Nevada Corporation",
        "price": 105.63
    }, {
        "symbol": "EGN",
        "label": "Energen Corporation",
        "price": 72.13
    }, {
        "symbol": "LPSN",
        "label": "LivePerson Inc.",
        "price": 41.3
    }, {
        "symbol": "TELL",
        "label": "Tellurian Inc.",
        "price": 8.69
    }, {
        "symbol": "KMX",
        "label": "CarMax Inc",
        "price": 95.68
    }, {
        "symbol": "MDSO",
        "label": "Medidata Solutions Inc.",
        "price": 91.25
    }, {
        "symbol": "LFC",
        "label": "China Life Insurance Company Limited American Depositary Shares",
        "price": 14.19
    }, {
        "symbol": "ESPR",
        "label": "Esperion Therapeutics Inc.",
        "price": 57.43
    }, {
        "symbol": "BRX",
        "label": "Brixmor Property Group Inc.",
        "price": 20.73
    }, {
        "symbol": "EQGP",
        "label": "EQT GP Holdings LP representing limited partner interests",
        "price": 19.99
    }, {
        "symbol": "LH",
        "label": "Laboratory Corporation of America Holdings",
        "price": 177.0
    }, {
        "symbol": "CJ",
        "label": "C&J Energy Services Inc.",
        "price": 11.56
    }, {
        "symbol": "AKAO",
        "label": "Achaogen Inc.",
        "price": 0.11
    }, {
        "symbol": "PLCE",
        "label": "Children's Place Inc. (The)",
        "price": 64.75
    }, {
        "symbol": "NBHC",
        "label": "National Bank Holdings Corporation",
        "price": 36.87
    }, {
        "symbol": "FND",
        "label": "Floor & Decor Holdings Inc.",
        "price": 48.39
    }, {
        "symbol": "MOH",
        "label": "Molina Healthcare Inc",
        "price": 136.82
    }, {
        "symbol": "SSW",
        "label": "Seaspan Corporation",
        "price": 14.08
    }, {
        "symbol": "FUL",
        "label": "H. B. Fuller Company",
        "price": 49.4
    }, {
        "symbol": "LNCE",
        "label": "Snyder's-Lance Inc.",
        "price": 49.98
    }, {
        "symbol": "MRC",
        "label": "MRC Global Inc.",
        "price": 13.4
    }, {
        "symbol": "EXR",
        "label": "Extra Space Storage Inc",
        "price": 111.56
    }, {
        "symbol": "SCHL",
        "label": "Scholastic Corporation",
        "price": 36.76
    }, {
        "symbol": "CPG",
        "label": "Crescent Point Energy Corporation (Canada)",
        "price": 4.2
    }, {
        "symbol": "ITG",
        "label": "Investment Technology Group Inc.",
        "price": 30.25
    }, {
        "symbol": "ADTN",
        "label": "ADTRAN Inc.",
        "price": 10.3
    }, {
        "symbol": "WHR",
        "label": "Whirlpool Corporation",
        "price": 152.63
    }, {
        "symbol": "AMGP",
        "label": "Antero Midstream GP LP of Beneficial Interests",
        "price": 12.54
    }, {
        "symbol": "NTRI",
        "label": "NutriSystem Inc",
        "price": 42.91
    }, {
        "symbol": "HSY",
        "label": "The Hershey Company",
        "price": 150.85
    }, {
        "symbol": "HTGM",
        "label": "HTG Molecular Diagnostics Inc.",
        "price": 0.6577
    }, {
        "symbol": "SEAS",
        "label": "SeaWorld Entertainment Inc.",
        "price": 35.73
    }, {
        "symbol": "BECN",
        "label": "Beacon Roofing Supply Inc.",
        "price": 35.755
    }, {
        "symbol": "BPOP",
        "label": "Popular Inc.",
        "price": 61.39
    }, {
        "symbol": "IEV",
        "label": "iShares Europe",
        "price": 43.4
    }, {
        "symbol": "PGRE",
        "label": "Paramount Group Inc.",
        "price": 14.05
    }, {
        "symbol": "COO",
        "label": "The Cooper Companies Inc.",
        "price": 355.31
    }, {
        "symbol": "JT",
        "label": "Jianpu Technology Inc. American depositary shares each two representing five Cla" +
                "ss A",
        "price": 1.55
    }, {
        "symbol": "WRI",
        "label": "Weingarten Realty Investors",
        "price": 30.98
    }, {
        "symbol": "COMM",
        "label": "CommScope Holding Company Inc.",
        "price": 14.78
    }, {
        "symbol": "ABEO",
        "label": "Abeona Therapeutics Inc.",
        "price": 2.54
    }, {
        "symbol": "KORS",
        "label": "Michael Kors Holdings Limited",
        "price": 37.87
    }, {
        "symbol": "CPT",
        "label": "Camden Property Trust",
        "price": 109.97
    }, {
        "symbol": "SHOP",
        "label": "Shopify Inc. Class A Subordinate",
        "price": 454.88
    }, {
        "symbol": "RETA",
        "label": "Reata Pharmaceuticals Inc.",
        "price": 211.43
    }, {
        "symbol": "MWA",
        "label": "MUELLER WATER PRODUCTS",
        "price": 12.13
    }, {
        "symbol": "BLUE",
        "label": "bluebird bio Inc.",
        "price": 98.04
    }, {
        "symbol": "EIDO",
        "label": "iShares MSCI Indonesia",
        "price": 25.21
    }, {
        "symbol": "GWB",
        "label": "Great Western Bancorp Inc.",
        "price": 34.09
    }, {
        "symbol": "SOXS",
        "label": "Direxion Daily Semiconductor Bear 3x Shares",
        "price": 6.91
    }, {
        "symbol": "WPRT",
        "label": "Westport Fuel Systems Inc",
        "price": 2.7
    }, {
        "symbol": "CORT",
        "label": "Corcept Therapeutics Incorporated",
        "price": 12.9
    }, {
        "symbol": "BOOT",
        "label": "Boot Barn Holdings Inc.",
        "price": 43.65
    }, {
        "symbol": "ALEX",
        "label": "Alexander & Baldwin Inc. REIT Holding Company",
        "price": 21.93
    }, {
        "symbol": "VTIP",
        "label": "Vanguard Short-Term Inflation-Protected Securities Index Fund",
        "price": 49.29
    }, {
        "symbol": "TKR",
        "label": "Timken Company (The)",
        "price": 58.22
    }, {
        "symbol": "RL",
        "label": "Ralph Lauren Corporation",
        "price": 122.1
    }, {
        "symbol": "DBA",
        "label": "PowerShares DB Agriculture Fund",
        "price": 16.42
    }, {
        "symbol": "XXII",
        "label": "22nd Century Group Inc.",
        "price": 1.14
    }, {
        "symbol": "CLGX",
        "label": "CoreLogic Inc.",
        "price": 45.38
    }, {
        "symbol": "AXGN",
        "label": "AxoGen Inc.",
        "price": 14.31
    }, {
        "symbol": "MASI",
        "label": "Masimo Corporation",
        "price": 169.74
    }, {
        "symbol": "OKTA",
        "label": "Okta Inc.",
        "price": 125.83
    }, {
        "symbol": "BKS",
        "label": "Barnes & Noble Inc.",
        "price": 4.65
    }, {
        "symbol": "TD",
        "label": "Toronto Dominion Bank (The)",
        "price": 56.74
    }, {
        "symbol": "BRO",
        "label": "Brown & Brown Inc.",
        "price": 41.41
    }, {
        "symbol": "ALSN",
        "label": "Allison Transmission Holdings Inc.",
        "price": 48.41
    }, {
        "symbol": "FAF",
        "label": "First American Corporation (New)",
        "price": 60.02
    }, {
        "symbol": "BRKR",
        "label": "Bruker Corporation",
        "price": 53.625
    }, {
        "symbol": "G",
        "label": "Genpact Limited",
        "price": 44.32
    }, {
        "symbol": "CFX",
        "label": "Colfax Corporation",
        "price": 38.07
    }, {
        "symbol": "PANW",
        "label": "Palo Alto Networks Inc.",
        "price": 242.07
    }, {
        "symbol": "GPC",
        "label": "Genuine Parts Company",
        "price": 102.8
    }, {
        "symbol": "ELP",
        "label": "Companhia Paranaense de Energia (COPEL)",
        "price": 17.73
    }, {
        "symbol": "KMPR",
        "label": "Kemper Corporation",
        "price": 78.8
    }, {
        "symbol": "BGS",
        "label": "B&G Foods Inc.",
        "price": 15.68
    }, {
        "symbol": "NBIX",
        "label": "Neurocrine Biosciences Inc.",
        "price": 104.67
    }, {
        "symbol": "CHKP",
        "label": "Check Point Software Technologies Ltd.",
        "price": 113.75
    }, {
        "symbol": "JAZZ",
        "label": "Jazz Pharmaceuticals plc",
        "price": 151.05
    }, {
        "symbol": "CMCO",
        "label": "Columbus McKinnon Corporation",
        "price": 39.33
    }, {
        "symbol": "CRS",
        "label": "Carpenter Technology Corporation",
        "price": 46.39
    }, {
        "symbol": "AMC",
        "label": "AMC Entertainment Holdings Inc. Class A",
        "price": 7.28
    }, {
        "symbol": "BVN",
        "label": "Buenaventura Mining Company Inc.",
        "price": 13.54
    }, {
        "symbol": "EXK",
        "label": "Endeavour Silver Corporation (Canada)",
        "price": 2.14
    }, {
        "symbol": "BLK",
        "label": "BlackRock Inc.",
        "price": 535.24
    }, {
        "symbol": "IMO",
        "label": "Imperial Oil Limited",
        "price": 27.11
    }, {
        "symbol": "WRD",
        "label": "WildHorse Resource Development Corporation",
        "price": 17.02
    }, {
        "symbol": "UUP",
        "label": "PowerShares DB USD Index Bullish",
        "price": 26.2
    }, {
        "symbol": "SFS",
        "label": "Smart & Final Stores Inc.",
        "price": 6.5
    }, {
        "symbol": "CATM",
        "label": "Cardtronics plc",
        "price": 46.94
    }, {
        "symbol": "POR",
        "label": "Portland General Electric Co",
        "price": 58.81
    }, {
        "symbol": "BBL",
        "label": "BHP Billiton plc Sponsored ADR",
        "price": 48.42
    }, {
        "symbol": "GCI",
        "label": "Gannett Co. Inc.",
        "price": 6.96
    }, {
        "symbol": "RWX",
        "label": "SPDR DJ Wilshire Intl Real Estate",
        "price": 38.77
    }, {
        "symbol": "DYN",
        "label": "Dynegy Inc.",
        "price": 13.53
    }, {
        "symbol": "ORLY",
        "label": "O'Reilly Automotive Inc.",
        "price": 440.68
    }, {
        "symbol": "HDV",
        "label": "iShares Core High Dividend",
        "price": 92.7
    }, {
        "symbol": "GDI",
        "label": "Gardner Denver Holdings Inc.",
        "price": 35.48
    }, {
        "symbol": "FFIV",
        "label": "F5 Networks Inc.",
        "price": 137.0
    }, {
        "symbol": "PHB",
        "label": "PowerShares High Yield Corporate Bond Portfolio",
        "price": 18.66
    }, {
        "symbol": "KNL",
        "label": "Knoll Inc.",
        "price": 25.1
    }, {
        "symbol": "HOG",
        "label": "Harley-Davidson Inc.",
        "price": 35.72
    }, {
        "symbol": "STWD",
        "label": "STARWOOD PROPERTY TRUST INC. Starwood Property Trust Inc.",
        "price": 25.4
    }, {
        "symbol": "PRTA",
        "label": "Prothena Corporation plc",
        "price": 14.26
    }, {
        "symbol": "UNVR",
        "label": "Univar Inc.",
        "price": 23.46
    }, {
        "symbol": "TUP",
        "label": "Tupperware Brands Corporation",
        "price": 9.42
    }, {
        "symbol": "LXFT",
        "label": "Luxoft Holding Inc. Class A",
        "price": 58.16
    }, {
        "symbol": "FSM",
        "label": "Fortuna Silver Mines Inc (Canada)",
        "price": 3.87
    }, {
        "symbol": "XIN",
        "label": "Xinyuan Real Estate Co Ltd American Depositary Shares",
        "price": 3.8
    }, {
        "symbol": "SCWX",
        "label": "SecureWorks Corp.",
        "price": 16.39
    }, {
        "symbol": "PTLA",
        "label": "Portola Pharmaceuticals Inc.",
        "price": 14.37
    }, {
        "symbol": "PAAS",
        "label": "Pan American Silver Corp.",
        "price": 21.335
    }, {
        "symbol": "NUGT",
        "label": "Direxion Daily Gold Miners Index Bull 3X Shares",
        "price": 19.24
    }, {
        "symbol": "ADNT",
        "label": "Adient plc",
        "price": 21.84
    }, {
        "symbol": "TRUP",
        "label": "Trupanion Inc.",
        "price": 32.79
    }, {
        "symbol": "HAE",
        "label": "Haemonetics Corporation",
        "price": 113.85
    }, {
        "symbol": "PCY",
        "label": "PowerShares Emerging Markets Sovereign Debt Portfolio",
        "price": 29.78
    }, {
        "symbol": "WTR",
        "label": "Aqua America Inc.",
        "price": 50.57
    }, {
        "symbol": "EVEP",
        "label": "EV Energy Partners L.P.",
        "price": 0.12
    }, {
        "symbol": "HOPE",
        "label": "Hope Bancorp Inc.",
        "price": 14.79
    }, {
        "symbol": "MRTN",
        "label": "Marten Transport Ltd.",
        "price": 21.65
    }, {
        "symbol": "DLX",
        "label": "Deluxe Corporation",
        "price": 49.39
    }, {
        "symbol": "UUUU",
        "label": "Energy Fuels Inc (Canada)",
        "price": 1.73
    }, {
        "symbol": "GEO",
        "label": "Geo Group Inc (The) REIT",
        "price": 16.1
    }, {
        "symbol": "AAV",
        "label": "Advantage Oil & Gas Ltd",
        "price": 2.53
    }, {
        "symbol": "LMNX",
        "label": "Luminex Corporation",
        "price": 23.18
    }, {
        "symbol": "AAP",
        "label": "Advance Auto Parts Inc W/I",
        "price": 151.05
    }, {
        "symbol": "AON",
        "label": "Aon plc Class A (UK)",
        "price": 211.85
    }, {
        "symbol": "TGTX",
        "label": "TG Therapeutics Inc.",
        "price": 13.7
    }, {
        "symbol": "SKM",
        "label": "SK Telecom Co. Ltd.",
        "price": 22.76
    }, {
        "symbol": "MAN",
        "label": "ManpowerGroup",
        "price": 98.76
    }, {
        "symbol": "SDY",
        "label": "SPDR S&P Dividend",
        "price": 108.34
    }, {
        "symbol": "CRUS",
        "label": "Cirrus Logic Inc.",
        "price": 84.11
    }, {
        "symbol": "AAXN",
        "label": "Axon Enterprise Inc.",
        "price": 73.6
    }, {
        "symbol": "DPLO",
        "label": "Diplomat Pharmacy Inc.",
        "price": 3.98
    }, {
        "symbol": "TXRH",
        "label": "Texas Roadhouse Inc.",
        "price": 57.8
    }, {
        "symbol": "BEAT",
        "label": "BioTelemetry Inc.",
        "price": 54.12
    }, {
        "symbol": "FRED",
        "label": "Fred's Inc.",
        "price": 0.48
    }, {
        "symbol": "AAXJ",
        "label": "iShares MSCI All Country Asia ex Japan Index Fund",
        "price": 76.23
    }, {
        "symbol": "TNET",
        "label": "TriNet Group Inc.",
        "price": 58.84
    }, {
        "symbol": "ECR",
        "label": "Eclipse Resources Corporation",
        "price": 1.18
    }, {
        "symbol": "LZB",
        "label": "La-Z-Boy Incorporated",
        "price": 32.03
    }, {
        "symbol": "CVRS",
        "label": "Corindus Vascular Robotics Inc. (DE)",
        "price": 2.57
    }, {
        "symbol": "FTSI",
        "label": "FTS International Inc.",
        "price": 0.9251
    }, {
        "symbol": "EEFT",
        "label": "Euronet Worldwide Inc.",
        "price": 164.31
    }, {
        "symbol": "BPL",
        "label": "Buckeye Partners L.P.",
        "price": 40.62
    }, {
        "symbol": "COL",
        "label": "Rockwell Collins Inc.",
        "price": 141.34
    }, {
        "symbol": "BXS",
        "label": "BancorpSouth Bank",
        "price": 30.82
    }, {
        "symbol": "CVGI",
        "label": "Commercial Vehicle Group Inc.",
        "price": 6.07
    }, {
        "symbol": "TRI",
        "label": "Thomson Reuters Corp",
        "price": 78.96
    }, {
        "symbol": "JQC",
        "label": "Nuveen Credit Strategies Income Fund Shares of Beneficial Interest",
        "price": 7.66
    }, {
        "symbol": "GMS",
        "label": "GMS Inc.",
        "price": 28.79
    }, {
        "symbol": "PBI",
        "label": "Pitney Bowes Inc.",
        "price": 4.27
    }, {
        "symbol": "XON",
        "label": "Intrexon Corporation",
        "price": 5.83
    }, {
        "symbol": "JEC",
        "label": "Jacobs Engineering Group Inc.",
        "price": 85.84
    }, {
        "symbol": "RJF",
        "label": "Raymond James Financial Inc.",
        "price": 96.27
    }, {
        "symbol": "WWD",
        "label": "Woodward Inc.",
        "price": 125.3
    }, {
        "symbol": "NRE",
        "label": "NorthStar Realty Europe Corp.",
        "price": 16.51
    }, {
        "symbol": "SNY",
        "label": "Sanofi American Depositary Shares (Each repstg one-half of one)",
        "price": 51.24
    }, {
        "symbol": "IWB",
        "label": "iShares Russell 1000",
        "price": 184.11
    }, {
        "symbol": "TTC",
        "label": "Toro Company (The)",
        "price": 83.08
    }, {
        "symbol": "BAM",
        "label": "Brookfield Asset Management Inc.",
        "price": 62.27
    }, {
        "symbol": "SHW",
        "label": "Sherwin-Williams Company (The)",
        "price": 594.47
    }, {
        "symbol": "AMP",
        "label": "Ameriprise Financial Inc.",
        "price": 172.43
    }, {
        "symbol": "FINL",
        "label": "The Finish Line Inc.",
        "price": 13.5
    }, {
        "symbol": "SNR",
        "label": "New Senior Investment Group Inc.",
        "price": 7.93
    }, {
        "symbol": "WSO",
        "label": "Watsco Inc.",
        "price": 175.13
    }, {
        "symbol": "ATO",
        "label": "Atmos Energy Corporation",
        "price": 114.98
    }, {
        "symbol": "SNH",
        "label": "Senior Housing Properties Trust",
        "price": 8.44
    }, {
        "symbol": "QID",
        "label": "ProShares UltraShort QQQ",
        "price": 35.87
    }, {
        "symbol": "HOLI",
        "label": "Hollysys Automation Technologies Ltd.",
        "price": 17.48
    }, {
        "symbol": "CBAY",
        "label": "CymaBay Therapeutics Inc.",
        "price": 2.02
    }, {
        "symbol": "BGC",
        "label": "General Cable Corporation",
        "price": 30.0
    }, {
        "symbol": "STAG",
        "label": "Stag Industrial Inc.",
        "price": 32.46
    }, {
        "symbol": "WAB",
        "label": "Westinghouse Air Brake Technologies Corporation",
        "price": 80.15
    }, {
        "symbol": "FSCT",
        "label": "ForeScout Technologies Inc.",
        "price": 33.745
    }, {
        "symbol": "AVEO",
        "label": "AVEO Pharmaceuticals Inc.",
        "price": 0.6356
    }, {
        "symbol": "PFGC",
        "label": "Performance Food Group Company",
        "price": 51.81
    }, {
        "symbol": "MUSA",
        "label": "Murphy USA Inc.",
        "price": 112.24
    }, {
        "symbol": "RCM",
        "label": "R1 RCM Inc.",
        "price": 13.42
    }, {
        "symbol": "DK",
        "label": "Delek US Holdings Inc.",
        "price": 31.13
    }, {
        "symbol": "CM",
        "label": "Canadian Imperial Bank of Commerce",
        "price": 83.25
    }, {
        "symbol": "SWX",
        "label": "Southwest Gas Holdings Inc.",
        "price": 76.7
    }, {
        "symbol": "SSRM",
        "label": "SSR Mining Inc.",
        "price": 17.71
    }, {
        "symbol": "ACWF",
        "label": "iShares Edge MSCI Multifactor Global",
        "price": 28.22
    }, {
        "symbol": "MTW",
        "label": "Manitowoc Company Inc. (The)",
        "price": 16.36
    }, {
        "symbol": "WUBA",
        "label": "58.com Inc. American Depositary Shares each representing 2 Class A",
        "price": 68.7
    }, {
        "symbol": "IRS",
        "label": "IRSA Inversiones Y Representaciones S.A.",
        "price": 6.19
    }, {
        "symbol": "AHL",
        "label": "Aspen Insurance Holdings Limited",
        "price": 42.74
    }, {
        "symbol": "MIC",
        "label": "Macquarie Infrastructure Corporation",
        "price": 45.32
    }, {
        "symbol": "PSQ",
        "label": "ProShares Short QQQ",
        "price": 30.13
    }, {
        "symbol": "CYS",
        "label": "CYS Investments Inc.",
        "price": 7.35
    }, {
        "symbol": "TSEM",
        "label": "Tower Semiconductor Ltd.",
        "price": 25.21
    }, {
        "symbol": "ILF",
        "label": "iShares Latin America 40",
        "price": 33.8
    }, {
        "symbol": "DNLI",
        "label": "Denali Therapeutics Inc.",
        "price": 22.36
    }, {
        "symbol": "CHRW",
        "label": "C.H. Robinson Worldwide Inc.",
        "price": 80.74
    }, {
        "symbol": "SPPI",
        "label": "Spectrum Pharmaceuticals Inc.",
        "price": 3.24
    }, {
        "symbol": "TTD",
        "label": "The Trade Desk Inc.",
        "price": 280.95
    }, {
        "symbol": "ASML",
        "label": "ASML Holding N.V.",
        "price": 300.04
    }, {
        "symbol": "IDXX",
        "label": "IDEXX Laboratories Inc.",
        "price": 285.8
    }, {
        "symbol": "ONCS",
        "label": "OncoSec Medical Incorporated",
        "price": 2.09
    }, {
        "symbol": "SRPT",
        "label": "Sarepta Therapeutics Inc.",
        "price": 127.9
    }, {
        "symbol": "SHLM",
        "label": "A. Schulman Inc.",
        "price": 44.0
    }, {
        "symbol": "LTM",
        "label": "LATAM Airlines Group S.A.",
        "price": 9.25
    }, {
        "symbol": "PNK",
        "label": "Pinnacle Entertainment Inc.",
        "price": 32.57
    }, {
        "symbol": "SGEN",
        "label": "Seattle Genetics Inc.",
        "price": 109.0
    }, {
        "symbol": "CNK",
        "label": "Cinemark Holdings Inc Inc.",
        "price": 32.43
    }, {
        "symbol": "TCBI",
        "label": "Texas Capital Bancshares Inc.",
        "price": 54.48
    }, {
        "symbol": "OCN",
        "label": "Ocwen Financial Corporation",
        "price": 1.46
    }, {
        "symbol": "MDB",
        "label": "MongoDB Inc.",
        "price": 149.39
    }, {
        "symbol": "DXPE",
        "label": "DXP Enterprises Inc.",
        "price": 39.25
    }, {
        "symbol": "NWBI",
        "label": "Northwest Bancshares Inc.",
        "price": 16.39
    }, {
        "symbol": "LLL",
        "label": "L3 Technologies Inc.",
        "price": 254.56
    }, {
        "symbol": "ENS",
        "label": "Enersys",
        "price": 75.53
    }, {
        "symbol": "RP",
        "label": "RealPage Inc.",
        "price": 57.86
    }, {
        "symbol": "TFX",
        "label": "Teleflex Incorporated",
        "price": 383.73
    }, {
        "symbol": "EQC",
        "label": "Equity Commonwealth of Beneficial Interest",
        "price": 32.83
    }, {
        "symbol": "DEI",
        "label": "Douglas Emmett Inc.",
        "price": 42.28
    }, {
        "symbol": "UFS",
        "label": "Domtar Corporation (NEW)",
        "price": 39.87
    }, {
        "symbol": "MTN",
        "label": "Vail Resorts Inc.",
        "price": 249.44
    }, {
        "symbol": "SPR",
        "label": "Spirit Aerosystems Holdings Inc.",
        "price": 67.3
    }, {
        "symbol": "PSEC",
        "label": "Prospect Capital Corporation",
        "price": 6.6
    }, {
        "symbol": "LEE",
        "label": "Lee Enterprises Incorporated",
        "price": 1.3
    }, {
        "symbol": "QDEL",
        "label": "Quidel Corporation",
        "price": 72.51
    }, {
        "symbol": "WETF",
        "label": "WisdomTree Investments Inc.",
        "price": 4.33
    }, {
        "symbol": "BBX",
        "label": "BBX Capital Corporation Class A",
        "price": 4.22
    }, {
        "symbol": "NRZ",
        "label": "New Residential Investment Corp.",
        "price": 16.7
    }, {
        "symbol": "HBM",
        "label": "Hudbay Minerals Inc. (Canada)",
        "price": 3.78
    }, {
        "symbol": "INVA",
        "label": "Innoviva Inc.",
        "price": 14.43
    }, {
        "symbol": "BJRI",
        "label": "BJ's Restaurants Inc.",
        "price": 39.485
    }, {
        "symbol": "EVRI",
        "label": "Everi Holdings Inc.",
        "price": 12.76
    }, {
        "symbol": "LCI",
        "label": "Lannett Co Inc",
        "price": 9.75
    }, {
        "symbol": "FLIR",
        "label": "FLIR Systems Inc.",
        "price": 54.66
    }, {
        "symbol": "PII",
        "label": "Polaris Industries Inc.",
        "price": 93.08
    }, {
        "symbol": "JCOM",
        "label": "j2 Global Inc.",
        "price": 102.42
    }, {
        "symbol": "CTT",
        "label": "CatchMark Timber Trust Inc. Class A",
        "price": 9.64
    }, {
        "symbol": "SCO",
        "label": "ProShares UltraShort Bloomberg Crude Oil",
        "price": 21.83
    }, {
        "symbol": "TLRD",
        "label": "Tailored Brands Inc.",
        "price": 4.47
    }, {
        "symbol": "AHH",
        "label": "Armada Hoffler Properties Inc.",
        "price": 18.74
    }, {
        "symbol": "ENRJ",
        "label": "EnerJex Resources Inc. (NEW)",
        "price": 0.21
    }, {
        "symbol": "ZS",
        "label": "Zscaler Inc.",
        "price": 59.43
    }, {
        "symbol": "MIDD",
        "label": "The Middleby Corporation",
        "price": 113.33
    }, {
        "symbol": "SF",
        "label": "Stifel Financial Corporation",
        "price": 64.97
    }, {
        "symbol": "RCII",
        "label": "Rent-A-Center Inc.",
        "price": 31.1
    }, {
        "symbol": "DCP",
        "label": "DCP Midstream LP",
        "price": 24.49
    }, {
        "symbol": "IMPV",
        "label": "Imperva Inc.",
        "price": 55.75
    }, {
        "symbol": "VEDL",
        "label": "Vedanta Limited American Depositary Shares (Each representing four equity shares" +
                ")",
        "price": 9.02
    }, {
        "symbol": "SERV",
        "label": "ServiceMaster Global Holdings Inc.",
        "price": 36.75
    }, {
        "symbol": "UN",
        "label": "Unilever NV",
        "price": 56.8
    }, {
        "symbol": "WBS",
        "label": "Webster Financial Corporation",
        "price": 52.75
    }, {
        "symbol": "CRON",
        "label": "Cronos Group Inc.",
        "price": 8.55
    }, {
        "symbol": "WST",
        "label": "West Pharmaceutical Services Inc.",
        "price": 157.59
    }, {
        "symbol": "SCI",
        "label": "Service Corporation International",
        "price": 48.5
    }, {
        "symbol": "BERY",
        "label": "Berry Global Group Inc.",
        "price": 48.75
    }, {
        "symbol": "ENIA",
        "label": "Enel Americas S.A. American Depositary Shares",
        "price": 10.86
    }, {
        "symbol": "TEUM",
        "label": "Pareteum Corporation",
        "price": 0.777
    }, {
        "symbol": "TPRE",
        "label": "Third Point Reinsurance Ltd.",
        "price": 10.75
    }, {
        "symbol": "CCK",
        "label": "Crown Holdings Inc.",
        "price": 73.44
    }, {
        "symbol": "MAG",
        "label": "MAG Silver Corporation",
        "price": 10.54
    }, {
        "symbol": "WNC",
        "label": "Wabash National Corporation",
        "price": 13.22
    }, {
        "symbol": "CSGP",
        "label": "CoStar Group Inc.",
        "price": 649.63
    }, {
        "symbol": "PETX",
        "label": "Aratana Therapeutics Inc.",
        "price": 4.83
    }, {
        "symbol": "ETR",
        "label": "Entergy Corporation",
        "price": 126.31
    }, {
        "symbol": "ATHN",
        "label": "athenahealth Inc.",
        "price": 135.0
    }, {
        "symbol": "VLUE",
        "label": "iShares Edge MSCI USA Value Factor",
        "price": 90.37
    }, {
        "symbol": "TTMI",
        "label": "TTM Technologies Inc.",
        "price": 15.38
    }, {
        "symbol": "FLS",
        "label": "Flowserve Corporation",
        "price": 50.49
    }, {
        "symbol": "ARWR",
        "label": "Arrowhead Pharmaceuticals Inc.",
        "price": 56.42
    }, {
        "symbol": "IAC",
        "label": "IAC/InterActiveCorp",
        "price": 275.7
    }, {
        "symbol": "VIRT",
        "label": "Virtu Financial Inc.",
        "price": 15.45
    }, {
        "symbol": "UCTT",
        "label": "Ultra Clean Holdings Inc.",
        "price": 25.0
    }, {
        "symbol": "WBT",
        "label": "Welbilt Inc.",
        "price": 15.51
    }, {
        "symbol": "VUG",
        "label": "Vanguard Growth",
        "price": 191.91
    }, {
        "symbol": "ANGI",
        "label": "ANGI Homeservices Inc.",
        "price": 9.33
    }, {
        "symbol": "BCOR",
        "label": "Blucora Inc.",
        "price": 23.31
    }, {
        "symbol": "CISN",
        "label": "Cision Ltd.",
        "price": 9.99
    }, {
        "symbol": "CRH",
        "label": "CRH PLC American Depositary Shares",
        "price": 38.82
    }, {
        "symbol": "GHL",
        "label": "Greenhill & Co. Inc.",
        "price": 17.44
    }, {
        "symbol": "IPI",
        "label": "Intrepid Potash Inc",
        "price": 2.38
    }, {
        "symbol": "HIW",
        "label": "Highwoods Properties Inc.",
        "price": 50.48
    }, {
        "symbol": "BLDR",
        "label": "Builders FirstSource Inc.",
        "price": 26.82
    }, {
        "symbol": "CNXM",
        "label": "CNX Midstream Partners LP representing limited partner interests",
        "price": 16.52
    }, {
        "symbol": "MOBL",
        "label": "MobileIron Inc.",
        "price": 5.0
    }, {
        "symbol": "CPS",
        "label": "Cooper-Standard Holdings Inc.",
        "price": 31.07
    }, {
        "symbol": "CHEF",
        "label": "The Chefs' Warehouse Inc.",
        "price": 38.68
    }, {
        "symbol": "APH",
        "label": "Amphenol Corporation",
        "price": 109.45
    }, {
        "symbol": "REXR",
        "label": "Rexford Industrial Realty Inc.",
        "price": 47.75
    }, {
        "symbol": "KURA",
        "label": "Kura Oncology Inc.",
        "price": 12.76
    }, {
        "symbol": "GTHX",
        "label": "G1 Therapeutics Inc.",
        "price": 25.36
    }, {
        "symbol": "TK",
        "label": "Teekay Corporation",
        "price": 4.88
    }, {
        "symbol": "MRNS",
        "label": "Marinus Pharmaceuticals Inc.",
        "price": 2.26
    }, {
        "symbol": "GPRE",
        "label": "Green Plains Inc.",
        "price": 12.96
    }, {
        "symbol": "TERP",
        "label": "TerraForm Power Inc.",
        "price": 17.29
    }, {
        "symbol": "RS",
        "label": "Reliance Steel & Aluminum Co. (DE)",
        "price": 121.5
    }, {
        "symbol": "HIBB",
        "label": "Hibbett Sports Inc.",
        "price": 26.82
    }, {
        "symbol": "ALGN",
        "label": "Align Technology Inc.",
        "price": 280.7
    }, {
        "symbol": "HEI",
        "label": "Heico Corporation",
        "price": 121.38
    }, {
        "symbol": "CFR",
        "label": "Cullen/Frost Bankers Inc.",
        "price": 96.14
    }, {
        "symbol": "YPF",
        "label": "YPF Sociedad Anonima",
        "price": 10.32
    }, {
        "symbol": "LGIH",
        "label": "LGI Homes Inc.",
        "price": 78.74
    }, {
        "symbol": "CMG",
        "label": "Chipotle Mexican Grill Inc.",
        "price": 878.15
    }, {
        "symbol": "REG",
        "label": "Regency Centers Corporation",
        "price": 63.2
    }, {
        "symbol": "AVXL",
        "label": "Anavex Life Sciences Corp.",
        "price": 3.3
    }, {
        "symbol": "AXDX",
        "label": "Accelerate Diagnostics Inc.",
        "price": 17.3
    }, {
        "symbol": "RPM",
        "label": "RPM International Inc.",
        "price": 74.44
    }, {
        "symbol": "CTRL",
        "label": "Control4 Corporation",
        "price": 23.64
    }, {
        "symbol": "BNS",
        "label": "Bank Nova Scotia Halifax Pfd 3",
        "price": 55.47
    }, {
        "symbol": "USAT",
        "label": "USA Technologies Inc.",
        "price": 7.33
    }, {
        "symbol": "BWXT",
        "label": "BWX Technologies Inc.",
        "price": 66.17
    }, {
        "symbol": "WOW",
        "label": "WideOpenWest Inc.",
        "price": 6.7
    }, {
        "symbol": "PRMW",
        "label": "Primo Water Corporation",
        "price": 14.67
    }, {
        "symbol": "WOR",
        "label": "Worthington Industries Inc.",
        "price": 40.56
    }, {
        "symbol": "NIHD",
        "label": "NII Holdings Inc.",
        "price": 2.17
    }, {
        "symbol": "NUVA",
        "label": "NuVasive Inc.",
        "price": 78.1
    }, {
        "symbol": "HPT",
        "label": "Hospitality Properties Trust",
        "price": 24.13
    }, {
        "symbol": "TIP",
        "label": "iShares TIPS Bond",
        "price": 117.1
    }, {
        "symbol": "ADVM",
        "label": "Adverum Biotechnologies Inc.",
        "price": 11.72
    }, {
        "symbol": "SWIR",
        "label": "Sierra Wireless Inc.",
        "price": 10.3
    }, {
        "symbol": "BIOS",
        "label": "BioScrip Inc.",
        "price": 4.2
    }, {
        "symbol": "DNKN",
        "label": "Dunkin' Brands Group Inc.",
        "price": 78.61
    }, {
        "symbol": "MBB",
        "label": "iShares MBS ETF",
        "price": 108.43
    }, {
        "symbol": "EFII",
        "label": "Electronics for Imaging Inc.",
        "price": 36.68
    }, {
        "symbol": "FRAN",
        "label": "Francesca's Holdings Corporation",
        "price": 9.24
    }, {
        "symbol": "HUBS",
        "label": "HubSpot Inc.",
        "price": 181.72
    }, {
        "symbol": "FRPT",
        "label": "Freshpet Inc.",
        "price": 66.08
    }, {
        "symbol": "BNCL",
        "label": "Beneficial Bancorp Inc.",
        "price": 16.01
    }, {
        "symbol": "ARAY",
        "label": "Accuray Incorporated",
        "price": 3.28
    }, {
        "symbol": "QUOT",
        "label": "Quotient Technology Inc.",
        "price": 10.5
    }, {
        "symbol": "COLD",
        "label": "Americold Realty Trust",
        "price": 35.42
    }, {
        "symbol": "DERM",
        "label": "Dermira Inc.",
        "price": 19.155
    }, {
        "symbol": "NS",
        "label": "Nustar Energy L.P.",
        "price": 28.31
    }, {
        "symbol": "YANG",
        "label": "Direxion Daily FTSE China Bear 3x Shares",
        "price": 58.55
    }, {
        "symbol": "FAS",
        "label": "Direxion Financial Bull 3X Shares",
        "price": 69.61
    }, {
        "symbol": "FMX",
        "label": "Fomento Economico Mexicano S.A.B. de C.V.",
        "price": 94.53
    }, {
        "symbol": "GLIBA",
        "label": "GCI Liberty Inc. Class A Common Stock",
        "price": 72.79
    }, {
        "symbol": "HTLD",
        "label": "Heartland Express Inc.",
        "price": 21.01
    }, {
        "symbol": "VRNS",
        "label": "Varonis Systems Inc.",
        "price": 85.26
    }, {
        "symbol": "IVE",
        "label": "iShares S&P 500 Value",
        "price": 112.18
    }, {
        "symbol": "EV",
        "label": "Eaton Vance Corporation",
        "price": 47.39
    }, {
        "symbol": "AYTU",
        "label": "Aytu BioScience Inc.",
        "price": 0.8
    }, {
        "symbol": "PKG",
        "label": "Packaging Corporation of America",
        "price": 109.04
    }, {
        "symbol": "CINF",
        "label": "Cincinnati Financial Corporation",
        "price": 107.31
    }, {
        "symbol": "ANY",
        "label": "Sphere 3D Corp.",
        "price": 0.8811
    }, {
        "symbol": "NAV",
        "label": "Navistar International Corporation",
        "price": 27.41
    }, {
        "symbol": "DENN",
        "label": "Denny's Corporation",
        "price": 21.35
    }, {
        "symbol": "CLX",
        "label": "Clorox Company (The)",
        "price": 157.7
    }, {
        "symbol": "VGR",
        "label": "Vector Group Ltd.",
        "price": 13.46
    }, {
        "symbol": "HII",
        "label": "Huntington Ingalls Industries Inc.",
        "price": 277.81
    }, {
        "symbol": "AFSI",
        "label": "AmTrust Financial Services Inc.",
        "price": 14.76
    }, {
        "symbol": "GLRE",
        "label": "Greenlight Reinsurance Ltd.",
        "price": 10.14
    }, {
        "symbol": "PRGO",
        "label": "Perrigo Company plc",
        "price": 59.02
    }, {
        "symbol": "OIL",
        "label": "Barclays Bank Plc iPath Exchange Traded Notes Linked to Goldman Sachs Crude Oil " +
                "Total Return",
        "price": 57.73
    }, {
        "symbol": "RRR",
        "label": "Red Rock Resorts Inc.",
        "price": 24.0
    }, {
        "symbol": "SINA",
        "label": "Sina Corporation",
        "price": 43.06
    }, {
        "symbol": "FNGN",
        "label": "Financial Engines Inc.",
        "price": 44.98
    }, {
        "symbol": "MPO",
        "label": "Midstates Petroleum Company Inc.",
        "price": 6.7
    }, {
        "symbol": "PRAH",
        "label": "PRA Health Sciences Inc.",
        "price": 106.17
    }, {
        "symbol": "MSA",
        "label": "MSA Safety Incorporated",
        "price": 137.0
    }, {
        "symbol": "SQNS",
        "label": "Sequans Communications S.A. American Depositary Shares each representing one",
        "price": 4.65
    }, {
        "symbol": "ZG",
        "label": "Zillow Group Inc.",
        "price": 47.66
    }, {
        "symbol": "ACM",
        "label": "AECOM",
        "price": 50.33
    }, {
        "symbol": "HLI",
        "label": "Houlihan Lokey Inc. Class A",
        "price": 52.49
    }, {
        "symbol": "CMP",
        "label": "Compass Minerals Intl Inc",
        "price": 59.57
    }, {
        "symbol": "GGAL",
        "label": "Grupo Financiero Galicia S.A.",
        "price": 15.6
    }, {
        "symbol": "FTNT",
        "label": "Fortinet Inc.",
        "price": 117.49
    }, {
        "symbol": "CLDX",
        "label": "Celldex Therapeutics Inc.",
        "price": 2.45
    }, {
        "symbol": "WR",
        "label": "Westar Energy Inc.",
        "price": 53.84
    }, {
        "symbol": "CZZ",
        "label": "Cosan Limited Class A",
        "price": 22.59
    }, {
        "symbol": "MCF",
        "label": "Contango Oil & Gas Company",
        "price": 4.08
    }, {
        "symbol": "VXUS",
        "label": "Vanguard Total International Stock ETF",
        "price": 56.64
    }, {
        "symbol": "SPB",
        "label": "Spectrum Brands Holdings Inc.",
        "price": 58.47
    }, {
        "symbol": "IFF",
        "label": "Internationa Flavors & Fragrances Inc.",
        "price": 134.88
    }, {
        "symbol": "MDCA",
        "label": "MDC Partners Inc.",
        "price": 2.58
    }, {
        "symbol": "VCEL",
        "label": "Vericel Corporation",
        "price": 17.47
    }, {
        "symbol": "SRCL",
        "label": "Stericycle Inc.",
        "price": 63.35
    }, {
        "symbol": "RING",
        "label": "iShares MSCI Global Gold Miners ETF",
        "price": 23.47
    }, {
        "symbol": "RBBN",
        "label": "Ribbon Communications Inc.",
        "price": 3.21
    }, {
        "symbol": "DBO",
        "label": "PowerShares DB Oil Fund",
        "price": 9.09
    }, {
        "symbol": "RYI",
        "label": "Ryerson Holding Corporation",
        "price": 11.22
    }, {
        "symbol": "MAC",
        "label": "Macerich Company (The)",
        "price": 25.76
    }, {
        "symbol": "SENS",
        "label": "Senseonics Holdings Inc.",
        "price": 0.9499
    }, {
        "symbol": "IQV",
        "label": "IQVIA Holdings Inc.",
        "price": 161.51
    }, {
        "symbol": "WNS",
        "label": "WNS (Holdings) Limited Sponsored ADR (Jersey)",
        "price": 70.23
    }, {
        "symbol": "WTFC",
        "label": "Wintrust Financial Corporation",
        "price": 69.0
    }, {
        "symbol": "SGRY",
        "label": "Surgery Partners Inc.",
        "price": 18.43
    }, {
        "symbol": "VIV",
        "label": "Telefonica Brasil S.A. ADS",
        "price": 14.25
    }, {
        "symbol": "XPER",
        "label": "Xperi Corporation",
        "price": 18.71
    }, {
        "symbol": "FCF",
        "label": "First Commonwealth Financial Corporation",
        "price": 14.32
    }, {
        "symbol": "ERX",
        "label": "Direxion Energy Bull 3X Shares",
        "price": 16.28
    }, {
        "symbol": "VG",
        "label": "Vonage Holdings Corp.",
        "price": 8.5
    }, {
        "symbol": "VYM",
        "label": "Vanguard High Dividend Yield",
        "price": 94.78
    }, {
        "symbol": "SNP",
        "label": "China Petroleum & Chemical Corporation",
        "price": 60.7
    }, {
        "symbol": "AGEN",
        "label": "Agenus Inc.",
        "price": 3.94
    }, {
        "symbol": "RPAI",
        "label": "Retail Properties of America Inc. Class A",
        "price": 13.12
    }, {
        "symbol": "ANW",
        "label": "Aegean Marine Petroleum Network Inc.",
        "price": 0.61
    }, {
        "symbol": "WLH",
        "label": "Lyon William Homes (Class A)",
        "price": 22.06
    }, {
        "symbol": "IVR",
        "label": "INVESCO MORTGAGE CAPITAL INC",
        "price": 17.18
    }, {
        "symbol": "RPD",
        "label": "Rapid7 Inc.",
        "price": 62.77
    }, {
        "symbol": "GLNG",
        "label": "Golar LNG Limited",
        "price": 14.47
    }, {
        "symbol": "PETS",
        "label": "PetMed Express Inc.",
        "price": 27.26
    }, {
        "symbol": "TTI",
        "label": "Tetra Technologies Inc.",
        "price": 1.62
    }, {
        "symbol": "CETV",
        "label": "Central European Media Enterprises Ltd.",
        "price": 4.51
    }, {
        "symbol": "THS",
        "label": "Treehouse Foods Inc.",
        "price": 47.41
    }, {
        "symbol": "RPXC",
        "label": "RPX Corporation",
        "price": 10.49
    }, {
        "symbol": "AMBA",
        "label": "Ambarella Inc.",
        "price": 62.69
    }, {
        "symbol": "RRD",
        "label": "R.R. Donnelley & Sons Company",
        "price": 3.44
    }, {
        "symbol": "EDR",
        "label": "Education Realty Trust Inc.",
        "price": 41.49
    }, {
        "symbol": "AMKR",
        "label": "Amkor Technology Inc.",
        "price": 13.2
    }, {
        "symbol": "MNTA",
        "label": "Momenta Pharmaceuticals Inc.",
        "price": 30.6
    }, {
        "symbol": "TBI",
        "label": "TrueBlue Inc.",
        "price": 23.65
    }, {
        "symbol": "COUP",
        "label": "Coupa Software Incorporated",
        "price": 169.43
    }, {
        "symbol": "MNKD",
        "label": "MannKind Corporation",
        "price": 1.51
    }, {
        "symbol": "ICPT",
        "label": "Intercept Pharmaceuticals Inc.",
        "price": 106.11
    }, {
        "symbol": "MITK",
        "label": "Mitek Systems Inc.",
        "price": 9.42
    }, {
        "symbol": "BHE",
        "label": "Benchmark Electronics Inc.",
        "price": 36.84
    }, {
        "symbol": "PZZA",
        "label": "Papa John's International Inc.",
        "price": 65.06
    }, {
        "symbol": "BGI",
        "label": "Birks Group Inc.",
        "price": 0.9899
    }, {
        "symbol": "BCE",
        "label": "BCE Inc.",
        "price": 47.5
    }, {
        "symbol": "GOVT",
        "label": "iShares U.S. Treasury Bond",
        "price": 26.07
    }, {
        "symbol": "POOL",
        "label": "Pool Corporation",
        "price": 219.82
    }, {
        "symbol": "EQM",
        "label": "EQT Midstream Partners LP representing Limited Partner Interests",
        "price": 30.17
    }, {
        "symbol": "MGRC",
        "label": "McGrath RentCorp",
        "price": 77.94
    }, {
        "symbol": "MDCO",
        "label": "The Medicines Company",
        "price": 84.9
    }, {
        "symbol": "FANH",
        "label": "Fanhua Inc.",
        "price": 26.87
    }, {
        "symbol": "INFN",
        "label": "Infinera Corporation",
        "price": 8.12
    }, {
        "symbol": "MMSI",
        "label": "Merit Medical Systems Inc.",
        "price": 36.7
    }, {
        "symbol": "SDOW",
        "label": "UltraPro Short Dow30",
        "price": 56.42
    }, {
        "symbol": "PTN",
        "label": "Palatin Technologies Inc.",
        "price": 0.7101
    }, {
        "symbol": "FCB",
        "label": "FCB Financial Holdings Inc. Class A",
        "price": 33.62
    }, {
        "symbol": "ENIC",
        "label": "Enel Chile S.A. American Depositary Shares (Each representing 50 shares of)",
        "price": 4.99
    }, {
        "symbol": "SPXC",
        "label": "SPX Corporation",
        "price": 52.68
    }, {
        "symbol": "HEWG",
        "label": "iShares Currency Hedged MSCI Germany ETF",
        "price": 29.7867
    }, {
        "symbol": "ARCH",
        "label": "Arch Coal Inc. Class A",
        "price": 66.3
    }, {
        "symbol": "AM",
        "label": "Antero Midstream Partners LP representing limited partner interests",
        "price": 7.24
    }, {
        "symbol": "TGI",
        "label": "Triumph Group Inc.",
        "price": 22.66
    }, {
        "symbol": "AWK",
        "label": "American Water Works Company Inc.",
        "price": 130.44
    }, {
        "symbol": "REDU",
        "label": "RISE Education Cayman Ltd",
        "price": 7.13
    }, {
        "symbol": "DEPO",
        "label": "Depomed Inc.",
        "price": 7.3
    }, {
        "symbol": "R",
        "label": "Ryder System Inc.",
        "price": 55.33
    }, {
        "symbol": "SREV",
        "label": "ServiceSource International Inc.",
        "price": 1.79
    }, {
        "symbol": "OA",
        "label": "Orbital ATK Inc.",
        "price": 134.5
    }, {
        "symbol": "EXG",
        "label": "Eaton Vance Tax-Managed Global Diversified Equity Income Fund of Beneficial Inte" +
                "rest",
        "price": 9.08
    }, {
        "symbol": "BKH",
        "label": "Black Hills Corporation",
        "price": 81.03
    }, {
        "symbol": "AVD",
        "label": "American Vanguard Corporation ($0.10 Par Value)",
        "price": 18.88
    }, {
        "symbol": "CMCM",
        "label": "Cheetah Mobile Inc. American Depositary Shares each representing 10 Class",
        "price": 3.87
    }, {
        "symbol": "UPLD",
        "label": "Upland Software Inc.",
        "price": 40.67
    }, {
        "symbol": "HYS",
        "label": "PIMCO 0-5 Year High Yield Corporat Bond Index Exchange-Traded Fund",
        "price": 99.23
    }, {
        "symbol": "GPN",
        "label": "Global Payments Inc.",
        "price": 198.62
    }, {
        "symbol": "COLM",
        "label": "Columbia Sportswear Company",
        "price": 95.59
    }, {
        "symbol": "GLUU",
        "label": "Glu Mobile Inc.",
        "price": 6.17
    }, {
        "symbol": "HE",
        "label": "Hawaiian Electric Industries Inc.",
        "price": 47.48
    }, {
        "symbol": "SJI",
        "label": "South Jersey Industries Inc.",
        "price": 31.84
    }, {
        "symbol": "DWT",
        "label": "VelocityShares 3x Inverse Crude Oil ETNs linked to the S&P GSCI Crude Oil Index " +
                "ER",
        "price": 9.77
    }, {
        "symbol": "CCOI",
        "label": "Cogent Communications Holdings Inc.",
        "price": 73.23
    }, {
        "symbol": "SEDG",
        "label": "SolarEdge Technologies Inc.",
        "price": 104.75
    }, {
        "symbol": "LLNW",
        "label": "Limelight Networks Inc.",
        "price": 5.43
    }, {
        "symbol": "PBYI",
        "label": "Puma Biotechnology Inc",
        "price": 9.73
    }, {
        "symbol": "IRWD",
        "label": "Ironwood Pharmaceuticals Inc.",
        "price": 12.6
    }, {
        "symbol": "CSGS",
        "label": "CSG Systems International Inc.",
        "price": 51.21
    }, {
        "symbol": "FBM",
        "label": "Foundation Building Materials Inc.",
        "price": 19.31
    }, {
        "symbol": "VAR",
        "label": "Varian Medical Systems Inc.",
        "price": 149.0
    }, {
        "symbol": "CNSL",
        "label": "Consolidated Communications Holdings Inc.",
        "price": 4.25
    }, {
        "symbol": "SUPN",
        "label": "Supernus Pharmaceuticals Inc.",
        "price": 24.44
    }, {
        "symbol": "DPW",
        "label": "DPW Holdings Inc.",
        "price": 1.45
    }, {
        "symbol": "UGI",
        "label": "UGI Corporation",
        "price": 44.5
    }, {
        "symbol": "COLL",
        "label": "Collegium Pharmaceutical Inc.",
        "price": 23.41
    }, {
        "symbol": "SVU",
        "label": "SuperValu Inc.",
        "price": 32.5
    }, {
        "symbol": "DRNA",
        "label": "Dicerna Pharmaceuticals Inc.",
        "price": 21.355
    }, {
        "symbol": "BKCC",
        "label": "BlackRock Capital Investment Corporation",
        "price": 5.04
    }, {
        "symbol": "CBD",
        "label": "Companhia Brasileira de Distribuicao ADS",
        "price": 21.6
    }, {
        "symbol": "SMG",
        "label": "Scotts Miracle-Gro Company (The)",
        "price": 111.65
    }, {
        "symbol": "PACB",
        "label": "Pacific Biosciences of California Inc.",
        "price": 4.98
    }, {
        "symbol": "AVDL",
        "label": "Avadel Pharmaceuticals plc",
        "price": 7.63
    }, {
        "symbol": "APHB",
        "label": "AmpliPhi Biosciences Corporation",
        "price": 0.31
    }, {
        "symbol": "PGX",
        "label": "PowerShares Preferred Portfolio",
        "price": 14.6
    }, {
        "symbol": "JNCE",
        "label": "Jounce Therapeutics Inc.",
        "price": 7.24
    }, {
        "symbol": "SLVO",
        "label": "Credit Suisse Silver Shares Covered Call Exchange Traded Notes",
        "price": 7.07
    }, {
        "symbol": "WAT",
        "label": "Waters Corporation",
        "price": 239.6
    }, {
        "symbol": "NLNK",
        "label": "NewLink Genetics Corporation",
        "price": 2.04
    }, {
        "symbol": "MUB",
        "label": "iShares National Muni Bond",
        "price": 112.66
    }, {
        "symbol": "FLOW",
        "label": "SPX FLOW Inc.",
        "price": 47.15
    }, {
        "symbol": "MKSI",
        "label": "MKS Instruments Inc.",
        "price": 114.78
    }, {
        "symbol": "CWT",
        "label": "California Water Service Group",
        "price": 51.72
    }, {
        "symbol": "RESI",
        "label": "Front Yard Residential Corporation",
        "price": 12.03
    }, {
        "symbol": "NEAR",
        "label": "iShares Short Maturity Bond",
        "price": 50.31
    }, {
        "symbol": "MTB",
        "label": "M&T Bank Corporation",
        "price": 163.03
    }, {
        "symbol": "CRL",
        "label": "Charles River Laboratories International Inc.",
        "price": 159.78
    }, {
        "symbol": "AHGP",
        "label": "Alliance Holdings GP L.P.",
        "price": 28.37
    }, {
        "symbol": "KEYW",
        "label": "The KEYW Holding Corporation",
        "price": 11.27
    }, {
        "symbol": "CDK",
        "label": "CDK Global Inc.",
        "price": 56.63
    }, {
        "symbol": "FDS",
        "label": "FactSet Research Systems Inc.",
        "price": 275.72
    }, {
        "symbol": "JLL",
        "label": "Jones Lang LaSalle Incorporated",
        "price": 171.94
    }, {
        "symbol": "HSC",
        "label": "Harsco Corporation",
        "price": 19.95
    }, {
        "symbol": "LNTH",
        "label": "Lantheus Holdings Inc.",
        "price": 18.915
    }, {
        "symbol": "SAIL",
        "label": "SailPoint Technologies Holdings Inc.",
        "price": 25.53
    }, {
        "symbol": "OEF",
        "label": "iShares S&P 100",
        "price": 124.5
    }, {
        "symbol": "PNM",
        "label": "PNM Resources Inc. (Holding Co.)",
        "price": 51.38
    }, {
        "symbol": "VSAR",
        "label": "Versartis Inc.",
        "price": 1.22
    }, {
        "symbol": "AT",
        "label": "Atlantic Power Corporation (Canada)",
        "price": 2.42
    }, {
        "symbol": "PACW",
        "label": "PacWest Bancorp",
        "price": 37.99
    }, {
        "symbol": "MXL",
        "label": "MaxLinear Inc.",
        "price": 21.54
    }, {
        "symbol": "OLLI",
        "label": "Ollie's Bargain Outlet Holdings Inc.",
        "price": 53.34
    }, {
        "symbol": "SGYP",
        "label": "Synergy Pharmaceuticals Inc.",
        "price": 0.05
    }, {
        "symbol": "LOCO",
        "label": "El Pollo Loco Holdings Inc.",
        "price": 14.87
    }, {
        "symbol": "RUSHA",
        "label": "Rush Enterprises Inc. Class A Common Stock",
        "price": 46.17
    }, {
        "symbol": "APPN",
        "label": "Appian Corporation",
        "price": 53.09
    }, {
        "symbol": "RHE",
        "label": "Regional Health Properties Inc.",
        "price": 1.5
    }, {
        "symbol": "AVY",
        "label": "Avery Dennison Corporation",
        "price": 134.72
    }, {
        "symbol": "EGLT",
        "label": "Egalet Corporation",
        "price": 0.33
    }, {
        "symbol": "WMS",
        "label": "Advanced Drainage Systems Inc.",
        "price": 41.78
    }, {
        "symbol": "MCFT",
        "label": "MCBC Holdings Inc.",
        "price": 15.61
    }, {
        "symbol": "DHT",
        "label": "DHT Holdings Inc.",
        "price": 7.51
    }, {
        "symbol": "AVA",
        "label": "Avista Corporation",
        "price": 49.3
    }, {
        "symbol": "WLK",
        "label": "Westlake Chemical Corporation",
        "price": 71.19
    }, {
        "symbol": "PSA",
        "label": "Public Storage",
        "price": 220.75
    }, {
        "symbol": "AAWW",
        "label": "Atlas Air Worldwide Holdings",
        "price": 27.85
    }, {
        "symbol": "WIX",
        "label": "Wix.com Ltd.",
        "price": 140.27
    }, {
        "symbol": "USCR",
        "label": "U S Concrete Inc.",
        "price": 40.16
    }, {
        "symbol": "INO",
        "label": "Inovio Pharmaceuticals Inc.",
        "price": 3.29
    }, {
        "symbol": "NTB",
        "label": "Bank of N.T. Butterfield & Son Limited (The) Voting",
        "price": 35.92
    }, {
        "symbol": "AAOI",
        "label": "Applied Optoelectronics Inc.",
        "price": 14.12
    }, {
        "symbol": "ERI",
        "label": "Eldorado Resorts Inc.",
        "price": 61.34
    }, {
        "symbol": "FOSL",
        "label": "Fossil Group Inc.",
        "price": 8.315
    }, {
        "symbol": "STE",
        "label": "STERIS plc",
        "price": 154.13
    }, {
        "symbol": "OSTK",
        "label": "Overstock.com Inc.",
        "price": 8.53
    }, {
        "symbol": "SCHE",
        "label": "Schwab Emerging Markets Equity",
        "price": 28.19
    }, {
        "symbol": "FOXF",
        "label": "Fox Factory Holding Corp.",
        "price": 68.75
    }, {
        "symbol": "MDGS",
        "label": "Medigus Ltd.",
        "price": 1.88
    }, {
        "symbol": "AZPN",
        "label": "Aspen Technology Inc.",
        "price": 141.7
    }, {
        "symbol": "DCI",
        "label": "Donaldson Company Inc.",
        "price": 55.87
    }, {
        "symbol": "FTAI",
        "label": "Fortress Transportation and Infrastructure Investors LLC",
        "price": 19.38
    }, {
        "symbol": "DSS",
        "label": "Document Security Systems Inc.",
        "price": 0.3118
    }, {
        "symbol": "ONVO",
        "label": "Organovo Holdings Inc.",
        "price": 0.4579
    }, {
        "symbol": "KERX",
        "label": "Keryx Biopharmaceuticals Inc.",
        "price": 3.35
    }, {
        "symbol": "AIZ",
        "label": "Assurant Inc.",
        "price": 133.77
    }, {
        "symbol": "IRBT",
        "label": "iRobot Corporation",
        "price": 54.82
    }, {
        "symbol": "SBLK",
        "label": "Star Bulk Carriers Corp.",
        "price": 10.62
    }, {
        "symbol": "CWST",
        "label": "Casella Waste Systems Inc.",
        "price": 49.55
    }, {
        "symbol": "BURL",
        "label": "Burlington Stores Inc.",
        "price": 226.97
    }, {
        "symbol": "LEG",
        "label": "Leggett & Platt Incorporated",
        "price": 51.2
    }, {
        "symbol": "COR",
        "label": "CoreSite Realty Corporation",
        "price": 117.55
    }, {
        "symbol": "AAN",
        "label": "Aaron's Inc.",
        "price": 61.38
    }, {
        "symbol": "SOI",
        "label": "Solaris Oilfield Infrastructure Inc. Class A",
        "price": 13.42
    }, {
        "symbol": "PRA",
        "label": "ProAssurance Corporation",
        "price": 37.06
    }, {
        "symbol": "GPMT",
        "label": "Granite Point Mortgage Trust Inc.",
        "price": 18.46
    }, {
        "symbol": "EDZ",
        "label": "Direxion Emerging Markets Bear 3X Shares",
        "price": 50.33
    }, {
        "symbol": "NBLX",
        "label": "Noble Midstream Partners LP Representing Limited Partner Interests",
        "price": 25.74
    }, {
        "symbol": "WMIH",
        "label": "WMIH Corp.",
        "price": 1.31
    }, {
        "symbol": "INT",
        "label": "World Fuel Services Corporation",
        "price": 41.28
    }, {
        "symbol": "UMBF",
        "label": "UMB Financial Corporation",
        "price": 67.85
    }, {
        "symbol": "LPG",
        "label": "Dorian LPG Ltd.",
        "price": 14.83
    }, {
        "symbol": "SOHU",
        "label": "Sohu.com Inc.",
        "price": 12.28
    }, {
        "symbol": "IPHI",
        "label": "Inphi Corporation $0.001 par value",
        "price": 83.11
    }, {
        "symbol": "FGEN",
        "label": "FibroGen Inc",
        "price": 44.35
    }, {
        "symbol": "BAP",
        "label": "Credicorp Ltd.",
        "price": 216.85
    }, {
        "symbol": "BC",
        "label": "Brunswick Corporation",
        "price": 58.75
    }, {
        "symbol": "FTR",
        "label": "Frontier Communications Corporation",
        "price": 0.6401
    }, {
        "symbol": "OCSL",
        "label": "Oaktree Specialty Lending Corporation",
        "price": 5.56
    }, {
        "symbol": "MBUU",
        "label": "Malibu Boats Inc.",
        "price": 42.62
    }, {
        "symbol": "ACHN",
        "label": "Achillion Pharmaceuticals Inc.",
        "price": 6.19
    }, {
        "symbol": "HTLF",
        "label": "Heartland Financial USA Inc.",
        "price": 49.61
    }, {
        "symbol": "BLKB",
        "label": "Blackbaud Inc.",
        "price": 81.78
    }, {
        "symbol": "SNN",
        "label": "Smith & Nephew SNATS Inc.",
        "price": 49.29
    }, {
        "symbol": "CUBI",
        "label": "Customers Bancorp Inc",
        "price": 22.56
    }, {
        "symbol": "ENPH",
        "label": "Enphase Energy Inc.",
        "price": 31.5
    }, {
        "symbol": "CADE",
        "label": "Cadence Bancorporation Class A",
        "price": 17.99
    }, {
        "symbol": "AJRD",
        "label": "Aerojet Rocketdyne Holdings Inc.",
        "price": 53.13
    }, {
        "symbol": "APAM",
        "label": "Artisan Partners Asset Management Inc. Class A",
        "price": 34.97
    }, {
        "symbol": "DXGE",
        "label": "WisdomTree Germany Hedged Equity Fund",
        "price": 32.24
    }, {
        "symbol": "PEI",
        "label": "Pennsylvania Real Estate Investment Trust",
        "price": 5.05
    }, {
        "symbol": "HLIT",
        "label": "Harmonic Inc.",
        "price": 8.21
    }, {
        "symbol": "ST",
        "label": "Sensata Technologies Holding N.V.",
        "price": 52.71
    }, {
        "symbol": "IPAR",
        "label": "Inter Parfums Inc.",
        "price": 73.67
    }, {
        "symbol": "CRTO",
        "label": "Criteo S.A.",
        "price": 14.02
    }, {
        "symbol": "AKR",
        "label": "Acadia Realty Trust",
        "price": 25.48
    }, {
        "symbol": "CALI",
        "label": "China Auto Logistics Inc.",
        "price": 2.39
    }, {
        "symbol": "MRTX",
        "label": "Mirati Therapeutics Inc.",
        "price": 97.05
    }, {
        "symbol": "ZUMZ",
        "label": "Zumiez Inc.",
        "price": 34.53
    }, {
        "symbol": "OAK",
        "label": "Oaktree Capital Group LLC Class A Units Representing Limited Liability Company I" +
                "nterests",
        "price": 48.89
    }, {
        "symbol": "BRSS",
        "label": "Global Brass and Copper Holdings Inc.",
        "price": 43.59
    }, {
        "symbol": "CHKR",
        "label": "Chesapeake Granite Wash Trust representing beneficial interests in the Trust",
        "price": 0.6163
    }, {
        "symbol": "MLI",
        "label": "Mueller Industries Inc.",
        "price": 32.07
    }, {
        "symbol": "ROG",
        "label": "Rogers Corporation",
        "price": 133.75
    }, {
        "symbol": "UMC",
        "label": "United Microelectronics Corporation (NEW)",
        "price": 2.63
    }, {
        "symbol": "EWD",
        "label": "iShares Inc MSCI Sweden",
        "price": 33.55
    }, {
        "symbol": "QHC",
        "label": "Quorum Health Corporation",
        "price": 1.12
    }, {
        "symbol": "TSS",
        "label": "Total System Services Inc.",
        "price": 124.87
    }, {
        "symbol": "BAND",
        "label": "Bandwidth Inc.",
        "price": 71.11
    }, {
        "symbol": "ABMD",
        "label": "ABIOMED Inc.",
        "price": 181.28
    }, {
        "symbol": "KEYS",
        "label": "Keysight Technologies Inc.",
        "price": 105.37
    }, {
        "symbol": "HRC",
        "label": "Hill-Rom Holdings Inc",
        "price": 115.75
    }, {
        "symbol": "SSP",
        "label": "E.W. Scripps Company (The)",
        "price": 16.38
    }, {
        "symbol": "FRT",
        "label": "Federal Realty Investment Trust",
        "price": 129.53
    }, {
        "symbol": "NDSN",
        "label": "Nordson Corporation",
        "price": 169.5
    }, {
        "symbol": "LOXO",
        "label": "Loxo Oncology Inc.",
        "price": 234.76
    }, {
        "symbol": "ALSK",
        "label": "Alaska Communications Systems Group Inc.",
        "price": 1.97
    }, {
        "symbol": "ASC",
        "label": "Ardmore Shipping Corporation",
        "price": 7.3
    }, {
        "symbol": "BATRK",
        "label": "Liberty Media Corporation Series C Liberty Braves Common Stock",
        "price": 29.33
    }, {
        "symbol": "STC",
        "label": "Stewart Information Services Corporation",
        "price": 40.77
    }, {
        "symbol": "IRT",
        "label": "Independence Realty Trust Inc.",
        "price": 14.81
    }, {
        "symbol": "FFBC",
        "label": "First Financial Bancorp.",
        "price": 25.39
    }, {
        "symbol": "DLTH",
        "label": "Duluth Holdings Inc.",
        "price": 9.39
    }, {
        "symbol": "LII",
        "label": "Lennox International Inc.",
        "price": 243.63
    }, {
        "symbol": "SCHV",
        "label": "Schwab U.S. Large-Cap Value",
        "price": 61.03
    }, {
        "symbol": "OSG",
        "label": "Overseas Shipholding Group Inc. Class A",
        "price": 2.02
    }, {
        "symbol": "KTWO",
        "label": "K2M Group Holdings Inc.",
        "price": 27.5
    }, {
        "symbol": "GOOS",
        "label": "Canada Goose Holdings Inc. Subordinate",
        "price": 34.44
    }, {
        "symbol": "MRT",
        "label": "MedEquities Realty Trust Inc.",
        "price": 11.04
    }, {
        "symbol": "AXTI",
        "label": "AXT Inc",
        "price": 4.9
    }, {
        "symbol": "EGRX",
        "label": "Eagle Pharmaceuticals Inc.",
        "price": 58.97
    }, {
        "symbol": "BMO",
        "label": "Bank Of Montreal",
        "price": 79.42
    }, {
        "symbol": "EVV",
        "label": "Eaton Vance Limited Duration Income Fund of Beneficial Interest",
        "price": 13.3
    }, {
        "symbol": "DBVT",
        "label": "DBV Technologies S.A.",
        "price": 12.67
    }, {
        "symbol": "EPAM",
        "label": "EPAM Systems Inc.",
        "price": 232.43
    }, {
        "symbol": "GDEN",
        "label": "Golden Entertainment Inc.",
        "price": 18.92
    }, {
        "symbol": "PVH",
        "label": "PVH Corp.",
        "price": 100.95
    }, {
        "symbol": "CRBP",
        "label": "Corbus Pharmaceuticals Holdings Inc.",
        "price": 7.01
    }, {
        "symbol": "MTD",
        "label": "Mettler-Toledo International Inc.",
        "price": 831.31
    }, {
        "symbol": "BCRX",
        "label": "BioCryst Pharmaceuticals Inc.",
        "price": 2.78
    }, {
        "symbol": "ASNA",
        "label": "Ascena Retail Group Inc.",
        "price": 6.5
    }, {
        "symbol": "TOWN",
        "label": "Towne Bank",
        "price": 28.03
    }, {
        "symbol": "CBPO",
        "label": "China Biologic Products Holdings Inc.",
        "price": 115.8
    }, {
        "symbol": "FIVN",
        "label": "Five9 Inc.",
        "price": 73.58
    }, {
        "symbol": "EEV",
        "label": "ProShares UltraShort MSCI Emerging Markets",
        "price": 45.6
    }, {
        "symbol": "EGHT",
        "label": "8x8 Inc",
        "price": 20.64
    }, {
        "symbol": "ESS",
        "label": "Essex Property Trust Inc.",
        "price": 308.87
    }, {
        "symbol": "JKHY",
        "label": "Jack Henry & Associates Inc.",
        "price": 149.47
    }, {
        "symbol": "INGR",
        "label": "Ingredion Incorporated",
        "price": 92.58
    }, {
        "symbol": "HT",
        "label": "Hersha Hospitality Trust Class A of Beneficial Interest",
        "price": 13.9
    }, {
        "symbol": "EPAY",
        "label": "Bottomline Technologies Inc.",
        "price": 54.05
    }, {
        "symbol": "GTXI",
        "label": "GTx Inc.",
        "price": 1.28
    }, {
        "symbol": "BAS",
        "label": "Basic Energy Services Inc.",
        "price": 0.435
    }, {
        "symbol": "CPHI",
        "label": "China Pharma Holdings Inc.",
        "price": 0.2931
    }, {
        "symbol": "EFAV",
        "label": "iShares Edge MSCI Min Vol EAFE",
        "price": 75.75
    }, {
        "symbol": "ADSW",
        "label": "Advanced Disposal Services Inc.",
        "price": 32.94
    }, {
        "symbol": "WPC",
        "label": "W.P. Carey Inc. REIT",
        "price": 83.14
    }, {
        "symbol": "VRSK",
        "label": "Verisk Analytics Inc.",
        "price": 160.55
    }, {
        "symbol": "ICLR",
        "label": "ICON plc",
        "price": 175.78
    }, {
        "symbol": "AQMS",
        "label": "Aqua Metals Inc.",
        "price": 0.71
    }, {
        "symbol": "AWR",
        "label": "American States Water Company",
        "price": 87.21
    }, {
        "symbol": "DRRX",
        "label": "DURECT Corporation",
        "price": 2.51
    }, {
        "symbol": "VRA",
        "label": "Vera Bradley Inc.",
        "price": 11.14
    }, {
        "symbol": "WLKP",
        "label": "Westlake Chemical Partners LP representing limited partner interests",
        "price": 24.44
    }, {
        "symbol": "SNHY",
        "label": "Sun Hydraulics Corporation",
        "price": 44.71
    }, {
        "symbol": "BFAM",
        "label": "Bright Horizons Family Solutions Inc.",
        "price": 162.25
    }, {
        "symbol": "IOVA",
        "label": "Iovance Biotherapeutics Inc.",
        "price": 23.875
    }, {
        "symbol": "TEN",
        "label": "Tenneco Inc.",
        "price": 10.79
    }, {
        "symbol": "SUP",
        "label": "Superior Industries International Inc. (DE)",
        "price": 3.45
    }, {
        "symbol": "LL",
        "label": "Lumber Liquidators Holdings Inc",
        "price": 9.35
    }, {
        "symbol": "BID",
        "label": "Sotheby's",
        "price": 33.51
    }, {
        "symbol": "TRXC",
        "label": "TransEnterix Inc.",
        "price": 1.49
    }, {
        "symbol": "LEA",
        "label": "Lear Corporation",
        "price": 140.66
    }, {
        "symbol": "APD",
        "label": "Air Products and Chemicals Inc.",
        "price": 237.0
    }, {
        "symbol": "OSUR",
        "label": "OraSure Technologies Inc.",
        "price": 8.22
    }, {
        "symbol": "AVT",
        "label": "Avnet Inc.",
        "price": 41.86
    }, {
        "symbol": "SXC",
        "label": "SunCoke Energy Inc.",
        "price": 6.02
    }, {
        "symbol": "SPLV",
        "label": "PowerShares S&P 500 Low Volatility Portfolio",
        "price": 59.76
    }, {
        "symbol": "ATTO",
        "label": "Atento S.A.",
        "price": 2.75
    }, {
        "symbol": "DVY",
        "label": "iShares Select Dividend ETF",
        "price": 107.3
    }, {
        "symbol": "NVCR",
        "label": "NovoCure Limited",
        "price": 92.58
    }, {
        "symbol": "DAR",
        "label": "Darling Ingredients Inc.",
        "price": 29.27
    }, {
        "symbol": "PCH",
        "label": "PotlatchDeltic Corporation",
        "price": 44.68
    }, {
        "symbol": "SAFM",
        "label": "Sanderson Farms Inc.",
        "price": 158.31
    }, {
        "symbol": "CRIS",
        "label": "Curis Inc.",
        "price": 1.71
    }, {
        "symbol": "RMBS",
        "label": "Rambus Inc.",
        "price": 14.37
    }, {
        "symbol": "PRTK",
        "label": "Paratek Pharmaceuticals Inc.",
        "price": 3.64
    }, {
        "symbol": "MLPI",
        "label": "UBS AG ETN",
        "price": 21.95
    }, {
        "symbol": "AJG",
        "label": "Arthur J. Gallagher & Co.",
        "price": 96.45
    }, {
        "symbol": "RGA",
        "label": "Reinsurance Group of America Incorporated",
        "price": 160.59
    }, {
        "symbol": "GMLP",
        "label": "Golar LNG Partners LP",
        "price": 8.59
    }, {
        "symbol": "TAST",
        "label": "Carrols Restaurant Group Inc.",
        "price": 5.39
    }, {
        "symbol": "KB",
        "label": "KB Financial Group Inc",
        "price": 41.02
    }, {
        "symbol": "OTEX",
        "label": "Open Text Corporation",
        "price": 46.94
    }, {
        "symbol": "FHB",
        "label": "First Hawaiian Inc.",
        "price": 28.93
    }, {
        "symbol": "SANM",
        "label": "Sanmina Corporation",
        "price": 34.33
    }, {
        "symbol": "SNV",
        "label": "Synovus Financial Corp.",
        "price": 40.0
    }, {
        "symbol": "EUFN",
        "label": "iShares MSCI Europe Financials Sector Index Fund",
        "price": 19.21
    }, {
        "symbol": "MOD",
        "label": "Modine Manufacturing Company",
        "price": 7.65
    }, {
        "symbol": "MGI",
        "label": "Moneygram International Inc.",
        "price": 2.65
    }, {
        "symbol": "WBC",
        "label": "Wabco Holdings Inc.",
        "price": 135.43
    }, {
        "symbol": "NSH",
        "label": "Nustar GP Holdings LLC Units",
        "price": 13.73
    }, {
        "symbol": "SNA",
        "label": "Snap-On Incorporated",
        "price": 170.0
    }, {
        "symbol": "ELGX",
        "label": "Endologix Inc.",
        "price": 2.03
    }, {
        "symbol": "KOPN",
        "label": "Kopin Corporation",
        "price": 0.51
    }, {
        "symbol": "CLDT",
        "label": "Chatham Lodging Trust (REIT) of Beneficial Interest",
        "price": 18.07
    }, {
        "symbol": "AIMT",
        "label": "Aimmune Therapeutics Inc.",
        "price": 34.92
    }, {
        "symbol": "OSBC",
        "label": "Old Second Bancorp Inc.",
        "price": 13.22
    }, {
        "symbol": "BRKL",
        "label": "Brookline Bancorp Inc.",
        "price": 16.11
    }, {
        "symbol": "DEO",
        "label": "Diageo plc",
        "price": 170.7
    }, {
        "symbol": "UNFI",
        "label": "United Natural Foods Inc.",
        "price": 8.36
    }, {
        "symbol": "PFF",
        "label": "iShares U.S. Preferred Stock ETF",
        "price": 38.07
    }, {
        "symbol": "ONDK",
        "label": "On Deck Capital Inc.",
        "price": 4.19
    }, {
        "symbol": "SAH",
        "label": "Sonic Automotive Inc.",
        "price": 31.23
    }, {
        "symbol": "NVTA",
        "label": "Invitae Corporation",
        "price": 19.63
    }, {
        "symbol": "HZO",
        "label": "MarineMax Inc. (FL)",
        "price": 17.85
    }, {
        "symbol": "TNK",
        "label": "Teekay Tankers Ltd.",
        "price": 22.36
    }, {
        "symbol": "GSG",
        "label": "iShares GSCI Commodity-Indexed Trust Fund",
        "price": 14.72
    }, {
        "symbol": "SATS",
        "label": "EchoStar Corporation",
        "price": 42.94
    }, {
        "symbol": "KANG",
        "label": "iKang Healthcare Group Inc.",
        "price": 20.54
    }, {
        "symbol": "SLGN",
        "label": "Silgan Holdings Inc.",
        "price": 31.57
    }, {
        "symbol": "IBKR",
        "label": "Interactive Brokers Group Inc.",
        "price": 53.08
    }, {
        "symbol": "VSM",
        "label": "Versum Materials Inc.",
        "price": 51.38
    }, {
        "symbol": "HMC",
        "label": "Honda Motor Company Ltd.",
        "price": 27.78
    }, {
        "symbol": "GPI",
        "label": "Group 1 Automotive Inc.",
        "price": 102.61
    }, {
        "symbol": "VLP",
        "label": "Valero Energy Partners LP representing limited partner interests",
        "price": 42.24
    }, {
        "symbol": "CPA",
        "label": "Copa Holdings S.A. Class A",
        "price": 103.51
    }, {
        "symbol": "ROCK",
        "label": "Gibraltar Industries Inc.",
        "price": 53.82
    }, {
        "symbol": "RNWK",
        "label": "RealNetworks Inc.",
        "price": 1.46
    }, {
        "symbol": "HOV",
        "label": "Hovnanian Enterprises Inc. Class A",
        "price": 27.09
    }, {
        "symbol": "ASTE",
        "label": "Astec Industries Inc.",
        "price": 42.99
    }, {
        "symbol": "BWP",
        "label": "Boardwalk Pipeline Partners LP",
        "price": 12.06
    }, {
        "symbol": "AVXS",
        "label": "AveXis Inc.",
        "price": 217.94
    }, {
        "symbol": "ACET",
        "label": "Aceto Corporation",
        "price": 0.14
    }, {
        "symbol": "CTLT",
        "label": "Catalent Inc.",
        "price": 60.88
    }, {
        "symbol": "ROBO",
        "label": "ROBO Global Robotics and Automation Index ETF",
        "price": 43.73
    }, {
        "symbol": "DMRC",
        "label": "Digimarc Corporation",
        "price": 34.97
    }, {
        "symbol": "CUTR",
        "label": "Cutera Inc.",
        "price": 33.91
    }, {
        "symbol": "UEPS",
        "label": "Net 1 UEPS Technologies Inc.",
        "price": 3.75
    }, {
        "symbol": "AMCX",
        "label": "AMC Networks Inc.",
        "price": 42.25
    }, {
        "symbol": "CALD",
        "label": "Callidus Software Inc.",
        "price": 35.93
    }, {
        "symbol": "DXD",
        "label": "ProShares UltraShort Dow30",
        "price": 29.03
    }, {
        "symbol": "NMM",
        "label": "Navios Maritime Partners LP Representing Limited Partner Interests",
        "price": 16.15
    }, {
        "symbol": "SAIC",
        "label": "SCIENCE APPLICATIONS INTERNATIONAL CORPORATION",
        "price": 92.58
    }, {
        "symbol": "QLD",
        "label": "ProShares Ultra QQQ",
        "price": 134.27
    }, {
        "symbol": "DIOD",
        "label": "Diodes Incorporated",
        "price": 58.95
    }, {
        "symbol": "RARE",
        "label": "Ultragenyx Pharmaceutical Inc.",
        "price": 59.96
    }, {
        "symbol": "CQH",
        "label": "Cheniere Energy Partners LP Holdings LLC",
        "price": 31.97
    }, {
        "symbol": "INOV",
        "label": "Inovalon Holdings Inc.",
        "price": 20.09
    }, {
        "symbol": "GOV",
        "label": "Government Properties Income Trust",
        "price": 6.89
    }, {
        "symbol": "TPX",
        "label": "Tempur Sealy International Inc.",
        "price": 87.86
    }, {
        "symbol": "CBM",
        "label": "Cambrex Corporation",
        "price": 59.99
    }, {
        "symbol": "MYOK",
        "label": "MyoKardia Inc.",
        "price": 73.74
    }, {
        "symbol": "BNFT",
        "label": "Benefitfocus Inc.",
        "price": 20.89
    }, {
        "symbol": "VVR",
        "label": "Invesco Senior Income Trust (DE)",
        "price": 4.29
    }, {
        "symbol": "IYE",
        "label": "iShares U.S. Energy",
        "price": 31.55
    }, {
        "symbol": "PLYA",
        "label": "Playa Hotels & Resorts N.V.",
        "price": 7.49
    }, {
        "symbol": "CSFL",
        "label": "CenterState Bank Corporation",
        "price": 24.48
    }, {
        "symbol": "CFFN",
        "label": "Capitol Federal Financial Inc.",
        "price": 13.82
    }, {
        "symbol": "HSII",
        "label": "Heidrick & Struggles International Inc.",
        "price": 32.08
    }, {
        "symbol": "GDS",
        "label": "GDS Holdings Limited",
        "price": 54.9
    }, {
        "symbol": "UL",
        "label": "Unilever PLC",
        "price": 57.34
    }, {
        "symbol": "LOPE",
        "label": "Grand Canyon Education Inc.",
        "price": 97.51
    }, {
        "symbol": "FAC",
        "label": "First Acceptance Corporation",
        "price": 0.87
    }, {
        "symbol": "TCMD",
        "label": "Tactile Systems Technology Inc.",
        "price": 60.82
    }, {
        "symbol": "HVT",
        "label": "Haverty Furniture Companies Inc.",
        "price": 20.77
    }, {
        "symbol": "EWQ",
        "label": "iShares MSCI France Index Fund",
        "price": 29.7
    }, {
        "symbol": "GOLD",
        "label": "Randgold Resources Limited",
        "price": 17.95
    }, {
        "symbol": "RLI",
        "label": "RLI Corp.",
        "price": 93.62
    }, {
        "symbol": "KEX",
        "label": "Kirby Corporation",
        "price": 90.46
    }, {
        "symbol": "SPYG",
        "label": "SPDR Series Trust Portfolio S&P 500 Growth",
        "price": 43.94
    }, {
        "symbol": "SMTC",
        "label": "Semtech Corporation",
        "price": 54.97
    }, {
        "symbol": "OR",
        "label": "Osisko Gold Royalties Ltd",
        "price": 9.49
    }, {
        "symbol": "XNCR",
        "label": "Xencor Inc.",
        "price": 37.55
    }, {
        "symbol": "B",
        "label": "Barnes Group Inc.",
        "price": 66.79
    }, {
        "symbol": "DIN",
        "label": "Dine Brands Global Inc.",
        "price": 87.69
    }, {
        "symbol": "SCHN",
        "label": "Schnitzer Steel Industries Inc.",
        "price": 20.73
    }, {
        "symbol": "OMN",
        "label": "OMNOVA Solutions Inc.",
        "price": 10.12
    }, {
        "symbol": "NM",
        "label": "Navios Maritime Holdings Inc.",
        "price": 3.91
    }, {
        "symbol": "HSON",
        "label": "Hudson Global Inc.",
        "price": 13.05
    }, {
        "symbol": "UBSI",
        "label": "United Bankshares Inc.",
        "price": 37.79
    }, {
        "symbol": "HEZU",
        "label": "iShares Currency Hedged MSCI Eurozone",
        "price": 32.47
    }, {
        "symbol": "FPRX",
        "label": "Five Prime Therapeutics Inc.",
        "price": 5.31
    }, {
        "symbol": "GVA",
        "label": "Granite Construction Incorporated",
        "price": 26.76
    }, {
        "symbol": "EVTC",
        "label": "Evertec Inc.",
        "price": 34.48
    }, {
        "symbol": "IVW",
        "label": "iShares S&P 500 Growth",
        "price": 202.88
    }, {
        "symbol": "SMCI",
        "label": "Super Micro Computer Inc.",
        "price": 15.6
    }, {
        "symbol": "FLXN",
        "label": "Flexion Therapeutics Inc.",
        "price": 17.51
    }, {
        "symbol": "HDGE",
        "label": "Ranger Equity Bear Bear",
        "price": 6.67
    }, {
        "symbol": "DESP",
        "label": "Despegar.com Corp.",
        "price": 13.71
    }, {
        "symbol": "ODFL",
        "label": "Old Dominion Freight Line Inc.",
        "price": 208.44
    }, {
        "symbol": "CFMS",
        "label": "ConforMIS Inc.",
        "price": 1.27
    }, {
        "symbol": "NVTR",
        "label": "Nuvectra Corporation",
        "price": 0.13
    }, {
        "symbol": "TGP",
        "label": "Teekay LNG Partners L.P.",
        "price": 14.72
    }, {
        "symbol": "VRP",
        "label": "PowerShares Variable Rate Preferred Portfolio",
        "price": 24.71
    }, {
        "symbol": "NFJ",
        "label": "AllianzGI NFJ Dividend Interest & Premium Strategy Fund",
        "price": 13.24
    }, {
        "symbol": "ENZ",
        "label": "Enzo Biochem Inc. ($0.01 Par Value)",
        "price": 2.63
    }, {
        "symbol": "KRA",
        "label": "Kraton Corporation",
        "price": 23.57
    }, {
        "symbol": "NSU",
        "label": "Nevsun Resources Ltd",
        "price": 4.45
    }, {
        "symbol": "WLTW",
        "label": "Willis Towers Watson Public Limited Company",
        "price": 204.99
    }, {
        "symbol": "TRCO",
        "label": "Tribune Media Company Class A",
        "price": 46.2
    }, {
        "symbol": "GGN",
        "label": "GAMCO Global Gold Natural Resources & Income Trust",
        "price": 4.37
    }, {
        "symbol": "QTM",
        "label": "Quantum Corporation",
        "price": 1.62
    }, {
        "symbol": "CDXS",
        "label": "Codexis Inc.",
        "price": 18.11
    }, {
        "symbol": "TFI",
        "label": "SPDR Nuveen Bloomberg Barclays Municipal Bond",
        "price": 50.08
    }, {
        "symbol": "FCFS",
        "label": "First Cash Inc.",
        "price": 83.88
    }, {
        "symbol": "MB",
        "label": "MINDBODY Inc.",
        "price": 36.46
    }, {
        "symbol": "I",
        "label": "Intelsat S.A.",
        "price": 7.03
    }, {
        "symbol": "FSNN",
        "label": "Fusion Telecommunications International Inc.",
        "price": 0.14
    }, {
        "symbol": "URG",
        "label": "Ur-Energy Inc (Canada)",
        "price": 0.57
    }, {
        "symbol": "LTXB",
        "label": "LegacyTexas Financial Group Inc.",
        "price": 38.05
    }, {
        "symbol": "VREX",
        "label": "Varex Imaging Corporation",
        "price": 31.32
    }, {
        "symbol": "KDMN",
        "label": "Kadmon Holdings Inc.",
        "price": 4.54
    }, {
        "symbol": "CNS",
        "label": "Cohen & Steers Inc",
        "price": 66.15
    }, {
        "symbol": "LM",
        "label": "Legg Mason Inc.",
        "price": 37.75
    }, {
        "symbol": "BITA",
        "label": "Bitauto Holdings Limited American Depositary Shares (each representing one)",
        "price": 15.32
    }, {
        "symbol": "LXU",
        "label": "LSB Industries Inc.",
        "price": 4.09
    }, {
        "symbol": "SON",
        "label": "Sonoco Products Company",
        "price": 59.73
    }, {
        "symbol": "GDOT",
        "label": "Green Dot Corporation Class A $0.001 par value",
        "price": 26.4
    }, {
        "symbol": "ROP",
        "label": "Roper Technologies Inc.",
        "price": 379.74
    }, {
        "symbol": "NLS",
        "label": "Nautilus Inc.",
        "price": 3.53
    }, {
        "symbol": "SITE",
        "label": "SiteOne Landscape Supply Inc.",
        "price": 94.67
    }, {
        "symbol": "LSXMK",
        "label": "Liberty Media Corporation Series C Liberty SiriusXM Common Stock",
        "price": 50.01
    }, {
        "symbol": "MSTR",
        "label": "MicroStrategy Incorporated",
        "price": 146.74
    }, {
        "symbol": "NFEC",
        "label": "NF Energy Saving Corporation",
        "price": 6.93
    }, {
        "symbol": "COHU",
        "label": "Cohu Inc.",
        "price": 25.15
    }, {
        "symbol": "RBA",
        "label": "Ritchie Bros. Auctioneers Incorporated",
        "price": 44.32
    }, {
        "symbol": "OTIC",
        "label": "Otonomy Inc.",
        "price": 3.57
    }, {
        "symbol": "OPB",
        "label": "Opus Bank",
        "price": 25.48
    }, {
        "symbol": "BT",
        "label": "BT Group plc American Depositary Shares",
        "price": 13.05
    }, {
        "symbol": "ABAX",
        "label": "ABAXIS Inc.",
        "price": 83.06
    }, {
        "symbol": "CPRX",
        "label": "Catalyst Pharmaceuticals Inc.",
        "price": 4.16
    }, {
        "symbol": "ZIOP",
        "label": "ZIOPHARM Oncology Inc",
        "price": 4.64
    }, {
        "symbol": "EFR",
        "label": "Eaton Vance Senior Floating-Rate Fund of Beneficial Interest",
        "price": 13.78
    }, {
        "symbol": "GRMN",
        "label": "Garmin Ltd.",
        "price": 99.04
    }, {
        "symbol": "SPDW",
        "label": "SPDR Portfolio Developed World ex-US",
        "price": 31.78
    }, {
        "symbol": "MEDP",
        "label": "Medpace Holdings Inc.",
        "price": 90.95
    }, {
        "symbol": "TEF",
        "label": "Telefonica SA",
        "price": 6.84
    }, {
        "symbol": "EXIV",
        "label": "VelocityShares 1x Daily Inverse VSTOXX Futures ETN",
        "price": 32.27
    }, {
        "symbol": "CSLT",
        "label": "Castlight Health Inc. Class B",
        "price": 1.38
    }, {
        "symbol": "KEP",
        "label": "Korea Electric Power Corporation",
        "price": 11.48
    }, {
        "symbol": "CEIX",
        "label": "CONSOL Energy Inc.",
        "price": 11.1
    }, {
        "symbol": "GIL",
        "label": "Gildan Activewear Inc. Class A Sub. Vot.",
        "price": 29.71
    }, {
        "symbol": "NEWM",
        "label": "New Media Investment Group Inc.",
        "price": 6.28
    }, {
        "symbol": "TYPE",
        "label": "Monotype Imaging Holdings Inc.",
        "price": 16.16
    }, {
        "symbol": "KYN",
        "label": "Kayne Anderson MLP Investment Company",
        "price": 14.6
    }, {
        "symbol": "NVGS",
        "label": "Navigator Holdings Ltd. (Marshall Islands)",
        "price": 13.06
    }, {
        "symbol": "MCC",
        "label": "Medley Capital Corporation",
        "price": 2.21
    }, {
        "symbol": "MCY",
        "label": "Mercury General Corporation",
        "price": 47.9
    }, {
        "symbol": "TWOU",
        "label": "2U Inc.",
        "price": 22.84
    }, {
        "symbol": "MDGL",
        "label": "Madrigal Pharmaceuticals Inc.",
        "price": 85.38
    }, {
        "symbol": "AZUL",
        "label": "Azul S.A. American Depositary Shares (each representing three preferred shares)",
        "price": 44.02
    }, {
        "symbol": "TCS",
        "label": "Container Store (The)",
        "price": 4.55
    }, {
        "symbol": "ACTG",
        "label": "Acacia Research Corporation",
        "price": 2.77
    }, {
        "symbol": "GATX",
        "label": "GATX Corporation",
        "price": 77.38
    }, {
        "symbol": "EPC",
        "label": "Edgewell Personal Care Company",
        "price": 29.07
    }, {
        "symbol": "SRG",
        "label": "Seritage Growth Properties Class A",
        "price": 40.5
    }, {
        "symbol": "AMWD",
        "label": "American Woodmark Corporation",
        "price": 112.96
    }, {
        "symbol": "CATY",
        "label": "Cathay General Bancorp",
        "price": 37.9
    }, {
        "symbol": "MSM",
        "label": "MSC Industrial Direct Company Inc.",
        "price": 76.89
    }, {
        "symbol": "COLB",
        "label": "Columbia Banking System Inc.",
        "price": 39.92
    }, {
        "symbol": "GBT",
        "label": "Global Blood Therapeutics Inc.",
        "price": 83.76
    }, {
        "symbol": "GEF",
        "label": "Greif Inc. Class A",
        "price": 45.91
    }, {
        "symbol": "LOMA",
        "label": "Loma Negra Compania Industrial Argentina Sociedad Anonima ADS",
        "price": 7.33
    }, {
        "symbol": "MPAA",
        "label": "Motorcar Parts of America Inc.",
        "price": 22.21
    }, {
        "symbol": "HCHC",
        "label": "HC2 Holdings Inc.",
        "price": 2.67
    }, {
        "symbol": "GGG",
        "label": "Graco Inc.",
        "price": 53.2
    }, {
        "symbol": "BPFH",
        "label": "Boston Private Financial Holdings Inc.",
        "price": 12.27
    }, {
        "symbol": "TDS",
        "label": "Telephone and Data Systems Inc.",
        "price": 25.36
    }, {
        "symbol": "SYNT",
        "label": "Syntel Inc.",
        "price": 41.0
    }, {
        "symbol": "COWN",
        "label": "Cowen Inc.",
        "price": 16.42
    }, {
        "symbol": "MTH",
        "label": "Meritage Homes Corporation",
        "price": 65.94
    }, {
        "symbol": "GTLS",
        "label": "Chart Industries Inc.",
        "price": 69.99
    }, {
        "symbol": "SKYW",
        "label": "SkyWest Inc.",
        "price": 66.2
    }, {
        "symbol": "CONN",
        "label": "Conn's Inc.",
        "price": 10.375
    }, {
        "symbol": "EZPW",
        "label": "EZCORP Inc.",
        "price": 6.35
    }, {
        "symbol": "GNL",
        "label": "Global Net Lease Inc.",
        "price": 20.46
    }, {
        "symbol": "BXC",
        "label": "Bluelinx Holdings Inc.",
        "price": 14.39
    }, {
        "symbol": "NPO",
        "label": "EnPro Industries Inc",
        "price": 67.06
    }, {
        "symbol": "RCI",
        "label": "Rogers Communication Inc.",
        "price": 49.7
    }, {
        "symbol": "LFIN",
        "label": "LongFin Corp",
        "price": 17.46
    }, {
        "symbol": "BSMX",
        "label": "Banco Santander (M?xico) S.A. Instituci?n de Banca M?ltiple Grupo Financiero San" +
                "tander M?xico",
        "price": 7.26
    }, {
        "symbol": "MHLD",
        "label": "Maiden Holdings Ltd.",
        "price": 0.8397
    }, {
        "symbol": "MLNX",
        "label": "Mellanox Technologies Ltd.",
        "price": 119.81
    }, {
        "symbol": "AERI",
        "label": "Aerie Pharmaceuticals Inc.",
        "price": 22.74
    }, {
        "symbol": "BAK",
        "label": "Braskem SA ADR",
        "price": 19.67
    }, {
        "symbol": "SOXX",
        "label": "iShares PHLX SOX Semiconductor Sector Index Fund",
        "price": 260.12
    }, {
        "symbol": "SEM",
        "label": "Select Medical Holdings Corporation",
        "price": 23.9
    }, {
        "symbol": "AST",
        "label": "Asterias Biotherapeutics Inc. Common Series A",
        "price": 0.93
    }, {
        "symbol": "REN",
        "label": "Resolute Energy Corporation Comon Stock",
        "price": 31.06
    }, {
        "symbol": "CCU",
        "label": "Compania Cervecerias Unidas S.A.",
        "price": 19.34
    }, {
        "symbol": "KPTI",
        "label": "Karyopharm Therapeutics Inc.",
        "price": 17.04
    }, {
        "symbol": "WAGE",
        "label": "WageWorks Inc.",
        "price": 51.1
    }, {
        "symbol": "GEL",
        "label": "Genesis Energy L.P.",
        "price": 21.46
    }, {
        "symbol": "AVID",
        "label": "Avid Technology Inc.",
        "price": 9.16
    }, {
        "symbol": "HEP",
        "label": "Holly Energy Partners L.P.",
        "price": 24.41
    }, {
        "symbol": "SLDB",
        "label": "Solid Biosciences Inc.",
        "price": 3.86
    }, {
        "symbol": "GNRC",
        "label": "Generac Holdlings Inc.",
        "price": 103.52
    }, {
        "symbol": "WD",
        "label": "Walker & Dunlop Inc",
        "price": 67.63
    }, {
        "symbol": "SCHG",
        "label": "Schwab U.S. Large-Cap Growth",
        "price": 79.37
    }, {
        "symbol": "SHLO",
        "label": "Shiloh Industries Inc.",
        "price": 3.46
    }, {
        "symbol": "DNB",
        "label": "Dun & Bradstreet Corporation (The)",
        "price": 144.98
    }, {
        "symbol": "AMID",
        "label": "American Midstream Partners LP representing Limited Partner Interests",
        "price": 5.21
    }, {
        "symbol": "TBBK",
        "label": "The Bancorp Inc.",
        "price": 12.11
    }, {
        "symbol": "IVC",
        "label": "Invacare Corporation",
        "price": 8.84
    }, {
        "symbol": "TOUR",
        "label": "Tuniu Corporation",
        "price": 2.42
    }, {
        "symbol": "ESND",
        "label": "Essendant Inc.",
        "price": 12.81
    }, {
        "symbol": "ITT",
        "label": "ITT Inc.",
        "price": 73.44
    }, {
        "symbol": "MTRX",
        "label": "Matrix Service Company",
        "price": 21.245
    }, {
        "symbol": "APLP",
        "label": "Archrock Partners L.P.",
        "price": 15.25
    }, {
        "symbol": "EPOL",
        "label": "iShares Trust MSCI Poland",
        "price": 22.21
    }, {
        "symbol": "BIV",
        "label": "Vanguard Intermediate-Term Bond",
        "price": 87.83
    }, {
        "symbol": "SPWR",
        "label": "SunPower Corporation",
        "price": 8.77
    }, {
        "symbol": "CHH",
        "label": "Choice Hotels International Inc.",
        "price": 100.26
    }, {
        "symbol": "TMK",
        "label": "Torchmark Corporation",
        "price": 88.1
    }, {
        "symbol": "SPYV",
        "label": "SPDR Series Trust Portfolio S&P 500 Value",
        "price": 30.06
    }, {
        "symbol": "TOT",
        "label": "Total S.A.",
        "price": 54.01
    }, {
        "symbol": "BDJ",
        "label": "Blackrock Enhanced Equity Dividend Trust",
        "price": 9.69
    }, {
        "symbol": "PGTI",
        "label": "PGT Innovations Inc.",
        "price": 16.1
    }, {
        "symbol": "MDP",
        "label": "Meredith Corporation",
        "price": 31.38
    }, {
        "symbol": "SLAB",
        "label": "Silicon Laboratories Inc.",
        "price": 119.45
    }, {
        "symbol": "DATA",
        "label": "Tableau Software Inc. Class A",
        "price": 120.28
    }, {
        "symbol": "WWE",
        "label": "World Wrestling Entertainment Inc. Class A",
        "price": 62.85
    }, {
        "symbol": "RNG",
        "label": "Ringcentral Inc. Class A",
        "price": 192.89
    }, {
        "symbol": "EHC",
        "label": "Encompass Health Corporation",
        "price": 79.46
    }, {
        "symbol": "PEGA",
        "label": "Pegasystems Inc.",
        "price": 86.44
    }, {
        "symbol": "NOVT",
        "label": "Novanta Inc.",
        "price": 97.64
    }, {
        "symbol": "DTE",
        "label": "DTE Energy Company",
        "price": 133.83
    }, {
        "symbol": "MYGN",
        "label": "Myriad Genetics Inc.",
        "price": 28.72
    }, {
        "symbol": "DEA",
        "label": "Easterly Government Properties Inc.",
        "price": 23.53
    }, {
        "symbol": "MINI",
        "label": "Mobile Mini Inc.",
        "price": 40.08
    }, {
        "symbol": "PXLW",
        "label": "Pixelworks Inc.",
        "price": 4.25
    }, {
        "symbol": "BRS",
        "label": "Bristow Group Inc.",
        "price": 0.29
    }, {
        "symbol": "DGRO",
        "label": "iShares Core Dividend Growth",
        "price": 42.88
    }, {
        "symbol": "ERF",
        "label": "Enerplus Corporation",
        "price": 6.46
    }, {
        "symbol": "AEIS",
        "label": "Advanced Energy Industries Inc.",
        "price": 74.01
    }, {
        "symbol": "ETV",
        "label": "Eaton Vance Corporation Tax-Managed Buy-Write Opportunities Fund of Beneficial I" +
                "nterest",
        "price": 15.48
    }, {
        "symbol": "LHCG",
        "label": "LHC Group",
        "price": 145.42
    }, {
        "symbol": "NDAQ",
        "label": "Nasdaq Inc.",
        "price": 109.31
    }, {
        "symbol": "NFG",
        "label": "National Fuel Gas Company",
        "price": 45.49
    }, {
        "symbol": "WSFS",
        "label": "WSFS Financial Corporation",
        "price": 42.94
    }, {
        "symbol": "CNNE",
        "label": "Cannae Holdings Inc.",
        "price": 39.04
    }, {
        "symbol": "WCC",
        "label": "WESCO International Inc.",
        "price": 55.82
    }, {
        "symbol": "BRC",
        "label": "Brady Corporation",
        "price": 57.56
    }, {
        "symbol": "PIRS",
        "label": "Pieris Pharmaceuticals Inc.",
        "price": 3.31
    }, {
        "symbol": "AAT",
        "label": "American Assets Trust Inc.",
        "price": 46.83
    }, {
        "symbol": "ENR",
        "label": "Energizer Holdings Inc.",
        "price": 49.31
    }, {
        "symbol": "NSA",
        "label": "National Storage Affiliates Trust of Beneficial Interest",
        "price": 34.95
    }, {
        "symbol": "ABIO",
        "label": "ARCA biopharma Inc.",
        "price": 5.75
    }, {
        "symbol": "RFP",
        "label": "Resolute Forest Products Inc.",
        "price": 4.49
    }, {
        "symbol": "SRRA",
        "label": "Sierra Oncology Inc.",
        "price": 0.4066
    }, {
        "symbol": "EE",
        "label": "El Pasoectric Company",
        "price": 68.2
    }, {
        "symbol": "NXEO",
        "label": "Nexeo Solutions Inc.",
        "price": 9.97
    }, {
        "symbol": "VRTU",
        "label": "Virtusa Corporation",
        "price": 43.54
    }, {
        "symbol": "COTV",
        "label": "Cotiviti Holdings Inc.",
        "price": 44.75
    }, {
        "symbol": "MMYT",
        "label": "MakeMyTrip Limited",
        "price": 25.4
    }, {
        "symbol": "MEET",
        "label": "The Meet Group Inc.",
        "price": 5.77
    }, {
        "symbol": "UTHR",
        "label": "United Therapeutics Corporation",
        "price": 93.13
    }, {
        "symbol": "CALM",
        "label": "Cal-Maine Foods Inc.",
        "price": 39.21
    }, {
        "symbol": "ALRM",
        "label": "Alarm.com Holdings Inc.",
        "price": 46.03
    }, {
        "symbol": "USAC",
        "label": "USA Compression Partners LP Representing Limited Partner Interests",
        "price": 17.45
    }, {
        "symbol": "MKTX",
        "label": "MarketAxess Holdings Inc.",
        "price": 369.06
    }, {
        "symbol": "LSCC",
        "label": "Lattice Semiconductor Corporation",
        "price": 23.34
    }, {
        "symbol": "PTGX",
        "label": "Protagonist Therapeutics Inc.",
        "price": 7.3
    }, {
        "symbol": "WSR",
        "label": "Whitestone REIT",
        "price": 13.62
    }, {
        "symbol": "RWT",
        "label": "Redwood Trust Inc.",
        "price": 17.22
    }, {
        "symbol": "ECPG",
        "label": "Encore Capital Group Inc",
        "price": 36.92
    }, {
        "symbol": "MCRN",
        "label": "Milacron Holdings Corp.",
        "price": 16.8
    }, {
        "symbol": "SFL",
        "label": "Ship Finance International Limited",
        "price": 14.5
    }, {
        "symbol": "AME",
        "label": "AMETEK Inc.",
        "price": 101.78
    }, {
        "symbol": "BKNG",
        "label": "Booking Holdings Inc.",
        "price": 2054.69
    }, {
        "symbol": "DCT",
        "label": "DCT Industrial Trust Inc",
        "price": 66.28
    }, {
        "symbol": "CSTE",
        "label": "Caesarstone Ltd.",
        "price": 15.38
    }, {
        "symbol": "BOFI",
        "label": "BofI Holding Inc.",
        "price": 34.4
    }, {
        "symbol": "QNST",
        "label": "QuinStreet Inc.",
        "price": 14.67
    }, {
        "symbol": "SR",
        "label": "Spire Inc.",
        "price": 84.13
    }, {
        "symbol": "SHLD",
        "label": "Sears Holdings Corporation",
        "price": 0.35
    }, {
        "symbol": "PMTS",
        "label": "CPI Card Group Inc.",
        "price": 0.799
    }, {
        "symbol": "SIMO",
        "label": "Silicon Motion Technology Corporation",
        "price": 50.1
    }, {
        "symbol": "AMED",
        "label": "Amedisys Inc",
        "price": 180.31
    }, {
        "symbol": "HRTG",
        "label": "Heritage Insurance Holdings Inc.",
        "price": 12.99
    }, {
        "symbol": "EUM",
        "label": "ProShares Short MSCI Emerging Markets",
        "price": 19.44
    }, {
        "symbol": "IGF",
        "label": "iShares Global Infrastructure ETF",
        "price": 49.19
    }, {
        "symbol": "VNET",
        "label": "21Vianet Group Inc.",
        "price": 10.77
    }, {
        "symbol": "AYX",
        "label": "Alteryx Inc. Class A",
        "price": 126.07
    }, {
        "symbol": "UBSH",
        "label": "Union Bankshares Corporation",
        "price": 35.3
    }, {
        "symbol": "CHRS",
        "label": "Coherus BioSciences Inc.",
        "price": 19.16
    }, {
        "symbol": "KEM",
        "label": "KEMET Corporation",
        "price": 26.59
    }, {
        "symbol": "BWB",
        "label": "Bridgewater Bancshares Inc.",
        "price": 13.17
    }, {
        "symbol": "BEDU",
        "label": "Bright Scholar Education Holdings Limited American Depositary Shares each repres" +
                "enting one Class A",
        "price": 9.5
    }, {
        "symbol": "CIB",
        "label": "BanColombia S.A.",
        "price": 54.96
    }, {
        "symbol": "PES",
        "label": "Pioneer Energy Services Corp. Common Stk",
        "price": 0.34
    }, {
        "symbol": "FSP",
        "label": "Franklin Street Properties Corp.",
        "price": 7.96
    }, {
        "symbol": "IIIN",
        "label": "Insteel Industries Inc.",
        "price": 26.01
    }, {
        "symbol": "BLD",
        "label": "TopBuild Corp.",
        "price": 111.6
    }, {
        "symbol": "HFWA",
        "label": "Heritage Financial Corporation",
        "price": 27.8
    }, {
        "symbol": "SCSC",
        "label": "ScanSource Inc.",
        "price": 35.89
    }, {
        "symbol": "QADA",
        "label": "QAD Inc.",
        "price": 53.24
    }, {
        "symbol": "SALT",
        "label": "Scorpio Bulkers Inc.",
        "price": 5.15
    }, {
        "symbol": "BHLB",
        "label": "Berkshire Hills Bancorp Inc.",
        "price": 32.03
    }, {
        "symbol": "CASI",
        "label": "CASI Pharmaceuticals Inc.",
        "price": 3.2
    }, {
        "symbol": "TSC",
        "label": "TriState Capital Holdings Inc.",
        "price": 25.23
    }, {
        "symbol": "STB",
        "label": "Student Transportation Inc",
        "price": 7.5
    }, {
        "symbol": "RE",
        "label": "Everest Re Group Ltd.",
        "price": 279.97
    }, {
        "symbol": "LUNA",
        "label": "Luna Innovations Incorporated",
        "price": 8.45
    }, {
        "symbol": "FBC",
        "label": "Flagstar Bancorp Inc.",
        "price": 37.57
    }, {
        "symbol": "CLS",
        "label": "Celestica Inc.",
        "price": 8.85
    }, {
        "symbol": "EVLV",
        "label": "EVINE Live Inc.",
        "price": 0.44
    }, {
        "symbol": "PHK",
        "label": "Pimco High Income Fund",
        "price": 7.59
    }, {
        "symbol": "SFNC",
        "label": "Simmons First National Corporation",
        "price": 26.11
    }, {
        "symbol": "GBX",
        "label": "Greenbrier Companies Inc. (The)",
        "price": 26.55
    }, {
        "symbol": "DGRW",
        "label": "WisdomTree U.S. Quality Dividend Growth Fund",
        "price": 49.64
    }, {
        "symbol": "GWR",
        "label": "Genesee & Wyoming Inc. Class A",
        "price": 111.88
    }, {
        "symbol": "VRS",
        "label": "Verso Corporation",
        "price": 16.81
    }, {
        "symbol": "HUBB",
        "label": "Hubbell Inc",
        "price": 149.16
    }, {
        "symbol": "EPP",
        "label": "iShares MSCI Pacific Ex-Japan Index Fund",
        "price": 45.43
    }, {
        "symbol": "PPDF",
        "label": "PPDAI Group Inc. American Depositary Shares each representing five Class A",
        "price": 2.7
    }, {
        "symbol": "GLOW",
        "label": "Glowpoint Inc.",
        "price": 1.4
    }, {
        "symbol": "EWM",
        "label": "iShares MSCI Malaysia Index Fund",
        "price": 28.83
    }, {
        "symbol": "GTT",
        "label": "GTT Communications Inc.",
        "price": 12.95
    }, {
        "symbol": "PBA",
        "label": "Pembina Pipeline Corp. (Canada)",
        "price": 38.59
    }, {
        "symbol": "LTS",
        "label": "Ladenburg Thalmann Financial Services Inc",
        "price": 3.48
    }, {
        "symbol": "UAN",
        "label": "CVR Partners LP representing Limited Partner Interests",
        "price": 2.96
    }, {
        "symbol": "GMED",
        "label": "Globus Medical Inc. Class A",
        "price": 53.3
    }, {
        "symbol": "VERU",
        "label": "Veru Inc.",
        "price": 4.19
    }, {
        "symbol": "PB",
        "label": "Prosperity Bancshares Inc.",
        "price": 70.66
    }, {
        "symbol": "VVI",
        "label": "Viad Corp",
        "price": 70.04
    }, {
        "symbol": "TSRO",
        "label": "TESARO Inc.",
        "price": 74.97
    }, {
        "symbol": "EGL",
        "label": "Engility Holdings Inc.",
        "price": 29.11
    }, {
        "symbol": "MRCY",
        "label": "Mercury Systems Inc",
        "price": 75.38
    }, {
        "symbol": "VSI",
        "label": "Vitamin Shoppe Inc",
        "price": 6.5
    }, {
        "symbol": "TU",
        "label": "Telus Corporation",
        "price": 39.27
    }, {
        "symbol": "LCII",
        "label": "LCI Industries",
        "price": 111.0
    }, {
        "symbol": "IBKC",
        "label": "IBERIABANK Corporation",
        "price": 78.22
    }, {
        "symbol": "ICFI",
        "label": "ICF International Inc.",
        "price": 92.25
    }, {
        "symbol": "ACIW",
        "label": "ACI Worldwide Inc.",
        "price": 37.3
    }, {
        "symbol": "ADX",
        "label": "Adams Diversified Equity Fund Inc.",
        "price": 16.15
    }, {
        "symbol": "ONCE",
        "label": "Spark Therapeutics Inc.",
        "price": 113.57
    }, {
        "symbol": "PTCT",
        "label": "PTC Therapeutics Inc.",
        "price": 53.8
    }, {
        "symbol": "TFSL",
        "label": "TFS Financial Corporation",
        "price": 19.87
    }, {
        "symbol": "ATNM",
        "label": "Actinium Pharmaceuticals Inc. (Delaware)",
        "price": 0.274
    }, {
        "symbol": "CSU",
        "label": "Capital Senior Living Corporation",
        "price": 2.99
    }, {
        "symbol": "RRGB",
        "label": "Red Robin Gourmet Burgers Inc.",
        "price": 34.95
    }, {
        "symbol": "HUD",
        "label": "Hudson Ltd. Class A",
        "price": 14.17
    }, {
        "symbol": "CECE",
        "label": "CECO Environmental Corp.",
        "price": 7.94
    }, {
        "symbol": "IART",
        "label": "Integra LifeSciences Holdings Corporation",
        "price": 59.13
    }, {
        "symbol": "CHU",
        "label": "China Unicom (Hong Kong) Ltd",
        "price": 9.3
    }, {
        "symbol": "VB",
        "label": "Vanguard Small-Cap",
        "price": 169.41
    }, {
        "symbol": "APTS",
        "label": "Preferred Apartment Communities Inc.",
        "price": 13.38
    }, {
        "symbol": "III",
        "label": "Information Services Group Inc.",
        "price": 2.8
    }, {
        "symbol": "JILL",
        "label": "J. Jill Inc.",
        "price": 1.32
    }, {
        "symbol": "STBZ",
        "label": "State Bank Financial Corporation.",
        "price": 21.44
    }, {
        "symbol": "YRCW",
        "label": "YRC Worldwide Inc.",
        "price": 3.05
    }, {
        "symbol": "CNAT",
        "label": "Conatus Pharmaceuticals Inc.",
        "price": 0.4314
    }, {
        "symbol": "WWW",
        "label": "Wolverine World Wide Inc.",
        "price": 34.15
    }, {
        "symbol": "LXRX",
        "label": "Lexicon Pharmaceuticals Inc.",
        "price": 4.04
    }, {
        "symbol": "PRAA",
        "label": "PRA Group Inc.",
        "price": 36.87
    }, {
        "symbol": "CLM",
        "label": "Cornerstone Strategic Value Fund Inc.",
        "price": 11.45
    }, {
        "symbol": "COBZ",
        "label": "CoBiz Financial Inc.",
        "price": 22.18
    }, {
        "symbol": "RPT",
        "label": "Ramco-Gershenson Properties Trust",
        "price": 14.66
    }, {
        "symbol": "STMP",
        "label": "Stamps.com Inc.",
        "price": 90.83
    }, {
        "symbol": "SCVL",
        "label": "Shoe Carnival Inc.",
        "price": 37.18
    }, {
        "symbol": "CORE",
        "label": "Core-Mark Holding Company Inc.",
        "price": 25.69
    }, {
        "symbol": "AFG",
        "label": "American Financial Group Inc.",
        "price": 111.28
    }, {
        "symbol": "KRNT",
        "label": "Kornit Digital Ltd.",
        "price": 40.76
    }, {
        "symbol": "CYD",
        "label": "China Yuchai International Limited",
        "price": 13.55
    }, {
        "symbol": "MYRG",
        "label": "MYR Group Inc.",
        "price": 31.96
    }, {
        "symbol": "BANR",
        "label": "Banner Corporation",
        "price": 54.87
    }, {
        "symbol": "CRESY",
        "label": "Cresud S.A.C.I.F. y A. American Depositary Shares each representing ten shares o" +
                "f Common Stock",
        "price": 6.15
    }, {
        "symbol": "HIFR",
        "label": "InfraREIT Inc.",
        "price": 21.12
    }, {
        "symbol": "XT",
        "label": "iShares Exponential Technologies ETF",
        "price": 44.19
    }, {
        "symbol": "IUSG",
        "label": "iShares Core S&P U.S. Growth ETF",
        "price": 70.78
    }, {
        "symbol": "FPH",
        "label": "Five Point Holdings LLC Class A",
        "price": 8.87
    }, {
        "symbol": "MNR",
        "label": "Monmouth Real Estate Investment Corporation Class A",
        "price": 14.66
    }, {
        "symbol": "FN",
        "label": "Fabrinet",
        "price": 66.66
    }, {
        "symbol": "CPK",
        "label": "Chesapeake Utilities Corporation",
        "price": 95.19
    }, {
        "symbol": "ORC",
        "label": "Orchid Island Capital Inc.",
        "price": 6.23
    }, {
        "symbol": "SLF",
        "label": "Sun Life Financial Inc.",
        "price": 48.63
    }, {
        "symbol": "KL",
        "label": "Kirkland Lake Gold Ltd.",
        "price": 43.44
    }, {
        "symbol": "VSAT",
        "label": "ViaSat Inc.",
        "price": 72.77
    }, {
        "symbol": "CHFC",
        "label": "Chemical Financial Corporation",
        "price": 39.42
    }, {
        "symbol": "WK",
        "label": "Workiva Inc. Class A",
        "price": 45.15
    }, {
        "symbol": "ADMS",
        "label": "Adamas Pharmaceuticals Inc.",
        "price": 5.88
    }, {
        "symbol": "FUN",
        "label": "Cedar Fair L.P.",
        "price": 54.92
    }, {
        "symbol": "SIGI",
        "label": "Selective Insurance Group Inc.",
        "price": 68.25
    }, {
        "symbol": "SCHA",
        "label": "Schwab U.S. Small-Cap",
        "price": 77.09
    }, {
        "symbol": "CACI",
        "label": "CACI International Inc. Class A",
        "price": 270.61
    }, {
        "symbol": "BBOX",
        "label": "Black Box Corporation",
        "price": 1.09
    }, {
        "symbol": "RMTI",
        "label": "Rockwell Medical Inc.",
        "price": 2.6
    }, {
        "symbol": "SUPV",
        "label": "Grupo Supervielle S.A. American Depositary Shares each Representing five Class B" +
                " shares",
        "price": 3.36
    }, {
        "symbol": "ALKS",
        "label": "Alkermes plc",
        "price": 19.24
    }, {
        "symbol": "CRAY",
        "label": "Cray Inc",
        "price": 34.88
    }, {
        "symbol": "GLDD",
        "label": "Great Lakes Dredge & Dock Corporation",
        "price": 11.63
    }, {
        "symbol": "BOH",
        "label": "Bank of Hawaii Corporation",
        "price": 94.13
    }, {
        "symbol": "FIZZ",
        "label": "National Beverage Corp.",
        "price": 45.86
    }, {
        "symbol": "LIQT",
        "label": "LiqTech International Inc.",
        "price": 6.49
    }, {
        "symbol": "CEPU",
        "label": "Central Puerto S.A. American Depositary Shares (each represents ten)",
        "price": 4.21
    }, {
        "symbol": "VVC",
        "label": "Vectren Corporation",
        "price": 72.38
    }, {
        "symbol": "SBBP",
        "label": "Strongbridge Biopharma plc",
        "price": 2.74
    }, {
        "symbol": "DORM",
        "label": "Dorman Products Inc.",
        "price": 77.75
    }, {
        "symbol": "EVBG",
        "label": "Everbridge Inc.",
        "price": 88.2
    }, {
        "symbol": "IGOV",
        "label": "iShares International Treasury Bond ETF",
        "price": 49.79
    }, {
        "symbol": "AKCA",
        "label": "Akcea Therapeutics Inc.",
        "price": 17.05
    }, {
        "symbol": "AEMD",
        "label": "Aethlon Medical Inc.",
        "price": 2.06
    }, {
        "symbol": "ENV",
        "label": "Envestnet Inc",
        "price": 70.59
    }, {
        "symbol": "AIN",
        "label": "Albany International Corporation",
        "price": 78.39
    }, {
        "symbol": "CAE",
        "label": "CAE Inc.",
        "price": 29.63
    }, {
        "symbol": "ATRS",
        "label": "Antares Pharma Inc.",
        "price": 4.24
    }, {
        "symbol": "QES",
        "label": "Quintana Energy Services Inc.",
        "price": 2.6
    }, {
        "symbol": "NUV",
        "label": "Nuveen Municipal Value Fund Inc.",
        "price": 10.72
    }, {
        "symbol": "HXL",
        "label": "Hexcel Corporation",
        "price": 77.75
    }, {
        "symbol": "SPHD",
        "label": "PowerShares S&P 500 High Dividend Low Volatility Portfolio",
        "price": 41.15
    }, {
        "symbol": "SWM",
        "label": "Schweitzer-Mauduit International Inc.",
        "price": 37.93
    }, {
        "symbol": "TUSK",
        "label": "Mammoth Energy Services Inc.",
        "price": 1.75
    }, {
        "symbol": "HBHC",
        "label": "Hancock Holding Company",
        "price": 51.63
    }, {
        "symbol": "EME",
        "label": "EMCOR Group Inc.",
        "price": 87.16
    }, {
        "symbol": "VUZI",
        "label": "Vuzix Corporation",
        "price": 1.95
    }, {
        "symbol": "OFG",
        "label": "OFG Bancorp",
        "price": 21.99
    }, {
        "symbol": "SSLJ",
        "label": "SSLJ.com Limited",
        "price": 3.81
    }, {
        "symbol": "GLT",
        "label": "Glatfelter",
        "price": 17.87
    }, {
        "symbol": "IUSV",
        "label": "iShares Core S&P U.S. Value ETF",
        "price": 63.83
    }, {
        "symbol": "PHG",
        "label": "Koninklijke Philips N.V. NY Registry Shares",
        "price": 50.08
    }, {
        "symbol": "DUST",
        "label": "Direxion Daily Gold Miners Index Bear 3X Shares",
        "price": 17.11
    }, {
        "symbol": "FMI",
        "label": "Foundation Medicine Inc.",
        "price": 136.98
    }, {
        "symbol": "MERC",
        "label": "Mercer International Inc.",
        "price": 13.25
    }, {
        "symbol": "HEES",
        "label": "H&E Equipment Services Inc.",
        "price": 31.28
    }, {
        "symbol": "CMO",
        "label": "Capstead Mortgage Corporation",
        "price": 8.15
    }, {
        "symbol": "BXMX",
        "label": "Nuveen S&P 500 Buy-Write Income Fund of Beneficial Interest",
        "price": 13.66
    }, {
        "symbol": "AZO",
        "label": "AutoZone Inc.",
        "price": 1154.58
    }, {
        "symbol": "AMZA",
        "label": "InfraCap MLP",
        "price": 5.4
    }, {
        "symbol": "AGFS",
        "label": "AgroFresh Solutions Inc.",
        "price": 2.49
    }, {
        "symbol": "WCG",
        "label": "Wellcare Health Plans Inc.",
        "price": 337.1
    }, {
        "symbol": "IDV",
        "label": "iShares International Select Dividend",
        "price": 30.62
    }, {
        "symbol": "JKS",
        "label": "JinkoSolar Holding Company Limited American Depositary Shares (each representing" +
                " 4)",
        "price": 22.83
    }, {
        "symbol": "SNCR",
        "label": "Synchronoss Technologies Inc.",
        "price": 5.68
    }, {
        "symbol": "PTI",
        "label": "Proteostasis Therapeutics Inc.",
        "price": 1.96
    }, {
        "symbol": "LKSD",
        "label": "LSC Communications Inc.",
        "price": 0.215
    }, {
        "symbol": "TM",
        "label": "Toyota Motor Corporation",
        "price": 140.04
    }, {
        "symbol": "UVV",
        "label": "Universal Corporation",
        "price": 55.98
    }, {
        "symbol": "VNM",
        "label": "VanEck Vectors Vietnam",
        "price": 16.23
    }, {
        "symbol": "EIGI",
        "label": "Endurance International Group Holdings Inc.",
        "price": 5.15
    }, {
        "symbol": "BIOC",
        "label": "Biocept Inc.",
        "price": 0.3475
    }, {
        "symbol": "HUBG",
        "label": "Hub Group Inc.",
        "price": 55.03
    }, {
        "symbol": "LAD",
        "label": "Lithia Motors Inc.",
        "price": 143.39
    }, {
        "symbol": "TWI",
        "label": "Titan International Inc. (DE)",
        "price": 3.91
    }, {
        "symbol": "SNBR",
        "label": "Sleep Number Corporation",
        "price": 48.56
    }, {
        "symbol": "RYAM",
        "label": "Rayonier Advanced Materials Inc.",
        "price": 3.9
    }, {
        "symbol": "LOGI",
        "label": "Logitech International S.A.",
        "price": 46.57
    }, {
        "symbol": "DCO",
        "label": "Ducommun Incorporated",
        "price": 42.74
    }, {
        "symbol": "FLWS",
        "label": "1-800 FLOWERS.COM Inc.",
        "price": 15.07
    }, {
        "symbol": "HYT",
        "label": "Blackrock Corporate High Yield Fund Inc.",
        "price": 11.34
    }, {
        "symbol": "FCPT",
        "label": "Four Corners Property Trust Inc.",
        "price": 28.59
    }, {
        "symbol": "EBIX",
        "label": "Ebix Inc.",
        "price": 35.16
    }, {
        "symbol": "UDOW",
        "label": "ProShares UltraPro Dow30",
        "price": 91.34
    }, {
        "symbol": "ENBL",
        "label": "Enable Midstream Partners LP representing limited partner interests",
        "price": 10.64
    }, {
        "symbol": "MTUM",
        "label": "iShares Edge MSCI USA Momentum Factor",
        "price": 131.38
    }, {
        "symbol": "NYRT",
        "label": "New York REIT Inc.",
        "price": 14.15
    }, {
        "symbol": "MFG",
        "label": "Mizuho Financial Group Inc. Sponosred ADR (Japan)",
        "price": 3.01
    }, {
        "symbol": "ANAB",
        "label": "AnaptysBio Inc.",
        "price": 15.88
    }, {
        "symbol": "ABUS",
        "label": "Arbutus Biopharma Corporation",
        "price": 3.07
    }, {
        "symbol": "SCG",
        "label": "SCANA Corporation",
        "price": 47.76
    }, {
        "symbol": "CBB",
        "label": "Cincinnati Bell Inc.",
        "price": 11.44
    }, {
        "symbol": "MSG",
        "label": "The Madison Square Garden Company Class A (New)",
        "price": 305.05
    }, {
        "symbol": "ANSS",
        "label": "ANSYS Inc.",
        "price": 270.28
    }, {
        "symbol": "STML",
        "label": "Stemline Therapeutics Inc.",
        "price": 7.03
    }, {
        "symbol": "NVRO",
        "label": "Nevro Corp.",
        "price": 121.0
    }, {
        "symbol": "MMS",
        "label": "Maximus Inc.",
        "price": 75.29
    }, {
        "symbol": "HTGC",
        "label": "Hercules Capital Inc.",
        "price": 14.41
    }, {
        "symbol": "CBRL",
        "label": "Cracker Barrel Old Country Store Inc.",
        "price": 159.41
    }, {
        "symbol": "GEN",
        "label": "Genesis Healthcare Inc.",
        "price": 1.22
    }, {
        "symbol": "ADXS",
        "label": "Advaxis Inc.",
        "price": 1.21
    }, {
        "symbol": "ITRI",
        "label": "Itron Inc.",
        "price": 87.84
    }, {
        "symbol": "EMES",
        "label": "Emerge Energy Services LP representing Limited Partner Interests",
        "price": 0.22
    }, {
        "symbol": "TEP",
        "label": "Tallgrass Energy Partners LP representing limited partner interests",
        "price": 44.18
    }, {
        "symbol": "GBCI",
        "label": "Glacier Bancorp Inc.",
        "price": 45.21
    }, {
        "symbol": "NR",
        "label": "Newpark Resources Inc.",
        "price": 5.26
    }, {
        "symbol": "ERII",
        "label": "Energy Recovery Inc.",
        "price": 10.39
    }, {
        "symbol": "CLMT",
        "label": "Calumet Specialty Products Partners L.P.",
        "price": 4.51
    }, {
        "symbol": "RVT",
        "label": "Royce Value Trust Inc.",
        "price": 15.03
    }, {
        "symbol": "AINV",
        "label": "Apollo Investment Corporation",
        "price": 17.55
    }, {
        "symbol": "FMS",
        "label": "Fresenius Medical Care AG",
        "price": 37.6
    }, {
        "symbol": "DST",
        "label": "DST Systems Inc.",
        "price": 84.0
    }, {
        "symbol": "EMD",
        "label": "Western Asset Emerging Markets Debt Fund Inc",
        "price": 14.84
    }, {
        "symbol": "RDY",
        "label": "Dr. Reddy's Laboratories Ltd",
        "price": 42.45
    }, {
        "symbol": "CERS",
        "label": "Cerus Corporation",
        "price": 4.07
    }, {
        "symbol": "LIT",
        "label": "Global X Lithium & Battery Tech",
        "price": 30.04
    }, {
        "symbol": "SDRL",
        "label": "Seadrill Limited (Bermuda)",
        "price": 1.82
    }, {
        "symbol": "BL",
        "label": "BlackLine Inc.",
        "price": 62.295
    }, {
        "symbol": "NCI",
        "label": "Navigant Consulting Inc.",
        "price": 22.65
    }, {
        "symbol": "TLK",
        "label": "PT Telekomunikasi Indonesia Tbk",
        "price": 27.93
    }, {
        "symbol": "SSYS",
        "label": "Stratasys Ltd.",
        "price": 21.77
    }, {
        "symbol": "GUNR",
        "label": "FlexShares Global Upstream Natural Resources Index Fund",
        "price": 31.34
    }, {
        "symbol": "HTH",
        "label": "Hilltop Holdings Inc.",
        "price": 24.34
    }, {
        "symbol": "LKFN",
        "label": "Lakeland Financial Corporation",
        "price": 49.06
    }, {
        "symbol": "ANGO",
        "label": "AngioDynamics Inc.",
        "price": 15.48
    }, {
        "symbol": "PKX",
        "label": "POSCO",
        "price": 52.11
    }, {
        "symbol": "SVM",
        "label": "Silvercorp Metals Inc.",
        "price": 5.46
    }, {
        "symbol": "ENT",
        "label": "Global Eagle Entertainment Inc.",
        "price": 0.4469
    }, {
        "symbol": "WGL",
        "label": "WGL Holdings IncCommon Stock",
        "price": 88.74
    }, {
        "symbol": "AEGN",
        "label": "Aegion Corp",
        "price": 22.36
    }, {
        "symbol": "AXE",
        "label": "Anixter International Inc.",
        "price": 99.17
    }, {
        "symbol": "BTX",
        "label": "BioTime Inc.",
        "price": 1.03
    }, {
        "symbol": "LE",
        "label": "Lands' End Inc.",
        "price": 14.67
    }, {
        "symbol": "ACIA",
        "label": "Acacia Communications Inc.",
        "price": 68.66
    }, {
        "symbol": "HAWK",
        "label": "Blackhawk Network Holdings Inc.",
        "price": 45.15
    }, {
        "symbol": "PAG",
        "label": "Penske Automotive Group Inc.",
        "price": 48.21
    }, {
        "symbol": "PRIM",
        "label": "Primoris Services Corporation",
        "price": 22.13
    }, {
        "symbol": "CTMX",
        "label": "CytomX Therapeutics Inc.",
        "price": 7.46
    }, {
        "symbol": "OPHT",
        "label": "Ophthotech Corporation",
        "price": 1.4
    }, {
        "symbol": "RDUS",
        "label": "Radius Health Inc.",
        "price": 19.65
    }, {
        "symbol": "FLRN",
        "label": "SPDR Bloomberg Barclays Investment Grade Floating Rate",
        "price": 30.79
    }, {
        "symbol": "BBGI",
        "label": "Beasley Broadcast Group Inc.",
        "price": 3.36
    }, {
        "symbol": "CASH",
        "label": "Meta Financial Group Inc.",
        "price": 37.21
    }, {
        "symbol": "SITO",
        "label": "SITO Mobile Ltd.",
        "price": 0.275
    }, {
        "symbol": "PINC",
        "label": "Premier Inc.",
        "price": 36.77
    }, {
        "symbol": "GWPH",
        "label": "GW Pharmaceuticals Plc",
        "price": 118.97
    }, {
        "symbol": "EGIF",
        "label": "Eagle Growth and Income Opportunities Fund of Beneficial Interest",
        "price": 17.5805
    }, {
        "symbol": "SFBS",
        "label": "ServisFirst Bancshares Inc.",
        "price": 36.95
    }, {
        "symbol": "SMPL",
        "label": "The Simply Good Foods Company",
        "price": 25.19
    }, {
        "symbol": "FNKO",
        "label": "Funko Inc.",
        "price": 15.62
    }, {
        "symbol": "CVRR",
        "label": "CVR Refining LP Representing Limited Partner Interests",
        "price": 10.49
    }, {
        "symbol": "RBS",
        "label": "Royal Bank of Scotland Group Plc New (The) ADS",
        "price": 5.88
    }, {
        "symbol": "SSTK",
        "label": "Shutterstock Inc.",
        "price": 45.06
    }, {
        "symbol": "DCM",
        "label": "NTT DOCOMO Inc American Depositary Shares",
        "price": 26.04
    }, {
        "symbol": "ZBRA",
        "label": "Zebra Technologies Corporation",
        "price": 252.74
    }, {
        "symbol": "BMA",
        "label": "Banco Macro S.A. ADR (representing Ten Class B)",
        "price": 33.05
    }, {
        "symbol": "BGG",
        "label": "Briggs & Stratton Corporation",
        "price": 5.73
    }, {
        "symbol": "GABC",
        "label": "German American Bancorp Inc.",
        "price": 35.51
    }, {
        "symbol": "NCMI",
        "label": "National CineMedia Inc.",
        "price": 7.78
    }, {
        "symbol": "PCSB",
        "label": "PCSB Financial Corporation",
        "price": 19.78
    }, {
        "symbol": "ERUS",
        "label": "iShares MSCI Russia",
        "price": 37.16
    }, {
        "symbol": "EROS",
        "label": "Eros International PLC A",
        "price": 4.44
    }, {
        "symbol": "HIVE",
        "label": "Aerohive Networks Inc.",
        "price": 3.36
    }, {
        "symbol": "INST",
        "label": "Instructure Inc.",
        "price": 47.69
    }, {
        "symbol": "FDP",
        "label": "Fresh Del Monte Produce Inc.",
        "price": 34.89
    }, {
        "symbol": "MGLN",
        "label": "Magellan Health Inc.",
        "price": 77.79
    }, {
        "symbol": "BOJA",
        "label": "Bojangles' Inc.",
        "price": 16.1
    }, {
        "symbol": "ADMP",
        "label": "Adamis Pharmaceuticals Corporation",
        "price": 0.7827
    }, {
        "symbol": "EEQ",
        "label": "Enbridge Energy Management LLC Shares repstg limited liability company interests",
        "price": 10.48
    }, {
        "symbol": "EDIT",
        "label": "Editas Medicine Inc.",
        "price": 30.64
    }, {
        "symbol": "TLRA",
        "label": "Telaria Inc.",
        "price": 10.98
    }, {
        "symbol": "BGY",
        "label": "Blackrock Enhanced International Dividend Trust",
        "price": 5.86
    }, {
        "symbol": "AB",
        "label": "AllianceBernstein Holding L.P. Units",
        "price": 33.11
    }, {
        "symbol": "DGAZ",
        "label": "VelocityShares 3X Inverse Natural Gas ETN linked to the S&P GSCI Natural Gas INd" +
                "ex Excess Return",
        "price": 156.89
    }, {
        "symbol": "GAIA",
        "label": "Gaia Inc.",
        "price": 8.51
    }, {
        "symbol": "PRGS",
        "label": "Progress Software Corporation",
        "price": 49.065
    }, {
        "symbol": "TRMK",
        "label": "Trustmark Corporation",
        "price": 34.46
    }, {
        "symbol": "ACRS",
        "label": "Aclaris Therapeutics Inc.",
        "price": 1.84
    }, {
        "symbol": "MSGN",
        "label": "MSG Networks Inc.",
        "price": 16.51
    }, {
        "symbol": "NUS",
        "label": "Nu Skin Enterprises Inc.",
        "price": 42.32
    }, {
        "symbol": "SPSC",
        "label": "SPS Commerce Inc.",
        "price": 57.0
    }, {
        "symbol": "EFSC",
        "label": "Enterprise Financial Services Corporation",
        "price": 48.46
    }, {
        "symbol": "BOKF",
        "label": "BOK Financial Corporation",
        "price": 86.54
    }, {
        "symbol": "MD",
        "label": "Mednax Inc.",
        "price": 27.69
    }, {
        "symbol": "PSMT",
        "label": "PriceSmart Inc.",
        "price": 64.5
    }, {
        "symbol": "FTK",
        "label": "Flotek Industries Inc.",
        "price": 1.82
    }, {
        "symbol": "UVSP",
        "label": "Univest Corporation of Pennsylvania",
        "price": 26.87
    }, {
        "symbol": "OCFC",
        "label": "OceanFirst Financial Corp.",
        "price": 25.06
    }, {
        "symbol": "RADA",
        "label": "RADA Electronic Industries Ltd.",
        "price": 6.64
    }, {
        "symbol": "PAM",
        "label": "Pampa Energia S.A.",
        "price": 14.95
    }, {
        "symbol": "BND",
        "label": "Vanguard Total Bond Market",
        "price": 84.35
    }, {
        "symbol": "EZA",
        "label": "iShares MSCI South Africa Index Fund",
        "price": 48.53
    }, {
        "symbol": "GOGL",
        "label": "Golden Ocean Group Limited",
        "price": 5.31
    }, {
        "symbol": "CNCE",
        "label": "Concert Pharmaceuticals Inc.",
        "price": 10.42
    }, {
        "symbol": "CCRN",
        "label": "Cross Country Healthcare Inc.",
        "price": 10.58
    }, {
        "symbol": "JBT",
        "label": "John Bean Technologies Corporation",
        "price": 115.84
    }, {
        "symbol": "PJT",
        "label": "PJT Partners Inc. Class A",
        "price": 46.22
    }, {
        "symbol": "RUBI",
        "label": "The Rubicon Project Inc.",
        "price": 10.13
    }, {
        "symbol": "BKE",
        "label": "Buckle Inc. (The)",
        "price": 25.38
    }, {
        "symbol": "RHP",
        "label": "Ryman Hospitality Properties Inc. (REIT)",
        "price": 86.78
    }, {
        "symbol": "IRTC",
        "label": "iRhythm Technologies Inc.",
        "price": 84.99
    }, {
        "symbol": "ADC",
        "label": "Agree Realty Corporation",
        "price": 72.54
    }, {
        "symbol": "SMIN",
        "label": "Ishares MSCI India Small Cap",
        "price": 38.1
    }, {
        "symbol": "ENVA",
        "label": "Enova International Inc.",
        "price": 24.35
    }, {
        "symbol": "QTNT",
        "label": "Quotient Limited",
        "price": 8.55
    }, {
        "symbol": "CMRE",
        "label": "Costamare Inc. $0.0001 par value",
        "price": 10.48
    }, {
        "symbol": "EGAN",
        "label": "eGain Corporation",
        "price": 7.88
    }, {
        "symbol": "ZGNX",
        "label": "Zogenix Inc.",
        "price": 54.98
    }, {
        "symbol": "TPIC",
        "label": "TPI Composites Inc.",
        "price": 20.56
    }, {
        "symbol": "ARW",
        "label": "Arrow Electronics Inc.",
        "price": 82.32
    }, {
        "symbol": "NEA",
        "label": "Nuveen AMT-Free Quality Municipal Income Fund of Beneficial Interest Par Value $" +
                ".01",
        "price": 14.45
    }, {
        "symbol": "TA",
        "label": "TravelCenters of America LLC",
        "price": 18.14
    }, {
        "symbol": "AMG",
        "label": "Affiliated Managers Group Inc.",
        "price": 85.64
    }, {
        "symbol": "FRTA",
        "label": "Forterra Inc.",
        "price": 14.22
    }, {
        "symbol": "EFV",
        "label": "iShares MSCI EAFE Value",
        "price": 47.95
    }, {
        "symbol": "INWK",
        "label": "InnerWorkings Inc.",
        "price": 4.64
    }, {
        "symbol": "PI",
        "label": "Impinj Inc.",
        "price": 31.7
    }, {
        "symbol": "NMR",
        "label": "Nomura Holdings Inc ADR American Depositary Shares",
        "price": 5.18
    }, {
        "symbol": "FPE",
        "label": "First Trust Preferred Securities and Income ETF",
        "price": 20.25
    }, {
        "symbol": "BLV",
        "label": "Vanguard Long-Term Bond",
        "price": 94.15
    }, {
        "symbol": "UCFC",
        "label": "United Community Financial Corp.",
        "price": 11.54
    }, {
        "symbol": "MNRO",
        "label": "Monro Inc.",
        "price": 75.28
    }, {
        "symbol": "CWI",
        "label": "SPDR MSCI ACWI ex-US",
        "price": 26.2
    }, {
        "symbol": "FMBI",
        "label": "First Midwest Bancorp Inc.",
        "price": 22.3
    }, {
        "symbol": "ACLS",
        "label": "Axcelis Technologies Inc.",
        "price": 25.03
    }, {
        "symbol": "ITCI",
        "label": "Intra-Cellular Therapies Inc.",
        "price": 26.25
    }, {
        "symbol": "LSI",
        "label": "Life Storage Inc.",
        "price": 113.93
    }, {
        "symbol": "NOBL",
        "label": "ProShares S&P 500 Dividend Aristocrats",
        "price": 76.45
    }, {
        "symbol": "CSJ",
        "label": "iShares 1-3 Year Credit Bond ETF",
        "price": 103.72
    }, {
        "symbol": "VIVO",
        "label": "Meridian Bioscience Inc.",
        "price": 9.89
    }, {
        "symbol": "TCAP",
        "label": "Triangle Capital Corporation",
        "price": 11.79
    }, {
        "symbol": "MCHX",
        "label": "Marchex Inc.",
        "price": 3.55
    }, {
        "symbol": "EPR",
        "label": "EPR Properties",
        "price": 72.54
    }, {
        "symbol": "GEM",
        "label": "Goldman Sachs ActiveBeta Emerging Markets Equity",
        "price": 31.48
    }, {
        "symbol": "IGD",
        "label": "Voya Global Equity Dividend and Premium Opportunity Fund",
        "price": 6.34
    }, {
        "symbol": "EWS",
        "label": "iShares Inc MSCI Singapore",
        "price": 23.0
    }, {
        "symbol": "CRAI",
        "label": "CRA InternationalInc.",
        "price": 56.14
    }, {
        "symbol": "DSGX",
        "label": "The Descartes Systems Group Inc.",
        "price": 46.21
    }, {
        "symbol": "ORIT",
        "label": "Oritani Financial Corp.",
        "price": 18.6
    }, {
        "symbol": "CTIC",
        "label": "CTI BioPharma Corp.",
        "price": 1.38
    }, {
        "symbol": "JASO",
        "label": "JA Solar Holdings Co. Ltd.",
        "price": 7.5
    }, {
        "symbol": "GUT",
        "label": "Gabelli Utility Trust (The)",
        "price": 7.9
    }, {
        "symbol": "AIT",
        "label": "Applied Industrial Technologies Inc.",
        "price": 67.48
    }, {
        "symbol": "HCM",
        "label": "Hutchison China MediTech Limited",
        "price": 29.41
    }, {
        "symbol": "TTS",
        "label": "Tile Shop Hldgs Inc.",
        "price": 1.46
    }, {
        "symbol": "TREE",
        "label": "LendingTree Inc.",
        "price": 310.38
    }, {
        "symbol": "MODN",
        "label": "Model N Inc.",
        "price": 33.78
    }, {
        "symbol": "AXAS",
        "label": "Abraxas Petroleum Corporation",
        "price": 0.3249
    }, {
        "symbol": "OUNZ",
        "label": "VanEck Merk Gold Trust",
        "price": 12.57
    }, {
        "symbol": "CTSO",
        "label": "CytoSorbents Corporation",
        "price": 4.58
    }, {
        "symbol": "KIE",
        "label": "SPDR S&P Insurance",
        "price": 36.17
    }, {
        "symbol": "SNX",
        "label": "Synnex Corporation",
        "price": 146.86
    }, {
        "symbol": "ACOR",
        "label": "Acorda Therapeutics Inc.",
        "price": 2.07
    }, {
        "symbol": "PCTY",
        "label": "Paylocity Holding Corporation",
        "price": 141.6
    }, {
        "symbol": "TX",
        "label": "Ternium S.A. American Depositary Shares (each representing ten shares USD1.00 pa" +
                "r value)",
        "price": 23.27
    }, {
        "symbol": "BSAC",
        "label": "Banco Santander - Chile ADS",
        "price": 22.5
    }, {
        "symbol": "ALIM",
        "label": "Alimera Sciences Inc.",
        "price": 7.05
    }, {
        "symbol": "LILAK",
        "label": "Liberty Latin America Ltd.",
        "price": 18.92
    }, {
        "symbol": "PTR",
        "label": "PetroChina Company Limited",
        "price": 50.95
    }, {
        "symbol": "ASUR",
        "label": "Asure Software Inc",
        "price": 8.78
    }, {
        "symbol": "UTG",
        "label": "Reaves Utility Income Fund of Beneficial Interest",
        "price": 38.16
    }, {
        "symbol": "SSL",
        "label": "Sasol Ltd. American Depositary Shares",
        "price": 18.93
    }, {
        "symbol": "GSS",
        "label": "Golden Star Resources Ltd",
        "price": 3.1
    }, {
        "symbol": "HMN",
        "label": "Horace Mann Educators Corporation",
        "price": 44.61
    }, {
        "symbol": "VMBS",
        "label": "Vanguard Mortgage-Backed Securities ETF",
        "price": 53.37
    }, {
        "symbol": "NTLA",
        "label": "Intellia Therapeutics Inc.",
        "price": 14.63
    }, {
        "symbol": "AEHR",
        "label": "Aehr Test Systems",
        "price": 1.99
    }, {
        "symbol": "BMI",
        "label": "Badger Meter Inc.",
        "price": 63.45
    }, {
        "symbol": "CHCT",
        "label": "Community Healthcare Trust Incorporated",
        "price": 45.24
    }, {
        "symbol": "MSBI",
        "label": "Midland States Bancorp Inc.",
        "price": 28.61
    }, {
        "symbol": "GNMK",
        "label": "GenMark Diagnostics Inc.",
        "price": 4.95
    }, {
        "symbol": "HLNE",
        "label": "Hamilton Lane Incorporated",
        "price": 67.23
    }, {
        "symbol": "XES",
        "label": "SPDR Series Trust S&P Oil & Gas Equipment & Services",
        "price": 8.73
    }, {
        "symbol": "HHC",
        "label": "The Howard Hughes Corporation",
        "price": 125.04
    }, {
        "symbol": "DLS",
        "label": "WisdomTree International SmallCap Fund",
        "price": 70.78
    }, {
        "symbol": "PMT",
        "label": "PennyMac Mortgage Investment Trust of Beneficial Interest",
        "price": 23.17
    }, {
        "symbol": "APPS",
        "label": "Digital Turbine Inc.",
        "price": 7.57
    }, {
        "symbol": "SHAK",
        "label": "Shake Shack Inc. Class A",
        "price": 69.91
    }, {
        "symbol": "GOLF",
        "label": "Acushnet Holdings Corp.",
        "price": 32.34
    }, {
        "symbol": "WTTR",
        "label": "Select Energy Services Inc. Class A",
        "price": 8.85
    }, {
        "symbol": "APU",
        "label": "AmeriGas Partners L.P.",
        "price": 34.71
    }, {
        "symbol": "VYGR",
        "label": "Voyager Therapeutics Inc.",
        "price": 13.79
    }, {
        "symbol": "DOOR",
        "label": "Masonite International Corporation (Canada)",
        "price": 77.81
    }, {
        "symbol": "LGND",
        "label": "Ligand Pharmaceuticals Incorporated",
        "price": 90.39
    }, {
        "symbol": "EBSB",
        "label": "Meridian Bancorp Inc.",
        "price": 19.49
    }, {
        "symbol": "CENTA",
        "label": "Central Garden & Pet Company Class A Common Stock Nonvoting",
        "price": 30.88
    }, {
        "symbol": "EGP",
        "label": "EastGroup Properties Inc.",
        "price": 135.58
    }, {
        "symbol": "SNPS",
        "label": "Synopsys Inc.",
        "price": 151.86
    }, {
        "symbol": "FCEL",
        "label": "FuelCell Energy Inc.",
        "price": 2.4
    }, {
        "symbol": "PSO",
        "label": "Pearson Plc",
        "price": 7.64
    }, {
        "symbol": "AOD",
        "label": "Alpine Total Dynamic Dividend Fund",
        "price": 9.11
    }, {
        "symbol": "EEX",
        "label": "Emerald Expositions Events Inc.",
        "price": 10.55
    }, {
        "symbol": "UIHC",
        "label": "United Insurance Holdings Corp.",
        "price": 10.79
    }, {
        "symbol": "VCV",
        "label": "Invesco California Value Municipal Income Trust",
        "price": 12.98
    }, {
        "symbol": "BIO",
        "label": "Bio-Rad Laboratories Inc. Class A",
        "price": 376.89
    }, {
        "symbol": "E",
        "label": "ENI S.p.A.",
        "price": 31.01
    }, {
        "symbol": "MIME",
        "label": "Mimecast Limited",
        "price": 49.97
    }, {
        "symbol": "GHDX",
        "label": "Genomic Health Inc.",
        "price": 63.44
    }, {
        "symbol": "KRC",
        "label": "Kilroy Realty Corporation",
        "price": 82.3
    }, {
        "symbol": "RVNC",
        "label": "Revance Therapeutics Inc.",
        "price": 22.465
    }, {
        "symbol": "NATI",
        "label": "National Instruments Corporation",
        "price": 46.075
    }, {
        "symbol": "CRR",
        "label": "Carbo Ceramics Inc.",
        "price": 0.297
    }, {
        "symbol": "ACCO",
        "label": "Acco Brands Corporation",
        "price": 9.15
    }, {
        "symbol": "PLXS",
        "label": "Plexus Corp.",
        "price": 79.03
    }, {
        "symbol": "TRNO",
        "label": "Terreno Realty Corporation",
        "price": 57.14
    }, {
        "symbol": "ASGN",
        "label": "On Assignment Inc.",
        "price": 69.99
    }, {
        "symbol": "CVNA",
        "label": "Carvana Co. Class A",
        "price": 84.0
    }, {
        "symbol": "MANT",
        "label": "ManTech International Corporation",
        "price": 81.87
    }, {
        "symbol": "FEN",
        "label": "First Trust Energy Income and Growth Fund",
        "price": 23.12
    }, {
        "symbol": "GSUM",
        "label": "Gridsum Holding Inc.",
        "price": 1.28
    }, {
        "symbol": "OMAB",
        "label": "Grupo Aeroportuario del Centro Norte S.A.B. de C.V.",
        "price": 64.57
    }, {
        "symbol": "VNOM",
        "label": "Viper Energy Partners LP",
        "price": 24.09
    }, {
        "symbol": "TCPC",
        "label": "TCP Capital Corp.",
        "price": 14.15
    }, {
        "symbol": "IBTX",
        "label": "Independent Bank Group Inc",
        "price": 53.75
    }, {
        "symbol": "ZN",
        "label": "Zion Oil & Gas Inc",
        "price": 0.333
    }, {
        "symbol": "DSPG",
        "label": "DSP Group Inc.",
        "price": 15.89
    }, {
        "symbol": "FWONA",
        "label": "Liberty Media Corporation Series A",
        "price": 45.67
    }, {
        "symbol": "GER",
        "label": "Goldman Sachs MLP Energy Renaissance Fund",
        "price": 4.54
    }, {
        "symbol": "QTRX",
        "label": "Quanterix Corporation",
        "price": 24.09
    }, {
        "symbol": "ZEAL",
        "label": "Zealand Pharma A/S",
        "price": 36.34
    }, {
        "symbol": "ATKR",
        "label": "Atkore International Group Inc.",
        "price": 41.58
    }, {
        "symbol": "UHAL",
        "label": "Amerco",
        "price": 380.66
    }, {
        "symbol": "SJR",
        "label": "Shaw Communications Inc.",
        "price": 20.42
    }, {
        "symbol": "SIGM",
        "label": "Sigma Designs Inc.",
        "price": 0.24
    }, {
        "symbol": "BOLD",
        "label": "Audentes Therapeutics Inc.",
        "price": 59.97
    }, {
        "symbol": "AI",
        "label": "Arlington Asset Investment Corp Class A (new)",
        "price": 5.72
    }, {
        "symbol": "SA",
        "label": "Seabridge Gold Inc. (Canada)",
        "price": 13.28
    }, {
        "symbol": "BCOV",
        "label": "Brightcove Inc.",
        "price": 8.9
    }, {
        "symbol": "ACBI",
        "label": "Atlantic Capital Bancshares Inc.",
        "price": 17.92
    }, {
        "symbol": "CVBF",
        "label": "CVB Financial Corporation",
        "price": 21.76
    }, {
        "symbol": "ISCA",
        "label": "International Speedway Corporation",
        "price": 44.65
    }, {
        "symbol": "NGVT",
        "label": "Ingevity Corporation",
        "price": 82.56
    }, {
        "symbol": "BY",
        "label": "Byline Bancorp Inc.",
        "price": 20.55
    }, {
        "symbol": "LSXMA",
        "label": "Liberty Media Corporation Series A Liberty SiriusXM Common Stock",
        "price": 49.43
    }, {
        "symbol": "PATK",
        "label": "Patrick Industries Inc.",
        "price": 54.68
    }, {
        "symbol": "AGQ",
        "label": "ProShares Ultra Silver",
        "price": 23.48
    }, {
        "symbol": "INUV",
        "label": "Inuvo Inc.",
        "price": 0.3213
    }, {
        "symbol": "SYNC",
        "label": "Synacor Inc.",
        "price": 1.63
    }, {
        "symbol": "VBLT",
        "label": "Vascular Biogenics Ltd.",
        "price": 1.43
    }, {
        "symbol": "VMI",
        "label": "Valmont Industries Inc.",
        "price": 153.22
    }, {
        "symbol": "CHSP",
        "label": "Chesapeake Lodging Trust of Beneficial Interest",
        "price": 28.45
    }, {
        "symbol": "HF",
        "label": "HFF Inc. Class A",
        "price": 43.75
    }, {
        "symbol": "BPMC",
        "label": "Blueprint Medicines Corporation",
        "price": 81.1
    }, {
        "symbol": "SASR",
        "label": "Sandy Spring Bancorp Inc.",
        "price": 36.94
    }, {
        "symbol": "AYR",
        "label": "Aircastle Limited",
        "price": 32.08
    }, {
        "symbol": "CLD",
        "label": "Cloud Peak Energy Inc",
        "price": 0.16
    }, {
        "symbol": "NBRV",
        "label": "Nabriva Therapeutics plc",
        "price": 1.47
    }, {
        "symbol": "PGAL",
        "label": "Global X MSCI Portugal",
        "price": 10.88
    }, {
        "symbol": "TVTY",
        "label": "Tivity Health Inc.",
        "price": 25.56
    }, {
        "symbol": "VGT",
        "label": "Vanguard Information Tech",
        "price": 199.18
    }, {
        "symbol": "FCN",
        "label": "FTI Consulting Inc.",
        "price": 117.84
    }, {
        "symbol": "ATOM",
        "label": "Atomera Incorporated",
        "price": 4.43
    }, {
        "symbol": "WEX",
        "label": "WEX Inc.",
        "price": 225.89
    }, {
        "symbol": "AQXP",
        "label": "Aquinox Pharmaceuticals Inc.",
        "price": 2.24
    }, {
        "symbol": "NXST",
        "label": "Nexstar Media Group Inc.",
        "price": 131.12
    }, {
        "symbol": "CCIH",
        "label": "ChinaCache International Holdings Ltd.",
        "price": 0.96
    }, {
        "symbol": "CURO",
        "label": "CURO Group Holdings Corp.",
        "price": 10.88
    }, {
        "symbol": "KODK",
        "label": "Eastman Kodak Company Common New",
        "price": 3.67
    }, {
        "symbol": "MTRN",
        "label": "Materion Corporation",
        "price": 61.33
    }, {
        "symbol": "TECH",
        "label": "Bio-Techne Corp",
        "price": 220.61
    }, {
        "symbol": "WING",
        "label": "Wingstop Inc.",
        "price": 93.32
    }, {
        "symbol": "VDSI",
        "label": "VASCO Data Security International Inc.",
        "price": 21.83
    }, {
        "symbol": "UBNK",
        "label": "United Financial Bancorp Inc.",
        "price": 13.1
    }, {
        "symbol": "ALO",
        "label": "Alio Gold Inc. (Canada)",
        "price": 0.7587
    }, {
        "symbol": "HAFC",
        "label": "Hanmi Financial Corporation",
        "price": 19.52
    }, {
        "symbol": "FI",
        "label": "Frank's International N.V.",
        "price": 4.12
    }, {
        "symbol": "ADES",
        "label": "Advanced Emissions Solutions Inc.",
        "price": 11.09
    }, {
        "symbol": "ATRA",
        "label": "Atara Biotherapeutics Inc.",
        "price": 15.03
    }, {
        "symbol": "BOOM",
        "label": "DMC Global Inc.",
        "price": 44.02
    }, {
        "symbol": "GLYC",
        "label": "GlycoMimetics Inc.",
        "price": 4.89
    }, {
        "symbol": "SPH",
        "label": "Suburban Propane Partners L.P.",
        "price": 22.6
    }, {
        "symbol": "GCAP",
        "label": "GAIN Capital Holdings Inc.",
        "price": 3.9
    }, {
        "symbol": "NEWR",
        "label": "New Relic Inc.",
        "price": 70.47
    }, {
        "symbol": "VCRA",
        "label": "Vocera Communications Inc.",
        "price": 21.59
    }, {
        "symbol": "SHV",
        "label": "iShares Short Treasury Bond ETF",
        "price": 110.54
    }, {
        "symbol": "FXE",
        "label": "Guggenheim CurrencyShares Euro Currency Trust",
        "price": 107.2
    }, {
        "symbol": "BZH",
        "label": "Beazer Homes USA Inc.",
        "price": 15.31
    }, {
        "symbol": "PRI",
        "label": "Primerica Inc.",
        "price": 128.43
    }, {
        "symbol": "FTSM",
        "label": "First Trust Enhanced Short Maturity ETF",
        "price": 60.14
    }, {
        "symbol": "TCO",
        "label": "Taubman Centers Inc.",
        "price": 31.96
    }, {
        "symbol": "EXLS",
        "label": "ExlService Holdings Inc.",
        "price": 72.25
    }, {
        "symbol": "DM",
        "label": "Dominion Energy Midstream Partners LP representing Limited Partner Interests",
        "price": 17.23
    }, {
        "symbol": "QLYS",
        "label": "Qualys Inc.",
        "price": 84.9
    }, {
        "symbol": "QURE",
        "label": "uniQure N.V.",
        "price": 67.53
    }, {
        "symbol": "RTRX",
        "label": "Retrophin Inc.",
        "price": 16.17
    }, {
        "symbol": "STAR",
        "label": "iStar Inc.",
        "price": 11.73
    }, {
        "symbol": "WIFI",
        "label": "Boingo Wireless Inc.",
        "price": 12.93
    }, {
        "symbol": "CLSD",
        "label": "Clearside Biomedical Inc.",
        "price": 2.84
    }, {
        "symbol": "FNDE",
        "label": "Schwab Fundamental Emerging Markets Large Company Index",
        "price": 27.55
    }, {
        "symbol": "ICUI",
        "label": "ICU Medical Inc.",
        "price": 191.54
    }, {
        "symbol": "WVE",
        "label": "WAVE Life Sciences Ltd.",
        "price": 7.88
    }, {
        "symbol": "MNTX",
        "label": "Manitex International Inc.",
        "price": 5.7
    }, {
        "symbol": "LSTR",
        "label": "Landstar System Inc.",
        "price": 117.84
    }, {
        "symbol": "LTC",
        "label": "LTC Properties Inc.",
        "price": 46.72
    }, {
        "symbol": "ELS",
        "label": "Equity Lifestyle Properties Inc.",
        "price": 70.52
    }, {
        "symbol": "TGS",
        "label": "Transportadora de Gas del Sur SA TGS",
        "price": 6.79
    }, {
        "symbol": "CDR",
        "label": "Cedar Realty Trust Inc.",
        "price": 2.8
    }, {
        "symbol": "BHBK",
        "label": "Blue Hills Bancorp Inc.",
        "price": 23.93
    }, {
        "symbol": "ABDC",
        "label": "Alcentra Capital Corp.",
        "price": 9.22
    }, {
        "symbol": "VGZ",
        "label": "Vista Gold Corp",
        "price": 0.6585
    }, {
        "symbol": "ARTX",
        "label": "Arotech Corporation",
        "price": 2.995
    }, {
        "symbol": "BIF",
        "label": "Boulder Growth & Income Fund Inc.",
        "price": 11.93
    }, {
        "symbol": "IGHG",
        "label": "ProShares Investment Grade-Interest Rate Hedged",
        "price": 72.79
    }, {
        "symbol": "NAK",
        "label": "Northern Dynasty Minerals Ltd.",
        "price": 0.4154
    }, {
        "symbol": "ASIX",
        "label": "AdvanSix Inc.",
        "price": 16.5
    }, {
        "symbol": "GMZ",
        "label": "Goldman Sachs MLP Income Opportunities Fund",
        "price": 6.42
    }, {
        "symbol": "SYBX",
        "label": "Synlogic Inc.",
        "price": 2.98
    }, {
        "symbol": "TEO",
        "label": "Telecom Argentina SA",
        "price": 11.99
    }, {
        "symbol": "WASH",
        "label": "Washington Trust Bancorp Inc.",
        "price": 52.18
    }, {
        "symbol": "XLRN",
        "label": "Acceleron Pharma Inc.",
        "price": 56.14
    }, {
        "symbol": "RMNI",
        "label": "Rimini Street Inc.",
        "price": 4.44
    }, {
        "symbol": "LMAT",
        "label": "LeMaitre Vascular Inc.",
        "price": 36.87
    }, {
        "symbol": "HMI",
        "label": "Huami Corporation American Depositary Shares each representing four Class A",
        "price": 14.77
    }, {
        "symbol": "SRT",
        "label": "StarTek Inc.",
        "price": 7.9
    }, {
        "symbol": "IMDZ",
        "label": "Immune Design Corp.",
        "price": 5.85
    }, {
        "symbol": "BPY",
        "label": "Brookfield Property Partners L.P.",
        "price": 19.86
    }, {
        "symbol": "CBZ",
        "label": "CBIZ Inc.",
        "price": 27.43
    }, {
        "symbol": "BLRX",
        "label": "BioLineRx Ltd.",
        "price": 2.48
    }, {
        "symbol": "KIRK",
        "label": "Kirkland's Inc.",
        "price": 1.6
    }, {
        "symbol": "BSCK",
        "label": "Guggenheim BulletShares 2020 Corporate Bond",
        "price": 21.3
    }, {
        "symbol": "CDZI",
        "label": "Cadiz Inc.",
        "price": 10.13
    }, {
        "symbol": "SAIA",
        "label": "Saia Inc.",
        "price": 97.5
    }, {
        "symbol": "CRMD",
        "label": "CorMedix Inc",
        "price": 7.02
    }, {
        "symbol": "IPOA",
        "label": "Social Capital Hedosophia Holdings Corp. Class A par value $0.0001",
        "price": 10.34
    }, {
        "symbol": "PIN",
        "label": "PowerShares India Portfolio",
        "price": 25.66
    }, {
        "symbol": "RQI",
        "label": "Cohen & Steers Quality Income Realty Fund Inc",
        "price": 14.18
    }, {
        "symbol": "THM",
        "label": "International Tower Hill Mines Ltd. (Canada)",
        "price": 0.5449
    }, {
        "symbol": "IDSY",
        "label": "I.D. Systems Inc.",
        "price": 5.25
    }, {
        "symbol": "SHY",
        "label": "iShares 1-3 Year Treasury Bond ETF",
        "price": 84.77
    }, {
        "symbol": "RRTS",
        "label": "Roadrunner Transportation Systems Inc",
        "price": 7.99
    }, {
        "symbol": "INSY",
        "label": "Insys Therapeutics Inc.",
        "price": 0.86
    }, {
        "symbol": "OGS",
        "label": "ONE Gas Inc.",
        "price": 94.53
    }, {
        "symbol": "RWO",
        "label": "SPDR DJ Wilshire Global Real Estate",
        "price": 50.02
    }, {
        "symbol": "AY",
        "label": "Atlantica Yield plc",
        "price": 27.8
    }, {
        "symbol": "USLV",
        "label": "VelocityShares 3x Long Silver ETN",
        "price": 93.24
    }, {
        "symbol": "CMD",
        "label": "Cantel Medical Corp.",
        "price": 62.46
    }, {
        "symbol": "LBY",
        "label": "Libbey Inc.",
        "price": 1.54
    }, {
        "symbol": "VSS",
        "label": "Vanguard FTSE All-Wld ex-US SmCp Idx",
        "price": 102.28
    }, {
        "symbol": "RBC",
        "label": "Regal Beloit Corporation",
        "price": 86.52
    }, {
        "symbol": "SCHM",
        "label": "Schwab U.S. Mid Cap",
        "price": 61.62
    }, {
        "symbol": "CIO",
        "label": "City Office REIT Inc.",
        "price": 13.91
    }, {
        "symbol": "THD",
        "label": "iShares Inc MSCI Thailand",
        "price": 87.11
    }, {
        "symbol": "FNJN",
        "label": "Finjan Holdings Inc.",
        "price": 2.3
    }, {
        "symbol": "ADUS",
        "label": "Addus HomeCare Corporation",
        "price": 97.4
    }, {
        "symbol": "FTEC",
        "label": "Fidelity MSCI Information Technology Index",
        "price": 76.82
    }, {
        "symbol": "LBAI",
        "label": "Lakeland Bancorp Inc.",
        "price": 16.97
    }, {
        "symbol": "NWE",
        "label": "NorthWestern Corporation",
        "price": 74.7
    }, {
        "symbol": "PLT",
        "label": "Plantronics Inc.",
        "price": 31.79
    }, {
        "symbol": "HTM",
        "label": "U.S. Geothermal Inc.",
        "price": 5.45
    }, {
        "symbol": "GOL",
        "label": "Gol Linhas Aereas Inteligentes S.A. Sponsored ADR representing 2 Pfd Shares",
        "price": 18.13
    }, {
        "symbol": "ELF",
        "label": "e.l.f. Beauty Inc.",
        "price": 15.14
    }, {
        "symbol": "VAC",
        "label": "Marriot Vacations Worldwide Corporation",
        "price": 129.25
    }, {
        "symbol": "GNBC",
        "label": "Green Bancorp Inc.",
        "price": 17.13
    }, {
        "symbol": "OMCL",
        "label": "Omnicell Inc.",
        "price": 85.93
    }, {
        "symbol": "UFPI",
        "label": "Universal Forest Products Inc.",
        "price": 48.31
    }, {
        "symbol": "IBOC",
        "label": "International Bancshares Corporation",
        "price": 42.9
    }, {
        "symbol": "ZAGG",
        "label": "ZAGG Inc",
        "price": 7.93
    }, {
        "symbol": "VEC",
        "label": "Vectrus Inc.",
        "price": 57.02
    }, {
        "symbol": "WBK",
        "label": "Westpac Banking Corporation",
        "price": 17.24
    }, {
        "symbol": "CSQ",
        "label": "Calamos Strategic Total Return Fund",
        "price": 13.97
    }, {
        "symbol": "HELE",
        "label": "Helen of Troy Limited",
        "price": 183.45
    }, {
        "symbol": "CRHM",
        "label": "CRH Medical Corporation of Beneficial Interest",
        "price": 4.05
    }, {
        "symbol": "FGP",
        "label": "Ferrellgas Partners L.P.",
        "price": 0.3701
    }, {
        "symbol": "IIF",
        "label": "Morgan Stanley India Investment Fund Inc.",
        "price": 20.89
    }, {
        "symbol": "NIB",
        "label": "iPath Bloomberg Cocoa Subindex Total Return SM IndexETN",
        "price": 27.45
    }, {
        "symbol": "XSPA",
        "label": "XpresSpa Group Inc.",
        "price": 0.78
    }, {
        "symbol": "BRG",
        "label": "Bluerock Residential Growth REIT Inc. Class A",
        "price": 12.08
    }, {
        "symbol": "GSV",
        "label": "Gold Standard Ventures Corporation (Canada)",
        "price": 0.7551
    }, {
        "symbol": "PCMI",
        "label": "PCM Inc.",
        "price": 24.9
    }, {
        "symbol": "AIR",
        "label": "AAR Corp.",
        "price": 45.82
    }, {
        "symbol": "POL",
        "label": "PolyOne Corporation",
        "price": 36.61
    }, {
        "symbol": "MINT",
        "label": "PIMCO Enhanced Short Maturity Active Exchange-Traded Fund",
        "price": 101.78
    }, {
        "symbol": "EBR",
        "label": "Centrais Electricas Brasileiras S A American Depositary Shares (Each representin" +
                "g one)",
        "price": 9.62
    }, {
        "symbol": "TRVG",
        "label": "trivago N.V.",
        "price": 2.94
    }, {
        "symbol": "HIIQ",
        "label": "Health Insurance Innovations Inc.",
        "price": 20.15
    }, {
        "symbol": "SIEN",
        "label": "Sientra Inc.",
        "price": 7.65
    }, {
        "symbol": "HASI",
        "label": "Hannon Armstrong Sustainable Infrastructure Capital Inc.",
        "price": 33.59
    }, {
        "symbol": "CVI",
        "label": "CVR Energy Inc.",
        "price": 37.44
    }, {
        "symbol": "CPLP",
        "label": "Capital Product Partners L.P.",
        "price": 13.08
    }, {
        "symbol": "JOBS",
        "label": "51job Inc.",
        "price": 90.67
    }, {
        "symbol": "FOGO",
        "label": "Fogo de Chao Inc.",
        "price": 15.73
    }, {
        "symbol": "SPAB",
        "label": "SPDR Portfolio Aggregate Bond",
        "price": 28.86
    }, {
        "symbol": "AWF",
        "label": "Alliancebernstein Global High Income Fund",
        "price": 12.31
    }, {
        "symbol": "BXG",
        "label": "Bluegreen Vacations Corporation",
        "price": 9.58
    }, {
        "symbol": "FBK",
        "label": "FB Financial Corporation",
        "price": 38.8
    }, {
        "symbol": "IYZ",
        "label": "iShares U.S. Telecommunications",
        "price": 29.12
    }, {
        "symbol": "FAX",
        "label": "Aberdeen Asia-Pacific Income Fund Inc",
        "price": 4.35
    }, {
        "symbol": "SHYG",
        "label": "iShares 0-5 Year High Yield Corporate Bond",
        "price": 46.12
    }, {
        "symbol": "NICE",
        "label": "NICE Ltd",
        "price": 175.77
    }, {
        "symbol": "KALU",
        "label": "Kaiser Aluminum Corporation",
        "price": 104.94
    }, {
        "symbol": "ACC",
        "label": "American Campus Communities Inc",
        "price": 46.33
    }, {
        "symbol": "RELX",
        "label": "RELX PLC PLC American Depositary Shares (Each representing One)",
        "price": 26.2
    }, {
        "symbol": "CAMP",
        "label": "CalAmp Corp.",
        "price": 9.99
    }, {
        "symbol": "ICON",
        "label": "Iconix Brand Group Inc.",
        "price": 1.55
    }, {
        "symbol": "JE",
        "label": "Just Energy Group Inc. (Canada)",
        "price": 1.61
    }, {
        "symbol": "ASPS",
        "label": "Altisource Portfolio Solutions S.A.",
        "price": 19.42
    }, {
        "symbol": "RGS",
        "label": "Regis Corporation",
        "price": 18.02
    }, {
        "symbol": "BNO",
        "label": "United States Brent Oil Fund LP ETV",
        "price": 17.66
    }, {
        "symbol": "ZIXI",
        "label": "Zix Corporation",
        "price": 7.805
    }, {
        "symbol": "ACWV",
        "label": "iShares Edge MSCI Min Vol Global",
        "price": 91.12
    }, {
        "symbol": "ITGR",
        "label": "Integer Holdings Corporation",
        "price": 89.03
    }, {
        "symbol": "CGBD",
        "label": "TCG BDC Inc.",
        "price": 14.03
    }, {
        "symbol": "EWN",
        "label": "iShares MSCI Netherlands Index Fund",
        "price": 30.15
    }, {
        "symbol": "MNLO",
        "label": "Menlo Therapeutics Inc.",
        "price": 6.68
    }, {
        "symbol": "BSF",
        "label": "Bear State Financial Inc.",
        "price": 10.32
    }, {
        "symbol": "RGEN",
        "label": "Repligen Corporation",
        "price": 100.71
    }, {
        "symbol": "BPI",
        "label": "Bridgepoint Education Inc.",
        "price": 6.39
    }, {
        "symbol": "KRO",
        "label": "Kronos Worldwide Inc",
        "price": 12.61
    }, {
        "symbol": "SB",
        "label": "Safe Bulkers Inc ($0.001 par value)",
        "price": 1.6
    }, {
        "symbol": "AOSL",
        "label": "Alpha and Omega Semiconductor Limited",
        "price": 14.18
    }, {
        "symbol": "CVGW",
        "label": "Calavo Growers Inc.",
        "price": 80.73
    }, {
        "symbol": "DSKE",
        "label": "Daseke Inc.",
        "price": 3.48
    }, {
        "symbol": "DGICA",
        "label": "Donegal Group Inc. Class A Common Stock",
        "price": 14.42
    }, {
        "symbol": "CCO",
        "label": "Clear Channel Outdoor Holdings Inc. Class A",
        "price": 3.04
    }, {
        "symbol": "TUES",
        "label": "Tuesday Morning Corp.",
        "price": 1.55
    }, {
        "symbol": "GXC",
        "label": "SPDR S&P China",
        "price": 89.01
    }, {
        "symbol": "SILC",
        "label": "Silicom Ltd",
        "price": 35.6
    }, {
        "symbol": "RGLD",
        "label": "Royal Gold Inc.",
        "price": 112.77
    }, {
        "symbol": "ARLZ",
        "label": "Aralez Pharmaceuticals Inc.",
        "price": 0.05
    }, {
        "symbol": "GBDC",
        "label": "Golub Capital BDC Inc.",
        "price": 18.4
    }, {
        "symbol": "IFGL",
        "label": "iShares FTSE EPRA/NAREIT Global Real Estate ex-U.S. Index Fund",
        "price": 30.57
    }, {
        "symbol": "OCSI",
        "label": "Oaktree Strategic Income Corporation",
        "price": 8.35
    }, {
        "symbol": "TSG",
        "label": "The Stars Group Inc.",
        "price": 25.16
    }, {
        "symbol": "EBS",
        "label": "Emergent Biosolutions Inc.",
        "price": 55.56
    }, {
        "symbol": "EURN",
        "label": "Euronav NV",
        "price": 11.74
    }, {
        "symbol": "QTWO",
        "label": "Q2 Holdings Inc.",
        "price": 87.7
    }, {
        "symbol": "SNLN",
        "label": "Highland/iBoxx Senior Loan ETF",
        "price": 17.525
    }, {
        "symbol": "SAGE",
        "label": "Sage Therapeutics Inc.",
        "price": 76.4
    }, {
        "symbol": "NOA",
        "label": "North American Energy Partners Inc. (no par)",
        "price": 11.9
    }, {
        "symbol": "APVO",
        "label": "Aptevo Therapeutics Inc.",
        "price": 0.68
    }, {
        "symbol": "CALX",
        "label": "Calix Inc",
        "price": 8.58
    }, {
        "symbol": "PLAB",
        "label": "Photronics Inc.",
        "price": 15.125
    }, {
        "symbol": "RARX",
        "label": "Ra Pharmaceuticals Inc.",
        "price": 46.97
    }, {
        "symbol": "AGYS",
        "label": "Agilysys Inc.",
        "price": 28.18
    }, {
        "symbol": "RSO",
        "label": "Resource Capital Corp.",
        "price": 9.95
    }, {
        "symbol": "AHT",
        "label": "Ashford Hospitality Trust Inc",
        "price": 2.74
    }, {
        "symbol": "CALA",
        "label": "Calithera Biosciences Inc.",
        "price": 7.41
    }, {
        "symbol": "TGH",
        "label": "Textainer Group Holdings Limited",
        "price": 10.34
    }, {
        "symbol": "CG",
        "label": "The Carlyle Group L.P.",
        "price": 33.28
    }, {
        "symbol": "EVA",
        "label": "Enviva Partners LP representing limited partner interests",
        "price": 37.41
    }, {
        "symbol": "RPV",
        "label": "Guggenheim S&P 500 Pure Value",
        "price": 69.6
    }, {
        "symbol": "ATR",
        "label": "AptarGroup Inc.",
        "price": 117.32
    }, {
        "symbol": "TRST",
        "label": "TrustCo Bank Corp NY",
        "price": 8.41
    }, {
        "symbol": "SYKE",
        "label": "Sykes Enterprises Incorporated",
        "price": 36.83
    }, {
        "symbol": "TEDU",
        "label": "Tarena International Inc.",
        "price": 2.82
    }, {
        "symbol": "FWRD",
        "label": "Forward Air Corporation",
        "price": 71.19
    }, {
        "symbol": "ABG",
        "label": "Asbury Automotive Group Inc",
        "price": 102.26
    }, {
        "symbol": "LBC",
        "label": "Luther Burbank Corporation",
        "price": 11.5
    }, {
        "symbol": "MARA",
        "label": "Marathon Patent Group Inc.",
        "price": 1.04
    }, {
        "symbol": "DS",
        "label": "Drive Shack Inc.",
        "price": 4.13
    }, {
        "symbol": "FLIC",
        "label": "The First of Long Island Corporation",
        "price": 23.88
    }, {
        "symbol": "ARDX",
        "label": "Ardelyx Inc.",
        "price": 7.14
    }, {
        "symbol": "ARR",
        "label": "ARMOUR Residential REIT Inc.",
        "price": 19.0
    }, {
        "symbol": "ADAP",
        "label": "Adaptimmune Therapeutics plc",
        "price": 4.52
    }, {
        "symbol": "BIL",
        "label": "SPDR Bloomberg Barclays 1-3 Month T-Bill",
        "price": 91.46
    }, {
        "symbol": "ATGE",
        "label": "Adtalem Global Education Inc.",
        "price": 36.51
    }, {
        "symbol": "WHD",
        "label": "Cactus Inc. Class A",
        "price": 33.59
    }, {
        "symbol": "BSV",
        "label": "Vanguard Short-Term Bond",
        "price": 80.21
    }, {
        "symbol": "LEXEA",
        "label": "Liberty Expedia Holdings Inc. Series A Common Stock",
        "price": 43.04
    }, {
        "symbol": "LECO",
        "label": "Lincoln Electric Holdings Inc.",
        "price": 96.83
    }, {
        "symbol": "PID",
        "label": "PowerShares International Dividend Achievers Portfolio",
        "price": 17.59
    }, {
        "symbol": "TRVN",
        "label": "Trevena Inc.",
        "price": 0.877
    }, {
        "symbol": "JPS",
        "label": "Nuveen Preferred & Income Securities Fund",
        "price": 10.07
    }, {
        "symbol": "FSB",
        "label": "Franklin Financial Network Inc.",
        "price": 34.46
    }, {
        "symbol": "SPHQ",
        "label": "PowerShares S&P 500 Quality Portfolio",
        "price": 37.68
    }, {
        "symbol": "CATO",
        "label": "Cato Corporation (The) Class A",
        "price": 16.22
    }, {
        "symbol": "SGOL",
        "label": "ETFS Physical Swiss Gold Shares",
        "price": 127.63
    }, {
        "symbol": "MTX",
        "label": "Minerals Technologies Inc.",
        "price": 58.87
    }, {
        "symbol": "BOE",
        "label": "Blackrock Enhanced Global Dividend Trust of Beneficial Interest",
        "price": 11.17
    }, {
        "symbol": "FENG",
        "label": "Phoenix New Media Limited American Depositary Shares each representing 8 Class A" +
                ".",
        "price": 1.96
    }, {
        "symbol": "VTL",
        "label": "Vital Therapies Inc.",
        "price": 0.83
    }, {
        "symbol": "LJPC",
        "label": "La Jolla Pharmaceutical Company",
        "price": 7.73
    }, {
        "symbol": "TREX",
        "label": "Trex Company Inc.",
        "price": 97.88
    }, {
        "symbol": "FTS",
        "label": "Fortis Inc.",
        "price": 43.25
    }, {
        "symbol": "VV",
        "label": "Vanguard Large-Cap",
        "price": 152.64
    }, {
        "symbol": "CCS",
        "label": "Century Communities Inc.",
        "price": 29.36
    }, {
        "symbol": "CHUY",
        "label": "Chuy's Holdings Inc.",
        "price": 24.8
    }, {
        "symbol": "FDN",
        "label": "First Trust DJ Internet Index Fund",
        "price": 148.37
    }, {
        "symbol": "MLNT",
        "label": "Melinta Therapeutics Inc.",
        "price": 0.5
    }, {
        "symbol": "CLB",
        "label": "Core Laboratories N.V.",
        "price": 39.83
    }, {
        "symbol": "CTRN",
        "label": "Citi Trends Inc.",
        "price": 22.43
    }, {
        "symbol": "VSTM",
        "label": "Verastem Inc.",
        "price": 1.9
    }, {
        "symbol": "CIT",
        "label": "CIT Group Inc (DEL)",
        "price": 46.39
    }, {
        "symbol": "UTI",
        "label": "Universal Technical Institute Inc",
        "price": 7.48
    }, {
        "symbol": "GPL",
        "label": "Great Panther Silver Limited (Canada)",
        "price": 0.6288
    }, {
        "symbol": "INTT",
        "label": "inTest Corporation",
        "price": 6.0
    }, {
        "symbol": "BGB",
        "label": "Blackstone / GSO Strategic Credit Fund",
        "price": 14.66
    }, {
        "symbol": "IBP",
        "label": "Installed Building Products Inc.",
        "price": 74.73
    }, {
        "symbol": "CENT",
        "label": "Central Garden & Pet Company",
        "price": 32.87
    }, {
        "symbol": "SPTN",
        "label": "SpartanNash Company",
        "price": 13.4
    }, {
        "symbol": "NML",
        "label": "Neuberger Berman MLP Income Fund Inc.",
        "price": 7.1
    }, {
        "symbol": "NSIT",
        "label": "Insight Enterprises Inc.",
        "price": 70.51
    }, {
        "symbol": "AMRS",
        "label": "Amyris Inc.",
        "price": 2.97
    }, {
        "symbol": "EQBK",
        "label": "Equity Bancshares Inc.",
        "price": 28.29
    }, {
        "symbol": "SEAC",
        "label": "SeaChange International Inc.",
        "price": 3.81
    }, {
        "symbol": "RVLT",
        "label": "Revolution Lighting Technologies Inc.",
        "price": 0.099
    }, {
        "symbol": "JMF",
        "label": "Nuveen Energy MLP Total Return Fund of Beneficial Interest",
        "price": 7.97
    }, {
        "symbol": "RNGR",
        "label": "Ranger Energy Services Inc. Class A",
        "price": 7.21
    }, {
        "symbol": "INAP",
        "label": "Internap Corporation",
        "price": 1.11
    }, {
        "symbol": "ACWX",
        "label": "iShares MSCI ACWI ex US Index Fund",
        "price": 50.0
    }, {
        "symbol": "ARGX",
        "label": "argenx SE",
        "price": 154.39
    }, {
        "symbol": "RACE",
        "label": "Ferrari N.V.",
        "price": 173.94
    }, {
        "symbol": "STKL",
        "label": "SunOpta Inc.",
        "price": 3.04
    }, {
        "symbol": "VFH",
        "label": "Vanguard Financials",
        "price": 77.05
    }, {
        "symbol": "ROL",
        "label": "Rollins Inc.",
        "price": 35.86
    }, {
        "symbol": "KOP",
        "label": "Koppers Holdings Inc.",
        "price": 34.74
    }, {
        "symbol": "STAA",
        "label": "STAAR Surgical Company",
        "price": 42.4
    }, {
        "symbol": "TCRD",
        "label": "THL Credit Inc.",
        "price": 6.37
    }, {
        "symbol": "LFUS",
        "label": "Littelfuse Inc.",
        "price": 190.82
    }, {
        "symbol": "CYBR",
        "label": "CyberArk Software Ltd.",
        "price": 140.0
    }, {
        "symbol": "ULTI",
        "label": "The Ultimate Software Group Inc.",
        "price": 331.37
    }, {
        "symbol": "ESL",
        "label": "Esterline Technologies Corporation",
        "price": 122.5
    }, {
        "symbol": "APOG",
        "label": "Apogee Enterprises Inc.",
        "price": 34.34
    }, {
        "symbol": "MPWR",
        "label": "Monolithic Power Systems Inc.",
        "price": 181.61
    }, {
        "symbol": "MBIN",
        "label": "Merchants Bancorp",
        "price": 19.13
    }, {
        "symbol": "HI",
        "label": "Hillenbrand Inc",
        "price": 32.66
    }, {
        "symbol": "CRED",
        "label": "iShares U.S. Credit Bond ETF",
        "price": 107.66
    }, {
        "symbol": "PZA",
        "label": "PowerShares National AMT-Free Municipal Bond Portfolio",
        "price": 26.73
    }, {
        "symbol": "FELE",
        "label": "Franklin Electric Co. Inc.",
        "price": 60.41
    }, {
        "symbol": "SSB",
        "label": "South State Corporation",
        "price": 86.83
    }, {
        "symbol": "LAYN",
        "label": "Layne Christensen Company",
        "price": 15.4
    }, {
        "symbol": "WBAI",
        "label": "500.com Limited American Depositary Shares each representing 10 Class A shares",
        "price": 7.91
    }, {
        "symbol": "ADMA",
        "label": "ADMA Biologics Inc",
        "price": 4.46
    }, {
        "symbol": "ETY",
        "label": "Eaton Vance Tax-Managed Diversified Equity Income Fund of Beneficial Interest",
        "price": 12.82
    }, {
        "symbol": "GLP",
        "label": "Global Partners LP representing Limited Partner Interests",
        "price": 19.95
    }, {
        "symbol": "ICLN",
        "label": "iShares S&P Global Clean Energy Index Fund",
        "price": 12.49
    }, {
        "symbol": "ITRN",
        "label": "Ituran Location and Control Ltd.",
        "price": 25.72
    }, {
        "symbol": "RYB",
        "label": "RYB Education Inc. American depositary shares each representing one Class A",
        "price": 5.71
    }, {
        "symbol": "MAIN",
        "label": "Main Street Capital Corporation",
        "price": 44.62
    }, {
        "symbol": "INNT",
        "label": "Innovate Biopharmaceuticals Inc.",
        "price": 0.65999
    }, {
        "symbol": "CAFD",
        "label": "8point3 Energy Partners LP",
        "price": 12.42
    }, {
        "symbol": "LANC",
        "label": "Lancaster Colony Corporation",
        "price": 160.4
    }, {
        "symbol": "PRGX",
        "label": "PRGX Global Inc.",
        "price": 4.47
    }, {
        "symbol": "CHDN",
        "label": "Churchill Downs Incorporated",
        "price": 145.13
    }, {
        "symbol": "DQ",
        "label": "DAQO New Energy Corp. American Depositary Shares each representing five",
        "price": 53.63
    }, {
        "symbol": "TYL",
        "label": "Tyler Technologies Inc.",
        "price": 309.03
    }, {
        "symbol": "AKTS",
        "label": "Akoustis Technologies Inc.",
        "price": 8.87
    }, {
        "symbol": "SLP",
        "label": "Simulations Plus Inc.",
        "price": 33.86
    }, {
        "symbol": "RICK",
        "label": "RCI Hospitality Holdings Inc.",
        "price": 18.76
    }, {
        "symbol": "AVAL",
        "label": "Grupo Aval Acciones y Valores S.A. ADR (Each representing 20 preferred shares)",
        "price": 8.68
    }, {
        "symbol": "PER",
        "label": "SandRidge Permian Trust of Benficial Interest",
        "price": 0.9158
    }, {
        "symbol": "ENTA",
        "label": "Enanta Pharmaceuticals Inc.",
        "price": 57.94
    }, {
        "symbol": "DJP",
        "label": "iPath Bloomberg Commodity Index Total Return ETN",
        "price": 21.23
    }, {
        "symbol": "EXPO",
        "label": "Exponent Inc.",
        "price": 72.07
    }, {
        "symbol": "IXC",
        "label": "iShares Global Energy",
        "price": 30.58
    }, {
        "symbol": "INDY",
        "label": "iShares S&P India Nifty 50 Index Fund",
        "price": 39.3127
    }, {
        "symbol": "FRGI",
        "label": "Fiesta Restaurant Group Inc.",
        "price": 10.77
    }, {
        "symbol": "HBIO",
        "label": "Harvard Bioscience Inc.",
        "price": 3.16
    }, {
        "symbol": "SEND",
        "label": "SendGrid Inc.",
        "price": 54.02
    }, {
        "symbol": "DAKT",
        "label": "Daktronics Inc.",
        "price": 6.19
    }, {
        "symbol": "EFG",
        "label": "iShares MSCI EAFE Growth",
        "price": 77.97
    }, {
        "symbol": "AREX",
        "label": "Approach Resources Inc.",
        "price": 0.0235
    }, {
        "symbol": "AAON",
        "label": "AAON Inc.",
        "price": 52.88
    }, {
        "symbol": "SKY",
        "label": "Skyline Corporation",
        "price": 34.89
    }, {
        "symbol": "FPL",
        "label": "First Trust New Opportunities MLP & Energy Fund of Beneficial Interest",
        "price": 9.58
    }, {
        "symbol": "NSTG",
        "label": "NanoString Technologies Inc.",
        "price": 30.8
    }, {
        "symbol": "MOV",
        "label": "Movado Group Inc.",
        "price": 19.9
    }, {
        "symbol": "HSEB",
        "label": "HSBC Holdings plc PERP SUB CAP SECS EXCH PREF SHS SER 2 (United Kingdom)",
        "price": 26.19
    }, {
        "symbol": "GTS",
        "label": "Triple-S Management Corporation Class B",
        "price": 17.6
    }, {
        "symbol": "JRO",
        "label": "Nuveen Floating Rate Income Opportuntiy Fund Shares of Beneficial Interest",
        "price": 10.01
    }, {
        "symbol": "CDMO",
        "label": "Avid Bioservices Inc.",
        "price": 6.53
    }, {
        "symbol": "ARC",
        "label": "ARC Document Solutions Inc.",
        "price": 1.31
    }, {
        "symbol": "KFY",
        "label": "Korn/Ferry International",
        "price": 43.38
    }, {
        "symbol": "CQQQ",
        "label": "Guggenheim China Technology",
        "price": 41.88
    }, {
        "symbol": "GIB",
        "label": "CGI Group Inc.",
        "price": 86.72
    }, {
        "symbol": "MDY",
        "label": "SPDR MidCap Trust Series I",
        "price": 381.45
    }, {
        "symbol": "BCEI",
        "label": "Bonanza Creek Energy Inc.",
        "price": 19.99
    }, {
        "symbol": "DFIN",
        "label": "Donnelley Financial Solutions Inc.",
        "price": 10.63
    }, {
        "symbol": "NNI",
        "label": "Nelnet Inc.",
        "price": 60.31
    }, {
        "symbol": "ESIO",
        "label": "Electro Scientific Industries Inc.",
        "price": 29.99
    }, {
        "symbol": "FLDM",
        "label": "Fluidigm Corporation",
        "price": 4.22
    }, {
        "symbol": "COGT",
        "label": "Cogint Inc.",
        "price": 2.53
    }, {
        "symbol": "EVF",
        "label": "Eaton Vance Senior Income Trust",
        "price": 6.54
    }, {
        "symbol": "IBDD",
        "label": "iShares iBonds Mar 2023 Term Corporate",
        "price": 26.41
    }, {
        "symbol": "NTG",
        "label": "Tortoise MLP Fund Inc.",
        "price": 11.45
    }, {
        "symbol": "CGIX",
        "label": "Cancer Genetics Inc.",
        "price": 4.85
    }, {
        "symbol": "ZOES",
        "label": "Zoe's Kitchen Inc.",
        "price": 12.76
    }, {
        "symbol": "RMAX",
        "label": "RE/MAX Holdings Inc. Class A",
        "price": 38.31
    }, {
        "symbol": "VCLT",
        "label": "Vanguard Long-Term Corporate Bond ETF",
        "price": 102.8
    }, {
        "symbol": "CRVS",
        "label": "Corvus Pharmaceuticals Inc.",
        "price": 5.7
    }, {
        "symbol": "HRI",
        "label": "Herc Holdings Inc.",
        "price": 45.96
    }, {
        "symbol": "LDL",
        "label": "Lydall Inc.",
        "price": 22.34
    }, {
        "symbol": "PRLB",
        "label": "Proto Labs Inc.",
        "price": 109.0
    }, {
        "symbol": "BIT",
        "label": "BlackRock Multi-Sector Income Trust of Beneficial Interest",
        "price": 16.4
    }, {
        "symbol": "TECD",
        "label": "Tech Data Corporation",
        "price": 144.26
    }, {
        "symbol": "HAIR",
        "label": "Restoration Robotics Inc.",
        "price": 0.384
    }, {
        "symbol": "MMI",
        "label": "Marcus & Millichap Inc.",
        "price": 37.79
    }, {
        "symbol": "CR",
        "label": "Crane Company",
        "price": 87.4
    }, {
        "symbol": "CEQP",
        "label": "Crestwood Equity Partners LP",
        "price": 32.29
    }, {
        "symbol": "CRWS",
        "label": "Crown Crafts Inc.",
        "price": 6.36
    }, {
        "symbol": "LNN",
        "label": "Lindsay Corporation",
        "price": 104.66
    }, {
        "symbol": "EDC",
        "label": "Direxion Emerging Markets Bull 3X Shares",
        "price": 66.65
    }, {
        "symbol": "TCBK",
        "label": "TriCo Bancshares",
        "price": 39.19
    }, {
        "symbol": "VMIN",
        "label": "REX VolMAXX Inverse VIX Weekly Futures Strategy",
        "price": 2.71
    }, {
        "symbol": "PTX",
        "label": "Pernix Therapeutics Holdings Inc.",
        "price": 0.21
    }, {
        "symbol": "SODA",
        "label": "SodaStream International Ltd.",
        "price": 143.7
    }, {
        "symbol": "SNDX",
        "label": "Syndax Pharmaceuticals Inc.",
        "price": 9.02
    }, {
        "symbol": "CLNC",
        "label": "Colony NorthStar Credit Real Estate Inc. Class A",
        "price": 13.4
    }, {
        "symbol": "INGN",
        "label": "Inogen Inc",
        "price": 49.43
    }, {
        "symbol": "GDV",
        "label": "Gabelli Dividend & Income Trust of Beneficial Interest",
        "price": 22.44
    }, {
        "symbol": "JONE",
        "label": "Jones Energy Inc. Class A",
        "price": 2.22
    }, {
        "symbol": "GSBC",
        "label": "Great Southern Bancorp Inc.",
        "price": 62.42
    }, {
        "symbol": "PEN",
        "label": "Penumbra Inc.",
        "price": 160.36
    }, {
        "symbol": "MTGE",
        "label": "MTGE Investment Corp.",
        "price": 19.88
    }, {
        "symbol": "AGR",
        "label": "Avangrid Inc.",
        "price": 51.18
    }, {
        "symbol": "ANIP",
        "label": "ANI Pharmaceuticals Inc.",
        "price": 61.27
    }, {
        "symbol": "CETX",
        "label": "Cemtrex Inc.",
        "price": 2.02
    }, {
        "symbol": "FRN",
        "label": "Guggenheim Frontier Markets",
        "price": 14.01
    }, {
        "symbol": "FXC",
        "label": "Guggenheim CurrencyShares Canadian Dollar Trust",
        "price": 73.45
    }, {
        "symbol": "GNCA",
        "label": "Genocea Biosciences Inc.",
        "price": 2.58
    }, {
        "symbol": "LQDT",
        "label": "Liquidity Services Inc.",
        "price": 5.9
    }, {
        "symbol": "PRSC",
        "label": "The Providence Service Corporation",
        "price": 65.38
    }, {
        "symbol": "SCJ",
        "label": "iShares MSCI Japan Sm Cap",
        "price": 67.58
    }, {
        "symbol": "TKC",
        "label": "Turkcell Iletisim Hizmetleri AS",
        "price": 6.09
    }, {
        "symbol": "VMO",
        "label": "Invesco Municipal Opportunity Trust",
        "price": 12.65
    }, {
        "symbol": "CRK",
        "label": "Comstock Resources Inc.",
        "price": 7.18
    }, {
        "symbol": "CYOU",
        "label": "Changyou.com Limited",
        "price": 10.46
    }, {
        "symbol": "FATE",
        "label": "Fate Therapeutics Inc.",
        "price": 25.65
    }, {
        "symbol": "NWN",
        "label": "Northwest Natural Gas Company",
        "price": 73.63
    }, {
        "symbol": "OSS",
        "label": "One Stop Systems Inc.",
        "price": 2.05
    }, {
        "symbol": "TSQ",
        "label": "Townsquare Media Inc. Class A",
        "price": 10.08
    }, {
        "symbol": "PDVW",
        "label": "pdvWireless Inc.",
        "price": 47.96
    }, {
        "symbol": "CODI",
        "label": "Compass Diversified Holdings Shares of Beneficial Interest",
        "price": 23.38
    }, {
        "symbol": "RDVY",
        "label": "First Trust Rising Dividend Achievers ETF",
        "price": 36.63
    }, {
        "symbol": "PBPB",
        "label": "Potbelly Corporation",
        "price": 4.18
    }, {
        "symbol": "WAAS",
        "label": "AquaVenture Holdings Limited",
        "price": 26.87
    }, {
        "symbol": "CRF",
        "label": "Cornerstone Total Return Fund Inc. (The)",
        "price": 11.12
    }, {
        "symbol": "IGR",
        "label": "CBRE Clarion Global Real Estate Income Fund",
        "price": 8.11
    }, {
        "symbol": "NANO",
        "label": "Nanometrics Incorporated",
        "price": 30.59
    }, {
        "symbol": "TACO",
        "label": "Del Taco Restaurants Inc.",
        "price": 8.06
    }, {
        "symbol": "AVAV",
        "label": "AeroVironment Inc.",
        "price": 70.84
    }, {
        "symbol": "SPEM",
        "label": "SPDR Index Shares Fund Portfolio Emerging Markets",
        "price": 38.57
    }, {
        "symbol": "IPGP",
        "label": "IPG Photonics Corporation",
        "price": 145.95
    }, {
        "symbol": "TTOO",
        "label": "T2 Biosystems Inc.",
        "price": 1.17
    }, {
        "symbol": "SAM",
        "label": "Boston Beer Company Inc. (The)",
        "price": 386.6
    }, {
        "symbol": "DSX",
        "label": "Diana Shipping inc.",
        "price": 3.01
    }, {
        "symbol": "IIPR",
        "label": "Innovative Industrial Properties Inc.",
        "price": 82.5
    }, {
        "symbol": "AMPH",
        "label": "Amphastar Pharmaceuticals Inc.",
        "price": 20.08
    }, {
        "symbol": "CNMD",
        "label": "CONMED Corporation",
        "price": 110.51
    }, {
        "symbol": "DOG",
        "label": "ProShares Short Dow30",
        "price": 55.85
    }, {
        "symbol": "HDNG",
        "label": "Hardinge Inc.",
        "price": 18.5
    }, {
        "symbol": "HEQ",
        "label": "John Hancock Hedged Equity & Income Fund of Beneficial Interest",
        "price": 15.13
    }, {
        "symbol": "NDLS",
        "label": "Noodles & Company",
        "price": 6.97
    }, {
        "symbol": "TNH",
        "label": "Terra Nitrogen Company L.P.",
        "price": 83.98
    }, {
        "symbol": "VWOB",
        "label": "Vanguard Emerging Markets Government Bond ETF",
        "price": 81.93
    }, {
        "symbol": "ABCB",
        "label": "Ameris Bancorp",
        "price": 43.53
    }, {
        "symbol": "BPMP",
        "label": "BP Midstream Partners LP representing Limited Partner Interests",
        "price": 16.87
    }, {
        "symbol": "PJC",
        "label": "Piper Jaffray Companies",
        "price": 79.62
    }, {
        "symbol": "CEN",
        "label": "Center Coast Brookfield MLP & Energy Infrastructure Fund",
        "price": 6.71
    }, {
        "symbol": "CYTK",
        "label": "Cytokinetics Incorporated",
        "price": 12.14
    }, {
        "symbol": "NTGR",
        "label": "NETGEAR Inc.",
        "price": 26.62
    }, {
        "symbol": "ITA",
        "label": "iShares U.S. Aerospace & Defense",
        "price": 232.21
    }, {
        "symbol": "FONR",
        "label": "Fonar Corporation",
        "price": 20.75
    }, {
        "symbol": "UNF",
        "label": "Unifirst Corporation",
        "price": 211.82
    }, {
        "symbol": "RDWR",
        "label": "Radware Ltd.",
        "price": 25.92
    }, {
        "symbol": "JDST",
        "label": "Direxion Daily Junior Gold Miners Index Bear 3X Shares",
        "price": 39.81
    }, {
        "symbol": "FTXO",
        "label": "First Trust Nasdaq Bank ETF",
        "price": 27.6193
    }, {
        "symbol": "GIFI",
        "label": "Gulf Island Fabrication Inc.",
        "price": 5.25
    }, {
        "symbol": "BGSF",
        "label": "BG Staffing Inc",
        "price": 21.45
    }, {
        "symbol": "CACC",
        "label": "Credit Acceptance Corporation",
        "price": 450.78
    }, {
        "symbol": "EMO",
        "label": "ClearBridge Energy MLP Opportunity Fund Inc.",
        "price": 8.91
    }, {
        "symbol": "OSIS",
        "label": "OSI Systems Inc.",
        "price": 100.58
    }, {
        "symbol": "NEP",
        "label": "NextEra Energy Partners LP representing limited partner interests",
        "price": 55.68
    }, {
        "symbol": "EPM",
        "label": "Evolution Petroleum Corporation Inc.",
        "price": 5.26
    }, {
        "symbol": "CNA",
        "label": "CNA Financial Corporation",
        "price": 45.97
    }, {
        "symbol": "CRY",
        "label": "CryoLife Inc.",
        "price": 29.99
    }, {
        "symbol": "SRLN",
        "label": "SPDR Blackstone GSO Senior Loan",
        "price": 46.05
    }, {
        "symbol": "FXY",
        "label": "Guggenheim CurrencyShares Japanese Yen Trust",
        "price": 87.89
    }, {
        "symbol": "LAUR",
        "label": "Laureate Education Inc.",
        "price": 18.2
    }, {
        "symbol": "SSD",
        "label": "Simpson Manufacturing Company Inc.",
        "price": 83.38
    }, {
        "symbol": "AMBC",
        "label": "Ambac Financial Group Inc.",
        "price": 22.65
    }, {
        "symbol": "BEL",
        "label": "Belmond Ltd. Class A",
        "price": 25.0
    }, {
        "symbol": "TSLX",
        "label": "TPG Specialty Lending Inc.",
        "price": 21.9
    }, {
        "symbol": "GBNK",
        "label": "Guaranty Bancorp",
        "price": 20.68
    }, {
        "symbol": "AXON",
        "label": "Axovant Sciences Ltd.",
        "price": 1.22
    }, {
        "symbol": "CLUB",
        "label": "Town Sports International Holdings Inc.",
        "price": 2.79
    }, {
        "symbol": "DON",
        "label": "WisdomTree U.S. MidCap Dividend Fund",
        "price": 38.58
    }, {
        "symbol": "OXLC",
        "label": "Oxford Lane Capital Corp.",
        "price": 8.92
    }, {
        "symbol": "PVAC",
        "label": "Penn Virginia Corporation",
        "price": 28.25
    }, {
        "symbol": "TWM",
        "label": "ProShares UltraShort Russell2000",
        "price": 15.99
    }, {
        "symbol": "OLBK",
        "label": "Old Line Bancshares Inc.",
        "price": 28.83
    }, {
        "symbol": "IYF",
        "label": "iShares U.S. Financial",
        "price": 123.36
    }, {
        "symbol": "TNC",
        "label": "Tennant Company",
        "price": 84.58
    }, {
        "symbol": "EIM",
        "label": "Eaton Vance Municipal Bond Fund of Beneficial Interest $.01 par value",
        "price": 13.01
    }, {
        "symbol": "OZM",
        "label": "Och-Ziff Capital Management Group LLC Class A Shares representing Class A limite" +
                "d liability company interests",
        "price": 18.16
    }, {
        "symbol": "PNNT",
        "label": "PennantPark Investment Corporation",
        "price": 6.67
    }, {
        "symbol": "AGIO",
        "label": "Agios Pharmaceuticals Inc.",
        "price": 52.86
    }, {
        "symbol": "UGP",
        "label": "Ultrapar Participacoes S.A. (New) American Depositary Shares (Each representing " +
                "one)",
        "price": 6.28
    }, {
        "symbol": "CO",
        "label": "Global Cord Blood Corporation",
        "price": 5.0
    }, {
        "symbol": "VCSH",
        "label": "Vanguard Short-Term Corporate Bond ETF",
        "price": 81.2
    }, {
        "symbol": "TDY",
        "label": "Teledyne Technologies Incorporated",
        "price": 376.88
    }, {
        "symbol": "SFE",
        "label": "Safeguard Scientifics Inc.",
        "price": 10.85
    }, {
        "symbol": "TRTX",
        "label": "TPG RE Finance Trust Inc.",
        "price": 20.51
    }, {
        "symbol": "PHYS",
        "label": "Sprott Physical Gold Trust ETV",
        "price": 10.61
    }, {
        "symbol": "DFE",
        "label": "WisdomTree Europe SmallCap Dividend Fund",
        "price": 64.5
    }, {
        "symbol": "RUTH",
        "label": "Ruth's Hospitality Group Inc.",
        "price": 22.15
    }, {
        "symbol": "SRCE",
        "label": "1st Source Corporation",
        "price": 51.13
    }, {
        "symbol": "HURN",
        "label": "Huron Consulting Group Inc.",
        "price": 69.04
    }, {
        "symbol": "MNDO",
        "label": "MIND C.T.I. Ltd.",
        "price": 2.46
    }, {
        "symbol": "UGAZ",
        "label": "VelocityShares 3X Long Natural Gas ETN linked to the S&P GSCI Natural Gas Index " +
                "Excess Return",
        "price": 17.92
    }, {
        "symbol": "FTD",
        "label": "FTD Companies Inc.",
        "price": 0.16
    }, {
        "symbol": "PSDO",
        "label": "Presidio Inc.",
        "price": 16.6
    }, {
        "symbol": "HABT",
        "label": "The Habit Restaurants Inc.",
        "price": 13.92
    }, {
        "symbol": "AKG",
        "label": "Asanko Gold Inc.",
        "price": 0.87
    }, {
        "symbol": "CSS",
        "label": "CSS Industries Inc.",
        "price": 4.69
    }, {
        "symbol": "EARS",
        "label": "Auris Medical Holding AG",
        "price": 1.55
    }, {
        "symbol": "HLTH",
        "label": "Nobilis Health Corp.",
        "price": 0.16
    }, {
        "symbol": "IGE",
        "label": "iShares North American Natural Resources",
        "price": 29.22
    }, {
        "symbol": "SRI",
        "label": "Stoneridge Inc.",
        "price": 29.52
    }, {
        "symbol": "WLDN",
        "label": "Willdan Group Inc.",
        "price": 34.98
    }, {
        "symbol": "MEI",
        "label": "Methode Electronics Inc.",
        "price": 39.05
    }, {
        "symbol": "SBSI",
        "label": "Southside Bancshares Inc.",
        "price": 37.28
    }, {
        "symbol": "OPTT",
        "label": "Ocean Power Technologies Inc.",
        "price": 0.9424
    }, {
        "symbol": "WSC",
        "label": "WillScot Corporation",
        "price": 19.14
    }, {
        "symbol": "QCRH",
        "label": "QCR Holdings Inc.",
        "price": 42.42
    }, {
        "symbol": "HSKA",
        "label": "Heska Corporation",
        "price": 107.93
    }, {
        "symbol": "CVTI",
        "label": "Covenant Transportation Group Inc.",
        "price": 14.02
    }, {
        "symbol": "USPH",
        "label": "U.S. Physical Therapy Inc.",
        "price": 120.94
    }, {
        "symbol": "JOE",
        "label": "St. Joe Company (The)",
        "price": 20.42
    }, {
        "symbol": "RMR",
        "label": "The RMR Group Inc.",
        "price": 45.14
    }, {
        "symbol": "CARA",
        "label": "Cara Therapeutics Inc.",
        "price": 17.305
    }, {
        "symbol": "CMRX",
        "label": "Chimerix Inc.",
        "price": 2.04
    }, {
        "symbol": "WATT",
        "label": "Energous Corporation",
        "price": 1.98
    }, {
        "symbol": "CEI",
        "label": "Camber Energy Inc.",
        "price": 1.95
    }, {
        "symbol": "TARO",
        "label": "Taro Pharmaceutical Industries Ltd.",
        "price": 85.55
    }, {
        "symbol": "SOXL",
        "label": "Direxion Daily Semiconductor Bull 3x Shares",
        "price": 116.42
    }, {
        "symbol": "HIX",
        "label": "Western Asset High Income Fund II Inc.",
        "price": 6.88
    }, {
        "symbol": "SBCF",
        "label": "Seacoast Banking Corporation of Florida",
        "price": 30.15
    }, {
        "symbol": "TRTN",
        "label": "Triton International Limited",
        "price": 39.63
    }, {
        "symbol": "NNBR",
        "label": "NN Inc.",
        "price": 9.97
    }, {
        "symbol": "SBT",
        "label": "Sterling Bancorp Inc.",
        "price": 7.67
    }, {
        "symbol": "PARR",
        "label": "Par Pacific Holdings Inc.",
        "price": 21.65
    }, {
        "symbol": "MLPA",
        "label": "Global X MLP",
        "price": 8.66
    }, {
        "symbol": "OSB",
        "label": "Norbord Inc.",
        "price": 30.5
    }, {
        "symbol": "SSTI",
        "label": "ShotSpotter Inc.",
        "price": 28.14
    }, {
        "symbol": "ASA",
        "label": "ASA Gold and Precious Metals Limited",
        "price": 13.6
    }, {
        "symbol": "ASNS",
        "label": "Arsanis Inc.",
        "price": 3.15
    }, {
        "symbol": "NNA",
        "label": "Navios Maritime Acquisition Corporation",
        "price": 7.64
    }, {
        "symbol": "PRPO",
        "label": "Precipio Inc.",
        "price": 2.1
    }, {
        "symbol": "SPKE",
        "label": "Spark Energy Inc.",
        "price": 9.7
    }, {
        "symbol": "REX",
        "label": "REX American Resources Corporation",
        "price": 74.66
    }, {
        "symbol": "EXTN",
        "label": "Exterran Corporation",
        "price": 6.61
    }, {
        "symbol": "IVTY",
        "label": "Invuity Inc.",
        "price": 7.4
    }, {
        "symbol": "FRME",
        "label": "First Merchants Corporation",
        "price": 41.4
    }, {
        "symbol": "WLB",
        "label": "Westmoreland Coal Company",
        "price": 0.17
    }, {
        "symbol": "CBPX",
        "label": "Continental Building Products Inc.",
        "price": 36.51
    }, {
        "symbol": "IDCC",
        "label": "InterDigital Inc.",
        "price": 59.62
    }, {
        "symbol": "TMV",
        "label": "Direxion Daily 20-Year Treasury Bear 3X",
        "price": 14.41
    }, {
        "symbol": "TUR",
        "label": "iShares MSCI Turkey ETF",
        "price": 29.21
    }, {
        "symbol": "AWP",
        "label": "Alpine Global Premier Properties Fund of Beneficial Interest",
        "price": 6.62
    }, {
        "symbol": "ROLL",
        "label": "RBC Bearings Incorporated",
        "price": 174.35
    }, {
        "symbol": "SNNA",
        "label": "Sienna Biopharmaceuticals Inc.",
        "price": 0.1358
    }, {
        "symbol": "NSSC",
        "label": "NAPCO Security Technologies Inc.",
        "price": 32.43
    }, {
        "symbol": "ECH",
        "label": "iShares Inc. MSCI Chile",
        "price": 33.69
    }, {
        "symbol": "NEO",
        "label": "NeoGenomics Inc.",
        "price": 32.05
    }, {
        "symbol": "FPI",
        "label": "Farmland Partners Inc.",
        "price": 6.69
    }, {
        "symbol": "GLOB",
        "label": "Globant S.A.",
        "price": 119.67
    }, {
        "symbol": "EMKR",
        "label": "EMCORE Corporation",
        "price": 3.43
    }, {
        "symbol": "BNED",
        "label": "Barnes & Noble Education Inc",
        "price": 3.92
    }, {
        "symbol": "FBNC",
        "label": "First Bancorp",
        "price": 38.39
    }, {
        "symbol": "LGCY",
        "label": "Legacy Reserves LP",
        "price": 0.22
    }, {
        "symbol": "DOVA",
        "label": "Dova Pharmaceuticals Inc.",
        "price": 28.04
    }, {
        "symbol": "SLRC",
        "label": "Solar Capital Ltd.",
        "price": 20.78
    }, {
        "symbol": "CPF",
        "label": "Central Pacific Financial Corp New",
        "price": 29.0
    }, {
        "symbol": "FNCL",
        "label": "Fidelity MSCI Financials Index",
        "price": 44.81
    }, {
        "symbol": "DSL",
        "label": "DoubleLine Income Solutions Fund of Beneficial Interests",
        "price": 20.1
    }, {
        "symbol": "HEWP",
        "label": "iShares Currency Hedged MSCI Spain",
        "price": 22.18
    }, {
        "symbol": "ISTB",
        "label": "iShares Core 1-5 Year USD Bond ETF",
        "price": 50.53
    }, {
        "symbol": "MTBC",
        "label": "Medical Transcription Billing Corp.",
        "price": 4.73
    }, {
        "symbol": "NEON",
        "label": "Neonode Inc.",
        "price": 2.81
    }, {
        "symbol": "NXE",
        "label": "Nexgen Energy Ltd.",
        "price": 1.27
    }, {
        "symbol": "PBFX",
        "label": "PBF Logistics LP representing limited partner interests",
        "price": 21.56
    }, {
        "symbol": "RCUS",
        "label": "Arcus Biosciences Inc.",
        "price": 10.23
    }, {
        "symbol": "AMRC",
        "label": "Ameresco Inc. Class A",
        "price": 19.78
    }, {
        "symbol": "TOWR",
        "label": "Tower International Inc.",
        "price": 18.08
    }, {
        "symbol": "SD",
        "label": "SandRidge Energy Inc.",
        "price": 3.41
    }, {
        "symbol": "EGLE",
        "label": "Eagle Bulk Shipping Inc.",
        "price": 4.25
    }, {
        "symbol": "MTEM",
        "label": "Molecular Templates Inc.",
        "price": 13.71
    }, {
        "symbol": "EGBN",
        "label": "Eagle Bancorp Inc.",
        "price": 46.13
    }, {
        "symbol": "THG",
        "label": "Hanover Insurance Group Inc",
        "price": 139.28
    }, {
        "symbol": "NOAH",
        "label": "Noah Holdings Limited",
        "price": 37.93
    }, {
        "symbol": "IDA",
        "label": "IDACORP Inc.",
        "price": 109.12
    }, {
        "symbol": "MED",
        "label": "MEDIFAST INC",
        "price": 115.58
    }, {
        "symbol": "FRBK",
        "label": "Republic First Bancorp Inc.",
        "price": 3.84
    }, {
        "symbol": "PEBO",
        "label": "Peoples Bancorp Inc.",
        "price": 34.61
    }, {
        "symbol": "AMRWW",
        "label": "Alta Mesa Resources Inc. Warrant",
        "price": 0.01
    }, {
        "symbol": "IWN",
        "label": "iShares Russell 2000 Value",
        "price": 117.02
    }, {
        "symbol": "ODT",
        "label": "Odonate Therapeutics Inc.",
        "price": 28.71
    }, {
        "symbol": "LOB",
        "label": "Live Oak Bancshares Inc.",
        "price": 18.7
    }, {
        "symbol": "SHOS",
        "label": "Sears Hometown and Outlet Stores Inc.",
        "price": 2.42
    }, {
        "symbol": "ETJ",
        "label": "Eaton Vance Risk-Managed Diversified Equity Income Fund of Beneficial Interest",
        "price": 9.55
    }, {
        "symbol": "FMHI",
        "label": "First Trust Municipal High Income ETF",
        "price": 53.955
    }, {
        "symbol": "MCRI",
        "label": "Monarch Casino & Resort Inc.",
        "price": 52.66
    }, {
        "symbol": "NSL",
        "label": "Nuveen Senior Income Fund",
        "price": 6.03
    }, {
        "symbol": "CRNT",
        "label": "Ceragon Networks Ltd.",
        "price": 2.2
    }, {
        "symbol": "RTEC",
        "label": "Rudolph Technologies Inc.",
        "price": 23.67
    }, {
        "symbol": "WMK",
        "label": "Weis Markets Inc.",
        "price": 38.95
    }, {
        "symbol": "IDT",
        "label": "IDT Corporation Class B",
        "price": 7.57
    }, {
        "symbol": "AHP",
        "label": "Ashford Hospitality Prime Inc.",
        "price": 10.39
    }, {
        "symbol": "WEB",
        "label": "Web.com Group Inc.",
        "price": 27.99
    }, {
        "symbol": "INSG",
        "label": "Inseego Corp.",
        "price": 9.15
    }, {
        "symbol": "ROYT",
        "label": "Pacific Coast Oil Trust Units of Beneficial Interest",
        "price": 0.5782
    }, {
        "symbol": "RTTR",
        "label": "Ritter Pharmaceuticals Inc.",
        "price": 0.2203
    }, {
        "symbol": "SDIV",
        "label": "Global X SuperDividend",
        "price": 16.87
    }, {
        "symbol": "TNP",
        "label": "Tsakos Energy Navigation Ltd",
        "price": 3.84
    }, {
        "symbol": "NGS",
        "label": "Natural Gas Services Group Inc.",
        "price": 11.73
    }, {
        "symbol": "CPSI",
        "label": "Computer Programs and Systems Inc.",
        "price": 27.96
    }, {
        "symbol": "AGS",
        "label": "PlayAGS Inc.",
        "price": 11.0
    }, {
        "symbol": "SCPH",
        "label": "scPharmaceuticals Inc.",
        "price": 5.4
    }, {
        "symbol": "GPRK",
        "label": "Geopark Ltd",
        "price": 20.63
    }, {
        "symbol": "TRNC",
        "label": "tronc Inc.",
        "price": 16.09
    }, {
        "symbol": "CLCT",
        "label": "Collectors Universe Inc.",
        "price": 24.83
    }, {
        "symbol": "CDXC",
        "label": "ChromaDex Corporation",
        "price": 4.36
    }, {
        "symbol": "BBSI",
        "label": "Barrett Business Services Inc.",
        "price": 90.19
    }, {
        "symbol": "FOR",
        "label": "Forestar Group Inc",
        "price": 22.19
    }, {
        "symbol": "PPT",
        "label": "Putnam Premier Income Trust",
        "price": 5.51
    }, {
        "symbol": "VLRS",
        "label": "Controladora Vuela Compania de Aviacion S.A.B. de C.V. American Depositary Share" +
                "s each representing ten (10) Ordinary Participation Certificates",
        "price": 11.4
    }, {
        "symbol": "ASMB",
        "label": "Assembly Biosciences Inc.",
        "price": 17.64
    }, {
        "symbol": "BSJJ",
        "label": "Guggenheim BulletShares 2019 High Yield Corporate Bond",
        "price": 23.94
    }, {
        "symbol": "CIR",
        "label": "CIRCOR International Inc.",
        "price": 44.69
    }, {
        "symbol": "IPHS",
        "label": "Innophos Holdings Inc.",
        "price": 31.95
    }, {
        "symbol": "FARO",
        "label": "FARO Technologies Inc.",
        "price": 55.4
    }, {
        "symbol": "THR",
        "label": "Thermon Group Holdings Inc.",
        "price": 26.56
    }, {
        "symbol": "LLEX",
        "label": "Lilis Energy Inc.",
        "price": 0.2982
    }, {
        "symbol": "PWV",
        "label": "PowerShares Dynamic LargeCap Value",
        "price": 41.84
    }, {
        "symbol": "RNR",
        "label": "RenaissanceRe Holdings Ltd.",
        "price": 193.22
    }, {
        "symbol": "PICO",
        "label": "PICO Holdings Inc.",
        "price": 10.17
    }, {
        "symbol": "CHE",
        "label": "Chemed Corp",
        "price": 473.83
    }, {
        "symbol": "JRVR",
        "label": "James River Group Holdings Ltd.",
        "price": 42.17
    }, {
        "symbol": "NBTB",
        "label": "NBT Bancorp Inc.",
        "price": 40.92
    }, {
        "symbol": "CMTL",
        "label": "Comtech Telecommunications Corp.",
        "price": 36.26
    }, {
        "symbol": "CRSP",
        "label": "CRISPR Therapeutics AG",
        "price": 58.33
    }, {
        "symbol": "DHX",
        "label": "DHI Group Inc.",
        "price": 3.03
    }, {
        "symbol": "EHIC",
        "label": "eHi Car Services Limited American Depositary Shares",
        "price": 12.2
    }, {
        "symbol": "EWO",
        "label": "iShares Inc MSCI Austria",
        "price": 19.86
    }, {
        "symbol": "IVAC",
        "label": "Intevac Inc.",
        "price": 7.54
    }, {
        "symbol": "WG",
        "label": "Willbros Group Inc. (DE)",
        "price": 0.15
    }, {
        "symbol": "TIS",
        "label": "Orchids Paper Products Company",
        "price": 1.27
    }, {
        "symbol": "VICL",
        "label": "Vical Incorporated",
        "price": 0.82
    }, {
        "symbol": "KFRC",
        "label": "Kforce Inc.",
        "price": 39.7
    }, {
        "symbol": "MUC",
        "label": "Blackrock MuniHoldings California Quality Fund Inc.",
        "price": 14.3
    }, {
        "symbol": "SMI",
        "label": "Semiconductor Manufacturing International Corporation ADR",
        "price": 5.55
    }, {
        "symbol": "CDTX",
        "label": "Cidara Therapeutics Inc.",
        "price": 3.475
    }, {
        "symbol": "CNOB",
        "label": "ConnectOne Bancorp Inc.",
        "price": 25.5
    }, {
        "symbol": "GLAD",
        "label": "Gladstone Capital Corporation",
        "price": 10.38
    }, {
        "symbol": "KMG",
        "label": "KMG Chemicals Inc.",
        "price": 75.98
    }, {
        "symbol": "PRF",
        "label": "Powershares FTSE RAFI US 1000 Portfolio",
        "price": 129.03
    }, {
        "symbol": "UFCS",
        "label": "United Fire Group Inc",
        "price": 46.52
    }, {
        "symbol": "CCMP",
        "label": "Cabot Microelectronics Corporation",
        "price": 154.7
    }, {
        "symbol": "TRS",
        "label": "TriMas Corporation",
        "price": 31.57
    }, {
        "symbol": "NSP",
        "label": "Insperity Inc.",
        "price": 93.18
    }, {
        "symbol": "BBN",
        "label": "BlackRock Taxable Municipal Bond Trust of Beneficial Interest",
        "price": 24.86
    }, {
        "symbol": "DFRG",
        "label": "Del Frisco's Restaurant Group Inc.",
        "price": 6.1
    }, {
        "symbol": "NZF",
        "label": "Nuveen Municipal Credit Income Fund",
        "price": 16.31
    }, {
        "symbol": "MEIP",
        "label": "MEI Pharma Inc.",
        "price": 2.52
    }, {
        "symbol": "CLNE",
        "label": "Clean Energy Fuels Corp.",
        "price": 2.52
    }, {
        "symbol": "XOXO",
        "label": "XO Group Inc.",
        "price": 34.99
    }, {
        "symbol": "LKM",
        "label": "Link Motion Inc. American Depositary Shares each representing five Class A",
        "price": 0.16
    }, {
        "symbol": "OMEX",
        "label": "Odyssey Marine Exploration Inc.",
        "price": 4.33
    }, {
        "symbol": "CBU",
        "label": "Community Bank System Inc.",
        "price": 71.32
    }, {
        "symbol": "XENT",
        "label": "Intersect ENT Inc.",
        "price": 24.45
    }, {
        "symbol": "CRME",
        "label": "Cardiome Pharma Corporation",
        "price": 2.31
    }, {
        "symbol": "EVM",
        "label": "Eaton Vance California Municipal Bond Fund of Beneficial Interest $.01 par value",
        "price": 11.47
    }, {
        "symbol": "IGV",
        "label": "iShares North American Tech-Software",
        "price": 250.21
    }, {
        "symbol": "PXMG",
        "label": "PowerShares Russell Midcap Pure Growth Portfolio",
        "price": 56.36
    }, {
        "symbol": "QUAL",
        "label": "iShares Edge MSCI USA Quality Factor",
        "price": 104.56
    }, {
        "symbol": "TCON",
        "label": "TRACON Pharmaceuticals Inc.",
        "price": 3.96
    }, {
        "symbol": "VKI",
        "label": "Invesco Advantage Municipal Income Trust II of Beneficial Interest (DE)",
        "price": 11.31
    }, {
        "symbol": "NX",
        "label": "Quanex Building Products Corporation",
        "price": 17.85
    }, {
        "symbol": "STRA",
        "label": "Strayer Education Inc.",
        "price": 162.85
    }, {
        "symbol": "EWZS",
        "label": "iShares MSCI Brazil Small-Cap ETF",
        "price": 21.91
    }, {
        "symbol": "JAKK",
        "label": "JAKKS Pacific Inc.",
        "price": 1.0345
    }, {
        "symbol": "NERV",
        "label": "Minerva Neurosciences Inc",
        "price": 8.36
    }, {
        "symbol": "RECN",
        "label": "Resources Connection Inc.",
        "price": 16.28
    }, {
        "symbol": "EARN",
        "label": "Ellington Residential Mortgage REIT of Beneficial Interest",
        "price": 10.91
    }, {
        "symbol": "ATNX",
        "label": "Athenex Inc.",
        "price": 15.27
    }, {
        "symbol": "NYMX",
        "label": "Nymox Pharmaceutical Corporation",
        "price": 3.06
    }, {
        "symbol": "ALNA",
        "label": "Allena Pharmaceuticals Inc.",
        "price": 2.675
    }, {
        "symbol": "AVX",
        "label": "AVX Corporation",
        "price": 20.54
    }, {
        "symbol": "MYO",
        "label": "Myomo Inc.",
        "price": 0.323
    }, {
        "symbol": "FCSC",
        "label": "Fibrocell Science Inc",
        "price": 2.99
    }, {
        "symbol": "PDFS",
        "label": "PDF Solutions Inc.",
        "price": 17.31
    }, {
        "symbol": "MVIS",
        "label": "Microvision Inc.",
        "price": 0.747
    }, {
        "symbol": "TBK",
        "label": "Triumph Bancorp Inc.",
        "price": 38.29
    }, {
        "symbol": "DWX",
        "label": "SPDR S&P International Dividend",
        "price": 38.62
    }, {
        "symbol": "PFSI",
        "label": "PennyMac Financial Services Inc. Class A",
        "price": 33.7
    }, {
        "symbol": "DSE",
        "label": "Duff & Phelps Select Energy MLP Fund Inc.",
        "price": 4.05
    }, {
        "symbol": "AQN",
        "label": "Algonquin Power & Utilities Corp.",
        "price": 14.77
    }, {
        "symbol": "ARMO",
        "label": "ARMO BioSciences Inc.",
        "price": 49.99
    }, {
        "symbol": "CHIQ",
        "label": "Global X China Consumer",
        "price": 14.54
    }, {
        "symbol": "FIBK",
        "label": "First Interstate BancSystem Inc.",
        "price": 41.12
    }, {
        "symbol": "DEUS",
        "label": "Xtrackers Russell 1000 Comprehensive Factor",
        "price": 33.46
    }, {
        "symbol": "EDD",
        "label": "Morgan Stanley Emerging Markets Domestic Debt Fund Inc.",
        "price": 6.99
    }, {
        "symbol": "FEI",
        "label": "First Trust MLP and Energy Income Fund of Beneficial Interest",
        "price": 11.4
    }, {
        "symbol": "GPX",
        "label": "GP Strategies Corporation",
        "price": 14.9
    }, {
        "symbol": "MARK",
        "label": "Remark Holdings Inc.",
        "price": 0.628
    }, {
        "symbol": "MCA",
        "label": "Blackrock MuniYield California Quality Fund Inc.",
        "price": 14.49
    }, {
        "symbol": "OPTN",
        "label": "OptiNose Inc.",
        "price": 8.57
    }, {
        "symbol": "QTNA",
        "label": "Quantenna Communications Inc.",
        "price": 24.26
    }, {
        "symbol": "TLH",
        "label": "iShares 10-20 Year Treasury Bond",
        "price": 141.08
    }, {
        "symbol": "VOC",
        "label": "VOC Energy Trust Units of Beneficial Interest",
        "price": 4.82
    }, {
        "symbol": "CHUBA",
        "label": "CommerceHub Inc. Series A Common Stock",
        "price": 22.74
    }, {
        "symbol": "SRV",
        "label": "Cushing MLP & Infrastructure Total Return Fund",
        "price": 10.1
    }, {
        "symbol": "ETO",
        "label": "Eaton Vance Tax-Advantage Global Dividend Opp",
        "price": 26.73
    }, {
        "symbol": "FCG",
        "label": "First Trust Natural Gas",
        "price": 14.67
    }, {
        "symbol": "CRCM",
        "label": "Care.com Inc.",
        "price": 14.98
    }, {
        "symbol": "BLCM",
        "label": "Bellicum Pharmaceuticals Inc.",
        "price": 2.29
    }, {
        "symbol": "FFIN",
        "label": "First Financial Bankshares Inc.",
        "price": 34.925
    }, {
        "symbol": "RGNX",
        "label": "REGENXBIO Inc.",
        "price": 48.19
    }, {
        "symbol": "APPF",
        "label": "AppFolio Inc.",
        "price": 124.1
    }, {
        "symbol": "KE",
        "label": "Kimball Electronics Inc.",
        "price": 17.55
    }, {
        "symbol": "ASR",
        "label": "Grupo Aeroportuario del Sureste S.A. de C.V.",
        "price": 205.4
    }, {
        "symbol": "EBIO",
        "label": "Eleven Biotherapeutics Inc.",
        "price": 3.3
    }, {
        "symbol": "MBRX",
        "label": "Moleculin Biotech Inc.",
        "price": 0.9594
    }, {
        "symbol": "AFMD",
        "label": "Affimed N.V.",
        "price": 3.02
    }, {
        "symbol": "ASYS",
        "label": "Amtech Systems Inc.",
        "price": 7.54
    }, {
        "symbol": "APEI",
        "label": "American Public Education Inc.",
        "price": 27.94
    }, {
        "symbol": "HYGS",
        "label": "Hydrogenics Corporation",
        "price": 11.75
    }, {
        "symbol": "PSB",
        "label": "PS Business Parks Inc.",
        "price": 170.85
    }, {
        "symbol": "GORO",
        "label": "Gold Resource Corporation",
        "price": 5.0
    }, {
        "symbol": "MN",
        "label": "Manning & Napier Inc. Class A",
        "price": 2.03
    }, {
        "symbol": "NITE",
        "label": "Nightstar Therapeutics plc",
        "price": 25.45
    }, {
        "symbol": "AFI",
        "label": "Armstrong Flooring Inc.",
        "price": 4.36
    }, {
        "symbol": "TECL",
        "label": "Direxion Technology Bull 3X Shares",
        "price": 132.52
    }, {
        "symbol": "VICR",
        "label": "Vicor Corporation",
        "price": 54.29
    }, {
        "symbol": "MORN",
        "label": "Morningstar Inc.",
        "price": 158.68
    }, {
        "symbol": "EIGR",
        "label": "Eiger BioPharmaceuticals Inc.",
        "price": 14.09
    }, {
        "symbol": "ATRC",
        "label": "AtriCure Inc.",
        "price": 38.25
    }, {
        "symbol": "EMLP",
        "label": "First Trust North American Energy Infrastructure Fund",
        "price": 26.17
    }, {
        "symbol": "ICD",
        "label": "Independence Contract Drilling Inc.",
        "price": 0.8232
    }, {
        "symbol": "CW",
        "label": "Curtiss-Wright Corporation",
        "price": 148.67
    }, {
        "symbol": "IWR",
        "label": "iShares Russell Mid-Cap",
        "price": 54.12
    }, {
        "symbol": "APLS",
        "label": "Apellis Pharmaceuticals Inc.",
        "price": 40.5
    }, {
        "symbol": "CFO",
        "label": "VictoryShares US 500 Enhanced Volatility Wtd ETF",
        "price": 53.9147
    }, {
        "symbol": "ILTB",
        "label": "iShares Core 10 Year USD Bond",
        "price": 63.94
    }, {
        "symbol": "ALE",
        "label": "Allete Inc.",
        "price": 83.57
    }, {
        "symbol": "Y",
        "label": "Alleghany Corporation",
        "price": 816.56
    }, {
        "symbol": "HZN",
        "label": "Horizon Global Corporation",
        "price": 3.45
    }, {
        "symbol": "UEIC",
        "label": "Universal Electronics Inc.",
        "price": 52.73
    }, {
        "symbol": "KOL",
        "label": "VanEck Vectors Coal",
        "price": 12.62
    }, {
        "symbol": "AFH",
        "label": "Atlas Financial Holdings Inc.",
        "price": 0.61
    }, {
        "symbol": "AQ",
        "label": "Aquantia Corp.",
        "price": 13.12
    }, {
        "symbol": "AUTO",
        "label": "AutoWeb Inc.",
        "price": 2.41
    }, {
        "symbol": "CKPT",
        "label": "Checkpoint Therapeutics Inc.",
        "price": 1.81
    }, {
        "symbol": "HTBI",
        "label": "HomeTrust Bancshares Inc.",
        "price": 26.63
    }, {
        "symbol": "IDN",
        "label": "Intellicheck Inc.",
        "price": 9.66
    }, {
        "symbol": "KOF",
        "label": "Coca Cola Femsa S.A.B. de C.V.",
        "price": 61.95
    }, {
        "symbol": "LYTS",
        "label": "LSI Industries Inc.",
        "price": 6.29
    }, {
        "symbol": "PHI",
        "label": "PLDT Inc. Sponsored ADR",
        "price": 21.24
    }, {
        "symbol": "RIF",
        "label": "RMR Real Estate Income Fund (MD)",
        "price": 20.8
    }, {
        "symbol": "SPHB",
        "label": "PowerShares S&P 500 High Beta Portfolio",
        "price": 40.18
    }, {
        "symbol": "CTR",
        "label": "ClearBridge Energy MLP Total Return Fund Inc.",
        "price": 9.03
    }, {
        "symbol": "UBNT",
        "label": "Ubiquiti Networks Inc.",
        "price": 125.15
    }, {
        "symbol": "MFL",
        "label": "Blackrock MuniHoldings Investment Quality Fund of Beneficial Interest",
        "price": 13.62
    }, {
        "symbol": "YINN",
        "label": "Direxion Daily FTSE China Bull 3x Shares",
        "price": 17.36
    }, {
        "symbol": "ORA",
        "label": "Ormat Technologies Inc.",
        "price": 78.4
    }, {
        "symbol": "CMPR",
        "label": "Cimpress N.V",
        "price": 105.23
    }, {
        "symbol": "ESTE",
        "label": "Earthstone Energy Inc. Class A",
        "price": 5.46
    }, {
        "symbol": "SHM",
        "label": "SPDR Nuveen Bloomberg Barclays Short Term Municipal Bond",
        "price": 48.77
    }, {
        "symbol": "VKQ",
        "label": "Invesco Municipal Trust",
        "price": 12.64
    }, {
        "symbol": "EDGE",
        "label": "Edge Therapeutics Inc.",
        "price": 0.54
    }, {
        "symbol": "SRDX",
        "label": "Surmodics Inc.",
        "price": 41.22
    }, {
        "symbol": "OCUL",
        "label": "Ocular Therapeutix Inc.",
        "price": 4.63
    }, {
        "symbol": "WSBC",
        "label": "WesBanco Inc.",
        "price": 36.81
    }, {
        "symbol": "RAS",
        "label": "RAIT Financial Trust of Beneficial Interest",
        "price": 0.16
    }, {
        "symbol": "PFBC",
        "label": "Preferred Bank",
        "price": 60.8
    }, {
        "symbol": "CARG",
        "label": "CarGurus Inc.",
        "price": 38.06
    }, {
        "symbol": "EMAN",
        "label": "eMagin Corporation",
        "price": 0.439919
    }, {
        "symbol": "EPZM",
        "label": "Epizyme Inc.",
        "price": 26.72
    }, {
        "symbol": "WMC",
        "label": "Western Asset Mortgage Capital Corporation",
        "price": 10.48
    }, {
        "symbol": "TAN",
        "label": "Guggenheim Solar",
        "price": 26.73
    }, {
        "symbol": "LX",
        "label": "LexinFintech Holdings Ltd.",
        "price": 14.35
    }, {
        "symbol": "AIMC",
        "label": "Altra Industrial Motion Corp.",
        "price": 36.65
    }, {
        "symbol": "MFNC",
        "label": "Mackinac Financial Corporation",
        "price": 16.44
    }, {
        "symbol": "AUO",
        "label": "AU Optronics Corp American Depositary Shares",
        "price": 2.92
    }, {
        "symbol": "ADRO",
        "label": "Aduro Biotech Inc.",
        "price": 1.4
    }, {
        "symbol": "ARII",
        "label": "American Railcar Industries Inc.",
        "price": 69.97
    }, {
        "symbol": "CHI",
        "label": "Calamos Convertible Opportunities and Income Fund",
        "price": 11.26
    }, {
        "symbol": "CLY",
        "label": "iShares 10 Year Credit Bond",
        "price": 58.58
    }, {
        "symbol": "LEJU",
        "label": "Leju Holdings Limited American Depositary Shares each representing one",
        "price": 2.28
    }, {
        "symbol": "SCHH",
        "label": "Schwab U.S. REIT",
        "price": 44.72
    }, {
        "symbol": "SIVR",
        "label": "ETFS Physical Silver Shares Trust",
        "price": 14.38
    }, {
        "symbol": "NCZ",
        "label": "AllianzGI Convertible & Income Fund II of Beneficial Interest",
        "price": 5.23
    }, {
        "symbol": "UTF",
        "label": "Cohen & Steers Infrastructure Fund Inc",
        "price": 27.12
    }, {
        "symbol": "IBND",
        "label": "SPDR Bloomberg Barclays International Corporate Bond",
        "price": 33.39
    }, {
        "symbol": "CIU",
        "label": "iShares Intermediate Credit Bond ETF",
        "price": 106.69
    }, {
        "symbol": "GHY",
        "label": "Prudential Global Short Duration High Yield Fund Inc.",
        "price": 15.19
    }, {
        "symbol": "HMTV",
        "label": "Hemisphere Media Group Inc.",
        "price": 14.27
    }, {
        "symbol": "PAC",
        "label": "Grupo Aeroportuario Del Pacifico S.A. B. de C.V. de C.V. (each representing 10 S" +
                "eries B shares)",
        "price": 132.72
    }, {
        "symbol": "KYO",
        "label": "Kyocera Corporation",
        "price": 55.22
    }, {
        "symbol": "CTEK",
        "label": "CynergisTek Inc.",
        "price": 3.3
    }, {
        "symbol": "ABIL",
        "label": "Ability Inc.",
        "price": 0.36
    }, {
        "symbol": "IWS",
        "label": "iShares Russell Mid-cap Value",
        "price": 86.56
    }, {
        "symbol": "PLX",
        "label": "Protalix BioTherapeutics Inc. (DE)",
        "price": 3.55
    }, {
        "symbol": "IEI",
        "label": "iShares 3-7 Year Treasury Bond ETF",
        "price": 126.29
    }, {
        "symbol": "NVEE",
        "label": "NV5 Global Inc.",
        "price": 55.63
    }, {
        "symbol": "ATRO",
        "label": "Astronics Corporation",
        "price": 28.9
    }, {
        "symbol": "CFRX",
        "label": "ContraFect Corporation",
        "price": 1.04
    }, {
        "symbol": "AZZ",
        "label": "AZZ Inc.",
        "price": 44.07
    }, {
        "symbol": "SJT",
        "label": "San Juan Basin Royalty Trust",
        "price": 2.8
    }, {
        "symbol": "DX",
        "label": "Dynex Capital Inc.",
        "price": 17.99
    }, {
        "symbol": "IO",
        "label": "Ion Geophysical Corporation",
        "price": 6.34
    }, {
        "symbol": "VO",
        "label": "Vanguard Mid-Cap",
        "price": 183.55
    }, {
        "symbol": "IWV",
        "label": "iShares Russell 3000",
        "price": 194.31
    }, {
        "symbol": "KLXI",
        "label": "KLX Inc.",
        "price": 63.0
    }, {
        "symbol": "BLX",
        "label": "Banco Latinoamericano de Comercio Exterior S.A.",
        "price": 22.45
    }, {
        "symbol": "ENSG",
        "label": "The Ensign Group Inc.",
        "price": 47.53
    }, {
        "symbol": "SXCP",
        "label": "SunCoke Energy Partners L.P. Representing Limited partner Interests",
        "price": 10.75
    }, {
        "symbol": "MXWL",
        "label": "Maxwell Technologies Inc.",
        "price": 4.48
    }, {
        "symbol": "TOCA",
        "label": "Tocagen Inc.",
        "price": 0.6031
    }, {
        "symbol": "CYBE",
        "label": "CyberOptics Corporation",
        "price": 24.0
    }, {
        "symbol": "EFC",
        "label": "Ellington Financial LLC representing Limitied Liability Company Interests no par" +
                " valu",
        "price": 18.74
    }, {
        "symbol": "ACP",
        "label": "Aberdeen Income Credit Strategies Fund",
        "price": 11.69
    }, {
        "symbol": "AMTX",
        "label": "Aemetis Inc",
        "price": 0.8751
    }, {
        "symbol": "ICL",
        "label": "Israel Chemicals Limited",
        "price": 4.49
    }, {
        "symbol": "MIE",
        "label": "Cohen & Steers MLP Income and Energy Opportunity Fund Inc.",
        "price": 8.16
    }, {
        "symbol": "MSF",
        "label": "Morgan Stanley Emerging Markets Fund Inc.",
        "price": 16.33
    }, {
        "symbol": "NHF",
        "label": "NexPoint Strategic Opportunities Fund",
        "price": 17.32
    }, {
        "symbol": "PHD",
        "label": "Pioneer Floating Rate Trust Shares of Beneficial Interest",
        "price": 11.02
    }, {
        "symbol": "RWLK",
        "label": "ReWalk Robotics Ltd",
        "price": 2.13
    }, {
        "symbol": "INB",
        "label": "Cohen & Steers Global Income Builder Inc. of Beneficial Interest",
        "price": 9.15
    }, {
        "symbol": "NBEV",
        "label": "New Age Beverages Corporation",
        "price": 1.97
    }, {
        "symbol": "TDTT",
        "label": "FlexShares iBoxx 3 Year Target Duration TIPS Index Fund",
        "price": 24.49
    }, {
        "symbol": "ECOL",
        "label": "US Ecology Inc.",
        "price": 55.44
    }, {
        "symbol": "SMHI",
        "label": "SEACOR Marine Holdings Inc.",
        "price": 12.08
    }, {
        "symbol": "SHEN",
        "label": "Shenandoah Telecommunications Co",
        "price": 44.06
    }, {
        "symbol": "AVH",
        "label": "Avianca Holdings S.A. American Depositary Shares (Each representing 8 preferred " +
                "Shares)",
        "price": 4.61
    }, {
        "symbol": "TOO",
        "label": "Teekay Offshore Partners L.P. representing Limited Partner Interests",
        "price": 1.55
    }, {
        "symbol": "ITI",
        "label": "Iteris Inc.",
        "price": 5.3
    }, {
        "symbol": "ROX",
        "label": "Castle Brands Inc.",
        "price": 0.49
    }, {
        "symbol": "GV",
        "label": "Goldfield Corporation (The)",
        "price": 3.54
    }, {
        "symbol": "HNI",
        "label": "HNI Corporation",
        "price": 38.41
    }, {
        "symbol": "LPTX",
        "label": "Leap Therapeutics Inc.",
        "price": 1.78
    }, {
        "symbol": "NEU",
        "label": "NewMarket Corp",
        "price": 471.22
    }, {
        "symbol": "TIER",
        "label": "TIER REIT Inc.",
        "price": 27.05
    }, {
        "symbol": "FSS",
        "label": "Federal Signal Corporation",
        "price": 34.83
    }, {
        "symbol": "IWP",
        "label": "iShares Russell Midcap Growth",
        "price": 158.91
    }, {
        "symbol": "HCI",
        "label": "HCI Group Inc.",
        "price": 45.96
    }, {
        "symbol": "IFMK",
        "label": "iFresh Inc.",
        "price": 0.5749
    }, {
        "symbol": "NXRT",
        "label": "NexPoint Residential Trust Inc.",
        "price": 47.22
    }, {
        "symbol": "QQQX",
        "label": "Nuveen NASDAQ 100 Dynamic Overwrite Fund",
        "price": 24.54
    }, {
        "symbol": "FRPH",
        "label": "FRP Holdings Inc.",
        "price": 50.71
    }, {
        "symbol": "GUSH",
        "label": "Direxion Daily S&P Oil & Gas Exp. & Prod. Bull 3X Shares",
        "price": 5.87
    }, {
        "symbol": "HOS",
        "label": "Hornbeck Offshore Services",
        "price": 0.2998
    }, {
        "symbol": "ISD",
        "label": "Prudential Short Duration High Yield Fund Inc.",
        "price": 15.57
    }, {
        "symbol": "IWY",
        "label": "iShares Russell Top 200 Growth",
        "price": 101.43
    }, {
        "symbol": "JASN",
        "label": "Jason Industries Inc.",
        "price": 0.2401
    }, {
        "symbol": "MRIN",
        "label": "Marin Software Incorporated",
        "price": 1.66
    }, {
        "symbol": "NLST",
        "label": "Netlist Inc.",
        "price": 0.54
    }, {
        "symbol": "NUM",
        "label": "Nuveen Michigan Quality Municipal Income Fund",
        "price": 14.23
    }, {
        "symbol": "NVG",
        "label": "Nuveen AMT-Free Municipal Credit Income Fund",
        "price": 16.77
    }, {
        "symbol": "PHT",
        "label": "Pioneer High Income Trust of Beneficial Interest",
        "price": 9.64
    }, {
        "symbol": "PIM",
        "label": "Putnam Master Intermediate Income Trust",
        "price": 4.76
    }, {
        "symbol": "PRTS",
        "label": "U.S. Auto Parts Network Inc.",
        "price": 2.8
    }, {
        "symbol": "RLH",
        "label": "Red Lions Hotels Corporation",
        "price": 3.26
    }, {
        "symbol": "TYG",
        "label": "Tortoise Energy Infrastructure Corporation",
        "price": 19.36
    }, {
        "symbol": "VBTX",
        "label": "Veritex Holdings Inc.",
        "price": 28.45
    }, {
        "symbol": "WSCWW",
        "label": "WillScot Corporation Warrant",
        "price": 2.4
    }, {
        "symbol": "UWT",
        "label": "VelocityShares 3x Long Crude Oil ETNs linked to the S&P GSCI Crude Oil Index ER",
        "price": 10.87
    }, {
        "symbol": "FRO",
        "label": "Frontline Ltd.",
        "price": 12.38
    }, {
        "symbol": "HY",
        "label": "Hyster-Yale Materials Handling Inc. Class A",
        "price": 61.5
    }, {
        "symbol": "BCPC",
        "label": "Balchem Corporation",
        "price": 108.49
    }, {
        "symbol": "CLH",
        "label": "Clean Harbors Inc.",
        "price": 84.1
    }, {
        "symbol": "USNA",
        "label": "USANA Health Sciences Inc.",
        "price": 81.32
    }, {
        "symbol": "OTTR",
        "label": "Otter Tail Corporation",
        "price": 53.64
    }, {
        "symbol": "WGP",
        "label": "Western Gas Equity Partners LP Representing Limited Partner Interests",
        "price": 32.57
    }, {
        "symbol": "CTO",
        "label": "Consolidated-Tomoka Land Co.",
        "price": 65.62
    }, {
        "symbol": "VBIV",
        "label": "VBI Vaccines Inc.",
        "price": 1.63
    }, {
        "symbol": "BIZD",
        "label": "VanEck Vectors BDC Income",
        "price": 16.24
    }, {
        "symbol": "EPHE",
        "label": "iShares MSCI Philippines",
        "price": 35.25
    }, {
        "symbol": "TBPH",
        "label": "Theravance Biopharma Inc.",
        "price": 25.22
    }, {
        "symbol": "WPP",
        "label": "WPP plc American Depositary Shares",
        "price": 66.4
    }, {
        "symbol": "ANCX",
        "label": "Access National Corporation",
        "price": 23.63
    }, {
        "symbol": "PAHC",
        "label": "Phibro Animal Health Corporation",
        "price": 25.795
    }, {
        "symbol": "QUIK",
        "label": "QuickLogic Corporation",
        "price": 7.59
    }, {
        "symbol": "FNDX",
        "label": "Schwab Fundamental U.S. Large Company Index",
        "price": 43.48
    }, {
        "symbol": "AOI",
        "label": "Alliance One International Inc.",
        "price": 23.9
    }, {
        "symbol": "ALDX",
        "label": "Aldeyra Therapeutics Inc.",
        "price": 6.26
    }, {
        "symbol": "AMU",
        "label": "ETRACS Alerian MLP Index ETN",
        "price": 15.45
    }, {
        "symbol": "BBRG",
        "label": "Bravo Brio Restaurant Group Inc.",
        "price": 4.05
    }, {
        "symbol": "CKH",
        "label": "SEACOR Holdings Inc.",
        "price": 42.12
    }, {
        "symbol": "IFN",
        "label": "India Fund Inc. (The)",
        "price": 20.86
    }, {
        "symbol": "OBE",
        "label": "Obsidian Energy Ltd.",
        "price": 0.9301
    }, {
        "symbol": "ONTX",
        "label": "Onconova Therapeutics Inc.",
        "price": 0.41
    }, {
        "symbol": "RST",
        "label": "Rosetta Stone Inc.",
        "price": 18.28
    }, {
        "symbol": "RYE",
        "label": "Guggenheim S&P 500 Equal Weight Energy",
        "price": 47.51
    }, {
        "symbol": "TLI",
        "label": "Western Asset Corporate Loan Fund Inc",
        "price": 9.95
    }, {
        "symbol": "TREC",
        "label": "Trecora Resources",
        "price": 7.25
    }, {
        "symbol": "WWR",
        "label": "Westwater Resources Inc.",
        "price": 2.22
    }, {
        "symbol": "MATX",
        "label": "Matson Inc.",
        "price": 40.07
    }, {
        "symbol": "ANGL",
        "label": "VanEck Vectors Fallen Angel High Yield Bond",
        "price": 30.16
    }, {
        "symbol": "HCKT",
        "label": "The Hackett Group Inc.",
        "price": 17.065
    }, {
        "symbol": "BOMN",
        "label": "Boston Omaha Corporation",
        "price": 20.71
    }, {
        "symbol": "WSBF",
        "label": "Waterstone Financial Inc.",
        "price": 18.45
    }, {
        "symbol": "PKE",
        "label": "Park Electrochemical Corporation",
        "price": 16.6
    }, {
        "symbol": "JRS",
        "label": "Nuveen Real Estate Income Fund of Beneficial Interest",
        "price": 10.85
    }, {
        "symbol": "FFG",
        "label": "FBL Financial Group Inc.",
        "price": 58.81
    }, {
        "symbol": "MAXR",
        "label": "Maxar Technologies Ltd.",
        "price": 20.2
    }, {
        "symbol": "JAGX",
        "label": "Jaguar Health Inc.",
        "price": 0.8831
    }, {
        "symbol": "CSII",
        "label": "Cardiovascular Systems Inc.",
        "price": 53.61
    }, {
        "symbol": "OXM",
        "label": "Oxford Industries Inc.",
        "price": 74.09
    }, {
        "symbol": "BSJI",
        "label": "Guggenheim BulletShares 2018 High Yield Corporate Bond",
        "price": 25.1
    }, {
        "symbol": "FXP",
        "label": "ProShares Ultrashort FTSE China 50",
        "price": 70.82
    }, {
        "symbol": "PFLT",
        "label": "PennantPark Floating Rate Capital Ltd.",
        "price": 12.2
    }, {
        "symbol": "CMT",
        "label": "Core Molding Technologies Inc",
        "price": 3.37
    }, {
        "symbol": "ALGT",
        "label": "Allegiant Travel Company",
        "price": 177.79
    }, {
        "symbol": "MFIN",
        "label": "Medallion Financial Corp.",
        "price": 6.89
    }, {
        "symbol": "PKD",
        "label": "Parker Drilling Company",
        "price": 17.87
    }, {
        "symbol": "QYLD",
        "label": "Horizons NASDAQ-100 Covered Call ETF",
        "price": 23.97
    }, {
        "symbol": "LABU",
        "label": "Direxion Daily S&P Biotech Bull 3X Shares",
        "price": 42.78
    }, {
        "symbol": "FNHC",
        "label": "Federated National Holding Company",
        "price": 16.205
    }, {
        "symbol": "ASV",
        "label": "ASV Holdings Inc.",
        "price": 2.35
    }, {
        "symbol": "AVHI",
        "label": "A V Homes Inc.",
        "price": 19.83
    }, {
        "symbol": "BPK",
        "label": "Blackrock Municipal 2018 Term Trust",
        "price": 14.62
    }, {
        "symbol": "FSV",
        "label": "FirstService Corporation",
        "price": 98.34
    }, {
        "symbol": "JDD",
        "label": "Nuveen Diversified Dividend and Income Fund Shares of Beneficial Interest",
        "price": 11.14
    }, {
        "symbol": "NAUH",
        "label": "National American University Holdings Inc.",
        "price": 0.23
    }, {
        "symbol": "PFXF",
        "label": "VanEck Vectors Preferred Securities ex Financials",
        "price": 19.49
    }, {
        "symbol": "RDI",
        "label": "Reading International Inc",
        "price": 11.07
    }, {
        "symbol": "RTIX",
        "label": "RTI Surgical Inc.",
        "price": 4.41
    }, {
        "symbol": "SCON",
        "label": "Superconductor Technologies Inc.",
        "price": 0.2144
    }, {
        "symbol": "SMLP",
        "label": "Summit Midstream Partners LP Representing Limited Partner Interests",
        "price": 3.52
    }, {
        "symbol": "OMED",
        "label": "OncoMed Pharmaceuticals Inc.",
        "price": 0.89
    }, {
        "symbol": "HTBK",
        "label": "Heritage Commerce Corp",
        "price": 12.59
    }, {
        "symbol": "CLXT",
        "label": "Calyxt Inc.",
        "price": 7.3
    }, {
        "symbol": "KNSL",
        "label": "Kinsale Capital Group Inc.",
        "price": 109.23
    }, {
        "symbol": "DDM",
        "label": "ProShares Ultra Dow30",
        "price": 44.79
    }, {
        "symbol": "METC",
        "label": "Ramaco Resources Inc.",
        "price": 3.64
    }, {
        "symbol": "STIP",
        "label": "iShares 0-5 Year TIPS Bond",
        "price": 100.22
    }, {
        "symbol": "NEOG",
        "label": "Neogen Corporation",
        "price": 71.28
    }, {
        "symbol": "GTY",
        "label": "Getty Realty Corporation",
        "price": 32.52
    }, {
        "symbol": "WTS",
        "label": "Watts Water Technologies Inc. Class A",
        "price": 101.46
    }, {
        "symbol": "BFZ",
        "label": "BlackRock California Municipal Income Trust",
        "price": 13.89
    }, {
        "symbol": "HMST",
        "label": "HomeStreet Inc.",
        "price": 33.0
    }, {
        "symbol": "FFIC",
        "label": "Flushing Financial Corporation",
        "price": 20.96
    }, {
        "symbol": "CPL",
        "label": "CPFL Energia S.A. American Depositary Shares",
        "price": 17.81
    }, {
        "symbol": "GNMX",
        "label": "Aevi Genomic Medicine Inc.",
        "price": 0.1825
    }, {
        "symbol": "ECOM",
        "label": "ChannelAdvisor Corporation",
        "price": 9.18
    }, {
        "symbol": "CEO",
        "label": "CNOOC Limited",
        "price": 175.6
    }, {
        "symbol": "GALT",
        "label": "Galectin Therapeutics Inc.",
        "price": 3.0
    }, {
        "symbol": "BBW",
        "label": "Build-A-Bear Workshop Inc.",
        "price": 4.89
    }, {
        "symbol": "IMKTA",
        "label": "Ingles Markets Incorporated Class A Common Stock",
        "price": 43.25
    }, {
        "symbol": "SPSM",
        "label": "SPDR Portfolio Small Cap",
        "price": 33.28
    }, {
        "symbol": "NRK",
        "label": "Nuveen New York AMT-Free Quality Municipal Income Fund",
        "price": 13.64
    }, {
        "symbol": "RAIL",
        "label": "Freightcar America Inc.",
        "price": 1.8
    }, {
        "symbol": "KSA",
        "label": "iShares Trust MSCI Saudi Arabia",
        "price": 32.22
    }, {
        "symbol": "INPX",
        "label": "Inpixon",
        "price": 4.11
    }, {
        "symbol": "INDB",
        "label": "Independent Bank Corp.",
        "price": 80.05
    }, {
        "symbol": "IWO",
        "label": "iShares Russell 2000 Growth",
        "price": 221.76
    }, {
        "symbol": "GSLC",
        "label": "Goldman Sachs ActiveBeta U.S. Large Cap Equity",
        "price": 56.72
    }, {
        "symbol": "CWAY",
        "label": "Coastway Bancorp Inc.",
        "price": 28.23
    }, {
        "symbol": "AUPH",
        "label": "Aurinia Pharmaceuticals Inc",
        "price": 20.08
    }, {
        "symbol": "AVK",
        "label": "Advent Claymore Convertible Securities and Income Fund",
        "price": 15.73
    }, {
        "symbol": "BSJK",
        "label": "Guggenheim BulletShares 2020 High Yield Corporate Bond",
        "price": 24.02
    }, {
        "symbol": "HIO",
        "label": "Western Asset High Income Opportunity Fund Inc.",
        "price": 5.13
    }, {
        "symbol": "LN",
        "label": "LINE Corporation American Depositary Shares (each representing one share of)",
        "price": 48.68
    }, {
        "symbol": "NK",
        "label": "NantKwest Inc.",
        "price": 7.35
    }, {
        "symbol": "NMZ",
        "label": "Nuveen Municipal High Income Opportunity Fund $0.01 par value per share",
        "price": 14.44
    }, {
        "symbol": "OILD",
        "label": "ProShares UltraPro 3x Short Crude Oil",
        "price": 29.42
    }, {
        "symbol": "PTY",
        "label": "Pimco Corporate & Income Opportunity Fund",
        "price": 19.39
    }, {
        "symbol": "SGY",
        "label": "Stone Energy Corporation",
        "price": 35.53
    }, {
        "symbol": "CECO",
        "label": "Career Education Corporation",
        "price": 18.39
    }, {
        "symbol": "TR",
        "label": "Tootsie Roll Industries Inc.",
        "price": 34.71
    }, {
        "symbol": "CABO",
        "label": "Cable One Inc.",
        "price": 1630.63
    }, {
        "symbol": "LCUT",
        "label": "Lifetime Brands Inc.",
        "price": 7.14
    }, {
        "symbol": "IDOG",
        "label": "ALPS International Sector Dividend Dogs",
        "price": 25.23
    }, {
        "symbol": "BWX",
        "label": "SPDR Bloomberg Barclays Intl Treasury Bd",
        "price": 28.26
    }, {
        "symbol": "ERIE",
        "label": "Erie Indemnity Company",
        "price": 169.49
    }, {
        "symbol": "AAC",
        "label": "AAC Holdings Inc.",
        "price": 1.0
    }, {
        "symbol": "BCX",
        "label": "BlackRock Resources of Beneficial Interest",
        "price": 8.05
    }, {
        "symbol": "GIM",
        "label": "Templeton Global Income Fund Inc.",
        "price": 6.13
    }, {
        "symbol": "GPM",
        "label": "Guggenheim Enhanced Equity Income Fund",
        "price": 8.22
    }, {
        "symbol": "HSTM",
        "label": "HealthStream Inc.",
        "price": 27.62
    }, {
        "symbol": "EEMV",
        "label": "iShares Edge MSCI Min Vol Emerging Markets",
        "price": 57.51
    }, {
        "symbol": "AGM",
        "label": "Federal Agricultural Mortgage Corporation",
        "price": 82.7
    }, {
        "symbol": "DFFN",
        "label": "Diffusion Pharmaceuticals Inc.",
        "price": 0.505
    }, {
        "symbol": "VIVE",
        "label": "Viveve Medical Inc.",
        "price": 0.9857
    }, {
        "symbol": "AGII",
        "label": "Argo Group International Holdings Ltd.",
        "price": 58.95
    }, {
        "symbol": "KEG",
        "label": "Key Energy Services Inc.",
        "price": 0.266
    }, {
        "symbol": "UMH",
        "label": "UMH Properties Inc.",
        "price": 16.37
    }, {
        "symbol": "FIX",
        "label": "Comfort Systems USA Inc.",
        "price": 49.46
    }, {
        "symbol": "FDUS",
        "label": "Fidus Investment Corporation",
        "price": 14.97
    }, {
        "symbol": "ANAT",
        "label": "American National Insurance Company",
        "price": 114.91
    }, {
        "symbol": "RENX",
        "label": "RELX N.V. American Depositary Shares (Each representing One)",
        "price": 21.19
    }, {
        "symbol": "CIVB",
        "label": "Civista Bancshares Inc.",
        "price": 23.32
    }, {
        "symbol": "RKDA",
        "label": "Arcadia Biosciences Inc.",
        "price": 5.26
    }, {
        "symbol": "XSLV",
        "label": "PowerShares S&P SmallCap Low Volatility Portfolio",
        "price": 51.45
    }, {
        "symbol": "MORL",
        "label": "ETRACS Monthly Pay 2XLeveraged Mortgage REIT ETN",
        "price": 13.68
    }, {
        "symbol": "OFIX",
        "label": "Orthofix International N.V.",
        "price": 46.94
    }, {
        "symbol": "KVHI",
        "label": "KVH Industries Inc.",
        "price": 10.91
    }, {
        "symbol": "TTGT",
        "label": "TechTarget Inc.",
        "price": 25.71
    }, {
        "symbol": "HQH",
        "label": "Tekla Healthcare Investors",
        "price": 21.39
    }, {
        "symbol": "IWX",
        "label": "iShares Russell Top 200 Value",
        "price": 52.59
    }, {
        "symbol": "ARCB",
        "label": "ArcBest Corporation",
        "price": 28.09
    }, {
        "symbol": "WABC",
        "label": "Westamerica Bancorporation",
        "price": 67.88
    }, {
        "symbol": "ACRX",
        "label": "AcelRx Pharmaceuticals Inc.",
        "price": 1.9
    }, {
        "symbol": "CAF",
        "label": "Morgan Stanley China A Share Fund Inc.",
        "price": 22.42
    }, {
        "symbol": "CAI",
        "label": "CAI International Inc.",
        "price": 28.92
    }, {
        "symbol": "CNBKA",
        "label": "Century Bancorp Inc. Class A Common Stock",
        "price": 90.91
    }, {
        "symbol": "DXJS",
        "label": "WisdomTree Japan Hedged SmallCap Equity Fund",
        "price": 42.7035
    }, {
        "symbol": "GLMD",
        "label": "Galmed Pharmaceuticals Ltd.",
        "price": 6.4
    }, {
        "symbol": "GLOP",
        "label": "GasLog Partners LP representing limited partnership interests",
        "price": 15.39
    }, {
        "symbol": "ITM",
        "label": "VanEck Vectors AMT-Free Intermediate Municipal Index",
        "price": 49.58
    }, {
        "symbol": "LODE",
        "label": "Comstock Mining Inc.",
        "price": 0.7245
    }, {
        "symbol": "MATR",
        "label": "Mattersight Corporation",
        "price": 2.68
    }, {
        "symbol": "MDWD",
        "label": "MediWound Ltd.",
        "price": 3.03
    }, {
        "symbol": "MG",
        "label": "Mistras Group Inc",
        "price": 13.71
    }, {
        "symbol": "NOVN",
        "label": "Novan Inc.",
        "price": 0.64
    }, {
        "symbol": "OIA",
        "label": "Invesco Municipal Income Opportunities Trust",
        "price": 7.9898
    }, {
        "symbol": "OILU",
        "label": "ProShares UltraPro 3x Crude Oil",
        "price": 16.25
    }, {
        "symbol": "OPESU",
        "label": "Opes Acquisition Corp. Unit",
        "price": 10.62
    }, {
        "symbol": "PCK",
        "label": "Pimco California Municipal Income Fund II of Beneficial Interest",
        "price": 9.83
    }, {
        "symbol": "TANH",
        "label": "Tantech Holdings Ltd.",
        "price": 1.6
    }, {
        "symbol": "TEI",
        "label": "Templeton Emerging Markets Income Fund Inc.",
        "price": 9.12
    }, {
        "symbol": "TTT",
        "label": "ProShares UltraPro Short 20 Year Treasury",
        "price": 19.24
    }, {
        "symbol": "UNT",
        "label": "Unit Corporation",
        "price": 0.4837
    }, {
        "symbol": "VHC",
        "label": "VirnetX Holding Corp",
        "price": 3.98
    }, {
        "symbol": "ZFGN",
        "label": "Zafgen Inc.",
        "price": 1.22
    }, {
        "symbol": "ZSAN",
        "label": "Zosano Pharma Corporation",
        "price": 1.47
    }, {
        "symbol": "BKF",
        "label": "iShares MSCI BRIC Index Fund",
        "price": 40.29
    }, {
        "symbol": "VHI",
        "label": "Valhi Inc.",
        "price": 1.75
    }, {
        "symbol": "PUK",
        "label": "Prudential Public Limited Company",
        "price": 37.0
    }, {
        "symbol": "CCXI",
        "label": "ChemoCentryx Inc.",
        "price": 43.66
    }, {
        "symbol": "UBFO",
        "label": "United Security Bancshares",
        "price": 10.29
    }, {
        "symbol": "CNXN",
        "label": "PC Connection Inc.",
        "price": 53.15
    }, {
        "symbol": "JAX",
        "label": "J. Alexander's Holdings Inc.",
        "price": 9.67
    }, {
        "symbol": "SONA",
        "label": "Southern National Bancorp of Virginia Inc.",
        "price": 15.81
    }, {
        "symbol": "ARA",
        "label": "American Renal Associates Holdings Inc",
        "price": 9.66
    }, {
        "symbol": "CPLA",
        "label": "Capella Education Company",
        "price": 103.45
    }, {
        "symbol": "DLB",
        "label": "Dolby Laboratories",
        "price": 69.25
    }, {
        "symbol": "HAYN",
        "label": "Haynes International Inc.",
        "price": 29.83
    }, {
        "symbol": "TCX",
        "label": "Tucows Inc.",
        "price": 64.88
    }, {
        "symbol": "TPB",
        "label": "Turning Point Brands Inc.",
        "price": 27.48
    }, {
        "symbol": "KIN",
        "label": "Kindred Biosciences Inc.",
        "price": 9.84
    }, {
        "symbol": "LABL",
        "label": "Multi-Color Corporation",
        "price": 49.88
    }, {
        "symbol": "CII",
        "label": "Blackrock Capital and Income Fund Inc.",
        "price": 17.53
    }, {
        "symbol": "DWM",
        "label": "WisdomTree International Equity Fund",
        "price": 53.83
    }, {
        "symbol": "SPMD",
        "label": "SPDR Portfolio Mid Cap",
        "price": 36.8
    }, {
        "symbol": "SPLG",
        "label": "SPDR Series Trust Portfolio Large Cap",
        "price": 39.03
    }, {
        "symbol": "MACK",
        "label": "Merrimack Pharmaceuticals Inc.",
        "price": 3.25
    }, {
        "symbol": "ACST",
        "label": "Acasti Pharma Inc.",
        "price": 0.777
    }, {
        "symbol": "THQ",
        "label": "Tekla Healthcare Opportunies Fund Shares of Beneficial Interest",
        "price": 19.3
    }, {
        "symbol": "EZM",
        "label": "WisdomTree U.S. MidCap Earnings Fund",
        "price": 42.63
    }, {
        "symbol": "MCR",
        "label": "MFS Charter Income Trust",
        "price": 8.44
    }, {
        "symbol": "IYW",
        "label": "iShares U.S. Technology",
        "price": 184.99
    }, {
        "symbol": "BTO",
        "label": "John Hancock Financial Opportunities Fund",
        "price": 35.22
    }, {
        "symbol": "FFWM",
        "label": "First Foundation Inc.",
        "price": 16.91
    }, {
        "symbol": "BLOK",
        "label": "Amplify Transformational Data Sharing",
        "price": 17.47
    }, {
        "symbol": "BIP",
        "label": "Brookfield Infrastructure Partners LP Limited Partnership Units",
        "price": 53.37
    }, {
        "symbol": "CAC",
        "label": "Camden National Corporation",
        "price": 46.59
    }, {
        "symbol": "NATH",
        "label": "Nathan's Famous Inc.",
        "price": 74.63
    }, {
        "symbol": "ANDE",
        "label": "The Andersons Inc.",
        "price": 25.09
    }, {
        "symbol": "CTWS",
        "label": "Connecticut Water Service Inc.",
        "price": 68.68
    }, {
        "symbol": "WIW",
        "label": "Western Asset/Claymore Inflation-Linked Opportunities & Income Fund of Beneficia" +
                "l Interest",
        "price": 11.62
    }, {
        "symbol": "YUMA",
        "label": "Yuma Energy Inc.",
        "price": 3.37
    }, {
        "symbol": "CARO",
        "label": "Carolina Financial Corporation",
        "price": 42.62
    }, {
        "symbol": "MCS",
        "label": "Marcus Corporation (The)",
        "price": 31.53
    }, {
        "symbol": "GOOD",
        "label": "Gladstone Commercial Corporation",
        "price": 22.37
    }, {
        "symbol": "CIA",
        "label": "Citizens Inc. Class A ($1.00 Par)",
        "price": 6.76
    }, {
        "symbol": "CLW",
        "label": "Clearwater Paper Corporation",
        "price": 22.67
    }, {
        "symbol": "AMRK",
        "label": "A-Mark Precious Metals Inc.",
        "price": 8.55
    }, {
        "symbol": "ATHX",
        "label": "Athersys Inc.",
        "price": 1.27
    }, {
        "symbol": "CCD",
        "label": "Calamos Dynamic Convertible & Income Fund",
        "price": 21.79
    }, {
        "symbol": "CEM",
        "label": "ClearBridge Energy MLP Fund Inc.",
        "price": 11.69
    }, {
        "symbol": "COOL",
        "label": "PolarityTE Inc.",
        "price": 17.25
    }, {
        "symbol": "DGII",
        "label": "Digi International Inc.",
        "price": 17.49
    }, {
        "symbol": "ESES",
        "label": "Eco-Stim Energy Solutions Inc.",
        "price": 0.06
    }, {
        "symbol": "GMRE",
        "label": "Global Medical REIT Inc.",
        "price": 14.8
    }, {
        "symbol": "IHD",
        "label": "Voya Emerging Markets High Income Dividend Equity Fund",
        "price": 8.27
    }, {
        "symbol": "IXN",
        "label": "iShares Global Tech",
        "price": 169.54
    }, {
        "symbol": "KRYS",
        "label": "Krystal Biotech Inc.",
        "price": 51.83
    }, {
        "symbol": "NTIP",
        "label": "Network-1 Technologies Inc.",
        "price": 2.32
    }, {
        "symbol": "ORN",
        "label": "Orion Group Holdings Inc. Common",
        "price": 5.67
    }, {
        "symbol": "PEY",
        "label": "PowerShares High Yield Equity Dividend Achievers Portfolio",
        "price": 19.29
    }, {
        "symbol": "PLXP",
        "label": "PLx Pharma Inc.",
        "price": 4.7388
    }, {
        "symbol": "SLGL",
        "label": "Sol-Gel Technologies Ltd.",
        "price": 13.99
    }, {
        "symbol": "SPEX",
        "label": "Spherix Incorporated",
        "price": 1.1
    }, {
        "symbol": "VLGEA",
        "label": "Village Super Market Inc. Class A Common Stock",
        "price": 23.0
    }, {
        "symbol": "YTRA",
        "label": "Yatra Online Inc.",
        "price": 3.45
    }, {
        "symbol": "JO",
        "label": "iPath Bloomberg Coffee Subindex Total Return SM Index ETN",
        "price": 34.55
    }, {
        "symbol": "MKL",
        "label": "Markel Corporation",
        "price": 1192.76
    }, {
        "symbol": "FNDB",
        "label": "Schwab Fundamental U.S. Broad Market Index",
        "price": 42.955
    }, {
        "symbol": "ALOG",
        "label": "Analogic Corporation",
        "price": 83.98
    }, {
        "symbol": "EOI",
        "label": "Eaton Vance Enhance Equity Income Fundd Equity Income Fund Shares of Beneficial " +
                "Interest",
        "price": 16.1
    }, {
        "symbol": "KYE",
        "label": "Kayne Anderson Total Energy Return Fund Inc.",
        "price": 9.89
    }, {
        "symbol": "WRLD",
        "label": "World Acceptance Corporation",
        "price": 91.57
    }, {
        "symbol": "NAVG",
        "label": "The Navigators Group Inc.",
        "price": 69.97
    }, {
        "symbol": "QAI",
        "label": "IQ Hedge MultiIQ Hedge Multi-Strategy Tracker",
        "price": 29.96
    }, {
        "symbol": "FICO",
        "label": "Fair Isaac Corproation",
        "price": 404.97
    }, {
        "symbol": "GRC",
        "label": "Gorman-Rupp Company (The)",
        "price": 38.5
    }, {
        "symbol": "OLP",
        "label": "One Liberty Properties Inc.",
        "price": 28.1
    }, {
        "symbol": "PZN",
        "label": "Pzena Investment Management Inc Class A",
        "price": 8.52
    }, {
        "symbol": "SYBT",
        "label": "Stock Yards Bancorp Inc.",
        "price": 40.7
    }, {
        "symbol": "FTF",
        "label": "Franklin Limited Duration Income Trust of Beneficial Interest",
        "price": 9.7
    }, {
        "symbol": "HWKN",
        "label": "Hawkins Inc.",
        "price": 43.46
    }, {
        "symbol": "PICB",
        "label": "PowerShares International Corporate Bond Portfolio",
        "price": 25.77
    }, {
        "symbol": "DHY",
        "label": "Credit Suisse High Yield Bond Fund",
        "price": 2.49
    }, {
        "symbol": "SJW",
        "label": "SJW Group (DE)",
        "price": 72.86
    }, {
        "symbol": "GFF",
        "label": "Griffon Corporation",
        "price": 21.61
    }, {
        "symbol": "LRGF",
        "label": "iShares Edge MSCI Multifactor USA",
        "price": 35.14
    }, {
        "symbol": "DRIP",
        "label": "Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 3X Shares",
        "price": 15.21
    }, {
        "symbol": "CHY",
        "label": "Calamos Convertible and High Income Fund",
        "price": 11.78
    }, {
        "symbol": "LEO",
        "label": "Dreyfus Strategic Municipals Inc.",
        "price": 8.63
    }, {
        "symbol": "TORC",
        "label": "resTORbio Inc.",
        "price": 1.46
    }, {
        "symbol": "VISI",
        "label": "Volt Information Sciences Inc.",
        "price": 4.35
    }, {
        "symbol": "CEVA",
        "label": "CEVA Inc.",
        "price": 29.28
    }, {
        "symbol": "FLY",
        "label": "Fly Leasing Limited",
        "price": 19.53
    }, {
        "symbol": "AGX",
        "label": "Argan Inc.",
        "price": 43.37
    }, {
        "symbol": "XOMA",
        "label": "XOMA Corporation",
        "price": 24.6
    }, {
        "symbol": "SMP",
        "label": "Standard Motor Products Inc.",
        "price": 52.03
    }, {
        "symbol": "BSE",
        "label": "Blackrock New York Municipal Income Quality Trust of Beneficial Interest",
        "price": 13.8
    }, {
        "symbol": "NVIV",
        "label": "InVivo Therapeutics Holdings Corp.",
        "price": 0.27
    }, {
        "symbol": "AIA",
        "label": "iShares Asia 50 ETF",
        "price": 69.1
    }, {
        "symbol": "CGG",
        "label": "CGG",
        "price": 2.76
    }, {
        "symbol": "DCAR",
        "label": "DropCar Inc.",
        "price": 0.9592
    }, {
        "symbol": "EFZ",
        "label": "ProShares Short MSCI EAFE",
        "price": 27.37
    }, {
        "symbol": "GNK",
        "label": "Genco Shipping & Trading Limited New (Marshall Islands)",
        "price": 9.74
    }, {
        "symbol": "HCCI",
        "label": "Heritage-Crystal Clean Inc.",
        "price": 29.69
    }, {
        "symbol": "HQL",
        "label": "TeklaLife Sciences Investors",
        "price": 17.66
    }, {
        "symbol": "IGI",
        "label": "Western Asset Investment Grade Defined Opportunity Trust Inc.",
        "price": 22.14
    }, {
        "symbol": "ILPT",
        "label": "Industrial Logistics Properties Trust",
        "price": 22.63
    }, {
        "symbol": "JPGE",
        "label": "JPMorgan Diversified Return Global Equity",
        "price": 58.85
    }, {
        "symbol": "KIDS",
        "label": "OrthoPediatrics Corp.",
        "price": 47.8
    }, {
        "symbol": "PFN",
        "label": "PIMCO Income Strategy Fund II",
        "price": 10.74
    }, {
        "symbol": "PULM",
        "label": "Pulmatrix Inc.",
        "price": 1.63
    }, {
        "symbol": "SCYX",
        "label": "SCYNEXIS Inc.",
        "price": 1.07
    }, {
        "symbol": "SECO",
        "label": "Secoo Holding Limited",
        "price": 6.96
    }, {
        "symbol": "XPL",
        "label": "Solitario Zinc Corp.",
        "price": 0.285899
    }, {
        "symbol": "FFKT",
        "label": "Farmers Capital Bank Corporation",
        "price": 56.98
    }, {
        "symbol": "CHW",
        "label": "Calamos Global Dynamic Income Fund",
        "price": 8.71
    }, {
        "symbol": "EIG",
        "label": "Employers Holdings Inc",
        "price": 43.83
    }, {
        "symbol": "BST",
        "label": "BlackRock Science and Technology Trust of Beneficial Interest",
        "price": 35.36
    }, {
        "symbol": "CHCO",
        "label": "City Holding Company",
        "price": 81.94
    }, {
        "symbol": "STK",
        "label": "Columbia Seligman Premium Technology Growth Fund Inc",
        "price": 24.82
    }, {
        "symbol": "NXTD",
        "label": "NXT-ID Inc.",
        "price": 0.4212
    }, {
        "symbol": "IBCP",
        "label": "Independent Bank Corporation",
        "price": 22.55
    }, {
        "symbol": "DNP",
        "label": "DNP Select Income Fund Inc.",
        "price": 12.84
    }, {
        "symbol": "INSW",
        "label": "International Seaways Inc.",
        "price": 26.55
    }, {
        "symbol": "NCV",
        "label": "AllianzGI Convertible & Income Fund",
        "price": 5.99
    }, {
        "symbol": "DCIX",
        "label": "Diana Containerships Inc.",
        "price": 0.7871
    }, {
        "symbol": "FLKS",
        "label": "Flex Pharma Inc.",
        "price": 0.46
    }, {
        "symbol": "PCN",
        "label": "Pimco Corporate & Income Strategy Fund",
        "price": 19.7
    }, {
        "symbol": "IBDL",
        "label": "iShares iBonds Dec 2020 Term Corporate",
        "price": 25.25
    }, {
        "symbol": "CHT",
        "label": "Chunghwa Telecom Co. Ltd.",
        "price": 36.52
    }, {
        "symbol": "SLD",
        "label": "Sutherland Asset Management Corporation",
        "price": 16.63
    }, {
        "symbol": "EAD",
        "label": "Wells Fargo Income Opportunities Fund",
        "price": 8.52
    }, {
        "symbol": "MBWM",
        "label": "Mercantile Bank Corporation",
        "price": 35.44
    }, {
        "symbol": "ETW",
        "label": "Eaton Vance Corporation Tax-Managed Global Buy-Write Opportunites Fund of Benefi" +
                "cial Interest",
        "price": 10.48
    }, {
        "symbol": "RCKY",
        "label": "Rocky Brands Inc.",
        "price": 29.02
    }, {
        "symbol": "KAMN",
        "label": "Kaman Corporation",
        "price": 66.65
    }, {
        "symbol": "ZIV",
        "label": "VelocityShares Daily Inverse VIX Medium Term ETN",
        "price": 77.64
    }, {
        "symbol": "USM",
        "label": "United States Cellular Corporation",
        "price": 36.31
    }, {
        "symbol": "POWI",
        "label": "Power Integrations Inc.",
        "price": 105.38
    }, {
        "symbol": "TDW",
        "label": "Tidewater Inc.",
        "price": 17.39
    }, {
        "symbol": "HIL",
        "label": "Hill International Inc.",
        "price": 3.58
    }, {
        "symbol": "NCOM",
        "label": "National Commerce Corporation",
        "price": 39.22
    }, {
        "symbol": "NAD",
        "label": "Nuveen Quality Municipal Income Fund",
        "price": 14.75
    }, {
        "symbol": "OOMA",
        "label": "Ooma Inc.",
        "price": 13.99
    }, {
        "symbol": "NTRA",
        "label": "Natera Inc.",
        "price": 37.64
    }, {
        "symbol": "PGC",
        "label": "Peapack-Gladstone Financial Corporation",
        "price": 30.13
    }, {
        "symbol": "MSL",
        "label": "MidSouth Bancorp",
        "price": 11.55
    }, {
        "symbol": "EPV",
        "label": "ProShares UltraShort FTSE Europe",
        "price": 33.44
    }, {
        "symbol": "UVE",
        "label": "UNIVERSAL INSURANCE HOLDINGS INC",
        "price": 26.01
    }, {
        "symbol": "ATAX",
        "label": "America First Multifamily Investors L.P.",
        "price": 7.61
    }, {
        "symbol": "BTZ",
        "label": "BlackRock Credit Allocation Income Trust",
        "price": 14.28
    }, {
        "symbol": "CXE",
        "label": "MFS High Income Municipal Trust",
        "price": 5.4
    }, {
        "symbol": "DPG",
        "label": "Duff & Phelps Global Utility Income Fund Inc.",
        "price": 15.71
    }, {
        "symbol": "EDOM",
        "label": "WisdomTree Europe Domestic Economy Fund",
        "price": 22.1
    }, {
        "symbol": "FFC",
        "label": "Flaherty & Crumrine Preferred Securities Income Fund Incorporated",
        "price": 22.23
    }, {
        "symbol": "FPF",
        "label": "First Trust Intermediate Duration Preferred & Income Fund of Beneficial Interest",
        "price": 24.16
    }, {
        "symbol": "FXF",
        "label": "Guggenheim CurrencyShares Swiss Franc Trust",
        "price": 93.67
    }, {
        "symbol": "FXN",
        "label": "First Trust Energy AlphaDEX Fund",
        "price": 10.63
    }, {
        "symbol": "IBLN",
        "label": "Direxion iBillionaire Index",
        "price": 32.82
    }, {
        "symbol": "MBOT",
        "label": "Microbot Medical Inc.",
        "price": 12.47
    }, {
        "symbol": "MIXT",
        "label": "MiX Telematics Limited American Depositary Shares each representing 25",
        "price": 14.78
    }, {
        "symbol": "MOTS",
        "label": "Motus GI Holdings Inc.",
        "price": 2.28
    }, {
        "symbol": "MTL",
        "label": "Mechel PAO American Depositary Shares (Each rep. 1)",
        "price": 3.12
    }, {
        "symbol": "NEXT",
        "label": "NextDecade Corporation",
        "price": 5.67
    }, {
        "symbol": "NFBK",
        "label": "Northfield Bancorp Inc.",
        "price": 16.71
    }, {
        "symbol": "NL",
        "label": "NL Industries Inc.",
        "price": 3.55
    }, {
        "symbol": "NRO",
        "label": "Neuberger Berman Real Estate Securities Income Fund Inc.",
        "price": 5.66
    }, {
        "symbol": "OTIV",
        "label": "On Track Innovations Ltd",
        "price": 0.48
    }, {
        "symbol": "PCI",
        "label": "PIMCO Dynamic Credit and Mortgage Income Fund of Beneficial Interest",
        "price": 25.12
    }, {
        "symbol": "RVRS",
        "label": "Reverse Cap Weighted US Large Cap",
        "price": 16.47
    }, {
        "symbol": "UGL",
        "label": "ProShares Ultra Gold",
        "price": 36.32
    }, {
        "symbol": "UGLD",
        "label": "VelocityShares 3x Long Gold ETN",
        "price": 151.0
    }, {
        "symbol": "USEG",
        "label": "U.S. Energy Corp.",
        "price": 4.97
    }, {
        "symbol": "VTA",
        "label": "Invesco Credit Opportunities Fund of Beneficial Interest",
        "price": 11.56
    }, {
        "symbol": "SHG",
        "label": "Shinhan Financial Group Co Ltd American Depositary Shares",
        "price": 35.63
    }, {
        "symbol": "MQT",
        "label": "Blackrock MuniYield Quality Fund II Inc.",
        "price": 13.22
    }, {
        "symbol": "APTO",
        "label": "Aptose Biosciences Inc.",
        "price": 5.88
    }, {
        "symbol": "ABCD",
        "label": "Cambium Learning Group Inc.",
        "price": 14.48
    }, {
        "symbol": "CORR",
        "label": "CorEnergy Infrastructure Trust Inc.",
        "price": 46.89
    }, {
        "symbol": "FXR",
        "label": "First Trust Industrials AlphaDEX",
        "price": 40.02
    }, {
        "symbol": "REV",
        "label": "Revlon Inc.",
        "price": 24.02
    }, {
        "symbol": "ARGT",
        "label": "Global X MSCI Argentina",
        "price": 28.55
    }, {
        "symbol": "EGY",
        "label": "VAALCO Energy Inc.",
        "price": 2.5
    }, {
        "symbol": "MRSN",
        "label": "Mersana Therapeutics Inc.",
        "price": 8.05
    }, {
        "symbol": "VHT",
        "label": "Vanguard Health Care",
        "price": 168.05
    }, {
        "symbol": "VBK",
        "label": "Vanguard Small-Cap Growth",
        "price": 178.04
    }, {
        "symbol": "CBA",
        "label": "ClearBridge American Energy MLP Fund Inc.",
        "price": 6.74
    }, {
        "symbol": "SYX",
        "label": "Systemax Inc.",
        "price": 25.44
    }, {
        "symbol": "GHM",
        "label": "Graham Corporation",
        "price": 20.98
    }, {
        "symbol": "GWX",
        "label": "SPDR S&P International SmallCap",
        "price": 29.58
    }, {
        "symbol": "GCO",
        "label": "Genesco Inc.",
        "price": 43.14
    }, {
        "symbol": "SNSS",
        "label": "Sunesis Pharmaceuticals Inc.",
        "price": 0.5573
    }, {
        "symbol": "JBSS",
        "label": "John B. Sanfilippo & Son Inc.",
        "price": 90.49
    }, {
        "symbol": "CUB",
        "label": "Cubic Corporation",
        "price": 69.85
    }, {
        "symbol": "RGR",
        "label": "Sturm Ruger & Company Inc.",
        "price": 50.48
    }, {
        "symbol": "QTEC",
        "label": "First Trust NASDAQ-100- Technology Index Fund",
        "price": 105.24
    }, {
        "symbol": "LPTH",
        "label": "LightPath Technologies Inc.",
        "price": 0.67
    }, {
        "symbol": "MITT",
        "label": "AG Mortgage Investment Trust Inc.",
        "price": 15.78
    }, {
        "symbol": "REGL",
        "label": "ProShares S&P MidCap 400 Dividend Aristocrats",
        "price": 56.99
    }, {
        "symbol": "TTEC",
        "label": "TTEC Holdings Inc.",
        "price": 42.14
    }, {
        "symbol": "FISI",
        "label": "Financial Institutions Inc.",
        "price": 31.67
    }, {
        "symbol": "VCYT",
        "label": "Veracyte Inc.",
        "price": 30.15
    }, {
        "symbol": "CGEN",
        "label": "Compugen Ltd.",
        "price": 6.12
    }, {
        "symbol": "SMM",
        "label": "Salient Midstream of Beneficial Interest",
        "price": 8.04
    }, {
        "symbol": "FM",
        "label": "iShares MSCI Frontier 100 Fund",
        "price": 29.04
    }, {
        "symbol": "JPC",
        "label": "Nuveen Preferred & Income Opportunities Fund",
        "price": 10.42
    }, {
        "symbol": "MOFG",
        "label": "MidWestOne Financial Group Inc.",
        "price": 34.32
    }, {
        "symbol": "REM",
        "label": "iShares Trust Mortgage Real Estate",
        "price": 42.08
    }, {
        "symbol": "IPKW",
        "label": "PowerShares International BuyBack Achievers Portfolio",
        "price": 34.3306
    }, {
        "symbol": "NVR",
        "label": "NVR Inc.",
        "price": 3893.41
    }, {
        "symbol": "RNST",
        "label": "Renasant Corporation",
        "price": 35.58
    }, {
        "symbol": "ULBI",
        "label": "Ultralife Corporation",
        "price": 7.31
    }, {
        "symbol": "FOMX",
        "label": "Foamix Pharmaceuticals Ltd.",
        "price": 4.7
    }, {
        "symbol": "SCHC",
        "label": "Schwab International Small-Cap Equity",
        "price": 31.65
    }, {
        "symbol": "ESE",
        "label": "ESCO Technologies Inc.",
        "price": 98.99
    }, {
        "symbol": "FBMS",
        "label": "The First Bancshares Inc.",
        "price": 34.11
    }, {
        "symbol": "ACIU",
        "label": "AC Immune SA",
        "price": 9.2
    }, {
        "symbol": "KELYA",
        "label": "Kelly Services Inc. Class A Common Stock",
        "price": 21.99
    }, {
        "symbol": "TMP",
        "label": "Tompkins Financial Corporation",
        "price": 92.45
    }, {
        "symbol": "MGEN",
        "label": "Miragen Therapeutics Inc.",
        "price": 0.66
    }, {
        "symbol": "SQBG",
        "label": "Sequential Brands Group Inc.",
        "price": 0.3553
    }, {
        "symbol": "WIP",
        "label": "SPDR Citi International Government Inflation-Protected Bond",
        "price": 54.27
    }, {
        "symbol": "ACRE",
        "label": "Ares Commercial Real Estate Corporation",
        "price": 16.39
    }, {
        "symbol": "IMOS",
        "label": "ChipMOS TECHNOLOGIES INC.",
        "price": 22.62
    }, {
        "symbol": "HFRO",
        "label": "Highland Floating Rate Opportunities Fund",
        "price": 12.26
    }, {
        "symbol": "SPOK",
        "label": "Spok Holdings Inc.",
        "price": 11.49
    }, {
        "symbol": "ABTX",
        "label": "Allegiance Bancshares Inc.",
        "price": 37.4
    }, {
        "symbol": "BYBK",
        "label": "Bay Bancorp Inc.",
        "price": 13.5
    }, {
        "symbol": "CAMT",
        "label": "Camtek Ltd.",
        "price": 12.48
    }, {
        "symbol": "DCF",
        "label": "Dreyfus Alcentra Global Credit Income 2024 Target Term Fund Inc.",
        "price": 9.51
    }, {
        "symbol": "DGLD",
        "label": "VelocityShares 3x Inverse Gold ETN",
        "price": 26.46
    }, {
        "symbol": "DGS",
        "label": "WisdomTree Emerging Market SmallCap Fund",
        "price": 48.4401
    }, {
        "symbol": "ERY",
        "label": "Direxion Daily Energy Bear 3X Shares",
        "price": 52.17
    }, {
        "symbol": "HMLP",
        "label": "Hoegh LNG Partners LP representing Limited Partner Interests",
        "price": 16.29
    }, {
        "symbol": "MCBC",
        "label": "Macatawa Bank Corporation",
        "price": 10.81
    }, {
        "symbol": "MDIV",
        "label": "First Trust Multi-Asset Diversified Income Index Fund",
        "price": 19.0
    }, {
        "symbol": "NIM",
        "label": "Nuveen Select Maturities Municipal Fund",
        "price": 10.44
    }, {
        "symbol": "NRP",
        "label": "Natural Resource Partners LP Limited Partnership",
        "price": 21.83
    }, {
        "symbol": "PBT",
        "label": "Permian Basin Royalty Trust",
        "price": 4.06
    }, {
        "symbol": "PLUS",
        "label": "ePlus inc.",
        "price": 86.25
    }, {
        "symbol": "SDLP",
        "label": "Seadrill Partners LLC Representing Limited Liability Company Interests",
        "price": 0.38
    }, {
        "symbol": "SELB",
        "label": "Selecta Biosciences Inc.",
        "price": 3.32
    }, {
        "symbol": "TBF",
        "label": "ProShares Short 20+ Year Treasury",
        "price": 20.83
    }, {
        "symbol": "THFF",
        "label": "First Financial Corporation Indiana",
        "price": 44.18
    }, {
        "symbol": "YIN",
        "label": "Yintech Investment Holdings Limited",
        "price": 6.66
    }, {
        "symbol": "FGB",
        "label": "First Trust Specialty Finance and Financial Opportunities Fund",
        "price": 6.14
    }, {
        "symbol": "GDP",
        "label": "Goodrich Petroleum Corporation",
        "price": 8.94
    }, {
        "symbol": "RFIL",
        "label": "RF Industries Ltd.",
        "price": 6.75
    }, {
        "symbol": "NCSM",
        "label": "NCS Multistage Holdings Inc.",
        "price": 1.85
    }, {
        "symbol": "PRFT",
        "label": "Perficient Inc.",
        "price": 49.58
    }, {
        "symbol": "PFS",
        "label": "Provident Financial Services Inc",
        "price": 24.43
    }, {
        "symbol": "CSWI",
        "label": "CSW Industrials Inc.",
        "price": 80.0
    }, {
        "symbol": "MPA",
        "label": "Blackrock MuniYield Pennsylvania Quality Fund",
        "price": 14.52
    }, {
        "symbol": "TPHS",
        "label": "Trinity Place Holdings Inc.",
        "price": 3.3301
    }, {
        "symbol": "DCUD",
        "label": "Dominion Energy Inc. 2016 Series A Corporate Units",
        "price": 49.97
    }, {
        "symbol": "ECON",
        "label": "Columbia Emerging Markets Consumer",
        "price": 21.76
    }, {
        "symbol": "HYLB",
        "label": "Xtrackers USD High Yield Corporate Bond",
        "price": 49.27
    }, {
        "symbol": "DEM",
        "label": "WisdomTree Emerging Markets High Dividend Fund",
        "price": 42.85
    }, {
        "symbol": "BLBD",
        "label": "Blue Bird Corporation",
        "price": 22.03
    }, {
        "symbol": "NCNA",
        "label": "NuCana plc",
        "price": 5.68
    }, {
        "symbol": "BSCM",
        "label": "Guggenheim BulletShares 2022 Corporate Bond",
        "price": 21.31
    }, {
        "symbol": "KMPH",
        "label": "KemPharm Inc.",
        "price": 0.4188
    }, {
        "symbol": "CDTI",
        "label": "CDTI Advanced Materials Inc.",
        "price": 0.8
    }, {
        "symbol": "PDP",
        "label": "PowerShares DWA Momentum Portfolio",
        "price": 67.23
    }, {
        "symbol": "ZLAB",
        "label": "Zai Lab Limited",
        "price": 53.1
    }, {
        "symbol": "AMSC",
        "label": "American Superconductor Corporation",
        "price": 7.88
    }, {
        "symbol": "RCKT",
        "label": "Rocket Pharmaceuticals Inc.",
        "price": 20.76
    }, {
        "symbol": "VNQI",
        "label": "Vanguard Global ex-U.S. Real Estate ETF",
        "price": 59.64
    }, {
        "symbol": "TGB",
        "label": "Taseko Mines Ltd.",
        "price": 0.605759
    }, {
        "symbol": "FBT",
        "label": "First Trust Amex Biotech Index Fund",
        "price": 152.05
    }, {
        "symbol": "YRD",
        "label": "Yirendai Ltd. American Depositary Shares each representing two",
        "price": 6.09
    }, {
        "symbol": "AOM",
        "label": "iShares Core Moderate Allocation",
        "price": 38.12
    }, {
        "symbol": "FGM",
        "label": "First Trust Germany AlphaDEX Fund",
        "price": 44.702
    }, {
        "symbol": "GLO",
        "label": "Clough Global Opportunities Fund",
        "price": 9.88
    }, {
        "symbol": "RWM",
        "label": "ProShares Short Russell2000",
        "price": 41.68
    }, {
        "symbol": "LEMB",
        "label": "iShares J.P. Morgan EM Local Currency Bond",
        "price": 43.89
    }, {
        "symbol": "GAIN",
        "label": "Gladstone Investment Corporation",
        "price": 13.8
    }, {
        "symbol": "JRI",
        "label": "Nuveen Real Asset Income and Growth Fund of Beneficial Interest",
        "price": 18.7
    }, {
        "symbol": "LRN",
        "label": "K12 Inc",
        "price": 20.81
    }, {
        "symbol": "RAVN",
        "label": "Raven Industries Inc.",
        "price": 35.77
    }, {
        "symbol": "GNT",
        "label": "GAMCO Natural Resources Gold & Income Trust",
        "price": 6.08
    }, {
        "symbol": "GXG",
        "label": "Global X MSCI Colombia",
        "price": 9.82
    }, {
        "symbol": "MBIO",
        "label": "Mustang Bio Inc.",
        "price": 4.33
    }, {
        "symbol": "SIFI",
        "label": "SI Financial Group Inc.",
        "price": 14.83
    }, {
        "symbol": "ARDC",
        "label": "Ares Dynamic Credit Allocation Fund Inc.",
        "price": 15.71
    }, {
        "symbol": "CEV",
        "label": "Eaton Vance California Municipal Income Trust Shares of Beneficial Interest",
        "price": 13.43
    }, {
        "symbol": "CLSN",
        "label": "Celsion Corporation",
        "price": 1.65
    }, {
        "symbol": "COPX",
        "label": "Global X Copper Miners",
        "price": 20.36
    }, {
        "symbol": "ECC",
        "label": "Eagle Point Credit Company Inc.",
        "price": 17.08
    }, {
        "symbol": "EFT",
        "label": "Eaton Vance Floating Rate Income Trust of Beneficial Interest",
        "price": 13.99
    }, {
        "symbol": "EHI",
        "label": "Western Asset Global High Income Fund Inc",
        "price": 10.11
    }, {
        "symbol": "EOD",
        "label": "Wells Fargo Global Dividend Opportunity Fund",
        "price": 5.64
    }, {
        "symbol": "ETG",
        "label": "Eaton Vance Tax-Advantaged Global Dividend Income Fund of Beneficial Interest",
        "price": 18.47
    }, {
        "symbol": "FSD",
        "label": "First Trust High Income Long Short Fund of Beneficial Interest",
        "price": 15.91
    }, {
        "symbol": "HLG",
        "label": "Hailiang Education Group Inc.",
        "price": 67.45
    }, {
        "symbol": "IFRX",
        "label": "InflaRx N.V.",
        "price": 3.64
    }, {
        "symbol": "IRR",
        "label": "Voya Natural Resources Equity Income Fund of Beneficial Interest",
        "price": 4.04
    }, {
        "symbol": "JGH",
        "label": "Nuveen Global High Income Fund of Beneficial Interest",
        "price": 16.7
    }, {
        "symbol": "KMM",
        "label": "Deutsche Multi-Market Income Trust Common Sshares of Beneficial Interest",
        "price": 9.01
    }, {
        "symbol": "KNOP",
        "label": "KNOT Offshore Partners LP representing Limited Partner Interests",
        "price": 20.89
    }, {
        "symbol": "LION",
        "label": "Fidelity Southern Corporation",
        "price": 29.64
    }, {
        "symbol": "LMBS",
        "label": "First Trust Low Duration Opportunities ETF",
        "price": 51.95
    }, {
        "symbol": "MMAC",
        "label": "MMA Capital Management LLC",
        "price": 31.75
    }, {
        "symbol": "MYJ",
        "label": "Blackrock MuniYield New Jersey Fund Inc",
        "price": 15.43
    }, {
        "symbol": "NAO",
        "label": "Nordic Amern Offshore Ltd (Bermuda)",
        "price": 3.16
    }, {
        "symbol": "OAKS",
        "label": "Five Oaks Investment Corp.",
        "price": 3.44
    }, {
        "symbol": "OCX",
        "label": "OncoCyte Corporation",
        "price": 3.02
    }, {
        "symbol": "RENN",
        "label": "Renren Inc. American Depositary Shares each representing fifteen Class A",
        "price": 1.85
    }, {
        "symbol": "SMRT",
        "label": "Stein Mart Inc.",
        "price": 0.69
    }, {
        "symbol": "TRK",
        "label": "Speedway Motorsports Inc.",
        "price": 18.05
    }, {
        "symbol": "VRTV",
        "label": "Veritiv Corporation",
        "price": 16.05
    }, {
        "symbol": "WKHS",
        "label": "Workhorse Group Inc.",
        "price": 3.29
    }, {
        "symbol": "TSI",
        "label": "TCW Strategic Income Fund Inc.",
        "price": 5.86
    }, {
        "symbol": "CLLS",
        "label": "Cellectis S.A.",
        "price": 18.24
    }, {
        "symbol": "JJSF",
        "label": "J & J Snack Foods Corp.",
        "price": 187.97
    }, {
        "symbol": "NTEC",
        "label": "Intec Pharma Ltd.",
        "price": 0.5597
    }, {
        "symbol": "TTEK",
        "label": "Tetra Tech Inc.",
        "price": 89.11
    }, {
        "symbol": "CTBI",
        "label": "Community Trust Bancorp Inc.",
        "price": 45.85
    }, {
        "symbol": "REMX",
        "label": "VanEck Vectors Rare Earth Strategic Metals",
        "price": 14.43
    }, {
        "symbol": "CCF",
        "label": "Chase Corporation",
        "price": 103.44
    }, {
        "symbol": "YCS",
        "label": "ProShares UltraShort Yen New",
        "price": 73.73
    }, {
        "symbol": "FVD",
        "label": "First Trust VL Dividend",
        "price": 36.65
    }, {
        "symbol": "HAWX",
        "label": "iShares Currency Hedged MSCI ACWI ex U.S.",
        "price": 28.942
    }, {
        "symbol": "IHG",
        "label": "Intercontinental Hotels Group American Depositary Shares (Each representing one)",
        "price": 67.22
    }, {
        "symbol": "RFDA",
        "label": "RiverFront Dynamic US Dividend Advantage",
        "price": 31.78
    }, {
        "symbol": "BATRA",
        "label": "Liberty Media Corporation Series A Liberty Braves Common Stock",
        "price": 29.44
    }, {
        "symbol": "DZZ",
        "label": "DB Gold Double Short ETN due February 15 2038",
        "price": 5.95
    }, {
        "symbol": "INTL",
        "label": "INTL FCStone Inc.",
        "price": 50.41
    }, {
        "symbol": "FBIO",
        "label": "Fortress Biotech Inc.",
        "price": 2.58
    }, {
        "symbol": "KIO",
        "label": "KKR Income Opportunities Fund",
        "price": 15.87
    }, {
        "symbol": "BSRR",
        "label": "Sierra Bancorp",
        "price": 27.82
    }, {
        "symbol": "CORI",
        "label": "Corium International Inc.",
        "price": 12.71
    }, {
        "symbol": "EDUC",
        "label": "Educational Development Corporation",
        "price": 5.83
    }, {
        "symbol": "SOR",
        "label": "Source Capital Inc.",
        "price": 39.6
    }, {
        "symbol": "FDEF",
        "label": "First Defiance Financial Corp.",
        "price": 31.22
    }, {
        "symbol": "PSLV",
        "label": "Sprott Physical Silver Trust ETV",
        "price": 5.38
    }, {
        "symbol": "NVMI",
        "label": "Nova Measuring Instruments Ltd.",
        "price": 39.88
    }, {
        "symbol": "PXJ",
        "label": "PowerShares Dynamic Oil Services",
        "price": 5.78
    }, {
        "symbol": "ESXB",
        "label": "Community Bankers Trust Corporation.",
        "price": 9.45
    }, {
        "symbol": "KMF",
        "label": "Kayne Anderson Midstream Energy Fund Inc",
        "price": 10.84
    }, {
        "symbol": "KWEB",
        "label": "KraneShares Trust CSI China Internet",
        "price": 53.96
    }, {
        "symbol": "FLXS",
        "label": "Flexsteel Industries Inc.",
        "price": 20.17
    }, {
        "symbol": "VALU",
        "label": "Value Line Inc.",
        "price": 32.11
    }, {
        "symbol": "RIBT",
        "label": "RiceBran Technologies",
        "price": 1.26
    }, {
        "symbol": "DDWM",
        "label": "WisdomTree Dynamic Currency Hedged International Equity Fund",
        "price": 30.89
    }, {
        "symbol": "ABLX",
        "label": "Ablynx NV",
        "price": 52.78
    }, {
        "symbol": "HACK",
        "label": "ETFMG Prime Cyber Security",
        "price": 38.22
    }, {
        "symbol": "MYE",
        "label": "Myers Industries Inc.",
        "price": 17.01
    }, {
        "symbol": "CWEB",
        "label": "Direxion Daily CSI China Internet Index Bull 2X Shares",
        "price": 19.85
    }, {
        "symbol": "PMF",
        "label": "PIMCO Municipal Income Fund",
        "price": 15.34
    }, {
        "symbol": "GLPG",
        "label": "Galapagos NV",
        "price": 227.69
    }, {
        "symbol": "RLGT",
        "label": "Radiant Logistics Inc.",
        "price": 5.72
    }, {
        "symbol": "ZMLP",
        "label": "Direxion Zacks MLP High Income Index Shares",
        "price": 13.63
    }, {
        "symbol": "FC",
        "label": "Franklin Covey Company",
        "price": 33.62
    }, {
        "symbol": "NC",
        "label": "NACCO Industries Inc.",
        "price": 48.51
    }, {
        "symbol": "APT",
        "label": "Alpha Pro Tech Ltd.",
        "price": 3.5
    }, {
        "symbol": "EWX",
        "label": "SPDR S&P Emerging Markets Small Cap",
        "price": 43.18
    }, {
        "symbol": "EVI",
        "label": "EnviroStar Inc.",
        "price": 25.24
    }, {
        "symbol": "AGRX",
        "label": "Agile Therapeutics Inc.",
        "price": 2.82
    }, {
        "symbol": "AGTC",
        "label": "Applied Genetic Technologies Corporation",
        "price": 8.16
    }, {
        "symbol": "ALLT",
        "label": "Allot Communications Ltd.",
        "price": 9.48
    }, {
        "symbol": "BANX",
        "label": "StoneCastle Financial Corp",
        "price": 22.41
    }, {
        "symbol": "BFK",
        "label": "BlackRock Municipal Income Trust",
        "price": 14.41
    }, {
        "symbol": "CANE",
        "label": "Teucrium Sugar Fund ETV",
        "price": 7.15
    }, {
        "symbol": "CBIO",
        "label": "Catalyst Biosciences Inc.",
        "price": 7.38
    }, {
        "symbol": "CEA",
        "label": "China Eastern Airlines Corporation Ltd.",
        "price": 27.48
    }, {
        "symbol": "CNET",
        "label": "ChinaNet Online Holdings Inc.",
        "price": 1.38
    }, {
        "symbol": "DCPH",
        "label": "Deciphera Pharmaceuticals Inc.",
        "price": 69.4
    }, {
        "symbol": "HBB",
        "label": "Hamilton Beach Brands Holding Company Class A",
        "price": 17.52
    }, {
        "symbol": "HBP",
        "label": "Huttig Building Products Inc.",
        "price": 1.45
    }, {
        "symbol": "HOFT",
        "label": "Hooker Furniture Corporation",
        "price": 25.29
    }, {
        "symbol": "HYZD",
        "label": "WisdomTree Interest Rate Hedged High Yield Bond Fund",
        "price": 23.385
    }, {
        "symbol": "ICLK",
        "label": "iClick Interactive Asia Group Limited",
        "price": 3.74
    }, {
        "symbol": "IFON",
        "label": "InfoSonics Corp",
        "price": 3.88
    }, {
        "symbol": "IGA",
        "label": "Voya Global Advantage and Premium Opportunity Fund of Beneficial Interest",
        "price": 10.74
    }, {
        "symbol": "IIM",
        "label": "Invesco Value Municipal Income Trust",
        "price": 15.67
    }, {
        "symbol": "IPCI",
        "label": "Intellipharmaceutics International Inc.",
        "price": 0.23
    }, {
        "symbol": "ISTR",
        "label": "Investar Holding Corporation",
        "price": 23.96
    }, {
        "symbol": "KBAL",
        "label": "Kimball International Inc.",
        "price": 21.67
    }, {
        "symbol": "LNDC",
        "label": "Landec Corporation",
        "price": 11.36
    }, {
        "symbol": "NAN",
        "label": "Nuveen New York Quality Municipal Income Fund",
        "price": 14.32
    }, {
        "symbol": "NDP",
        "label": "Tortoise Energy Independence Fund Inc.",
        "price": 4.22
    }, {
        "symbol": "NTRSP",
        "label": "Northern Trust Corporation Depository Shares",
        "price": 25.01
    }, {
        "symbol": "NWPX",
        "label": "Northwest Pipe Company",
        "price": 33.58
    }, {
        "symbol": "PRCP",
        "label": "Perceptron Inc.",
        "price": 6.02
    }, {
        "symbol": "PRFZ",
        "label": "PowerShares FTSE RAFI US 1500 Small-Mid Portfolio",
        "price": 138.49
    }, {
        "symbol": "RM",
        "label": "Regional Management Corp.",
        "price": 28.99
    }, {
        "symbol": "SYRS",
        "label": "Syros Pharmaceuticals Inc.",
        "price": 8.505
    }, {
        "symbol": "UTL",
        "label": "UNITIL Corporation",
        "price": 63.02
    }, {
        "symbol": "WNEB",
        "label": "Western New England Bancorp Inc.",
        "price": 9.36
    }, {
        "symbol": "ZEUS",
        "label": "Olympic Steel Inc.",
        "price": 16.03
    }, {
        "symbol": "MVF",
        "label": "Blackrock MuniVest Fund Inc.",
        "price": 9.2
    }, {
        "symbol": "ERM",
        "label": "EquityCompass Risk Manager",
        "price": 19.67
    }, {
        "symbol": "DKT",
        "label": "Deutsch Bk Contingent Cap Tr V Tr Pfd Secs",
        "price": 25.43
    }, {
        "symbol": "NYF",
        "label": "iShares New York Muni Bond",
        "price": 56.66
    }, {
        "symbol": "SCL",
        "label": "Stepan Company",
        "price": 104.54
    }, {
        "symbol": "ASCMA",
        "label": "Ascent Capital Group Inc. Series A Common Stock",
        "price": 1.23
    }, {
        "symbol": "RSYS",
        "label": "RadiSys Corporation",
        "price": 1.72
    }, {
        "symbol": "SALM",
        "label": "Salem Media Group Inc.",
        "price": 1.4837
    }, {
        "symbol": "EOS",
        "label": "Eaton Vance Enhance Equity Income Fund II",
        "price": 18.43
    }, {
        "symbol": "NEWT",
        "label": "Newtek Business Services Corp.",
        "price": 21.83
    }, {
        "symbol": "NH",
        "label": "NantHealth Inc.",
        "price": 1.44
    }, {
        "symbol": "HBMD",
        "label": "Howard Bancorp Inc.",
        "price": 16.81
    }, {
        "symbol": "REIS",
        "label": "Reis Inc",
        "price": 22.99
    }, {
        "symbol": "SPXX",
        "label": "Nuveen S&P 500 Dynamic Overwrite Fund",
        "price": 16.67
    }, {
        "symbol": "SPNE",
        "label": "SeaSpine Holdings Corporation",
        "price": 15.89
    }, {
        "symbol": "FORR",
        "label": "Forrester Research Inc.",
        "price": 43.05
    }, {
        "symbol": "IYT",
        "label": "iShares Transportation Average",
        "price": 183.44
    }, {
        "symbol": "NTP",
        "label": "Nam Tai Property Inc.",
        "price": 8.64
    }, {
        "symbol": "STRL",
        "label": "Sterling Construction Company Inc",
        "price": 14.9
    }, {
        "symbol": "MFSF",
        "label": "MutualFirst Financial Inc.",
        "price": 38.79
    }, {
        "symbol": "BLCN",
        "label": "Reality Shares Nasdaq NextGen Economy ETF",
        "price": 26.9009
    }, {
        "symbol": "MRUS",
        "label": "Merus N.V.",
        "price": 16.98
    }, {
        "symbol": "RILY",
        "label": "B. Riley Financial Inc.",
        "price": 25.87
    }, {
        "symbol": "RUSL",
        "label": "Direxion Daily Russia Bull 3x Shares",
        "price": 44.22
    }, {
        "symbol": "DBL",
        "label": "DoubleLine Opportunistic Credit Fund of Beneficial Interest",
        "price": 20.89
    }, {
        "symbol": "MUI",
        "label": "Blackrock Muni Intermediate Duration Fund Inc",
        "price": 14.5
    }, {
        "symbol": "EPU",
        "label": "iShares Trust MSCI Peru",
        "price": 36.91
    }, {
        "symbol": "TAX",
        "label": "Liberty Tax Inc.",
        "price": 9.18
    }, {
        "symbol": "IMUC",
        "label": "ImmunoCellular Therapeutics Ltd.",
        "price": 0.05
    }, {
        "symbol": "RJA",
        "label": "AB Svensk Ekportkredit (Swedish Export Credit Corporation) ELEMENTS Linked to th" +
                "e Rogers International Commodity Index - Agriculture Total Return Structured Pro",
        "price": 5.51
    }, {
        "symbol": "NIE",
        "label": "AllianzGI Equity & Convertible Income Fund",
        "price": 23.8
    }, {
        "symbol": "TLTE",
        "label": "FlexShares Morningstar Emerging Markets Factor Tilt Index Fund",
        "price": 49.66
    }, {
        "symbol": "ASG",
        "label": "Liberty All-Star Growth Fund Inc.",
        "price": 6.94
    }, {
        "symbol": "STCN",
        "label": "Steel Connect Inc.",
        "price": 1.51
    }, {
        "symbol": "GRAM",
        "label": "Grana y Montero S.A.A. American Depositary Shares each representing five",
        "price": 2.49
    }, {
        "symbol": "ACFC",
        "label": "Atlantic Coast Financial Corporation",
        "price": 10.92
    }, {
        "symbol": "MMLP",
        "label": "Martin Midstream Partners L.P.",
        "price": 4.57
    }, {
        "symbol": "HBNC",
        "label": "Horizon Bancorp (IN)",
        "price": 18.57
    }, {
        "symbol": "CUE",
        "label": "Cue Biopharma Inc.",
        "price": 15.4
    }, {
        "symbol": "INFU",
        "label": "InfuSystems Holdings Inc.",
        "price": 8.8
    }, {
        "symbol": "CHAD",
        "label": "Direxion Daily CSI 300 China A Shares Bear 1X Shares",
        "price": 31.8
    }, {
        "symbol": "PCEF",
        "label": "PowerShares CEF Income Composite Portfolio",
        "price": 23.65
    }, {
        "symbol": "RPG",
        "label": "Guggenheim S&P 500 Pure Growth",
        "price": 115.09
    }, {
        "symbol": "MGNX",
        "label": "MacroGenics Inc.",
        "price": 11.26
    }, {
        "symbol": "TDIV",
        "label": "First Trust NASDAQ Technology Dividend Index Fund",
        "price": 44.59
    }, {
        "symbol": "BSCO",
        "label": "Guggenheim BulletShares 2024 Corporate Bond",
        "price": 21.03
    }, {
        "symbol": "RFAP",
        "label": "First Trust RiverFront Dynamic Asia Pacific ETF",
        "price": 53.83
    }, {
        "symbol": "AMEH",
        "label": "Apollo Medical Holdings Inc.",
        "price": 18.8
    }, {
        "symbol": "STN",
        "label": "Stantec Inc",
        "price": 29.81
    }, {
        "symbol": "AXSM",
        "label": "Axsome Therapeutics Inc.",
        "price": 90.34
    }, {
        "symbol": "GHC",
        "label": "Graham Holdings Company",
        "price": 623.14
    }, {
        "symbol": "IBMJ",
        "label": "iShares iBonds Dec 2021 Term Muni Bond",
        "price": 25.71
    }, {
        "symbol": "CIK",
        "label": "Credit Suisse Asset Management Income Fund Inc.",
        "price": 3.3
    }, {
        "symbol": "HYLD",
        "label": "Peritus High Yield",
        "price": 34.52
    }, {
        "symbol": "JTPY",
        "label": "JetPay Corporation",
        "price": 5.05
    }, {
        "symbol": "DCOM",
        "label": "Dime Community Bancshares Inc.",
        "price": 20.14
    }, {
        "symbol": "AMOT",
        "label": "Allied Motion Technologies Inc.",
        "price": 49.42
    }, {
        "symbol": "BIB",
        "label": "ProShares Ultra Nasdaq Biotechnology",
        "price": 62.95
    }, {
        "symbol": "BKEP",
        "label": "Blueknight Energy Partners L.P. L.L.C.",
        "price": 1.175
    }, {
        "symbol": "BRT",
        "label": "BRT Apartments Corp. (MD)",
        "price": 17.84
    }, {
        "symbol": "BWFG",
        "label": "Bankwell Financial Group Inc.",
        "price": 27.76
    }, {
        "symbol": "CPER",
        "label": "United States Copper Index Fund ETV",
        "price": 16.54
    }, {
        "symbol": "CSBK",
        "label": "Clifton Bancorp Inc.",
        "price": 15.68
    }, {
        "symbol": "CSV",
        "label": "Carriage Services Inc.",
        "price": 25.69
    }, {
        "symbol": "CVU",
        "label": "CPI Aerostructures Inc.",
        "price": 6.73
    }, {
        "symbol": "DNI",
        "label": "Dividend and Income Fund",
        "price": 13.46
    }, {
        "symbol": "DSM",
        "label": "Dreyfus Strategic Municipal Bond Fund Inc.",
        "price": 8.05
    }, {
        "symbol": "EBF",
        "label": "Ennis Inc.",
        "price": 21.97
    }, {
        "symbol": "EVT",
        "label": "Eaton Vance Tax Advantaged Dividend Income Fund of Beneficial Interest",
        "price": 26.16
    }, {
        "symbol": "FMBH",
        "label": "First Mid-Illinois Bancshares Inc.",
        "price": 34.86
    }, {
        "symbol": "FMO",
        "label": "Fiduciary/Claymore MLP Opportunity Fund of Beneficial Interest",
        "price": 8.43
    }, {
        "symbol": "FOF",
        "label": "Cohen & Steers Closed-End Opportunity Fund Inc.",
        "price": 13.81
    }, {
        "symbol": "FTCS",
        "label": "First Trust Capital Strength ETF",
        "price": 62.06
    }, {
        "symbol": "GEOS",
        "label": "Geospace Technologies Corporation",
        "price": 15.54
    }, {
        "symbol": "HNP",
        "label": "Huaneng Power Intl",
        "price": 20.26
    }, {
        "symbol": "HTBX",
        "label": "Heat Biologics Inc.",
        "price": 0.3283
    }, {
        "symbol": "IBMI",
        "label": "iShares iBonds Sep 2020 Term Muni Bond",
        "price": 25.5
    }, {
        "symbol": "IDE",
        "label": "Voya Infrastructure Industrials and Materials Fund of Beneficial Interest",
        "price": 11.78
    }, {
        "symbol": "IEUS",
        "label": "iShares MSCI Europe Small-Cap ETF",
        "price": 55.7969
    }, {
        "symbol": "INBK",
        "label": "First Internet Bancorp",
        "price": 24.19
    }, {
        "symbol": "IYK",
        "label": "iShares U.S. Consumer Goods",
        "price": 119.48
    }, {
        "symbol": "KALV",
        "label": "KalVista Pharmaceuticals Inc.",
        "price": 18.56
    }, {
        "symbol": "KED",
        "label": "Kayne Anderson Energy Development Company",
        "price": 18.4
    }, {
        "symbol": "KONA",
        "label": "Kona Grill Inc.",
        "price": 0.14
    }, {
        "symbol": "LIND",
        "label": "Lindblad Expeditions Holdings Inc.",
        "price": 17.73
    }, {
        "symbol": "LVHI",
        "label": "Legg Mason International Low Volatility High Dividend",
        "price": 25.51
    }, {
        "symbol": "MCRB",
        "label": "Seres Therapeutics Inc.",
        "price": 3.43
    }, {
        "symbol": "MMT",
        "label": "MFS Multimarket Income Trust",
        "price": 6.29
    }, {
        "symbol": "MMU",
        "label": "Western Asset Managed Municipals Fund Inc.",
        "price": 13.82
    }, {
        "symbol": "NTWK",
        "label": "NETSOL Technologies Inc.",
        "price": 3.7
    }, {
        "symbol": "NUDM",
        "label": "NuShares ESG International Developed Markets Equity",
        "price": 24.78
    }, {
        "symbol": "PANL",
        "label": "Pangaea Logistics Solutions Ltd.",
        "price": 3.14
    }, {
        "symbol": "PNQI",
        "label": "PowerShares Nasdaq Internet Portfolio",
        "price": 152.71
    }, {
        "symbol": "PPA",
        "label": "PowerShares Aerospace & Defense Portfolio",
        "price": 72.5
    }, {
        "symbol": "PXH",
        "label": "PowerShares FTSE RAFI Emerging Markets Portfolio",
        "price": 20.65
    }, {
        "symbol": "RCS",
        "label": "PIMCO Strategic Income Fund Inc.",
        "price": 9.14
    }, {
        "symbol": "REPH",
        "label": "Recro Pharma Inc.",
        "price": 16.1
    }, {
        "symbol": "RFDI",
        "label": "First Trust RiverFront Dynamic Developed International ETF",
        "price": 60.5483
    }, {
        "symbol": "SVVC",
        "label": "Firsthand Technology Value Fund Inc.",
        "price": 6.62
    }, {
        "symbol": "SZC",
        "label": "Cushing Renaissance Fund (The) of Beneficial Interest",
        "price": 13.62
    }, {
        "symbol": "TYHT",
        "label": "Shineco Inc.",
        "price": 0.646057
    }, {
        "symbol": "URE",
        "label": "ProShares Ultra Real Estate",
        "price": 75.01
    }, {
        "symbol": "VGM",
        "label": "Invesco Trust for Investment Grade Municipals (DE)",
        "price": 13.0
    }, {
        "symbol": "VIXM",
        "label": "ProShares Trust VIX Mid-Term Futures",
        "price": 22.28
    }, {
        "symbol": "ZSL",
        "label": "ProShares UltraShort Silver",
        "price": 40.64
    }, {
        "symbol": "DRV",
        "label": "Drexion Daily Real Estate Bear 3x Shares",
        "price": 6.6
    }, {
        "symbol": "DSU",
        "label": "Blackrock Debt Strategies Fund Inc.",
        "price": 11.37
    }, {
        "symbol": "MRAM",
        "label": "Everspin Technologies Inc.",
        "price": 5.04
    }, {
        "symbol": "VOE",
        "label": "Vanguard Mid-Cap Value",
        "price": 121.49
    }, {
        "symbol": "KALA",
        "label": "Kala Pharmaceuticals Inc.",
        "price": 6.52
    }, {
        "symbol": "WDFC",
        "label": "WD-40 Company",
        "price": 193.04
    }, {
        "symbol": "CNIT",
        "label": "China Information Technology Inc.",
        "price": 1.78
    }, {
        "symbol": "BCBP",
        "label": "BCB Bancorp Inc. (NJ)",
        "price": 13.85
    }, {
        "symbol": "TNAV",
        "label": "Telenav Inc.",
        "price": 4.95
    }, {
        "symbol": "EMQQ",
        "label": "Emerging Markets Internet and Ecommerce ETF (The)",
        "price": 38.38
    }, {
        "symbol": "IQI",
        "label": "Invesco Quality Municipal Income Trust",
        "price": 12.78
    }, {
        "symbol": "SPYD",
        "label": "SPDR Series Trust Portfolio S&P 500 High Dividend",
        "price": 37.12
    }, {
        "symbol": "DAVE",
        "label": "Famous Dave's of America Inc.",
        "price": 3.75
    }, {
        "symbol": "GARS",
        "label": "Garrison Capital Inc.",
        "price": 6.06
    }, {
        "symbol": "SRTY",
        "label": "ProShares UltraPro Short Russell2000",
        "price": 26.61
    }, {
        "symbol": "QINC",
        "label": "First Trust RBA Quality Income ETF",
        "price": 25.35
    }, {
        "symbol": "NP",
        "label": "Neenah Inc.",
        "price": 67.76
    }, {
        "symbol": "PSTI",
        "label": "Pluristem Therapeutics Inc.",
        "price": 4.08
    }, {
        "symbol": "BHB",
        "label": "Bar Harbor Bankshares Inc.",
        "price": 23.76
    }, {
        "symbol": "FVE",
        "label": "Five Star Senior Living Inc.",
        "price": 5.09
    }, {
        "symbol": "WTM",
        "label": "White Mountains Insurance Group Ltd.",
        "price": 1108.13
    }, {
        "symbol": "LILA",
        "label": "Liberty Latin America Ltd.",
        "price": 18.77
    }, {
        "symbol": "IPE",
        "label": "SPDR Bloomberg Barclays TIPS",
        "price": 57.12
    }, {
        "symbol": "DELT",
        "label": "Delta Technology Holdings Limited",
        "price": 0.92
    }, {
        "symbol": "EELV",
        "label": "PowerShares S&P Emerging Markets Low Volatility Portfolio",
        "price": 23.78
    }, {
        "symbol": "IBIO",
        "label": "iBio Inc.",
        "price": 0.2349
    }, {
        "symbol": "IEP",
        "label": "Icahn Enterprises L.P.",
        "price": 65.97
    }, {
        "symbol": "MJ",
        "label": "ETFMG Alternative Harvest",
        "price": 31.67
    }, {
        "symbol": "VOT",
        "label": "Vanguard Mid-Cap Growth",
        "price": 165.54
    }, {
        "symbol": "CURE",
        "label": "Direxion Daily Healthcare Bull 3X Shares",
        "price": 48.41
    }, {
        "symbol": "LMNR",
        "label": "Limoneira Co",
        "price": 20.67
    }, {
        "symbol": "VNRX",
        "label": "VolitionRX Limited",
        "price": 4.74
    }, {
        "symbol": "AGLE",
        "label": "Aeglea BioTherapeutics Inc.",
        "price": 7.94
    }, {
        "symbol": "RYTM",
        "label": "Rhythm Pharmaceuticals Inc.",
        "price": 19.7
    }, {
        "symbol": "CELH",
        "label": "Celsius Holdings Inc.",
        "price": 5.24
    }, {
        "symbol": "WEYS",
        "label": "Weyco Group Inc.",
        "price": 23.3
    }, {
        "symbol": "QEFA",
        "label": "SPDR MSCI EAFE StrategicFactors",
        "price": 60.52
    }, {
        "symbol": "SBOW",
        "label": "SilverBow Resorces Inc.",
        "price": 8.54
    }, {
        "symbol": "CHMI",
        "label": "Cherry Hill Mortgage Investment Corporation",
        "price": 14.68
    }, {
        "symbol": "CERC",
        "label": "Cerecor Inc.",
        "price": 5.03
    }, {
        "symbol": "MOTI",
        "label": "VanEck Vectors Morningstar International Moat",
        "price": 32.705
    }, {
        "symbol": "SCIF",
        "label": "VanEck Vectors India Small-Cap Index",
        "price": 41.55
    }, {
        "symbol": "DMF",
        "label": "Dreyfus Municipal Income Inc.",
        "price": 9.21
    }, {
        "symbol": "RDIV",
        "label": "Oppenheimer Ultra Dividend Revenue",
        "price": 39.71
    }, {
        "symbol": "ALOT",
        "label": "AstroNova Inc.",
        "price": 13.26
    }, {
        "symbol": "AFB",
        "label": "AllianceBernstein National Municipal Income Fund Inc",
        "price": 13.96
    }, {
        "symbol": "PDBC",
        "label": "PowerShares Optimum Yield Diversified Commodity Strategy No K-1 Portfolio",
        "price": 16.36
    }, {
        "symbol": "CBMG",
        "label": "Cellular Biomedicine Group Inc.",
        "price": 17.48
    }, {
        "symbol": "ENY",
        "label": "Guggenheim Canadian Energy Income",
        "price": 6.98
    }, {
        "symbol": "GOF",
        "label": "Guggenheim Strategic Opportunities Fund of Beneficial Interest",
        "price": 18.4
    }, {
        "symbol": "PSDV",
        "label": "pSivida Corp.",
        "price": 1.25
    }, {
        "symbol": "RBB",
        "label": "RBB Bancorp",
        "price": 20.71
    }, {
        "symbol": "DFJ",
        "label": "WisdomTree Japan SmallCap Fund",
        "price": 72.61
    }, {
        "symbol": "STKS",
        "label": "The ONE Group Hospitality Inc.",
        "price": 3.96
    }, {
        "symbol": "VOXX",
        "label": "VOXX International Corporation",
        "price": 4.47
    }, {
        "symbol": "ESGR",
        "label": "Enstar Group Limited",
        "price": 205.89
    }, {
        "symbol": "JFR",
        "label": "Nuveen Floating Rate Income Fund",
        "price": 10.21
    }, {
        "symbol": "RIOT",
        "label": "Riot Blockchain Inc",
        "price": 1.46
    }, {
        "symbol": "SORL",
        "label": "SORL Auto Parts Inc.",
        "price": 4.62
    }, {
        "symbol": "AOK",
        "label": "iShares Core Conservative Allocation",
        "price": 34.66
    }, {
        "symbol": "ASND",
        "label": "Ascendis Pharma A/S",
        "price": 139.13
    }, {
        "symbol": "BSM",
        "label": "Black Stone Minerals L.P. representing limited partner interests",
        "price": 12.21
    }, {
        "symbol": "BTAI",
        "label": "BioXcel Therapeutics Inc.",
        "price": 16.67
    }, {
        "symbol": "CBK",
        "label": "Christopher & Banks Corporation",
        "price": 0.35
    }, {
        "symbol": "CRMT",
        "label": "America's Car-Mart Inc.",
        "price": 109.55
    }, {
        "symbol": "CVCY",
        "label": "Central Valley Community Bancorp",
        "price": 20.78
    }, {
        "symbol": "CVY",
        "label": "Guggenheim Multi-Asset Income",
        "price": 21.39
    }, {
        "symbol": "CWCO",
        "label": "Consolidated Water Co. Ltd.",
        "price": 16.725
    }, {
        "symbol": "DBGR",
        "label": "Xtrackers MSCI Germany Hedged Equity",
        "price": 26.34
    }, {
        "symbol": "DGRE",
        "label": "WisdomTree Emerging Markets Quality Dividend Growth Fund",
        "price": 26.89
    }, {
        "symbol": "EHTH",
        "label": "eHealth Inc.",
        "price": 96.81
    }, {
        "symbol": "EOCC",
        "label": "Enel Generacion Chile S.A. American Depositary Shares (Each representing 30 shar" +
                "es of)",
        "price": 16.83
    }, {
        "symbol": "ESNC",
        "label": "EnSync Inc.",
        "price": 0.1
    }, {
        "symbol": "FEU",
        "label": "SPDR DJ STOXX 50 Etf",
        "price": 32.66
    }, {
        "symbol": "FREL",
        "label": "Fidelity MSCI Real Estate Index",
        "price": 28.4
    }, {
        "symbol": "GLL",
        "label": "ProShares UltraShort Gold",
        "price": 69.1
    }, {
        "symbol": "GROW",
        "label": "U.S. Global Investors Inc.",
        "price": 1.36
    }, {
        "symbol": "IBMH",
        "label": "iShares iBonds Sep 2019 Term Muni Bond",
        "price": 25.43
    }, {
        "symbol": "IJT",
        "label": "iShares S&P Small-Cap 600 Growth ETF",
        "price": 199.44
    }, {
        "symbol": "IPAY",
        "label": "ETFMG Prime Mobile Payments",
        "price": 44.92
    }, {
        "symbol": "IQDF",
        "label": "FlexShares International Quality Dividend Index Fund",
        "price": 22.57
    }, {
        "symbol": "IRL",
        "label": "New Ireland Fund Inc (The)",
        "price": 9.9675
    }, {
        "symbol": "JCAP",
        "label": "Jernigan Capital Inc.",
        "price": 19.14
    }, {
        "symbol": "JTA",
        "label": "Nuveen Tax-Advantaged Total Return Strategy Fund of Beneficial Interest",
        "price": 12.23
    }, {
        "symbol": "KF",
        "label": "Korea Fund Inc. (The)",
        "price": 30.4449
    }, {
        "symbol": "KINS",
        "label": "Kingstone Companies Inc",
        "price": 8.22
    }, {
        "symbol": "KTF",
        "label": "Deutsche Municiple Income Trust",
        "price": 11.56
    }, {
        "symbol": "MPX",
        "label": "Marine Products Corporation",
        "price": 14.23
    }, {
        "symbol": "MRLN",
        "label": "Marlin Business Services Corp.",
        "price": 21.38
    }, {
        "symbol": "MVC",
        "label": "MVC Capital Inc.",
        "price": 9.23
    }, {
        "symbol": "NAC",
        "label": "Nuveen California Quality Municipal Income Fund",
        "price": 15.03
    }, {
        "symbol": "NBW",
        "label": "Neuberger Berman California Intermediate Municipal Fund Inc.",
        "price": 14.0792
    }, {
        "symbol": "NETE",
        "label": "Net Element Inc.",
        "price": 3.6
    }, {
        "symbol": "NETS",
        "label": "Netshoes (Cayman) Limited",
        "price": 3.18
    }, {
        "symbol": "NVLN",
        "label": "Novelion Therapeutics Inc.",
        "price": 0.88
    }, {
        "symbol": "ORAN",
        "label": "Orange",
        "price": 14.52
    }, {
        "symbol": "PCYG",
        "label": "Park City Group Inc.",
        "price": 4.98
    }, {
        "symbol": "PDT",
        "label": "John Hancock Premium Dividend Fund",
        "price": 18.21
    }, {
        "symbol": "PML",
        "label": "Pimco Municipal Income Fund II of Beneficial Interest",
        "price": 15.39
    }, {
        "symbol": "PSP",
        "label": "PowerShares Listed Private Equity",
        "price": 11.62
    }, {
        "symbol": "QIWI",
        "label": "QIWI plc",
        "price": 19.86
    }, {
        "symbol": "RFTA",
        "label": "RAIT Financial Trust 7.125% Senior Notes due 2019",
        "price": 21.12
    }, {
        "symbol": "RMT",
        "label": "Royce Micro-Cap Trust Inc.",
        "price": 8.72
    }, {
        "symbol": "SJB",
        "label": "ProShares Short High Yield",
        "price": 21.88
    }, {
        "symbol": "SPYB",
        "label": "SPDR S&P 500 Buyback",
        "price": 74.9537
    }, {
        "symbol": "TCHF",
        "label": "iShares Edge MSCI Multifactor Technology",
        "price": 42.71
    }, {
        "symbol": "UFAB",
        "label": "Unique Fabricating Inc.",
        "price": 4.25
    }, {
        "symbol": "USAK",
        "label": "USA Truck Inc.",
        "price": 7.83
    }, {
        "symbol": "UTSI",
        "label": "UTStarcom Holdings Corp",
        "price": 2.85
    }, {
        "symbol": "VERI",
        "label": "Veritone Inc.",
        "price": 2.49
    }, {
        "symbol": "VXF",
        "label": "Vanguard Extended Market",
        "price": 129.65
    }, {
        "symbol": "WHG",
        "label": "Westwood Holdings Group Inc",
        "price": 29.25
    }, {
        "symbol": "PGZ",
        "label": "Principal Real Estate Income Fund of Beneficial Interest",
        "price": 21.16
    }, {
        "symbol": "SCHZ",
        "label": "Schwab US Aggregate Bond",
        "price": 53.74
    }, {
        "symbol": "CDC",
        "label": "VictoryShares US EQ Income Enhanced Volatility Wtd ETF",
        "price": 49.375
    }, {
        "symbol": "FENC",
        "label": "Fennec Pharmaceuticals Inc.",
        "price": 6.18
    }, {
        "symbol": "FARM",
        "label": "Farmer Brothers Company",
        "price": 12.76
    }, {
        "symbol": "JXI",
        "label": "iShares Global Utilities",
        "price": 54.91
    }, {
        "symbol": "CTBB",
        "label": "Qwest Corporation 6.5% Notes due 2056",
        "price": 25.86
    }, {
        "symbol": "VGIT",
        "label": "Vanguard Intermediate-Term Treasury ETF",
        "price": 66.27
    }, {
        "symbol": "CYB",
        "label": "WisdomTree Chinese Yuan Strategy Fund",
        "price": 25.56
    }, {
        "symbol": "ANIK",
        "label": "Anika Therapeutics Inc.",
        "price": 45.43
    }, {
        "symbol": "SVRA",
        "label": "Savara Inc.",
        "price": 3.75
    }, {
        "symbol": "TG",
        "label": "Tredegar Corporation",
        "price": 22.32
    }, {
        "symbol": "XPLR",
        "label": "Xplore Technologies Corp",
        "price": 6.0
    }, {
        "symbol": "LDRS",
        "label": "Innovator IBD ETF Leaders",
        "price": 20.42
    }, {
        "symbol": "QQEW",
        "label": "First Trust NASDAQ-100 Equal Weighted Index Fund",
        "price": 76.731
    }, {
        "symbol": "VGSH",
        "label": "Vanguard Short-Term Treasury ETF",
        "price": 60.88
    }, {
        "symbol": "EXI",
        "label": "iShares Global Industrials",
        "price": 89.02
    }, {
        "symbol": "BUSE",
        "label": "First Busey Corporation",
        "price": 27.37
    }, {
        "symbol": "HONE",
        "label": "HarborOne Bancorp Inc.",
        "price": 11.01
    }, {
        "symbol": "IAF",
        "label": "Aberdeen Australia Equity Fund Inc",
        "price": 5.55
    }, {
        "symbol": "HDMV",
        "label": "First Trust Exchange-Traded Fund III Horizon Managed Volatility Developed Intern" +
                "ational",
        "price": 32.92
    }, {
        "symbol": "IDLV",
        "label": "PowerShares S&P International Developed Low Volatility Portfolio",
        "price": 34.66
    }, {
        "symbol": "TOTL",
        "label": "SPDR DoubleLine Total Return Tactical",
        "price": 48.74
    }, {
        "symbol": "UHT",
        "label": "Universal Health Realty Income Trust",
        "price": 120.86
    }, {
        "symbol": "IESC",
        "label": "IES Holdings Inc.",
        "price": 26.32
    }, {
        "symbol": "LITB",
        "label": "LightInTheBox Holding Co. Ltd. American Depositary Shares each representing 2",
        "price": 1.07
    }, {
        "symbol": "RNET",
        "label": "RigNet Inc.",
        "price": 6.08
    }, {
        "symbol": "SCHP",
        "label": "Schwab U.S. TIPs",
        "price": 56.9
    }, {
        "symbol": "SMBC",
        "label": "Southern Missouri Bancorp Inc.",
        "price": 37.44
    }, {
        "symbol": "VCTR",
        "label": "Victory Capital Holdings Inc.",
        "price": 21.35
    }, {
        "symbol": "ZTR",
        "label": "Virtus Global Dividend & Income Fund Inc.",
        "price": 11.48
    }, {
        "symbol": "JHMM",
        "label": "John Hancock Multifactor Mid Cap",
        "price": 40.22
    }, {
        "symbol": "VTEB",
        "label": "Vanguard Tax-Exempt Bond",
        "price": 52.89
    }, {
        "symbol": "JPIN",
        "label": "JPMorgan Diversified Return International Equity",
        "price": 53.53
    }, {
        "symbol": "UBIO",
        "label": "Proshares UltraPro Nasdaq Biotechnology",
        "price": 33.5785
    }, {
        "symbol": "MSFG",
        "label": "MainSource Financial Group Inc.",
        "price": 40.65
    }, {
        "symbol": "LMFA",
        "label": "LM Funding America Inc.",
        "price": 0.7726
    }, {
        "symbol": "IJS",
        "label": "iShares S&P SmallCap 600 Value",
        "price": 161.58
    }, {
        "symbol": "KAI",
        "label": "Kadant Inc",
        "price": 112.5
    }, {
        "symbol": "PRQR",
        "label": "ProQR Therapeutics N.V.",
        "price": 8.04
    }, {
        "symbol": "UBA",
        "label": "Urstadt Biddle Properties Inc.",
        "price": 24.19
    }, {
        "symbol": "BREW",
        "label": "Craft Brew Alliance Inc.",
        "price": 16.42
    }, {
        "symbol": "PERY",
        "label": "Perry Ellis International Inc.",
        "price": 27.5
    }, {
        "symbol": "ORM",
        "label": "Owens Realty Mortgage Inc.",
        "price": 21.12
    }, {
        "symbol": "FCBC",
        "label": "First Community Bancshares Inc.",
        "price": 30.69
    }, {
        "symbol": "VDC",
        "label": "Vanguard Consumer Staples",
        "price": 163.11
    }, {
        "symbol": "MHO",
        "label": "M/I Homes Inc.",
        "price": 45.16
    }, {
        "symbol": "UTMD",
        "label": "Utah Medical Products Inc.",
        "price": 105.24
    }, {
        "symbol": "PCOM",
        "label": "Points International Ltd.",
        "price": 15.04
    }, {
        "symbol": "HDG",
        "label": "ProShares Hedge Replication",
        "price": 44.53
    }, {
        "symbol": "FEMS",
        "label": "First Trust Emerging Markets Small Cap AlphaDEX Fund",
        "price": 40.26
    }, {
        "symbol": "SPTM",
        "label": "SPDR Portfolio Total Stock Market",
        "price": 41.07
    }, {
        "symbol": "VCR",
        "label": "Vanguard Consumer Discretion",
        "price": 194.08
    }, {
        "symbol": "ATNI",
        "label": "ATN International Inc.",
        "price": 56.41
    }, {
        "symbol": "FNDA",
        "label": "Schwab Fundamental U.S. Small Company Index",
        "price": 36.54
    }, {
        "symbol": "GMF",
        "label": "SPDR S&P Emerging Asia Pacific",
        "price": 94.2
    }, {
        "symbol": "INF",
        "label": "Brookfield Global Listed Infrastructure Income Fund Closed End Fund",
        "price": 14.63
    }, {
        "symbol": "MDYG",
        "label": "SPDR S&P 400 Mid Cap Growth ETF (based on S&P MidCap 400 Growth Index--symbol: M" +
                "UV)",
        "price": 58.31
    }, {
        "symbol": "SCM",
        "label": "Stellus Capital Investment Corporation",
        "price": 14.35
    }, {
        "symbol": "AGC",
        "label": "Advent Claymore Convertible Securities and Income Fund of Beneficial Interest",
        "price": 5.69
    }, {
        "symbol": "AIRG",
        "label": "Airgain Inc.",
        "price": 10.34
    }, {
        "symbol": "BBC",
        "label": "Virtus LifeSci Biotech Clinical Trials",
        "price": 38.2126
    }, {
        "symbol": "BFOR",
        "label": "Barron's 400",
        "price": 38.73
    }, {
        "symbol": "BGH",
        "label": "Barings Global Short Duration High Yield Fund of Beneficial Interests",
        "price": 18.21
    }, {
        "symbol": "BGR",
        "label": "BlackRock Energy and Resources Trust",
        "price": 11.98
    }, {
        "symbol": "BGX",
        "label": "Blackstone GSO Long Short Credit Income Fund",
        "price": 15.89
    }, {
        "symbol": "BOND",
        "label": "PIMCO Active Bond Exchange-Traded Fund Exchange-Traded Fund",
        "price": 108.63
    }, {
        "symbol": "BOSC",
        "label": "B.O.S. Better Online Solutions",
        "price": 2.25
    }, {
        "symbol": "BSJM",
        "label": "Guggenheim BulletShares 2022 High Yield Corporate Bond",
        "price": 24.29
    }, {
        "symbol": "BUI",
        "label": "BlackRock Utility Infrastructure & Power Opportunities Trust",
        "price": 22.83
    }, {
        "symbol": "BWINB",
        "label": "Baldwin & Lyons Inc. Class B (nonvoting) Common Stock",
        "price": 23.35
    }, {
        "symbol": "CATB",
        "label": "Catabasis Pharmaceuticals Inc.",
        "price": 7.0
    }, {
        "symbol": "CDLX",
        "label": "Cardlytics Inc.",
        "price": 83.5
    }, {
        "symbol": "CEE",
        "label": "The Central and Eastern Europe Fund Inc. (The)",
        "price": 28.8875
    }, {
        "symbol": "CHFN",
        "label": "Charter Financial Corp.",
        "price": 24.89
    }, {
        "symbol": "CNTY",
        "label": "Century Casinos Inc.",
        "price": 8.2
    }, {
        "symbol": "DAIO",
        "label": "Data I/O Corporation",
        "price": 4.16
    }, {
        "symbol": "DES",
        "label": "WisdomTree U.S. SmallCap Dividend Fund",
        "price": 28.77
    }, {
        "symbol": "EDGW",
        "label": "Edgewater Technology Inc.",
        "price": 4.71
    }, {
        "symbol": "EDIV",
        "label": "SPDR S&P Emerging Markets Dividend",
        "price": 31.47
    }, {
        "symbol": "EFF",
        "label": "Eaton vance Floating-Rate Income Plus Fund of Beneficial Interest",
        "price": 16.03
    }, {
        "symbol": "EFU",
        "label": "ProShares UltraShort MSCI EAFE",
        "price": 25.26
    }, {
        "symbol": "EGPT",
        "label": "VanEck Vectors Egypt Index",
        "price": 30.26
    }, {
        "symbol": "EOLS",
        "label": "Evolus Inc.",
        "price": 11.29
    }, {
        "symbol": "ERC",
        "label": "Wells Fargo Multi-Sector Income Fund no par value",
        "price": 12.83
    }, {
        "symbol": "ETB",
        "label": "Eaton Vance Tax-Managed Buy-Write Income Fund of Beneficial Interest",
        "price": 16.76
    }, {
        "symbol": "FIV",
        "label": "First Trust Senior Floating Rate 2022 Target Term Fund of Beneficial Interest",
        "price": 9.27
    }, {
        "symbol": "FRSH",
        "label": "Papa Murphy's Holdings Inc.",
        "price": 6.45
    }, {
        "symbol": "GECC",
        "label": "Great Elm Capital Corp.",
        "price": 7.97
    }, {
        "symbol": "HEAR",
        "label": "Turtle Beach Corporation",
        "price": 9.18
    }, {
        "symbol": "HYI",
        "label": "Western Asset High Yield Defined Opportunity Fund Inc.",
        "price": 15.65
    }, {
        "symbol": "IHDG",
        "label": "WisdomTree International Hedged Quality Dividend Growth Fund",
        "price": 37.5
    }, {
        "symbol": "IHI",
        "label": "iShares U.S. Medical Devices",
        "price": 273.46
    }, {
        "symbol": "IOR",
        "label": "Income Opportunity Realty Investors Inc.",
        "price": 13.02
    }, {
        "symbol": "IVH",
        "label": "Ivy High Income Opportunities Fund of Beneficial Interest",
        "price": 13.99
    }, {
        "symbol": "IYM",
        "label": "iShares U.S. Basic Materials",
        "price": 96.85
    }, {
        "symbol": "KEMQ",
        "label": "KraneShares Emerging Markets Consumer Technology Index",
        "price": 20.15
    }, {
        "symbol": "KREF",
        "label": "KKR Real Estate Finance Trust Inc.",
        "price": 20.92
    }, {
        "symbol": "MAV",
        "label": "Pioneer Municipal High Income Advantage Trust of Beneficial Interest",
        "price": 11.02
    }, {
        "symbol": "MCN",
        "label": "Madison Covered Call & Equity Strategy Fund",
        "price": 6.71
    }, {
        "symbol": "MLPX",
        "label": "Global X MLP & Energy Infrastructure",
        "price": 12.51
    }, {
        "symbol": "MRCC",
        "label": "Monroe Capital Corporation",
        "price": 11.76
    }, {
        "symbol": "MTNB",
        "label": "Matinas Biopharma Holdings Inc.",
        "price": 1.49
    }, {
        "symbol": "MTSC",
        "label": "MTS Systems Corporation",
        "price": 51.67
    }, {
        "symbol": "MUS",
        "label": "Blackrock MuniHoldings Quality Fund Inc",
        "price": 12.81
    }, {
        "symbol": "MYN",
        "label": "Blackrock MuniYield New York Quality Fund Inc.Common Stock",
        "price": 13.19
    }, {
        "symbol": "NEV",
        "label": "Nuveen Enhanced Municipal Value Fund of Beneficial Interest",
        "price": 15.15
    }, {
        "symbol": "NPK",
        "label": "National Presto Industries Inc.",
        "price": 89.6
    }, {
        "symbol": "PFO",
        "label": "Flaherty & Crumrine Preferred Income Opportunity Fund Incorporated",
        "price": 12.6401
    }, {
        "symbol": "PGH",
        "label": "Pengrowth Energy Corporation",
        "price": 0.74
    }, {
        "symbol": "PMX",
        "label": "PIMCO Municipal Income Fund III of Beneficial Interest",
        "price": 12.46
    }, {
        "symbol": "POWL",
        "label": "Powell Industries Inc.",
        "price": 46.69
    }, {
        "symbol": "PSCE",
        "label": "PowerShares S&P SmallCap Energy Portfolio",
        "price": 6.78
    }, {
        "symbol": "PWOD",
        "label": "Penns Woods Bancorp Inc.",
        "price": 33.03
    }, {
        "symbol": "PXQ",
        "label": "PowerShares Dynamic Networking",
        "price": 56.22
    }, {
        "symbol": "RSLS",
        "label": "ReShape Lifesciences Inc.",
        "price": 0.25
    }, {
        "symbol": "SEED",
        "label": "Origin Agritech Limited",
        "price": 9.38
    }, {
        "symbol": "SGDJ",
        "label": "ALPS ETF Trust Sprott Junior Gold Miners",
        "price": 24.21
    }, {
        "symbol": "SGF",
        "label": "Aberdeen Singapore Fund Inc.",
        "price": 13.59
    }, {
        "symbol": "SHYD",
        "label": "VanEck Vectors Short High-Yield Municipal Index",
        "price": 24.95
    }, {
        "symbol": "SNMX",
        "label": "Senomyx Inc.",
        "price": 1.5
    }, {
        "symbol": "SOJC",
        "label": "Southern Company (The) Series 2017B 5.25% Junior Subordinated Notes due December" +
                " 1 2077",
        "price": 26.4164
    }, {
        "symbol": "STON",
        "label": "StoneMor Partners L.P. Rep Limited Partnership Interests",
        "price": 1.369
    }, {
        "symbol": "SXE",
        "label": "Southcross Energy Partners L.P.",
        "price": 0.31
    }, {
        "symbol": "TAT",
        "label": "TransAtlantic Petroleum Ltd (Bermuda)",
        "price": 0.401
    }, {
        "symbol": "TGLS",
        "label": "Tecnoglass Inc.",
        "price": 7.87
    }, {
        "symbol": "TITN",
        "label": "Titan Machinery Inc.",
        "price": 13.89
    }, {
        "symbol": "TLYS",
        "label": "Tilly's Inc.",
        "price": 8.56
    }, {
        "symbol": "UQM",
        "label": "UQM Technologies Inc",
        "price": 1.67
    }, {
        "symbol": "VDE",
        "label": "Vanguard Energy",
        "price": 79.92
    }, {
        "symbol": "VXZ",
        "label": "iPath S&P 500 VIX Mid-Term Futures ETN",
        "price": 18.93
    }, {
        "symbol": "WRLSU",
        "label": "Pensare Acquisition Corp. Unit",
        "price": 10.6
    }, {
        "symbol": "ZIONW",
        "label": "Zions Bancorporation Warrants 05/21/2020",
        "price": 18.4
    }, {
        "symbol": "ZROZ",
        "label": "PIMCO 25 Year Zero Coupon U.S. Treasury Index Exchange-Traded Fund",
        "price": 137.26
    }, {
        "symbol": "ZX",
        "label": "China Zenix Auto International Limited American Depositary Shares each represent" +
                "ing four.",
        "price": 1.39
    }, {
        "symbol": "ZYNE",
        "label": "Zynerba Pharmaceuticals Inc.",
        "price": 5.7
    }, {
        "symbol": "CTG",
        "label": "Computer Task Group Incorporated",
        "price": 5.89
    }, {
        "symbol": "PCYO",
        "label": "Pure Cycle Corporation",
        "price": 12.59
    }, {
        "symbol": "SNOA",
        "label": "Sonoma Pharmaceuticals Inc.",
        "price": 5.05
    }, {
        "symbol": "DXYN",
        "label": "The Dixie Group Inc.",
        "price": 1.37
    }, {
        "symbol": "IJJ",
        "label": "iShares S&P Mid-Cap 400 Value",
        "price": 172.57
    }, {
        "symbol": "CYRX",
        "label": "CryoPort Inc.",
        "price": 19.27
    }, {
        "symbol": "COKE",
        "label": "Coca-Cola Bottling Co. Consolidated",
        "price": 279.3
    }, {
        "symbol": "FDVV",
        "label": "Fidelity High Dividend",
        "price": 28.79
    }, {
        "symbol": "GSL",
        "label": "Global Ship Lease Inc New Class A",
        "price": 8.39
    }, {
        "symbol": "ELD",
        "label": "WisdomTree Emerging Markets Local Debt Fund",
        "price": 33.84
    }, {
        "symbol": "DGP",
        "label": "DB Gold Double Long ETN due February 15 2038",
        "price": 22.67
    }, {
        "symbol": "KCAP",
        "label": "KCAP Financial Inc.",
        "price": 3.78
    }, {
        "symbol": "TRC",
        "label": "Tejon Ranch Co",
        "price": 16.17
    }, {
        "symbol": "KNDI",
        "label": "Kandi Technologies Group Inc.",
        "price": 4.72
    }, {
        "symbol": "FTSL",
        "label": "First Trust Senior Loan Fund ETF",
        "price": 48.05
    }, {
        "symbol": "BRZU",
        "label": "Direxion Daily Brazil Bull 3X Shares",
        "price": 28.6
    }, {
        "symbol": "PGJ",
        "label": "PowerShares Golden Dragon China Portfolio",
        "price": 45.57
    }, {
        "symbol": "SPTL",
        "label": "SPDR Portfolio Long Term Treasury",
        "price": 37.49
    }, {
        "symbol": "FBNK",
        "label": "First Connecticut Bancorp Inc.",
        "price": 29.03
    }, {
        "symbol": "FNI",
        "label": "First Trust Chindia",
        "price": 34.98
    }, {
        "symbol": "MOO",
        "label": "VanEck Vectors Agribusiness",
        "price": 69.12
    }, {
        "symbol": "USDU",
        "label": "WisdomTree Bloomberg U.S. Dollar Bullish Fund",
        "price": 27.72
    }, {
        "symbol": "WHLR",
        "label": "Wheeler Real Estate Investment Trust Inc.",
        "price": 1.79
    }, {
        "symbol": "NSPR",
        "label": "InspireMD Inc.",
        "price": 1.36
    }, {
        "symbol": "UWM",
        "label": "ProShares Ultra Russell2000",
        "price": 63.67
    }, {
        "symbol": "DXR",
        "label": "Daxor Corporation",
        "price": 9.55
    }, {
        "symbol": "RDHL",
        "label": "Redhill Biopharma Ltd.",
        "price": 6.13
    }, {
        "symbol": "PFSW",
        "label": "PFSweb Inc.",
        "price": 4.71
    }, {
        "symbol": "ARKK",
        "label": "ARK Innovation",
        "price": 53.67
    }, {
        "symbol": "MUJ",
        "label": "Blackrock MuniHoldings New Jersey Quality Fund Inc.",
        "price": 14.62
    }, {
        "symbol": "HYEM",
        "label": "VanEck Vectors Emerging Markets High Yield Bond",
        "price": 24.31
    }, {
        "symbol": "GRIF",
        "label": "Griffin Industrial Realty Inc.",
        "price": 40.02
    }, {
        "symbol": "SLX",
        "label": "VanEck Vectors Steel",
        "price": 35.98
    }, {
        "symbol": "CLAR",
        "label": "Clarus Corporation",
        "price": 13.735
    }, {
        "symbol": "MNA",
        "label": "IQ Merger Arbitrage",
        "price": 33.39
    }, {
        "symbol": "PLOW",
        "label": "Douglas Dynamics Inc.",
        "price": 55.67
    }, {
        "symbol": "TWIN",
        "label": "Twin Disc Incorporated",
        "price": 10.56
    }, {
        "symbol": "TNTR",
        "label": "Tintri Inc.",
        "price": 0.15
    }, {
        "symbol": "SYNL",
        "label": "Synalloy Corporation",
        "price": 12.85
    }, {
        "symbol": "CCLP",
        "label": "CSI Compressco LP",
        "price": 2.37
    }, {
        "symbol": "RNP",
        "label": "Cohen & Steers Reit and Preferred Income Fund Inc",
        "price": 23.92
    }, {
        "symbol": "DMO",
        "label": "Western Asset Mortgage Defined Opportunity Fund Inc",
        "price": 21.01
    }, {
        "symbol": "KRMA",
        "label": "Global X Conscious Companies ETF",
        "price": 24.2244
    }, {
        "symbol": "FNWB",
        "label": "First Northwest Bancorp",
        "price": 17.46
    }, {
        "symbol": "JTD",
        "label": "Nuveen Tax-Advantaged Dividend Growth Fund of Beneficial Interest",
        "price": 17.98
    }, {
        "symbol": "TRT",
        "label": "Trio-Tech International",
        "price": 5.2915
    }, {
        "symbol": "LFGR",
        "label": "Leaf Group Ltd.",
        "price": 11.1
    }, {
        "symbol": "TSBK",
        "label": "Timberland Bancorp Inc.",
        "price": 26.74
    }, {
        "symbol": "RFFC",
        "label": "RiverFront Dynamic US Flex-Cap",
        "price": 31.25
    }, {
        "symbol": "SPHS",
        "label": "Sophiris Bio Inc.",
        "price": 0.6558
    }, {
        "symbol": "AFAM",
        "label": "Almost Family Inc",
        "price": 56.25
    }, {
        "symbol": "NGHCN",
        "label": "National General Holdings Corp Depositary Shares each representing 1/40th of a s" +
                "hare of 7.50% Non-Cumulative Preferred Stock Series C",
        "price": 25.62
    }, {
        "symbol": "VPG",
        "label": "Vishay Precision Group Inc.",
        "price": 35.7
    }, {
        "symbol": "BGT",
        "label": "BlackRock Floating Rate Income Trust",
        "price": 13.09
    }, {
        "symbol": "NHC",
        "label": "National HealthCare Corporation",
        "price": 87.1
    }, {
        "symbol": "PKB",
        "label": "PowerShares Dynamic Build & Construction",
        "price": 29.81
    }, {
        "symbol": "REET",
        "label": "iShares Trust Global REIT",
        "price": 26.8
    }, {
        "symbol": "EMCB",
        "label": "WisdomTree Emerging Markets Corporate Bond Fund",
        "price": 74.435
    }, {
        "symbol": "RYT",
        "label": "Guggenheim S&P 500 Equal Weight Technology",
        "price": 206.88
    }, {
        "symbol": "INFI",
        "label": "Infinity Pharmaceuticals Inc.",
        "price": 1.17
    }, {
        "symbol": "MGU",
        "label": "Macquarie Global Infrastructure Total Return Fund Inc.",
        "price": 26.27
    }, {
        "symbol": "VSEC",
        "label": "VSE Corporation",
        "price": 34.5
    }, {
        "symbol": "MGEE",
        "label": "MGE Energy Inc.",
        "price": 79.32
    }, {
        "symbol": "MPCT",
        "label": "iShares MSCI Global Impact ETF",
        "price": 54.66
    }, {
        "symbol": "CVCO",
        "label": "Cavco Industries Inc.",
        "price": 213.11
    }, {
        "symbol": "FGD",
        "label": "First Trust DJ Global Select Dividend",
        "price": 25.1
    }, {
        "symbol": "CAPL",
        "label": "CrossAmerica Partners LP representing limited partner interests",
        "price": 18.72
    }, {
        "symbol": "VOX",
        "label": "Vanguard Telecom",
        "price": 82.96
    }, {
        "symbol": "SP",
        "label": "SP Plus Corporation",
        "price": 41.11
    }, {
        "symbol": "STBA",
        "label": "S&T Bancorp Inc.",
        "price": 39.99
    }, {
        "symbol": "MSN",
        "label": "Emerson Radio Corporation",
        "price": 0.86
    }, {
        "symbol": "TBNK",
        "label": "Territorial Bancorp Inc.",
        "price": 30.61
    }, {
        "symbol": "BFIN",
        "label": "BankFinancial Corporation",
        "price": 12.7
    }, {
        "symbol": "NUEM",
        "label": "NuShares ESG Emerging Markets Equity",
        "price": 24.82
    }, {
        "symbol": "PJP",
        "label": "PowerShares Dynamic Pharmaceuticals",
        "price": 58.84
    }, {
        "symbol": "BSPM",
        "label": "Biostar Pharmaceuticals Inc.",
        "price": 1.77
    }, {
        "symbol": "DKL",
        "label": "Delek Logistics Partners L.P. representing Limited Partner Interests",
        "price": 32.88
    }, {
        "symbol": "SLY",
        "label": "SPDR S&P 600 Small Cap ETF (based on S&P SmallCap 600 Index -- symbol SML)",
        "price": 73.83
    }, {
        "symbol": "SPFF",
        "label": "Global X SuperIncome Preferred",
        "price": 11.54
    }, {
        "symbol": "ABAC",
        "label": "Renmin Tianli Group Inc.",
        "price": 1.42
    }, {
        "symbol": "ACGLP",
        "label": "Arch Capital Group Ltd. Depositary Shares Representing Interest in 5.25% Non-Cum" +
                "ulative Preferred Series E Shrs",
        "price": 25.48
    }, {
        "symbol": "ACH",
        "label": "Aluminum Corporation of China Limited American Depositary Shares",
        "price": 8.48
    }, {
        "symbol": "AKP",
        "label": "Alliance California Municipal Income Fund Inc",
        "price": 14.84
    }, {
        "symbol": "ALG",
        "label": "Alamo Group Inc.",
        "price": 130.73
    }, {
        "symbol": "AMBR",
        "label": "Amber Road Inc.",
        "price": 13.0
    }, {
        "symbol": "AMCN",
        "label": "AirMedia Group Inc",
        "price": 1.7
    }, {
        "symbol": "ANFI",
        "label": "Amira Nature Foods Ltd",
        "price": 0.3951
    }, {
        "symbol": "ARKQ",
        "label": "ARK Industrial Innovation",
        "price": 29.82
    }, {
        "symbol": "ARKR",
        "label": "Ark Restaurants Corp.",
        "price": 22.24
    }, {
        "symbol": "ATOS",
        "label": "Atossa Genetics Inc.",
        "price": 1.52
    }, {
        "symbol": "AXU",
        "label": "Alexco Resource Corp (Canada)",
        "price": 1.85
    }, {
        "symbol": "BBH",
        "label": "VanEck Vectors Biotech ETF",
        "price": 140.38
    }, {
        "symbol": "BIOL",
        "label": "Biolase Inc.",
        "price": 0.8
    }, {
        "symbol": "BLW",
        "label": "Blackrock Limited Duration Income Trust",
        "price": 16.53
    }, {
        "symbol": "BSCJ",
        "label": "Guggenheim BulletShares 2019 Corporate Bond",
        "price": 21.1
    }, {
        "symbol": "BSCL",
        "label": "Guggenheim BulletShares 2021 Corporate Bond",
        "price": 21.16
    }, {
        "symbol": "BSL",
        "label": "Blackstone GSO Senior Floating Rate Term Fund of Beneficial Interest",
        "price": 16.26
    }, {
        "symbol": "BTT",
        "label": "BlackRock Municipal 2030 Target Term Trust",
        "price": 24.4
    }, {
        "symbol": "CBTX",
        "label": "CBTX Inc.",
        "price": 31.23
    }, {
        "symbol": "CET",
        "label": "Central Securities Corporation",
        "price": 33.75
    }, {
        "symbol": "CGNT",
        "label": "Cogentix Medical Inc.",
        "price": 3.84
    }, {
        "symbol": "CIGI",
        "label": "Colliers International Group Inc.",
        "price": 83.74
    }, {
        "symbol": "CRBN",
        "label": "iShares MSCI ACWI Low Carbon Target",
        "price": 114.1
    }, {
        "symbol": "CREG",
        "label": "China Recycling Energy Corporation",
        "price": 0.3688
    }, {
        "symbol": "CVON",
        "label": "ConvergeOne Holdings Inc.",
        "price": 12.44
    }, {
        "symbol": "DNN",
        "label": "Denison Mines Corp (Canada)",
        "price": 0.396637
    }, {
        "symbol": "DOL",
        "label": "WisdomTree International LargeCap Dividend Fund",
        "price": 49.3747
    }, {
        "symbol": "DTW",
        "label": "DTE Energy Company 2017 Series E 5.25% Junior Subordinated Debentures due 2077",
        "price": 26.91
    }, {
        "symbol": "DUC",
        "label": "Duff & Phelps Utility & Corporate Bond Trust Inc.",
        "price": 9.13
    }, {
        "symbol": "DUKH",
        "label": "Duke Energy Corporation 5.125% Junior Subordinated Debentures due 2073",
        "price": 25.52
    }, {
        "symbol": "DVP",
        "label": "Deep Value",
        "price": 32.7691
    }, {
        "symbol": "DXJF",
        "label": "WisdomTree Japan Hedged Financials Fund",
        "price": 20.87
    }, {
        "symbol": "EUSC",
        "label": "WisdomTree Europe Hedged SmallCap Equity Fund",
        "price": 33.3415
    }, {
        "symbol": "EVJ",
        "label": "Eaton Vance New Jersey Municipal Income Trust Shares of Beneficial Interest",
        "price": 12.09
    }, {
        "symbol": "GENC",
        "label": "Gencor Industries Inc.",
        "price": 11.13
    }, {
        "symbol": "GNMA",
        "label": "iShares GNMA Bond ETF",
        "price": 50.3
    }, {
        "symbol": "GRX",
        "label": "The Gabelli Healthcare & Wellness Trust of Beneficial Interest",
        "price": 11.6749
    }, {
        "symbol": "GVI",
        "label": "iShares Intermediate Government/Credit Bond",
        "price": 111.48
    }, {
        "symbol": "HCOM",
        "label": "Hawaiian Telcom Holdco Inc.",
        "price": 28.55
    }, {
        "symbol": "IAE",
        "label": "Voya Asia Pacific High Dividend Equity Income Fund ING Asia Pacific High Dividen" +
                "d Equity Income Fund of Beneficial Interest",
        "price": 8.96
    }, {
        "symbol": "IBDQ",
        "label": "iShares iBonds Dec 2025 Term Corporate",
        "price": 25.16
    }, {
        "symbol": "ICF",
        "label": "iShares Cohen & Steers REIT",
        "price": 112.8
    }, {
        "symbol": "IOSP",
        "label": "Innospec Inc.",
        "price": 106.08
    }, {
        "symbol": "IOTS",
        "label": "Adesto Technologies Corporation",
        "price": 8.54
    }, {
        "symbol": "IPCC",
        "label": "Infinity Property and Casualty Corporation",
        "price": 142.63
    }, {
        "symbol": "JCE",
        "label": "Nuveen Core Equity Alpha Fund of Beneficial Interest",
        "price": 14.85
    }, {
        "symbol": "JCO",
        "label": "Nuveen Credit Opportunities 2022 Target Term Fund of Beneficial Interest",
        "price": 10.05
    }, {
        "symbol": "JMLP",
        "label": "Nuveen All Cap Energy MLP Opportunities Fund of Beneficial Interest",
        "price": 5.37
    }, {
        "symbol": "JNP",
        "label": "Juniper Pharmaceuticals Inc.",
        "price": 11.48
    }, {
        "symbol": "KLDW",
        "label": "Knowledge Leaders Developed World",
        "price": 32.53
    }, {
        "symbol": "MFM",
        "label": "MFS Municipal Income Trust",
        "price": 7.16
    }, {
        "symbol": "MNOV",
        "label": "MediciNova Inc.",
        "price": 6.97
    }, {
        "symbol": "MQY",
        "label": "Blackrock MuniYield Quality Fund Inc.",
        "price": 15.2564
    }, {
        "symbol": "MYI",
        "label": "Blackrock MuniYield Quality Fund III Inc",
        "price": 13.77
    }, {
        "symbol": "NURO",
        "label": "NeuroMetrix Inc.",
        "price": 4.26
    }, {
        "symbol": "NVEC",
        "label": "NVE Corporation",
        "price": 72.06
    }, {
        "symbol": "OVID",
        "label": "Ovid Therapeutics Inc.",
        "price": 3.96
    }, {
        "symbol": "OXFD",
        "label": "Oxford Immunotec Global PLC",
        "price": 16.15
    }, {
        "symbol": "PBIP",
        "label": "Prudential Bancorp Inc.",
        "price": 17.61
    }, {
        "symbol": "PCTI",
        "label": "PC-Tel Inc.",
        "price": 8.88
    }, {
        "symbol": "PFNX",
        "label": "Pfenex Inc.",
        "price": 12.3
    }, {
        "symbol": "PHDG",
        "label": "PowerShares S&P 500 Downside Hedged Portfolio",
        "price": 27.86
    }, {
        "symbol": "PLM",
        "label": "Polymet Mining Corporation (Canada)",
        "price": 0.2984
    }, {
        "symbol": "PLSE",
        "label": "Pulse Biosciences Inc",
        "price": 14.09
    }, {
        "symbol": "PMM",
        "label": "Putnam Managed Municipal Income Trust",
        "price": 8.17
    }, {
        "symbol": "PPR",
        "label": "Voya Prime Rate Trust Shares of Beneficial Interest",
        "price": 5.09
    }, {
        "symbol": "RBCAA",
        "label": "Republic Bancorp Inc. Class A Common Stock",
        "price": 45.64
    }, {
        "symbol": "RVP",
        "label": "Retractable Technologies Inc.",
        "price": 1.6
    }, {
        "symbol": "RXII",
        "label": "RXi Pharmaceuticals Corporation",
        "price": 0.33
    }, {
        "symbol": "SAFE",
        "label": "Safety Income & Growth Inc.",
        "price": 42.01
    }, {
        "symbol": "SAL",
        "label": "Salisbury Bancorp Inc.",
        "price": 43.55
    }, {
        "symbol": "SBPH",
        "label": "Spring Bank Pharmaceuticals Inc.",
        "price": 1.84
    }, {
        "symbol": "SIL",
        "label": "Global X Silver Miners",
        "price": 23.86
    }, {
        "symbol": "SLQD",
        "label": "iShares 0-5 Year Investment Grade Corporate Bond ETF",
        "price": 51.165
    }, {
        "symbol": "SRAX",
        "label": "Social Reality Inc.",
        "price": 2.415
    }, {
        "symbol": "SSI",
        "label": "Stage Stores Inc.",
        "price": 3.55
    }, {
        "symbol": "SUNS",
        "label": "Solar Senior Capital Ltd.",
        "price": 17.91
    }, {
        "symbol": "UWN",
        "label": "Nevada Gold & Casinos Inc.",
        "price": 2.49
    }, {
        "symbol": "VET",
        "label": "Vermilion Energy Inc. Common (Canada)",
        "price": 16.29
    }, {
        "symbol": "VSMV",
        "label": "VictoryShares US Multi-Factor Minimum Volatility ETF",
        "price": 33.79
    }, {
        "symbol": "WF",
        "label": "Woori Bank American Depositary Shares (Each representing 3 shares of)",
        "price": 27.71
    }, {
        "symbol": "XRF",
        "label": "China Rapid Finance Limited American Depositary Shares each representing one Cla" +
                "ss A",
        "price": 2.28
    }, {
        "symbol": "XRM",
        "label": "Xerium Technologies Inc.",
        "price": 13.5
    }, {
        "symbol": "JHI",
        "label": "John Hancock Investors Trust",
        "price": 17.72
    }, {
        "symbol": "JP",
        "label": "Jupai Holdings Limited American Depositary Shares each representing six",
        "price": 1.66
    }, {
        "symbol": "IDX",
        "label": "VanEck Vectors Indonesia Index",
        "price": 23.26
    }, {
        "symbol": "ARKW",
        "label": "ARK Web x.0",
        "price": 63.17
    }, {
        "symbol": "FCVT",
        "label": "First Trust SSI Strategic Convertible Securities ETF",
        "price": 34.27
    }, {
        "symbol": "IBDM",
        "label": "iShares iBonds Dec 2021 Term Corporate",
        "price": 24.82
    }, {
        "symbol": "PFL",
        "label": "PIMCO Income Strategy Fund Shares of Beneficial Interest",
        "price": 12.02
    }, {
        "symbol": "FIDU",
        "label": "Fidelity MSCI Industrials Index",
        "price": 38.32
    }, {
        "symbol": "QQQC",
        "label": "Global X NASDAQ China Technology ETF",
        "price": 24.97
    }, {
        "symbol": "JPNL",
        "label": "Direxion Daily Japan Bull 3X",
        "price": 53.04
    }, {
        "symbol": "BCH",
        "label": "Banco De Chile ADS",
        "price": 21.28
    }, {
        "symbol": "IX",
        "label": "Orix Corp Ads",
        "price": 84.89
    }, {
        "symbol": "PBE",
        "label": "PowerShares Dynamic Biotech &Genome",
        "price": 50.24
    }, {
        "symbol": "FXU",
        "label": "First Trust Utilities AlphaDEX Fund",
        "price": 29.67
    }, {
        "symbol": "USRT",
        "label": "iShares Core U.S. REIT",
        "price": 52.27
    }, {
        "symbol": "GNR",
        "label": "SPDR S&P Global Natural Resources",
        "price": 42.73
    }, {
        "symbol": "TLP",
        "label": "TransMontaigne Partners L.P. Transmontaigne Partners L.P. representing limited p" +
                "artner interests",
        "price": 41.0
    }, {
        "symbol": "SNGX",
        "label": "Soligenix Inc.",
        "price": 2.09
    }, {
        "symbol": "NINE",
        "label": "Nine Energy Service Inc.",
        "price": 7.25
    }, {
        "symbol": "VPL",
        "label": "Vanguard FTSE Pacific",
        "price": 64.14
    }, {
        "symbol": "MDYV",
        "label": "SPDR S&P 400 Mid Cap Value ETF (based on S&P MidCap 400 Value Index--symbol: MGD",
        "price": 55.3
    }, {
        "symbol": "SGG",
        "label": "iPath Bloomberg Sugar Subindex Total Return Sm Index ETN",
        "price": 44.84
    }, {
        "symbol": "CSBR",
        "label": "Champions Oncology Inc.",
        "price": 8.4
    }, {
        "symbol": "PXSG",
        "label": "PowerShares Russell 2000 Pure Growth Portfolio",
        "price": 35.42
    }, {
        "symbol": "SPNS",
        "label": "Sapiens International Corporation N.V.",
        "price": 25.22
    }, {
        "symbol": "LBJ",
        "label": "Direxion Daily Latin America 3x Bull Shares",
        "price": 20.75
    }, {
        "symbol": "GF",
        "label": "New Germany Fund Inc. (The)",
        "price": 15.87
    }, {
        "symbol": "FF",
        "label": "FutureFuel Corp.",
        "price": 11.72
    }, {
        "symbol": "FIHD",
        "label": "UBS AG FI Enhanced Global High Yield ETN due March 3 2026",
        "price": 167.85
    }, {
        "symbol": "MBTF",
        "label": "M B T Financial Corp",
        "price": 9.58
    }, {
        "symbol": "UGA",
        "label": "United States Gasoline Fund LP",
        "price": 27.28
    }, {
        "symbol": "IMTM",
        "label": "iShares Edge MSCI Intl Momentum Factor",
        "price": 28.84
    }, {
        "symbol": "HEB",
        "label": "Hemispherx BioPharma Inc.",
        "price": 0.11
    }, {
        "symbol": "AVGR",
        "label": "Avinger Inc.",
        "price": 1.14
    }, {
        "symbol": "ACMR",
        "label": "ACM Research Inc.",
        "price": 36.09
    }, {
        "symbol": "GCH",
        "label": "Aberdeen Greater China Fund Inc.",
        "price": 12.3
    }, {
        "symbol": "GWRS",
        "label": "Global Water Resources Inc.",
        "price": 12.6
    }, {
        "symbol": "PZE",
        "label": "Petrobras Argentina S.A. ADS",
        "price": 10.77
    }, {
        "symbol": "PRK",
        "label": "Park National Corporation",
        "price": 101.91
    }, {
        "symbol": "SLYV",
        "label": "SPDR S&P 600 Small Cap Value ETF (based on S&P SmallCap Value Index--symbol--CVK",
        "price": 66.01
    }, {
        "symbol": "PCF",
        "label": "Putnam High Income Securities Fund",
        "price": 9.36
    }, {
        "symbol": "PFBI",
        "label": "Premier Financial Bancorp Inc.",
        "price": 17.38
    }, {
        "symbol": "CCBG",
        "label": "Capital City Bank Group",
        "price": 29.04
    }, {
        "symbol": "NYNY",
        "label": "Empire Resorts Inc.",
        "price": 9.75
    }, {
        "symbol": "GOVNI",
        "label": "Government Properties Income Trust 5.875% Senior Notes due 2046",
        "price": 22.8
    }, {
        "symbol": "DBV",
        "label": "PowerShares DB G10 Currency Harvest",
        "price": 24.18
    }, {
        "symbol": "SSWN",
        "label": "Seaspan Corporation 6.375% Notes due 2019",
        "price": 24.99
    }, {
        "symbol": "PPLT",
        "label": "ETFS Physical Platinum Shares",
        "price": 75.86
    }, {
        "symbol": "SMN",
        "label": "ProShares UltraShort Basic Materials",
        "price": 28.99
    }, {
        "symbol": "ENFC",
        "label": "Entegra Financial Corp.",
        "price": 30.16
    }, {
        "symbol": "IMH",
        "label": "Impac Mortgage Holdings Inc.",
        "price": 5.685
    }, {
        "symbol": "FPX",
        "label": "First Trust US Equity Opportunities",
        "price": 83.54
    }, {
        "symbol": "MGK",
        "label": "Vanguard Mega Cap Growth",
        "price": 123.22
    }, {
        "symbol": "BMRC",
        "label": "Bank of Marin Bancorp",
        "price": 44.43
    }, {
        "symbol": "PBW",
        "label": "PowerShares WilderHill Clean Energy Portfolio",
        "price": 27.88
    }, {
        "symbol": "RGI",
        "label": "Guggenheim S&P 500 Equal Weight Industrials",
        "price": 121.52
    }, {
        "symbol": "XPH",
        "label": "SPDR S&P Pharmaceuticals",
        "price": 38.02
    }, {
        "symbol": "FXZ",
        "label": "First Trust Materials AlphaDEX Fund",
        "price": 39.99
    }, {
        "symbol": "SSC",
        "label": "Seven Stars Cloud Group Inc.",
        "price": 3.0
    }, {
        "symbol": "FSZ",
        "label": "First Trust Switzerland AlphaDEX Fund",
        "price": 55.3314
    }, {
        "symbol": "PDI",
        "label": "PIMCO Dynamic Income Fund",
        "price": 32.76
    }, {
        "symbol": "FBZ",
        "label": "First Trust Brazil AlphaDEX Fund",
        "price": 17.7209
    }, {
        "symbol": "IJK",
        "label": "iShares S&P Mid-Cap 400 Growth",
        "price": 243.26
    }, {
        "symbol": "AC",
        "label": "Associated Capital Group Inc.",
        "price": 51.08
    }, {
        "symbol": "PEO",
        "label": "Adams Natural Resources Fund Inc.",
        "price": 16.21
    }, {
        "symbol": "DHIL",
        "label": "Diamond Hill Investment Group Inc.",
        "price": 142.05
    }, {
        "symbol": "CIBR",
        "label": "First Trust NASDAQ Cybersecurity ETF",
        "price": 32.0337
    }, {
        "symbol": "REW",
        "label": "ProShares UltraShort Technology",
        "price": 21.33
    }, {
        "symbol": "XPP",
        "label": "ProShares Ultra FTSE China 50",
        "price": 57.75
    }, {
        "symbol": "PXR",
        "label": "PowerShares Global Emerging Markets Infrastructure",
        "price": 34.74
    }, {
        "symbol": "DWCH",
        "label": "Datawatch Corporation",
        "price": 13.1
    }, {
        "symbol": "PUB",
        "label": "People's Utah Bancorp",
        "price": 29.55
    }, {
        "symbol": "ESSA",
        "label": "ESSA Bancorp Inc.",
        "price": 17.19
    }, {
        "symbol": "FFNW",
        "label": "First Financial Northwest Inc.",
        "price": 14.73
    }, {
        "symbol": "CELC",
        "label": "Celcuity Inc.",
        "price": 11.23
    }, {
        "symbol": "OPY",
        "label": "Oppenheimer Holdings Inc. Class A (DE)",
        "price": 27.78
    }, {
        "symbol": "TIPX",
        "label": "SPDR Bloomberg Barclays 1-10 Year TIPS",
        "price": 19.47
    }, {
        "symbol": "BOCH",
        "label": "Bank of Commerce Holdings (CA)",
        "price": 11.2
    }, {
        "symbol": "KWR",
        "label": "Quaker Chemical Corporation",
        "price": 169.04
    }, {
        "symbol": "DXB",
        "label": "Deutsche Bk Contingent Cap TR II Tr Pfd Sec",
        "price": 26.36
    }, {
        "symbol": "SVA",
        "label": "Sinovac Biotech Ltd.",
        "price": 6.47
    }, {
        "symbol": "CMSA",
        "label": "CMS Energy Corporation 5.625% Junior Subordinated Notes due 2078",
        "price": 27.1
    }, {
        "symbol": "FCNCA",
        "label": "First Citizens BancShares Inc. Class A Common Stock",
        "price": 530.85
    }, {
        "symbol": "USCI",
        "label": "United States Commodity Index Fund ETV",
        "price": 36.24
    }, {
        "symbol": "SPLB",
        "label": "SPDR Portfolio Long Term Corporate Bond",
        "price": 30.54
    }, {
        "symbol": "XONE",
        "label": "The ExOne Company",
        "price": 7.05
    }, {
        "symbol": "WTBA",
        "label": "West Bancorporation",
        "price": 24.32
    }, {
        "symbol": "DL",
        "label": "China Distance Education Holdings Limited American Depositary Shares",
        "price": 9.05
    }, {
        "symbol": "SCHO",
        "label": "Schwab Short-Term U.S. Treasury",
        "price": 50.44
    }, {
        "symbol": "ULH",
        "label": "Universal Logistics Holdings Inc.",
        "price": 19.27
    }, {
        "symbol": "ACHV",
        "label": "Achieve Life Sciences Inc.",
        "price": 0.6378
    }, {
        "symbol": "ACT",
        "label": "AdvisorShares Vice ETF",
        "price": 25.7433
    }, {
        "symbol": "AIRR",
        "label": "First Trust RBA American Industrial Renaissance ETF",
        "price": 29.44
    }, {
        "symbol": "ALT",
        "label": "Altimmune Inc.",
        "price": 1.85
    }, {
        "symbol": "AMDA",
        "label": "Amedica Corporation",
        "price": 0.31
    }, {
        "symbol": "ANTH",
        "label": "Anthera Pharmaceuticals Inc.",
        "price": 0.12
    }, {
        "symbol": "ARD",
        "label": "Ardagh Group S.A.",
        "price": 21.07
    }, {
        "symbol": "ARKG",
        "label": "ARK Genomic Revolution Multi-Sector",
        "price": 35.05
    }, {
        "symbol": "AROW",
        "label": "Arrow Financial Corporation",
        "price": 37.1
    }, {
        "symbol": "AUMN",
        "label": "Golden Minerals Company",
        "price": 0.2957
    }, {
        "symbol": "AZRE",
        "label": "Azure Power Global Limited Equity Shares",
        "price": 12.02
    }, {
        "symbol": "BAB",
        "label": "PowerShares Taxable Municipal Bond Portfolio",
        "price": 32.066
    }, {
        "symbol": "BANF",
        "label": "BancFirst Corporation",
        "price": 62.74
    }, {
        "symbol": "BDCL",
        "label": "2xLeveraged Long Exchange Traded Access Securities (E-TRACS) Linked to the Wells" +
                " Fargo Business Development Company Index due May 24 2041",
        "price": 13.77
    }, {
        "symbol": "BDCS",
        "label": "UBS AG Exchange Traded Access Securities (E TRACS) Linked to the Wells Fargo Bus" +
                "iness Development Company Index due April 26 2041",
        "price": 19.35
    }, {
        "symbol": "BDGE",
        "label": "Bridge Bancorp Inc.",
        "price": 32.89
    }, {
        "symbol": "BELFB",
        "label": "Bel Fuse Inc. Class B Common Stock",
        "price": 19.7
    }, {
        "symbol": "BHK",
        "label": "Blackrock Core Bond Trust",
        "price": 14.69
    }, {
        "symbol": "BPT",
        "label": "BP Prudhoe Bay Royalty Trust",
        "price": 8.21
    }, {
        "symbol": "BRF",
        "label": "VanEck Vectors Brazil Small-Cap",
        "price": 22.32
    }, {
        "symbol": "BTAL",
        "label": "AGFiQ U.S. Market Neutral Anti-Beta Fund",
        "price": 22.45
    }, {
        "symbol": "BVX",
        "label": "Bovie Medical Corporation",
        "price": 6.43
    }, {
        "symbol": "BYFC",
        "label": "Broadway Financial Corporation",
        "price": 1.57
    }, {
        "symbol": "CBH",
        "label": "AllianzGI Convertible & Income 2024 Target Term Fund of Beneficial Interest",
        "price": 9.75
    }, {
        "symbol": "CCA",
        "label": "MFS California Municipal Fund of Beneficial Interest",
        "price": 11.65
    }, {
        "symbol": "CEL",
        "label": "Cellcom Israel Ltd.",
        "price": 3.18
    }, {
        "symbol": "CELP",
        "label": "Cypress Energy Partners L.P. representing limited partner interests",
        "price": 9.1001
    }, {
        "symbol": "CH",
        "label": "Aberdeen Chile Fund Inc. (The)",
        "price": 9.16
    }, {
        "symbol": "CHFS",
        "label": "CHF Solutions Inc.",
        "price": 1.1
    }, {
        "symbol": "CHMG",
        "label": "Chemung Financial Corp",
        "price": 41.4
    }, {
        "symbol": "CMCL",
        "label": "Caledonia Mining Corporation Plc",
        "price": 8.41
    }, {
        "symbol": "COE",
        "label": "China Online Education Group American depositary shares each representing 15 Cla" +
                "ss A",
        "price": 14.51
    }, {
        "symbol": "CPIX",
        "label": "Cumberland Pharmaceuticals Inc.",
        "price": 5.1
    }, {
        "symbol": "CPST",
        "label": "Capstone Turbine Corporation",
        "price": 3.1
    }, {
        "symbol": "CSTR",
        "label": "CapStar Financial Holdings Inc.",
        "price": 16.26
    }, {
        "symbol": "CZFC",
        "label": "Citizens First Corporation",
        "price": 24.41
    }, {
        "symbol": "DFEN",
        "label": "Direxion Daily Aerospace & Defense Bull 3X Shares",
        "price": 44.83
    }, {
        "symbol": "DFP",
        "label": "Flaherty & Crumrine Dynamic Preferred and Income Fund Inc.",
        "price": 27.28
    }, {
        "symbol": "DIAX",
        "label": "Nuveen Dow 30SM Dynamic Overwrite Fund of Beneficial Interest",
        "price": 17.99
    }, {
        "symbol": "DIVY",
        "label": "Realty Shares DIVS",
        "price": 26.605
    }, {
        "symbol": "DOD",
        "label": "Deutsche Bank AG ELEMENTS Dogs of the Dow Total Return Index Note ELEMENTS Dogs " +
                "of the Dow Linked to the Dow Jones High Yield Select 10 Total Return Index due N",
        "price": 25.65
    }, {
        "symbol": "DOO",
        "label": "WisdomTree International Dividend Top 100 Fund",
        "price": 40.26
    }, {
        "symbol": "DRIO",
        "label": "DarioHealth Corp.",
        "price": 8.25
    }, {
        "symbol": "EDI",
        "label": "Stone Harbor Emerging Markets Total Income Fund of Beneficial Interests",
        "price": 12.05
    }, {
        "symbol": "EEMA",
        "label": "iShares MSCI Emerging Markets Asia Index Fund",
        "price": 73.44
    }, {
        "symbol": "EFL",
        "label": "Eaton Vance Floating-Rate 2022 Target Term Trust of Beneficial Interest",
        "price": 9.5756
    }, {
        "symbol": "ELSE",
        "label": "Electro-Sensors Inc.",
        "price": 3.6244
    }, {
        "symbol": "EMHY",
        "label": "iShares Emerging Markets High Yield Bond",
        "price": 48.07
    }, {
        "symbol": "ENOR",
        "label": "iShares Inc MSCI Norway",
        "price": 24.27
    }, {
        "symbol": "ERA",
        "label": "Era Group Inc.",
        "price": 8.56
    }, {
        "symbol": "ESLT",
        "label": "Elbit Systems Ltd.",
        "price": 156.74
    }, {
        "symbol": "EVOL",
        "label": "Evolving Systems Inc.",
        "price": 0.9022
    }, {
        "symbol": "FAM",
        "label": "First Trust/Aberdeen Global Opportunity Income Fund of Beneficial Interest",
        "price": 11.47
    }, {
        "symbol": "FDEU",
        "label": "First Trust Dynamic Europe Equity Income Fund of Beneficial Interest",
        "price": 15.33
    }, {
        "symbol": "FENY",
        "label": "Fidelity MSCI Energy Index",
        "price": 15.71
    }, {
        "symbol": "FIF",
        "label": "First Trust Energy Infrastructure Fund of Beneficial Interest",
        "price": 17.32
    }, {
        "symbol": "FLTR",
        "label": "VanEck Vectors Investment Grade Floating Rate",
        "price": 25.38
    }, {
        "symbol": "FNDF",
        "label": "Schwab Fundamental International Large Company Index",
        "price": 29.09
    }, {
        "symbol": "FRBA",
        "label": "First Bank",
        "price": 10.6
    }, {
        "symbol": "FRSX",
        "label": "Foresight Autonomous Holdings Ltd.",
        "price": 1.1
    }, {
        "symbol": "GASS",
        "label": "StealthGas Inc.",
        "price": 3.33
    }, {
        "symbol": "GBLI",
        "label": "Global Indemnity Limited",
        "price": 30.7
    }, {
        "symbol": "GEC",
        "label": "Great Elm Capital Group Inc.",
        "price": 3.06
    }, {
        "symbol": "GLIBP",
        "label": "GCI Liberty Inc. Series A Cumulative Redeemable Preferred Stock",
        "price": 27.34
    }, {
        "symbol": "GOAU",
        "label": "US Global GO Gold and Precious Metal Miners",
        "price": 11.66
    }, {
        "symbol": "GPP",
        "label": "Green Plains Partners LP",
        "price": 14.7
    }, {
        "symbol": "GSIE",
        "label": "Goldman Sachs ActiveBeta International Equity",
        "price": 30.67
    }, {
        "symbol": "HSEA",
        "label": "HSBC Holdings plc. Perpetual Sub Cap Secs",
        "price": 26.36
    }, {
        "symbol": "IBD",
        "label": "Inspire Corporate Bond Impact",
        "price": 25.3
    }, {
        "symbol": "IPDN",
        "label": "Professional Diversity Network Inc.",
        "price": 0.91
    }, {
        "symbol": "IYJ",
        "label": "iShares U.S. Industrials",
        "price": 152.49
    }, {
        "symbol": "JLS",
        "label": "Nuveen Mortgage Opportunity Term Fund",
        "price": 22.33
    }, {
        "symbol": "JMBA",
        "label": "Jamba Inc.",
        "price": 13.0
    }, {
        "symbol": "JOUT",
        "label": "Johnson Outdoors Inc.",
        "price": 78.35
    }, {
        "symbol": "KBWB",
        "label": "PowerShares KBW Bank Portfolio",
        "price": 57.02
    }, {
        "symbol": "KEQU",
        "label": "Kewaunee Scientific Corporation",
        "price": 12.59
    }, {
        "symbol": "KSM",
        "label": "Deutsche Strategic Municiple Income Trust of Beneficial Interest",
        "price": 12.08
    }, {
        "symbol": "LBCC",
        "label": "Long Blockchain Corp.",
        "price": 1.27
    }, {
        "symbol": "LDP",
        "label": "Cohen & Steers Limited Duration Preferred and Income Fund Inc.",
        "price": 26.31
    }, {
        "symbol": "LGCYO",
        "label": "Legacy Reserves LP 8.00% Series B Fixed-to-Floating Rate Cumulative Redeemable P" +
                "erpetual Preferred Units",
        "price": 14.08
    }, {
        "symbol": "LGI",
        "label": "Lazard Global Total Return and Income Fund",
        "price": 16.78
    }, {
        "symbol": "LIFE",
        "label": "aTyr Pharma Inc.",
        "price": 6.16
    }, {
        "symbol": "LORL",
        "label": "Loral Space and Communications Inc.",
        "price": 33.38
    }, {
        "symbol": "LPCN",
        "label": "Lipocine Inc.",
        "price": 0.53
    }, {
        "symbol": "LTBR",
        "label": "Lightbridge Corporation",
        "price": 4.8
    }, {
        "symbol": "MHH",
        "label": "Mastech Digital Inc",
        "price": 12.24
    }, {
        "symbol": "MLR",
        "label": "Miller Industries Inc.",
        "price": 35.62
    }, {
        "symbol": "MNP",
        "label": "Western Asset Municipal Partners Fund Inc.",
        "price": 15.43
    }, {
        "symbol": "MTT",
        "label": "Western Asset Municipal Defined Opportunity Trust Inc",
        "price": 21.27
    }, {
        "symbol": "MUH",
        "label": "Blackrock MuniHoldings Fund II Inc.",
        "price": 15.44
    }, {
        "symbol": "MXF",
        "label": "Mexico Fund Inc. (The)",
        "price": 15.06
    }, {
        "symbol": "MYF",
        "label": "Blackrock MuniYield Investment Fund",
        "price": 14.55
    }, {
        "symbol": "MYSZ",
        "label": "My Size Inc.",
        "price": 2.965
    }, {
        "symbol": "MZF",
        "label": "Managed Duration Investment Grade Municipal Fund",
        "price": 14.33
    }, {
        "symbol": "NEPT",
        "label": "Neptune Technologies & Bioresources Inc",
        "price": 3.02
    }, {
        "symbol": "NHS",
        "label": "Neuberger Berman High Yield Strategies Fund",
        "price": 12.36
    }, {
        "symbol": "NIQ",
        "label": "Nuveenn Intermediate Duration Quality Municipal Term Fund of Beneficial Interest",
        "price": 14.04
    }, {
        "symbol": "NODK",
        "label": "NI Holdings Inc.",
        "price": 15.98
    }, {
        "symbol": "NTIC",
        "label": "Northern Technologies International Corporation",
        "price": 14.04
    }, {
        "symbol": "NXQ",
        "label": "Nuveen Select Tax Free Income Portfolio II",
        "price": 15.33
    }, {
        "symbol": "OBLN",
        "label": "Obalon Therapeutics Inc.",
        "price": 1.9
    }, {
        "symbol": "OFS",
        "label": "OFS Capital Corporation",
        "price": 11.5
    }, {
        "symbol": "OPP",
        "label": "RiverNorth/DoubleLine Strategic Opportunity Fund Inc.",
        "price": 16.59
    }, {
        "symbol": "OREX",
        "label": "Orexigen Therapeutics Inc.",
        "price": 0.21
    }, {
        "symbol": "PAR",
        "label": "PAR Technology Corporation",
        "price": 30.15
    }, {
        "symbol": "PBSK",
        "label": "Poage Bankshares Inc.",
        "price": 24.71
    }, {
        "symbol": "PFFD",
        "label": "Global X U.S. Preferred",
        "price": 24.24
    }, {
        "symbol": "PGF",
        "label": "PowerShares Financial Preferred",
        "price": 18.44
    }, {
        "symbol": "PHIIK",
        "label": "PHI Inc.",
        "price": 0.62
    }, {
        "symbol": "PIXY",
        "label": "ShiftPixy Inc.",
        "price": 15.5
    }, {
        "symbol": "PNI",
        "label": "Pimco New York Municipal Income Fund II of Beneficial Interest",
        "price": 12.2213
    }, {
        "symbol": "PQ",
        "label": "PetroQuest Energy Inc.",
        "price": 0.55
    }, {
        "symbol": "PRAN",
        "label": "Prana Biotechnology Ltd",
        "price": 2.09
    }, {
        "symbol": "PRSS",
        "label": "CafePress Inc.",
        "price": 1.46
    }, {
        "symbol": "PSCD",
        "label": "PowerShares S&P SmallCap Consumer Discretionary Portfolio",
        "price": 65.345
    }, {
        "symbol": "PTIE",
        "label": "Pain Therapeutics Inc.",
        "price": 1.17
    }, {
        "symbol": "PYDS",
        "label": "Payment Data Systems Inc.",
        "price": 2.87
    }, {
        "symbol": "PYN",
        "label": "PIMCO New York Municipal Income Fund III of Beneficial Interest",
        "price": 9.805
    }, {
        "symbol": "QUMU",
        "label": "Qumu Corporation",
        "price": 2.63
    }, {
        "symbol": "QVAL",
        "label": "Alpha Architect U.S. Quantitative Value",
        "price": 26.85
    }, {
        "symbol": "RDCM",
        "label": "Radcom Ltd.",
        "price": 9.05
    }, {
        "symbol": "RELL",
        "label": "Richardson Electronics Ltd.",
        "price": 5.63
    }, {
        "symbol": "RILYG",
        "label": "B. Riley Financial Inc. 7.25% Senior Notes due 2027",
        "price": 25.49
    }, {
        "symbol": "RJI",
        "label": "AB Svensk Ekportkredit (Swedish Export Credit Corporation) ELEMENTS Linked to th" +
                "e Rogers International Commodity Index - Total Return Structured Product",
        "price": 5.14
    }, {
        "symbol": "ROM",
        "label": "ProShares Ultra Technology",
        "price": 103.57
    }, {
        "symbol": "SBI",
        "label": "Western Asset Intermediate Muni Fund Inc",
        "price": 9.45
    }, {
        "symbol": "SDOG",
        "label": "ALPS Sector Dividend Dogs",
        "price": 41.98
    }, {
        "symbol": "SDPI",
        "label": "Superior Drilling Products Inc.",
        "price": 0.805
    }, {
        "symbol": "SEII",
        "label": "Sharing Economy International Inc.",
        "price": 0.7
    }, {
        "symbol": "SGC",
        "label": "Superior Uniform Group Inc.",
        "price": 13.99
    }, {
        "symbol": "SMBK",
        "label": "SmartFinancial Inc.",
        "price": 23.15
    }, {
        "symbol": "SOHO",
        "label": "Sotherly Hotels Inc.",
        "price": 6.53
    }, {
        "symbol": "SOJB",
        "label": "Southern Company (The) Series 2016A 5.25% Junior Subordinated Notes due October " +
                "1 2076",
        "price": 26.53
    }, {
        "symbol": "SPA",
        "label": "Sparton Corporation",
        "price": 18.5
    }, {
        "symbol": "SPKEP",
        "label": "Spark Energy Inc. 8.75% Series A Fixed-to-Floating Rate Cumulative Redeemable Pe" +
                "rpetual Preferred Stock",
        "price": 25.35
    }, {
        "symbol": "SPRT",
        "label": "Support.com Inc.",
        "price": 1.26
    }, {
        "symbol": "SRS",
        "label": "ProShares UltraShort Real Estate",
        "price": 21.92
    }, {
        "symbol": "STRS",
        "label": "Stratus Properties Inc.",
        "price": 29.88
    }, {
        "symbol": "SYLD",
        "label": "Cambria Shareholder Yield",
        "price": 40.43
    }, {
        "symbol": "TCGP",
        "label": "The Carlyle Group L.P.",
        "price": 23.51
    }, {
        "symbol": "TECS",
        "label": "Direxion Technology Bear 3X Shares",
        "price": 15.37
    }, {
        "symbol": "TGA",
        "label": "TransGlobe Energy Corporation",
        "price": 1.39
    }, {
        "symbol": "TIG",
        "label": "TiGenix",
        "price": 40.45
    }, {
        "symbol": "TIPT",
        "label": "Tiptree Inc.",
        "price": 7.74
    }, {
        "symbol": "TMF",
        "label": "Direxion Daily 20-Yr Treasury Bull 3x Shrs",
        "price": 23.9
    }, {
        "symbol": "TPGE",
        "label": "TPG Pace Energy Holdings Corp. Class A",
        "price": 12.36
    }, {
        "symbol": "TROV",
        "label": "TrovaGene Inc.",
        "price": 1.38
    }, {
        "symbol": "TST",
        "label": "TheStreet Inc.",
        "price": 5.67
    }, {
        "symbol": "TTNP",
        "label": "Titan Pharmaceuticals Inc.",
        "price": 0.334
    }, {
        "symbol": "TVC",
        "label": "Tennessee Valley Authority",
        "price": 26.83
    }, {
        "symbol": "UBT",
        "label": "ProShares Ultra 20+ Year Treasury",
        "price": 80.94
    }, {
        "symbol": "VRIG",
        "label": "PowerShares Variable Rate Investment Grade Portfolio",
        "price": 25.025
    }, {
        "symbol": "VTHR",
        "label": "Vanguard Russell 3000 ETF",
        "price": 151.016
    }, {
        "symbol": "VTN",
        "label": "Invesco Trust for Investment Grade New York Municipals",
        "price": 13.79
    }, {
        "symbol": "VTVT",
        "label": "vTv Therapeutics Inc.",
        "price": 2.37
    }, {
        "symbol": "VUSE",
        "label": "ETF Series Solutions Trust Vident Core US Equity",
        "price": 33.75
    }, {
        "symbol": "WIA",
        "label": "Western Asset/Claymore Inflation-Linked Securities & Income Fund of Beneficial I" +
                "nterest",
        "price": 12.27
    }, {
        "symbol": "WSTL",
        "label": "Westell Technologies Inc.",
        "price": 1.1695
    }, {
        "symbol": "XSD",
        "label": "SPDR S&P Semiconductor",
        "price": 111.29
    }, {
        "symbol": "LMRK",
        "label": "Landmark Infrastructure Partners LP",
        "price": 16.74
    }, {
        "symbol": "PBBI",
        "label": "PB Bancorp Inc.",
        "price": 15.14
    }, {
        "symbol": "VRTS",
        "label": "Virtus Investment Partners Inc.",
        "price": 128.33
    }, {
        "symbol": "BOIL",
        "label": "ProShares Ultra Bloomberg Natural Gas",
        "price": 14.9
    }, {
        "symbol": "MBII",
        "label": "Marrone Bio Innovations Inc.",
        "price": 1.15
    }, {
        "symbol": "DTO",
        "label": "DB Crude Oil Double Short ETN due June 1 2038",
        "price": 53.91
    }, {
        "symbol": "FTRPR",
        "label": "Frontier Communications Corporation 11.125% Series A Mandatory Convertible Prefe" +
                "rred Stock",
        "price": 9.67
    }, {
        "symbol": "FWP",
        "label": "Forward Pharma A/S",
        "price": 6.42
    }, {
        "symbol": "YORW",
        "label": "The York Water Company",
        "price": 47.89
    }, {
        "symbol": "TGEN",
        "label": "Tecogen Inc.",
        "price": 2.1
    }, {
        "symbol": "SENEA",
        "label": "Seneca Foods Corp. Class A Common Stock",
        "price": 38.23
    }, {
        "symbol": "TI",
        "label": "Telecom Italia S.P.A. New",
        "price": 5.05
    }, {
        "symbol": "BMTC",
        "label": "Bryn Mawr Bank Corporation",
        "price": 39.86
    }, {
        "symbol": "CCRC",
        "label": "China Customer Relations Centers Inc.",
        "price": 10.58
    }, {
        "symbol": "PSCI",
        "label": "PowerShares S&P SmallCap Industrials Portfolio",
        "price": 73.955
    }, {
        "symbol": "EML",
        "label": "Eastern Company (The)",
        "price": 31.0
    }, {
        "symbol": "CPTA",
        "label": "Capitala Finance Corp.",
        "price": 8.9
    }, {
        "symbol": "PFMT",
        "label": "Performant Financial Corporation",
        "price": 1.11
    }, {
        "symbol": "CIF",
        "label": "MFS Intermediate High Income Fund",
        "price": 2.95
    }, {
        "symbol": "XMLV",
        "label": "PowerShares S&P MidCap Low Volatility Portfolio",
        "price": 54.6
    }, {
        "symbol": "IAGG",
        "label": "iShares International Aggregate Bond Fund",
        "price": 55.07
    }, {
        "symbol": "VBR",
        "label": "Vanguard Small-Cap Value",
        "price": 138.7
    }, {
        "symbol": "EBAYL",
        "label": "eBay Inc. 6.0% Notes Due 2056",
        "price": 26.7
    }, {
        "symbol": "CRVL",
        "label": "CorVel Corp.",
        "price": 95.67
    }, {
        "symbol": "IPAC",
        "label": "iShares Core MSCI Pacific",
        "price": 54.58
    }, {
        "symbol": "BBU",
        "label": "Brookfield Business Partners L.P. Limited Partnership Units",
        "price": 42.69
    }, {
        "symbol": "HYMB",
        "label": "SPDR Nuveen S&P High Yield Municipal Bond",
        "price": 58.13
    }, {
        "symbol": "PMBC",
        "label": "Pacific Mercantile Bancorp",
        "price": 7.54
    }, {
        "symbol": "PVI",
        "label": "PowerShares VRDO Tax Free Weekly Portfolio",
        "price": 24.93
    }, {
        "symbol": "SNHNI",
        "label": "Senior Housing Properties Trust 5.625% Senior Notes due 2042",
        "price": 24.75
    }, {
        "symbol": "ALBO",
        "label": "Albireo Pharma Inc.",
        "price": 24.97
    }, {
        "symbol": "GASL",
        "label": "Direxion Daily Natural Gas Related Bull 3X Shares",
        "price": 4.12
    }, {
        "symbol": "URGN",
        "label": "UroGen Pharma Ltd.",
        "price": 33.85
    }, {
        "symbol": "MSB",
        "label": "Mesabi Trust",
        "price": 22.72
    }, {
        "symbol": "NRCIA",
        "label": "National Research Corporation Class A Common Stock",
        "price": 33.65
    }, {
        "symbol": "BH",
        "label": "Biglari Holdings Inc.",
        "price": 112.27
    }, {
        "symbol": "JYNT",
        "label": "The Joint Corp.",
        "price": 16.35
    }, {
        "symbol": "MGV",
        "label": "Vanguard Mega Cap Value",
        "price": 88.16
    }, {
        "symbol": "SCHK",
        "label": "Schwab 1000 Index",
        "price": 27.8
    }, {
        "symbol": "GRBK",
        "label": "Green Brick Partners Inc.",
        "price": 11.9
    }, {
        "symbol": "NCA",
        "label": "Nuveen California Municipal Value Fund Inc.",
        "price": 10.75
    }, {
        "symbol": "FNLC",
        "label": "First Bancorp Inc (ME)",
        "price": 29.82
    }, {
        "symbol": "MBCN",
        "label": "Middlefield Banc Corp.",
        "price": 25.64
    }, {
        "symbol": "FILL",
        "label": "iShares MSCI Global Energy Producers Fund",
        "price": 18.9699
    }, {
        "symbol": "VONV",
        "label": "Vanguard Russell 1000 Value ETF",
        "price": 121.56
    }, {
        "symbol": "NKSH",
        "label": "National Bankshares Inc.",
        "price": 42.11
    }, {
        "symbol": "AMNB",
        "label": "American National Bankshares Inc.",
        "price": 37.59
    }, {
        "symbol": "UYG",
        "label": "ProShares Ultra Financials",
        "price": 43.03
    }, {
        "symbol": "CHA",
        "label": "China Telecom Corp Ltd ADS",
        "price": 41.3
    }, {
        "symbol": "CHSCM",
        "label": "CHS Inc Class B Reset Rate Cumulative Redeemable Preferred Stock Series 3",
        "price": 27.0
    }, {
        "symbol": "CHAU",
        "label": "Direxion Daily CSI 300 China A Share Bull 2X Shares",
        "price": 19.33
    }, {
        "symbol": "CEFL",
        "label": "ETRACS Monthly Pay 2X Leveraged Closed-End Fund ETN",
        "price": 13.76
    }, {
        "symbol": "GPJA",
        "label": "Georgia Power Company Series 2017A 5.00% Junior Subordinated Notes due October 1" +
                " 2077",
        "price": 26.38
    }, {
        "symbol": "RYH",
        "label": "Guggenheim S&P 500 Equal Weight Healthcare",
        "price": 188.84
    }, {
        "symbol": "WPXP",
        "label": "WPX Energy Inc. 6.25% Series A Mandatory Convertible Preferred Stock",
        "price": 76.37
    }, {
        "symbol": "GLF",
        "label": "GulfMark Offshore Inc.",
        "price": 28.27
    }, {
        "symbol": "JHMT",
        "label": "John Hancock Multifactor Technology",
        "price": 50.8
    }, {
        "symbol": "ONEQ",
        "label": "Fidelity Nasdaq Composite Index Tracking Stock",
        "price": 365.25
    }, {
        "symbol": "CEZ",
        "label": "VictoryShares Emerging Market Volatility Wtd ETF",
        "price": 27.16
    }, {
        "symbol": "LAND",
        "label": "Gladstone Land Corporation",
        "price": 13.43
    }, {
        "symbol": "SCHR",
        "label": "Schwab Intermediate-Term U.S. Treasury",
        "price": 55.22
    }, {
        "symbol": "CHSCN",
        "label": "CHS Inc Preferred Class B Series 2 Reset Rate",
        "price": 27.76
    }, {
        "symbol": "HBANO",
        "label": "Huntington Bancshares Incorporated Depositary Shares",
        "price": 26.36
    }, {
        "symbol": "YYY",
        "label": "YieldShares High Income",
        "price": 17.16
    }, {
        "symbol": "EXT",
        "label": "WisdomTree U.S. Total Earnings Fund",
        "price": 37.7389
    }, {
        "symbol": "MILN",
        "label": "Global X Millennials Thematic ETF",
        "price": 27.075
    }, {
        "symbol": "CFFI",
        "label": "C&F Financial Corporation",
        "price": 52.15
    }, {
        "symbol": "AIEQ",
        "label": "AI Powered Equity",
        "price": 25.23
    }, {
        "symbol": "XAR",
        "label": "SPDR S&P Aerospace & Defense",
        "price": 116.33
    }, {
        "symbol": "AOR",
        "label": "iShares Core Growth Allocation",
        "price": 44.63
    }, {
        "symbol": "IXG",
        "label": "iShares Global Financial",
        "price": 63.17
    }, {
        "symbol": "VIS",
        "label": "Vanguard Industrials",
        "price": 140.37
    }, {
        "symbol": "ALX",
        "label": "Alexander's Inc.",
        "price": 349.32
    }, {
        "symbol": "ATXI",
        "label": "Avenue Therapeutics Inc.",
        "price": 9.19
    }, {
        "symbol": "RWR",
        "label": "SPDR DJ Wilshire REIT",
        "price": 99.72
    }, {
        "symbol": "UCI",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI E" +
                "TN",
        "price": 14.2
    }, {
        "symbol": "CMBS",
        "label": "iShares CMBS Bond",
        "price": 53.1901
    }, {
        "symbol": "EDN",
        "label": "Empresa Distribuidora Y Comercializadora Norte S.A. (Edenor) Empresa Distribuido" +
                "ra Y Comercializadora Norte S.A. (Edenor) American Depositary Shares",
        "price": 5.86
    }, {
        "symbol": "DGZ",
        "label": "DB Gold Short ETN due February 15 2038",
        "price": 14.37
    }, {
        "symbol": "GURU",
        "label": "Global X Guru Index",
        "price": 31.46
    }, {
        "symbol": "ISG",
        "label": "ING Group N.V. Perpetual Dent Secs 6.125%",
        "price": 26.45
    }, {
        "symbol": "KCE",
        "label": "SPDR S&P Capital Markets",
        "price": 54.31
    }, {
        "symbol": "SHBI",
        "label": "Shore Bancshares Inc",
        "price": 16.39
    }, {
        "symbol": "RFV",
        "label": "Guggenheim S&P Midcap 400 Pure Value",
        "price": 68.5078
    }, {
        "symbol": "CWBR",
        "label": "CohBar Inc.",
        "price": 2.31
    }, {
        "symbol": "IBUY",
        "label": "Amplify Online Retail ETF",
        "price": 54.5687
    }, {
        "symbol": "MXC",
        "label": "Mexco Energy Corporation",
        "price": 4.0
    }, {
        "symbol": "RYF",
        "label": "Guggenheim S&P 500 Equal Weight Financials",
        "price": 47.5005
    }, {
        "symbol": "PTEU",
        "label": "Pacer TrendpilotTM European Index",
        "price": 25.54
    }, {
        "symbol": "FIEU",
        "label": "FI Enhanced Europe 50 ETN",
        "price": 120.96
    }, {
        "symbol": "MVO",
        "label": "MV Oil Trust",
        "price": 5.9995
    }, {
        "symbol": "GFN",
        "label": "General Finance Corporation",
        "price": 10.79
    }, {
        "symbol": "IRIX",
        "label": "IRIDEX Corporation",
        "price": 3.41
    }, {
        "symbol": "JETS",
        "label": "U.S. Global Jets",
        "price": 29.34
    }, {
        "symbol": "AOA",
        "label": "iShares Core Aggressive Allocation",
        "price": 59.2
    }, {
        "symbol": "CYAD",
        "label": "Celyad SA",
        "price": 11.99
    }, {
        "symbol": "FFTY",
        "label": "Innovator IBD 50",
        "price": 36.48
    }, {
        "symbol": "IWC",
        "label": "iShares Microcap",
        "price": 89.99
    }, {
        "symbol": "XELA",
        "label": "Exela Technologies Inc.",
        "price": 0.4525
    }, {
        "symbol": "UFPT",
        "label": "UFP Technologies Inc.",
        "price": 47.11
    }, {
        "symbol": "VIA",
        "label": "Viacom Inc.",
        "price": 26.11
    }, {
        "symbol": "WIRE",
        "label": "Encore Wire Corporation",
        "price": 59.31
    }, {
        "symbol": "AKER",
        "label": "Akers Biosciences Inc",
        "price": 3.45
    }, {
        "symbol": "BLMT",
        "label": "BSB Bancorp Inc.",
        "price": 32.89
    }, {
        "symbol": "CLPR",
        "label": "Clipper Realty Inc.",
        "price": 10.74
    }, {
        "symbol": "EMCG",
        "label": "WisdomTree Emerging Markets Consumer Growth Fund",
        "price": 24.4701
    }, {
        "symbol": "TCFC",
        "label": "The Community Financial Corporation",
        "price": 33.93
    }, {
        "symbol": "DAX",
        "label": "Horizons DAX Germany ETF",
        "price": 28.32
    }, {
        "symbol": "DSI",
        "label": "iShares MSCI KLD 400 Social",
        "price": 104.92
    }, {
        "symbol": "GBL",
        "label": "Gamco Investors Inc.",
        "price": 17.6
    }, {
        "symbol": "IGN",
        "label": "iShares North American Tech-Multimedia Networking",
        "price": 53.06
    }, {
        "symbol": "ATRI",
        "label": "Atrion Corporation",
        "price": 707.54
    }, {
        "symbol": "BRID",
        "label": "Bridgford Foods Corporation",
        "price": 24.75
    }, {
        "symbol": "PGHY",
        "label": "PowerShares Global Short Term High Yield Bond Portfolio",
        "price": 23.06
    }, {
        "symbol": "AUBN",
        "label": "Auburn National Bancorporation Inc.",
        "price": 53.85
    }, {
        "symbol": "DWSN",
        "label": "Dawson Geophysical Company",
        "price": 2.45
    }, {
        "symbol": "OMP",
        "label": "Oasis Midstream Partners LP Representing Limited Partner Interests",
        "price": 17.52
    }, {
        "symbol": "USD",
        "label": "ProShares Ultra Semiconductors",
        "price": 34.88
    }, {
        "symbol": "BWZ",
        "label": "SPDR Bloomberg Barclays Short Term International Treasury Bond",
        "price": 30.69
    }, {
        "symbol": "JOB",
        "label": "GEE Group Inc.",
        "price": 0.3994
    }, {
        "symbol": "OVBC",
        "label": "Ohio Valley Banc Corp.",
        "price": 37.44
    }, {
        "symbol": "SLYG",
        "label": "SPDR S&P 600 Small Cap Growth ETF (based on S&P SmallCap 600 Growth Index --symb" +
                "ol CGK)",
        "price": 66.57
    }, {
        "symbol": "ADRE",
        "label": "BLDRS Emerging Markets 50 ADR Index Fund",
        "price": 45.5
    }, {
        "symbol": "AE",
        "label": "Adams Resources & Energy Inc.",
        "price": 37.81
    }, {
        "symbol": "AFT",
        "label": "Apollo Senior Floating Rate Fund Inc.",
        "price": 15.39
    }, {
        "symbol": "AHPI",
        "label": "Allied Healthcare Products Inc.",
        "price": 1.6
    }, {
        "symbol": "ALTY",
        "label": "Global X SuperDividend Alternatives ETF",
        "price": 15.075
    }, {
        "symbol": "AMBCW",
        "label": "Ambac Financial Group Inc. Warrants",
        "price": 10.21
    }, {
        "symbol": "AMSF",
        "label": "AMERISAFE Inc.",
        "price": 68.6
    }, {
        "symbol": "AP",
        "label": "Ampco-Pittsburgh Corporation",
        "price": 3.04
    }, {
        "symbol": "APWC",
        "label": "Asia Pacific Wire & Cable Corporation Limited",
        "price": 1.5644
    }, {
        "symbol": "ARCT",
        "label": "Arcturus Therapeutics Ltd.",
        "price": 11.87
    }, {
        "symbol": "ASPN",
        "label": "Aspen Aerogels Inc.",
        "price": 9.98
    }, {
        "symbol": "AWRE",
        "label": "Aware Inc.",
        "price": 3.5
    }, {
        "symbol": "AXJL",
        "label": "WisdomTree Asia Pacific ex-Japan Total Dividend Fund",
        "price": 64.67
    }, {
        "symbol": "AZRX",
        "label": "AzurRx BioPharma Inc.",
        "price": 1.16
    }, {
        "symbol": "BCRH",
        "label": "Blue Capital Reinsurance Holdings Ltd.",
        "price": 6.92
    }, {
        "symbol": "BCTF",
        "label": "Bancorp 34 Inc.",
        "price": 15.55
    }, {
        "symbol": "BDR",
        "label": "Blonder Tongue Laboratories Inc.",
        "price": 0.82
    }, {
        "symbol": "BDXA",
        "label": "Becton Dickinson and Company Depositary Shares each Representing a 1/20th Intere" +
                "st in a Share of 6.125% Mandatory Convertible Preferred Stock Series A $1.00 par",
        "price": 65.99
    }, {
        "symbol": "BFY",
        "label": "BlackRock New York Municipal Income Trust II",
        "price": 14.6688
    }, {
        "symbol": "BKT",
        "label": "BlackRock Income Trust Inc. (The)",
        "price": 6.05
    }, {
        "symbol": "BLPH",
        "label": "Bellerophon Therapeutics Inc.",
        "price": 0.39
    }, {
        "symbol": "BNSO",
        "label": "Bonso Electronics International Inc.",
        "price": 2.37
    }, {
        "symbol": "BNY",
        "label": "BlackRock New York Municipal Income Trust",
        "price": 14.07
    }, {
        "symbol": "BSCQ",
        "label": "Claymore Exchange-Traded Fund Trust",
        "price": 19.82
    }, {
        "symbol": "BSET",
        "label": "Bassett Furniture Industries Incorporated",
        "price": 16.74
    }, {
        "symbol": "BSJL",
        "label": "Guggenheim BulletShares 2021 High Yield Corporate Bond",
        "price": 24.58
    }, {
        "symbol": "BSJN",
        "label": "Guggenheim BulletShares 2023 High Yield Corporate Bond",
        "price": 25.92
    }, {
        "symbol": "BSTC",
        "label": "BioSpecifics Technologies Corp",
        "price": 57.12
    }, {
        "symbol": "BWEN",
        "label": "Broadwind Energy Inc.",
        "price": 1.67
    }, {
        "symbol": "BYM",
        "label": "Blackrock Municipal Income Quality Trust of Beneficial Interest",
        "price": 14.29
    }, {
        "symbol": "CAPE",
        "label": "Barclays ETN Schiller CAPE",
        "price": 153.507
    }, {
        "symbol": "CART",
        "label": "Carolina Trust BancShares Inc.",
        "price": 13.05
    }, {
        "symbol": "CASS",
        "label": "Cass Information Systems Inc",
        "price": 57.81
    }, {
        "symbol": "CATH",
        "label": "Global X S&P 500 Catholic Values ETF",
        "price": 40.76
    }, {
        "symbol": "CCCR",
        "label": "China Commercial Credit Inc.",
        "price": 1.25
    }, {
        "symbol": "CHEK",
        "label": "Check-Cap Ltd.",
        "price": 2.05
    }, {
        "symbol": "CHIX",
        "label": "Global X China Financials",
        "price": 16.64
    }, {
        "symbol": "CHSCL",
        "label": "CHS Inc Class B Cumulative Redeemable Preferred Stock Series 4",
        "price": 27.97
    }, {
        "symbol": "CIDM",
        "label": "Cinedigm Corp",
        "price": 0.7092
    }, {
        "symbol": "CLWT",
        "label": "Euro Tech Holdings Company Limited",
        "price": 2.41
    }, {
        "symbol": "CMTA",
        "label": "Clementia Pharmaceuticals Inc.",
        "price": 26.36
    }, {
        "symbol": "CORN",
        "label": "Teucrium Corn Fund ETV",
        "price": 16.36
    }, {
        "symbol": "CPAC",
        "label": "Cementos Pacasmayo S.A.A. American Depositary Shares (Each representing five)",
        "price": 9.07
    }, {
        "symbol": "CSD",
        "label": "Guggenheim S&P Spin-Off",
        "price": 50.51
    }, {
        "symbol": "CTS",
        "label": "CTS Corporation",
        "price": 31.36
    }, {
        "symbol": "CTU",
        "label": "Qwest Corporation 7.00% Notes due 2025",
        "price": 25.27
    }, {
        "symbol": "CTW",
        "label": "Qwest Corporation 7.50% Notes due 2051",
        "price": 25.32
    }, {
        "symbol": "CTY",
        "label": "Qwest Corporation 6.125% Notes due 2053",
        "price": 25.39
    }, {
        "symbol": "CUBA",
        "label": "The Herzfeld Caribbean Basin Fund Inc.",
        "price": 6.8
    }, {
        "symbol": "CULP",
        "label": "Culp Inc.",
        "price": 13.28
    }, {
        "symbol": "CVV",
        "label": "CVD Equipment Corporation",
        "price": 3.69
    }, {
        "symbol": "CXRX",
        "label": "Concordia International Corp.",
        "price": 0.19
    }, {
        "symbol": "CYCC",
        "label": "Cyclacel Pharmaceuticals Inc.",
        "price": 0.8
    }, {
        "symbol": "CZWI",
        "label": "Citizens Community Bancorp Inc.",
        "price": 11.9
    }, {
        "symbol": "DBEM",
        "label": "Xtrackers MSCI Emerging Markets Hedged Equity",
        "price": 24.55
    }, {
        "symbol": "DFBG",
        "label": "Differential Brands Group Inc.",
        "price": 5.09
    }, {
        "symbol": "DFNL",
        "label": "Davis Select Financial ETF",
        "price": 25.5
    }, {
        "symbol": "DHF",
        "label": "Dreyfus High Yield Strategies Fund",
        "price": 3.15
    }, {
        "symbol": "DHS",
        "label": "WisdomTree U.S. High Dividend Fund",
        "price": 69.3
    }, {
        "symbol": "DIAL",
        "label": "Columbia Diversified Fixed Income Allocation",
        "price": 20.04
    }, {
        "symbol": "DINT",
        "label": "Davis Select International ETF",
        "price": 19.93
    }, {
        "symbol": "DLA",
        "label": "Delta Apparel Inc.",
        "price": 26.43
    }, {
        "symbol": "DRAD",
        "label": "Digirad Corporation",
        "price": 2.91
    }, {
        "symbol": "DRN",
        "label": "Direxion Daily Real Estate Bull 3x Shares",
        "price": 25.83
    }, {
        "symbol": "DTN",
        "label": "WisdomTree U.S. Dividend ex-Financials Fund",
        "price": 80.73
    }, {
        "symbol": "DTV",
        "label": "DTE Energy Company 2016 Corporate Units",
        "price": 56.8
    }, {
        "symbol": "DTY",
        "label": "DTE Energy Company 2016 Series F 6.00% Junior Subordinated Debentures due 2076",
        "price": 27.63
    }, {
        "symbol": "DWAS",
        "label": "PowerShares DWA SmallCap Momentum Portfolio",
        "price": 58.2932
    }, {
        "symbol": "DWIN",
        "label": "PowerShares DWA Tactical Multi-Asset Income Portfolio",
        "price": 25.8568
    }, {
        "symbol": "EAE",
        "label": "Entergy Arkansas Inc. First Mortgage Bonds 4.75% Series due June 1 2063",
        "price": 25.55
    }, {
        "symbol": "EBTC",
        "label": "Enterprise Bancorp Inc",
        "price": 32.36
    }, {
        "symbol": "EDAP",
        "label": "EDAP TMS S.A.",
        "price": 4.1
    }, {
        "symbol": "EFAD",
        "label": "ProShares Trust MSCI EAFE Dividend Growers",
        "price": 36.66
    }, {
        "symbol": "EGF",
        "label": "Blackrock Enhanced Government Fund Inc.",
        "price": 13.15
    }, {
        "symbol": "EIRL",
        "label": "iShares Trust MSCI Ireland",
        "price": 42.02
    }, {
        "symbol": "EKSO",
        "label": "Ekso Bionics Holdings Inc.",
        "price": 0.3651
    }, {
        "symbol": "EMCI",
        "label": "EMC Insurance Group Inc.",
        "price": 36.02
    }, {
        "symbol": "EMP",
        "label": "Entergy Mississippi Inc. First Mortgage Bonds 4.90% Series Due October 1 2066",
        "price": 27.17
    }, {
        "symbol": "ENJ",
        "label": "Entergy New Orleans LLC First Mortgage Bonds 5.0% Series due December 1 2052",
        "price": 26.14
    }, {
        "symbol": "EQWL",
        "label": "PowerShares Russell Top 200 Equal Weight Portfolio",
        "price": 63.169
    }, {
        "symbol": "ESGE",
        "label": "iShares MSCI EM ESG Optimized ETF",
        "price": 37.05
    }, {
        "symbol": "ESP",
        "label": "Espey Mfg. & Electronics Corp.",
        "price": 20.5124
    }, {
        "symbol": "EVY",
        "label": "Eaton Vance New York Municipal Income Trust Shares of Beneficial Interest",
        "price": 13.375
    }, {
        "symbol": "EYES",
        "label": "Second Sight Medical Products Inc.",
        "price": 5.02
    }, {
        "symbol": "FBSS",
        "label": "Fauquier Bankshares Inc.",
        "price": 21.595
    }, {
        "symbol": "FCCY",
        "label": "1st Constitution Bancorp (NJ)",
        "price": 21.87
    }, {
        "symbol": "FCT",
        "label": "First Trust Senior Floating Rate Income Fund II of Beneficial Interest",
        "price": 12.56
    }, {
        "symbol": "FDIS",
        "label": "Fidelity MSCI Consumer Discretionary Index",
        "price": 49.4301
    }, {
        "symbol": "FDL",
        "label": "First Trust Morningstar",
        "price": 32.57
    }, {
        "symbol": "FDT",
        "label": "First Trust Developed Markets Ex-US AlphaDEX Fund",
        "price": 56.2331
    }, {
        "symbol": "FDUSL",
        "label": "Fidus Investment Corporation 5.875% Notes due 2023",
        "price": 25.68
    }, {
        "symbol": "FEMB",
        "label": "First Trust Emerging Markets Local Currency Bond ETF",
        "price": 39.08
    }, {
        "symbol": "FFR",
        "label": "First Trust FTSE EPRA/NAREIT Global Real Estate Index Fund",
        "price": 49.41
    }, {
        "symbol": "FHLC",
        "label": "Fidelity MSCI Health Care Index",
        "price": 51.04
    }, {
        "symbol": "FINX",
        "label": "Global X FinTech ETF",
        "price": 31.93
    }, {
        "symbol": "FLC",
        "label": "Flaherty & Crumrine Total Return Fund Inc",
        "price": 22.92
    }, {
        "symbol": "FLL",
        "label": "Full House Resorts Inc.",
        "price": 3.34
    }, {
        "symbol": "FLQE",
        "label": "Franklin LibertyQ Emerging Markets",
        "price": 29.17
    }, {
        "symbol": "FLTB",
        "label": "Fidelity Limited Term Bond",
        "price": 50.31
    }, {
        "symbol": "FMAO",
        "label": "Farmers & Merchants Bancorp Inc.",
        "price": 29.5
    }, {
        "symbol": "FMNB",
        "label": "Farmers National Banc Corp.",
        "price": 15.94
    }, {
        "symbol": "FNBG",
        "label": "FNB Bancorp",
        "price": 37.79
    }, {
        "symbol": "FNX",
        "label": "First Trust Mid Cap Core AlphaDEX Fund",
        "price": 73.52
    }, {
        "symbol": "FNY",
        "label": "First Trust Mid Cap Growth AlphaDEX Fund",
        "price": 49.4715
    }, {
        "symbol": "FRA",
        "label": "Blackrock Floating Rate Income Strategies Fund Inc",
        "price": 13.53
    }, {
        "symbol": "FSTR",
        "label": "L.B. Foster Company",
        "price": 20.01
    }, {
        "symbol": "FT",
        "label": "Franklin Universal Trust",
        "price": 7.98
    }, {
        "symbol": "FTC",
        "label": "First Trust Large Cap Growth AlphaDEX Fund",
        "price": 76.07
    }, {
        "symbol": "FTEK",
        "label": "Fuel Tech Inc.",
        "price": 0.91
    }, {
        "symbol": "FTLS",
        "label": "First Trust Long/Short Equity",
        "price": 43.61
    }, {
        "symbol": "FXO",
        "label": "First Trust Financials AlphaDEX",
        "price": 33.98
    }, {
        "symbol": "GAL",
        "label": "SPDR SSgA Global Allocation",
        "price": 37.38
    }, {
        "symbol": "GCOW",
        "label": "Pacer Global Cash Cows Dividend",
        "price": 29.62
    }, {
        "symbol": "GDL",
        "label": "GDL Fund The of Beneficial Interest",
        "price": 9.39
    }, {
        "symbol": "GEK",
        "label": "General Electric Capital Corporation 4.70% Notes due May 16 2053",
        "price": 24.5
    }, {
        "symbol": "GFA",
        "label": "Gafisa SA S.A. American Depositary Shares",
        "price": 8.15
    }, {
        "symbol": "GGM",
        "label": "Guggenheim Credit Allocation Fund of Beneficial Interest",
        "price": 20.12
    }, {
        "symbol": "GGT",
        "label": "Gabelli Multi-Media Trust Inc. (The)",
        "price": 8.26
    }, {
        "symbol": "GII",
        "label": "SPDR S&P Global Infrastructure",
        "price": 51.65
    }, {
        "symbol": "GLQ",
        "label": "Clough Global Equity Fund of Beneficial Interest",
        "price": 12.71
    }, {
        "symbol": "GLTR",
        "label": "ETFS Physical Precious Metal Basket Shares",
        "price": 64.03
    }, {
        "symbol": "GNE",
        "label": "Genie Energy Ltd. Class B Stock",
        "price": 7.51
    }, {
        "symbol": "GSBD",
        "label": "Goldman Sachs BDC Inc.",
        "price": 21.27
    }, {
        "symbol": "GURE",
        "label": "Gulf Resources Inc.",
        "price": 0.67
    }, {
        "symbol": "GVAL",
        "label": "Cambria Global Value",
        "price": 22.25
    }, {
        "symbol": "GXF",
        "label": "Global X FTSE Nordic Region",
        "price": 22.34
    }, {
        "symbol": "HALL",
        "label": "Hallmark Financial Services Inc.",
        "price": 18.76
    }, {
        "symbol": "HESM",
        "label": "Hess Midstream Partners LP Representing Limited Partner Interests",
        "price": 25.53
    }, {
        "symbol": "HFBC",
        "label": "HopFed Bancorp Inc.",
        "price": 19.01
    }, {
        "symbol": "HIPS",
        "label": "GraniteShares HIPS US High Income",
        "price": 17.18
    }, {
        "symbol": "HNW",
        "label": "Pioneer Diversified High Income Trust of Beneficial Interest",
        "price": 15.15
    }, {
        "symbol": "HPJ",
        "label": "Highpower International Inc",
        "price": 3.38
    }, {
        "symbol": "HSGX",
        "label": "Histogenics Corporation",
        "price": 0.18
    }, {
        "symbol": "HTD",
        "label": "John Hancock Tax Advantaged Dividend Income Fund of Beneficial Interest",
        "price": 27.48
    }, {
        "symbol": "HUSE",
        "label": "US Market Rotation Strategy",
        "price": 34.93
    }, {
        "symbol": "HUSV",
        "label": "First Trust Exchange-Traded Fund III Horizon Managed Volatility Domestic",
        "price": 25.72
    }, {
        "symbol": "IAI",
        "label": "iShares U.S. Broker-Dealers & Securities Exchanges",
        "price": 62.76
    }, {
        "symbol": "IAK",
        "label": "iShares U.S. Insurance",
        "price": 68.5
    }, {
        "symbol": "IAT",
        "label": "iShares U.S. Regional Banks",
        "price": 45.24
    }, {
        "symbol": "IBKCP",
        "label": "IBERIABANK Corporation Depositary Shares Representing Series B Fixed to Floating",
        "price": 27.84
    }, {
        "symbol": "IBMG",
        "label": "iShares iBonds Sep 2018 Term Muni Bond",
        "price": 25.43
    }, {
        "symbol": "ICAN",
        "label": "SerenityShares Impact",
        "price": 27.19
    }, {
        "symbol": "ICOL",
        "label": "iShares Inc MSCI Colombia",
        "price": 12.26
    }, {
        "symbol": "IDHQ",
        "label": "PowerShares S&P International Developed Quality Portfolio (S&P International Dev" +
                "eloped High Quality Ranking Index--index symbol SPIDHQR)",
        "price": 26.6859
    }, {
        "symbol": "IEZ",
        "label": "iShares U.S. Oil Equipment & Services",
        "price": 19.69
    }, {
        "symbol": "IF",
        "label": "Aberdeen Indonesia Fund Inc. (Common Stock",
        "price": 7.19
    }, {
        "symbol": "IFLY",
        "label": "ETFMG Drone Economy Strategy",
        "price": 33.54
    }, {
        "symbol": "IIN",
        "label": "IntriCon Corporation",
        "price": 19.11
    }, {
        "symbol": "INCO",
        "label": "Columbia India Consumer",
        "price": 41.18
    }, {
        "symbol": "INDL",
        "label": "Direxion Daily MSCI India Bull 3x Shares",
        "price": 80.27
    }, {
        "symbol": "INDUU",
        "label": "Industrea Acquisition Corp. Unit",
        "price": 11.16
    }, {
        "symbol": "INTX",
        "label": "Intersections Inc.",
        "price": 3.68
    }, {
        "symbol": "IOO",
        "label": "iShares Global 100",
        "price": 47.31
    }, {
        "symbol": "IRMD",
        "label": "iRadimed Corporation",
        "price": 26.25
    }, {
        "symbol": "ISNS",
        "label": "Image Sensing Systems Inc.",
        "price": 5.46
    }, {
        "symbol": "ITUS",
        "label": "ITUS Corporation",
        "price": 4.7
    }, {
        "symbol": "IWL",
        "label": "iShares Russell Top 200",
        "price": 77.6312
    }, {
        "symbol": "IZRL",
        "label": "ARK Israel Innovative Technology",
        "price": 20.13
    }, {
        "symbol": "JHA",
        "label": "Nuveen High Income December 2018 Target Term Fund",
        "price": 9.93
    }, {
        "symbol": "JJC",
        "label": "iPath Bloomberg Copper Subindex Total Return Sub-Index ETN",
        "price": 33.41
    }, {
        "symbol": "JJG",
        "label": "iPath Bloomberg Grains Total Return Sub-Index ETN",
        "price": 48.3
    }, {
        "symbol": "JKD",
        "label": "iShares Morningstar Large-Cap",
        "price": 191.6
    }, {
        "symbol": "JKE",
        "label": "iShares Morningstar Large-Cap Growth",
        "price": 221.481
    }, {
        "symbol": "JPT",
        "label": "Nuveen Preferred and Income 2022 Term Fund of Beneficial Interest",
        "price": 24.93
    }, {
        "symbol": "JPXN",
        "label": "iShares JPX-Nikkei 400",
        "price": 56.98
    }, {
        "symbol": "JRJC",
        "label": "China Finance Online Co. Limited",
        "price": 7.37
    }, {
        "symbol": "JSYN",
        "label": "Jensyn Acquistion Corp.",
        "price": 10.59
    }, {
        "symbol": "KBWD",
        "label": "PowerShares KBW High Dividend Yield Financial Portfolio",
        "price": 22.49
    }, {
        "symbol": "KBWY",
        "label": "PowerShares KBW Premium Yield Equity REIT Portfolio",
        "price": 31.2045
    }, {
        "symbol": "KEN",
        "label": "Kenon Holdings Ltd.",
        "price": 21.9613
    }, {
        "symbol": "KTOV",
        "label": "Kitov Pharma Ltd.",
        "price": 0.8397
    }, {
        "symbol": "LAC",
        "label": "Lithium Americas Corp.",
        "price": 4.14
    }, {
        "symbol": "LCM",
        "label": "Advent/Claymore Enhanced Growth & Income Fund of Beneficial Interest",
        "price": 7.77
    }, {
        "symbol": "LENS",
        "label": "Presbia PLC",
        "price": 0.33
    }, {
        "symbol": "LEVB",
        "label": "Level Brands Inc.",
        "price": 6.14
    }, {
        "symbol": "LFVN",
        "label": "Lifevantage Corporation",
        "price": 15.67
    }, {
        "symbol": "LMB",
        "label": "Limbach Holdings Inc.",
        "price": 4.41
    }, {
        "symbol": "LOV",
        "label": "Spark Networks Inc. American Depositary Shares (each representing one-tenth of a" +
                "n)",
        "price": 5.02
    }, {
        "symbol": "LTPZ",
        "label": "Pimco 15 Year U.S. TIPS Index Exchange-Traded Fund",
        "price": 73.2615
    }, {
        "symbol": "LYL",
        "label": "Dragon Victory International Limited",
        "price": 1.2813
    }, {
        "symbol": "MACQW",
        "label": "M I Acquisitions Inc. Warrant",
        "price": 1.88
    }, {
        "symbol": "MAGS",
        "label": "Magal Security Systems Ltd.",
        "price": 4.2
    }, {
        "symbol": "MCB",
        "label": "Metropolitan Bank Holding Corp.",
        "price": 47.48
    }, {
        "symbol": "MGIC",
        "label": "Magic Software Enterprises Ltd.",
        "price": 10.51
    }, {
        "symbol": "MHI",
        "label": "Pioneer Municipal High Income Trust of Beneficial Interest",
        "price": 12.25
    }, {
        "symbol": "MLN",
        "label": "VanEck Vectors AMT-Free Long Municipal Index",
        "price": 21.25
    }, {
        "symbol": "MLVF",
        "label": "Malvern Bancorp Inc.",
        "price": 21.66
    }, {
        "symbol": "MNGA",
        "label": "MagneGas Corporation",
        "price": 0.83
    }, {
        "symbol": "MOSY",
        "label": "MoSys Inc.",
        "price": 1.9
    }, {
        "symbol": "MRDN",
        "label": "Meridian Waste Solutions Inc",
        "price": 0.6
    }, {
        "symbol": "MRRL",
        "label": "ETRACS Monthly Pay 2xLeveraged Mortgage REIT ETN Series B due October 16 2042",
        "price": 12.76
    }, {
        "symbol": "MSP",
        "label": "Madison Strategic Sector Premium Fund of Beneficial Interest",
        "price": 11.63
    }, {
        "symbol": "MTR",
        "label": "Mesa Royalty Trust",
        "price": 7.55
    }, {
        "symbol": "MUA",
        "label": "Blackrock MuniAssets Fund Inc",
        "price": 15.73
    }, {
        "symbol": "MVV",
        "label": "ProShares Ultra MidCap400",
        "price": 35.82
    }, {
        "symbol": "MXI",
        "label": "iShares Global Materials",
        "price": 62.81
    }, {
        "symbol": "MYD",
        "label": "Blackrock MuniYield Fund Inc.",
        "price": 15.02
    }, {
        "symbol": "MZA",
        "label": "Blackrock MuniYield Arizona Fund Inc.",
        "price": 14.3
    }, {
        "symbol": "NAII",
        "label": "Natural Alternatives International Inc.",
        "price": 9.25
    }, {
        "symbol": "NGE",
        "label": "Global X MSCI Nigeria",
        "price": 15.47
    }, {
        "symbol": "NID",
        "label": "Nuveen Intermediate Duration Municipal Term Fund of Beneficial Interest",
        "price": 13.86
    }, {
        "symbol": "NJV",
        "label": "Nuveen New Jersey Municipal Value Fund of Beneficial Interest",
        "price": 13.98
    }, {
        "symbol": "NORW",
        "label": "Global X MSCI Norway",
        "price": 12.19
    }, {
        "symbol": "NTN",
        "label": "NTN Buzztime Inc.",
        "price": 2.4289
    }, {
        "symbol": "NVFY",
        "label": "Nova Lifestyle Inc",
        "price": 1.95
    }, {
        "symbol": "NVUS",
        "label": "Novus Therapeutics Inc.",
        "price": 0.6919
    }, {
        "symbol": "NXJ",
        "label": "Nuveen New Jersey Qualified Municipal Fund",
        "price": 14.75
    }, {
        "symbol": "NXN",
        "label": "Nuveen New York Select Tax-Free Income Portfolio",
        "price": 14.1795
    }, {
        "symbol": "NYH",
        "label": "Eaton Vance New York Municipal Bond Fund II of Beneficial Interest $.01 par valu" +
                "e",
        "price": 10.52
    }, {
        "symbol": "OCIP",
        "label": "OCI Partners LP representing Limited Partner Interests",
        "price": 11.5
    }, {
        "symbol": "OGEN",
        "label": "Oragenics Inc.",
        "price": 0.4621
    }, {
        "symbol": "OIIM",
        "label": "O2Micro International Limited",
        "price": 1.64
    }, {
        "symbol": "OMAA",
        "label": "OM Asset Management plc 5.125% Notes due 2031",
        "price": 23.94
    }, {
        "symbol": "OMNT",
        "label": "Ominto Inc.",
        "price": 0.66
    }, {
        "symbol": "ONTL",
        "label": "O'Shares FTSE Russell International Quality Dividend",
        "price": 24.22
    }, {
        "symbol": "OPNT",
        "label": "Opiant Pharmaceuticals Inc.",
        "price": 13.36
    }, {
        "symbol": "OSN",
        "label": "Ossen Innovation Co. Ltd.",
        "price": 3.2
    }, {
        "symbol": "PBCTP",
        "label": "People's United Financial Inc. Perpetual Preferred Series A Fixed-to-floating Ra" +
                "te",
        "price": 28.4
    }, {
        "symbol": "PDN",
        "label": "PowerShares FTSE RAFI Developed Markets ex-U.S. Small-Mid Portfolio",
        "price": 29.2
    }, {
        "symbol": "PERI",
        "label": "Perion Network Ltd",
        "price": 8.97
    }, {
        "symbol": "PFIE",
        "label": "Profire Energy Inc.",
        "price": 1.44
    }, {
        "symbol": "PHX",
        "label": "Panhandle Oil and Gas Inc",
        "price": 8.87
    }, {
        "symbol": "PIY",
        "label": "Preferred Plus Trust (Ser CZN) Ser CZN-1 Tr Ctf 8.375% Maturity 10/01/2046",
        "price": 10.74
    }, {
        "symbol": "PKOH",
        "label": "Park-Ohio Holdings Corp.",
        "price": 34.82
    }, {
        "symbol": "POLA",
        "label": "Polar Power Inc.",
        "price": 2.98
    }, {
        "symbol": "PPIH",
        "label": "Perma-Pipe International Holdings Inc.",
        "price": 9.24
    }, {
        "symbol": "PPSI",
        "label": "Pioneer Power Solutions Inc.",
        "price": 2.4797
    }, {
        "symbol": "PPX",
        "label": "PPL Capital Funding Inc. 2013 Series B Junior Subordinated Notes due 2073",
        "price": 26.16
    }, {
        "symbol": "PRME",
        "label": "First Trust Heitman Global Prime Real Estate",
        "price": 19.2
    }, {
        "symbol": "PSCM",
        "label": "PowerShares S&P SmallCap Materials Portfolio",
        "price": 46.9703
    }, {
        "symbol": "PSJ",
        "label": "PowerShares Dynamic Software",
        "price": 104.01
    }, {
        "symbol": "PSK",
        "label": "SPDR Wells Fargo Preferred Stock",
        "price": 42.9
    }, {
        "symbol": "PST",
        "label": "ProShares UltraShort Lehman 7-10 Year Treasury",
        "price": 19.92
    }, {
        "symbol": "PTMC",
        "label": "Pacer Trendpilot US Mid Cap",
        "price": 28.51
    }, {
        "symbol": "PUTW",
        "label": "WisdomTree CBOE S&P 500 PutWrite Strategy Fund",
        "price": 26.78
    }, {
        "symbol": "PXE",
        "label": "PowerShares Dynamic Energy E&P",
        "price": 15.56
    }, {
        "symbol": "PXF",
        "label": "PowerShares FTSE RAFI Developed Markets ex-U.S. Portfolio",
        "price": 42.71
    }, {
        "symbol": "PXI",
        "label": "PowerShares DWA Energy Momentum Portfolio",
        "price": 27.0003
    }, {
        "symbol": "QABA",
        "label": "First Trust NASDAQ ABA Community Bank Index Fund",
        "price": 51.38
    }, {
        "symbol": "QDF",
        "label": "FlexShares Quality Dividend Index Fund",
        "price": 49.31
    }, {
        "symbol": "QQQE",
        "label": "Direxion NASDAQ-100 Equal Weighted Index Shares",
        "price": 46.86
    }, {
        "symbol": "RAVE",
        "label": "Rave Restaurant Group Inc.",
        "price": 1.61
    }, {
        "symbol": "REK",
        "label": "ProShares Short Real Estate",
        "price": 14.42
    }, {
        "symbol": "RETO",
        "label": "ReTo Eco-Solutions Inc.",
        "price": 1.04
    }, {
        "symbol": "RFEM",
        "label": "First Trust RiverFront Dynamic Emerging Markets ETF",
        "price": 67.0638
    }, {
        "symbol": "RFG",
        "label": "Guggenheim S&P Midcap 400 Pure Growth",
        "price": 140.16
    }, {
        "symbol": "RGLS",
        "label": "Regulus Therapeutics Inc.",
        "price": 1.21
    }, {
        "symbol": "RJN",
        "label": "AB Svensk Ekportkredit (Swedish Export Credit Corporation) ELEMENTS Linked to th" +
                "e Rogers International Commodity Index - Energy Total Return Structured Product",
        "price": 2.7
    }, {
        "symbol": "RLY",
        "label": "SPDR SSgA Multi Asset Real Return",
        "price": 24.83
    }, {
        "symbol": "RMGN",
        "label": "RMG Networks Holding Corporation",
        "price": 1.28
    }, {
        "symbol": "RNN",
        "label": "Rexahn Pharmaceuticals Inc.",
        "price": 5.19
    }, {
        "symbol": "ROSE",
        "label": "Rosehill Resources Inc.",
        "price": 1.22
    }, {
        "symbol": "RVSB",
        "label": "Riverview Bancorp Inc",
        "price": 7.68
    }, {
        "symbol": "RZB",
        "label": "Reinsurance Group of America Incorporated 5.75% Fixed-To-Floating Rate Subordina" +
                "ted Debentures due 2056",
        "price": 29.86
    }, {
        "symbol": "SACH",
        "label": "Sachem Capital Corp.",
        "price": 4.34
    }, {
        "symbol": "SANW",
        "label": "S&W Seed Company",
        "price": 2.28
    }, {
        "symbol": "SBNA",
        "label": "Scorpio Tankers Inc. 6.75% Senior Notes due 2020",
        "price": 25.4426
    }, {
        "symbol": "SGBX",
        "label": "SG Blocks Inc.",
        "price": 0.178
    }, {
        "symbol": "SGOC",
        "label": "SGOCO Group Ltd",
        "price": 0.9119
    }, {
        "symbol": "SILJ",
        "label": "ETFMG Prime Junior Silver",
        "price": 7.51
    }, {
        "symbol": "SKF",
        "label": "ProShares UltraShort Financials",
        "price": 17.8
    }, {
        "symbol": "SKYS",
        "label": "Sky Solar Holdings Ltd.",
        "price": 1.47
    }, {
        "symbol": "SKYY",
        "label": "First Trust Cloud Computing ETF",
        "price": 64.9
    }, {
        "symbol": "SLDA",
        "label": "Sutherland Asset Management Corporation 7.00% Convertible Senior Notes due 2023",
        "price": 26.08
    }, {
        "symbol": "SLS",
        "label": "SELLAS Life Sciences Group Inc.",
        "price": 3.07
    }, {
        "symbol": "SMB",
        "label": "VanEck Vectors AMT-Free Short Municipal Index",
        "price": 17.65
    }, {
        "symbol": "SMIT",
        "label": "Schmitt Industries Inc.",
        "price": 3.48
    }, {
        "symbol": "SMMF",
        "label": "Summit Financial Group Inc.",
        "price": 25.22
    }, {
        "symbol": "SOCL",
        "label": "Global X Social Media ETF",
        "price": 37.6
    }, {
        "symbol": "SPLP",
        "label": "Steel Partners Holdings LP LTD PARTNERSHIP UNIT",
        "price": 11.71
    }, {
        "symbol": "SRLP",
        "label": "Sprague Resources LP representing Limited Partner Interests",
        "price": 16.81
    }, {
        "symbol": "STAF",
        "label": "Staffing 360 Solutions Inc.",
        "price": 0.9015
    }, {
        "symbol": "STPZ",
        "label": "PIMCO 1-5 Year U.S. TIPS Index Exchange-Traded Fund",
        "price": 52.44
    }, {
        "symbol": "STRM",
        "label": "Streamline Health Solutions Inc.",
        "price": 1.27
    }, {
        "symbol": "STRT",
        "label": "Strattec Security Corporation",
        "price": 22.1
    }, {
        "symbol": "SYN",
        "label": "Synthetic Biologics Inc.",
        "price": 0.51
    }, {
        "symbol": "TANNI",
        "label": "TravelCenters of America LLC 8.25% Senior Notes due 2028",
        "price": 25.38
    }, {
        "symbol": "TBB",
        "label": "AT&T Inc. 5.350% Global Notes due 2066",
        "price": 26.76
    }, {
        "symbol": "TDA",
        "label": "Telephone and Data Systems Inc. 5.875% Senior Notes due 2061",
        "price": 25.22
    }, {
        "symbol": "TDE",
        "label": "Telephone and Data Systems Inc. 6.875% Senior Notes due 2059",
        "price": 25.91
    }, {
        "symbol": "TDF",
        "label": "Templeton Dragon Fund Inc.",
        "price": 20.3
    }, {
        "symbol": "TENX",
        "label": "Tenax Therapeutics Inc.",
        "price": 1.28
    }, {
        "symbol": "TESS",
        "label": "TESSCO Technologies Incorporated",
        "price": 11.24
    }, {
        "symbol": "TETF",
        "label": "ETF Industry Exposure & Financial Services",
        "price": 20.1073
    }, {
        "symbol": "THW",
        "label": "Tekla World Healthcare Fund Shares of Beneficial Interest",
        "price": 14.21
    }, {
        "symbol": "TILT",
        "label": "FlexShares Mornigstar US Market Factors Tilt Index Fund",
        "price": 112.54
    }, {
        "symbol": "TLF",
        "label": "Tandy Leather Factory Inc.",
        "price": 5.2444
    }, {
        "symbol": "TNXP",
        "label": "Tonix Pharmaceuticals Holding Corp.",
        "price": 1.55
    }, {
        "symbol": "TPL",
        "label": "Texas Pacific Land Trust",
        "price": 792.86
    }, {
        "symbol": "TPOR",
        "label": "Direxion Daily Transportation Bull 3X Shares",
        "price": 24.98
    }, {
        "symbol": "TRIB",
        "label": "Trinity Biotech plc",
        "price": 1.04
    }, {
        "symbol": "TSLF",
        "label": "THL Credit Senior Loan Fund of Beneficial Interest",
        "price": 15.29
    }, {
        "symbol": "TTP",
        "label": "Tortoise Pipeline & Energy Fund Inc.",
        "price": 13.51
    }, {
        "symbol": "TWMC",
        "label": "Trans World Entertainment Corp.",
        "price": 2.36
    }, {
        "symbol": "UCBA",
        "label": "United Community Bancorp",
        "price": 27.75
    }, {
        "symbol": "UFI",
        "label": "Unifi Inc.",
        "price": 26.34
    }, {
        "symbol": "URTY",
        "label": "ProShares UltraPro Russell2000",
        "price": 65.49
    }, {
        "symbol": "USAU",
        "label": "U.S. Gold Corp.",
        "price": 0.8456
    }, {
        "symbol": "UUU",
        "label": "Universal Security Instruments Inc.",
        "price": 0.759799
    }, {
        "symbol": "UZA",
        "label": "United States Cellular Corporation 6.95% Senior Notes due 2060",
        "price": 26.13
    }, {
        "symbol": "VAMO",
        "label": "Cambria ETF Trust Value and Momentum",
        "price": 19.74
    }, {
        "symbol": "VAW",
        "label": "Vanguard Materials",
        "price": 133.25
    }, {
        "symbol": "VCO",
        "label": "Vina Concha Y Toro",
        "price": 41.86
    }, {
        "symbol": "VGI",
        "label": "Virtus Global Multi-Sector Income Fund of Beneficial Interest",
        "price": 13.2
    }, {
        "symbol": "VIGI",
        "label": "Vanguard International Dividend Appreciation ETF",
        "price": 74.0716
    }, {
        "symbol": "VIOG",
        "label": "Vanguard S&P Small-Cap 600 Growth",
        "price": 146.73
    }, {
        "symbol": "VJET",
        "label": "voxeljet AG American Depositary Shares each representing one-fifth of an/",
        "price": 1.9562
    }, {
        "symbol": "VMAX",
        "label": "REX VolMAXX Long VIX Weekly Futures Strategy",
        "price": 18.62
    }, {
        "symbol": "VPV",
        "label": "Invesco Pennsylvania Value Municipal Income Trust (DE)",
        "price": 13.39
    }, {
        "symbol": "VTRB",
        "label": "Ventas Realty Limited Partnership // Capital Corporation 5.45% Senior Notes due " +
                "2043",
        "price": 24.87
    }, {
        "symbol": "VVPR",
        "label": "VivoPower International PLC",
        "price": 1.07
    }, {
        "symbol": "WDIV",
        "label": "SPDR S&P Global Dividend",
        "price": 65.35
    }, {
        "symbol": "WEAT",
        "label": "Teucrium Wheat Fund ETV",
        "price": 5.55
    }, {
        "symbol": "WHF",
        "label": "WhiteHorse Finance Inc.",
        "price": 14.02
    }, {
        "symbol": "WHLRP",
        "label": "Wheeler Real Estate Investment Trust Inc. Preferred Stock",
        "price": 11.26
    }, {
        "symbol": "WYY",
        "label": "WidePoint Corporation",
        "price": 0.42885
    }, {
        "symbol": "XENE",
        "label": "Xenon Pharmaceuticals Inc.",
        "price": 17.4
    }, {
        "symbol": "XHS",
        "label": "SPDR S&P Health Care Services",
        "price": 64.32
    }, {
        "symbol": "XNTK",
        "label": "SPDR NYSE Technology",
        "price": 67.58
    }, {
        "symbol": "XSOE",
        "label": "WisdomTree Emerging Markets Ex-State Owned Enterprises Fund",
        "price": 32.5
    }, {
        "symbol": "XSW",
        "label": "SPDR S&P Software & Services",
        "price": 89.88
    }, {
        "symbol": "XTNT",
        "label": "Xtant Medical Holdings Inc.",
        "price": 1.5
    }, {
        "symbol": "YCL",
        "label": "ProShares Ultra Yen",
        "price": 56.42
    }, {
        "symbol": "YECO",
        "label": "Yulong Eco-Materials Limited",
        "price": 5.03
    }, {
        "symbol": "YGE",
        "label": "Yingli Green Energy Holding Company Limited ADR",
        "price": 1.38
    }, {
        "symbol": "YGYI",
        "label": "Youngevity International Inc.",
        "price": 2.63
    }, {
        "symbol": "YLCO",
        "label": "Global X Yieldco Index ETF",
        "price": 15.525
    }, {
        "symbol": "YOGA",
        "label": "YogaWorks Inc.",
        "price": 0.81
    }, {
        "symbol": "ZDGE",
        "label": "Zedge Inc. Class B",
        "price": 1.57
    }, {
        "symbol": "ZF",
        "label": "Virtus Total Return Fund Inc.",
        "price": 11.22
    }, {
        "symbol": "ZNH",
        "label": "China Southern Airlines Company Limited",
        "price": 34.2
    }, {
        "symbol": "MTLS",
        "label": "Materialise NV",
        "price": 18.96
    }, {
        "symbol": "RZG",
        "label": "Guggenheim S&P Smallcap 600 Pure Growth",
        "price": 106.75
    }, {
        "symbol": "JJN",
        "label": "iPath Bloomberg Nickel Subindex Total Return ETN",
        "price": 49.42
    }, {
        "symbol": "CSSE",
        "label": "Chicken Soup for the Soul Entertainment Inc.",
        "price": 8.7
    }, {
        "symbol": "AMCA",
        "label": "iShares Russell 1000 Pure U.S. Revenue ETF",
        "price": 31.0387
    }, {
        "symbol": "MSEX",
        "label": "Middlesex Water Company",
        "price": 66.13
    }, {
        "symbol": "EWGS",
        "label": "Ishares MSCI Germany Small Cap",
        "price": 54.58
    }, {
        "symbol": "NAP",
        "label": "Navios Maritime Midstream Partners LP representing limited partner interests",
        "price": 2.5
    }, {
        "symbol": "RFEU",
        "label": "First Trust RiverFront Dynamic Europe ETF",
        "price": 63.1424
    }, {
        "symbol": "BGIO",
        "label": "BlackRock 2022 Global Income Opportunity Trust of Beneficial Interest",
        "price": 9.77
    }, {
        "symbol": "ASHS",
        "label": "Xtrackers Harvest CSI 500 China A-Shares Small Cap",
        "price": 25.47
    }, {
        "symbol": "DEEF",
        "label": "Xtrackers FTSE Developed ex US Comprehensive Factor",
        "price": 27.15
    }, {
        "symbol": "GEX",
        "label": "VanEck Vectors Global Alternative Energy",
        "price": 60.68
    }, {
        "symbol": "HGH",
        "label": "Hartford Financial Services Group Inc. (The) 7.875% Fixed to Floating Rate Junio" +
                "r Subordinated Debentures due 2042",
        "price": 28.56
    }, {
        "symbol": "JPUS",
        "label": "JPMorgan Diversified Return U.S. Equity",
        "price": 72.07
    }, {
        "symbol": "KOIN",
        "label": "Innovation Shares NextGen Protocol",
        "price": 26.08
    }, {
        "symbol": "NGHCO",
        "label": "National General Holdings Corp Depositary Shares",
        "price": 25.49
    }, {
        "symbol": "PED",
        "label": "Pedevco Corp.",
        "price": 1.61
    }, {
        "symbol": "PRTO",
        "label": "Proteon Therapeutics Inc.",
        "price": 0.8352
    }, {
        "symbol": "SEB",
        "label": "Seaboard Corporation",
        "price": 4235.0
    }, {
        "symbol": "EMGF",
        "label": "iShares Edge MSCI Multifactor Emerging Markets",
        "price": 40.2
    }, {
        "symbol": "FVAL",
        "label": "Fidelity Value Factor",
        "price": 32.46
    }, {
        "symbol": "SFST",
        "label": "Southern First Bancshares Inc.",
        "price": 41.805
    }, {
        "symbol": "DTH",
        "label": "WisdomTree International High Dividend Fund",
        "price": 42.18
    }, {
        "symbol": "FIGY",
        "label": "Barclays ETN FI Enhanced Global High Yield ETN",
        "price": 159.92
    }, {
        "symbol": "KXI",
        "label": "iShares Global Consumer Staples",
        "price": 52.15
    }, {
        "symbol": "PALL",
        "label": "ETFS Physical Palladium Shares",
        "price": 125.42
    }, {
        "symbol": "PEJ",
        "label": "PowerShares Dynamic Leisure & Entertainment Portfolio",
        "price": 42.3
    }, {
        "symbol": "RA",
        "label": "Brookfield Real Assets Income Fund Inc.",
        "price": 21.85
    }, {
        "symbol": "SHI",
        "label": "SINOPEC Shangai Petrochemical Company Ltd.",
        "price": 30.0
    }, {
        "symbol": "DLN",
        "label": "WisdomTree U.S. LargeCap Dividend Fund",
        "price": 107.92
    }, {
        "symbol": "GCC",
        "label": "WisdomTree Continuous Commodity Index Fund",
        "price": 17.61
    }, {
        "symbol": "LCNB",
        "label": "LCNB Corporation",
        "price": 18.15
    }, {
        "symbol": "PBS",
        "label": "PowerShares Dynamic Media",
        "price": 32.24
    }, {
        "symbol": "SSG",
        "label": "ProShares UltraShort Semiconductors",
        "price": 29.98
    }, {
        "symbol": "PICK",
        "label": "iShares MSCI Global Select Metals & Mining Producers Fund",
        "price": 30.25
    }, {
        "symbol": "ISF",
        "label": "ING Group N.V. Perp Hybrid Cap Secs (Netherlands)",
        "price": 25.83
    }, {
        "symbol": "RTH",
        "label": "VanEck Vectors Retail",
        "price": 102.9
    }, {
        "symbol": "SGZA",
        "label": "Selective Insurance Group Inc. 5.875% Senior Notes due 2043",
        "price": 25.16
    }, {
        "symbol": "SMEZ",
        "label": "SPDR EURO STOXX Small Cap",
        "price": 62.56
    }, {
        "symbol": "XLG",
        "label": "Guggenheim S&P 500 Top 50",
        "price": 241.833
    }, {
        "symbol": "EUXL",
        "label": "Direxion Daily EURO STOXX 50? Bull 3X Shares",
        "price": 19.08
    }, {
        "symbol": "FRI",
        "label": "First Trust S&P REIT Index Fund",
        "price": 25.1
    }, {
        "symbol": "DPST",
        "label": "Direxion Daily Regional Banks Bull 3X Shares",
        "price": 41.07
    }, {
        "symbol": "PZG",
        "label": "Paramount Gold Nevada Corp.",
        "price": 0.776646
    }, {
        "symbol": "ROAM",
        "label": "Hartford Multifactor Emerging Markets",
        "price": 22.82
    }, {
        "symbol": "VRML",
        "label": "Vermillion Inc.",
        "price": 0.8047
    }, {
        "symbol": "DDE",
        "label": "Dover Downs Gaming & Entertainment Inc",
        "price": 2.6
    }, {
        "symbol": "UG",
        "label": "United-Guardian Inc.",
        "price": 19.38
    }, {
        "symbol": "XTN",
        "label": "SPDR S&P Transportation",
        "price": 58.52
    }, {
        "symbol": "GENY",
        "label": "Principal Millennials Index ETF",
        "price": 43.689
    }, {
        "symbol": "RIGS",
        "label": "RiverFront Strategic Income Fund",
        "price": 24.845
    }, {
        "symbol": "SMDD",
        "label": "UltraPro Short MidCap400",
        "price": 8.85
    }, {
        "symbol": "NES",
        "label": "Nuverra Environmental Solutions Inc.",
        "price": 2.94
    }, {
        "symbol": "SBBX",
        "label": "Sussex Bancorp",
        "price": 24.45
    }, {
        "symbol": "WLFC",
        "label": "Willis Lease Finance Corporation",
        "price": 58.75
    }, {
        "symbol": "ACER",
        "label": "Acer Therapeutics Inc.",
        "price": 4.33
    }, {
        "symbol": "BSWN",
        "label": "UBS AG VelocityShares VIX Tail Risk ETN linked to the S&P 500 VIX Futures Tail R" +
                "isk Index Short Term due July 18 2046",
        "price": 12.7801
    }, {
        "symbol": "EVBN",
        "label": "Evans Bancorp Inc.",
        "price": 39.49
    }, {
        "symbol": "FEUZ",
        "label": "First Trust Eurozone AlphaDEX ETF",
        "price": 40.87
    }, {
        "symbol": "EDV",
        "label": "Vanguard Extended Duration Treasury",
        "price": 133.33
    }, {
        "symbol": "FALN",
        "label": "iShares Fallen Angels USD Bond ETF",
        "price": 27.47
    }, {
        "symbol": "GCBC",
        "label": "Greene County Bancorp Inc.",
        "price": 29.15
    }, {
        "symbol": "PEK",
        "label": "VanEck Vectors ChinaAMC CSI 3000",
        "price": 43.3039
    }, {
        "symbol": "SOHOB",
        "label": "Sotherly Hotels Inc. 8.0% Series B Cumulative Redeemable Perpetual Preferred Sto" +
                "ck",
        "price": 26.31
    }, {
        "symbol": "DTJ",
        "label": "DTE Energy Company 2016 Series B 5.375% Junior Subordinated Debentures due 2076",
        "price": 26.43
    }, {
        "symbol": "PLPC",
        "label": "Preformed Line Products Company",
        "price": 59.7
    }, {
        "symbol": "FBIZ",
        "label": "First Business Financial Services Inc.",
        "price": 26.13
    }, {
        "symbol": "BZF",
        "label": "WisdomTree Brazilian Real Strategy Fund",
        "price": 16.32
    }, {
        "symbol": "CLIR",
        "label": "ClearSign Combustion Corporation",
        "price": 1.0
    }, {
        "symbol": "FEEU",
        "label": "Barclays ETN FI Enhanced Europe 50 ETN",
        "price": 119.72
    }, {
        "symbol": "AMSWA",
        "label": "American Software Inc. Class A Common Stock",
        "price": 14.83
    }, {
        "symbol": "JPEM",
        "label": "JPMorgan Diversified Return Emerging Markets Equity",
        "price": 53.78
    }, {
        "symbol": "RWK",
        "label": "Oppenheimer Mid Cap Revenue",
        "price": 65.5586
    }, {
        "symbol": "RZA",
        "label": "Reinsurance Group of America Incorporated 6.20% Fixed-to-Floating Rate Subordina" +
                "ted Debentures due 2042",
        "price": 27.83
    }, {
        "symbol": "VLRX",
        "label": "Valeritas Holdings Inc.",
        "price": 0.7948
    }, {
        "symbol": "PMR",
        "label": "PowerShares Dynamic Retail",
        "price": 37.75
    }, {
        "symbol": "MKGI",
        "label": "Monaker Group Inc.",
        "price": 2.02
    }, {
        "symbol": "CRAK",
        "label": "VanEck Vectors Oil Refiners",
        "price": 28.14
    }, {
        "symbol": "FINU",
        "label": "ProShares UltraPro Financial Select Sector",
        "price": 86.15
    }, {
        "symbol": "MIN",
        "label": "MFS Intermediate Income Trust",
        "price": 3.79
    }, {
        "symbol": "CORP",
        "label": "Pimco Investment Grade Corporate Bond Index Exchange-Traded Fund",
        "price": 105.2
    }, {
        "symbol": "CXH",
        "label": "MFS Investment Grade Municipal Trust",
        "price": 9.92
    }, {
        "symbol": "FNG",
        "label": "AdvisorShares New Tech and Media",
        "price": 14.03
    }, {
        "symbol": "FVC",
        "label": "First Trust Dorsey Wright Dynamic Focus 5 ETF",
        "price": 28.67
    }, {
        "symbol": "ACIM",
        "label": "SPDR MSCI ACWI IMI",
        "price": 80.4787
    }, {
        "symbol": "ROOF",
        "label": "IQ US Real Estate Small Cap",
        "price": 25.13
    }, {
        "symbol": "MIND",
        "label": "Mitcham Industries Inc.",
        "price": 3.25
    }, {
        "symbol": "OUSA",
        "label": "O'Shares FTSE U.S. Quality Dividend",
        "price": 32.52
    }, {
        "symbol": "IRCP",
        "label": "IRSA Propiedades Comerciales S.A.",
        "price": 13.59
    }, {
        "symbol": "RUSHB",
        "label": "Rush Enterprises Inc. Class B Common Stock",
        "price": 47.47
    }, {
        "symbol": "CMCT",
        "label": "CIM Commercial Trust Corporation",
        "price": 14.65
    }, {
        "symbol": "JJU",
        "label": "iPath Bloomberg Aluminum Subindex Total Return SM IndexETN",
        "price": 19.51
    }, {
        "symbol": "NCBS",
        "label": "Nicolet Bankshares Inc.",
        "price": 71.48
    }, {
        "symbol": "FYT",
        "label": "First Trust Small Cap Value AlphaDEX Fund",
        "price": 37.9601
    }, {
        "symbol": "PBJ",
        "label": "PowerShares Dynamic Food & Beverage",
        "price": 32.92
    }, {
        "symbol": "CXSE",
        "label": "WisdomTree China ex-State-Owned Enterprises Fund",
        "price": 89.48
    }, {
        "symbol": "LWAY",
        "label": "Lifeway Foods Inc.",
        "price": 2.51
    }, {
        "symbol": "AGZD",
        "label": "WisdomTree Barclays Interest Rate Hedged U.S. Aggregate Bond Fund",
        "price": 48.2167
    }, {
        "symbol": "RWL",
        "label": "Oppenheimer Large Cap Revenue",
        "price": 59.37
    }, {
        "symbol": "VTWV",
        "label": "Vanguard Russell 2000 Value ETF",
        "price": 112.42
    }, {
        "symbol": "FCCO",
        "label": "First Community Corporation",
        "price": 20.43
    }, {
        "symbol": "FLEU",
        "label": "Barclays ETN FI Enhanced Europe 50 ETN Series B",
        "price": 131.12
    }, {
        "symbol": "IPFF",
        "label": "iShares International Preferred Stock",
        "price": 15.58
    }, {
        "symbol": "SLCT",
        "label": "Select Bancorp Inc.",
        "price": 11.97
    }, {
        "symbol": "WTFCM",
        "label": "Wintrust Financial Corporation Fixed-to-Floating Rate Non-Cumulative Perpetual P" +
                "referred Stock Series D",
        "price": 29.71
    }, {
        "symbol": "FLEE",
        "label": "Franklin FTSE Europe",
        "price": 24.23
    }, {
        "symbol": "OFED",
        "label": "Oconee Federal Financial Corp.",
        "price": 27.24
    }, {
        "symbol": "EES",
        "label": "WisdomTree U.S. SmallCap Earnings Fund",
        "price": 38.18
    }, {
        "symbol": "SINO",
        "label": "Sino-Global Shipping America Ltd.",
        "price": 0.46
    }, {
        "symbol": "BCOM",
        "label": "B Communications Ltd.",
        "price": 1.61
    }, {
        "symbol": "FIEE",
        "label": "UBS AG FI Enhanced Europe 50 ETN due February 12 2026",
        "price": 150.31
    }, {
        "symbol": "LMHB",
        "label": "Legg Mason Inc. 5.45% Junior Subordinated Notes due 2056",
        "price": 26.11
    }, {
        "symbol": "NWLI",
        "label": "National Western Life Group Inc.",
        "price": 284.6
    }, {
        "symbol": "NYMTP",
        "label": "New York Mortgage Trust Inc. 7.75% Series B Cumulative Redeemable Preferred Stoc" +
                "k",
        "price": 25.35
    }, {
        "symbol": "SWP",
        "label": "Stanley Black & Decker Inc. Corporate Units",
        "price": 111.71
    }, {
        "symbol": "CATC",
        "label": "Cambridge Bancorp",
        "price": 76.39
    }, {
        "symbol": "EDOG",
        "label": "ALPS Emerging Sector Dividend Dogs",
        "price": 21.66
    }, {
        "symbol": "EWMC",
        "label": "Guggenheim S&P MidCap 400 Equal Weight",
        "price": 62.02
    }, {
        "symbol": "KORU",
        "label": "Direxion Daily South Korea Bull 3X Shares",
        "price": 20.48
    }, {
        "symbol": "SAFT",
        "label": "Safety Insurance Group Inc.",
        "price": 95.47
    }, {
        "symbol": "SCIN",
        "label": "Columbia India Small Cap",
        "price": 13.72
    }, {
        "symbol": "PILL",
        "label": "Direxion Daily Pharmaceutical & Medical Bull 3X Shares",
        "price": 16.01
    }, {
        "symbol": "SGRP",
        "label": "SPAR Group Inc.",
        "price": 1.195
    }, {
        "symbol": "TPVG",
        "label": "TriplePoint Venture Growth BDC Corp.",
        "price": 13.89
    }, {
        "symbol": "VDTH",
        "label": "Videocon d2h Limited",
        "price": 8.24
    }, {
        "symbol": "DNBF",
        "label": "DNB Financial Corp",
        "price": 46.19
    }, {
        "symbol": "ICAD",
        "label": "icad inc.",
        "price": 9.41
    }, {
        "symbol": "RXI",
        "label": "iShares Global Consumer Discretionary",
        "price": 113.4
    }, {
        "symbol": "AEH",
        "label": "AEGON N.V. Perp. Cap Secs.",
        "price": 25.67
    }, {
        "symbol": "DEW",
        "label": "WisdomTree Global High Dividend Fund",
        "price": 45.65
    }, {
        "symbol": "DVYE",
        "label": "iShares Emerging Markets Dividend Index Fund Exchange Traded Fund",
        "price": 41.99
    }, {
        "symbol": "MICT",
        "label": "Micronet Enertec Technologies Inc.",
        "price": 1.18
    }, {
        "symbol": "NRIM",
        "label": "Northrim BanCorp Inc",
        "price": 38.2
    }, {
        "symbol": "TAIL",
        "label": "Cambria Tail Risk",
        "price": 21.06
    }, {
        "symbol": "EBMT",
        "label": "Eagle Bancorp Montana Inc.",
        "price": 22.5
    }, {
        "symbol": "ICBK",
        "label": "County Bancorp Inc.",
        "price": 26.84
    }, {
        "symbol": "SYE",
        "label": "SPDR MFS Systematic Core Equity",
        "price": 92.0635
    }, {
        "symbol": "BFS",
        "label": "Saul Centers Inc.",
        "price": 53.68
    }, {
        "symbol": "DEF",
        "label": "Guggenheim Defensive Equity",
        "price": 49.9
    }, {
        "symbol": "DGL",
        "label": "PowerShares DB Gold Fund",
        "price": 40.75
    }, {
        "symbol": "EMFM",
        "label": "Global X Next Emerging & Frontier",
        "price": 20.11
    }, {
        "symbol": "FGBI",
        "label": "First Guaranty Bancshares Inc.",
        "price": 20.98
    }, {
        "symbol": "GRVY",
        "label": "GRAVITY Co. Ltd.",
        "price": 36.5
    }, {
        "symbol": "HIFS",
        "label": "Hingham Institution for Savings",
        "price": 209.11
    }, {
        "symbol": "HX",
        "label": "Hexindai Inc.",
        "price": 0.5999
    }, {
        "symbol": "ITIC",
        "label": "Investors Title Company",
        "price": 164.05
    }, {
        "symbol": "MLAB",
        "label": "Mesa Laboratories Inc.",
        "price": 265.55
    }, {
        "symbol": "PEBK",
        "label": "Peoples Bancorp of North Carolina Inc.",
        "price": 30.45
    }, {
        "symbol": "PVBC",
        "label": "Provident Bancorp Inc.",
        "price": 12.35
    }, {
        "symbol": "PYT",
        "label": "PPlus Tr GSC-2 Tr Ctf Fltg Rate",
        "price": 22.4
    }, {
        "symbol": "SBFG",
        "label": "SB Financial Group Inc.",
        "price": 19.29
    }, {
        "symbol": "SGMA",
        "label": "SigmaTron International Inc.",
        "price": 4.0
    }, {
        "symbol": "SMMT",
        "label": "Summit Therapeutics plc",
        "price": 1.55
    }, {
        "symbol": "TAC",
        "label": "TransAlta Corporation",
        "price": 7.13
    }, {
        "symbol": "TCI",
        "label": "Transcontinental Realty Investors Inc.",
        "price": 31.44
    }, {
        "symbol": "WSKY",
        "label": "Spirited Funds/ETFMG Whiskey & Spirits",
        "price": 32.51
    }, {
        "symbol": "AADR",
        "label": "AdvisorShares Dorsey Wright ADR",
        "price": 45.47
    }, {
        "symbol": "AAMC",
        "label": "Altisource Asset Management Corp Com",
        "price": 12.9934
    }, {
        "symbol": "AAME",
        "label": "Atlantic American Corporation",
        "price": 1.9
    }, {
        "symbol": "AAU",
        "label": "Almaden Minerals Ltd.",
        "price": 0.5792
    }, {
        "symbol": "ABE",
        "label": "Aberdeen Emerging Markets Company Oppotunities Fund Inc.",
        "price": 14.74
    }, {
        "symbol": "ACGLO",
        "label": "Arch Capital Group Ltd. Depositary Shares Each Representing 1/1000th Interest in" +
                " a Share of5.45% Non-Cumulative Preferred Shares Series F",
        "price": 26.13
    }, {
        "symbol": "ACNB",
        "label": "ACNB Corporation",
        "price": 35.11
    }, {
        "symbol": "ACSF",
        "label": "American Capital Senior Floating Ltd.",
        "price": 12.25
    }, {
        "symbol": "ACSI",
        "label": "American Customer Satisfaction Core Alpha",
        "price": 30.66
    }, {
        "symbol": "ACU",
        "label": "Acme United Corporation.",
        "price": 24.265
    }, {
        "symbol": "ACV",
        "label": "AllianzGI Diversified Income & Convertible Fund of Beneficial Interest",
        "price": 25.18
    }, {
        "symbol": "ACY",
        "label": "AeroCentury Corp.",
        "price": 4.41
    }, {
        "symbol": "ADOM",
        "label": "ADOMANI Inc.",
        "price": 0.32
    }, {
        "symbol": "ADRA",
        "label": "BLDRS Asia 50 ADR Index Fund",
        "price": 35.495
    }, {
        "symbol": "ADRD",
        "label": "BLDRS Developed Markets 100 ADR Index Fund",
        "price": 22.635
    }, {
        "symbol": "ADRU",
        "label": "BLDRS Europe 100 ADR Index Fund",
        "price": 22.06
    }, {
        "symbol": "ADXSW",
        "label": "Advaxis Inc. Warrants",
        "price": 0.03
    }, {
        "symbol": "ADZ",
        "label": "DB Agriculture Short ETN due April 1 2038",
        "price": 33.4
    }, {
        "symbol": "AEB",
        "label": "AEGON N.V. Perp. Cap. Secs. Floating Rate (Netherlands)",
        "price": 24.87
    }, {
        "symbol": "AED",
        "label": "AEGON N.V. Perp. Cap. Securities (Netherlands)",
        "price": 25.58
    }, {
        "symbol": "AEK",
        "label": "Aegon NV 8.00% Non-Cumulative Subordinated Notes due 2042",
        "price": 25.57
    }, {
        "symbol": "AETI",
        "label": "American Electric Technologies Inc.",
        "price": 0.78
    }, {
        "symbol": "AEUA",
        "label": "Anadarko Petroleum Corporation 7.50% Tangible Equity Units",
        "price": 32.84
    }, {
        "symbol": "AEY",
        "label": "ADDvantage Technologies Group Inc.",
        "price": 2.1
    }, {
        "symbol": "AEZS",
        "label": "Aeterna Zentaris Inc.",
        "price": 1.09
    }, {
        "symbol": "AFC",
        "label": "Allied Capital Corporation 6.875% Notes due April 15 2047",
        "price": 27.0
    }, {
        "symbol": "AFHBL",
        "label": "Atlas Financial Holdings Inc. 6.625% Senior Unsecured Notes Due 2022",
        "price": 6.5
    }, {
        "symbol": "AFK",
        "label": "VanEck Vectors-Africa Index",
        "price": 20.16
    }, {
        "symbol": "AFTY",
        "label": "CSOP FTSE China A50",
        "price": 16.1
    }, {
        "symbol": "AGA",
        "label": "DB Agriculture Double Short ETN due April 1 2038",
        "price": 29.45
    }, {
        "symbol": "AGD",
        "label": "Alpine Global Dynamic Dividend Fund Shares of Beneficial Interest",
        "price": 10.53
    }, {
        "symbol": "AGF",
        "label": "DB Agriculture Long ETN due April 1 2038",
        "price": 10.43
    }, {
        "symbol": "AGFSW",
        "label": "AgroFresh Solutions Inc. Warrants",
        "price": 0.0149
    }, {
        "symbol": "AGGE",
        "label": "IQ Enhanced Core Bond U.S.",
        "price": 18.93
    }, {
        "symbol": "AGGP",
        "label": "IQ Enhanced Core Plus Bond U.S.",
        "price": 19.34
    }, {
        "symbol": "AGGY",
        "label": "WisdomTree Barclays Yield Enhanced U.S. Aggregate Bond Fund",
        "price": 52.63
    }, {
        "symbol": "AGIIL",
        "label": "Argo Group International Holdings Ltd. 6.5% Senior Notes Due 2042",
        "price": 25.33
    }, {
        "symbol": "AGNCB",
        "label": "AGNC Investment Corp. Depositary Shares representing 1/1000th Series B Preferred" +
                " Stock",
        "price": 25.21
    }, {
        "symbol": "AGNCN",
        "label": "AGNC Investment Corp. Depositary Shares Each Representing a 1/1000th Interest in" +
                " a Share of 7.00% Series C Fixed-To-Floating Rate Cumulative Redeemable Preferre",
        "price": 26.19
    }, {
        "symbol": "AGND",
        "label": "WisdomTree Barclays Negative Duration U.S. Aggregate Bond Fund",
        "price": 41.495
    }, {
        "symbol": "AGT",
        "label": "iShares MSCI Argentina and Global Exposure",
        "price": 24.6
    }, {
        "symbol": "AGZ",
        "label": "iShares Agency Bond",
        "price": 114.37
    }, {
        "symbol": "AHC",
        "label": "A.H. Belo Corporation",
        "price": 2.98
    }, {
        "symbol": "AHPA",
        "label": "Avista Healthcare Public Acquisition Corp.",
        "price": 10.08
    }, {
        "symbol": "AHPAU",
        "label": "Avista Healthcare Public Acquisition Corp. Unit",
        "price": 10.33
    }, {
        "symbol": "AHPAW",
        "label": "Avista Healthcare Public Acquisition Corp. Warrants",
        "price": 0.2
    }, {
        "symbol": "AIF",
        "label": "Apollo Tactical Income Fund Inc.",
        "price": 15.4
    }, {
        "symbol": "AIHS",
        "label": "Senmiao Technology Limited",
        "price": 0.67
    }, {
        "symbol": "AINC",
        "label": "Ashford Inc.",
        "price": 23.11
    }, {
        "symbol": "AIPT",
        "label": "Precision Therapeutics Inc.",
        "price": 0.52
    }, {
        "symbol": "AIRI",
        "label": "Air Industries Group",
        "price": 2.15
    }, {
        "symbol": "AIRT",
        "label": "Air T Inc.",
        "price": 21.1069
    }, {
        "symbol": "AIW",
        "label": "Arlington Asset Investment Corp 6.625% Notes due 2023",
        "price": 24.65
    }, {
        "symbol": "AIY",
        "label": "Apollo Investment Corporation 6.875% Senior Notes due 2043",
        "price": 25.96
    }, {
        "symbol": "AIZP",
        "label": "Assurant Inc. 6.50% Series D Mandatory Convertible Preferred Stock $1.00 par val" +
                "ue",
        "price": 129.256
    }, {
        "symbol": "AJX",
        "label": "Great Ajax Corp.",
        "price": 15.06
    }, {
        "symbol": "AJXA",
        "label": "Great Ajax Corp. 7.25% Convertible Senior Notes due 2024",
        "price": 26.9445
    }, {
        "symbol": "AKTX",
        "label": "Akari Therapeutics Plc",
        "price": 1.94
    }, {
        "symbol": "ALCO",
        "label": "Alico Inc.",
        "price": 37.29
    }, {
        "symbol": "ALD",
        "label": "WisdomTree Asia Local Debt Fund",
        "price": 44.23
    }, {
        "symbol": "ALFA",
        "label": "AlphaClone Alternative Alpha",
        "price": 51.57
    }, {
        "symbol": "ALFI",
        "label": "AlphaClone International",
        "price": 25.14
    }, {
        "symbol": "ALJJ",
        "label": "ALJ Regional Holdings Inc.",
        "price": 1.16
    }, {
        "symbol": "ALN",
        "label": "American Lorain Corporation",
        "price": 0.14
    }, {
        "symbol": "ALPN",
        "label": "Alpine Immune Sciences Inc.",
        "price": 3.4
    }, {
        "symbol": "ALQA",
        "label": "Alliqua BioMedical Inc.",
        "price": 2.75
    }, {
        "symbol": "ALRN",
        "label": "Aileron Therapeutics Inc.",
        "price": 0.805
    }, {
        "symbol": "ALTS",
        "label": "ProShares Morningstar Alternatives Solution",
        "price": 36.97
    }, {
        "symbol": "AMJL",
        "label": "Credit Suisse X-Links Monthly Pay 2xLeveraged Alerian MLP Index Exchange Traded " +
                "Notes due May 16 2036",
        "price": 14.79
    }, {
        "symbol": "AMLX",
        "label": "Amplify YieldShares Oil Hedged MLP Income",
        "price": 10.27
    }, {
        "symbol": "AMMA",
        "label": "Alliance MMA Inc.",
        "price": 0.21
    }, {
        "symbol": "AMOV",
        "label": "America Movil S.A.B. de C.V. Class An Depositary Shares",
        "price": 16.49
    }, {
        "symbol": "AMRB",
        "label": "American River Bankshares",
        "price": 14.95
    }, {
        "symbol": "AMRH",
        "label": "Ameri Holdings Inc.",
        "price": 1.59
    }, {
        "symbol": "AMRHW",
        "label": "Ameri Holdings Inc. Warrant",
        "price": 0.02
    }, {
        "symbol": "AMS",
        "label": "American Shared Hospital Services",
        "price": 2.42
    }, {
        "symbol": "AMUB",
        "label": "ETRACS Alerian MLP Index ETN Series B due July 18 2042",
        "price": 16.38
    }, {
        "symbol": "ANCB",
        "label": "Anchor Bancorp",
        "price": 25.69
    }, {
        "symbol": "ANTX",
        "label": "Anthem Inc. Corporate Units",
        "price": 56.98
    }, {
        "symbol": "APB",
        "label": "Asia Pacific Fund Inc. (The)",
        "price": 11.47
    }, {
        "symbol": "APDN",
        "label": "Applied DNA Sciences Inc",
        "price": 4.29
    }, {
        "symbol": "APEN",
        "label": "Apollo Endosurgery Inc.",
        "price": 2.85
    }, {
        "symbol": "APF",
        "label": "Morgan Stanley Asia-Pacific Fund Inc.",
        "price": 16.73
    }, {
        "symbol": "APOP",
        "label": "Cellect Biotechnology Ltd.",
        "price": 2.92
    }, {
        "symbol": "APOPW",
        "label": "Cellect Biotechnology Ltd. Warrants to Purchase ADR (1 WT and $7.50 to purchase " +
                "1 ADS)",
        "price": 0.11
    }, {
        "symbol": "APRI",
        "label": "Apricus Biosciences Inc.",
        "price": 0.22
    }, {
        "symbol": "AQB",
        "label": "AquaBounty Technologies Inc.",
        "price": 2.245
    }, {
        "symbol": "ARCI",
        "label": "Appliance Recycling Centers of America Inc.",
        "price": 4.86
    }, {
        "symbol": "ARCM",
        "label": "Arrow Reserve Capital Management",
        "price": 100.18
    }, {
        "symbol": "ARCW",
        "label": "ARC Group Worldwide Inc.",
        "price": 0.48
    }, {
        "symbol": "ARDM",
        "label": "Aradigm Corporation",
        "price": 0.41
    }, {
        "symbol": "ARGS",
        "label": "Argos Therapeutics Inc.",
        "price": 0.28
    }, {
        "symbol": "ARL",
        "label": "American Realty Investors Inc.",
        "price": 14.9
    }, {
        "symbol": "ARTNA",
        "label": "Artesian Resources Corporation Class A Non-Voting Common Stock",
        "price": 39.2
    }, {
        "symbol": "ARTW",
        "label": "Art's-Way Manufacturing Co. Inc.",
        "price": 1.9393
    }, {
        "symbol": "ASEA",
        "label": "Global X FTSE Southeast Asia",
        "price": 15.65
    }, {
        "symbol": "ASET",
        "label": "FlexShares Real Assets Allocation Index Fund",
        "price": 31.0758
    }, {
        "symbol": "ASFI",
        "label": "Asta Funding Inc.",
        "price": 10.26
    }, {
        "symbol": "ASHX",
        "label": "Xtrackers CSI 300 China A-Shares Hedged Equity",
        "price": 18.8
    }, {
        "symbol": "ASM",
        "label": "Avino Silver & Gold Mines Ltd. (Canada)",
        "price": 0.5253
    }, {
        "symbol": "ASPU",
        "label": "Aspen Group Inc.",
        "price": 7.65
    }, {
        "symbol": "ASRV",
        "label": "AmeriServ Financial Inc.",
        "price": 4.16
    }, {
        "symbol": "ASRVP",
        "label": "AmeriServ Financial Inc. AmeriServ Financial Trust I",
        "price": 28.4596
    }, {
        "symbol": "ASTC",
        "label": "Astrotech Corporation",
        "price": 2.09
    }, {
        "symbol": "ATAC",
        "label": "Atlantic Acquisition Corp.",
        "price": 12.73
    }, {
        "symbol": "ATACR",
        "label": "Atlantic Acquisition Corp. Right",
        "price": 1.2
    }, {
        "symbol": "ATACU",
        "label": "Atlantic Acquisition Corp. Unit",
        "price": 14.29
    }, {
        "symbol": "ATAI",
        "label": "ATA Inc.",
        "price": 1.92
    }, {
        "symbol": "ATEC",
        "label": "Alphatec Holdings Inc.",
        "price": 7.43
    }, {
        "symbol": "ATLC",
        "label": "Atlanticus Holdings Corporation",
        "price": 11.79
    }, {
        "symbol": "ATLO",
        "label": "Ames National Corporation",
        "price": 27.13
    }, {
        "symbol": "ATMP",
        "label": "Barclays ETN Plus Select MLP",
        "price": 18.21
    }, {
        "symbol": "ATTU",
        "label": "Attunity Ltd.",
        "price": 23.43
    }, {
        "symbol": "ATV",
        "label": "Acorn International Inc. ADS",
        "price": 18.24
    }, {
        "symbol": "AUDC",
        "label": "AudioCodes Ltd.",
        "price": 27.46
    }, {
        "symbol": "AUG",
        "label": "Auryn Resources Inc.",
        "price": 1.37
    }, {
        "symbol": "AUSE",
        "label": "WisdomTree Australia Dividend Index",
        "price": 54.0152
    }, {
        "symbol": "AVNW",
        "label": "Aviat Networks Inc.",
        "price": 14.7
    }, {
        "symbol": "AWX",
        "label": "Avalon Holdings Corporation",
        "price": 1.92
    }, {
        "symbol": "AXJV",
        "label": "iShares Edge MSCI A Min Vol Asia ex Japan",
        "price": 35.83
    }, {
        "symbol": "AXR",
        "label": "AMREP Corporation",
        "price": 5.8
    }, {
        "symbol": "AYT",
        "label": "iPath GEMS Asia 8 ETN",
        "price": 42.33
    }, {
        "symbol": "BAF",
        "label": "BlackRock Municipal Income Investment Quality Trust",
        "price": 14.44
    }, {
        "symbol": "BAL",
        "label": "iPath Bloomberg Cotton Subindex Total Return SM Index ETN",
        "price": 40.09
    }, {
        "symbol": "BALB",
        "label": "iPathA Series B Bloomberg Cotton Subindex Total Return ETN",
        "price": 48.53
    }, {
        "symbol": "BANFP",
        "label": "BancFirst Corporation 7.2% Cumulative Trust Preferred Securities",
        "price": 27.47
    }, {
        "symbol": "BAR",
        "label": "GraniteShares Gold Trust Shares of Beneficial Interest",
        "price": 12.79
    }, {
        "symbol": "BASI",
        "label": "Bioanalytical Systems Inc.",
        "price": 6.04
    }, {
        "symbol": "BBDO",
        "label": "Banco Bradesco Sa American Depositary Shares (each representing one)",
        "price": 8.05
    }, {
        "symbol": "BBF",
        "label": "BlackRock Municipal Income Investment Trust",
        "price": 13.87
    }, {
        "symbol": "BBK",
        "label": "Blackrock Municipal Bond Trust",
        "price": 15.74
    }, {
        "symbol": "BBP",
        "label": "Virtus LifeSci Biotech Products",
        "price": 44.0464
    }, {
        "symbol": "BBRC",
        "label": "Columbia Beyond BRICs",
        "price": 16.07
    }, {
        "symbol": "BCAC",
        "label": "Bison Capital Acquisition Corp.",
        "price": 10.53
    }, {
        "symbol": "BCACR",
        "label": "Bison Capital Acquisition Corp. Rights",
        "price": 0.4
    }, {
        "symbol": "BCACU",
        "label": "Bison Capital Acquisition Corp. Units Consisting of 1 OS 1/2 WT and 1 RT",
        "price": 10.72
    }, {
        "symbol": "BCACW",
        "label": "Bison Capital Acquisition Corp. Warrant",
        "price": 0.18
    }, {
        "symbol": "BCD",
        "label": "ETFS Bloomberg All Commodity Longer Dated Strategy K-1 Free",
        "price": 23.27
    }, {
        "symbol": "BCI",
        "label": "ETFS Bloomberg All Commodity Strategy K-1 Free",
        "price": 21.67
    }, {
        "symbol": "BCLI",
        "label": "Brainstorm Cell Therapeutics Inc.",
        "price": 4.35
    }, {
        "symbol": "BCM",
        "label": "iPath Pure Beta Broad Commodity ETN",
        "price": 27.19
    }, {
        "symbol": "BCV",
        "label": "Bancroft Fund Ltd.",
        "price": 25.93
    }, {
        "symbol": "BDCZ",
        "label": "ETRACS Wells Fargo Business Development Company Index ETN Series B due April 26 " +
                "2041",
        "price": 19.4301
    }, {
        "symbol": "BDD",
        "label": "DB Base Metals Double Long Exchange Traded Notes due June 1 2038",
        "price": 6.11
    }, {
        "symbol": "BDL",
        "label": "Flanigan's Enterprises Inc.",
        "price": 22.25
    }, {
        "symbol": "BDRY",
        "label": "Breakwave Dry Bulk Shipping",
        "price": 12.21
    }, {
        "symbol": "BEF",
        "label": "ETFS Bloomberg Energy Commodity Longer Dated Strategy K-1 Free",
        "price": 22.93
    }, {
        "symbol": "BELFA",
        "label": "Bel Fuse Inc. Class A Common Stock",
        "price": 16.84
    }, {
        "symbol": "BEMO",
        "label": "Aptus Behavioral Momentum",
        "price": 28.34
    }, {
        "symbol": "BEP",
        "label": "Brookfield Renewable Partners L.P.",
        "price": 46.28
    }, {
        "symbol": "BERN",
        "label": "Bernstein U.S. Research Fund",
        "price": 26.94
    }, {
        "symbol": "BFIT",
        "label": "Global X Health & Wellness Thematic ETF",
        "price": 22.4
    }, {
        "symbol": "BFO",
        "label": "Blackrock Florida Municipal 2020 Term Trust",
        "price": 14.41
    }, {
        "symbol": "BFRA",
        "label": "Biofrontera AG",
        "price": 11.3463
    }, {
        "symbol": "BGCA",
        "label": "BGC Partners Inc. 8.125% Senior Notes due 2042",
        "price": 25.51
    }, {
        "symbol": "BHAC",
        "label": "Barington/Hilco Acquisition Corp.",
        "price": 10.83
    }, {
        "symbol": "BHACU",
        "label": "Barington/Hilco Acquisition Corp. Unit",
        "price": 10.4
    }, {
        "symbol": "BHV",
        "label": "BlackRock Virginia Municipal Bond Trust",
        "price": 16.5
    }, {
        "symbol": "BIBL",
        "label": "Inspire 100",
        "price": 26.35
    }, {
        "symbol": "BICK",
        "label": "First Trust BICK Index Fund",
        "price": 30.45
    }, {
        "symbol": "BIS",
        "label": "ProShares UltraShort Nasdaq Biotechnology",
        "price": 13.1139
    }, {
        "symbol": "BJJN",
        "label": "iPathA Series B Bloomberg Nickel Subindex Total Return ETN",
        "price": 59.78
    }, {
        "symbol": "BJK",
        "label": "VanEck Vectors Gaming",
        "price": 35.35
    }, {
        "symbol": "BJO",
        "label": "iPathA Series B Bloomberg Coffee Subindex Total Return ETN",
        "price": 42.3
    }, {
        "symbol": "BJZ",
        "label": "Blackrock California Municipal 2018 Term Trust",
        "price": 14.46
    }, {
        "symbol": "BKEPP",
        "label": "Blueknight Energy Partners L.P. L.L.C. Series A Preferred Units",
        "price": 5.37
    }, {
        "symbol": "BKHU",
        "label": "Black Hills Corporation Corporate Units",
        "price": 64.9
    }, {
        "symbol": "BKJ",
        "label": "Bancorp of New Jersey Inc",
        "price": 17.89
    }, {
        "symbol": "BKK",
        "label": "Blackrock Municipal 2020 Term Trust",
        "price": 14.88
    }, {
        "symbol": "BKN",
        "label": "BlackRock Investment Quality Municipal Trust Inc. (The)",
        "price": 15.79
    }, {
        "symbol": "BKSC",
        "label": "Bank of South Carolina Corp.",
        "price": 19.0
    }, {
        "symbol": "BKYI",
        "label": "BIO-key International Inc.",
        "price": 0.641
    }, {
        "symbol": "BLE",
        "label": "BlackRock Municipal Income Trust II",
        "price": 15.555
    }, {
        "symbol": "BLES",
        "label": "Inspire Global Hope",
        "price": 27.38
    }, {
        "symbol": "BLFS",
        "label": "BioLife Solutions Inc.",
        "price": 16.21
    }, {
        "symbol": "BLH",
        "label": "Blackrock New York Municipal 2018 Term Trust",
        "price": 14.59
    }, {
        "symbol": "BLHY",
        "label": "Virtus Newfleet Dynamic Credit",
        "price": 24.02
    }, {
        "symbol": "BLIN",
        "label": "Bridgeline Digital Inc.",
        "price": 3.19
    }, {
        "symbol": "BLJ",
        "label": "Blackrock New Jersey Municipal Bond Trust",
        "price": 13.83
    }, {
        "symbol": "BLNG",
        "label": "iPath Pure Beta Precious Metal ETN",
        "price": 34.07
    }, {
        "symbol": "BLNK",
        "label": "Blink Charging Co.",
        "price": 2.05
    }, {
        "symbol": "BLNKW",
        "label": "Blink Charging Co. Warrant",
        "price": 0.63003
    }, {
        "symbol": "BME",
        "label": "Blackrock Health Sciences Trust",
        "price": 42.25
    }, {
        "symbol": "BMLP",
        "label": "BMO Elkhorn DWA MLP Select Index Exchange Traded Notes due December 10 2036",
        "price": 46.61
    }, {
        "symbol": "BMRA",
        "label": "Biomerica Inc.",
        "price": 2.97
    }, {
        "symbol": "BNDC",
        "label": "FlexShares Core Select Bond Fund",
        "price": 24.6
    }, {
        "symbol": "BNJ",
        "label": "BlackRock New Jersey Municipal Income Trust",
        "price": 13.84
    }, {
        "symbol": "BNTC",
        "label": "Benitec Biopharma Limited",
        "price": 5.06
    }, {
        "symbol": "BOFIL",
        "label": "BofI Holding Inc. 6.25% Subordinated Notes Due 2026",
        "price": 25.95
    }, {
        "symbol": "BOKFL",
        "label": "BOK Financial Corporation 5.375% Subordinated Notes due 2056",
        "price": 26.4318
    }, {
        "symbol": "BOM",
        "label": "DB Base Metals Double Short Exchange Traded Notes due June 1 2038",
        "price": 8.45
    }, {
        "symbol": "BOON",
        "label": "NYSE Pickens Oil Response",
        "price": 27.13
    }, {
        "symbol": "BORN",
        "label": "China New Borun Corporation American Depositary Shares",
        "price": 0.86
    }, {
        "symbol": "BOS",
        "label": "DB Base Metals Short Exchange Traded Notes due June 1 2038",
        "price": 20.99
    }, {
        "symbol": "BOSS",
        "label": "Global X Funds Founder-Run Companies",
        "price": 19.86
    }, {
        "symbol": "BOTJ",
        "label": "Bank of the James Financial Group Inc.",
        "price": 14.51
    }, {
        "symbol": "BOXL",
        "label": "Boxlight Corporation",
        "price": 1.53
    }, {
        "symbol": "BPFHP",
        "label": "Boston Private Financial Holdings Inc. Depositary Shares representing 1/40th Int" +
                "erest in a Share of 6.95% Non-Cumulative Perpetual Preferred Stock Series D",
        "price": 24.98
    }, {
        "symbol": "BPFHW",
        "label": "Boston Private Financial Holdings Inc. Warrants to purchase 1 share of common st" +
                "ock @ $8.00/share",
        "price": 7.05
    }, {
        "symbol": "BPMX",
        "label": "BioPharmX Corporation. Common",
        "price": 0.47
    }, {
        "symbol": "BPOPM",
        "label": "Popular Inc. Popular Capital Trust II",
        "price": 26.69
    }, {
        "symbol": "BPOPN",
        "label": "Popular Inc. Popular Capital Trust I -6.70% Cumulative Monthly Income Trust Pref" +
                "erred Securities",
        "price": 26.71
    }, {
        "symbol": "BPTH",
        "label": "Bio-Path Holdings Inc.",
        "price": 7.57
    }, {
        "symbol": "BQH",
        "label": "Blackrock New York Municipal Bond Trust of Beneficial Interest",
        "price": 15.4079
    }, {
        "symbol": "BRAC",
        "label": "Black Ridge Acquisition Corp.",
        "price": 10.23
    }, {
        "symbol": "BRACR",
        "label": "Black Ridge Acquisition Corp. Right",
        "price": 0.34
    }, {
        "symbol": "BRACU",
        "label": "Black Ridge Acquisition Corp. Unit",
        "price": 10.56
    }, {
        "symbol": "BRACW",
        "label": "Black Ridge Acquisition Corp. Warrant",
        "price": 0.25
    }, {
        "symbol": "BRGL",
        "label": "Bernstein Global Research Fund",
        "price": 26.9316
    }, {
        "symbol": "BRN",
        "label": "Barnwell Industries Inc.",
        "price": 1.18
    }, {
        "symbol": "BRPA",
        "label": "Big Rock Partners Acquisition Corp.",
        "price": 11.63
    }, {
        "symbol": "BRPAR",
        "label": "Big Rock Partners Acquisition Corp. Right",
        "price": 0.33
    }, {
        "symbol": "BRPAU",
        "label": "Big Rock Partners Acquisition Corp. Unit",
        "price": 10.7
    }, {
        "symbol": "BRPAW",
        "label": "Big Rock Partners Acquisition Corp. Warrant",
        "price": 0.1609
    }, {
        "symbol": "BRQS",
        "label": "Borqs Technologies Inc.",
        "price": 1.98
    }, {
        "symbol": "BSCI",
        "label": "Guggenheim BulletShares 2018 Corporate Bond",
        "price": 21.14
    }, {
        "symbol": "BSCN",
        "label": "Guggenheim BulletShares 2023 Corporate Bond",
        "price": 21.01
    }, {
        "symbol": "BSCP",
        "label": "Guggenheim BulletShares 2025 Corporate Bond",
        "price": 20.86
    }, {
        "symbol": "BSCR",
        "label": "Guggenheim BulletShares 2027 Corporate Bond",
        "price": 20.17
    }, {
        "symbol": "BSD",
        "label": "BlackRock Strategic Municipal Trust Inc. (The)",
        "price": 14.2
    }, {
        "symbol": "BSJO",
        "label": "Guggenheim BulletShares 2024 High Yield Corporate Bond",
        "price": 24.76
    }, {
        "symbol": "BSJP",
        "label": "Claymore Exchange-Traded Fund Trust",
        "price": 23.94
    }, {
        "symbol": "BSQR",
        "label": "BSQUARE Corporation",
        "price": 1.46
    }, {
        "symbol": "BTA",
        "label": "BlackRock Long-Term Municipal Advantage Trust of Beneficial Interest",
        "price": 12.9
    }, {
        "symbol": "BTEC",
        "label": "Principal Healthcare Innovators Index ETF",
        "price": 39.5802
    }, {
        "symbol": "BTN",
        "label": "Ballantyne Strong Inc.",
        "price": 3.4
    }, {
        "symbol": "BUR",
        "label": "Burcon NutraScience Corp",
        "price": 0.54
    }, {
        "symbol": "BURG",
        "label": "Chanticleer Holdings Inc.",
        "price": 0.655
    }, {
        "symbol": "BUY",
        "label": "USCF SummerHaven SHPEI Index Fund",
        "price": 24.57
    }, {
        "symbol": "BUYN",
        "label": "USCF SummerHaven SHPEN Index Fund",
        "price": 24.34
    }, {
        "symbol": "BUZ",
        "label": "Buzz US Sentiment Leaders",
        "price": 35.18
    }, {
        "symbol": "BVAL",
        "label": "Brand Value",
        "price": 16.35
    }, {
        "symbol": "BVNSC",
        "label": "Brandes Investment Trust",
        "price": 100.04
    }, {
        "symbol": "BVSN",
        "label": "BroadVision Inc.",
        "price": 3.0695
    }, {
        "symbol": "BVXV",
        "label": "BiondVax Pharmaceuticals Ltd.",
        "price": 10.25
    }, {
        "symbol": "BWG",
        "label": "BrandywineGLOBAL Global Income Opportunities Fund Inc.",
        "price": 12.86
    }, {
        "symbol": "BWINA",
        "label": "Baldwin & Lyons Inc. Class A (voting) Common Stock",
        "price": 23.99
    }, {
        "symbol": "BWV",
        "label": "iPath Goldman Sachs Barclays Bank PLC Exchange Traded Notes Linked to the CBOE S" +
                "&P 500 BuyWrite Index Structured Product",
        "price": 75.53
    }, {
        "symbol": "BXE",
        "label": "Bellatrix Exploration Ltd (Canada)",
        "price": 0.5
    }, {
        "symbol": "BYLD",
        "label": "iShares Yield Optimized Bond",
        "price": 25.81
    }, {
        "symbol": "BYSI",
        "label": "BeyondSpring Inc.",
        "price": 17.96
    }, {
        "symbol": "BZM",
        "label": "BlackRock Maryland Municipal Bond Trust of beneficial interest",
        "price": 16.03
    }, {
        "symbol": "BZQ",
        "label": "ProShares UltraShort MSCI Brazil Capped",
        "price": 27.03
    }, {
        "symbol": "CAAS",
        "label": "China Automotive Systems Inc.",
        "price": 3.12
    }, {
        "symbol": "CACG",
        "label": "ClearBridge All Cap Growth ETF",
        "price": 35.915
    }, {
        "symbol": "CADC",
        "label": "China Advanced Construction Materials Group Inc.",
        "price": 2.61
    }, {
        "symbol": "CAFE",
        "label": "iPath Pure Beta Coffee ETN",
        "price": 10.43
    }, {
        "symbol": "CALF",
        "label": "Pacer US Small Cap Cash Cows 100",
        "price": 25.27
    }, {
        "symbol": "CALL",
        "label": "magicJack VocalTec Ltd",
        "price": 8.68
    }, {
        "symbol": "CANF",
        "label": "Can-Fite Biopharma Ltd Sponsored ADR (Israel)",
        "price": 2.73
    }, {
        "symbol": "CAPR",
        "label": "Capricor Therapeutics Inc.",
        "price": 1.83
    }, {
        "symbol": "CARV",
        "label": "Carver Bancorp Inc.",
        "price": 2.59
    }, {
        "symbol": "CARZ",
        "label": "First Trust NASDAQ Global Auto Index Fund",
        "price": 34.1652
    }, {
        "symbol": "CASM",
        "label": "CAS Medical Systems Inc.",
        "price": 2.45
    }, {
        "symbol": "CATS",
        "label": "Catasys Inc.",
        "price": 17.58
    }, {
        "symbol": "CATYW",
        "label": "Cathay General Bancorp Warrant",
        "price": 22.21
    }, {
        "symbol": "CAW",
        "label": "CCA Industries Inc.",
        "price": 1.53
    }, {
        "symbol": "CBAK",
        "label": "CBAK Energy Technology Inc.",
        "price": 0.67
    }, {
        "symbol": "CBAN",
        "label": "Colony Bankcorp Inc.",
        "price": 15.46
    }, {
        "symbol": "CBFV",
        "label": "CB Financial Services Inc.",
        "price": 30.05
    }, {
        "symbol": "CBLI",
        "label": "Cleveland BioLabs Inc.",
        "price": 1.07
    }, {
        "symbol": "CBND",
        "label": "SPDR Bloomberg Barclays Issuer Scored Corporate Bond",
        "price": 32.7
    }, {
        "symbol": "CBON",
        "label": "VanEck Vectors ChinaAMC China Bond",
        "price": 22.23
    }, {
        "symbol": "CBSHP",
        "label": "Commerce Bancshares Inc. Depositary Shares each representing a 1/1000th interest" +
                " of 6.00% Series B Non-Cumulative Perpetual Preferred Stock",
        "price": 26.7
    }, {
        "symbol": "CCCL",
        "label": "China Ceramics Co. Ltd.",
        "price": 0.7384
    }, {
        "symbol": "CCM",
        "label": "Concord Medical Services Holdings Limited ADS (Each represents three)",
        "price": 2.56
    }, {
        "symbol": "CCNE",
        "label": "CNB Financial Corporation",
        "price": 31.51
    }, {
        "symbol": "CCOR",
        "label": "Cambria Core Equity",
        "price": 27.48
    }, {
        "symbol": "CCR",
        "label": "CONSOL Coal Resources LP representing limited partner interests",
        "price": 10.49
    }, {
        "symbol": "CCUR",
        "label": "CCUR Holdings Inc.",
        "price": 4.86
    }, {
        "symbol": "CCZ",
        "label": "Comcast Holdings ZONES",
        "price": 56.5767
    }, {
        "symbol": "CDL",
        "label": "VictoryShares US Large Cap High Div Volatility Wtd ETF",
        "price": 50.9722
    }, {
        "symbol": "CDMOP",
        "label": "Avid Bioservices Inc. 10.50% Series E Convertible Preferred Stock",
        "price": 26.7359
    }, {
        "symbol": "CDOR",
        "label": "Condor Hospitality Trust Inc.",
        "price": 11.07
    }, {
        "symbol": "CEFS",
        "label": "Exchange Listed Funds Trust",
        "price": 20.3
    }, {
        "symbol": "CELGZ",
        "label": "Celgene Corporation Contingent Value Right",
        "price": 0.469899
    }, {
        "symbol": "CEMB",
        "label": "iShares J.P. Morgan EM Corporate Bond",
        "price": 49.91
    }, {
        "symbol": "CEMI",
        "label": "Chembio Diagnostics Inc.",
        "price": 3.9
    }, {
        "symbol": "CETXP",
        "label": "Cemtrex Inc. Series 1 Preferred Stock",
        "price": 0.9511
    }, {
        "symbol": "CETXW",
        "label": "Cemtrex Inc. Series 1 Warrant",
        "price": 0.0244
    }, {
        "symbol": "CEW",
        "label": "WisdomTree Emerging Currency Strategy Fund",
        "price": 18.29
    }, {
        "symbol": "CEY",
        "label": "VictoryShares Emerging Market High Div Volatility Wtd ETF",
        "price": 24.595
    }, {
        "symbol": "CFA",
        "label": "VictoryShares US 500 Volatility Wtd ETF",
        "price": 57.8732
    }, {
        "symbol": "CFBI",
        "label": "Community First Bancshares Inc.",
        "price": 12.0
    }, {
        "symbol": "CFBK",
        "label": "Central Federal Corporation",
        "price": 14.14
    }, {
        "symbol": "CGA",
        "label": "China Green Agriculture Inc.",
        "price": 3.78
    }, {
        "symbol": "CGO",
        "label": "Calamos Global Total Return Fund",
        "price": 13.56
    }, {
        "symbol": "CGW",
        "label": "Guggenheim S&P Global Water",
        "price": 36.06
    }, {
        "symbol": "CHCI",
        "label": "Comstock Holding Companies Inc.",
        "price": 2.23
    }, {
        "symbol": "CHEP",
        "label": "AGFiQ U.S. Market Neutral Value Fund",
        "price": 23.34
    }, {
        "symbol": "CHGX",
        "label": "Change Finance Diversified Impact U.S. Large Cap Fossil Fuel Free",
        "price": 20.52
    }, {
        "symbol": "CHIE",
        "label": "Global X China Energy",
        "price": 11.32
    }, {
        "symbol": "CHII",
        "label": "Global X China Industrials",
        "price": 14.06
    }, {
        "symbol": "CHIM",
        "label": "Global X China Materials",
        "price": 17.66
    }, {
        "symbol": "CHKE",
        "label": "Cherokee Inc.",
        "price": 0.85
    }, {
        "symbol": "CHMA",
        "label": "Chiasma Inc.",
        "price": 4.89
    }, {
        "symbol": "CHN",
        "label": "China Fund Inc. (The)",
        "price": 22.58
    }, {
        "symbol": "CHNR",
        "label": "China Natural Resources Inc.",
        "price": 1.53
    }, {
        "symbol": "CHOC",
        "label": "iPath Pure Beta Cocoa ETN",
        "price": 38.8275
    }, {
        "symbol": "CHSCO",
        "label": "CHS Inc Class B Cumulative Redeemable Preferred Stock",
        "price": 27.8
    }, {
        "symbol": "CHSCP",
        "label": "CHS Inc 8%  Cumulative Redeemable Preferred Stock",
        "price": 28.99
    }, {
        "symbol": "CIC",
        "label": "Capitol Investment Corp. IV Class A",
        "price": 10.22
    }, {
        "symbol": "CID",
        "label": "VictoryShares International High Div Volatility Wtd ETF",
        "price": 34.0056
    }, {
        "symbol": "CIFS",
        "label": "China Internet Nationwide Financial Services Inc.",
        "price": 1.03
    }, {
        "symbol": "CIL",
        "label": "VictoryShares International Volatility Wtd ETF",
        "price": 40.3793
    }, {
        "symbol": "CINR",
        "label": "Ciner Resources LP representing Limited Partner Interests",
        "price": 18.08
    }, {
        "symbol": "CIVBP",
        "label": "Civista Bancshares Inc. Depositary Shares Each Representing a 1/40th Interest in" +
                " a 6.50% Noncumulative Redeemable Convertible Perpetual Preferred Share Series B",
        "price": 25.4121
    }, {
        "symbol": "CIX",
        "label": "CompX International Inc.",
        "price": 14.33
    }, {
        "symbol": "CIZ",
        "label": "VictoryShares Developed Enhanced Volatility Wtd ETF",
        "price": 33.8586
    }, {
        "symbol": "CIZN",
        "label": "Citizens Holding Company",
        "price": 21.96
    }, {
        "symbol": "CJJD",
        "label": "China Jo-Jo Drugstores Inc.",
        "price": 1.7
    }, {
        "symbol": "CJNK",
        "label": "SPDR ICE BofAML Crossover Corporate Bond",
        "price": 26.1915
    }, {
        "symbol": "CKX",
        "label": "CKX Lands Inc.",
        "price": 9.79
    }, {
        "symbol": "CLBS",
        "label": "Caladrius Biosciences Inc.",
        "price": 3.14
    }, {
        "symbol": "CLDC",
        "label": "China Lending Corporation",
        "price": 1.26
    }, {
        "symbol": "CLFD",
        "label": "Clearfield Inc.",
        "price": 14.14
    }, {
        "symbol": "CLGN",
        "label": "CollPlant Holdings Ltd.",
        "price": 11.62
    }, {
        "symbol": "CLIRW",
        "label": "ClearSign Combustion Corporation Warrant",
        "price": 0.8
    }, {
        "symbol": "CLIX",
        "label": "ProShares Long Online/Short Stores",
        "price": 52.97
    }, {
        "symbol": "CLRB",
        "label": "Cellectar Biosciences Inc.",
        "price": 2.52
    }, {
        "symbol": "CLRBW",
        "label": "Cellectar Biosciences Inc. Warrants",
        "price": 0.03
    }, {
        "symbol": "CLRBZ",
        "label": "Cellectar Biosciences Inc. Series A Warrants",
        "price": 0.13
    }, {
        "symbol": "CLRG",
        "label": "IQ Chaikin U.S. Large Cap ETF",
        "price": 27.4996
    }, {
        "symbol": "CLRO",
        "label": "ClearOne Inc.",
        "price": 1.81
    }, {
        "symbol": "CLTL",
        "label": "PowerShares Treasury Collateral Portfolio",
        "price": 105.51
    }, {
        "symbol": "CLYH",
        "label": "iShares Interest Rate Hedged 10 Year Credit Bond",
        "price": 26.07
    }, {
        "symbol": "CMDT",
        "label": "iShares Commodity Optimized Trust",
        "price": 37.23
    }, {
        "symbol": "CMF",
        "label": "iShares California Muni Bond",
        "price": 60.37
    }, {
        "symbol": "CMFN",
        "label": "CM Finance Inc",
        "price": 7.21
    }, {
        "symbol": "CMSS",
        "label": "CM Seven Star Acquisition Corporation",
        "price": 2.8
    }, {
        "symbol": "CMSSR",
        "label": "CM Seven Star Acquisition Corporation Right",
        "price": 0.3
    }, {
        "symbol": "CMSSU",
        "label": "CM Seven Star Acquisition Corporation Unit",
        "price": 7.21
    }, {
        "symbol": "CMSSW",
        "label": "CM Seven Star Acquisition Corporation Warrant",
        "price": 0.19
    }, {
        "symbol": "CMU",
        "label": "MFS Municipal Income Trust",
        "price": 4.83
    }, {
        "symbol": "CN",
        "label": "Xtrackers MSCI All China Equity",
        "price": 31.16
    }, {
        "symbol": "CNAC",
        "label": "Constellation Alpha Capital Corp.",
        "price": 9.92
    }, {
        "symbol": "CNACR",
        "label": "Constellation Alpha Capital Corp. Right",
        "price": 0.17
    }, {
        "symbol": "CNACU",
        "label": "Constellation Alpha Capital Corp. Unit",
        "price": 11.43
    }, {
        "symbol": "CNACW",
        "label": "Constellation Alpha Capital Corp. Warrant",
        "price": 0.05
    }, {
        "symbol": "CNCR",
        "label": "Loncar Cancer Immunotherapy ETF",
        "price": 23.4335
    }, {
        "symbol": "CNDF",
        "label": "iShares Edge MSCI Multifactor Consumer Discretionary",
        "price": 34.0
    }, {
        "symbol": "CNFR",
        "label": "Conifer Holdings Inc.",
        "price": 3.99
    }, {
        "symbol": "CNHX",
        "label": "CSOP MSCI China A International Hedged",
        "price": 29.12
    }, {
        "symbol": "CNSF",
        "label": "iShares Edge MSCI Multifactor Consumer Staples",
        "price": 24.4201
    }, {
        "symbol": "CNTF",
        "label": "China TechFaith Wireless Communication Technology Limited",
        "price": 0.66
    }, {
        "symbol": "CNXT",
        "label": "VanEck Vectors ChinaAMC SME-ChNext",
        "price": 24.25
    }, {
        "symbol": "CNY",
        "label": "Market Vectors Chinese Renminbi/USD ETN",
        "price": 45.28
    }, {
        "symbol": "CNYA",
        "label": "iShares MSCI China A",
        "price": 26.87
    }, {
        "symbol": "COCP",
        "label": "Cocrystal Pharma Inc.",
        "price": 0.615
    }, {
        "symbol": "CODA",
        "label": "Coda Octopus Group Inc.",
        "price": 8.21
    }, {
        "symbol": "CODX",
        "label": "Co-Diagnostics Inc.",
        "price": 1.09
    }, {
        "symbol": "COHN",
        "label": "Cohen & Company Inc.",
        "price": 3.61
    }, {
        "symbol": "COM",
        "label": "Direxion Auspice Broad Commodity Strategy",
        "price": 22.95
    }, {
        "symbol": "COMB",
        "label": "GraniteShares Bloomberg Commodity Broad Strategy No K-1",
        "price": 23.9
    }, {
        "symbol": "COMG",
        "label": "GraniteShares S&P GSCI Commodity Broad Strategy No K-1",
        "price": 22.81
    }, {
        "symbol": "COMT",
        "label": "iShares Commodities Select Strategy ETF",
        "price": 32.6544
    }, {
        "symbol": "COW",
        "label": "iPath Bloomberg Livestock Total Return Sub-Index ETN",
        "price": 46.64
    }, {
        "symbol": "COWB",
        "label": "iPathA Series B Bloomberg Livestock Subindex Total Return ETN",
        "price": 47.41
    }, {
        "symbol": "COWNZ",
        "label": "Cowen Inc. 7.35% Senior Notes Due 2027",
        "price": 26.08
    }, {
        "symbol": "COWZ",
        "label": "Pacer US Cash Cows 100",
        "price": 27.27
    }, {
        "symbol": "CPAH",
        "label": "CounterPath Corporation",
        "price": 2.64
    }, {
        "symbol": "CPHC",
        "label": "Canterbury Park Holding Corporation",
        "price": 12.78
    }, {
        "symbol": "CPI",
        "label": "IQ Real Return",
        "price": 27.39
    }, {
        "symbol": "CPSH",
        "label": "CPS Technologies Corp.",
        "price": 1.02
    }, {
        "symbol": "CPSS",
        "label": "Consumer Portfolio Services Inc.",
        "price": 3.46
    }, {
        "symbol": "CPTAG",
        "label": "Capitala Finance Corp. 5.75% Convertible Notes Due 2022",
        "price": 24.75
    }, {
        "symbol": "CPTAL",
        "label": "Capitala Finance Corp. 6% Notes Due 2022",
        "price": 25.15
    }, {
        "symbol": "CROC",
        "label": "ProShares UltraShort Australian Dollar",
        "price": 46.665
    }, {
        "symbol": "CROP",
        "label": "IQ Global Agribusiness Small Cap",
        "price": 31.53
    }, {
        "symbol": "CRT",
        "label": "Cross Timbers Royalty Trust",
        "price": 8.79
    }, {
        "symbol": "CRUSC",
        "label": "Calvert Management Series",
        "price": 99.99
    }, {
        "symbol": "CRVP",
        "label": "Crystal Rock Holdings Inc. Class A",
        "price": 0.9652
    }, {
        "symbol": "CSA",
        "label": "VictoryShares US Small Cap Volatility Wtd ETF",
        "price": 50.0168
    }, {
        "symbol": "CSB",
        "label": "VictoryShares US Small Cap High Div Volatility Wtd ETF",
        "price": 47.24
    }, {
        "symbol": "CSF",
        "label": "VictoryShares US Discovery Enhanced Volatility Wtd ETF",
        "price": 39.925
    }, {
        "symbol": "CSM",
        "label": "ProShares Large Cap Core Plus",
        "price": 79.288
    }, {
        "symbol": "CSML",
        "label": "IQ Chaikin U.S. Small Cap ETF",
        "price": 27.9527
    }, {
        "symbol": "CSPI",
        "label": "CSP Inc.",
        "price": 13.3
    }, {
        "symbol": "CSWC",
        "label": "Capital Southwest Corporation",
        "price": 21.19
    }, {
        "symbol": "CSWCL",
        "label": "Capital Southwest Corporation 5.95% Notes due 2022",
        "price": 25.7
    }, {
        "symbol": "CTDD",
        "label": "Qwest Corporation 6.75% Notes due 2057",
        "price": 26.42
    }, {
        "symbol": "CTHR",
        "label": "Charles & Colvard Ltd.",
        "price": 1.55
    }, {
        "symbol": "CTIB",
        "label": "CTI Industries Corporation",
        "price": 1.66
    }, {
        "symbol": "CTNN",
        "label": "iPath Pure Beta Cotton ETN",
        "price": 35.05
    }, {
        "symbol": "CTRV",
        "label": "ContraVir Pharmaceuticals Inc",
        "price": 5.06
    }, {
        "symbol": "CTX",
        "label": "Qwest Corporation 7.00% Notes due 2052",
        "price": 25.2
    }, {
        "symbol": "CTXR",
        "label": "Citius Pharmaceuticals Inc.",
        "price": 1.14
    }, {
        "symbol": "CTXRW",
        "label": "Citius Pharmaceuticals Inc. Warrant",
        "price": 0.35
    }, {
        "symbol": "CUBS",
        "label": "Customers Bancorp Inc 6.375% Senior Notes due 2018",
        "price": 25.15
    }, {
        "symbol": "CUI",
        "label": "CUI Global Inc.",
        "price": 1.31
    }, {
        "symbol": "CUMB",
        "label": "Virtus Cumberland Municipal Bond",
        "price": 24.84
    }, {
        "symbol": "CUO",
        "label": "Continental Materials Corporation",
        "price": 7.14
    }, {
        "symbol": "CUPM",
        "label": "iPath Pure Beta Copper ETN",
        "price": 32.74
    }, {
        "symbol": "CUR",
        "label": "Neuralstem Inc.",
        "price": 0.47
    }, {
        "symbol": "CUT",
        "label": "Guggenheim MSCI Global Timber",
        "price": 28.54
    }, {
        "symbol": "CVLY",
        "label": "Codorus Valley Bancorp Inc",
        "price": 22.24
    }, {
        "symbol": "CVM",
        "label": "Cel-Sci Corporation ($0.001 Par Value)",
        "price": 10.03
    }, {
        "symbol": "CVR",
        "label": "Chicago Rivet & Machine Co.",
        "price": 25.81
    }, {
        "symbol": "CWAI",
        "label": "CWA Income",
        "price": 24.758
    }, {
        "symbol": "CWBC",
        "label": "Community West Bancshares",
        "price": 11.05
    }, {
        "symbol": "CWS",
        "label": "AdvisorShares Focused Equity",
        "price": 28.63
    }, {
        "symbol": "CXDC",
        "label": "China XD Plastics Company Limited",
        "price": 2.08
    }, {
        "symbol": "CYAN",
        "label": "Cyanotech Corporation",
        "price": 2.5312
    }, {
        "symbol": "CYCCP",
        "label": "Cyclacel Pharmaceuticals Inc. 6% Convertible Preferred Stock",
        "price": 5.92
    }, {
        "symbol": "CYHHZ",
        "label": "Community Health Systems Inc. Series A Contingent Value Rights",
        "price": 0.0
    }, {
        "symbol": "CYRN",
        "label": "CYREN Ltd.",
        "price": 1.28
    }, {
        "symbol": "CYTR",
        "label": "CytRx Corporation",
        "price": 0.31
    }, {
        "symbol": "CYTX",
        "label": "Cytori Therapeutics Inc",
        "price": 0.26
    }, {
        "symbol": "CYTXW",
        "label": "Cytori Therapeutics Inc Warrant",
        "price": 0.02
    }, {
        "symbol": "CZA",
        "label": "Guggenheim Mid-Cap Core",
        "price": 78.3278
    }, {
        "symbol": "CZNC",
        "label": "Citizens & Northern Corp",
        "price": 27.73
    }, {
        "symbol": "DAC",
        "label": "Danaos Corporation",
        "price": 8.51
    }, {
        "symbol": "DAG",
        "label": "DB Agriculture Double Long ETN due April 1 2038",
        "price": 2.0
    }, {
        "symbol": "DALT",
        "label": "Anfield Capital Diversified Alternatives ETF",
        "price": 9.89
    }, {
        "symbol": "DARE",
        "label": "Dare Bioscience Inc.",
        "price": 1.66
    }, {
        "symbol": "DAUD",
        "label": "ETNs linked to the VelocityShares Daily 4X Long USD vs. AUD Index",
        "price": 33.99
    }, {
        "symbol": "DBAP",
        "label": "Xtrackers MSCI Asia Pacific ex Japan Hedged Equity",
        "price": 26.86
    }, {
        "symbol": "DBAW",
        "label": "Xtrackers MSCI All World ex US Hedged Equity",
        "price": 30.03
    }, {
        "symbol": "DBB",
        "label": "PowerShares DB Base Metals Fund",
        "price": 15.25
    }, {
        "symbol": "DBBR",
        "label": "Xtrackers MSCI Brazil Hedged Equity",
        "price": 11.96
    }, {
        "symbol": "DBE",
        "label": "PowerShares DB Energy Fund",
        "price": 13.46
    }, {
        "symbol": "DBES",
        "label": "Xtrackers MSCI EAFE Small Cap Hedged Equity",
        "price": 28.96
    }, {
        "symbol": "DBEZ",
        "label": "Xtrackers MSCI Eurozone Hedged Equity",
        "price": 33.995
    }, {
        "symbol": "DBKO",
        "label": "Xtrackers MSCI South Korea Hedged Equity",
        "price": 25.43
    }, {
        "symbol": "DBMX",
        "label": "Xtrackers MSCI Mexico Hedged Equity",
        "price": 18.8
    }, {
        "symbol": "DBP",
        "label": "PowerShares DB Precious Metals Fund",
        "price": 36.62
    }, {
        "symbol": "DBRT",
        "label": "Credit Suisse AxelaTrader 3x Inverse Brent Crude Oil ETN",
        "price": 27.91
    }, {
        "symbol": "DBS",
        "label": "PowerShares DB Silver Fund",
        "price": 21.24
    }, {
        "symbol": "DBUK",
        "label": "Xtrackers MSCI United Kingdom Hedged Equity",
        "price": 20.85
    }, {
        "symbol": "DCHF",
        "label": "ETNs linked to the VelocityShares Daily 4X Long USD vs. CHF Index",
        "price": 21.69
    }, {
        "symbol": "DCNG",
        "label": "iPath Seasonal Natural Gas ETN",
        "price": 16.736
    }, {
        "symbol": "DDBI",
        "label": "Legg Mason Developed EX-US Diversified Core ETF",
        "price": 28.94
    }, {
        "symbol": "DDEZ",
        "label": "WisdomTree Dynamic Currency Hedged Europe Equity Fund",
        "price": 30.5702
    }, {
        "symbol": "DDF",
        "label": "Delaware Investments Dividend & Income Fund Inc.",
        "price": 15.935
    }, {
        "symbol": "DDG",
        "label": "ProShares Short Oil & Gas",
        "price": 25.43
    }, {
        "symbol": "DDJP",
        "label": "WisdomTree Dynamic Currency Hedged Japan Equity Fund",
        "price": 28.01
    }, {
        "symbol": "DDLS",
        "label": "WisdomTree Dynamic Currency Hedged International SmallCap Equity Fund",
        "price": 33.04
    }, {
        "symbol": "DDP",
        "label": "DB Commodity Short ETN due April 1 2038",
        "price": 49.17
    }, {
        "symbol": "DDT",
        "label": "Dillard's Capital Trust I",
        "price": 25.92
    }, {
        "symbol": "DEE",
        "label": "DB Commodity Double Short ETN due April 1 2038",
        "price": 77.0
    }, {
        "symbol": "DEFA",
        "label": "iShares Adaptive Currency Hedged MSCI EAFE",
        "price": 29.7329
    }, {
        "symbol": "DEMG",
        "label": "Xtrackers FTSE Emerging Comprehensive Factor",
        "price": 24.99
    }, {
        "symbol": "DEMS",
        "label": "Democratic Policies Fund",
        "price": 20.44
    }, {
        "symbol": "DESC",
        "label": "Xtrackers Russell 2000 Comprehensive Factor",
        "price": 32.12
    }, {
        "symbol": "DEST",
        "label": "Destination Maternity Corporation",
        "price": 1.48
    }, {
        "symbol": "DEUR",
        "label": "ETNs linked to the VelocityShares Daily 4X Long USD vs. EUR Index",
        "price": 29.29
    }, {
        "symbol": "DEWJ",
        "label": "iShares Adaptive Currency Hedged MSCI Japan",
        "price": 28.2
    }, {
        "symbol": "DEX",
        "label": "Delaware Enhanced Global Dividend of Beneficial Interest",
        "price": 10.75
    }, {
        "symbol": "DEZU",
        "label": "iShares Adaptive Currency Hedged MSCI Eurozone",
        "price": 29.17
    }, {
        "symbol": "DFBHU",
        "label": "DFB Healthcare Acquisitions Corp. Unit",
        "price": 10.05
    }, {
        "symbol": "DFND",
        "label": "Realty Shares DIVCON Dividend Defender",
        "price": 32.3697
    }, {
        "symbol": "DFVL",
        "label": "iPath US Treasury 5 Year Bull ETN",
        "price": 72.1165
    }, {
        "symbol": "DFVS",
        "label": "iPath US Treasury 5-year Bear ETN",
        "price": 26.5206
    }, {
        "symbol": "DGBP",
        "label": "ETNs linked to the VelocityShares Daily 4X Long USD vs. GBP Index",
        "price": 26.37
    }, {
        "symbol": "DGICB",
        "label": "Donegal Group Inc. Class B Common Stock",
        "price": 12.9
    }, {
        "symbol": "DGLY",
        "label": "Digital Ally Inc.",
        "price": 0.949
    }, {
        "symbol": "DGRS",
        "label": "WisdomTree U.S. SmallCap Quality Dividend Growth Fund",
        "price": 37.98
    }, {
        "symbol": "DGSE",
        "label": "DGSE Companies Inc.",
        "price": 1.4
    }, {
        "symbol": "DGT",
        "label": "SPDR Global Dow ETF (based on The Global Dow)",
        "price": 81.8
    }, {
        "symbol": "DHCP",
        "label": "Ditech Holding Corporation",
        "price": 1.01
    }, {
        "symbol": "DHDG",
        "label": "WisdomTree Dynamic Currency Hedged International Quality Dividend Growth Fund",
        "price": 21.67
    }, {
        "symbol": "DHVW",
        "label": "Diamond Hill Valuation-Weighted 500",
        "price": 32.32
    }, {
        "symbol": "DHXM",
        "label": "DHX Media Ltd.",
        "price": 1.11
    }, {
        "symbol": "DIG",
        "label": "ProShares Ultra Oil & Gas",
        "price": 24.82
    }, {
        "symbol": "DIM",
        "label": "WisdomTree International MidCap Dividend Fund",
        "price": 66.1008
    }, {
        "symbol": "DIRT",
        "label": "iPath Pure Beta Agriculture ETN",
        "price": 30.44
    }, {
        "symbol": "DISCB",
        "label": "Discovery Communications Inc. Series B Common Stock",
        "price": 37.68
    }, {
        "symbol": "DIT",
        "label": "AMCON Distributing Company",
        "price": 76.94
    }, {
        "symbol": "DIV",
        "label": "Global X Super Dividend",
        "price": 22.19
    }, {
        "symbol": "DIVA",
        "label": "AGFiQ Hedged Dividend Income Fund",
        "price": 24.17
    }, {
        "symbol": "DIVB",
        "label": "iShares U.S. Dividend and Buyback",
        "price": 27.32
    }, {
        "symbol": "DIVC",
        "label": "Citigroup Inc. C-Tracks ETN Miller/Howard Strategic Dividend Reinvestors Due 9/1" +
                "6/2014",
        "price": 35.35
    }, {
        "symbol": "DIVO",
        "label": "Amplify YieldShares CWP Dividend & Option Income",
        "price": 29.38
    }, {
        "symbol": "DJCI",
        "label": "E-TRACS Linked to the Bloomberg Commodity Index Total Return due October 31 2039",
        "price": 14.79
    }, {
        "symbol": "DJCO",
        "label": "Daily Journal Corp. (S.C.)",
        "price": 287.4
    }, {
        "symbol": "DJD",
        "label": "Guggenheim Dow Jones Industrial Average Dividend",
        "price": 39.79
    }, {
        "symbol": "DJPY",
        "label": "ETNs linked to the VelocityShares Daily 4X Long USD vs. JPY Index",
        "price": 26.08
    }, {
        "symbol": "DLBL",
        "label": "iPath US Treasury Long Bond Bull ETN",
        "price": 75.08
    }, {
        "symbol": "DLBR",
        "label": "Citigroup Global Markets Holdings Inc VelocityShares Short LIBOR ETN",
        "price": 19.34
    }, {
        "symbol": "DLBS",
        "label": "iPath US Treasury Long Bond Bear ETN",
        "price": 9.9395
    }, {
        "symbol": "DLHC",
        "label": "DLH Holdings Corp.",
        "price": 4.73
    }, {
        "symbol": "DLNG",
        "label": "Dynagas LNG Partners LP",
        "price": 2.05
    }, {
        "symbol": "DLPN",
        "label": "Dolphin Entertainment Inc.",
        "price": 0.68
    }, {
        "symbol": "DLPNW",
        "label": "Dolphin Entertainment Inc. Warrant",
        "price": 0.04
    }, {
        "symbol": "DMB",
        "label": "Dreyfus Municipal Bond Infrastructure Fund Inc.",
        "price": 14.85
    }, {
        "symbol": "DMLP",
        "label": "Dorchester Minerals L.P.",
        "price": 20.6
    }, {
        "symbol": "DMPI",
        "label": "DelMar Pharmaceuticals Inc.",
        "price": 0.63
    }, {
        "symbol": "DMRI",
        "label": "DeltaShares S&P International Managed Risk",
        "price": 48.09
    }, {
        "symbol": "DMRL",
        "label": "DeltaShares S&P 500 Managed Risk",
        "price": 55.8
    }, {
        "symbol": "DMRM",
        "label": "DeltaShares S&P 400 Managed Risk",
        "price": 55.27
    }, {
        "symbol": "DMRS",
        "label": "DeltaShares S&P 600 Managed Risk",
        "price": 51.9
    }, {
        "symbol": "DNJR",
        "label": "Golden Bull Limited",
        "price": 0.3996
    }, {
        "symbol": "DNL",
        "label": "WisdomTree Global ex-US Quality Dividend Growth Fund",
        "price": 58.04
    }, {
        "symbol": "DNO",
        "label": "United States Short Oil Fund",
        "price": 47.39
    }, {
        "symbol": "DOGS",
        "label": "Arrow Dogs of the World",
        "price": 47.28
    }, {
        "symbol": "DOGZ",
        "label": "Dogness (International) Corporation",
        "price": 1.18
    }, {
        "symbol": "DOTA",
        "label": "Draper Oakwood Technology Acquisition Inc.",
        "price": 5.25
    }, {
        "symbol": "DOTAR",
        "label": "Draper Oakwood Technology Acquisition Inc. Right",
        "price": 0.39
    }, {
        "symbol": "DOTAU",
        "label": "Draper Oakwood Technology Acquisition Inc. Unit",
        "price": 10.72
    }, {
        "symbol": "DOTAW",
        "label": "Draper Oakwood Technology Acquisition Inc. Warrant",
        "price": 0.31
    }, {
        "symbol": "DPK",
        "label": "Direxion Developed Markets Bear 3x Shares",
        "price": 13.67
    }, {
        "symbol": "DRD",
        "label": "DRDGOLD Limited American Depositary Shares",
        "price": 5.93
    }, {
        "symbol": "DRR",
        "label": "Market Vectors Double Short Euro ETN",
        "price": 60.11
    }, {
        "symbol": "DRUA",
        "label": "Dominion Energy Inc. 2016 Series A 5.25% Enhanced Junior Subordinated Notes due " +
                "2076",
        "price": 26.54
    }, {
        "symbol": "DRW",
        "label": "WisdomTree Global ex-US Real Estate Index",
        "price": 31.84
    }, {
        "symbol": "DSLV",
        "label": "VelocityShares 3x Inverse Silver ETN",
        "price": 15.69
    }, {
        "symbol": "DSUM",
        "label": "PowerShares Chinese Yuan Dim Sum Bond Portfolio",
        "price": 22.19
    }, {
        "symbol": "DSWL",
        "label": "Deswell Industries Inc.",
        "price": 2.94
    }, {
        "symbol": "DTD",
        "label": "WisdomTree U.S. Total Dividend Fund",
        "price": 107.46
    }, {
        "symbol": "DTEA",
        "label": "DAVIDsTEA Inc.",
        "price": 1.65
    }, {
        "symbol": "DTEC",
        "label": "ALPS ETF Trust Disruptive Technologies",
        "price": 28.01
    }, {
        "symbol": "DTF",
        "label": "DTF Tax-Free Income Inc.",
        "price": 15.15
    }, {
        "symbol": "DTQ",
        "label": "DTE Energy Company 2012 Series C 5.25% Junior Subordinate Debentures due Decembe" +
                "r 1 2062",
        "price": 25.5
    }, {
        "symbol": "DTRM",
        "label": "Determine Inc.",
        "price": 0.28
    }, {
        "symbol": "DTUL",
        "label": "iPath US Treasury 2 Yr Bull ETN",
        "price": 63.3492
    }, {
        "symbol": "DTUS",
        "label": "iPath US Treasury 2-year Bear ETN",
        "price": 33.9903
    }, {
        "symbol": "DTYL",
        "label": "iPath US Treasury 10 Year Bull ETN",
        "price": 85.4739
    }, {
        "symbol": "DTYS",
        "label": "iPath US Treasury 10-year Bear ETN",
        "price": 11.8604
    }, {
        "symbol": "DUG",
        "label": "ProShares UltraShort Oil & Gas",
        "price": 45.41
    }, {
        "symbol": "DUSA",
        "label": "Davis Select U.S. Equity ETF",
        "price": 26.66
    }, {
        "symbol": "DUSL",
        "label": "Direxion Daily Industrials Bull 3X Shares",
        "price": 29.53
    }, {
        "symbol": "DVCR",
        "label": "Diversicare Healthcare Services Inc.",
        "price": 3.49
    }, {
        "symbol": "DVD",
        "label": "Dover Motorsports Inc.",
        "price": 1.8648
    }, {
        "symbol": "DVEM",
        "label": "WisdomTree Emerging Markets Dividend Fund",
        "price": 33.5637
    }, {
        "symbol": "DVHL",
        "label": "ETRACS 2xLeveraged Diversified High Income ETN",
        "price": 18.2
    }, {
        "symbol": "DVYA",
        "label": "iShares Asia / Pacific Dividend 30 Index Fund Exchange Traded Fund",
        "price": 43.61
    }, {
        "symbol": "DVYL",
        "label": "ETRACS Monthly Pay 2x Leveraged Dow Jones Select Dividend Index ETN",
        "price": 66.97
    }, {
        "symbol": "DWAC",
        "label": "Elkhorn Commodity Rotation Strategy ETF",
        "price": 25.15
    }, {
        "symbol": "DWAQ",
        "label": "PowerShares DWA NASDAQ Momentum Portfolio",
        "price": 133.13
    }, {
        "symbol": "DWAT",
        "label": "Arrow DWA Tactical ETF",
        "price": 11.36
    }, {
        "symbol": "DWCR",
        "label": "Arrow DWA Country Rotation ETF",
        "price": 28.8706
    }, {
        "symbol": "DWFI",
        "label": "SPDR Dorsey Wright Fixed Income Allocation ETF",
        "price": 23.65
    }, {
        "symbol": "DWLD",
        "label": "Davis Select Worldwide ETF",
        "price": 26.2454
    }, {
        "symbol": "DWLV",
        "label": "PowerShares DWA Momentum & Low Volatility Rotation Portfolio",
        "price": 30.79
    }, {
        "symbol": "DWPP",
        "label": "First Trust Dorsey Wright People's Portfolio ETF",
        "price": 34.4145
    }, {
        "symbol": "DWTR",
        "label": "PowerShares DWA Tactical Sector Rotation Portfolio",
        "price": 34.335
    }, {
        "symbol": "DXF",
        "label": "Dunxin Financial Holdings Limited American Depositary Shares",
        "price": 1.22
    }, {
        "symbol": "DYB",
        "label": "WisdomTree Dynamic Bearish U.S. Equity Fund",
        "price": 21.98
    }, {
        "symbol": "DYLS",
        "label": "WisdomTree Dynamic Long/Short U.S. Equity Fund",
        "price": 27.93
    }, {
        "symbol": "DYNC",
        "label": "Dynegy Inc. 7.00% Tangible Equity Units",
        "price": 100.32
    }, {
        "symbol": "DYNT",
        "label": "Dynatronics Corporation",
        "price": 0.9351
    }, {
        "symbol": "DYSL",
        "label": "Dynasil Corporation of America",
        "price": 1.05
    }, {
        "symbol": "DYY",
        "label": "DB Commodity Double Long ETN due April 1 2038",
        "price": 2.05
    }, {
        "symbol": "DZK",
        "label": "Direxion Developed Markets Bull 3x Shares",
        "price": 55.85
    }, {
        "symbol": "DZSI",
        "label": "DASAN Zhone Solutions Inc.",
        "price": 10.6
    }, {
        "symbol": "EAB",
        "label": "Entergy Arkansas Inc. First Mortgage Bonds 4.90% Series Due December 1 2052",
        "price": 26.0477
    }, {
        "symbol": "EACQ",
        "label": "Easterly Acquisition Corp.",
        "price": 10.21
    }, {
        "symbol": "EACQU",
        "label": "Easterly Acquisition Corp. Unit",
        "price": 10.78
    }, {
        "symbol": "EAGL",
        "label": "Platinum Eagle Acquisition Corp.",
        "price": 10.04
    }, {
        "symbol": "EAGLU",
        "label": "Platinum Eagle Acquisition Corp. Unit",
        "price": 10.51
    }, {
        "symbol": "EAGLW",
        "label": "Platinum Eagle Acquisition Corp. Warrant",
        "price": 1.42
    }, {
        "symbol": "EAI",
        "label": "Entergy Arkansas Inc. First Mortgage Bonds 4.875% Series Due September 1 2066",
        "price": 26.44
    }, {
        "symbol": "EAST",
        "label": "Eastside Distilling Inc.",
        "price": 3.15
    }, {
        "symbol": "EASTW",
        "label": "Eastside Distilling Inc. Warrants",
        "price": 1.92
    }, {
        "symbol": "EBND",
        "label": "SPDR Bloomberg Barclays Emerging Markets Local Bond",
        "price": 26.73
    }, {
        "symbol": "ECCA",
        "label": "Eagle Point Credit Company Inc. Series A Term Preferred Stock due 2022",
        "price": 25.95
    }, {
        "symbol": "ECCB",
        "label": "Eagle Point Credit Company Inc. 7.75% Series B Term Preferred Stock due 2026",
        "price": 26.631
    }, {
        "symbol": "ECCY",
        "label": "Eagle Point Credit Company Inc. 6.75% Notes due 2027",
        "price": 25.66
    }, {
        "symbol": "ECCZ",
        "label": "Eagle Point Credit Company Inc. 7.00% Notes due 2020",
        "price": 25.18
    }, {
        "symbol": "ECF",
        "label": "Ellsworth Growth and Income Fund Ltd.",
        "price": 11.68
    }, {
        "symbol": "ECNS",
        "label": "iShares MSCI China Small-Cap",
        "price": 41.04
    }, {
        "symbol": "ECT",
        "label": "ECA Marcellus Trust I of Beneficial Interest",
        "price": 0.64
    }, {
        "symbol": "EDBI",
        "label": "Legg Mason Emerging Markets Diversified Core ETF",
        "price": 28.12
    }, {
        "symbol": "EDEN",
        "label": "iShares Inc MSCI Denmark",
        "price": 61.59
    }, {
        "symbol": "EDF",
        "label": "Stone Harbor Emerging Markets Income Fund of Beneficial Interest",
        "price": 13.71
    }, {
        "symbol": "EDOW",
        "label": "First Trust Dow 30 Equal Weight",
        "price": 23.67
    }, {
        "symbol": "EEA",
        "label": "The European Equity Fund Inc.",
        "price": 9.63
    }, {
        "symbol": "EEB",
        "label": "Guggenheim BRIC",
        "price": 35.72
    }, {
        "symbol": "EEH",
        "label": "Aktiebolaget Svensk Exportkredit (Swed Ex Cred Corp) Elements (SM) Linked to the" +
                " SPECTRUM Large Cap U.S. Sector Momentum Index developed by BNP Paribas due Augu",
        "price": 18.82
    }, {
        "symbol": "EEI",
        "label": "Ecology and Environment Inc.",
        "price": 15.48
    }, {
        "symbol": "EEMD",
        "label": "AAM S&P Emerging Markets High Dividend Value",
        "price": 22.1
    }, {
        "symbol": "EEMO",
        "label": "PowerShares S&P Emerging Markets Momentum Portfolio",
        "price": 16.27
    }, {
        "symbol": "EEMS",
        "label": "Ishares MSCI Emerging Markets Small Cap Index Fund",
        "price": 43.04
    }, {
        "symbol": "EEMX",
        "label": "SPDR MSCI Emerging Markets Fuel Reserves Free",
        "price": 59.06
    }, {
        "symbol": "EET",
        "label": "ProShares Ultra MSCI Emerging Markets",
        "price": 66.22
    }, {
        "symbol": "EFAS",
        "label": "Global X MSCI SuperDividend EAFE ETF",
        "price": 16.515
    }, {
        "symbol": "EFAX",
        "label": "SPDR MSCI EAFE Fossil Fuel Reserves Free",
        "price": 65.33
    }, {
        "symbol": "EFBI",
        "label": "Eagle Financial Bancorp Inc.",
        "price": 16.66
    }, {
        "symbol": "EFFE",
        "label": "Global X JPMorgan Efficiente Index",
        "price": 24.97
    }, {
        "symbol": "EFNL",
        "label": "iShares Inc MSCI Finland",
        "price": 36.57
    }, {
        "symbol": "EFO",
        "label": "ProShares Ultra MSCI EAFE",
        "price": 36.42
    }, {
        "symbol": "EFOI",
        "label": "Energy Focus Inc.",
        "price": 0.6171
    }, {
        "symbol": "EGI",
        "label": "Entree Resources Ltd.",
        "price": 0.33
    }, {
        "symbol": "EHT",
        "label": "Eaton Vance High Income 2021 Target Term Trust of Beneficial Interest",
        "price": 10.08
    }, {
        "symbol": "EIA",
        "label": "Eaton Vance California Municipal Bond Fund II of Beneficial Interest $.01 par va" +
                "lue",
        "price": 10.22
    }, {
        "symbol": "EIO",
        "label": "Eaton Vance Ohio Municipal Bond Fundd of Befeficial Interest $.01 par value",
        "price": 11.77
    }, {
        "symbol": "EIP",
        "label": "Eaton Vance Pennsylvania Municipal Bond Fund of Befeficial Interest $.01 par val" +
                "ue",
        "price": 11.73
    }, {
        "symbol": "EIS",
        "label": "iShares Inc MSCI Israel",
        "price": 53.19
    }, {
        "symbol": "EIV",
        "label": "Eaton Vance Municipal Bond Fund II of Beneficial Interest $.01 Par Value",
        "price": 11.86
    }, {
        "symbol": "EKAR",
        "label": "Innovation Shares NextGen Vehicles & Technology",
        "price": 24.6824
    }, {
        "symbol": "ELC",
        "label": "Entergy Louisiana Inc. Collateral Trust Mortgage Bonds 4.875 % Series due Septem" +
                "ber 1 2066",
        "price": 26.3
    }, {
        "symbol": "ELEC",
        "label": "Electrum Special Acquisition Corporation",
        "price": 10.53
    }, {
        "symbol": "ELECU",
        "label": "Electrum Special Acquisition Corporation Unit",
        "price": 10.7
    }, {
        "symbol": "ELJ",
        "label": "Entergy Louisiana Inc. First Mortgage Bonds 5.25% Series due July 1 2052",
        "price": 26.33
    }, {
        "symbol": "ELLO",
        "label": "Ellomay Capital Ltd (Israel)",
        "price": 20.34
    }, {
        "symbol": "ELMD",
        "label": "Electromed Inc.",
        "price": 9.43
    }, {
        "symbol": "ELON",
        "label": "Echelon Corporation",
        "price": 8.45
    }, {
        "symbol": "ELTK",
        "label": "Eltek Ltd.",
        "price": 3.66
    }, {
        "symbol": "ELU",
        "label": "Entergy Louisiana Inc. First Mortgage Bonds 4.70% Series due June 1 2063",
        "price": 25.35
    }, {
        "symbol": "ELVT",
        "label": "Elevate Credit Inc.",
        "price": 4.48
    }, {
        "symbol": "EMAG",
        "label": "VanEck Vectors Emerging Markets Aggregate Bond",
        "price": 20.79
    }, {
        "symbol": "EMBH",
        "label": "iShares Interest Rate Hedged Emerging Markets Bond",
        "price": 24.75
    }, {
        "symbol": "EMBU",
        "label": "Direxion Funds Daily Emerging Markets Bond Bull 3X Shares",
        "price": 20.47
    }, {
        "symbol": "EMCF",
        "label": "Emclaire Financial Corp",
        "price": 32.15
    }, {
        "symbol": "EMDV",
        "label": "ProShares MSCI Emerging Markets Dividend Growers",
        "price": 55.4
    }, {
        "symbol": "EMEM",
        "label": "Virtus Glovista Emerging Markets",
        "price": 23.5
    }, {
        "symbol": "EMF",
        "label": "Templeton Emerging Markets Fund",
        "price": 16.29
    }, {
        "symbol": "EMI",
        "label": "Eaton Vance Michigan Municipal Income Trust Shares of Beneficial Interest",
        "price": 12.59
    }, {
        "symbol": "EMIF",
        "label": "iShares S&P Emerging Markets Infrastructure Index Fund",
        "price": 32.26
    }, {
        "symbol": "EMIH",
        "label": "Xtrackers Emerging Markets Bond - Interest Rate Hedged",
        "price": 24.21
    }, {
        "symbol": "EMITF",
        "label": "Elbit Imaging Ltd.",
        "price": 1.08
    }, {
        "symbol": "EMJ",
        "label": "Eaton Vance New Jersey Municipal Bond Fund Common Shres of Beneficial Interest $" +
                ".01 par value",
        "price": 12.09
    }, {
        "symbol": "EMLB",
        "label": "iPath Long Enhanced MSCI Emerging Markets Index ETN",
        "price": 80.8
    }, {
        "symbol": "EMMS",
        "label": "Emmis Communications Corporation",
        "price": 3.68
    }, {
        "symbol": "EMSH",
        "label": "ProShares Short Term USD Emerging Markets Bond",
        "price": 74.3
    }, {
        "symbol": "EMTL",
        "label": "SPDR DoubleLine Emerging Markets Fixed Income",
        "price": 49.94
    }, {
        "symbol": "EMTY",
        "label": "ProShares Decline of the Retail Store",
        "price": 33.51
    }, {
        "symbol": "EMX",
        "label": "EMX Royalty Corporation (Canada)",
        "price": 1.6799
    }, {
        "symbol": "EMXC",
        "label": "iShares MSCI Emerging Markets ex China ETF",
        "price": 52.3618
    }, {
        "symbol": "ENFR",
        "label": "Alerian Energy Infrastructure",
        "price": 21.315
    }, {
        "symbol": "ENG",
        "label": "ENGlobal Corporation",
        "price": 0.9948
    }, {
        "symbol": "ENO",
        "label": "Entergy New Orleans LLC First Mortgage Bonds 5.50% Series due April 1 2066",
        "price": 26.57
    }, {
        "symbol": "ENSV",
        "label": "Enservco Corporation",
        "price": 0.169
    }, {
        "symbol": "ENTR",
        "label": "ERShares Entrepreneur 30",
        "price": 18.47
    }, {
        "symbol": "ENX",
        "label": "Eaton Vance New York Municipal Bond Fund of Beneficial Interest $.01 par value",
        "price": 12.27
    }, {
        "symbol": "ENZL",
        "label": "iShares MSCI New Zealand ETF",
        "price": 58.45
    }, {
        "symbol": "EOT",
        "label": "Eaton Vance Municipal Income Trust EATON VANCE NATIONAL MUNICIPAL OPPORTUNITIES " +
                "TRUST",
        "price": 22.1
    }, {
        "symbol": "EPIX",
        "label": "ESSA Pharma Inc.",
        "price": 5.73
    }, {
        "symbol": "EPRF",
        "label": "Elkhorn S&P High Quality Preferred",
        "price": 23.34
    }, {
        "symbol": "EPS",
        "label": "WisdomTree U.S. Earnings 500 Fund",
        "price": 31.63
    }, {
        "symbol": "EQAL",
        "label": "PowerShares Russell 1000 Equal Weight POrtfolio",
        "price": 34.8151
    }, {
        "symbol": "EQFN",
        "label": "Equitable Financial Corp.",
        "price": 10.58
    }, {
        "symbol": "EQL",
        "label": "ALPS Equal Sector Weight",
        "price": 74.08
    }, {
        "symbol": "EQLT",
        "label": "Workplace Equality Portfolio",
        "price": 37.0
    }, {
        "symbol": "EQRR",
        "label": "ProShares Equities for Rising Rates ETF",
        "price": 43.5568
    }, {
        "symbol": "EQS",
        "label": "Equus Total Return Inc.",
        "price": 1.7
    }, {
        "symbol": "EQWM",
        "label": "PowerShares Russell Midcap Equal Weight Portfolio",
        "price": 49.07
    }, {
        "symbol": "EQWS",
        "label": "PowerShares Russell 2000 Equal Weight Portfolio",
        "price": 43.52
    }, {
        "symbol": "ERGF",
        "label": "iShares Edge MSCI Multifactor Energy",
        "price": 26.13
    }, {
        "symbol": "ERH",
        "label": "Wells Fargo Utilities and High Income Fund",
        "price": 14.29
    }, {
        "symbol": "ERN",
        "label": "Erin Energy Corp.",
        "price": 1.63
    }, {
        "symbol": "ERO",
        "label": "Barclays PLC iPath EUR/USD Exchange Rate ETN",
        "price": 45.69
    }, {
        "symbol": "ERYP",
        "label": "Erytech Pharma S.A.",
        "price": 7.2
    }, {
        "symbol": "ESBA",
        "label": "Empire State Realty OP L.P. Series ES Operating Partnership Units Representing L" +
                "imited Partnership Interests",
        "price": 15.4
    }, {
        "symbol": "ESBK",
        "label": "Elmira Savings Bank NY (The)",
        "price": 16.45
    }, {
        "symbol": "ESCA",
        "label": "Escalade Incorporated",
        "price": 8.97
    }, {
        "symbol": "ESEA",
        "label": "Euroseas Ltd.",
        "price": 4.0399
    }, {
        "symbol": "ESG",
        "label": "FlexShares STOXX US ESG Impact Index Fund",
        "price": 79.1037
    }, {
        "symbol": "ESGD",
        "label": "iShares MSCI EAFE ESG Optimized ETF",
        "price": 69.62
    }, {
        "symbol": "ESGF",
        "label": "Oppenheimer Revenue Weighted ETF Trust Global ESG Revenue",
        "price": 28.6
    }, {
        "symbol": "ESGG",
        "label": "FlexShares STOXX Global ESG Impact Index Fund",
        "price": 110.245
    }, {
        "symbol": "ESGL",
        "label": "Oppenheimer Revenue Weighted ETF Trust ESG Revenue",
        "price": 31.59
    }, {
        "symbol": "ESGN",
        "label": "Columbia Sustainable International Equity Income",
        "price": 25.94
    }, {
        "symbol": "ESGS",
        "label": "Columbia Sustainable U.S. Equity Income",
        "price": 24.03
    }, {
        "symbol": "ESGU",
        "label": "iShares MSCI USA ESG Optimized ETF",
        "price": 73.8
    }, {
        "symbol": "ESGW",
        "label": "Columbia Sustainable Global Equity Income",
        "price": 29.67
    }, {
        "symbol": "ESQ",
        "label": "Esquire Financial Holdings Inc.",
        "price": 24.76
    }, {
        "symbol": "ESTR",
        "label": "Estre Ambiental Inc.",
        "price": 0.3225
    }, {
        "symbol": "ESTRW",
        "label": "Estre Ambiental Inc. Warrants",
        "price": 0.01
    }, {
        "symbol": "ETHO",
        "label": "Etho Climate Leadership U.S.",
        "price": 37.45
    }, {
        "symbol": "ETX",
        "label": "Eaton Vance Municipal Income 2028 Term Trust of Beneficial Interest",
        "price": 20.67
    }, {
        "symbol": "EUDG",
        "label": "WisdomTree Europe Quality Dividend Growth Fund",
        "price": 28.5461
    }, {
        "symbol": "EUDV",
        "label": "ProShares MSCI Europe Dividend Growers",
        "price": 40.38
    }, {
        "symbol": "EUFL",
        "label": "Direxion Daily European Financials Bull 2X Shares",
        "price": 28.67
    }, {
        "symbol": "EUFX",
        "label": "ProShares Short Euro",
        "price": 43.26
    }, {
        "symbol": "EUMV",
        "label": "iShares Edge MSCI Min Vol Europe",
        "price": 25.19
    }, {
        "symbol": "EUO",
        "label": "ProShares UltraShort Euro",
        "price": 25.86
    }, {
        "symbol": "EURL",
        "label": "Direxion Daily FTSE Europe Bull 3x Shares",
        "price": 27.54
    }, {
        "symbol": "EURZ",
        "label": "Xtrackers Eurozone Equity",
        "price": 24.07
    }, {
        "symbol": "EUSA",
        "label": "iShares MSCI USA Equal Weighted",
        "price": 56.7
    }, {
        "symbol": "EVFM",
        "label": "Evofem Biosciences Inc.",
        "price": 5.9
    }, {
        "symbol": "EVFTC",
        "label": "Eaton Vance NextShares Trust II",
        "price": 100.05
    }, {
        "symbol": "EVG",
        "label": "Eaton Vance Short Diversified Income Fund Duration Diversified Income Fund of Be" +
                "neficial Interest",
        "price": 13.52
    }, {
        "symbol": "EVGBC",
        "label": "Eaton Vance NextShares Trust",
        "price": 100.06
    }, {
        "symbol": "EVGN",
        "label": "Evogene Ltd.",
        "price": 1.7
    }, {
        "symbol": "EVIX",
        "label": "VelocityShares 1x Long VSTOXX Futures ETN",
        "price": 8.65
    }, {
        "symbol": "EVK",
        "label": "Ever-Glory International Group Inc.",
        "price": 1.5
    }, {
        "symbol": "EVLMC",
        "label": "Eaton Vance NextShares Trust II",
        "price": 100.04
    }, {
        "symbol": "EVN",
        "label": "Eaton Vance Municipal Income Trust",
        "price": 12.99
    }, {
        "symbol": "EVO",
        "label": "Eaton Vance Ohio Municipal Income Trust Shares of Beneficial Interest",
        "price": 12.57
    }, {
        "symbol": "EVOK",
        "label": "Evoke Pharma Inc.",
        "price": 1.575
    }, {
        "symbol": "EVP",
        "label": "Eaton Vance Pennsylvania Municipal Income Trust Shares of Beneficial Interest",
        "price": 11.51
    }, {
        "symbol": "EVSTC",
        "label": "Eaton Vance NextShares Trust",
        "price": 100.02
    }, {
        "symbol": "EVX",
        "label": "VanEck Vectors Environmental Services",
        "price": 93.62
    }, {
        "symbol": "EWEM",
        "label": "Guggenheim MSCI Emerging Markets Equal Country Weight",
        "price": 31.61
    }, {
        "symbol": "EWK",
        "label": "iShares Inc MSCI Belgium",
        "price": 17.97
    }, {
        "symbol": "EWRE",
        "label": "Guggenheim S&P 500 Equal Weight Real Estate",
        "price": 29.53
    }, {
        "symbol": "EWSC",
        "label": "Guggenheim S&P SmallCap 600 Equal Weight",
        "price": 53.76
    }, {
        "symbol": "EWUS",
        "label": "Ishares MSCI United Kingdom Small Cap",
        "price": 40.49
    }, {
        "symbol": "EWV",
        "label": "ProShares UltraShort MSCI Japan",
        "price": 28.75
    }, {
        "symbol": "EXD",
        "label": "Eaton Vance Tax-Advantaged Bond of Beneficial Interest",
        "price": 10.41
    }, {
        "symbol": "EXFO",
        "label": "EXFO Inc",
        "price": 4.08
    }, {
        "symbol": "EYEG",
        "label": "Eyegate Pharmaceuticals Inc.",
        "price": 6.85
    }, {
        "symbol": "EYEN",
        "label": "Eyenovia Inc.",
        "price": 4.73
    }, {
        "symbol": "EYESW",
        "label": "Second Sight Medical Products Inc. Warrants",
        "price": 0.7001
    }, {
        "symbol": "EYLD",
        "label": "Cambria ETF Trust Emerging Shareholder Yield",
        "price": 30.08
    }, {
        "symbol": "EZJ",
        "label": "ProShares Ultra MSCI Japan",
        "price": 32.63
    }, {
        "symbol": "EZT",
        "label": "Entergy Texas Inc First Mortgage Bonds 5.625% Series due June 1 2064",
        "price": 28.24
    }, {
        "symbol": "FAAR",
        "label": "First Trust Alternative Absolute Return Strategy ETF",
        "price": 25.8701
    }, {
        "symbol": "FAB",
        "label": "First Trust Multi Cap Value AlphaDEX Fund",
        "price": 58.315
    }, {
        "symbol": "FAD",
        "label": "First Trust Multi Cap Growth AlphaDEX Fund",
        "price": 80.27
    }, {
        "symbol": "FAMI",
        "label": "Farmmi INC.",
        "price": 0.78
    }, {
        "symbol": "FAN",
        "label": "First Trust Global Wind Energy",
        "price": 12.92
    }, {
        "symbol": "FANZ",
        "label": "ProSports Sponsors",
        "price": 22.04
    }, {
        "symbol": "FAT",
        "label": "FAT Brands Inc.",
        "price": 4.66
    }, {
        "symbol": "FAUS",
        "label": "First Trust Australia AlphaDex fund",
        "price": 31.78
    }, {
        "symbol": "FBGX",
        "label": "UBS AG FI Enhanced Large Cap Growth ETN",
        "price": 258.05
    }, {
        "symbol": "FBIOP",
        "label": "Fortress Biotech Inc. 9.375% Series A Cumulative Redeemable Perpetual Preferred " +
                "Stock",
        "price": 21.24
    }, {
        "symbol": "FBND",
        "label": "Fidelity Total Bond",
        "price": 52.03
    }, {
        "symbol": "FCA",
        "label": "First Trust China AlphaDEX Fund",
        "price": 27.42
    }, {
        "symbol": "FCAL",
        "label": "First Trust California Municipal High income ETF",
        "price": 53.9244
    }, {
        "symbol": "FCAN",
        "label": "First Trust Canada AlphaDEX Fund",
        "price": 25.515
    }, {
        "symbol": "FCAP",
        "label": "First Capital Inc.",
        "price": 72.5
    }, {
        "symbol": "FCEF",
        "label": "First Trust CEF Income Opportunity ETF",
        "price": 23.45
    }, {
        "symbol": "FCO",
        "label": "Aberdeen Global Income Fund Inc.",
        "price": 8.227
    }, {
        "symbol": "FCOM",
        "label": "Fidelity MSCI Telecommunication Services Index",
        "price": 37.73
    }, {
        "symbol": "FCOR",
        "label": "Fidelity Corporate Bond",
        "price": 50.87
    }, {
        "symbol": "FCRE",
        "label": "FC Global Realty Incorporated",
        "price": 0.76
    }, {
        "symbol": "FDBC",
        "label": "Fidelity D & D Bancorp Inc.",
        "price": 61.0
    }, {
        "symbol": "FDD",
        "label": "First Trust Dow Jones STOXX Select Dividend 30 Index Fund",
        "price": 12.7
    }, {
        "symbol": "FDIV",
        "label": "First Trust Strategic Income ETF",
        "price": 51.79
    }, {
        "symbol": "FDLO",
        "label": "Fidelity Low Volatility Factor",
        "price": 34.37
    }, {
        "symbol": "FDM",
        "label": "First Trust DJ Select MicroCap",
        "price": 50.465
    }, {
        "symbol": "FDMO",
        "label": "Fidelity Momentum Factor",
        "price": 33.49
    }, {
        "symbol": "FDRR",
        "label": "Fidelity Dividend ETF for Rising Rates",
        "price": 30.78
    }, {
        "symbol": "FDTS",
        "label": "First Trust Developed Markets ex-US Small Cap AlphaDEX Fund",
        "price": 38.265
    }, {
        "symbol": "FEIM",
        "label": "Frequency Electronics Inc.",
        "price": 9.7
    }, {
        "symbol": "FELP",
        "label": "Foresight Energy LP representing Limited Partner Interests",
        "price": 0.1582
    }, {
        "symbol": "FEM",
        "label": "First Trust Emerging Markets AlphaDEX Fund",
        "price": 27.59
    }, {
        "symbol": "FEO",
        "label": "First Trust/Aberdeen Emerging Opportunity Fund of Beneficial Interest",
        "price": 14.91
    }, {
        "symbol": "FEP",
        "label": "First Trust Europe AlphaDEX Fund",
        "price": 37.97
    }, {
        "symbol": "FEX",
        "label": "First Trust Large Cap Core AlphaDEX Fund",
        "price": 66.7676
    }, {
        "symbol": "FFA",
        "label": "First Trust Enhanced Equity Income Fund",
        "price": 17.17
    }, {
        "symbol": "FFBCW",
        "label": "First Financial Bancorp. Warrant 12/23/2018",
        "price": 18.1
    }, {
        "symbol": "FFBW",
        "label": "FFBW Inc.",
        "price": 10.75
    }, {
        "symbol": "FFHG",
        "label": "Formula Folios Hedged Growth",
        "price": 26.85
    }, {
        "symbol": "FFHL",
        "label": "Fuwei Films (Holdings) Co. Ltd.",
        "price": 3.03
    }, {
        "symbol": "FFIU",
        "label": "Fieldstone UVA Unconstrained Medium-Term Fixed Income",
        "price": 24.94
    }, {
        "symbol": "FFSG",
        "label": "FormulaFolios Smart Growth",
        "price": 25.32
    }, {
        "symbol": "FFTG",
        "label": "FormulaFolios Tactical Growth",
        "price": 23.94
    }, {
        "symbol": "FFTI",
        "label": "FormulaFolios Tactical Income",
        "price": 24.2
    }, {
        "symbol": "FHK",
        "label": "First Trust Hong Kong AlphaDEX Fund",
        "price": 36.575
    }, {
        "symbol": "FHY",
        "label": "First Trust Strategic High Income Fund II of Beneficial Interest",
        "price": 11.85
    }, {
        "symbol": "FIBR",
        "label": "iShares Edge U.S. Fixed Income Balanced Risk",
        "price": 102.34
    }, {
        "symbol": "FIDI",
        "label": "Fidelity International High Dividend",
        "price": 19.98
    }, {
        "symbol": "FIEG",
        "label": "FI Enhanced Gloabl High Yield ETN",
        "price": 156.0
    }, {
        "symbol": "FINZ",
        "label": "ProShares UltraPro Short Financial Select Sector",
        "price": 6.45
    }, {
        "symbol": "FISK",
        "label": "Empire State Realty OP L.P. Series 250 Operating Partnership Units Representing " +
                "Limited Partnership Interests",
        "price": 14.64
    }, {
        "symbol": "FITBI",
        "label": "Fifth Third Bancorp Depositary Share repstg 1/1000th Ownership Interest Perp Pfd" +
                " Series I",
        "price": 28.79
    }, {
        "symbol": "FIVA",
        "label": "Fidelity International Value Factor",
        "price": 21.12
    }, {
        "symbol": "FIW",
        "label": "First Trust Water",
        "price": 50.27
    }, {
        "symbol": "FIXD",
        "label": "First Trust TCW Opportunistic Fixed Income ETF",
        "price": 52.05
    }, {
        "symbol": "FIYY",
        "label": "Barclays ETN FI Enhanced Global High Yield Exchange Traded Notes Series B",
        "price": 97.65
    }, {
        "symbol": "FJP",
        "label": "First Trust Japan AlphaDEX Fund",
        "price": 50.715
    }, {
        "symbol": "FKO",
        "label": "First Trust South Korea AlphaDEX Fund",
        "price": 20.74
    }, {
        "symbol": "FKU",
        "label": "First Trust United Kingdom AlphaDEX Fund",
        "price": 40.9028
    }, {
        "symbol": "FLAG",
        "label": "Exchange Traded Concepts Trust FLAG-Forensic Accounting Long-Short",
        "price": 40.1294
    }, {
        "symbol": "FLAT",
        "label": "iPath US Treasury Flattener ETN",
        "price": 68.97
    }, {
        "symbol": "FLAU",
        "label": "Franklin FTSE Australia",
        "price": 25.08
    }, {
        "symbol": "FLAX",
        "label": "Franklin FTSE Asia ex Japan",
        "price": 20.27
    }, {
        "symbol": "FLBR",
        "label": "Franklin FTSE Brazil",
        "price": 26.13
    }, {
        "symbol": "FLCA",
        "label": "Franklin FTSE Canada",
        "price": 24.87
    }, {
        "symbol": "FLCH",
        "label": "Franklin FTSE China",
        "price": 21.74
    }, {
        "symbol": "FLCO",
        "label": "Franklin Liberty Investment Grade Corporate",
        "price": 24.27
    }, {
        "symbol": "FLEH",
        "label": "Franklin FTSE Europe Hedged",
        "price": 23.62
    }, {
        "symbol": "FLFR",
        "label": "Franklin FTSE France",
        "price": 24.47
    }, {
        "symbol": "FLGB",
        "label": "Franklin FTSE United Kingdom",
        "price": 23.9
    }, {
        "symbol": "FLGE",
        "label": "Credit Suisse FI Large Cap Growth Enhanced ETN",
        "price": 226.64
    }, {
        "symbol": "FLGR",
        "label": "Franklin FTSE Germany",
        "price": 19.21
    }, {
        "symbol": "FLGT",
        "label": "Fulgent Genetics Inc.",
        "price": 13.7
    }, {
        "symbol": "FLHK",
        "label": "Franklin FTSE Hong Kong",
        "price": 26.95
    }, {
        "symbol": "FLIN",
        "label": "Franklin FTSE India",
        "price": 22.06
    }, {
        "symbol": "FLIO",
        "label": "Franklin Liberty International Opportunities",
        "price": 29.5707
    }, {
        "symbol": "FLIY",
        "label": "Franklin FTSE Italy",
        "price": 23.58
    }, {
        "symbol": "FLJH",
        "label": "Franklin FTSE Japan Hedged",
        "price": 23.33
    }, {
        "symbol": "FLJP",
        "label": "Franklin FTSE Japan",
        "price": 23.35
    }, {
        "symbol": "FLKR",
        "label": "Franklin FTSE South Korea",
        "price": 19.57
    }, {
        "symbol": "FLLV",
        "label": "Franklin Liberty U.S. Low Volatility",
        "price": 34.05
    }, {
        "symbol": "FLM",
        "label": "First Trust Global Engineering and Construction",
        "price": 47.22
    }, {
        "symbol": "FLMB",
        "label": "Franklin Templeton ETF Trust Liberty Municipal Bond",
        "price": 25.62
    }, {
        "symbol": "FLMI",
        "label": "Franklin Templeton ETF Trust Liberty Intermediate Municipal Opportunities",
        "price": 24.96
    }, {
        "symbol": "FLMX",
        "label": "Franklin FTSE Mexico",
        "price": 21.48
    }, {
        "symbol": "FLN",
        "label": "First Trust Latin America AlphaDEX Fund",
        "price": 23.58
    }, {
        "symbol": "FLQD",
        "label": "Franklin LibertyQ Global Dividend",
        "price": 28.51
    }, {
        "symbol": "FLQG",
        "label": "Franklin LibertyQ Global Equity",
        "price": 31.24
    }, {
        "symbol": "FLQH",
        "label": "Franklin LibertyQ International Equity Hedged",
        "price": 24.61
    }, {
        "symbol": "FLQL",
        "label": "Franklin LibertyQ U.S. Equity",
        "price": 31.35
    }, {
        "symbol": "FLQM",
        "label": "Franklin LibertyQ U.S. Mid Cap Equity",
        "price": 30.41
    }, {
        "symbol": "FLQS",
        "label": "Franklin LibertyQ U.S. Small Cap Equity",
        "price": 28.73
    }, {
        "symbol": "FLRT",
        "label": "AdvisorShares Pacific Asset Enhanced Floating Rate",
        "price": 48.94
    }, {
        "symbol": "FLRU",
        "label": "Franklin FTSE Russia",
        "price": 22.72
    }, {
        "symbol": "FLSW",
        "label": "Franklin FTSE Switzerland",
        "price": 24.43
    }, {
        "symbol": "FLTW",
        "label": "Franklin FTSE Taiwan",
        "price": 24.35
    }, {
        "symbol": "FMAT",
        "label": "Fidelity MSCI Materials Index",
        "price": 34.14
    }, {
        "symbol": "FMB",
        "label": "First Trust Managed Municipal ETF",
        "price": 56.0972
    }, {
        "symbol": "FMDG",
        "label": "Fieldstone Merlin Dynamic Large Cap Growth",
        "price": 25.03
    }, {
        "symbol": "FMF",
        "label": "First Trust Morningstar Managed Futures Strategy Fund",
        "price": 44.83
    }, {
        "symbol": "FMK",
        "label": "First Trust Mega Cap AlphaDEX Fund",
        "price": 39.1533
    }, {
        "symbol": "FMN",
        "label": "Federated Premier Municipal Income Fund",
        "price": 14.395
    }, {
        "symbol": "FMY",
        "label": "First Trust Motgage Income Fund of Beneficial Interest",
        "price": 14.05
    }, {
        "symbol": "FNCB",
        "label": "FNCB Bancorp Inc.",
        "price": 8.05
    }, {
        "symbol": "FNCF",
        "label": "iShares Edge MSCI Multifactor Financials",
        "price": 34.76
    }, {
        "symbol": "FNDC",
        "label": "Schwab Fundamental International Small Company Index",
        "price": 33.09
    }, {
        "symbol": "FNGD",
        "label": "BMO REX MicroSectors FANG Index -3X Inverse Leveraged Exchange Traded Notes",
        "price": 29.16
    }, {
        "symbol": "FNK",
        "label": "First Trust Mid Cap Value AlphaDEX Fund",
        "price": 36.1496
    }, {
        "symbol": "FNTE",
        "label": "FinTech Acquisition Corp. II",
        "price": 9.84
    }, {
        "symbol": "FNTEU",
        "label": "FinTech Acquisition Corp. II Unit",
        "price": 10.8
    }, {
        "symbol": "FNTEW",
        "label": "FinTech Acquisition Corp. II Warrant",
        "price": 1.58
    }, {
        "symbol": "FOANC",
        "label": "Gabelli NextShares Trust",
        "price": 100.04
    }, {
        "symbol": "FOIL",
        "label": "iPath Pure Beta Aluminum ETN",
        "price": 27.89
    }, {
        "symbol": "FONE",
        "label": "First Trust NASDAQ Smartphone Index Fund",
        "price": 46.7
    }, {
        "symbol": "FORD",
        "label": "Forward Industries Inc.",
        "price": 1.1126
    }, {
        "symbol": "FORK",
        "label": "Fuling Global Inc.",
        "price": 2.38
    }, {
        "symbol": "FORTY",
        "label": "Formula Systems (1985) Ltd. ADS represents 1 ordinary shares",
        "price": 75.81
    }, {
        "symbol": "FPA",
        "label": "First Trust Asia Pacific Ex-Japan AlphaDEX Fund",
        "price": 29.175
    }, {
        "symbol": "FPAY",
        "label": "FlexShopper Inc.",
        "price": 2.52
    }, {
        "symbol": "FPEI",
        "label": "First Trust Institutional Preferred Securities and Income",
        "price": 19.07
    }, {
        "symbol": "FPXI",
        "label": "First Trust International IPO ETF",
        "price": 42.18
    }, {
        "symbol": "FQAL",
        "label": "Fidelity Quality Factor",
        "price": 32.86
    }, {
        "symbol": "FRAK",
        "label": "VanEck Vectors Unconventional Oil & Gas",
        "price": 11.22
    }, {
        "symbol": "FRD",
        "label": "Friedman Industries Inc.",
        "price": 5.9
    }, {
        "symbol": "FSAC",
        "label": "Federal Street Acquisition Corp.",
        "price": 10.12
    }, {
        "symbol": "FSACU",
        "label": "Federal Street Acquisition Corp. Unit consisting of One Common Stock and Half of" +
                " a Warrant",
        "price": 10.61
    }, {
        "symbol": "FSACW",
        "label": "Federal Street Acquisition Corp. Warrant",
        "price": 0.77
    }, {
        "symbol": "FSBC",
        "label": "FSB Bancorp Inc.",
        "price": 17.24
    }, {
        "symbol": "FSBW",
        "label": "FS Bancorp Inc.",
        "price": 62.9
    }, {
        "symbol": "FSFG",
        "label": "First Savings Financial Group Inc.",
        "price": 65.0
    }, {
        "symbol": "FSI",
        "label": "Flexible Solutions International Inc.",
        "price": 2.42
    }, {
        "symbol": "FSTA",
        "label": "Fidelity MSCI COnsumer Staples Index",
        "price": 34.25
    }, {
        "symbol": "FTA",
        "label": "First Trust Large Cap Value AlphaDEX Fund",
        "price": 57.05
    }, {
        "symbol": "FTAG",
        "label": "First Trust Indxx Global Agriculture ETF",
        "price": 23.5517
    }, {
        "symbol": "FTEO",
        "label": "FRONTEO Inc.",
        "price": 5.55
    }, {
        "symbol": "FTFT",
        "label": "Future FinTech Group Inc.",
        "price": 0.8645
    }, {
        "symbol": "FTGC",
        "label": "First Trust Global Tactical Commodity Strategy Fund",
        "price": 18.8
    }, {
        "symbol": "FTHI",
        "label": "First Trust BuyWrite Income ETF",
        "price": 23.0851
    }, {
        "symbol": "FTLB",
        "label": "First Trust Hedged BuyWrite Income ETF",
        "price": 22.5403
    }, {
        "symbol": "FTNW",
        "label": "FTE Networks Inc.",
        "price": 1.5
    }, {
        "symbol": "FTRI",
        "label": "First Trust Indxx Global Natural Resources Income ETF",
        "price": 12.9447
    }, {
        "symbol": "FTSD",
        "label": "Franklin Liberty Short Duration U.S. Government",
        "price": 94.59
    }, {
        "symbol": "FTVA",
        "label": "Aptus Fortified Value",
        "price": 24.47
    }, {
        "symbol": "FTW",
        "label": "First Trust Taiwan AlphaDEX Fund",
        "price": 38.7897
    }, {
        "symbol": "FTXD",
        "label": "First Trust Nasdaq Retail ETF",
        "price": 24.7272
    }, {
        "symbol": "FTXG",
        "label": "First Trust Nasdaq Food & Beverage ETF",
        "price": 22.265
    }, {
        "symbol": "FTXH",
        "label": "First Trust Nasdaq Pharmaceuticals ETF",
        "price": 24.1737
    }, {
        "symbol": "FTXL",
        "label": "First Trust Nasdaq Semiconductor ETF",
        "price": 42.89
    }, {
        "symbol": "FTXN",
        "label": "First Trust Nasdaq Oil & Gas ETF",
        "price": 16.1
    }, {
        "symbol": "FTXR",
        "label": "First Trust Nasdaq Transportation ETF",
        "price": 25.1484
    }, {
        "symbol": "FUD",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI F" +
                "ood ETN",
        "price": 16.35
    }, {
        "symbol": "FUE",
        "label": "AB Svensk Ekportkredit (Swedish Export Credit Corporation) ELEMENTS Linked to th" +
                "e MLCX Biofuels Index - Total Return Structured Product",
        "price": 6.04
    }, {
        "symbol": "FUNC",
        "label": "First United Corporation",
        "price": 24.05
    }, {
        "symbol": "FUND",
        "label": "Sprott Focus Trust Inc.",
        "price": 7.47
    }, {
        "symbol": "FUSB",
        "label": "First US Bancshares Inc.",
        "price": 11.01
    }, {
        "symbol": "FUT",
        "label": "ProShares Managed Futures Strategy",
        "price": 39.045
    }, {
        "symbol": "FUTY",
        "label": "Fidelity MSCI Utilities Index",
        "price": 43.61
    }, {
        "symbol": "FUV",
        "label": "Arcimoto Inc.",
        "price": 1.62
    }, {
        "symbol": "FV",
        "label": "First Trust Dorsey Wright Focus 5 ETF",
        "price": 32.76
    }, {
        "symbol": "FVL",
        "label": "First Trust Value Line 100 Fund",
        "price": 21.29
    }, {
        "symbol": "FWDB",
        "label": "Madrona Global Bond",
        "price": 24.71
    }, {
        "symbol": "FWDD",
        "label": "Madrona Domestic",
        "price": 51.08
    }, {
        "symbol": "FWDI",
        "label": "Madrona International",
        "price": 27.21
    }, {
        "symbol": "FXA",
        "label": "Guggenheim CurrencyShares Australian Dollar Trust",
        "price": 70.0
    }, {
        "symbol": "FXB",
        "label": "Guggenheim CurrencyShares British Pound Sterling Trust",
        "price": 123.11
    }, {
        "symbol": "FXCH",
        "label": "Guggenheim CurrencyShares Chinese Renminbi Trust",
        "price": 70.15
    }, {
        "symbol": "FXD",
        "label": "First Trust Cons. Discret. AlphaDEX",
        "price": 41.36
    }, {
        "symbol": "FXG",
        "label": "First Trust Cons. Staples AlphaDEX",
        "price": 49.9305
    }, {
        "symbol": "FXH",
        "label": "First Trust Health Care AlphaDEX",
        "price": 87.1
    }, {
        "symbol": "FXL",
        "label": "First Trust Technology AlphaDEX",
        "price": 63.06
    }, {
        "symbol": "FXS",
        "label": "Guggenheim CurrencyShares Swedish Krona Trust",
        "price": 103.72
    }, {
        "symbol": "FXSG",
        "label": "Guggenheim CurrencyShares Singapore Dollar Trust",
        "price": 74.95
    }, {
        "symbol": "FYC",
        "label": "First Trust Small Cap Growth AlphaDEX Fund",
        "price": 48.2439
    }, {
        "symbol": "FYLD",
        "label": "Cambria Foreign Shareholder Yield",
        "price": 22.2
    }, {
        "symbol": "FYX",
        "label": "First Trust Small Cap Core AlphaDEX Fund",
        "price": 65.63
    }, {
        "symbol": "GAA",
        "label": "Cambria Global Asset Allocation",
        "price": 26.32
    }, {
        "symbol": "GAINM",
        "label": "Gladstone Investment Corporation 6.25% Series D Cumulative Term Preferred Stock",
        "price": 25.669
    }, {
        "symbol": "GAINN",
        "label": "Gladstone Investment Corporation 6.50% Series C Cumulative Term Preferred Stock " +
                "Due 2022",
        "price": 25.11
    }, {
        "symbol": "GAINO",
        "label": "Gladstone Investment Corporation 6.75% Series B Cumulative Term Preferred Stock",
        "price": 25.41
    }, {
        "symbol": "GAM",
        "label": "General American Investors Inc.",
        "price": 38.4
    }, {
        "symbol": "GAMR",
        "label": "ETFMG Video Game Tech",
        "price": 42.19
    }, {
        "symbol": "GARD",
        "label": "Realty Shares DIVCON Dividend Guard",
        "price": 22.59
    }, {
        "symbol": "GASX",
        "label": "Direxion Daily Natural Gas Related Bear 3X Shares",
        "price": 46.71
    }, {
        "symbol": "GAZB",
        "label": "Barclays Bank PLC iPath Series B Bloomberg Natural Gas Subindex Total Return ETN",
        "price": 37.92
    }, {
        "symbol": "GBAB",
        "label": "Guggenheim Taxable Municipal Managed Duration Trust of Beneficial Interest",
        "price": 24.14
    }, {
        "symbol": "GBB",
        "label": "iPath Goldman Sachs GBP/USD Exchange Rate ETN",
        "price": 36.91
    }, {
        "symbol": "GBF",
        "label": "iShares Government/Credit Bond",
        "price": 114.59
    }, {
        "symbol": "GBIL",
        "label": "Goldman Sachs Group Inc. (The)",
        "price": 100.18
    }, {
        "symbol": "GBLIL",
        "label": "Global Indemnity Limited 7.875% Subordinated Notes due 2047",
        "price": 27.26
    }, {
        "symbol": "GBR",
        "label": "New Concept Energy Inc",
        "price": 1.28
    }, {
        "symbol": "GCE",
        "label": "Clarymore CEF GS Connect ETN",
        "price": 14.73
    }, {
        "symbol": "GCV",
        "label": "Gabelli Convertible and Income Securities Fund Inc. (The)",
        "price": 6.03
    }, {
        "symbol": "GCVRZ",
        "label": "Sanofi Contingent Value Right (Expiring 12/31/2020)",
        "price": 0.8702
    }, {
        "symbol": "GDO",
        "label": "Western Asset Global Corporate Defined Opportunity Fund Inc.",
        "price": 18.21
    }, {
        "symbol": "GDVD",
        "label": "Principal Active Global Dividend Income",
        "price": 28.14
    }, {
        "symbol": "GDXS",
        "label": "ProShares UltraShort Gold Miners",
        "price": 14.28
    }, {
        "symbol": "GDXX",
        "label": "ProShares Ultra Gold Miners",
        "price": 33.89
    }, {
        "symbol": "GECCL",
        "label": "Great Elm Capital Corp. 6.50% Notes due 2022",
        "price": 25.1637
    }, {
        "symbol": "GECCM",
        "label": "Great Elm Capital Corp. 6.75% Notes Due 2025",
        "price": 25.7579
    }, {
        "symbol": "GEMP",
        "label": "Gemphire Therapeutics Inc.",
        "price": 0.3
    }, {
        "symbol": "GENE",
        "label": "Genetic Technologies Ltd",
        "price": 3.94
    }, {
        "symbol": "GEVO",
        "label": "Gevo Inc.",
        "price": 2.19
    }, {
        "symbol": "GFED",
        "label": "Guaranty Federal Bancshares Inc.",
        "price": 24.98
    }, {
        "symbol": "GFNCP",
        "label": "General Finance Corporation Cumulative Redeemable Perpetual Preferred Series C",
        "price": 109.0
    }, {
        "symbol": "GFNSL",
        "label": "General Finance Corporation Senior Notes due 2021",
        "price": 25.8
    }, {
        "symbol": "GFY",
        "label": "Western Asset Variable Rate Strategic Fund Inc.",
        "price": 16.75
    }, {
        "symbol": "GGO",
        "label": "The Gabelli Go Anywhere Trust of Beneficial Interest",
        "price": 15.68
    }, {
        "symbol": "GGZ",
        "label": "Gabelli Global Small and Mid Cap Value Trust (The) of Beneficial Interest",
        "price": 11.989
    }, {
        "symbol": "GHII",
        "label": "Guggenheim S&P High Income Infrastructure",
        "price": 29.3853
    }, {
        "symbol": "GHS",
        "label": "REX Gold Hedged S&P 500",
        "price": 33.54
    }, {
        "symbol": "GHYB",
        "label": "Goldman Sachs Access High Yield Corporate Bond",
        "price": 48.73
    }, {
        "symbol": "GHYG",
        "label": "iShares US & Intl High Yield Corp Bond",
        "price": 48.29
    }, {
        "symbol": "GIG",
        "label": "GigCapital Inc.",
        "price": 11.15
    }, {
        "symbol": "GIGB",
        "label": "Goldman Sachs Access Investment Grade Corporate Bond",
        "price": 49.77
    }, {
        "symbol": "GIGM",
        "label": "GigaMedia Limited",
        "price": 2.21
    }, {
        "symbol": "GILT",
        "label": "Gilat Satellite Networks Ltd.",
        "price": 9.15
    }, {
        "symbol": "GJH",
        "label": "Synthetic Fixed-Income Securities Inc 6.375% (STRATS) Cl A-1",
        "price": 10.1612
    }, {
        "symbol": "GJO",
        "label": "Synthetic Fixed-Income Securities Inc. on behalf of STRATS(SM) Trust for Wal-Mar" +
                "t Stores Inc. Securities Series 2004-5",
        "price": 23.5
    }, {
        "symbol": "GJP",
        "label": "Synthetic Fixed-Income Securities Inc. on behalf of STRATS (SM) Trust for Domini" +
                "on Resources Inc. Securities Series 2005-6 Floating Rate Structured Repackaged A",
        "price": 23.49
    }, {
        "symbol": "GJR",
        "label": "Synthetic Fixed-Income Securities Inc. STRATS Trust for Procter&Gamble Securitie" +
                "s Series 2006-1",
        "price": 22.5
    }, {
        "symbol": "GJS",
        "label": "Goldman Sachs Group Securities STRATS Trust for Series 2006-2",
        "price": 21.15
    }, {
        "symbol": "GJT",
        "label": "Synthetic Fixed-Income Securities Inc. Floating Rate Structured Repackaged Asset" +
                "-Backed Trust Securities Certificates Series 2006-3",
        "price": 21.35
    }, {
        "symbol": "GJV",
        "label": "Synthetic Fixed-Income Securities Inc 7.00% Fixed Rate Structured Repackaged Ass" +
                "et-Backed Trust Securities (STRATS)",
        "price": 26.2635
    }, {
        "symbol": "GLADN",
        "label": "Gladstone Capital Corporation 6.00% Series 2024 Term Preferred Stock",
        "price": 25.44
    }, {
        "symbol": "GLBS",
        "label": "Globus Maritime Limited",
        "price": 0.9974
    }, {
        "symbol": "GLBZ",
        "label": "Glen Burnie Bancorp",
        "price": 11.45
    }, {
        "symbol": "GLDI",
        "label": "Credit Suisse Gold Shares Covered Call Exchange Traded Notes",
        "price": 9.15
    }, {
        "symbol": "GLDW",
        "label": "SPDR Long Dollar Gold Trust",
        "price": 127.33
    }, {
        "symbol": "GLU",
        "label": "Gabelli Global Utility of Beneficial Ownership",
        "price": 19.4611
    }, {
        "symbol": "GLV",
        "label": "Clough Global Dividend and Income Fund of beneficial interest",
        "price": 11.3329
    }, {
        "symbol": "GMFL",
        "label": "Guggenheim Multi-Factor Large Cap",
        "price": 28.23
    }, {
        "symbol": "GMLPP",
        "label": "Golar LNG Partners LP 8.75% Series A Cumulative Redeemable Preferred Units",
        "price": 25.945
    }, {
        "symbol": "GMO",
        "label": "General Moly Inc.",
        "price": 0.2393
    }, {
        "symbol": "GMOM",
        "label": "Cambria Global Momentum",
        "price": 24.71
    }, {
        "symbol": "GMTA",
        "label": "GATX Corporation 5.625% Senior Notes due 2066",
        "price": 27.59
    }, {
        "symbol": "GNRX",
        "label": "VanEck Vectors Generic Drugs ETF",
        "price": 22.05
    }, {
        "symbol": "GNTY",
        "label": "Guaranty Bancshares Inc.",
        "price": 32.34
    }, {
        "symbol": "GNUS",
        "label": "Genius Brands International Inc.",
        "price": 0.305
    }, {
        "symbol": "GOEX",
        "label": "Global X Gold Explorers",
        "price": 25.84
    }, {
        "symbol": "GOODM",
        "label": "Gladstone Commercial Corporation Series D Cumulative Redeemable Preferred Stock",
        "price": 26.16
    }, {
        "symbol": "GOODO",
        "label": "Gladstone Commercial Corporation 7.50% Series B Cumulative Redeemable Preferred " +
                "Stock",
        "price": 26.77
    }, {
        "symbol": "GOODP",
        "label": "Gladstone Commercial Corporation 7.75% Series A Cumulative Redeemable Preferred " +
                "Stock",
        "price": 26.49
    }, {
        "symbol": "GOP",
        "label": "Republican Policies Fund",
        "price": 22.42
    }, {
        "symbol": "GPAQ",
        "label": "Gordon Pointe Acquisition Corp.",
        "price": 10.56
    }, {
        "symbol": "GPAQU",
        "label": "Gordon Pointe Acquisition Corp. Unit",
        "price": 10.68
    }, {
        "symbol": "GPAQW",
        "label": "Gordon Pointe Acquisition Corp. Warrant",
        "price": 0.36
    }, {
        "symbol": "GPIC",
        "label": "Gaming Partners International Corporation",
        "price": 13.71
    }, {
        "symbol": "GQRE",
        "label": "FlexShares Global Quality Real Estate Index Fund",
        "price": 62.68
    }, {
        "symbol": "GRBIC",
        "label": "Gabelli NextShares Trust",
        "price": 100.05
    }, {
        "symbol": "GRES",
        "label": "IQ ARB Global Resources",
        "price": 25.77
    }, {
        "symbol": "GRF",
        "label": "Eagle Capital Growth Fund Inc.",
        "price": 7.78
    }, {
        "symbol": "GRI",
        "label": "Cohen & Steers Global Realty Majors",
        "price": 48.42
    }, {
        "symbol": "GRID",
        "label": "First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund",
        "price": 56.2159
    }, {
        "symbol": "GRMY",
        "label": "Xtrackers Germany Equity",
        "price": 23.935
    }, {
        "symbol": "GRN",
        "label": "iPath Global Carbon ETN",
        "price": 16.81
    }, {
        "symbol": "GRNB",
        "label": "VanEck Vectors Green Bond",
        "price": 25.92
    }, {
        "symbol": "GRR",
        "label": "Asia Tigers Fund Inc. (The)",
        "price": 11.0
    }, {
        "symbol": "GRU",
        "label": "AB Svensk Ekportkredit (Swedish Export Credit Corporation) ELEMENTS Linked to th" +
                "e MLCX Grains Index - Total Return",
        "price": 3.24
    }, {
        "symbol": "GRWN",
        "label": "iPath Pure Beta Softs ETN",
        "price": 19.72
    }, {
        "symbol": "GSB",
        "label": "GlobalSCAPE Inc.",
        "price": 11.0
    }, {
        "symbol": "GSC",
        "label": "Goldman Sachs Connect S&P Enhanced Commodity Total Return Strategy Index",
        "price": 22.46
    }, {
        "symbol": "GSD",
        "label": "WisdomTree Global SmallCap Dividend Fund",
        "price": 30.56
    }, {
        "symbol": "GSEU",
        "label": "Goldman Sachs ActiveBeta Europe Equity",
        "price": 31.964
    }, {
        "symbol": "GSEW",
        "label": "Goldman Sachs Equal Weight U.S. Large Cap Equity",
        "price": 44.75
    }, {
        "symbol": "GSH",
        "label": "Guangshen Railway Company Limited",
        "price": 15.55
    }, {
        "symbol": "GSHT",
        "label": "Gores Holdings II Inc.",
        "price": 10.51
    }, {
        "symbol": "GSHTU",
        "label": "Gores Holdings II Inc. Unit",
        "price": 10.92
    }, {
        "symbol": "GSHTW",
        "label": "Gores Holdings II Inc. Warrants",
        "price": 2.2
    }, {
        "symbol": "GSIT",
        "label": "GSI Technology Inc.",
        "price": 8.32
    }, {
        "symbol": "GSJY",
        "label": "Goldman Sachs ActiveBeta Japan Equity",
        "price": 29.45
    }, {
        "symbol": "GSP",
        "label": "Barclays Bank PLC iPath Exchange Traded Notes due June 12 2036 Linked to GSCI To" +
                "tal Return Index",
        "price": 14.28
    }, {
        "symbol": "GSSC",
        "label": "GS ActiveBeta U.S. Small Cap Equity",
        "price": 44.75
    }, {
        "symbol": "GTIM",
        "label": "Good Times Restaurants Inc.",
        "price": 1.74
    }, {
        "symbol": "GTO",
        "label": "Guggenheim Total Return Bond",
        "price": 54.66
    }, {
        "symbol": "GTYH",
        "label": "GTY Technology Holdings Inc.",
        "price": 6.14
    }, {
        "symbol": "GTYHU",
        "label": "GTY Technology Holdings Inc. Unit",
        "price": 10.87
    }, {
        "symbol": "GTYHW",
        "label": "GTY Technology Holdings Inc. Warrants",
        "price": 0.82
    }, {
        "symbol": "GUDB",
        "label": "Sage ESG Intermediate Credit",
        "price": 49.67
    }, {
        "symbol": "GULF",
        "label": "WisdomTree Middle East Dividend Fund",
        "price": 20.4766
    }, {
        "symbol": "GVIP",
        "label": "Goldman Sachs Hedge Industry VIP",
        "price": 55.71
    }, {
        "symbol": "GVP",
        "label": "GSE Systems Inc.",
        "price": 1.8134
    }, {
        "symbol": "GWGH",
        "label": "GWG Holdings Inc",
        "price": 9.5
    }, {
        "symbol": "GYB",
        "label": "CABCO Series 2004-101 Trust Goldman Sachs Capital I Floating Rate Callable Certi" +
                "ficates",
        "price": 25.0
    }, {
        "symbol": "GYC",
        "label": "Corporate Asset Backed Corp CABCOoration CABCO Series 2004-102 Trust (SBC Commun" +
                "ications Inc.) Collared Floating Rate Cllable Certificates",
        "price": 24.1465
    }, {
        "symbol": "GYLD",
        "label": "Arrow Dow Jones Global Yield ETF",
        "price": 16.06
    }, {
        "symbol": "GYRO",
        "label": "Gyrodyne  LLC",
        "price": 19.0
    }, {
        "symbol": "GZT",
        "label": "Gazit-Globe Ltd.",
        "price": 7.8
    }, {
        "symbol": "HACV",
        "label": "iShares Edge MSCI Min Vol Global Currency Hedged",
        "price": 29.27
    }, {
        "symbol": "HACW",
        "label": "iShares Currency Hedged MSCI ACWI",
        "price": 29.43
    }, {
        "symbol": "HAHA",
        "label": "CSOP China CSI 300 A-H Dynamic",
        "price": 33.05
    }, {
        "symbol": "HAO",
        "label": "Guggenheim China Small Cap Index",
        "price": 24.06
    }, {
        "symbol": "HAP",
        "label": "VanEck Vectors Natural Resources",
        "price": 34.41
    }, {
        "symbol": "HAUD",
        "label": "iShares Currency Hedged MSCI Australia",
        "price": 23.0
    }, {
        "symbol": "HBANN",
        "label": "Huntington Bancshares Incorporated Depositary Shares each representing a 1/40th " +
                "interest in a share of 5.875% Series C Non-Cumulative Perpetual Preferred Stock",
        "price": 26.37
    }, {
        "symbol": "HBCP",
        "label": "Home Bancorp Inc.",
        "price": 38.35
    }, {
        "symbol": "HBK",
        "label": "Hamilton Bancorp Inc.",
        "price": 15.39
    }, {
        "symbol": "HCAC",
        "label": "Hennessy Capital Acquisition Corp. III",
        "price": 10.23
    }, {
        "symbol": "HCAP",
        "label": "Harvest Capital Credit Corporation",
        "price": 8.97
    }, {
        "symbol": "HCAPZ",
        "label": "Harvest Capital Credit Corporation 6.125% Notes due 2022",
        "price": 25.72
    }, {
        "symbol": "HCOR",
        "label": "Hartford Corporate Bond",
        "price": 49.8767
    }, {
        "symbol": "HCRF",
        "label": "iShares Edge MSCI Multifactor Healthcare",
        "price": 31.82
    }, {
        "symbol": "HDAW",
        "label": "Xtrackers MSCI All World ex US High Dividend Yield Equity",
        "price": 24.38
    }, {
        "symbol": "HDEF",
        "label": "Xtrackers MSCI EAFE High Dividend Yield Equity",
        "price": 22.49
    }, {
        "symbol": "HDLV",
        "label": "ETRACS Montly Pay 2xLeveraged US High Dividend Low Volatility ETN due September " +
                "30 2044",
        "price": 27.48
    }, {
        "symbol": "HEBT",
        "label": "Hebron Technology Co. Ltd.",
        "price": 7.86
    }, {
        "symbol": "HECO",
        "label": "EcoLogical Strategy",
        "price": 40.51
    }, {
        "symbol": "HEEM",
        "label": "iShares Currency Hedged MSCI Emerging Markets",
        "price": 24.41
    }, {
        "symbol": "HEFV",
        "label": "iShares Edge MSCI Min Vol EAFE Currency Hedged",
        "price": 27.83
    }, {
        "symbol": "HEMV",
        "label": "iShares Edge MSCI Min Vol EM Currency Hedged",
        "price": 26.93
    }, {
        "symbol": "HEUS",
        "label": "iShares Currency Hedged MSCI Europe Small-Cap",
        "price": 29.94
    }, {
        "symbol": "HEUV",
        "label": "iShares Edge MSCI Min Vol Europe Currency Hedged",
        "price": 24.61
    }, {
        "symbol": "HEVY",
        "label": "iPath Pure Beta Industrial Metals ETN",
        "price": 33.04
    }, {
        "symbol": "HEWC",
        "label": "iShares Currency Hedged MSCI Canada",
        "price": 26.2
    }, {
        "symbol": "HEWI",
        "label": "iShares Currency Hedged MSCI Italy",
        "price": 17.09
    }, {
        "symbol": "HEWL",
        "label": "iShares Currency Hedged MSCI Switzerland",
        "price": 28.49
    }, {
        "symbol": "HEWU",
        "label": "iShares Currency Hedged MSCI United Kingdom",
        "price": 23.63
    }, {
        "symbol": "HEWW",
        "label": "iShares Currency Hedged MSCI Mexico",
        "price": 19.44
    }, {
        "symbol": "HEWY",
        "label": "iShares Currency Hedged MSCI South Korea",
        "price": 25.0
    }, {
        "symbol": "HFBL",
        "label": "Home Federal Bancorp Inc. of Louisiana",
        "price": 35.01
    }, {
        "symbol": "HFGIC",
        "label": "Hartford Funds NextShares Trust",
        "price": 99.99
    }, {
        "symbol": "HFXE",
        "label": "IQ 50 Percent Hedged FTSE Europe",
        "price": 21.2094
    }, {
        "symbol": "HFXI",
        "label": "IQ 50 Percent Hedged FTSE International",
        "price": 22.18
    }, {
        "symbol": "HFXJ",
        "label": "IQ 50 Percent Hedged FTSE Japan",
        "price": 21.9463
    }, {
        "symbol": "HGI",
        "label": "Guggenheim International Multi-Asset Income Index",
        "price": 15.0
    }, {
        "symbol": "HGSD",
        "label": "WisdomTree Global Hedged SmallCap Dividend Fund",
        "price": 22.73
    }, {
        "symbol": "HGSH",
        "label": "China HGS Real Estate Inc.",
        "price": 0.57
    }, {
        "symbol": "HGT",
        "label": "Hugoton Royalty Trust",
        "price": 0.41
    }, {
        "symbol": "HHS",
        "label": "Harte-Hanks Inc.",
        "price": 3.6263
    }, {
        "symbol": "HHYX",
        "label": "iShares Currency Hedged International High Yield Bond",
        "price": 27.75
    }, {
        "symbol": "HIE",
        "label": "Miller/Howard High Income Equity Fund of Beneficial Interest",
        "price": 11.54
    }, {
        "symbol": "HIHO",
        "label": "Highway Holdings Limited",
        "price": 1.86
    }, {
        "symbol": "HILO",
        "label": "Columbia EM Quality Dividend",
        "price": 13.1
    }, {
        "symbol": "HJPX",
        "label": "iShares Currency Hedged JPX-Nikkei 400",
        "price": 30.6096
    }, {
        "symbol": "HJV",
        "label": "MS Structured Asset Corp. SATURNS J.C. Penney Company Inc. Debenture Backed Seri" +
                "es 2007-1 7% Callable Class A Units",
        "price": 5.82
    }, {
        "symbol": "HMG",
        "label": "HMG/Courtland Properties Inc.",
        "price": 13.5782
    }, {
        "symbol": "HMNF",
        "label": "HMN Financial Inc.",
        "price": 20.75
    }, {
        "symbol": "HMOP",
        "label": "Hartford Municipal Opportunities",
        "price": 41.5
    }, {
        "symbol": "HMTA",
        "label": "HomeTown Bankshares Corporation",
        "price": 14.41
    }, {
        "symbol": "HNDL",
        "label": "Strategy Shares Nasdaq 7HANDL Index ETF",
        "price": 24.605
    }, {
        "symbol": "HNNA",
        "label": "Hennessy Advisors Inc.",
        "price": 10.14
    }, {
        "symbol": "HNRG",
        "label": "Hallador Energy Company",
        "price": 2.41
    }, {
        "symbol": "HOLD",
        "label": "AdvisorShares Sage Core Reserves",
        "price": 99.15
    }, {
        "symbol": "HOML",
        "label": "ETRACS Monthly Reset 2xLeveraged ISE Exclusively Homebuilders ETN due March 13 2" +
                "045",
        "price": 50.29
    }, {
        "symbol": "HONR",
        "label": "InsightShares Patriotic Employers",
        "price": 21.92
    }, {
        "symbol": "HOVNP",
        "label": "Hovnanian Enterprises Inc Depositary Share representing 1/1000th of 7.625% Serie" +
                "s A Preferred Stock",
        "price": 5.8
    }, {
        "symbol": "HPF",
        "label": "John Hancock Pfd Income Fund II Pfd Income Fund II",
        "price": 22.34
    }, {
        "symbol": "HPI",
        "label": "John Hancock Preferred Income Fund of Beneficial Interest",
        "price": 23.08
    }, {
        "symbol": "HPS",
        "label": "John Hancock Preferred Income Fund III Preferred Income Fund III",
        "price": 19.41
    }, {
        "symbol": "HQBD",
        "label": "Hartford Quality Bonds",
        "price": 48.9555
    }, {
        "symbol": "HQCL",
        "label": "Hanwha Q CELLS Co. Ltd.",
        "price": 9.84
    }, {
        "symbol": "HRZN",
        "label": "Horizon Technology Finance Corporation",
        "price": 13.23
    }, {
        "symbol": "HSCZ",
        "label": "iShares Currency Hedged MSCI EAFE Small-Cap",
        "price": 31.2461
    }, {
        "symbol": "HSPX",
        "label": "Horizons ETF Trust I S&P 500 Covered Call",
        "price": 51.9162
    }, {
        "symbol": "HTFA",
        "label": "Horizon Technology Finance Corporation 6.25% Notes due 2022",
        "price": 25.75
    }, {
        "symbol": "HTGX",
        "label": "Hercules Capital Inc. 6.25% Notes due 2024",
        "price": 25.07
    }, {
        "symbol": "HTRB",
        "label": "Hartford Total Return Bond",
        "price": 39.94
    }, {
        "symbol": "HTUS",
        "label": "Hull Tactical US",
        "price": 24.04
    }, {
        "symbol": "HTY",
        "label": "John Hancock Tax-Advantaged Global Shareholder Yield Fund of Beneficial Interest",
        "price": 7.17
    }, {
        "symbol": "HUNT",
        "label": "Hunter Maritime Acquisition Corp.",
        "price": 94.69
    }, {
        "symbol": "HUNTU",
        "label": "Hunter Maritime Acquisition Corp. Unit",
        "price": 10.19
    }, {
        "symbol": "HUNTW",
        "label": "Hunter Maritime Acquisition Corp. Warrant",
        "price": 0.48
    }, {
        "symbol": "HURC",
        "label": "Hurco Companies Inc.",
        "price": 33.04
    }, {
        "symbol": "HUSA",
        "label": "Houston American Energy Corporation",
        "price": 0.1658
    }, {
        "symbol": "HVBC",
        "label": "HV Bancorp Inc.",
        "price": 17.1259
    }, {
        "symbol": "HWBK",
        "label": "Hawthorn Bancshares Inc.",
        "price": 25.17
    }, {
        "symbol": "HWCC",
        "label": "Houston Wire & Cable Company",
        "price": 4.07
    }, {
        "symbol": "HYAC",
        "label": "Haymaker Acquisition Corp.",
        "price": 10.19
    }, {
        "symbol": "HYACU",
        "label": "Haymaker Acquisition Corp. Unit",
        "price": 10.65
    }, {
        "symbol": "HYACW",
        "label": "Haymaker Acquisition Corp. Warrant",
        "price": 1.51
    }, {
        "symbol": "HYB",
        "label": "New America High Income Fund Inc. (The)",
        "price": 9.17
    }, {
        "symbol": "HYDB",
        "label": "iShares Edge High Yield Defensive Bond",
        "price": 49.63
    }, {
        "symbol": "HYDD",
        "label": "Direxion Daily High Yield Bear 2X Shares",
        "price": 17.13
    }, {
        "symbol": "HYDW",
        "label": "Xtrackers Low Beta High Yield Bond",
        "price": 48.98
    }, {
        "symbol": "HYGH",
        "label": "iShares Interest Rate Hedged High Yield Bond",
        "price": 89.49
    }, {
        "symbol": "HYHG",
        "label": "ProShares High Yield Interest Rate Hedged",
        "price": 65.5664
    }, {
        "symbol": "HYIH",
        "label": "Xtrackers High Yield Corporate Bond - Interest Rate Hedged",
        "price": 22.32
    }, {
        "symbol": "HYLS",
        "label": "First Trust High Yield Long/Short ETF",
        "price": 49.03
    }, {
        "symbol": "HYLV",
        "label": "IndexIQ ETF Trust IQ S&P High Yield Low Volatility Bond",
        "price": 24.63
    }, {
        "symbol": "HYND",
        "label": "WisdomTree Negative Duration High Yield Bond Fund",
        "price": 18.81
    }, {
        "symbol": "HYUP",
        "label": "Xtrackers High Beta High Yield Bond",
        "price": 47.77
    }, {
        "symbol": "HYXE",
        "label": "iShares iBoxx $ High Yield ex Oil & Gas Corporate Bond ETF",
        "price": 52.5389
    }, {
        "symbol": "HYXU",
        "label": "iShares International High Yield Bond",
        "price": 49.44
    }, {
        "symbol": "IAM",
        "label": "I-AM Capital Acquisition Company",
        "price": 9.77
    }, {
        "symbol": "IAMXR",
        "label": "I-AM Capital Acquisition Company Right",
        "price": 0.46
    }, {
        "symbol": "IAMXW",
        "label": "I-AM Capital Acquisition Company Warrant",
        "price": 0.25
    }, {
        "symbol": "IBA",
        "label": "Industrias Bachoco S.A.B. de C.V.",
        "price": 51.59
    }, {
        "symbol": "IBCD",
        "label": "iShares iBonds Mar 2020 Term Corporate ex-Financials Term",
        "price": 24.58
    }, {
        "symbol": "IBCE",
        "label": "iShares iBonds Mar 2023 Term Corporate ex-Financials",
        "price": 24.09
    }, {
        "symbol": "IBDB",
        "label": "iShares iBonds Mar 2018 Term Corporate",
        "price": 25.58
    }, {
        "symbol": "IBDC",
        "label": "iShares iBonds Mar 2020 Term Corporate",
        "price": 26.06
    }, {
        "symbol": "IBDH",
        "label": "iShares iBonds Dec 2018 Term Corporate",
        "price": 25.18
    }, {
        "symbol": "IBDK",
        "label": "iShares iBonds Dec 2019 Term Corporate",
        "price": 24.83
    }, {
        "symbol": "IBDN",
        "label": "iShares iBonds Dec 2022 Term Corporate",
        "price": 25.04
    }, {
        "symbol": "IBDO",
        "label": "iShares iBonds Dec 2023 Term Corporate",
        "price": 25.18
    }, {
        "symbol": "IBDP",
        "label": "iShares iBonds Dec 2024 Term Corporate",
        "price": 25.14
    }, {
        "symbol": "IBDR",
        "label": "iShares iBonds Dec 2026 Term Corporate",
        "price": 24.6
    }, {
        "symbol": "IBDS",
        "label": "iShares iBonds Dec 2027 Term Corporate",
        "price": 24.94
    }, {
        "symbol": "IBKCO",
        "label": "IBERIABANK Corporation Depositary Shares Representing Series C Fixed to Floating",
        "price": 27.88
    }, {
        "symbol": "IBMK",
        "label": "iShares iBonds Dec 2022 Term Muni Bond",
        "price": 25.99
    }, {
        "symbol": "IBML",
        "label": "iShares iBonds Dec 2023 Term Muni Bond",
        "price": 25.59
    }, {
        "symbol": "ICB",
        "label": "MS Income Securities Inc.",
        "price": 18.56
    }, {
        "symbol": "ICCC",
        "label": "ImmuCell Corporation",
        "price": 5.05
    }, {
        "symbol": "ICCH",
        "label": "ICC Holdings Inc.",
        "price": 13.5838
    }, {
        "symbol": "ICI",
        "label": "iPath Optimized Currency Carry ETN",
        "price": 37.01
    }, {
        "symbol": "ICOW",
        "label": "Pacer Developed Markets International Cash Cows 100",
        "price": 25.41
    }, {
        "symbol": "ICSH",
        "label": "iShares Ultra Short-Term Bond",
        "price": 50.28
    }, {
        "symbol": "ICVT",
        "label": "iShares Convertible Bond",
        "price": 65.08
    }, {
        "symbol": "IDEV",
        "label": "iShares Core MSCI International Developed Markets",
        "price": 53.81
    }, {
        "symbol": "IDHD",
        "label": "PowerShares S&P International Developed High Dividend Low Volatility Portfolio",
        "price": 27.49
    }, {
        "symbol": "IDIV",
        "label": "U.S. Equity Cumulative Dividends Fund Series 2027 Shares",
        "price": 12.15
    }, {
        "symbol": "IDLB",
        "label": "PowerShares FTSE International Low Beta Equal Weight Portfolio",
        "price": 28.835
    }, {
        "symbol": "IDMO",
        "label": "PowerShares S&P International Developed Momentum Portfolio",
        "price": 22.04
    }, {
        "symbol": "IDSA",
        "label": "Industrial Services of America Inc.",
        "price": 1.125
    }, {
        "symbol": "IDU",
        "label": "iShares U.S. Utilities",
        "price": 167.14
    }, {
        "symbol": "IDXG",
        "label": "Interpace Diagnostics Group Inc.",
        "price": 8.19
    }, {
        "symbol": "IEC",
        "label": "IEC Electronics Corp.",
        "price": 9.3
    }, {
        "symbol": "IEO",
        "label": "iShares U.S. Oil & Gas Exploration & Production",
        "price": 54.67
    }, {
        "symbol": "IFEU",
        "label": "iShares FTSE EPRA/NAREIT Europe Index Fund",
        "price": 41.585
    }, {
        "symbol": "IFIX",
        "label": "Xtrackers Barclays International Corporate Bond Hedged",
        "price": 49.9
    }, {
        "symbol": "IFV",
        "label": "First Trust Dorsey Wright International Focus 5 ETF",
        "price": 21.9
    }, {
        "symbol": "IGC",
        "label": "India Globalization Capital Inc.",
        "price": 0.7
    }, {
        "symbol": "IGEB",
        "label": "iShares Edge Investment Grade Enhanced Bond",
        "price": 49.96
    }, {
        "symbol": "IGEM",
        "label": "VanEck Vectors ETF Trust Market Vectors EM Investment Grade + BB Rated USD Sover" +
                "eign Bond",
        "price": 23.6999
    }, {
        "symbol": "IGIH",
        "label": "Xtrackers Investment Grade Bond - Interest Rate Hedged",
        "price": 23.21
    }, {
        "symbol": "IGLD",
        "label": "Internet Gold Golden Lines Ltd.",
        "price": 12.0
    }, {
        "symbol": "IGM",
        "label": "iShares North American Tech",
        "price": 256.54
    }, {
        "symbol": "IGRO",
        "label": "iShares International Dividend Growth",
        "price": 53.94
    }, {
        "symbol": "IGVT",
        "label": "Xtrackers Barclays International Treasury Bond Hedged",
        "price": 49.09
    }, {
        "symbol": "IHC",
        "label": "Independence Holding Company",
        "price": 41.71
    }, {
        "symbol": "IHE",
        "label": "iShares U.S. Pharmaceutical",
        "price": 145.59
    }, {
        "symbol": "IHF",
        "label": "iShares U.S. Health Care Providers",
        "price": 167.46
    }, {
        "symbol": "IHIT",
        "label": "Invesco High Income 2023 Target Term Fund of Beneficial Interest",
        "price": 10.57
    }, {
        "symbol": "IHT",
        "label": "InnSuites Hospitality Trust Shares of Beneficial Interest",
        "price": 1.54
    }, {
        "symbol": "IHTA",
        "label": "Invesco High Income 2024 Target Term Fund of Beneficial Interest No par value pe" +
                "r share",
        "price": 10.65
    }, {
        "symbol": "IHY",
        "label": "VanEck Vectors International High Yield Bond",
        "price": 24.22
    }, {
        "symbol": "IID",
        "label": "Voya International High Dividend Equity Income Fund of Beneficial Interest",
        "price": 5.44
    }, {
        "symbol": "IIJI",
        "label": "Internet Initiative Japan Inc.",
        "price": 9.93
    }, {
        "symbol": "IKNX",
        "label": "Ikonics Corporation",
        "price": 7.2
    }, {
        "symbol": "IMI",
        "label": "Intermolecular Inc.",
        "price": 1.18
    }, {
        "symbol": "IMLP",
        "label": "iPath S&P MLP ETN",
        "price": 17.29
    }, {
        "symbol": "IMMP",
        "label": "Immutep Limited",
        "price": 2.15
    }, {
        "symbol": "IMMY",
        "label": "Imprimis Pharmaceuticals Inc.",
        "price": 5.75
    }, {
        "symbol": "IMNP",
        "label": "Immune Pharmaceuticals Inc.",
        "price": 0.16
    }, {
        "symbol": "IMOM",
        "label": "Alpha Architect International Quantitative Momentum",
        "price": 25.12
    }, {
        "symbol": "IMRN",
        "label": "Immuron Limited",
        "price": 3.51
    }, {
        "symbol": "IMRNW",
        "label": "Immuron Limited Warrants",
        "price": 0.95
    }, {
        "symbol": "IMTB",
        "label": "iShares Core 5-10 Year USD Bond",
        "price": 49.53
    }, {
        "symbol": "IMTE",
        "label": "Integrated Media Technology Limited",
        "price": 9.195
    }, {
        "symbol": "INBKL",
        "label": "First Internet Bancorp 6.0% Fixed-to-Floating Rate Subordinated Notes due 2026",
        "price": 25.8
    }, {
        "symbol": "INDF",
        "label": "iShares Edge MSCI Multifactor Industrials",
        "price": 31.1001
    }, {
        "symbol": "INDU",
        "label": "Industrea Acquisition Corp.",
        "price": 9.35
    }, {
        "symbol": "INDUW",
        "label": "Industrea Acquisition Corp. Warrant",
        "price": 0.68
    }, {
        "symbol": "INFR",
        "label": "Legg Mason Global Infrastructure ETF",
        "price": 32.89
    }, {
        "symbol": "INKM",
        "label": "SPDR SSgA Income Allocation",
        "price": 33.05
    }, {
        "symbol": "INOD",
        "label": "Innodata Inc.",
        "price": 1.1633
    }, {
        "symbol": "INP",
        "label": "Barclays Bank Plc iPath ETNs linked to the MSCI India Total Return Index",
        "price": 83.12
    }, {
        "symbol": "INR",
        "label": "Market Vectors Indian Rupee/USD ETN",
        "price": 44.47
    }, {
        "symbol": "INS",
        "label": "Intelligent Systems Corporation",
        "price": 39.91
    }, {
        "symbol": "INSE",
        "label": "Inspired Entertainment Inc.",
        "price": 6.7
    }, {
        "symbol": "INSI",
        "label": "Insight Select Income Fund",
        "price": 20.07
    }, {
        "symbol": "INTF",
        "label": "iShares Edge MSCI Multifactor Intl",
        "price": 27.44
    }, {
        "symbol": "INTG",
        "label": "The Intergroup Corporation",
        "price": 35.99
    }, {
        "symbol": "INVE",
        "label": "Identiv Inc.",
        "price": 6.31
    }, {
        "symbol": "INXX",
        "label": "Columbia India Infrastructure",
        "price": 11.39
    }, {
        "symbol": "IPAS",
        "label": "iPass Inc.",
        "price": 3.21
    }, {
        "symbol": "IPB",
        "label": "Merrill Lynch & Co. Inc. 6.0518% Index Plus Trust Certificates Series 2003-1",
        "price": 28.51
    }, {
        "symbol": "IPIC",
        "label": "iPic Entertainment Inc.",
        "price": 3.53
    }, {
        "symbol": "IPO",
        "label": "Renaissance IPO",
        "price": 29.59
    }, {
        "symbol": "IPOS",
        "label": "Renaissance Capital Greenwich Fund",
        "price": 21.5
    }, {
        "symbol": "IPWR",
        "label": "Ideal Power Inc.",
        "price": 2.59
    }, {
        "symbol": "IQDE",
        "label": "FlexShares International Quality Dividend Defensive Index Fund",
        "price": 21.67
    }, {
        "symbol": "IQDG",
        "label": "WisdomTree International Quality Dividend Growth Fund",
        "price": 28.11
    }, {
        "symbol": "IQDY",
        "label": "FlexShares International Quality Dividend Dynamic Index Fund",
        "price": 27.07
    }, {
        "symbol": "IQLT",
        "label": "iShares Edge MSCI Intl Quality Factor",
        "price": 32.88
    }, {
        "symbol": "IRDMB",
        "label": "Iridium Communications Inc 6.75% Series B Cumulative Perpetual Convertible Prefe" +
                "rred Stock",
        "price": 902.91
    }, {
        "symbol": "IROQ",
        "label": "IF Bancorp Inc.",
        "price": 23.0
    }, {
        "symbol": "ISCF",
        "label": "iShares Edge MSCI Intl Size Factor",
        "price": 31.8616
    }, {
        "symbol": "ISDR",
        "label": "Issuer Direct Corporation",
        "price": 11.6
    }, {
        "symbol": "ISHG",
        "label": "iShares 1-3 Year International Treasury Bond ETF",
        "price": 78.44
    }, {
        "symbol": "ISIG",
        "label": "Insignia Systems Inc.",
        "price": 0.7999
    }, {
        "symbol": "ISL",
        "label": "Aberdeen Israel Fund Inc. (The)",
        "price": 18.74
    }, {
        "symbol": "ISMD",
        "label": "Inspire Small/Mid Cap Impact",
        "price": 26.9
    }, {
        "symbol": "ISR",
        "label": "IsoRay Inc.",
        "price": 0.88
    }, {
        "symbol": "ISRA",
        "label": "VanEck Vectors Israel",
        "price": 32.23
    }, {
        "symbol": "ISRL",
        "label": "Isramco Inc.",
        "price": 119.0
    }, {
        "symbol": "ISSC",
        "label": "Innovative Solutions and Support Inc.",
        "price": 5.68
    }, {
        "symbol": "ISZE",
        "label": "iShares Edge MSCI Intl Size Factor",
        "price": 27.09
    }, {
        "symbol": "ITCB",
        "label": "Itau CorpBanca American Depositary Shares (each representing 1500 shares of no p" +
                "ar value)",
        "price": 7.8
    }, {
        "symbol": "ITE",
        "label": "SPDR Bloomberg Barclays Intermediate Term Treasury",
        "price": 61.37
    }, {
        "symbol": "ITEQ",
        "label": "BlueStar Israel Technology",
        "price": 46.7491
    }, {
        "symbol": "IUSB",
        "label": "iShares Core Total USD Bond Market ETF",
        "price": 52.47
    }, {
        "symbol": "IVAL",
        "label": "Alpha Architect International Quantitative Value",
        "price": 29.705
    }, {
        "symbol": "IVENC",
        "label": "Ivy NextShares",
        "price": 99.98
    }, {
        "symbol": "IVFGC",
        "label": "Ivy NextShares",
        "price": 100.02
    }, {
        "symbol": "IVFVC",
        "label": "Ivy NextShares",
        "price": 99.98
    }, {
        "symbol": "IVLU",
        "label": "iShares Edge MSCI Intl Value Factor",
        "price": 24.43
    }, {
        "symbol": "IVOG",
        "label": "Vanguard S&P Mid-Cap 400 Growth",
        "price": 132.07
    }, {
        "symbol": "IVOO",
        "label": "Vanguard S&P Mid-Cap 400",
        "price": 141.19
    }, {
        "symbol": "IVOP",
        "label": "iPath Inverse S&P 500 VIX Short Term Futures",
        "price": 38.0
    }, {
        "symbol": "IVOV",
        "label": "Vanguard S&P Mid-Cap 400 Value",
        "price": 131.53
    }, {
        "symbol": "IXJ",
        "label": "iShares Global Healthcare",
        "price": 59.73
    }, {
        "symbol": "IXP",
        "label": "iShares Global Telecom",
        "price": 55.63
    }, {
        "symbol": "IYC",
        "label": "iShares U.S. Consumer Services",
        "price": 233.105
    }, {
        "symbol": "IYG",
        "label": "iShares U.S. Financial Services",
        "price": 129.36
    }, {
        "symbol": "IYH",
        "label": "iShares U.S. Healthcare",
        "price": 188.47
    }, {
        "symbol": "IYLD",
        "label": "iShares Morningstar Multi-Asset Income",
        "price": 25.8736
    }, {
        "symbol": "IYY",
        "label": "iShares Dow Jones U.S.",
        "price": 164.66
    }, {
        "symbol": "IZEA",
        "label": "IZEA Inc.",
        "price": 0.2703
    }, {
        "symbol": "JBK",
        "label": "Lehman ABS 3.50 3.50% Adjustable Corp Backed Tr Certs GS Cap I",
        "price": 30.51
    }, {
        "symbol": "JBN",
        "label": "Select Asset Inc. on behalf of Corporate Backed Callable Trust Certificates J.C." +
                " Penney Debenture Backed Series 2007-1 Trust",
        "price": 5.7419
    }, {
        "symbol": "JBR",
        "label": "Select Asset Inc. Corporate Backed Callable Trust Certificates J.C. Penney Deben" +
                "ture-Backed Series 2006-1 Class A-1",
        "price": 5.89
    }, {
        "symbol": "JBRI",
        "label": "James Biblically Responsible Investment",
        "price": 24.23
    }, {
        "symbol": "JCS",
        "label": "Communications Systems Inc.",
        "price": 6.5
    }, {
        "symbol": "JCTCF",
        "label": "Jewett-Cameron Trading Company",
        "price": 7.72
    }, {
        "symbol": "JDIV",
        "label": "JPMorgan U.S. Dividend",
        "price": 26.19
    }, {
        "symbol": "JEM",
        "label": "iPath GEMS Index ETN",
        "price": 29.3227
    }, {
        "symbol": "JEMD",
        "label": "Nuveen Emerging Markets Debt 2022 Target Term Fund of Beneficial Interest $0.01 " +
                "par value per share",
        "price": 9.2285
    }, {
        "symbol": "JEQ",
        "label": "Aberdeen Japan Equity Fund Inc.",
        "price": 7.9
    }, {
        "symbol": "JHB",
        "label": "Nuveen High Income November 2021 Target Term Fund",
        "price": 10.01
    }, {
        "symbol": "JHD",
        "label": "Nuveen High Income December 2019 Target Term Fund of Beneficial Interest",
        "price": 9.9
    }, {
        "symbol": "JHDG",
        "label": "WisdomTree Japan Hedged Quality Dividend Growth Fund",
        "price": 25.71
    }, {
        "symbol": "JHMA",
        "label": "John Hancock Multifactor Materials",
        "price": 33.75
    }, {
        "symbol": "JHMC",
        "label": "John Hancock Multifactor Consumer Discretionary",
        "price": 31.93
    }, {
        "symbol": "JHMD",
        "label": "John Hancock Exchange-Traded Fund Trust Multifactor Developed International",
        "price": 27.82
    }, {
        "symbol": "JHME",
        "label": "John Hancock Multifactor Energy",
        "price": 23.4795
    }, {
        "symbol": "JHMF",
        "label": "John Hancock Multifactor Financials",
        "price": 42.0161
    }, {
        "symbol": "JHMH",
        "label": "John Hancock Multifactor Healthcare",
        "price": 33.2
    }, {
        "symbol": "JHMI",
        "label": "John Hancock Multifactor Industrials",
        "price": 40.9067
    }, {
        "symbol": "JHML",
        "label": "John Hancock Multifactor Large Cap",
        "price": 35.49
    }, {
        "symbol": "JHMS",
        "label": "John Hancock Multifactor Consumer Staples",
        "price": 26.46
    }, {
        "symbol": "JHMU",
        "label": "John Hancock Multifactor Utilities",
        "price": 31.15
    }, {
        "symbol": "JHS",
        "label": "John Hancock Income Securities Trust",
        "price": 14.76
    }, {
        "symbol": "JHSC",
        "label": "John Hancock Multifactor Small Cap",
        "price": 25.15
    }, {
        "symbol": "JHX",
        "label": "James Hardie Industries plc American Depositary Shares (Ireland)",
        "price": 21.19
    }, {
        "symbol": "JHY",
        "label": "Nuveen High Income 2020 Target Term Fund of Beneficial Interest",
        "price": 9.78
    }, {
        "symbol": "JJA",
        "label": "iPath Bloomberg Agriculture Total Return Sub-Index ETN",
        "price": 45.2
    }, {
        "symbol": "JJAB",
        "label": "iPathA Series B Bloomberg Agriculture Subindex Total Return ETN",
        "price": 45.87
    }, {
        "symbol": "JJCB",
        "label": "iPathA Series B Bloomberg Copper Subindex Total Return ETN",
        "price": 43.87
    }, {
        "symbol": "JJE",
        "label": "iPath Bloomberg Energy Total Return Sub-Index ETN",
        "price": 6.39
    }, {
        "symbol": "JJEB",
        "label": "iPathA Series B Bloomberg Energy Subindex Total Return ETN",
        "price": 45.05
    }, {
        "symbol": "JJGB",
        "label": "iPathA Series B Bloomberg Grains Subindex Total Return ETN",
        "price": 52.0237
    }, {
        "symbol": "JJM",
        "label": "iPath Bloomberg Industrial Metals Total Return Sub-Index ETN",
        "price": 28.18
    }, {
        "symbol": "JJMB",
        "label": "iPathA Series B Bloomberg Industrial Metals Subindex Total Return ETN",
        "price": 49.58
    }, {
        "symbol": "JJP",
        "label": "iPath Bloomberg Precious Metals Subindex Total Return SM Index ETN",
        "price": 59.75
    }, {
        "symbol": "JJPB",
        "label": "iPathA Series B Bloomberg Precious Metals Subindex Total Return ETN",
        "price": 48.6301
    }, {
        "symbol": "JJS",
        "label": "iPath Bloomberg Softs Subindex Total Return SM Index ETN",
        "price": 43.36
    }, {
        "symbol": "JJSB",
        "label": "iPathA Series B Bloomberg Softs Subindex Total Return ETN",
        "price": 47.5
    }, {
        "symbol": "JJT",
        "label": "iPath Bloomberg Tin Subindex Total Return SM ETN",
        "price": 48.24
    }, {
        "symbol": "JJTB",
        "label": "iPathA Series B Bloomberg Tin Subindex Total Return ETN",
        "price": 52.92
    }, {
        "symbol": "JJUB",
        "label": "iPathA Series B Bloomberg Aluminum Subindex Total Return ETN",
        "price": 47.35
    }, {
        "symbol": "JKF",
        "label": "iShares Morningstar Large-Cap Value",
        "price": 104.23
    }, {
        "symbol": "JKG",
        "label": "iShares Morningstar Mid-Cap",
        "price": 215.042
    }, {
        "symbol": "JKH",
        "label": "iShares Morningstar Mid-Cap Growth",
        "price": 275.476
    }, {
        "symbol": "JKI",
        "label": "iShares Morningstar Mid-Cap ETF",
        "price": 170.51
    }, {
        "symbol": "JKJ",
        "label": "iShares Morningstar Small-Cap",
        "price": 192.001
    }, {
        "symbol": "JKK",
        "label": "iShares Morningstar Small-Cap Growth",
        "price": 193.33
    }, {
        "symbol": "JKL",
        "label": "iShares Morningstar Small-Cap Value ETFnd",
        "price": 133.98
    }, {
        "symbol": "JMIN",
        "label": "JPMorgan U.S. Minimum Volatility",
        "price": 28.52
    }, {
        "symbol": "JMM",
        "label": "Nuveen Multi-Market Income Fund (MA)",
        "price": 7.4
    }, {
        "symbol": "JMOM",
        "label": "JPMorgan U.S. Momentum Factor",
        "price": 28.05
    }, {
        "symbol": "JMP",
        "label": "JMP Group LLC",
        "price": 3.06
    }, {
        "symbol": "JMPB",
        "label": "JMP Group Inc 8.00% Senior Notes due 2023",
        "price": 25.48
    }, {
        "symbol": "JMPD",
        "label": "JMP Group LLC 7.25% Senior Notes due 2027",
        "price": 25.82
    }, {
        "symbol": "JMT",
        "label": "Nuveen Mortgage Opportunity Term Fund 2 of Beneficial Interest",
        "price": 22.05
    }, {
        "symbol": "JMU",
        "label": "JMU Limited",
        "price": 0.97
    }, {
        "symbol": "JOF",
        "label": "Japan Smaller Capitalization Fund Inc",
        "price": 8.79
    }, {
        "symbol": "JPED",
        "label": "JPMorgan Event Driven",
        "price": 25.33
    }, {
        "symbol": "JPEH",
        "label": "JPMorgan Diversified Return Europe Currency Hedged",
        "price": 26.24
    }, {
        "symbol": "JPEU",
        "label": "JPMorgan Diversified Return Europe Equity",
        "price": 54.3
    }, {
        "symbol": "JPGB",
        "label": "JPMorgan Global Bond Opportunities",
        "price": 49.74
    }, {
        "symbol": "JPHF",
        "label": "J P Morgan Chase & Co",
        "price": 24.08
    }, {
        "symbol": "JPHY",
        "label": "JPMorgan Disciplined HY",
        "price": 50.12
    }, {
        "symbol": "JPI",
        "label": "Nuveen Preferred and Income Term Fund of Beneficial Interest",
        "price": 25.62
    }, {
        "symbol": "JPIH",
        "label": "JPMorgan Diversified Return International Currency Hedged",
        "price": 28.32
    }, {
        "symbol": "JPLS",
        "label": "JPMorgan Long/Short",
        "price": 22.36
    }, {
        "symbol": "JPMB",
        "label": "JPMorgan USD Emerging Markets Sovereign Bond",
        "price": 45.9
    }, {
        "symbol": "JPME",
        "label": "JPMorgan Diversified Return U.S. Mid Cap Equity",
        "price": 65.03
    }, {
        "symbol": "JPMF",
        "label": "JPMorgan Managed Futures Strategy",
        "price": 23.98
    }, {
        "symbol": "JPMV",
        "label": "iShares Edge MSCI Min Vol Japan",
        "price": 64.26
    }, {
        "symbol": "JPN",
        "label": "Xtrackers Japan JPX-Nikkei 400 Equity",
        "price": 25.94
    }, {
        "symbol": "JPSE",
        "label": "JPMorgan Diversified Return U.S. Small Cap Equity",
        "price": 29.64
    }, {
        "symbol": "JPST",
        "label": "JPMorgan Ultra-Short Income",
        "price": 50.38
    }, {
        "symbol": "JQUA",
        "label": "JPMorgan U.S. Quality Factor",
        "price": 26.37
    }, {
        "symbol": "JRJR",
        "label": "JRjr33 Inc.",
        "price": 0.13
    }, {
        "symbol": "JSD",
        "label": "Nuveen Short Duration Credit Opportunities Fund of Beneficial Interest",
        "price": 15.37
    }, {
        "symbol": "JSM",
        "label": "Navient Corporation",
        "price": 24.6
    }, {
        "symbol": "JSMD",
        "label": "Janus Henderson Small/Mid Cap Growth Alpha ETF",
        "price": 49.72
    }, {
        "symbol": "JSML",
        "label": "Janus Henderson Small Cap Growth Alpha ETF",
        "price": 48.205
    }, {
        "symbol": "JSYNR",
        "label": "Jensyn Acquistion Corp. Rights",
        "price": 0.39
    }, {
        "symbol": "JSYNU",
        "label": "Jensyn Acquistion Corp. Unit",
        "price": 12.93
    }, {
        "symbol": "JVA",
        "label": "Coffee Holding Co. Inc.",
        "price": 4.11
    }, {
        "symbol": "JVAL",
        "label": "JPMorgan U.S. Value Factor",
        "price": 26.73
    }, {
        "symbol": "JXSB",
        "label": "Jacksonville Bancorp Inc.",
        "price": 33.4
    }, {
        "symbol": "JYN",
        "label": "BARCLAYS BANK PLC iPath JPY/USD Exchange Rate ETN",
        "price": 52.0
    }, {
        "symbol": "KAAC",
        "label": "Kayne Anderson Acquisition Corp.",
        "price": 9.71
    }, {
        "symbol": "KAACU",
        "label": "Kayne Anderson Acquisition Corp. Unit",
        "price": 10.43
    }, {
        "symbol": "KAACW",
        "label": "Kayne Anderson Acquisition Corp. Warrant",
        "price": 1.15
    }, {
        "symbol": "KAP",
        "label": "KCAP Financial Inc. 7.375% Senior Notes due 2019",
        "price": 25.39
    }, {
        "symbol": "KARS",
        "label": "KraneShares Electric Vehicles and Future Mobility Index",
        "price": 20.78
    }, {
        "symbol": "KBA",
        "label": "KraneShares Bosera MSCI China A",
        "price": 28.87
    }, {
        "symbol": "KBLM",
        "label": "KBL Merger Corp. IV",
        "price": 10.55
    }, {
        "symbol": "KBLMR",
        "label": "KBL Merger Corp. IV Right",
        "price": 0.16
    }, {
        "symbol": "KBLMU",
        "label": "KBL Merger Corp. IV Unit",
        "price": 10.55
    }, {
        "symbol": "KBLMW",
        "label": "KBL Merger Corp. IV Warrant",
        "price": 0.07
    }, {
        "symbol": "KBSF",
        "label": "KBS Fashion Group Limited",
        "price": 2.34
    }, {
        "symbol": "KBWP",
        "label": "PowerShares KBW Property & Casualty Insurance Portfolio",
        "price": 72.95
    }, {
        "symbol": "KBWR",
        "label": "PowerShares KBW Regional Banking Portfolio",
        "price": 53.733
    }, {
        "symbol": "KCAPL",
        "label": "KCAP Financial Inc. 6.125% Notes due 2022",
        "price": 25.13
    }, {
        "symbol": "KCNY",
        "label": "KraneShares E Fund China Commercial Paper",
        "price": 34.41
    }, {
        "symbol": "KELYB",
        "label": "Kelly Services Inc. Class B Common Stock",
        "price": 21.9
    }, {
        "symbol": "KEMP",
        "label": "KraneShares FTSE Emerging Markets Plus",
        "price": 25.58
    }, {
        "symbol": "KFFB",
        "label": "Kentucky First Federal Bancorp",
        "price": 7.85
    }, {
        "symbol": "KFS",
        "label": "Kingsway Financial Services Inc.",
        "price": 1.83
    }, {
        "symbol": "KFYP",
        "label": "KraneShares Zacks New China",
        "price": 25.89
    }, {
        "symbol": "KGJI",
        "label": "Kingold Jewelry Inc.",
        "price": 1.74
    }, {
        "symbol": "KGRN",
        "label": "KraneShares MSCI China Environment Index",
        "price": 20.3
    }, {
        "symbol": "KIQ",
        "label": "Kelso Technologies Inc",
        "price": 0.7
    }, {
        "symbol": "KMDA",
        "label": "Kamada Ltd.",
        "price": 6.52
    }, {
        "symbol": "KMPA",
        "label": "Kemper Corporation 7.375% Subordinated Debentures due 2054",
        "price": 25.15
    }, {
        "symbol": "KNOW",
        "label": "Direxion All Cap Insider Sentiment Shares",
        "price": 36.91
    }, {
        "symbol": "KOLD",
        "label": "ProShares UltraShort Bloomberg Natural Gas",
        "price": 29.2
    }, {
        "symbol": "KONE",
        "label": "Kingtone Wirelessinfo Solution Holding Ltd",
        "price": 7.33
    }, {
        "symbol": "KOOL",
        "label": "Cesca Therapeutics Inc.",
        "price": 2.81
    }, {
        "symbol": "KOR",
        "label": "AdvisorShares KIM Korea Equity",
        "price": 27.27
    }, {
        "symbol": "KORP",
        "label": "American Century Diversified Corporate Bond",
        "price": 48.01
    }, {
        "symbol": "KOSS",
        "label": "Koss Corporation",
        "price": 1.55
    }, {
        "symbol": "KRP",
        "label": "Kimbell Royalty Partners Representing Limited Partner Interests",
        "price": 15.58
    }, {
        "symbol": "KST",
        "label": "Deutsche Strategic Income Trust Shares of Beneficial Interest",
        "price": 12.58
    }, {
        "symbol": "KTCC",
        "label": "Key Tronic Corporation",
        "price": 6.68
    }, {
        "symbol": "KTH",
        "label": "Structures Products Cp 8% CorTS Issued by Peco Energy Cap Tr II Preferred Stock",
        "price": 32.7
    }, {
        "symbol": "KTN",
        "label": "Structured Products Corp 8.205% CorTS 8.205% Corporate Backed Trust Securities (" +
                "CorTS)",
        "price": 31.6402
    }, {
        "symbol": "KTP",
        "label": "Corts 7.625 Pfd",
        "price": 6.29
    }, {
        "symbol": "KURE",
        "label": "KraneShares MSCI All China Health Care Index",
        "price": 19.73
    }, {
        "symbol": "KZIA",
        "label": "Kazia Therapeutics Limited",
        "price": 4.69
    }, {
        "symbol": "LACQ",
        "label": "Leisure Acquisition Corp.",
        "price": 10.42
    }, {
        "symbol": "LACQU",
        "label": "Leisure Acquisition Corp. Unit",
        "price": 10.8
    }, {
        "symbol": "LACQW",
        "label": "Leisure Acquisition Corp. Warrant",
        "price": 0.8
    }, {
        "symbol": "LAKE",
        "label": "Lakeland Industries Inc.",
        "price": 11.03
    }, {
        "symbol": "LALT",
        "label": "PowerShares Multi-Strategy Alternative Portfolio",
        "price": 21.95
    }, {
        "symbol": "LANDP",
        "label": "Gladstone Land Corporation 6.375% Series A Cumulative Term Preferred Stock",
        "price": 26.0
    }, {
        "symbol": "LAQ",
        "label": "Aberdeen Latin America Equity Fund Inc.",
        "price": 26.36
    }, {
        "symbol": "LARK",
        "label": "Landmark Bancorp Inc.",
        "price": 25.4
    }, {
        "symbol": "LAWS",
        "label": "Lawson Products Inc.",
        "price": 54.7
    }, {
        "symbol": "LAZY",
        "label": "Lazydays Holdings Inc.",
        "price": 4.41
    }, {
        "symbol": "LBDC",
        "label": "2xLeveraged Long ETRACS Wells Fargo Business Development Company Index Series B " +
                "due May 24 2041",
        "price": 13.59
    }, {
        "symbol": "LBIX",
        "label": "Leading Brands Inc",
        "price": 1.36
    }, {
        "symbol": "LBTYB",
        "label": "Liberty Global plc Class B Ordinary Shares",
        "price": 21.7
    }, {
        "symbol": "LCA",
        "label": "Landcadia Holdings Inc.",
        "price": 9.88
    }, {
        "symbol": "LCAHU",
        "label": "Landcadia Holdings Inc. Unit",
        "price": 10.2175
    }, {
        "symbol": "LCAHW",
        "label": "Landcadia Holdings Inc. Warrant",
        "price": 0.74
    }, {
        "symbol": "LD",
        "label": "iPath Bloomberg Lead Subindex Total Return SM Index ETN",
        "price": 51.81
    }, {
        "symbol": "LDF",
        "label": "Latin American Discovery Fund Inc. (The)",
        "price": 12.14
    }, {
        "symbol": "LDRI",
        "label": "PowerShares LadderRite 0-5 Year Corporate Bond Portfolio",
        "price": 25.365
    }, {
        "symbol": "LDUR",
        "label": "PIMCO Enhanced Low Duration Active Exchange-Traded Fund",
        "price": 100.03
    }, {
        "symbol": "LEAD",
        "label": "Realty Shares DIVCON Leaders Dividend",
        "price": 35.09
    }, {
        "symbol": "LEDD",
        "label": "iPath Pure Beta Lead ETN",
        "price": 39.51
    }, {
        "symbol": "LEDS",
        "label": "SemiLEDS Corporation",
        "price": 2.01
    }, {
        "symbol": "LEGR",
        "label": "First Trust Indxx Innovative Transaction & Process ETF",
        "price": 32.9406
    }, {
        "symbol": "LEU",
        "label": "Centrus Energy Corp. Class A",
        "price": 6.6
    }, {
        "symbol": "LEXEB",
        "label": "Liberty Expedia Holdings Inc. Series B Common Stock",
        "price": 49.4408
    }, {
        "symbol": "LFEQ",
        "label": "VanEck Vectors NDR CMG Long/Flat Allocation",
        "price": 26.2
    }, {
        "symbol": "LGC",
        "label": "Legacy Acquisition Corp. Class A par value $0.0001 per share",
        "price": 10.11
    }, {
        "symbol": "LGCYP",
        "label": "Legacy Reserves LP 8% Series A Fixed-to-Floating Rate Cumulative Redeemable Perp" +
                "etual Preferred Units",
        "price": 13.94
    }, {
        "symbol": "LGL",
        "label": "LGL Group Inc. (The)",
        "price": 14.484
    }, {
        "symbol": "LGLV",
        "label": "SPDR SSGA US Large Cap Low Volatility Index",
        "price": 117.55
    }, {
        "symbol": "LINC",
        "label": "Lincoln Educational Services Corporation",
        "price": 2.195
    }, {
        "symbol": "LINDW",
        "label": "Lindblad Expeditions Holdings Inc. Warrant",
        "price": 5.85
    }, {
        "symbol": "LINK",
        "label": "Interlink Electronics Inc.",
        "price": 1.95
    }, {
        "symbol": "LINU",
        "label": "LiNiu Technology Group",
        "price": 0.6
    }, {
        "symbol": "LIVE",
        "label": "Live Ventures Incorporated",
        "price": 7.55
    }, {
        "symbol": "LIVX",
        "label": "LiveXLive Media Inc.",
        "price": 1.65
    }, {
        "symbol": "LKOR",
        "label": "FlexShares Credit-Scored US Long Corporate Bond Index Fund",
        "price": 58.6504
    }, {
        "symbol": "LLIT",
        "label": "Lianluo Smart Limited",
        "price": 0.52
    }, {
        "symbol": "LLQD",
        "label": "iShares 10 Year Investment Grade Corporate Bond",
        "price": 48.99
    }, {
        "symbol": "LMHA",
        "label": "Legg Mason Inc. 6.375% Junior Subordinated Notes due 2056",
        "price": 27.11
    }, {
        "symbol": "LMLP",
        "label": "ETRACS Monthly Pay 2xLeveraged Wells Fargo MLP Ex-Energy ETN",
        "price": 15.41
    }, {
        "symbol": "LMRKO",
        "label": "Landmark Infrastructure Partners LP Preferred Units",
        "price": 25.91
    }, {
        "symbol": "LMRKP",
        "label": "Landmark Infrastructure Partners LP 8.00% Series A Cumulative Redeemable Perpetu" +
                "al Preferred Units",
        "price": 26.08
    }, {
        "symbol": "LND",
        "label": "Brasilagro Brazilian Agric Real Estate Co Sponsored ADR (Brazil)",
        "price": 4.69
    }, {
        "symbol": "LNGR",
        "label": "Global X Longevity Thematic ETF",
        "price": 24.105
    }, {
        "symbol": "LOAN",
        "label": "Manhattan Bridge Capital Inc",
        "price": 6.27
    }, {
        "symbol": "LOGO",
        "label": "Global X Iconic U.S. Brands",
        "price": 15.25
    }, {
        "symbol": "LONE",
        "label": "Lonestar Resources US Inc.",
        "price": 2.2
    }, {
        "symbol": "LOOP",
        "label": "Loop Industries Inc.",
        "price": 10.19
    }, {
        "symbol": "LOR",
        "label": "Lazard World Dividend & Income Fund Inc.",
        "price": 9.68
    }, {
        "symbol": "LOWC",
        "label": "SPDR MSCI ACWI Low Carbon Target",
        "price": 91.55
    }, {
        "symbol": "LQDH",
        "label": "iShares Interest Rate Hedged Corporate Bond",
        "price": 93.35
    }, {
        "symbol": "LRAD",
        "label": "LRAD Corporation",
        "price": 3.24
    }, {
        "symbol": "LRET",
        "label": "ETRACS Monthly Pay 2xLeveraged MSCI US REIT Index ETN due May 5 2045",
        "price": 26.51
    }, {
        "symbol": "LRGE",
        "label": "ClearBridge Large Cap Growth ESG ETF",
        "price": 39.75
    }, {
        "symbol": "LSBK",
        "label": "Lake Shore Bancorp Inc.",
        "price": 15.3027
    }, {
        "symbol": "LSST",
        "label": "Natixis ETF Trust Loomis Sayles Short Duration Income",
        "price": 25.03
    }, {
        "symbol": "LSTK",
        "label": "iPath Pure Beta Livestock ETN",
        "price": 43.37
    }, {
        "symbol": "LSVX",
        "label": "UBS AG VelocityShares VIX Variable Long/Short ETN linked to the S&P 500 VIX Futu" +
                "res Variable Long/Short Index Short Term due July 18 2046",
        "price": 16.25
    }, {
        "symbol": "LSXMB",
        "label": "Liberty Media Corporation Series B Liberty SiriusXM Common Stock",
        "price": 48.5973
    }, {
        "symbol": "LTL",
        "label": "ProShares Ultra Telecommunications",
        "price": 43.05
    }, {
        "symbol": "LTRPB",
        "label": "Liberty TripAdvisor Holdings Inc. Series B Common Stock",
        "price": 8.39
    }, {
        "symbol": "LTRX",
        "label": "Lantronix Inc.",
        "price": 3.76
    }, {
        "symbol": "LTSL",
        "label": "Ladenburg Thalmann Financial Services Inc 6.50% Senior Notes due 2027",
        "price": 21.7
    }, {
        "symbol": "LUB",
        "label": "Luby's Inc.",
        "price": 2.36
    }, {
        "symbol": "LVHB",
        "label": "Elkhorn Lunt Low Vol/High Beta Tactical",
        "price": 34.19
    }, {
        "symbol": "LVHD",
        "label": "Legg Mason Low Volatility High Dividend ETF",
        "price": 34.49
    }, {
        "symbol": "LVHE",
        "label": "Legg Mason Emerging Markets Low Volatility High Dividend",
        "price": 25.66
    }, {
        "symbol": "LVIN",
        "label": "Hartford Multifactor Low Volatility International Equity",
        "price": 28.15
    }, {
        "symbol": "LVL",
        "label": "Guggenheim Global Dividend Opportunities Index",
        "price": 11.37
    }, {
        "symbol": "LVUS",
        "label": "Hartford Multifactor Low Volatility US Equity",
        "price": 28.22
    }, {
        "symbol": "LXFR",
        "label": "Luxfer Holdings PLC",
        "price": 17.75
    }, {
        "symbol": "MAB",
        "label": "Eaton Vance Massachusetts Municipal Bond Fund of Beneficial Interest $.01 par va" +
                "lue",
        "price": 12.17
    }, {
        "symbol": "MACQ",
        "label": "M I Acquisitions Inc.",
        "price": 10.99
    }, {
        "symbol": "MACQU",
        "label": "M I Acquisitions Inc. Unit",
        "price": 12.08
    }, {
        "symbol": "MAGA",
        "label": "Point Bridge GOP Stock Tracker",
        "price": 27.42
    }, {
        "symbol": "MAMS",
        "label": "MAM Software Group Inc.",
        "price": 9.03
    }, {
        "symbol": "MANU",
        "label": "Manchester United Ltd. Class A",
        "price": 20.25
    }, {
        "symbol": "MARPS",
        "label": "Marine Petroleum Trust Units of Beneficial Interest",
        "price": 2.045
    }, {
        "symbol": "MATF",
        "label": "iShares Edge MSCI Multifactor Materials",
        "price": 32.98
    }, {
        "symbol": "MATH",
        "label": "Meidell Tactical Advantage",
        "price": 32.6617
    }, {
        "symbol": "MAYS",
        "label": "J. W. Mays Inc.",
        "price": 29.71
    }, {
        "symbol": "MBFIO",
        "label": "MB Financial Inc. Depositary Shares Each Representing a 1/40th Interest in a Sha" +
                "re of 6.000% Noncumulative Perpetual Preferred Stock Series C",
        "price": 24.63
    }, {
        "symbol": "MBG",
        "label": "SPDR Bloomberg Barclays Mortgage Backed Bond",
        "price": 26.23
    }, {
        "symbol": "MBSD",
        "label": "FlexShares Disciplined Duration MBS Index Fund",
        "price": 23.555
    }, {
        "symbol": "MBVX",
        "label": "MabVax Therapeutics Holdings Inc.",
        "price": 1.06
    }, {
        "symbol": "MCEF",
        "label": "First Trust Municipal CEF Income Opportunity ETF",
        "price": 19.795
    }, {
        "symbol": "MCEP",
        "label": "Mid-Con Energy Partners LP",
        "price": 0.2717
    }, {
        "symbol": "MCI",
        "label": "Barings Corporate Investors",
        "price": 16.3558
    }, {
        "symbol": "MCRO",
        "label": "IQ Hedge Macro Tracker",
        "price": 26.189
    }, {
        "symbol": "MCV",
        "label": "Medley Capital Corporation 6.125% Senior Notes due 2023",
        "price": 23.25
    }, {
        "symbol": "MCX",
        "label": "Medley Capital Corporation 6.50% Notes due 2021",
        "price": 24.9
    }, {
        "symbol": "MDLQ",
        "label": "Medley LLC 7.25% Notes due 2024",
        "price": 18.75
    }, {
        "symbol": "MDLX",
        "label": "Medley LLC 6.875% Senior Notes due 2026",
        "price": 18.5
    }, {
        "symbol": "MDLY",
        "label": "Medley Management Inc. Class A",
        "price": 3.04
    }, {
        "symbol": "MEAR",
        "label": "iShares Short Maturity Municipal Bond",
        "price": 50.08
    }, {
        "symbol": "MELR",
        "label": "Melrose Bancorp Inc.",
        "price": 18.55
    }, {
        "symbol": "MEN",
        "label": "Blackrock MuniEnhanced Fund Inc",
        "price": 11.45
    }, {
        "symbol": "MESO",
        "label": "Mesoblast Limited",
        "price": 9.45
    }, {
        "symbol": "MEXX",
        "label": "Direxion Daily MSCI Mexico Bull 3X Shares",
        "price": 10.57
    }, {
        "symbol": "MFCB",
        "label": "MFC Bancorp Ltd.",
        "price": 12.9
    }, {
        "symbol": "MFD",
        "label": "Macquarie First Trust Global",
        "price": 11.21
    }, {
        "symbol": "MFDX",
        "label": "PIMCO Equitiy Series RAFI Dynamic Multi-Factor International Equity",
        "price": 24.85
    }, {
        "symbol": "MFEM",
        "label": "PIMCO Equitiy Series RAFI Dynamic Multi-Factor Emerging Markets Equity",
        "price": 22.56
    }, {
        "symbol": "MFINL",
        "label": "Medallion Financial Corp. 9.000% Notes due 2021",
        "price": 26.08
    }, {
        "symbol": "MFLA",
        "label": "iPath Long Enhanced MSCI EAFE Index ETN",
        "price": 194.36
    }, {
        "symbol": "MFO",
        "label": "MFA Financial Inc. 8.00% Senior Notes due 2042",
        "price": 26.05
    }, {
        "symbol": "MFT",
        "label": "Blackrock MuniYield Investment Quality Fund of Beneficial Interest",
        "price": 13.76
    }, {
        "symbol": "MFUS",
        "label": "PIMCO Equitiy Series RAFI Dynamic Multi-Factor U.S. Equity",
        "price": 28.04
    }, {
        "symbol": "MFV",
        "label": "MFS Special Value Trust",
        "price": 6.7501
    }, {
        "symbol": "MGC",
        "label": "Vanguard Mega Cap",
        "price": 115.47
    }, {
        "symbol": "MGF",
        "label": "MFS Government Markets Income Trust",
        "price": 4.52
    }, {
        "symbol": "MGYR",
        "label": "Magyar Bancorp Inc.",
        "price": 12.43
    }, {
        "symbol": "MHD",
        "label": "Blackrock MuniHoldings Fund Inc.",
        "price": 17.14
    }, {
        "symbol": "MHE",
        "label": "BlackRock Massachusetts Tax-Exempt Trust",
        "price": 13.4556
    }, {
        "symbol": "MHF",
        "label": "Western Asset Municipal High Income Fund Inc.",
        "price": 7.7
    }, {
        "symbol": "MHLA",
        "label": "Maiden Holdings Ltd. 6.625% Notes due 2046",
        "price": 19.46
    }, {
        "symbol": "MHN",
        "label": "Blackrock MuniHoldings New York Quality Fund Inc.",
        "price": 13.81
    }, {
        "symbol": "MHNC",
        "label": "Maiden Holdings North America Ltd. 7.75% Notes due 2043",
        "price": 22.5
    }, {
        "symbol": "MICR",
        "label": "Micron Solutions Inc.",
        "price": 2.505
    }, {
        "symbol": "MICTW",
        "label": "Micronet Enertec Technologies Inc. Warrant",
        "price": 0.031
    }, {
        "symbol": "MIDU",
        "label": "Direxion Mid Cap Bull 3X Shares",
        "price": 37.44
    }, {
        "symbol": "MIDZ",
        "label": "Direxion Mid Cap Bear 3X Shares",
        "price": 10.9
    }, {
        "symbol": "MIII",
        "label": "M III Acquisition Corp.",
        "price": 8.37
    }, {
        "symbol": "MIIIU",
        "label": "M III Acquisition Corp. Unit",
        "price": 10.5753
    }, {
        "symbol": "MIIIW",
        "label": "M III Acquisition Corp. Warrant",
        "price": 0.8799
    }, {
        "symbol": "MINC",
        "label": "AdvisorShares Newfleet Multi-sector Income",
        "price": 48.6001
    }, {
        "symbol": "MINDP",
        "label": "Mitcham Industries Inc. Series A 9.00% Series A Cumulative Preferred Stock",
        "price": 24.4
    }, {
        "symbol": "MIW",
        "label": "Eaton Vance Michigan Municipal Bond Fund of Beneficial Interest $.01 par value",
        "price": 12.4
    }, {
        "symbol": "MIY",
        "label": "Blackrock MuniYield Michigan Quality Fund Inc.",
        "price": 14.45
    }, {
        "symbol": "MJCO",
        "label": "Majesco",
        "price": 7.48
    }, {
        "symbol": "MLP",
        "label": "Maui Land & Pineapple Company Inc.",
        "price": 10.87
    }, {
        "symbol": "MLPB",
        "label": "ETRACS Alerian MLP Infrastructure Index ETN Series B due April 2 2040",
        "price": 21.135
    }, {
        "symbol": "MLPC",
        "label": "C-Tracks ETNs based on Performance of the Miller/Howard MLP Fundamental Index",
        "price": 13.86
    }, {
        "symbol": "MLPE",
        "label": "C-Tracks ETN on Miller/Howard Fundamental MLP Index Series B Due July 13 2026",
        "price": 22.48
    }, {
        "symbol": "MLPG",
        "label": "UBS AG Exchange Traded Access Securities (E-TRACS) Linked to the Alerian Natural" +
                " Gas MLP Index due July 9 2040",
        "price": 21.95
    }, {
        "symbol": "MLPO",
        "label": "Credit Suisse Group Exchange Traded Notes due December 4 2034 Linked to the S&P " +
                "MLP Index",
        "price": 14.0295
    }, {
        "symbol": "MLPQ",
        "label": "UBS AG ETRACS 2xMonthly Leveraged Alerian MLP Infrastructure Index ETN Series B " +
                "due February 12 2046",
        "price": 28.26
    }, {
        "symbol": "MLPS",
        "label": "UBS AG 1xMonthly Short Exchange Traded Access Securities (E-TRACS) Linked to the" +
                " Alerian MLP Infrastructure Total Return Index due October 1 2040",
        "price": 13.88
    }, {
        "symbol": "MLPY",
        "label": "Morgan Stanley Cushing MLP High Income Index ETN",
        "price": 6.24
    }, {
        "symbol": "MLPZ",
        "label": "UBS AG ETRACS ETRACS 2xMonthly Leveraged S&P MLP Index ETN Series B due February" +
                " 12 2046",
        "price": 33.95
    }, {
        "symbol": "MLQD",
        "label": "iShares 5-10 Year Investment Grade Corporate Bond",
        "price": 49.5
    }, {
        "symbol": "MLSS",
        "label": "Milestone Scientific Inc.",
        "price": 1.63
    }, {
        "symbol": "MLTI",
        "label": "Credit Suisse X-Links Multi-Asset High Income Exchange Traded Notes (ETNs) due S" +
                "eptember 28 2035",
        "price": 27.5362
    }, {
        "symbol": "MMD",
        "label": "MainStay MacKay DefinedTerm Municipal Opportunities Fund",
        "price": 21.73
    }, {
        "symbol": "MMDM",
        "label": "Modern Media Acquisition Corp.",
        "price": 10.32
    }, {
        "symbol": "MMDMR",
        "label": "Modern Media Acquisition Corp. Right",
        "price": 0.3
    }, {
        "symbol": "MMDMU",
        "label": "Modern Media Acquisition Corp. Unit",
        "price": 10.42
    }, {
        "symbol": "MMDMW",
        "label": "Modern Media Acquisition Corp. Warrant",
        "price": 0.39
    }, {
        "symbol": "MMIN",
        "label": "IQ MacKay Shields Municipal Insured ETF",
        "price": 25.9
    }, {
        "symbol": "MMIT",
        "label": "IQ MacKay Shields Municipal Intermediate",
        "price": 24.5816
    }, {
        "symbol": "MMTM",
        "label": "SPDR S&P 1500 Momentum Tilt",
        "price": 142.689
    }, {
        "symbol": "MMV",
        "label": "Eaton Vance Massachusetts Municipal Income Trust Shares of Beneficial Interest",
        "price": 12.64
    }, {
        "symbol": "MNE",
        "label": "Blackrock Muni New York Intermediate Duration Fund Inc",
        "price": 15.5
    }, {
        "symbol": "MNI",
        "label": "McClatchy Company (The)",
        "price": 1.19
    }, {
        "symbol": "MOC",
        "label": "Command Security Corporation",
        "price": 2.84
    }, {
        "symbol": "MOGLC",
        "label": "Gabelli NextShares Trust",
        "price": 100.04
    }, {
        "symbol": "MOM",
        "label": "AGFiQ U.S. Market Neutral Momentum Fund",
        "price": 24.34
    }, {
        "symbol": "MORT",
        "label": "VanEck Vectors Mortgage REIT Income",
        "price": 22.71
    }, {
        "symbol": "MOSC",
        "label": "Mosaic Acquisition Corp. Class A",
        "price": 9.9
    }, {
        "symbol": "MOXC",
        "label": "Moxian Inc.",
        "price": 2.03
    }, {
        "symbol": "MPAC",
        "label": "Matlin & Partners Acquisition Corporation",
        "price": 10.01
    }, {
        "symbol": "MPACU",
        "label": "Matlin & Partners Acquisition Corporation Unit",
        "price": 10.99
    }, {
        "symbol": "MPACW",
        "label": "Matlin & Partners Acquisition Corporation Warrants",
        "price": 0.73
    }, {
        "symbol": "MPB",
        "label": "Mid Penn Bancorp",
        "price": 28.2
    }, {
        "symbol": "MPV",
        "label": "Barings Participation Investors",
        "price": 16.43
    }, {
        "symbol": "MPVD",
        "label": "Mountain Province Diamonds Inc.",
        "price": 0.875
    }, {
        "symbol": "MRBK",
        "label": "Meridian Bank",
        "price": 20.37
    }, {
        "symbol": "MRDNW",
        "label": "Meridian Waste Solutions Inc Warrants",
        "price": 0.0512
    }, {
        "symbol": "MRGR",
        "label": "ProShares Merger",
        "price": 37.47
    }, {
        "symbol": "MSBF",
        "label": "MSB Financial Corp.",
        "price": 17.45
    }, {
        "symbol": "MSD",
        "label": "Morgan Stanley Emerging Markets Debt Fund Inc.",
        "price": 9.73
    }, {
        "symbol": "MSON",
        "label": "MISONIX Inc.",
        "price": 19.53
    }, {
        "symbol": "MTBCP",
        "label": "Medical Transcription Billing Corp. 11% Series A Cumulative Redeemable Perpetual" +
                " Preferred Stock",
        "price": 27.3
    }, {
        "symbol": "MTEC",
        "label": "MTech Acquisition Corp.",
        "price": 10.22
    }, {
        "symbol": "MTECU",
        "label": "MTech Acquisition Corp. Unit",
        "price": 10.93
    }, {
        "symbol": "MTECW",
        "label": "MTech Acquisition Corp. Warrant",
        "price": 0.95
    }, {
        "symbol": "MTEX",
        "label": "Mannatech Incorporated",
        "price": 16.0744
    }, {
        "symbol": "MTFB",
        "label": "Motif Bio plc",
        "price": 0.105
    }, {
        "symbol": "MTFBW",
        "label": "Motif Bio plc Warrant",
        "price": 0.0035
    }, {
        "symbol": "MTGEP",
        "label": "MTGE Investment Corp. 8.125% Series A Cumulative Redeemable Preferred Stock",
        "price": 25.81
    }, {
        "symbol": "MTP",
        "label": "Midatech Pharma PLC",
        "price": 0.9
    }, {
        "symbol": "MTSL",
        "label": "MER Telemanagement Solutions Ltd.",
        "price": 1.11
    }, {
        "symbol": "MUDS",
        "label": "Mudrick Capital Acquisition Corporation",
        "price": 10.37
    }, {
        "symbol": "MUDSU",
        "label": "Mudrick Capital Acquisition Corporation Unit",
        "price": 10.95
    }, {
        "symbol": "MUDSW",
        "label": "Mudrick Capital Acquisition Corporation Warrant",
        "price": 0.735
    }, {
        "symbol": "MUE",
        "label": "Blackrock MuniHoldings Quality Fund II Inc.",
        "price": 12.85
    }, {
        "symbol": "MUNI",
        "label": "PIMCO Intermediate Municipal Bond Active Exchange-Traded Fund",
        "price": 54.65
    }, {
        "symbol": "MVBF",
        "label": "MVB Financial Corp.",
        "price": 22.22
    }, {
        "symbol": "MVCD",
        "label": "MVC Capital Inc. 6.25% Senior Notes due 2022",
        "price": 25.3
    }, {
        "symbol": "MVIN",
        "label": "Natixis ETF Trust",
        "price": 42.46
    }, {
        "symbol": "MVT",
        "label": "Blackrock MuniVest Fund II Inc.",
        "price": 15.16
    }, {
        "symbol": "MXDU",
        "label": "Nationwide Maximum Diversification U.S. Core Equity",
        "price": 27.21
    }, {
        "symbol": "MXE",
        "label": "Mexico Equity and Income Fund Inc. (The)",
        "price": 12.1501
    }, {
        "symbol": "MYC",
        "label": "Blackrock MuniYield California Fund Inc.",
        "price": 14.37
    }, {
        "symbol": "MYND",
        "label": "MYnd Analytics Inc.",
        "price": 1.24
    }, {
        "symbol": "MYNDW",
        "label": "MYnd Analytics Inc. Warrant",
        "price": 0.13
    }, {
        "symbol": "MYOS",
        "label": "MYOS RENS Technology Inc.",
        "price": 1.45
    }, {
        "symbol": "MYY",
        "label": "ProShares Short MidCap400",
        "price": 43.8
    }, {
        "symbol": "MZZ",
        "label": "ProShares UltraShort MidCap400",
        "price": 18.82
    }, {
        "symbol": "NAIL",
        "label": "Direxion Daily Homebuilders & Supplies Bull 3X Shares",
        "price": 45.69
    }, {
        "symbol": "NAKD",
        "label": "Naked Brand Group Inc.",
        "price": 1.22
    }, {
        "symbol": "NANR",
        "label": "SPDR S&P North American Natural Resources",
        "price": 29.63
    }, {
        "symbol": "NAOV",
        "label": "NanoVibronix Inc.",
        "price": 2.9
    }, {
        "symbol": "NATR",
        "label": "Nature's Sunshine Products Inc.",
        "price": 9.73
    }, {
        "symbol": "NAVB",
        "label": "Navidea Biopharmaceuticals Inc.",
        "price": 1.45
    }, {
        "symbol": "NAZ",
        "label": "Nuveen Arizona Quality Municipal Income Fund",
        "price": 13.5974
    }, {
        "symbol": "NBB",
        "label": "Nuveen Build America Bond Fund of Beneficial Interest",
        "price": 22.14
    }, {
        "symbol": "NBD",
        "label": "Nuveen Build America Bond Opportunity Fund of Beneficial Interest",
        "price": 20.25
    }, {
        "symbol": "NBH",
        "label": "Neuberger Berman Intermediate Municipal Fund Inc.",
        "price": 15.74
    }, {
        "symbol": "NBN",
        "label": "Northeast Bancorp",
        "price": 20.76
    }, {
        "symbol": "NBO",
        "label": "Neuberger Berman New York Intermediate Municipal Fund Inc.",
        "price": 12.66
    }, {
        "symbol": "NBY",
        "label": "NovaBay Pharmaceuticals Inc.",
        "price": 0.628119
    }, {
        "symbol": "NCB",
        "label": "Nuveen California Municipal Value Fund 2 of Beneficial Interest",
        "price": 16.09
    }, {
        "symbol": "NCTY",
        "label": "The9 Limited",
        "price": 1.01
    }, {
        "symbol": "NDRA",
        "label": "ENDRA Life Sciences Inc.",
        "price": 1.83
    }, {
        "symbol": "NDRAW",
        "label": "ENDRA Life Sciences Inc. Warrants",
        "price": 0.4
    }, {
        "symbol": "NDRO",
        "label": "Enduro Royalty Trust Trust Units representing beneficial interest in the trust",
        "price": 3.65
    }, {
        "symbol": "NEBU",
        "label": "Nebula Acquisition Corporation",
        "price": 10.5
    }, {
        "symbol": "NEBUU",
        "label": "Nebula Acquisition Corporation Unit",
        "price": 11.09
    }, {
        "symbol": "NEBUW",
        "label": "Nebula Acquisition Corporation Warrant",
        "price": 1.65
    }, {
        "symbol": "NEN",
        "label": "New England Realty Associates Limited Partnership Class A Depositary Receipts Ev" +
                "idencing Units of Limited Partnership",
        "price": 64.0
    }, {
        "symbol": "NESR",
        "label": "National Energy Services Reunited Corp.",
        "price": 8.67
    }, {
        "symbol": "NESRW",
        "label": "National Energy Services Reunited Corp. Warrant",
        "price": 0.6931
    }, {
        "symbol": "NEWA",
        "label": "Newater Technology Inc.",
        "price": 4.05
    }, {
        "symbol": "NEWTI",
        "label": "Newtek Business Services Corp. 6.25% Notes Due 2023",
        "price": 25.78
    }, {
        "symbol": "NFLT",
        "label": "Virtus Newfleet Multi-Sector Bond",
        "price": 24.26
    }, {
        "symbol": "NFO",
        "label": "Guggenheim Insider",
        "price": 74.4417
    }, {
        "symbol": "NFRA",
        "label": "FlexShares STOXX Global Broad Infrastructure Index Fund",
        "price": 49.78
    }, {
        "symbol": "NGHCP",
        "label": "National General Holdings Corp 7.50% Non-Cumulative Preferred Stock Series A",
        "price": 25.76
    }, {
        "symbol": "NGVC",
        "label": "Natural Grocers by Vitamin Cottage Inc.",
        "price": 10.15
    }, {
        "symbol": "NHA",
        "label": "Nuveen Municipal 2021 Target Term Fund Fund",
        "price": 9.8
    }, {
        "symbol": "NHLD",
        "label": "National Holdings Corporation",
        "price": 2.765
    }, {
        "symbol": "NHLDW",
        "label": "National Holdings Corporation Warrants",
        "price": 0.1487
    }, {
        "symbol": "NHTC",
        "label": "Natural Health Trends Corp.",
        "price": 5.24
    }, {
        "symbol": "NICK",
        "label": "Nicholas Financial Inc.",
        "price": 8.41
    }, {
        "symbol": "NINI",
        "label": "iPath Pure Beta Nickel ETN",
        "price": 22.7855
    }, {
        "symbol": "NKG",
        "label": "Nuveen Georgia Quality Municipal Income Fund",
        "price": 12.771
    }, {
        "symbol": "NKX",
        "label": "Nuveen California AMT-Free Quality Municipal Income Fund",
        "price": 15.52
    }, {
        "symbol": "NLR",
        "label": "VanEck Vectors Uranium & Nuclear Energy",
        "price": 48.83
    }, {
        "symbol": "NMI",
        "label": "Nuveen Municipal Income Fund Inc.",
        "price": 11.48
    }, {
        "symbol": "NMRD",
        "label": "Nemaura Medical Inc.",
        "price": 3.6021
    }, {
        "symbol": "NMS",
        "label": "Nuveen Minnesota Quality Municipal Income Fund",
        "price": 14.4
    }, {
        "symbol": "NMT",
        "label": "Nuveen Massachusetts Quality Municipal Income Fund",
        "price": 14.17
    }, {
        "symbol": "NMY",
        "label": "Nuveen Maryland Quality Municipal Income Fund",
        "price": 13.59
    }, {
        "symbol": "NNC",
        "label": "Nuveen North Carolina Quality Municipal Income Fund",
        "price": 13.66
    }, {
        "symbol": "NNDM",
        "label": "Nano Dimension Ltd.",
        "price": 2.2892
    }, {
        "symbol": "NNVC",
        "label": "NanoViricides Inc.",
        "price": 3.36
    }, {
        "symbol": "NNY",
        "label": "Nuveen New York Municipal Value Fund Inc.",
        "price": 10.23
    }, {
        "symbol": "NOM",
        "label": "Nuveen Missouri Quality Municipal Income Fund",
        "price": 14.21
    }, {
        "symbol": "NPN",
        "label": "Nuveen Pennsylvania Municipal Value Fund of Beneficial Interest",
        "price": 15.39
    }, {
        "symbol": "NPV",
        "label": "Nuveen Virginia Quality Municipal Income Fund",
        "price": 14.57
    }, {
        "symbol": "NQP",
        "label": "Nuveen Pennsylvania Quality Municipal Income Fund",
        "price": 14.31
    }, {
        "symbol": "NRCIB",
        "label": "National Research Corporation Common Stock",
        "price": 53.0
    }, {
        "symbol": "NRT",
        "label": "North European Oil Royality Trust",
        "price": 5.73
    }, {
        "symbol": "NSEC",
        "label": "National Security Group Inc.",
        "price": 15.34
    }, {
        "symbol": "NSS",
        "label": "NuStar Logistics L.P. 7.625% Fixed-to-Floating Rate Subordinated Notes due 2043",
        "price": 26.3
    }, {
        "symbol": "NSYS",
        "label": "Nortech Systems Incorporated",
        "price": 5.0536
    }, {
        "symbol": "NTC",
        "label": "Nuveen Connecticut Quality Municipal Income Fund",
        "price": 12.04
    }, {
        "symbol": "NTRP",
        "label": "Neurotrope Inc.",
        "price": 1.23
    }, {
        "symbol": "NTX",
        "label": "Nuveen Texas Quality Municipal Income Fund",
        "price": 14.58
    }, {
        "symbol": "NTZ",
        "label": "Natuzzi S.p.A.",
        "price": 1.727
    }, {
        "symbol": "NUAG",
        "label": "NuShares Enhanced Yield US Aggregate Bond ETF",
        "price": 24.21
    }, {
        "symbol": "NUBD",
        "label": "NuShares ESG U.S. Aggregate Bond",
        "price": 23.85
    }, {
        "symbol": "NULG",
        "label": "NuShares ESG Large-Cap Growth",
        "price": 35.76
    }, {
        "symbol": "NULV",
        "label": "NuShares ESG Large-Cap Value",
        "price": 29.59
    }, {
        "symbol": "NUMG",
        "label": "NuShares ESG Mid-Cap Growth",
        "price": 27.81
    }, {
        "symbol": "NUMV",
        "label": "NuShares ESG Mid-Cap Value",
        "price": 27.08
    }, {
        "symbol": "NUO",
        "label": "Nuveen Ohio Quality Municipal Income Fund",
        "price": 15.47
    }, {
        "symbol": "NURE",
        "label": "NuShares Enhanced Yield US Aggregate Bond ETF",
        "price": 29.22
    }, {
        "symbol": "NUSA",
        "label": "NuShares Enhanced Yield 1-5 Year U.S. Aggregate Bond",
        "price": 24.41
    }, {
        "symbol": "NUSC",
        "label": "NuShares ESG Small-Cap",
        "price": 28.37
    }, {
        "symbol": "NUW",
        "label": "Nuveen AMT-Free Municipal Value Fund",
        "price": 16.48
    }, {
        "symbol": "NVMM",
        "label": "Novume Solutions Inc.",
        "price": 0.67
    }, {
        "symbol": "NWFL",
        "label": "Norwood Financial Corp.",
        "price": 37.15
    }, {
        "symbol": "NWHM",
        "label": "The New Home Company Inc.",
        "price": 4.95
    }, {
        "symbol": "NXC",
        "label": "Nuveen California Select Tax-Free Income Portfolio",
        "price": 15.67
    }, {
        "symbol": "NXEOU",
        "label": "Nexeo Solutions Inc. Unit",
        "price": 8.85
    }, {
        "symbol": "NXP",
        "label": "Nuveen Select Tax Free Income Portfolio",
        "price": 16.725
    }, {
        "symbol": "NXR",
        "label": "Nuveen Select Tax Free Income Portfolio III",
        "price": 16.31
    }, {
        "symbol": "NYMTN",
        "label": "New York Mortgage Trust Inc. 8.00% Series D Fixed-to-Floating Rate Cumulative Re" +
                "deemable Preferred Stock",
        "price": 25.77
    }, {
        "symbol": "NYMTO",
        "label": "New York Mortgage Trust Inc. 7.875% Series C Cumulative Redeemable Preferred Sto" +
                "ck",
        "price": 25.44
    }, {
        "symbol": "NYV",
        "label": "Nuveen New York Municipal Value Fund 2 of Beneficial Interest",
        "price": 14.42
    }, {
        "symbol": "OASI",
        "label": "O'Shares FTSE Asia Pacific Quality Dividend",
        "price": 25.62
    }, {
        "symbol": "OASM",
        "label": "Oasmia Pharmaceutical AB",
        "price": 1.51
    }, {
        "symbol": "OBAS",
        "label": "Optibase Ltd.",
        "price": 11.14
    }, {
        "symbol": "OBCI",
        "label": "Ocean Bio-Chem Inc.",
        "price": 3.35
    }, {
        "symbol": "OBOR",
        "label": "KraneShares MSCI One Belt One Road Index",
        "price": 23.39
    }, {
        "symbol": "OBSV",
        "label": "ObsEva SA",
        "price": 3.8
    }, {
        "symbol": "OCC",
        "label": "Optical Cable Corporation",
        "price": 3.54
    }, {
        "symbol": "OCIO",
        "label": "ETF Series Solutions Trust",
        "price": 26.93
    }, {
        "symbol": "OCSLL",
        "label": "Oaktree Specialty Lending Corporation 6.125% senior notes due 2028",
        "price": 25.62
    }, {
        "symbol": "ODC",
        "label": "Oil-Dri Corporation Of America",
        "price": 37.61
    }, {
        "symbol": "OESX",
        "label": "Orion Energy Systems Inc.",
        "price": 4.56
    }, {
        "symbol": "OEUR",
        "label": "O'Shares FTSE Europe Quality Dividend",
        "price": 23.61
    }, {
        "symbol": "OEW",
        "label": "Guggenheim ETF Trust",
        "price": 31.63
    }, {
        "symbol": "OFLX",
        "label": "Omega Flex Inc.",
        "price": 109.0
    }, {
        "symbol": "OGCP",
        "label": "Empire State Realty OP L.P. Series 60 Operating Partnership Units Representing L" +
                "imited Partnership Interests",
        "price": 15.62
    }, {
        "symbol": "OHAI",
        "label": "OHA Investment Corporation",
        "price": 1.315
    }, {
        "symbol": "OHGI",
        "label": "One Horizon Group Inc.",
        "price": 0.08
    }, {
        "symbol": "OHRP",
        "label": "Ohr Pharmaceutical Inc.",
        "price": 3.04
    }, {
        "symbol": "OIIL",
        "label": "Credit Suisse X-Links WTI Crude Oil Index ETNs due February 8 2016",
        "price": 41.8
    }, {
        "symbol": "OILB",
        "label": "iPath Series B S&P GSCI Crude Oil",
        "price": 64.41
    }, {
        "symbol": "OILK",
        "label": "ProShares K-1 Free Crude Oil Strategy",
        "price": 23.08
    }, {
        "symbol": "OILX",
        "label": "ETRACS S&P GSCI Crude Oil Total Return Index ETN due February 22 2046",
        "price": 42.14
    }, {
        "symbol": "OKDCC",
        "label": "Eaton Vance NextShares Trust II",
        "price": 99.99
    }, {
        "symbol": "OLD",
        "label": "The Long-Term Care ETF",
        "price": 31.6133
    }, {
        "symbol": "OLEM",
        "label": "iPath Pure Beta Crude Oil ETN",
        "price": 19.87
    }, {
        "symbol": "OLO",
        "label": "DB Crude Oil Long Exchange Traded Notes due June 1 2038",
        "price": 5.36
    }, {
        "symbol": "OMAD",
        "label": "One Madison Corporation Class A",
        "price": 9.9
    }, {
        "symbol": "OMFL",
        "label": "Oppenheimer Russell 1000 Dynamic Multifactor",
        "price": 28.35
    }, {
        "symbol": "OMFS",
        "label": "Oppenheimer Russell 2000 Dynamic Multifactor",
        "price": 25.9
    }, {
        "symbol": "OMOM",
        "label": "Oppenheimer Russell 1000 Momentum Factor",
        "price": 27.72
    }, {
        "symbol": "ONEO",
        "label": "SPDR Russell 1000 Momentum Focus",
        "price": 70.38
    }, {
        "symbol": "ONEV",
        "label": "SPDR Russell 1000 Low Volatility Focus",
        "price": 88.0501
    }, {
        "symbol": "ONEY",
        "label": "SPDR Russell 1000 Yield Focus",
        "price": 77.8531
    }, {
        "symbol": "ONG",
        "label": "iPath Pure Beta Energy ETN",
        "price": 19.69
    }, {
        "symbol": "ONP",
        "label": "Orient Paper Inc.",
        "price": 0.85
    }, {
        "symbol": "ONS",
        "label": "Oncobiologics Inc.",
        "price": 0.66
    }, {
        "symbol": "ONSIZ",
        "label": "Oncobiologics Inc. Series B Warrant",
        "price": 0.03
    }, {
        "symbol": "ONTXW",
        "label": "Onconova Therapeutics Inc. Warrants",
        "price": 0.1594
    }, {
        "symbol": "OPGN",
        "label": "OpGen Inc.",
        "price": 1.91
    }, {
        "symbol": "OPHC",
        "label": "OptimumBank Holdings Inc.",
        "price": 2.89
    }, {
        "symbol": "OPOF",
        "label": "Old Point Financial Corporation",
        "price": 27.93
    }, {
        "symbol": "OQAL",
        "label": "Oppenheimer Russell 1000 Quality Factor",
        "price": 26.03
    }, {
        "symbol": "ORG",
        "label": "The Organics ETF",
        "price": 23.77
    }, {
        "symbol": "ORGS",
        "label": "Orgenesis Inc.",
        "price": 5.4
    }, {
        "symbol": "ORMP",
        "label": "Oramed Pharmaceuticals Inc.",
        "price": 5.38
    }, {
        "symbol": "ORPN",
        "label": "Bioblast Pharma Ltd.",
        "price": 10.72
    }, {
        "symbol": "ORRF",
        "label": "Orrstown Financial Services Inc",
        "price": 22.23
    }, {
        "symbol": "OSBCP",
        "label": "Old Second Bancorp Inc. 7.80% Cumulative Trust Preferred Securities",
        "price": 10.66
    }, {
        "symbol": "OSIZ",
        "label": "Oppenheimer Russell 1000 Size Factor",
        "price": 26.0789
    }, {
        "symbol": "OSLE",
        "label": "Oaktree Specialty Lending Corporation 5.875% Senior Notes due 2024",
        "price": 25.87
    }, {
        "symbol": "OTEL",
        "label": "Otelco Inc.",
        "price": 8.7
    }, {
        "symbol": "OTTW",
        "label": "Ottawa Bancorp Inc.",
        "price": 13.98
    }, {
        "symbol": "OUSM",
        "label": "O'Shares FTSE Russell Small Cap Quality Dividend",
        "price": 25.85
    }, {
        "symbol": "OVAS",
        "label": "OvaScience Inc.",
        "price": 0.76
    }, {
        "symbol": "OVLC",
        "label": "Guggenheim U.S. Large Cap Optimized Volatility",
        "price": 31.59
    }, {
        "symbol": "OVLU",
        "label": "Oppenheimer Russell 1000 Value Factor",
        "price": 26.5297
    }, {
        "symbol": "OVLY",
        "label": "Oak Valley Bancorp (CA)",
        "price": 18.85
    }, {
        "symbol": "OVOL",
        "label": "Oppenheimer Russell 1000 Low Volatility Factor",
        "price": 28.72
    }, {
        "symbol": "OXBR",
        "label": "Oxbridge Re Holdings Limited",
        "price": 1.0
    }, {
        "symbol": "OXBRW",
        "label": "Oxbridge Re Holdings Limited Warrant",
        "price": 0.0598
    }, {
        "symbol": "OXLCM",
        "label": "Oxford Lane Capital Corp. 6.75% Series 2024 Term Preferred Stock",
        "price": 25.65
    }, {
        "symbol": "OXLCO",
        "label": "Oxford Lane Capital Corp. Term Preferred Shares 7.50% Series 2023",
        "price": 25.45
    }, {
        "symbol": "OYLD",
        "label": "Oppenheimer Russell 1000 Yield Factor",
        "price": 24.2099
    }, {
        "symbol": "PAF",
        "label": "PowerShares FTSE RAFI Asia Pacific ex-Japan Portfolio",
        "price": 53.08
    }, {
        "symbol": "PAGG",
        "label": "PowerShares Global Agriculture Portfolio",
        "price": 25.18
    }, {
        "symbol": "PAI",
        "label": "Western Asset Income Fund",
        "price": 16.1
    }, {
        "symbol": "PAK",
        "label": "Global X MSCI Pakistan",
        "price": 7.51
    }, {
        "symbol": "PATI",
        "label": "Patriot Transportation Holding Inc.",
        "price": 15.0
    }, {
        "symbol": "PAVE",
        "label": "Global X Funds U.S. Infrastructure Development",
        "price": 15.45
    }, {
        "symbol": "PAVM",
        "label": "PAVmed Inc.",
        "price": 1.52
    }, {
        "symbol": "PAVMW",
        "label": "PAVmed Inc. Warrant",
        "price": 0.9197
    }, {
        "symbol": "PBB",
        "label": "Prospect Capital Corporation 6.25% Notes due 2024",
        "price": 25.6301
    }, {
        "symbol": "PBD",
        "label": "PowerShares Global Clean Energy Portfolio",
        "price": 12.56
    }, {
        "symbol": "PBDM",
        "label": "PowerShares PureBeta FTSE Developed ex-North America Portfolio",
        "price": 24.8
    }, {
        "symbol": "PBEE",
        "label": "PowerShares PureBeta FTSE Emerging Markets Portfolio",
        "price": 27.1588
    }, {
        "symbol": "PBHC",
        "label": "Pathfinder Bancorp Inc.",
        "price": 13.97
    }, {
        "symbol": "PBIB",
        "label": "Porter Bancorp Inc.",
        "price": 14.06
    }, {
        "symbol": "PBND",
        "label": "PowerShares PureBeta US Aggregate Bond Portfolio",
        "price": 24.32
    }, {
        "symbol": "PBP",
        "label": "PowerShares S&P 500 BuyWrite Portfolio",
        "price": 21.79
    }, {
        "symbol": "PBSM",
        "label": "PowerShares PureBeta MSCI USA Small Cap Portfolio",
        "price": 27.0682
    }, {
        "symbol": "PBTP",
        "label": "PowerShares PureBeta 0-5 Yr US TIPS Portfolio",
        "price": 24.58
    }, {
        "symbol": "PBUS",
        "label": "PowerShares PureBeta MSCI USA Portfolio",
        "price": 27.2
    }, {
        "symbol": "PCM",
        "label": "PCM Fund Inc.",
        "price": 11.54
    }, {
        "symbol": "PCQ",
        "label": "PIMCO California Municipal Income Fund",
        "price": 19.09
    }, {
        "symbol": "PDEX",
        "label": "Pro-Dex Inc.",
        "price": 17.2
    }, {
        "symbol": "PDLB",
        "label": "PDL Community Bancorp",
        "price": 14.6
    }, {
        "symbol": "PESI",
        "label": "Perma-Fix Environmental Services Inc.",
        "price": 9.34
    }, {
        "symbol": "PETZ",
        "label": "TDH Holdings Inc.",
        "price": 1.5
    }, {
        "symbol": "PEX",
        "label": "ProShares Global Listed Private Equity",
        "price": 34.33
    }, {
        "symbol": "PEZ",
        "label": "PowerShares DWA Consumer Cyclicals Momentum Portfolio",
        "price": 55.93
    }, {
        "symbol": "PFD",
        "label": "Flaherty & Crumrine Preferred Income Fund Incorporated",
        "price": 15.93
    }, {
        "symbol": "PFFR",
        "label": "ETFIS Series Trust I",
        "price": 24.61
    }, {
        "symbol": "PFH",
        "label": "Cabco Tr Jcp 7.625",
        "price": 6.25
    }, {
        "symbol": "PFI",
        "label": "PowerShares DWA Financial Momentum Portfolio",
        "price": 40.195
    }, {
        "symbol": "PFIG",
        "label": "PowerShares Fundamental Investment Grade Corporate Bond Portfolio",
        "price": 25.7
    }, {
        "symbol": "PFIN",
        "label": "P & F Industries Inc.",
        "price": 6.92
    }, {
        "symbol": "PFIS",
        "label": "Peoples Financial Services Corp.",
        "price": 49.7
    }, {
        "symbol": "PFK",
        "label": "Prudential Financial Inflation-Linked Retail Medium Linked Retail Medium-Term No" +
                "tes due April 10 2018",
        "price": 24.95
    }, {
        "symbol": "PFM",
        "label": "PowerShares Dividend Achievers Portfolio",
        "price": 31.4421
    }, {
        "symbol": "PGD",
        "label": "iPath Asian and Gulf Currency Revaluation ETN Revaluation ETN",
        "price": 50.0
    }, {
        "symbol": "PGLC",
        "label": "Pershing Gold Corporation",
        "price": 1.09
    }, {
        "symbol": "PGM",
        "label": "iPath Bloomberg Platinum Subindex Total Return SM ETN",
        "price": 19.05
    }, {
        "symbol": "PGMB",
        "label": "iPathA Series B Bloomberg Platinum Subindex Total Return ETN",
        "price": 49.8
    }, {
        "symbol": "PGP",
        "label": "Pimco Global Stocksplus & Income Fund StocksPlus & Income Fund of Beneficial Int" +
                "erest",
        "price": 12.41
    }, {
        "symbol": "PHII",
        "label": "PHI Inc.",
        "price": 1.2
    }, {
        "symbol": "PHO",
        "label": "PowerShares Water Resources Portfolio",
        "price": 40.0
    }, {
        "symbol": "PIE",
        "label": "PowerShares DWA Emerging Markets Momentum Portfolio",
        "price": 19.9952
    }, {
        "symbol": "PIH",
        "label": "1347 Property Insurance Holdings Inc.",
        "price": 5.74
    }, {
        "symbol": "PIO",
        "label": "PowerShares Global Water Portfolio",
        "price": 31.82
    }, {
        "symbol": "PIZ",
        "label": "PowerShares DWA Developed Markets Momentum Portfolio",
        "price": 29.7355
    }, {
        "symbol": "PJH",
        "label": "Prudential Financial Inc. 5.75% Junior Subordinated Notes due 2052",
        "price": 26.27
    }, {
        "symbol": "PKBK",
        "label": "Parke Bancorp Inc.",
        "price": 24.54
    }, {
        "symbol": "PKO",
        "label": "Pimco Income Opportunity Fund of Beneficial Interest",
        "price": 28.08
    }, {
        "symbol": "PKW",
        "label": "PowerShares BuyBack Achievers Portfolio",
        "price": 70.65
    }, {
        "symbol": "PLBC",
        "label": "Plumas Bancorp",
        "price": 26.89
    }, {
        "symbol": "PLG",
        "label": "Platinum Group Metals Ltd. (Canada)",
        "price": 2.65
    }, {
        "symbol": "PLND",
        "label": "VanEck Vectors Poland",
        "price": 17.25
    }, {
        "symbol": "PLTM",
        "label": "GraniteShares Platinum Shares",
        "price": 8.39
    }, {
        "symbol": "PLW",
        "label": "PowerShares 1-30 Laddered Treasury Portfolio",
        "price": 34.86
    }, {
        "symbol": "PLYM",
        "label": "Plymouth Industrial REIT Inc.",
        "price": 18.43
    }, {
        "symbol": "PMD",
        "label": "Psychemedics Corporation",
        "price": 10.62
    }, {
        "symbol": "PME",
        "label": "Pingtan Marine Enterprise Ltd.",
        "price": 1.15
    }, {
        "symbol": "PMO",
        "label": "Putnam Municipal Opportunities Trust",
        "price": 13.44
    }, {
        "symbol": "PMOM",
        "label": "Principal Sustainable Momentum Index ETF",
        "price": 29.925
    }, {
        "symbol": "PMPT",
        "label": "iSectors Post-MPT Growth ETF",
        "price": 25.85
    }, {
        "symbol": "PNBK",
        "label": "Patriot National Bancorp Inc.",
        "price": 13.02
    }, {
        "symbol": "PNF",
        "label": "PIMCO New York Municipal Income Fund",
        "price": 13.4875
    }, {
        "symbol": "PNRG",
        "label": "PrimeEnergy Corporation",
        "price": 147.0
    }, {
        "symbol": "PNTR",
        "label": "Pointer Telocation Ltd.",
        "price": 14.26
    }, {
        "symbol": "POPE",
        "label": "Pope Resources",
        "price": 125.26
    }, {
        "symbol": "PPDM",
        "label": "Portfolio Developed Markets",
        "price": 23.83
    }, {
        "symbol": "PPEM",
        "label": "Portfolio Emerging Markets",
        "price": 18.55
    }, {
        "symbol": "PPH",
        "label": "VanEck Vectors Pharmaceutical ETF",
        "price": 66.7
    }, {
        "symbol": "PPLC",
        "label": "Portfolio S&P 500",
        "price": 40.23
    }, {
        "symbol": "PPLN",
        "label": "Cushing 30 MLP Index ETNs due June 15 2037",
        "price": 16.92
    }, {
        "symbol": "PPMC",
        "label": "Portfolio S&P Mid Cap",
        "price": 24.38
    }, {
        "symbol": "PPSC",
        "label": "Portfolio S&P Small Cap",
        "price": 33.76
    }, {
        "symbol": "PPTB",
        "label": "Portfolio Total Bond Markets",
        "price": 25.03
    }, {
        "symbol": "PRB",
        "label": "VanEck Vectors Pre-refunded Municipal Index",
        "price": 24.55
    }, {
        "symbol": "PREF",
        "label": "Principal Spectrum Preferred Securities Active",
        "price": 95.51
    }, {
        "symbol": "PRH",
        "label": "Prudential Financial Inc. 5.70% Junior Subordinated Notes due 2053",
        "price": 26.08
    }, {
        "symbol": "PRID",
        "label": "InsightShares LGBT Employment Equality",
        "price": 27.2
    }, {
        "symbol": "PRKR",
        "label": "ParkerVision Inc.",
        "price": 0.26
    }, {
        "symbol": "PRN",
        "label": "PowerShares DWA Industrials Momentum Portfolio",
        "price": 71.7323
    }, {
        "symbol": "PRNT",
        "label": "3D Printing (The)",
        "price": 21.24
    }, {
        "symbol": "PROV",
        "label": "Provident Financial Holdings Inc.",
        "price": 21.74
    }, {
        "symbol": "PRPH",
        "label": "ProPhase Labs Inc.",
        "price": 1.83
    }, {
        "symbol": "PRPL",
        "label": "Purple Innovation Inc.",
        "price": 10.97
    }, {
        "symbol": "PSAU",
        "label": "PowerShares Global Gold & Precious Metals Portfolio",
        "price": 18.56
    }, {
        "symbol": "PSC",
        "label": "Principal U.S. Small-Cap Multi-Factor Index ETF",
        "price": 33.335
    }, {
        "symbol": "PSCC",
        "label": "PowerShares S&P SmallCap Consumer Staples Portfolio",
        "price": 78.225
    }, {
        "symbol": "PSCF",
        "label": "PowerShares S&P SmallCap Financials Portfolio",
        "price": 56.725
    }, {
        "symbol": "PSCH",
        "label": "PowerShares S&P SmallCap Health Care Portfolio",
        "price": 136.4
    }, {
        "symbol": "PSCT",
        "label": "PowerShares S&P SmallCap Information Technology Portfolio",
        "price": 100.21
    }, {
        "symbol": "PSCU",
        "label": "PowerShares S&P SmallCap Utilities Portfolio",
        "price": 54.095
    }, {
        "symbol": "PSET",
        "label": "Principal Price Setters Index ETF",
        "price": 42.33
    }, {
        "symbol": "PSF",
        "label": "Cohen & Steers Select Preferred and Income Fund Inc.",
        "price": 31.75
    }, {
        "symbol": "PSI",
        "label": "PowerShares Dynamic Semiconductors",
        "price": 71.0605
    }, {
        "symbol": "PSL",
        "label": "PowerShares DWA Consumer Staples Momentum Portfolio",
        "price": 76.0273
    }, {
        "symbol": "PSMB",
        "label": "PowerShares Balanced Multi-Asset Allocation Portfolio",
        "price": 12.88
    }, {
        "symbol": "PSMC",
        "label": "PowerShares Conservative Multi-Asset Allocation Portfolio",
        "price": 12.97
    }, {
        "symbol": "PSMG",
        "label": "PowerShares Growth Multi-Asset Allocation Portfolio",
        "price": 14.36
    }, {
        "symbol": "PSMM",
        "label": "PowerShares Moderately Conservative Multi-Asset Allocation Portfolio",
        "price": 12.7
    }, {
        "symbol": "PSR",
        "label": "PowerShares Active U.S. Real Estate Fund",
        "price": 97.6713
    }, {
        "symbol": "PTF",
        "label": "PowerShares DWA Technology Momenum Portfolio",
        "price": 82.84
    }, {
        "symbol": "PTH",
        "label": "PowerShares DWA Healthcare Momentum Portfolio",
        "price": 96.3157
    }, {
        "symbol": "PTLC",
        "label": "Pacer Trendpilot US Large Cap",
        "price": 29.36
    }, {
        "symbol": "PTM",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI P" +
                "latinum ETN",
        "price": 9.47
    }, {
        "symbol": "PTNQ",
        "label": "Pacer Trendpilot 100",
        "price": 33.47
    }, {
        "symbol": "PTNR",
        "label": "Partner Communications Company Ltd.",
        "price": 4.23
    }, {
        "symbol": "PTSI",
        "label": "P.A.M. Transportation Services Inc.",
        "price": 57.35
    }, {
        "symbol": "PUI",
        "label": "PowerShares DWA Utilities Momentum Portfolio",
        "price": 35.48
    }, {
        "symbol": "PUW",
        "label": "PowerShares WilderHill Progressive Energy Portfolio",
        "price": 23.84
    }, {
        "symbol": "PVAL",
        "label": "Principal Contrarian Value Index ETF",
        "price": 28.5873
    }, {
        "symbol": "PW",
        "label": "Power REIT (MD)",
        "price": 8.89
    }, {
        "symbol": "PWB",
        "label": "PowerShares Dynamic LargeCap Growth",
        "price": 53.79
    }, {
        "symbol": "PWC",
        "label": "PowerShares XTF: Dynamic Market Portfolio",
        "price": 100.832
    }, {
        "symbol": "PWS",
        "label": "Pacer WealthShield",
        "price": 24.06
    }, {
        "symbol": "PWZ",
        "label": "PowerShares California AMT-Free Municipal Bond Portfolio",
        "price": 26.94
    }, {
        "symbol": "PXLG",
        "label": "PowerShares Russell Top 200 Pure Growth Portfolio",
        "price": 51.05
    }, {
        "symbol": "PXLV",
        "label": "PowerShares Russell Top 200 Pure Value Portfolio",
        "price": 40.29
    }, {
        "symbol": "PXMV",
        "label": "PowerShares Russell Midcap Pure Value Portfolio",
        "price": 31.83
    }, {
        "symbol": "PXS",
        "label": "Pyxis Tankers Inc.",
        "price": 1.085
    }, {
        "symbol": "PXSV",
        "label": "PowerShares Russell 2000 Pure Value Portfolio",
        "price": 28.25
    }, {
        "symbol": "PXUS",
        "label": "Principal International Multi-Factor Index ETF",
        "price": 106.29
    }, {
        "symbol": "PY",
        "label": "Principal Shareholder Yield Index ETF",
        "price": 34.7673
    }, {
        "symbol": "PYS",
        "label": "Merrill Lynch Depositor Inc PPlus Tr Ser RRD-1 Tr Ctf Cl A",
        "price": 20.6308
    }, {
        "symbol": "PYZ",
        "label": "PowerShares DWA Basic Materials Momentum Portfolio",
        "price": 61.63
    }, {
        "symbol": "PZC",
        "label": "PIMCO California Municipal Income Fund III of Beneficial Interest",
        "price": 11.2
    }, {
        "symbol": "PZD",
        "label": "PowerShares Cleantech Portfolio",
        "price": 42.19
    }, {
        "symbol": "PZI",
        "label": "PowerShares Zacks Micro Cap",
        "price": 17.82
    }, {
        "symbol": "PZT",
        "label": "PowerShares New York AMT-Free Municipal Bond Portfolio",
        "price": 25.61
    }, {
        "symbol": "QADB",
        "label": "QAD Inc.",
        "price": 37.35
    }, {
        "symbol": "QAT",
        "label": "iShares MSCI Qatar ETF",
        "price": 18.42
    }, {
        "symbol": "QBAK",
        "label": "Qualstar Corporation",
        "price": 5.85
    }, {
        "symbol": "QCAN",
        "label": "SPDR MSCI Canada StrategicFactors",
        "price": 58.93
    }, {
        "symbol": "QCLN",
        "label": "First Trust NASDAQ Clean Edge Green Energy Index Fund",
        "price": 26.91
    }, {
        "symbol": "QDEF",
        "label": "FlexShares Quality Dividend Defensive Index Fund",
        "price": 48.7219
    }, {
        "symbol": "QDEU",
        "label": "SPDR MSCI Germany StrategicFactors",
        "price": 62.7099
    }, {
        "symbol": "QDYN",
        "label": "FlexShares Quality Dynamic Index Fund",
        "price": 49.23
    }, {
        "symbol": "QED",
        "label": "IQ Hedge Event-Driven Tracker",
        "price": 21.865
    }, {
        "symbol": "QEMM",
        "label": "SPDR MSCI Emerging Markets StrategicFactors",
        "price": 56.71
    }, {
        "symbol": "QGBR",
        "label": "SPDR MSCI United Kingdom StrategicFactors",
        "price": 52.299
    }, {
        "symbol": "QGTA",
        "label": "IQ Leaders GTAA Tracker",
        "price": 25.6453
    }, {
        "symbol": "QJPN",
        "label": "SPDR MSCI Japan StrategicFactors",
        "price": 80.72
    }, {
        "symbol": "QLC",
        "label": "FlexShares US Quality Large Cap Index Fund",
        "price": 37.73
    }, {
        "symbol": "QLS",
        "label": "IQ Hedge Long Short Tracker",
        "price": 22.1334
    }, {
        "symbol": "QLTA",
        "label": "iShares Aaa A Rated Corporate Bond",
        "price": 52.31
    }, {
        "symbol": "QMN",
        "label": "IQ Hedge Market Neutral Tracker",
        "price": 25.7
    }, {
        "symbol": "QMOM",
        "label": "Alpha Architect U.S. Quantitative Momentum",
        "price": 30.22
    }, {
        "symbol": "QQXT",
        "label": "First Trust NASDAQ-100 Ex-Technology Sector Index Fund",
        "price": 60.24
    }, {
        "symbol": "QRHC",
        "label": "Quest Resource Holding Corporation.",
        "price": 2.51
    }, {
        "symbol": "QRTEB",
        "label": "Liberty Interactive Corporation Series B QVC Group Common Stock",
        "price": 8.69
    }, {
        "symbol": "QSY",
        "label": "WisdomTree U.S. Quality Shareholder Yield Fund",
        "price": 83.75
    }, {
        "symbol": "QTRH",
        "label": "Quarterhill Inc.",
        "price": 1.2
    }, {
        "symbol": "QUS",
        "label": "SPDR MSCI USA StrategicFactors",
        "price": 97.5
    }, {
        "symbol": "QVM",
        "label": "Arrow QVM Equity Factor",
        "price": 27.55
    }, {
        "symbol": "QWLD",
        "label": "SPDR MSCI World StrategicFactors",
        "price": 77.17
    }, {
        "symbol": "QXGG",
        "label": "QuantX Risk Managed Growth ETF",
        "price": 28.2
    }, {
        "symbol": "QXMI",
        "label": "QuantX Risk Managed Multi-Asset Income",
        "price": 25.36
    }, {
        "symbol": "QXRR",
        "label": "QuantX Risk Managed Real Return",
        "price": 24.4
    }, {
        "symbol": "QXTR",
        "label": "QuantX Risk Managed Multi-Asset Total Return",
        "price": 23.95
    }, {
        "symbol": "RALS",
        "label": "ProShares RAFI Long Short",
        "price": 35.18
    }, {
        "symbol": "RAND",
        "label": "Rand Capital Corporation",
        "price": 2.71
    }, {
        "symbol": "RAVI",
        "label": "FlexShares Ready Access Variable Income Fund",
        "price": 75.67
    }, {
        "symbol": "RBCN",
        "label": "Rubicon Technology Inc.",
        "price": 8.76
    }, {
        "symbol": "RBIN",
        "label": "Nationwide Risk-Based International Equity",
        "price": 23.88
    }, {
        "symbol": "RBNC",
        "label": "Reliant Bancorp Inc.",
        "price": 22.56
    }, {
        "symbol": "RBUS",
        "label": "Nationwide Risk-Based U.S. Equity",
        "price": 26.1775
    }, {
        "symbol": "RCD",
        "label": "Guggenheim S&P 500 Equal Weight Consumer Discretionary",
        "price": 100.41
    }, {
        "symbol": "RCG",
        "label": "RENN Fund Inc",
        "price": 1.7
    }, {
        "symbol": "RCMT",
        "label": "RCM Technologies Inc.",
        "price": 2.79
    }, {
        "symbol": "RCOM",
        "label": "Elkhorn ETF Trust",
        "price": 28.2769
    }, {
        "symbol": "RCON",
        "label": "Recon Technology Ltd.",
        "price": 2.44
    }, {
        "symbol": "RDIB",
        "label": "Reading International Inc",
        "price": 26.03
    }, {
        "symbol": "REED",
        "label": "Reeds Inc.",
        "price": 1.14
    }, {
        "symbol": "REEM",
        "label": "Oppenheimer Revenue Weighted ETF Trust",
        "price": 28.28
    }, {
        "symbol": "REFA",
        "label": "Oppenheimer International Revenue",
        "price": 24.75
    }, {
        "symbol": "REFR",
        "label": "Research Frontiers Incorporated",
        "price": 3.18
    }, {
        "symbol": "RELV",
        "label": "Reliv' International Inc.",
        "price": 3.98
    }, {
        "symbol": "REML",
        "label": "Credit Suisse AG X-Links Monthly Pay 2xLeveraged Mortgage REIT Exchange Traded N" +
                "otes (ETNs) due July 11 2036",
        "price": 23.0
    }, {
        "symbol": "RETL",
        "label": "Direxion Daily Retail Bull 3X Shares",
        "price": 21.85
    }, {
        "symbol": "REXX",
        "label": "Rex Energy Corporation",
        "price": 0.77
    }, {
        "symbol": "REZ",
        "label": "iShares Trust Residential Real Estate",
        "price": 77.7194
    }, {
        "symbol": "RFCI",
        "label": "RiverFront Dynamic Core Income",
        "price": 24.75
    }, {
        "symbol": "RFI",
        "label": "Cohen & Steers Total Return Realty Fund Inc.",
        "price": 14.59
    }, {
        "symbol": "RFT",
        "label": "RAIT Financial Trust 7.625% Senior Notes due 2024",
        "price": 17.66
    }, {
        "symbol": "RFUN",
        "label": "RiverFront Dynamic Unconstrained Income",
        "price": 24.44
    }, {
        "symbol": "RGCO",
        "label": "RGC Resources Inc.",
        "price": 26.52
    }, {
        "symbol": "RGLB",
        "label": "Oppenheimer Global Revenue",
        "price": 26.89
    }, {
        "symbol": "RGSE",
        "label": "Real Goods Solar Inc.",
        "price": 0.46
    }, {
        "symbol": "RGT",
        "label": "Royce Global Value Trust Inc.",
        "price": 11.88
    }, {
        "symbol": "RHS",
        "label": "Guggenheim S&P 500 Equal Weight Consumer Staples",
        "price": 147.62
    }, {
        "symbol": "RIBTW",
        "label": "RiceBran Technologies Warrant",
        "price": 0.16
    }, {
        "symbol": "RILYL",
        "label": "B. Riley Financial Inc. 7.50% Senior Notes",
        "price": 25.29
    }, {
        "symbol": "RILYZ",
        "label": "B. Riley Financial Inc. 7.50% Senior Notes Due 2027",
        "price": 25.24
    }, {
        "symbol": "RINF",
        "label": "ProShares Inflation Expectations",
        "price": 27.32
    }, {
        "symbol": "RISE",
        "label": "Sit Rising Rate",
        "price": 23.94
    }, {
        "symbol": "RIV",
        "label": "RiverNorth Opportunities Fund Inc.",
        "price": 16.96
    }, {
        "symbol": "RJZ",
        "label": "AB Svensk Ekportkredit (Swedish Export Credit Corporation) ELEMENTS Linked to th" +
                "e Rogers International Commodity Index - Metals Total Return Structured Product",
        "price": 8.04
    }, {
        "symbol": "RLJE",
        "label": "RLJ Entertainment Inc.",
        "price": 6.23
    }, {
        "symbol": "RMBL",
        "label": "RumbleOn Inc.",
        "price": 0.652
    }, {
        "symbol": "RMCF",
        "label": "Rocky Mountain Chocolate Factory Inc.",
        "price": 8.81
    }, {
        "symbol": "RNDB",
        "label": "Randolph Bancorp Inc.",
        "price": 17.47
    }, {
        "symbol": "RNDM",
        "label": "First Trust Developed International Equity Select ETF",
        "price": 52.8754
    }, {
        "symbol": "RNDV",
        "label": "First Trust US Equity Dividend Select ETF",
        "price": 24.5
    }, {
        "symbol": "RNEM",
        "label": "First Trust Emerging Markets Equity Select ETF",
        "price": 53.2696
    }, {
        "symbol": "RNLC",
        "label": "First Trust Large Cap US Equity Select ETF",
        "price": 25.5
    }, {
        "symbol": "RNMC",
        "label": "First Trust Mid Cap US Equity Select ETF",
        "price": 23.9507
    }, {
        "symbol": "RNSC",
        "label": "First Trust Small Cap US Equity Select ETF",
        "price": 22.445
    }, {
        "symbol": "ROBT",
        "label": "First Trust Nasdaq Artificial Intelligence and Robotics ETF",
        "price": 36.3598
    }, {
        "symbol": "RODI",
        "label": "Barclays Return on Disability ETN",
        "price": 70.0
    }, {
        "symbol": "RODM",
        "label": "Hartford Multifactor Developed Markets (ex-US)",
        "price": 29.71
    }, {
        "symbol": "ROGS",
        "label": "Hartford Multifactor Global Small Cap",
        "price": 28.02
    }, {
        "symbol": "ROLA",
        "label": "iPath Long Extended Russell 1000 TR Index ETN",
        "price": 228.79
    }, {
        "symbol": "RORE",
        "label": "Hartford Multifactor REIT",
        "price": 14.71
    }, {
        "symbol": "ROSEU",
        "label": "Rosehill Resources Inc. Unit",
        "price": 1.35
    }, {
        "symbol": "ROSG",
        "label": "Rosetta Genomics Ltd.",
        "price": 0.49
    }, {
        "symbol": "ROUS",
        "label": "Hartford Multifactor U.S. Equity",
        "price": 34.4822
    }, {
        "symbol": "RPIBC",
        "label": "Managed Portfolio Series",
        "price": 100.03
    }, {
        "symbol": "RPUT",
        "label": "WisdomTree CBOE Russell 2000 PutWrite Strategy Fund",
        "price": 17.18
    }, {
        "symbol": "RSXJ",
        "label": "VanEck Vectors Russia Small-Cap",
        "price": 31.03
    }, {
        "symbol": "RTLA",
        "label": "iPath Long Extended Russell 2000 TR Index ETN",
        "price": 209.9
    }, {
        "symbol": "RTM",
        "label": "Guggenheim S&P 500 Equal Weight Materials",
        "price": 115.4
    }, {
        "symbol": "RUSS",
        "label": "Direxion Daily Russia Bear 3x Shares",
        "price": 12.0
    }, {
        "symbol": "RVEN",
        "label": "Reven Housing REIT Inc.",
        "price": 3.59
    }, {
        "symbol": "RVNU",
        "label": "Xtrackers Municipal Infrastructure Revenue Bond",
        "price": 28.47
    }, {
        "symbol": "RWC",
        "label": "RELM Wireless Corporation",
        "price": 3.8
    }, {
        "symbol": "RWGE",
        "label": "Regalwood Global Energy Ltd. Class A",
        "price": 10.3575
    }, {
        "symbol": "RWJ",
        "label": "Oppenheimer Small Cap Revenue",
        "price": 60.07
    }, {
        "symbol": "RWW",
        "label": "Oppenheimer Financials Sector Revenue",
        "price": 74.9932
    }, {
        "symbol": "RXD",
        "label": "ProShares UltraShort Health Care",
        "price": 22.12
    }, {
        "symbol": "RXIIW",
        "label": "RXi Pharmaceuticals Corporation Warrants",
        "price": 0.28
    }, {
        "symbol": "RXL",
        "label": "ProShares Ultra Health Care",
        "price": 93.43
    }, {
        "symbol": "RYJ",
        "label": "Guggenheim Raymond James SB-1 Equity Fund",
        "price": 41.71
    }, {
        "symbol": "RYU",
        "label": "Guggenheim S&P 500 Equal Weight Utilities",
        "price": 109.49
    }, {
        "symbol": "RZV",
        "label": "Guggenheim S&P Smallcap 600 Pure Value",
        "price": 60.17
    }, {
        "symbol": "SAA",
        "label": "ProShares Ultra SmallCap600",
        "price": 84.69
    }, {
        "symbol": "SAEX",
        "label": "SAExploration Holdings Inc.",
        "price": 2.44
    }, {
        "symbol": "SAGG",
        "label": "Direxion Daily Total Bond Market Bear 1X Shares",
        "price": 30.88
    }, {
        "symbol": "SAMG",
        "label": "Silvercrest Asset Management Group Inc.",
        "price": 12.42
    }, {
        "symbol": "SAR",
        "label": "Saratoga Investment Corp New",
        "price": 28.14
    }, {
        "symbol": "SAUC",
        "label": "Diversified Restaurant Holdings Inc.",
        "price": 1.045
    }, {
        "symbol": "SBB",
        "label": "ProShares Short SmallCap600",
        "price": 35.02
    }, {
        "symbol": "SBFGP",
        "label": "SB Financial Group Inc. Depositary Shares each representing a 1/100th interest i" +
                "n a 6.50% Noncumulative convertible perpetual preferred share Series A",
        "price": 18.76
    }, {
        "symbol": "SBIO",
        "label": "ALPS Medical Breakthroughs",
        "price": 42.44
    }, {
        "symbol": "SBLKZ",
        "label": "Star Bulk Carriers Corp. 8.30% Senior Notes due 2022",
        "price": 26.1
    }, {
        "symbol": "SBM",
        "label": "Short Basic Materials",
        "price": 20.81
    }, {
        "symbol": "SBNYW",
        "label": "Signature Bank Warrants 12/12/2018",
        "price": 127.865
    }, {
        "symbol": "SBOT",
        "label": "Stellar Biotechnologies Inc.",
        "price": 1.16
    }, {
        "symbol": "SBR",
        "label": "Sabine Royalty Trust",
        "price": 40.88
    }, {
        "symbol": "SBRAP",
        "label": "Sabra Health Care REIT Inc. 7.125% Preferred Series A",
        "price": 25.39
    }, {
        "symbol": "SBV",
        "label": "iPath Pure Beta Broad Commodity ETN",
        "price": 22.55
    }, {
        "symbol": "SCA",
        "label": "Stellus Capital Investment Corporation 5.75% Notes due 2022",
        "price": 25.65
    }, {
        "symbol": "SCAC",
        "label": "Saban Capital Acquisition Corp.",
        "price": 10.28
    }, {
        "symbol": "SCACU",
        "label": "Saban Capital Acquisition Corp. Unit",
        "price": 10.53
    }, {
        "symbol": "SCACW",
        "label": "Saban Capital Acquisition Corp. Warrants",
        "price": 0.01
    }, {
        "symbol": "SCAP",
        "label": "AdvisorShares Cornerstone Small Cap",
        "price": 34.7799
    }, {
        "symbol": "SCC",
        "label": "ProShares UltraShort Consumer Services",
        "price": 16.71
    }, {
        "symbol": "SCD",
        "label": "LMP Capital and Income Fund Inc.",
        "price": 15.65
    }, {
        "symbol": "SCID",
        "label": "Global X Scientific Beta Europe",
        "price": 24.55
    }, {
        "symbol": "SCIJ",
        "label": "Global X Scientific Beta Japan",
        "price": 26.03
    }, {
        "symbol": "SCIU",
        "label": "Global X Scientific Beta US",
        "price": 31.16
    }, {
        "symbol": "SCIX",
        "label": "Global X Scientific Beta Asia ex-Japan",
        "price": 24.58
    }, {
        "symbol": "SCKT",
        "label": "Socket Mobile Inc.",
        "price": 1.71
    }, {
        "symbol": "SCTO",
        "label": "Global X JPMorgan US Sector Rotator Index",
        "price": 27.29
    }, {
        "symbol": "SCX",
        "label": "L.S. Starrett Company (The)",
        "price": 5.93
    }, {
        "symbol": "SDD",
        "label": "ProShares UltraShort SmallCap600",
        "price": 14.48
    }, {
        "symbol": "SDEM",
        "label": "Global X MSCI SuperDividend Emerging Markets",
        "price": 13.48
    }, {
        "symbol": "SDP",
        "label": "ProShares UltraShort Utilities",
        "price": 18.36
    }, {
        "symbol": "SDR",
        "label": "SandRidge Mississippian Trust II representing Beneficial Interests",
        "price": 0.226
    }, {
        "symbol": "SDT",
        "label": "SandRidge Mississippian Trust I of Beneficial Interest",
        "price": 0.170128
    }, {
        "symbol": "SDVY",
        "label": "First Trust SMID Cap Rising Dividend Achievers ETF",
        "price": 22.1025
    }, {
        "symbol": "SDYL",
        "label": "ETRACS Monthly Pay 2xLeveraged S&P Dividend ETN",
        "price": 85.43
    }, {
        "symbol": "SEA",
        "label": "Guggenheim Shipping",
        "price": 8.45
    }, {
        "symbol": "SECT",
        "label": "Northern Lights Fund Trust IV Main Sector Rotation",
        "price": 27.7
    }, {
        "symbol": "SEF",
        "label": "ProShares Short Financials",
        "price": 21.9
    }, {
        "symbol": "SELF",
        "label": "Global Self Storage Inc.",
        "price": 4.41
    }, {
        "symbol": "SENEB",
        "label": "Seneca Foods Corp. Class B Common Stock",
        "price": 39.89
    }, {
        "symbol": "SES",
        "label": "Synthesis Energy Systems Inc.",
        "price": 7.04
    }, {
        "symbol": "SFB",
        "label": "Stifel Financial Corporation 5.20% Senior Notes due 2047",
        "price": 26.2
    }, {
        "symbol": "SFBC",
        "label": "Sound Financial Bancorp Inc.",
        "price": 37.1
    }, {
        "symbol": "SFHY",
        "label": "WisdomTree Fundamental U.S. Short-Term High Yield Corporate Bond Fund",
        "price": 50.47
    }, {
        "symbol": "SFIG",
        "label": "WisdomTree Fundamental U.S. Short-Term Corporate Bond Fund",
        "price": 48.78
    }, {
        "symbol": "SFLA",
        "label": "iPath Long Extended S&P 500 TR Index ETN",
        "price": 214.83
    }, {
        "symbol": "SGA",
        "label": "Saga Communications Inc. New Class A",
        "price": 30.64
    }, {
        "symbol": "SGAR",
        "label": "iPath Pure Beta Sugar ETN",
        "price": 19.9618
    }, {
        "symbol": "SGB",
        "label": "Southwest Georgia Financial Corporation",
        "price": 34.45
    }, {
        "symbol": "SGDM",
        "label": "Sprott Gold Miners",
        "price": 19.25
    }, {
        "symbol": "SGGB",
        "label": "iPathA Series B Bloomberg Sugar Subindex Total Return ETN",
        "price": 43.41
    }, {
        "symbol": "SGLB",
        "label": "Sigma Labs Inc.",
        "price": 0.969
    }, {
        "symbol": "SGLBW",
        "label": "Sigma Labs Inc. Warrant",
        "price": 0.48
    }, {
        "symbol": "SGQI",
        "label": "Janus Henderson SG Global Quality Income ETF",
        "price": 27.48
    }, {
        "symbol": "SHAG",
        "label": "WisdomTree Barclays Yield Enhanced U.S. Short-Term Aggregate Bond Fund",
        "price": 49.67
    }, {
        "symbol": "SHE",
        "label": "SPDR Series Trust SSGA Gender Diversity Index",
        "price": 69.46
    }, {
        "symbol": "SHIP",
        "label": "Seanergy Maritime Holdings Corp",
        "price": 0.496
    }, {
        "symbol": "SHIPW",
        "label": "Seanergy Maritime Holdings Corp Class A Warrants",
        "price": 0.0089
    }, {
        "symbol": "SHNY",
        "label": "Direxion Funds",
        "price": 5.03
    }, {
        "symbol": "SHSP",
        "label": "SharpSpring Inc.",
        "price": 11.78
    }, {
        "symbol": "SHYL",
        "label": "Xtrackers Short Duration High Yield Bond",
        "price": 48.64
    }, {
        "symbol": "SIEB",
        "label": "Siebert Financial Corp.",
        "price": 8.45
    }, {
        "symbol": "SIF",
        "label": "SIFCO Industries Inc.",
        "price": 4.7351
    }, {
        "symbol": "SIFY",
        "label": "Sify Technologies Limited",
        "price": 1.2881
    }, {
        "symbol": "SIJ",
        "label": "ProShares UltraShort Industrials",
        "price": 14.18
    }, {
        "symbol": "SIM",
        "label": "Grupo Simec S.A.B. de C.V. American Depositary Shares",
        "price": 10.5
    }, {
        "symbol": "SIZ",
        "label": "AGFiQ U.S. Market Neutral Size Fund",
        "price": 19.66
    }, {
        "symbol": "SIZE",
        "label": "iShares Edge MSCI USA Size Factor",
        "price": 99.88
    }, {
        "symbol": "SKIS",
        "label": "Peak Resorts Inc.",
        "price": 3.87
    }, {
        "symbol": "SKOR",
        "label": "FlexShares Credit-Scored US Corporate Bond Index Fund",
        "price": 52.42
    }, {
        "symbol": "SLIM",
        "label": "The Obesity ETF",
        "price": 40.8679
    }, {
        "symbol": "SLMBP",
        "label": "SLM Corporation Floating Rate Non-Cumulative Preferred Stock Series B",
        "price": 56.2
    }, {
        "symbol": "SLNO",
        "label": "Soleno Therapeutics Inc.",
        "price": 2.85
    }, {
        "symbol": "SLVP",
        "label": "iShares MSCI Global Silver Miners Fund",
        "price": 7.86
    }, {
        "symbol": "SMCP",
        "label": "AlphaMark Actively Managed Small Cap ETF",
        "price": 25.37
    }, {
        "symbol": "SMDV",
        "label": "ProShares Russell 2000 Dividend Growers",
        "price": 58.44
    }, {
        "symbol": "SMED",
        "label": "Sharps Compliance Corp.",
        "price": 4.47
    }, {
        "symbol": "SMHD",
        "label": "ETRACS Monthly Pay 2xLeveraged US Small Cap High Dividend ETN due February 6 204" +
                "5",
        "price": 13.13
    }, {
        "symbol": "SMLF",
        "label": "iShares Edge MSCI Multifactor USA Small-Cap",
        "price": 43.94
    }, {
        "symbol": "SMLL",
        "label": "Direxion Daily Small Cap Bull 2X Shares",
        "price": 56.29
    }, {
        "symbol": "SMLV",
        "label": "SPDR SSGA US Small Cap Low Volatility Index",
        "price": 101.011
    }, {
        "symbol": "SMMD",
        "label": "iShares Trust Russell 2500",
        "price": 43.37
    }, {
        "symbol": "SMMU",
        "label": "Short Term Municipal Bond Active Exchange-Traded Fund",
        "price": 50.43
    }, {
        "symbol": "SMMV",
        "label": "iShares Trust",
        "price": 32.06
    }, {
        "symbol": "SMPLW",
        "label": "The Simply Good Foods Company Warrant",
        "price": 7.23
    }, {
        "symbol": "SMSI",
        "label": "Smith Micro Software Inc.",
        "price": 4.75
    }, {
        "symbol": "SMTS",
        "label": "Sierra Metals Inc.",
        "price": 1.73
    }, {
        "symbol": "SMTX",
        "label": "SMTC Corporation",
        "price": 3.85
    }, {
        "symbol": "SNDE",
        "label": "Sundance Energy Australia Limited",
        "price": 15.71
    }, {
        "symbol": "SNES",
        "label": "SenesTech Inc.",
        "price": 0.5799
    }, {
        "symbol": "SNFCA",
        "label": "Security National Financial Corporation Class A Common Stock",
        "price": 5.85
    }, {
        "symbol": "SNGXW",
        "label": "Soligenix Inc. Warrant",
        "price": 0.17
    }, {
        "symbol": "SNHNL",
        "label": "Senior Housing Properties Trust 6.25% Senior Notes Due 2046",
        "price": 25.87
    }, {
        "symbol": "SNMP",
        "label": "Sanchez Midstream Partners LP",
        "price": 0.4571
    }, {
        "symbol": "SNSR",
        "label": "Global X Internet of Things ETF",
        "price": 24.2
    }, {
        "symbol": "SOFO",
        "label": "Sonic Foundry Inc.",
        "price": 0.62
    }, {
        "symbol": "SOHOK",
        "label": "Sotherly Hotels LP 7.25% Senior Unsecured Notes Due 2021",
        "price": 25.2
    }, {
        "symbol": "SOHOO",
        "label": "Sotherly Hotels Inc. 7.875% Series C Cumulative Redeemable Perpetual Preferred S" +
                "tock",
        "price": 26.18
    }, {
        "symbol": "SOIL",
        "label": "Global X Fertilizers/Potash",
        "price": 8.99
    }, {
        "symbol": "SOL",
        "label": "Renesola Ltd. ADR",
        "price": 1.4
    }, {
        "symbol": "SOVB",
        "label": "Cambria Sovereign Bond",
        "price": 25.5
    }, {
        "symbol": "SOYB",
        "label": "Teucrium Soybean Fund ETV",
        "price": 15.51
    }, {
        "symbol": "SPCB",
        "label": "SuperCom Ltd.",
        "price": 0.7148
    }, {
        "symbol": "SPDN",
        "label": "Direxion Daily S&P 500 Bear 1X Shares",
        "price": 29.59
    }, {
        "symbol": "SPDV",
        "label": "AAM S&P 500 High Dividend Value",
        "price": 25.26
    }, {
        "symbol": "SPE",
        "label": "Special Opportunities Fund Inc",
        "price": 14.82
    }, {
        "symbol": "SPI",
        "label": "SPI Energy Co. Ltd.",
        "price": 2.05
    }, {
        "symbol": "SPIL",
        "label": "Siliconware Precision Industries Company Ltd.",
        "price": 8.6
    }, {
        "symbol": "SPLX",
        "label": "ETRACS Monthly Reset 2xLeveraged S&P 500 Total Return ETN",
        "price": 57.48
    }, {
        "symbol": "SPMO",
        "label": "PowerShares S&P 500 Momentum Portfolio",
        "price": 38.92
    }, {
        "symbol": "SPMV",
        "label": "PowerShares S&P 500 Minimum Variance Portfolio",
        "price": 27.75
    }, {
        "symbol": "SPPP",
        "label": "Sprott Physical Platinum and Palladium Trust",
        "price": 10.23
    }, {
        "symbol": "SPRO",
        "label": "Spero Therapeutics Inc.",
        "price": 8.89
    }, {
        "symbol": "SPTS",
        "label": "SPDR Portfolio Short Term Treasury",
        "price": 29.98
    }, {
        "symbol": "SPUN",
        "label": "VanEck Vectors Spin-Off",
        "price": 19.71
    }, {
        "symbol": "SPUU",
        "label": "Direxion Daily S&P 500 Bull 2X Shares",
        "price": 49.54
    }, {
        "symbol": "SPVM",
        "label": "PowerShares S&P 500 Value With Momentum Portfolio",
        "price": 43.6313
    }, {
        "symbol": "SPVU",
        "label": "PowerShares S&P 500 Enhanced Value Portfolio",
        "price": 38.7949
    }, {
        "symbol": "SPXE",
        "label": "ProShares S&P 500 Ex-Energy",
        "price": 71.0294
    }, {
        "symbol": "SPXN",
        "label": "ProShares S&P 500 Ex-Financials",
        "price": 68.8524
    }, {
        "symbol": "SPXT",
        "label": "ProShares S&P 500 Ex-Technology",
        "price": 54.15
    }, {
        "symbol": "SPXV",
        "label": "ProShares S&P 500 Ex-Health Care",
        "price": 69.4571
    }, {
        "symbol": "SPYX",
        "label": "SPDR S&P 500 Fossil Fuel Reserves Free",
        "price": 81.4739
    }, {
        "symbol": "SQLV",
        "label": "Legg Mason Small-Cap Quality Value ETF",
        "price": 28.2017
    }, {
        "symbol": "SQZZ",
        "label": "Active Alts Contrarian ETF",
        "price": 25.61
    }, {
        "symbol": "SRCLP",
        "label": "Stericycle Inc. Depository Receipt",
        "price": 45.34
    }, {
        "symbol": "SRET",
        "label": "Global X SuperDividend REIT ETF",
        "price": 15.62
    }, {
        "symbol": "SRF",
        "label": "Cushing Energy Income Fund of Beneficial Interest",
        "price": 6.7004
    }, {
        "symbol": "SRTS",
        "label": "Sensus Healthcare Inc.",
        "price": 4.23
    }, {
        "symbol": "SRTSW",
        "label": "Sensus Healthcare Inc. Warrant",
        "price": 0.12
    }, {
        "symbol": "SSBI",
        "label": "Summit State Bank",
        "price": 12.8346
    }, {
        "symbol": "SSFN",
        "label": "Stewardship Financial Corp",
        "price": 8.83
    }, {
        "symbol": "SSKN",
        "label": "Strata Skin Sciences Inc.",
        "price": 2.18
    }, {
        "symbol": "SSNT",
        "label": "SilverSun Technologies Inc.",
        "price": 3.22
    }, {
        "symbol": "SSWA",
        "label": "Seaspan Corporation 7.125% Notes due 2027",
        "price": 25.75
    }, {
        "symbol": "SSY",
        "label": "SunLink Health Systems Inc.",
        "price": 1.1272
    }, {
        "symbol": "STDY",
        "label": "SteadyMed Ltd.",
        "price": 4.73
    }, {
        "symbol": "STLR",
        "label": "Stellar Acquisition III Inc.",
        "price": 9.61
    }, {
        "symbol": "STLRU",
        "label": "Stellar Acquisition III Inc. Unit",
        "price": 10.8
    }, {
        "symbol": "STLRW",
        "label": "Stellar Acquisition III Inc. Warrants",
        "price": 0.2
    }, {
        "symbol": "STNL",
        "label": "Sentinel Energy Services Inc.",
        "price": 10.31
    }, {
        "symbol": "STNLU",
        "label": "Sentinel Energy Services Inc. Unit",
        "price": 10.31
    }, {
        "symbol": "STNLW",
        "label": "Sentinel Energy Services Inc. Warrant",
        "price": 0.0044
    }, {
        "symbol": "STOT",
        "label": "SPDR DoubleLine Short Duration Total Return Tactical",
        "price": 49.44
    }, {
        "symbol": "STPP",
        "label": "iPath US Treasury Steepener ETN",
        "price": 26.054
    }, {
        "symbol": "SUB",
        "label": "iShares Short-Term National Muni Bond",
        "price": 106.41
    }, {
        "symbol": "SUMR",
        "label": "Summer Infant Inc.",
        "price": 0.2911
    }, {
        "symbol": "SUNW",
        "label": "Sunworks Inc.",
        "price": 1.14
    }, {
        "symbol": "SUSA",
        "label": "iShares MSCI USA ESG Select",
        "price": 116.38
    }, {
        "symbol": "SUSB",
        "label": "iShares ESG 1-5 Year USD Corporate Bond ETF",
        "price": 25.5126
    }, {
        "symbol": "SUSC",
        "label": "iShares ESG USD Corporate Bond ETF",
        "price": 26.695
    }, {
        "symbol": "SVBI",
        "label": "Severn Bancorp Inc",
        "price": 9.0
    }, {
        "symbol": "SVT",
        "label": "Servotronics Inc.",
        "price": 10.45
    }, {
        "symbol": "SWIN",
        "label": "ALPS/Dorsey Wright Sector Momentum ETF",
        "price": 32.96
    }, {
        "symbol": "SWJ",
        "label": "Stanley Black & Decker Inc. 5.75% Junior Subordinated Debenture due 2052",
        "price": 25.35
    }, {
        "symbol": "SWZ",
        "label": "Swiss Helvetia Fund Inc. (The)",
        "price": 8.633
    }, {
        "symbol": "SXI",
        "label": "Standex International Corporation",
        "price": 79.39
    }, {
        "symbol": "SYG",
        "label": "SPDR MFS Systematic Growth Equity",
        "price": 70.97
    }, {
        "symbol": "SYPR",
        "label": "Sypris Solutions Inc.",
        "price": 1.0
    }, {
        "symbol": "SYV",
        "label": "SPDR MFS Systematic Value Equity",
        "price": 72.9143
    }, {
        "symbol": "SZK",
        "label": "ProShares UltraShort Consumer Goods",
        "price": 13.93
    }, {
        "symbol": "SZO",
        "label": "DB Crude Oil Short ETN due June 1 2038",
        "price": 72.26
    }, {
        "symbol": "TACOW",
        "label": "Del Taco Restaurants Inc. Warrants",
        "price": 0.1
    }, {
        "symbol": "TACT",
        "label": "TransAct Technologies Incorporated",
        "price": 10.76
    }, {
        "symbol": "TAGS",
        "label": "Teucrium Agricultural Fund ETV",
        "price": 19.31
    }, {
        "symbol": "TAIT",
        "label": "Taitron Components Incorporated",
        "price": 2.81
    }, {
        "symbol": "TAO",
        "label": "Guggenheim China Real Estate",
        "price": 28.075
    }, {
        "symbol": "TAPR",
        "label": "Barclays Inverse US Treasury Composite ETN",
        "price": 12.9963
    }, {
        "symbol": "TATT",
        "label": "TAT Technologies Ltd.",
        "price": 5.23
    }, {
        "symbol": "TAXR",
        "label": "U.S. Tax Reform Fund",
        "price": 20.565
    }, {
        "symbol": "TAYD",
        "label": "Taylor Devices Inc.",
        "price": 12.3
    }, {
        "symbol": "TBLU",
        "label": "Managed Portfolio Series Tortoise Water Fund",
        "price": 29.85
    }, {
        "symbol": "TBX",
        "label": "ProShares Short 7 10 Year Treasury",
        "price": 27.19
    }, {
        "symbol": "TCBIL",
        "label": "Texas Capital Bancshares Inc. 6.50% Subordinated Notes due 2042",
        "price": 26.18
    }, {
        "symbol": "TCBIP",
        "label": "Texas Capital Bancshares Inc. Non Cumulative Preferred Perpetual Stock Series A",
        "price": 26.08
    }, {
        "symbol": "TCBIW",
        "label": "Texas Capital Bancshares Inc. Warrants 01/16/2019",
        "price": 68.2636
    }, {
        "symbol": "TCCA",
        "label": "Triangle Capital Corporation 6.375% Senior Notes due 2022",
        "price": 25.1
    }, {
        "symbol": "TCCO",
        "label": "Technical Communications Corporation",
        "price": 5.3
    }, {
        "symbol": "TCRZ",
        "label": "THL Credit Inc. 6.75% Notes due 2022",
        "price": 25.37
    }, {
        "symbol": "TCTL",
        "label": "Premise Capital Frontier Advantage Diversified Tactical",
        "price": 30.6
    }, {
        "symbol": "TDI",
        "label": "Telephone and Data Systems Inc. Sr Nt",
        "price": 28.0
    }, {
        "symbol": "TDJ",
        "label": "Telephone and Data Systems Inc. 7% Senior Notes due 2060",
        "price": 25.82
    }, {
        "symbol": "TDTF",
        "label": "FlexShares iBoxx 5 Year Target Duration TIPS Index Fund",
        "price": 25.01
    }, {
        "symbol": "TERM",
        "label": "EquityCompass Tactical Risk Manager",
        "price": 17.51
    }, {
        "symbol": "TFLO",
        "label": "iShares Treasury Floating Rate Bond",
        "price": 50.36
    }, {
        "symbol": "TGC",
        "label": "Tengasco Inc.",
        "price": 0.5821
    }, {
        "symbol": "THGA",
        "label": "The Hanover Insurance Group Inc. 6.35% Subordinated Debentures due 2053",
        "price": 26.05
    }, {
        "symbol": "THST",
        "label": "Truett-Hurst Inc.",
        "price": 1.49
    }, {
        "symbol": "TIBRU",
        "label": "Tiberius Acquisition Corporation Units",
        "price": 11.64
    }, {
        "symbol": "TIK",
        "label": "Tel-Instrument Electronics Corp.",
        "price": 3.56
    }, {
        "symbol": "TIL",
        "label": "Till Capital Ltd.",
        "price": 4.04
    }, {
        "symbol": "TIPZ",
        "label": "PIMCO Broad U.S. TIPS Index Exchange-Traded Fund",
        "price": 57.53
    }, {
        "symbol": "TISA",
        "label": "Top Image Systems Ltd.",
        "price": 0.85
    }, {
        "symbol": "TKAT",
        "label": "Takung Art Co. Ltd.",
        "price": 0.69
    }, {
        "symbol": "TLDH",
        "label": "FlexShares Currency Hedged Morningstar DM ex-US Factor Tilt Index Fund",
        "price": 29.4245
    }, {
        "symbol": "TLEH",
        "label": "FlexShares Currency Hedged Morningstar EM Factor Tilt Index Fund",
        "price": 28.84
    }, {
        "symbol": "TLTD",
        "label": "FlexShares Morningstar Developed Markets ex-US Factor Tilt Index Fund",
        "price": 59.56
    }, {
        "symbol": "TMFC",
        "label": "Motley Fool 100 Index ETF",
        "price": 22.26
    }, {
        "symbol": "TMQ",
        "label": "Trilogy Metals Inc.",
        "price": 2.51
    }, {
        "symbol": "TMSR",
        "label": "TMSR Holding Company Limited",
        "price": 1.5
    }, {
        "symbol": "TMSRW",
        "label": "TMSR Holding Company Limited Warrants",
        "price": 0.444
    }, {
        "symbol": "TOK",
        "label": "iShares MSCI Kokusai",
        "price": 75.6496
    }, {
        "symbol": "TOLZ",
        "label": "ProShares DJ Brookfield Global Infrastructure",
        "price": 43.81
    }, {
        "symbol": "TPGH",
        "label": "TPG Pace Holdings Corp. Class A",
        "price": 10.5
    }, {
        "symbol": "TPIV",
        "label": "TapImmune Inc.",
        "price": 8.22
    }, {
        "symbol": "TPVY",
        "label": "TriplePoint Venture Growth BDC Corp. 5.75% Notes due 2022",
        "price": 25.74
    }, {
        "symbol": "TPYP",
        "label": "Tortoise North American Pipeline",
        "price": 23.89
    }, {
        "symbol": "TPZ",
        "label": "Tortoise Power and Energy Infrastructure Fund Inc",
        "price": 17.4202
    }, {
        "symbol": "TRCB",
        "label": "Two River Bancorp",
        "price": 22.4
    }, {
        "symbol": "TRCH",
        "label": "Torchlight Energy Resources Inc.",
        "price": 0.655
    }, {
        "symbol": "TRIL",
        "label": "Trillium Therapeutics Inc.",
        "price": 2.67
    }, {
        "symbol": "TRMD",
        "label": "TORM plc",
        "price": 10.25
    }, {
        "symbol": "TRMT",
        "label": "Tremont Mortgage Trust",
        "price": 6.0
    }, {
        "symbol": "TRNS",
        "label": "Transcat Inc.",
        "price": 32.37
    }, {
        "symbol": "TRPX",
        "label": "Therapix Biosciences Ltd.",
        "price": 0.78
    }, {
        "symbol": "TRX",
        "label": "Tanzanian Royalty Exploration Corporation",
        "price": 0.5431
    }, {
        "symbol": "TSRI",
        "label": "TSR Inc.",
        "price": 4.39
    }, {
        "symbol": "TTAC",
        "label": "TrimTabs All Cap U.S. Free-Cash-Flow",
        "price": 34.89
    }, {
        "symbol": "TTAI",
        "label": "TRIMTABS ETF TRUST",
        "price": 25.49
    }, {
        "symbol": "TTFS",
        "label": "AdvisorShares Wilshire Buyback",
        "price": 70.26
    }, {
        "symbol": "TURN",
        "label": "180 Degree Capital Corp.",
        "price": 2.16
    }, {
        "symbol": "TUSA",
        "label": "First Trust Total US Market AlphaDEX ETF",
        "price": 36.785
    }, {
        "symbol": "TUZ",
        "label": "PIMCO 1-3 Year US Treasury Index Exchage-Traded Fund",
        "price": 50.6
    }, {
        "symbol": "TVE",
        "label": "Tennessee Valley Authority",
        "price": 25.8892
    }, {
        "symbol": "TVIZ",
        "label": "VelocityShares Daily 2x VIX Medium Term ETN",
        "price": 8.27
    }, {
        "symbol": "TWN",
        "label": "Taiwan Fund Inc. (The)",
        "price": 21.6499
    }, {
        "symbol": "TY",
        "label": "Tri Continental Corporation",
        "price": 28.83
    }, {
        "symbol": "TYBS",
        "label": "Direxion Daily 20 Year Treasury Bear 1X Shares",
        "price": 19.59
    }, {
        "symbol": "TYD",
        "label": "Direxion Daily 10-Yr Treasury Bull 3x Shrs",
        "price": 49.7
    }, {
        "symbol": "TYNS",
        "label": "Direxion Daily 7 10 Year Treasury Bear 1X Shares",
        "price": 28.18
    }, {
        "symbol": "TYO",
        "label": "Direxion Daily 10-Yr Treasury Bear 3x Shrs",
        "price": 11.77
    }, {
        "symbol": "TZOO",
        "label": "Travelzoo",
        "price": 10.91
    }, {
        "symbol": "UAE",
        "label": "iShares MSCI UAE ETF",
        "price": 13.685
    }, {
        "symbol": "UAG",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI A" +
                "griculture ETN",
        "price": 16.03
    }, {
        "symbol": "UAMY",
        "label": "United States Antimony Corporation",
        "price": 0.4
    }, {
        "symbol": "UAUD",
        "label": "ETNs linked to the VelocityShares Daily 4X Long AUD vs. USD Index",
        "price": 23.88
    }, {
        "symbol": "UBC",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI L" +
                "ivestock ETN",
        "price": 18.55
    }, {
        "symbol": "UBCP",
        "label": "United Bancorp Inc.",
        "price": 14.35
    }, {
        "symbol": "UBG",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI G" +
                "old ETN",
        "price": 32.92
    }, {
        "symbol": "UBM",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI I" +
                "ndustrial ETN",
        "price": 17.13
    }, {
        "symbol": "UBN",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI E" +
                "nergy ETN",
        "price": 9.54
    }, {
        "symbol": "UBOH",
        "label": "United Bancshares Inc.",
        "price": 22.75
    }, {
        "symbol": "UBP",
        "label": "Urstadt Biddle Properties Inc.",
        "price": 19.1161
    }, {
        "symbol": "UBR",
        "label": "ProShares Ultra MSCI Brazil Capped",
        "price": 70.79
    }, {
        "symbol": "UBRT",
        "label": "Credit Suisse AxelaTrader 3x Long Brent Crude Oil ETN",
        "price": 247.88
    }, {
        "symbol": "UCC",
        "label": "ProShares Ultra Consumer Services",
        "price": 99.23
    }, {
        "symbol": "UCHF",
        "label": "ETNs linked to the VelocityShares Daily 4X Long CHF vs. USD Index",
        "price": 21.98
    }, {
        "symbol": "UCIB",
        "label": "ETRACS UBS Bloomberg Constant Maturity Commodity Index (CMCI) Total Return ETN S" +
                "eries B due April 5 2038",
        "price": 15.45
    }, {
        "symbol": "UDBI",
        "label": "Legg Mason US Diversified Core ETF",
        "price": 30.98
    }, {
        "symbol": "UDN",
        "label": "PowerShares DB USD Index Bearish",
        "price": 20.53
    }, {
        "symbol": "UEUR",
        "label": "ETNs linked to the VelocityShares Daily 4X Long EUR vs. USD Index",
        "price": 18.91
    }, {
        "symbol": "UEVM",
        "label": "USAA MSCI Emerging Markets Value Momentum Blend Index",
        "price": 44.52
    }, {
        "symbol": "UGBP",
        "label": "ETNs linked to the VelocityShares Daily 4X Long GBP vs. USD Index",
        "price": 17.58
    }, {
        "symbol": "UGE",
        "label": "ProShares Ultra Consumer Goods",
        "price": 41.39
    }, {
        "symbol": "UHN",
        "label": "United States Diesel Heating Oil Fund LP",
        "price": 20.93
    }, {
        "symbol": "UITB",
        "label": "USAA Core Intermediate-Term Bond",
        "price": 50.57
    }, {
        "symbol": "UIVM",
        "label": "USAA MSCI International Value Momentum Blend Index",
        "price": 43.88
    }, {
        "symbol": "UJB",
        "label": "ProShares Ultra High Yield",
        "price": 65.36
    }, {
        "symbol": "UJPY",
        "label": "ETNs linked to the VelocityShares Daily 4X Long JPY vs. USD Index",
        "price": 22.31
    }, {
        "symbol": "ULBR",
        "label": "Citigroup Global Markets Holdings Inc VelocityShares Long LIBOR ETN",
        "price": 30.14
    }, {
        "symbol": "ULE",
        "label": "ProShares Ultra Euro",
        "price": 13.96
    }, {
        "symbol": "ULST",
        "label": "SPDR SSgA Ultra Short Term Bond",
        "price": 40.39
    }, {
        "symbol": "ULVM",
        "label": "USAA MSCI USA Value Momentum Blend Index",
        "price": 49.4
    }, {
        "symbol": "UMDD",
        "label": "UltraPro MidCap400",
        "price": 95.69
    }, {
        "symbol": "UNAM",
        "label": "Unico American Corporation",
        "price": 7.0749
    }, {
        "symbol": "UNB",
        "label": "Union Bankshares Inc.",
        "price": 36.48
    }, {
        "symbol": "UNL",
        "label": "United States 12 Month Natural Gas Fund",
        "price": 9.96
    }, {
        "symbol": "UNTY",
        "label": "Unity Bancorp Inc.",
        "price": 21.35
    }, {
        "symbol": "UONE",
        "label": "Urban One Inc.",
        "price": 2.07
    }, {
        "symbol": "UONEK",
        "label": "Urban One Inc.",
        "price": 1.92
    }, {
        "symbol": "UPV",
        "label": "ProShares Ultra FTSE Europe",
        "price": 44.85
    }, {
        "symbol": "UPW",
        "label": "ProShares Ultra Utilities",
        "price": 56.67
    }, {
        "symbol": "URR",
        "label": "Market Vectors Double Long Euro ETN",
        "price": 15.72
    }, {
        "symbol": "URTH",
        "label": "Ishares MSCI World Index Fund",
        "price": 101.16
    }, {
        "symbol": "USAG",
        "label": "United States Agriculture Index Fund ETV",
        "price": 15.9
    }, {
        "symbol": "USAI",
        "label": "American Energy Independence",
        "price": 25.16
    }, {
        "symbol": "USAP",
        "label": "Universal Stainless & Alloy Products Inc.",
        "price": 14.0
    }, {
        "symbol": "USAS",
        "label": "Americas Silver Corporation no par value",
        "price": 2.91
    }, {
        "symbol": "USATP",
        "label": "USA Technologies Inc. Preferred Stock",
        "price": 29.15
    }, {
        "symbol": "USDP",
        "label": "USD Partners LP representing limited partner interest",
        "price": 10.41
    }, {
        "symbol": "USDY",
        "label": "Horizons Cadence Hedged US Dividend Yield",
        "price": 25.85
    }, {
        "symbol": "USEQ",
        "label": "PowerShares Russell 1000 Enhanced Equal Weight Portfolio",
        "price": 27.5
    }, {
        "symbol": "USFR",
        "label": "WisdomTree Bloomberg Floating Rate Treasury Fund",
        "price": 25.11
    }, {
        "symbol": "USHY",
        "label": "iShares Broad USD High Yield Corporate Bond",
        "price": 40.13
    }, {
        "symbol": "USL",
        "label": "United States 12 Month Oil",
        "price": 19.42
    }, {
        "symbol": "USLB",
        "label": "PowerShares Russell 1000 Low Beta Equal Weight Portfolio",
        "price": 35.535
    }, {
        "symbol": "USLM",
        "label": "United States Lime & Minerals Inc.",
        "price": 94.45
    }, {
        "symbol": "USMC",
        "label": "Principal U.S. Mega-Cap Multi-Factor Index ETF",
        "price": 32.2706
    }, {
        "symbol": "USMF",
        "label": "WisdomTree U.S. Multifactor Fund",
        "price": 29.04
    }, {
        "symbol": "USOD",
        "label": "United States 3x Short Oil Fund",
        "price": 7.18
    }, {
        "symbol": "USOI",
        "label": "Credit Suisse X-Links Crude Oil Call ETN IOPV",
        "price": 20.7528
    }, {
        "symbol": "USOU",
        "label": "United States 3x Oil Fund",
        "price": 20.93
    }, {
        "symbol": "UST",
        "label": "ProShares Ultra 7-10 Year Treasury",
        "price": 61.47
    }, {
        "symbol": "USTB",
        "label": "USAA Core Short-Term Bond",
        "price": 49.56
    }, {
        "symbol": "USV",
        "label": "E-TRACS USB Bloomberg Commodity Index Exchange Traded Notes UBS Bloomberg CMCI S" +
                "ilver ETN",
        "price": 20.19
    }, {
        "symbol": "USVM",
        "label": "USAA MSCI USA Small Cap Value Momentum Blend Index",
        "price": 51.52
    }, {
        "symbol": "UTES",
        "label": "ETFIS Series Trust I Reaves Utilities",
        "price": 42.2051
    }, {
        "symbol": "UTLF",
        "label": "iShares Edge MSCI Multifactor Utilities",
        "price": 24.6601
    }, {
        "symbol": "UTSL",
        "label": "Direxion Daily Utilities Bull 3X Shares",
        "price": 36.55
    }, {
        "symbol": "UXI",
        "label": "ProShares Ultra Industrials",
        "price": 70.11
    }, {
        "symbol": "UYM",
        "label": "ProShares Ultra Basic Materials",
        "price": 53.46
    }, {
        "symbol": "UZC",
        "label": "United States Cellular Corporation 7.25% Senior Notes due 2064",
        "price": 26.33
    }, {
        "symbol": "VALQ",
        "label": "American Century STOXX U.S. Quality Value",
        "price": 40.23
    }, {
        "symbol": "VALX",
        "label": "Validea Market Legends ETF",
        "price": 27.715
    }, {
        "symbol": "VBF",
        "label": "Invesco Bond Fund",
        "price": 20.0
    }, {
        "symbol": "VBFC",
        "label": "Village Bank and Trust Financial Corp.",
        "price": 42.0
    }, {
        "symbol": "VBND",
        "label": "ETF Series Solutions Trust Vident Core U.S. Bond Strategy Fund",
        "price": 50.73
    }, {
        "symbol": "VCF",
        "label": "Delaware Investments Colorado Municipal Income Fund Inc",
        "price": 15.308
    }, {
        "symbol": "VEAC",
        "label": "Vantage Energy Acquisition Corp.",
        "price": 10.21
    }, {
        "symbol": "VEACU",
        "label": "Vantage Energy Acquisition Corp. Unit",
        "price": 10.21
    }, {
        "symbol": "VEACW",
        "label": "Vantage Energy Acquisition Corp. Warrant",
        "price": 0.01
    }, {
        "symbol": "VEGA",
        "label": "AdvisorShares STAR Global Buy-Write",
        "price": 34.89
    }, {
        "symbol": "VEGI",
        "label": "iShares MSCI Agriculture Producers Fund",
        "price": 28.999
    }, {
        "symbol": "VESH",
        "label": "Virtus Enhanced Short U.S. Equity",
        "price": 22.0
    }, {
        "symbol": "VFL",
        "label": "Delaware Investments National Municipal Income Fund",
        "price": 13.54
    }, {
        "symbol": "VFLQ",
        "label": "Vanguard U.S. Liquidity Factor",
        "price": 74.54
    }, {
        "symbol": "VFMF",
        "label": "Vanguard U.S. Multifactor",
        "price": 73.75
    }, {
        "symbol": "VFMO",
        "label": "Vanguard U.S. Momentum Factor",
        "price": 78.48
    }, {
        "symbol": "VFMV",
        "label": "Vanguard U.S. Minimum Volatility",
        "price": 83.47
    }, {
        "symbol": "VFQY",
        "label": "Vanguard U.S. Quality Factor",
        "price": 81.92
    }, {
        "symbol": "VFVA",
        "label": "Vanguard U.S. Value Factor",
        "price": 76.19
    }, {
        "symbol": "VGFO",
        "label": "Virtus WMC Global Factor Opportunities",
        "price": 26.7801
    }, {
        "symbol": "VGLT",
        "label": "Vanguard Long-Treasury ETF",
        "price": 84.7
    }, {
        "symbol": "VIDI",
        "label": "ETF Series Solutions Trust Vident International Equity Fund",
        "price": 25.8
    }, {
        "symbol": "VII",
        "label": "Vicon Industries Inc",
        "price": 0.19
    }, {
        "symbol": "VIIX",
        "label": "VelocityShares VIX Short Term ETN",
        "price": 28.23
    }, {
        "symbol": "VIIZ",
        "label": "VelocityShares VIX Medium Term ETN",
        "price": 62.37
    }, {
        "symbol": "VIOO",
        "label": "Vanguard S&P Small-Cap 600",
        "price": 155.11
    }, {
        "symbol": "VIOV",
        "label": "Vanguard S&P Small-Cap 600 Value",
        "price": 139.628
    }, {
        "symbol": "VIRC",
        "label": "Virco Manufacturing Corporation",
        "price": 4.27
    }, {
        "symbol": "VLT",
        "label": "Invesco High Income Trust II",
        "price": 14.62
    }, {
        "symbol": "VLU",
        "label": "SPDR S&P 1500 Value Tilt",
        "price": 115.278
    }, {
        "symbol": "VMM",
        "label": "Delaware Investments Minnesota Municipal Income Fund II Inc.",
        "price": 13.35
    }, {
        "symbol": "VMOT",
        "label": "Alpha Architect Value Momentum Trend",
        "price": 23.67
    }, {
        "symbol": "VNCE",
        "label": "Vince Holding Corp.",
        "price": 17.13
    }, {
        "symbol": "VNLA",
        "label": "Janus Henderson Short Duration Income",
        "price": 49.75
    }, {
        "symbol": "VONE",
        "label": "Vanguard Russell 1000 ETF",
        "price": 152.34
    }, {
        "symbol": "VONG",
        "label": "Vanguard Russell 1000 Growth ETF",
        "price": 189.64
    }, {
        "symbol": "VOOG",
        "label": "Vanguard S&P 500 Growth",
        "price": 182.79
    }, {
        "symbol": "VOOV",
        "label": "Vanguard S&P 500 Value",
        "price": 107.42
    }, {
        "symbol": "VPU",
        "label": "Vanguard Utilities",
        "price": 147.35
    }, {
        "symbol": "VQT",
        "label": "Barclays ETN S&P VEQTOR ETN",
        "price": 162.54
    }, {
        "symbol": "VRNA",
        "label": "Verona Pharma plc",
        "price": 5.75
    }, {
        "symbol": "VRTSP",
        "label": "Virtus Investment Partners Inc. 7.25% Series D Mandatory Convertible Preferred S" +
                "tock",
        "price": 101.18
    }, {
        "symbol": "VSDA",
        "label": "VictoryShares Dividend Accelerator ETF",
        "price": 36.52
    }, {
        "symbol": "VSL",
        "label": "Volshares Large Cap",
        "price": 27.65
    }, {
        "symbol": "VTC",
        "label": "Vanguard Total Corporate Bond ETF",
        "price": 89.2493
    }, {
        "symbol": "VTGN",
        "label": "VistaGen Therapeutics Inc.",
        "price": 0.73
    }, {
        "symbol": "VTNR",
        "label": "Vertex Energy Inc",
        "price": 1.79
    }, {
        "symbol": "VTWG",
        "label": "Vanguard Russell 2000 Growth ETF",
        "price": 161.14
    }, {
        "symbol": "VTWO",
        "label": "Vanguard Russell 2000 ETF",
        "price": 135.67
    }, {
        "symbol": "VVUS",
        "label": "VIVUS Inc.",
        "price": 2.72
    }, {
        "symbol": "VXRT",
        "label": "Vaxart Inc.",
        "price": 0.39
    }, {
        "symbol": "VXXB",
        "label": "iPath Series B S&P 500 VIX Short-Term Futures ETN",
        "price": 27.03
    }, {
        "symbol": "VXZB",
        "label": "iPath Series B S&P 500 VIX Mid-Term Futures ETN",
        "price": 17.94
    }, {
        "symbol": "VYMI",
        "label": "Vanguard International High Dividend Yield ETF",
        "price": 64.31
    }, {
        "symbol": "VZA",
        "label": "Verizon Communications Inc. 5.90% Notes due 2054",
        "price": 25.7
    }, {
        "symbol": "WAFDW",
        "label": "Washington Federal Inc. Warrants 11/14/2018",
        "price": 19.99
    }, {
        "symbol": "WALA",
        "label": "Western Alliance Bancorporation 6.25% Subordinated Debentures due 2056",
        "price": 26.18
    }, {
        "symbol": "WBAL",
        "label": "WisdomTree Balanced Income Fund",
        "price": 23.72
    }, {
        "symbol": "WBIA",
        "label": "WBI Tactical SMGD Shares",
        "price": 21.95
    }, {
        "symbol": "WBIB",
        "label": "WBI Tactical SMV Shares",
        "price": 21.81
    }, {
        "symbol": "WBIC",
        "label": "WBI Tactical SMY Shares",
        "price": 18.43
    }, {
        "symbol": "WBID",
        "label": "WBI Tactical SMQ Shares",
        "price": 20.94
    }, {
        "symbol": "WBIE",
        "label": "WBI Tactical LCGD Shares",
        "price": 25.22
    }, {
        "symbol": "WBIF",
        "label": "WBI Tactical LCV Shares",
        "price": 26.67
    }, {
        "symbol": "WBIG",
        "label": "WBI Tactical LCY Shares",
        "price": 24.45
    }, {
        "symbol": "WBIH",
        "label": "WBI Tactical High Income Shares",
        "price": 21.61
    }, {
        "symbol": "WBII",
        "label": "WBI Tactical Income Shares",
        "price": 24.62
    }, {
        "symbol": "WBIL",
        "label": "WBI Tactical LCQ Shares",
        "price": 26.12
    }, {
        "symbol": "WBIR",
        "label": "WBI",
        "price": 22.81
    }, {
        "symbol": "WBIY",
        "label": "WBI Power Factor High Dividend",
        "price": 23.88
    }, {
        "symbol": "WCFB",
        "label": "WCF Bancorp Inc.",
        "price": 8.29
    }, {
        "symbol": "WCHN",
        "label": "WisdomTree ICBCCS S&P China 500 Fund",
        "price": 26.63
    }, {
        "symbol": "WDRW",
        "label": "Direxion Daily Regional Banks Bear 3X Shares",
        "price": 30.2
    }, {
        "symbol": "WEA",
        "label": "Western Asset Bond Fund Share of Beneficial Interest",
        "price": 14.57
    }, {
        "symbol": "WEAR",
        "label": "Exchange Listed Funds Trust ETF The WEAR",
        "price": 20.68
    }, {
        "symbol": "WEBK",
        "label": "Wellesley Bancorp Inc.",
        "price": 43.25
    }, {
        "symbol": "WEET",
        "label": "iPath Pure Beta Grains ETN",
        "price": 32.55
    }, {
        "symbol": "WFHY",
        "label": "WisdomTree Fundamental U.S. High Yield Corporate Bond Fund",
        "price": 50.51
    }, {
        "symbol": "WFIG",
        "label": "WisdomTree Fundamental U.S. Corporate Bond Fund",
        "price": 47.99
    }, {
        "symbol": "WHFBL",
        "label": "WhiteHorse Finance Inc. 6.50% Senior Notes due 2020",
        "price": 25.16
    }, {
        "symbol": "WHLM",
        "label": "Wilhelmina International Inc.",
        "price": 4.0
    }, {
        "symbol": "WHLRD",
        "label": "Wheeler Real Estate Investment Trust Inc. Series D Cumulative Preferred Stock",
        "price": 15.01
    }, {
        "symbol": "WHLRW",
        "label": "Wheeler Real Estate Investment Trust Inc. Warrants",
        "price": 0.0472
    }, {
        "symbol": "WIL",
        "label": "Barclays PLC Women in Leadership ETN",
        "price": 64.4242
    }, {
        "symbol": "WILC",
        "label": "G. Willi-Food International  Ltd.",
        "price": 12.16
    }, {
        "symbol": "WINA",
        "label": "Winmark Corporation",
        "price": 200.58
    }, {
        "symbol": "WINS",
        "label": "Wins Finance Holdings Inc.",
        "price": 13.0
    }, {
        "symbol": "WLDR",
        "label": "Affinity World Leaders Equity",
        "price": 21.45
    }, {
        "symbol": "WMCR",
        "label": "Wilshire Micro-Cap ETF (based on the US Micro-Cap Index)",
        "price": 32.76
    }, {
        "symbol": "WMGIZ",
        "label": "Wright Medical Group N.V. Contingent Value Right",
        "price": 0.01
    }, {
        "symbol": "WMLP",
        "label": "Westmoreland Resource Partners LP representing Limited Partner Interests",
        "price": 2.09
    }, {
        "symbol": "WMW",
        "label": "DEUTSCHE BANK AKTIENGESELLSCHAFT ELEMENTS Linked to the Morningstar Wide Moat Fo" +
                "cus Total Return Index due October 24 2022",
        "price": 34.9
    }, {
        "symbol": "WOOD",
        "label": "iShares S&P Global Timber & Forestry Index Fund",
        "price": 66.55
    }, {
        "symbol": "WPS",
        "label": "iShares International Developed Property",
        "price": 38.01
    }, {
        "symbol": "WREI",
        "label": "Wilshire US REIT",
        "price": 44.9
    }, {
        "symbol": "WRLS",
        "label": "Pensare Acquisition Corp.",
        "price": 10.5
    }, {
        "symbol": "WRLSR",
        "label": "Pensare Acquisition Corp. Right",
        "price": 0.25
    }, {
        "symbol": "WRLSW",
        "label": "Pensare Acquisition Corp. Warrant",
        "price": 0.33
    }, {
        "symbol": "WRN",
        "label": "Western Copper and Gold Corporation",
        "price": 0.7425
    }, {
        "symbol": "WSCI",
        "label": "WSI Industries Inc.",
        "price": 6.99
    }, {
        "symbol": "WSTG",
        "label": "Wayside Technology Group Inc.",
        "price": 16.25
    }, {
        "symbol": "WTFCW",
        "label": "Wintrust Financial Corporation Warrants",
        "price": 59.6
    }, {
        "symbol": "WTID",
        "label": "UBS ETRACS - ProShares Daily 3x Inverse Crude ETN",
        "price": 5.61
    }, {
        "symbol": "WTIU",
        "label": "UBS ETRACS - ProShares Daily 3x Long Crude ETN",
        "price": 11.04
    }, {
        "symbol": "WTMF",
        "label": "WisdomTree Managed Futures Strategy Fund",
        "price": 37.79
    }, {
        "symbol": "WTRX",
        "label": "Elevation ETF Trust",
        "price": 29.51
    }, {
        "symbol": "WTT",
        "label": "Wireless Telecom Group Inc.",
        "price": 1.39
    }, {
        "symbol": "WVFC",
        "label": "WVS Financial Corp.",
        "price": 16.61
    }, {
        "symbol": "WVVI",
        "label": "Willamette Valley Vineyards Inc.",
        "price": 6.9531
    }, {
        "symbol": "WVVIP",
        "label": "Willamette Valley Vineyards Inc. Series A Redeemable Preferred Stock",
        "price": 4.73
    }, {
        "symbol": "WYDE",
        "label": "ProShares CDS Short North American HY Credit",
        "price": 33.26
    }, {
        "symbol": "XBIO",
        "label": "Xenetic Biosciences Inc.",
        "price": 1.52
    }, {
        "symbol": "XBIT",
        "label": "XBiotech Inc.",
        "price": 24.3
    }, {
        "symbol": "XCEM",
        "label": "Columbia EM Core ex-China",
        "price": 28.21
    }, {
        "symbol": "XDIV",
        "label": "U.S. Equity Ex-Dividend Fund Series 2027 Shares",
        "price": 57.63
    }, {
        "symbol": "XELB",
        "label": "Xcel Brands Inc",
        "price": 1.4502
    }, {
        "symbol": "XFLT",
        "label": "XAI Octagon Floating Rate & Alternative Income Term Trust of Beneficial Interest",
        "price": 8.2
    }, {
        "symbol": "XGTI",
        "label": "XG Technology Inc",
        "price": 0.45
    }, {
        "symbol": "XGTIW",
        "label": "XG Technology Inc Warrants (1 Wt & 824.40 to purchase 1 shr).",
        "price": 3.0
    }, {
        "symbol": "XHE",
        "label": "SPDR S&P Health Care Equipment",
        "price": 88.27
    }, {
        "symbol": "XINA",
        "label": "SPDR MSCI China A Shares IMI",
        "price": 19.68
    }, {
        "symbol": "XITK",
        "label": "SPDR FactSet Innovative Technology",
        "price": 123.563
    }, {
        "symbol": "XIVH",
        "label": "UBS AG VelocityShares VIX Short Volatility Hedged ETN linked to the S&P 500 VIX " +
                "Futures Short Volatility Hedged Index a&#128;&#147; Short Term due July 18 2046",
        "price": 13.6445
    }, {
        "symbol": "XKFS",
        "label": "SPDR Kensho Future Security",
        "price": 28.3
    }, {
        "symbol": "XKII",
        "label": "SPDR Kensho Intelligent Structures",
        "price": 29.31
    }, {
        "symbol": "XKST",
        "label": "SPDR Kensho Smart Mobility",
        "price": 30.338
    }, {
        "symbol": "XMPT",
        "label": "VanEck Vectors CEF Municipal Income",
        "price": 27.985
    }, {
        "symbol": "XMX",
        "label": "WisdomTree Global ex-Mexico Equity Fund",
        "price": 29.81
    }, {
        "symbol": "XRLV",
        "label": "PowerShares S&P 500 ex-Rate Sensitive Low Volatility Portfolio",
        "price": 41.9431
    }, {
        "symbol": "XSHD",
        "label": "PowerShares S&P SmallCap High Dividend Low Volatility Portfolio",
        "price": 24.64
    }, {
        "symbol": "XSHQ",
        "label": "PowerShares S&P SmallCap Quality Portfolio",
        "price": 28.1
    }, {
        "symbol": "XTH",
        "label": "SPDR S&P Technology Hardware",
        "price": 61.59
    }, {
        "symbol": "XTL",
        "label": "SPDR S&P Telecom",
        "price": 67.0
    }, {
        "symbol": "XTLB",
        "label": "XTL Biopharmaceuticals Ltd.",
        "price": 1.3
    }, {
        "symbol": "XUSA",
        "label": "QuantX Dynamic Beta US Equity",
        "price": 28.155
    }, {
        "symbol": "XVZ",
        "label": "iPath S&P 500 Dynamic VIX ETN",
        "price": 17.97
    }, {
        "symbol": "XWEB",
        "label": "SPDR S&P Internet",
        "price": 92.04
    }, {
        "symbol": "XXV",
        "label": "iPath Inverse S&P 500 VIX Short-Term Futures ETN",
        "price": 37.9201
    }, {
        "symbol": "YAO",
        "label": "Guggenheim China All-Cap",
        "price": 31.1
    }, {
        "symbol": "YDIV",
        "label": "First Trust International Multi-Asset Diversified Income Index Fund",
        "price": 17.55
    }, {
        "symbol": "YESR",
        "label": "Amplify YieldShares Senior Loan and Income",
        "price": 24.38
    }, {
        "symbol": "YLD",
        "label": "Principal Exchange-Traded Funds EDGE Active Income",
        "price": 41.211
    }, {
        "symbol": "YLDE",
        "label": "ClearBridge Dividend Strategy ESG ETF",
        "price": 33.645
    }, {
        "symbol": "YMLI",
        "label": "VanEck Vectors High Income Infrastructure MLP",
        "price": 12.41
    }, {
        "symbol": "YMLP",
        "label": "VanEck Vectors High Income MLP",
        "price": 19.45
    }, {
        "symbol": "YRIV",
        "label": "Yangtze River Port and Logistics Limited",
        "price": 0.66
    }, {
        "symbol": "YTEN",
        "label": "Yield10 Bioscience Inc.",
        "price": 6.8
    }, {
        "symbol": "YXI",
        "label": "ProShares Short FTSE China 50",
        "price": 20.17
    }, {
        "symbol": "ZAIS",
        "label": "ZAIS Group Holdings Inc.",
        "price": 4.07
    }, {
        "symbol": "ZBIO",
        "label": "ProShares UltraPro Short NASDAQ Biotechnology",
        "price": 8.849
    }, {
        "symbol": "ZBK",
        "label": "Zions Bancorporation 6.95% Fixed-to-Floating Rate Subordinated Notes due Septemb" +
                "er 15 2028",
        "price": 30.7096
    }, {
        "symbol": "ZIONZ",
        "label": "Zions Bancorporation Warrants",
        "price": 13.55
    }, {
        "symbol": "ZKIN",
        "label": "ZK International Group Co. Ltd",
        "price": 1.68
    }, {
        "symbol": "ZOM",
        "label": "Zomedica Pharmaceuticals Corp.",
        "price": 0.3
    }, {
        "symbol": "ZVZZT",
        "label": "NASDAQ TEST STOCK",
        "price": 10.03
    }, {
        "symbol": "ZWZZT",
        "label": "NASDAQ TEST STOCK",
        "price": 10.81
    }, {
        "symbol": "ZXZZT",
        "label": "NASDAQ TEST STOCK",
        "price": 31.01
    }, {
        "symbol": "ZYME",
        "label": "Zymeworks Inc.",
        "price": 49.12
    }, {
        "symbol": "POLIX",
        "label": "Polen Growth Fund Institutional Class",
        "price": 36.81
    }, {
        "symbol": "ALC",
        "label": "Alcon, Inc.",
        "price": 61.47
    }, {
        "symbol": "AMCR",
        "label": "Amcor plc",
        "price": 10.9
    }, {
        "symbol": "AMRX",
        "label": "Amneal Pharmaceuticals, Inc.",
        "price": 5.1
    }, {
        "symbol": "HKIB",
        "label": "AMTD International Inc.",
        "price": 8.53
    }, {
        "symbol": "PLAN",
        "label": "Anaplan, Inc.",
        "price": 56.52
    }, {
        "symbol": "FINS",
        "label": "Angel Oak Financial Strategies Income Term Trust",
        "price": 20.754
    }, {
        "symbol": "APY",
        "label": "Apergy Corporation",
        "price": 31.02
    }, {
        "symbol": "APHA",
        "label": "Aphria Inc.",
        "price": 5.27
    }, {
        "symbol": "ACA",
        "label": "Arcosa, Inc.",
        "price": 46.52
    }, {
        "symbol": "ARGO",
        "label": "Argo Group International Holdings, Ltd.",
        "price": 70.11
    }, {
        "symbol": "ARLO",
        "label": "Arlo Technologies, Inc.",
        "price": 4.57
    }, {
        "symbol": "AMK",
        "label": "AssetMark Financial Holdings, Inc.",
        "price": 30.43
    }, {
        "symbol": "ACB",
        "label": "Aurora Cannabis Inc.",
        "price": 2.13
    }, {
        "symbol": "AVLR",
        "label": "Avalara, Inc.",
        "price": 84.65
    }, {
        "symbol": "AVNS",
        "label": "Avanos Medical, Inc.",
        "price": 33.14
    }, {
        "symbol": "AVTR",
        "label": "Avantor, Inc.",
        "price": 19.2
    }, {
        "symbol": "EQH",
        "label": "AXA Equitable Holdings, Inc.",
        "price": 26.08
    }, {
        "symbol": "AX",
        "label": "Axos Financial, Inc.",
        "price": 30.45
    }, {
        "symbol": "BRPM",
        "label": "B. Riley Principal Merger Corp.",
        "price": 10.32
    }, {
        "symbol": "BCSF",
        "label": "Bain Capital Specialty Finance, Inc.",
        "price": 19.68
    }, {
        "symbol": "BBDC",
        "label": "Barings BDC, Inc.",
        "price": 10.46
    }, {
        "symbol": "BHC",
        "label": "Bausch Health Companies Inc.",
        "price": 30.25
    }, {
        "symbol": "BBAR",
        "label": "Banco BBVA Argentina S.A.",
        "price": 5.44
    }, {
        "symbol": "BRK-B",
        "label": "Berkshire Hathaway Inc.",
        "price": 230.2
    }, {
        "symbol": "BEST",
        "label": "BEST Inc.",
        "price": 6.41
    }, {
        "symbol": "BH-A",
        "label": "Biglari Holdings Inc.",
        "price": 605.58
    }, {
        "symbol": "BIO-B",
        "label": "Bio-Rad Laboratories, Inc.",
        "price": 375.44
    }, {
        "symbol": "BJ",
        "label": "BJ's Wholesale Club Holdings, Inc.",
        "price": 22.78
    }, {
        "symbol": "BSTZ",
        "label": "BlackRock Science and Technology Trust II",
        "price": 21.53
    }, {
        "symbol": "BE",
        "label": "Bloom Energy Corporation",
        "price": 9.71
    }, {
        "symbol": "BORR",
        "label": "Borr Drilling Limited",
        "price": 7.74
    }, {
        "symbol": "BHR",
        "label": "Braemar Hotels & Resorts, Inc.",
        "price": 8.92
    }, {
        "symbol": "MNRL",
        "label": "Brigham Minerals, Inc.",
        "price": 19.51
    }, {
        "symbol": "BSIG",
        "label": "BrightSphere Investment Group Inc.",
        "price": 10.17
    }, {
        "symbol": "BV",
        "label": "BrightView Holdings, Inc.",
        "price": 16.83
    }, {
        "symbol": "BF-A",
        "label": "Brown-Forman Corporation",
        "price": 66.11
    }, {
        "symbol": "BF-B",
        "label": "Brown-Forman Corporation",
        "price": 70.74
    }, {
        "symbol": "CANG",
        "label": "Cango Inc.",
        "price": 8.5
    }, {
        "symbol": "CTST",
        "label": "CannTrust Holdings Inc.",
        "price": 1.18
    }, {
        "symbol": "CGC",
        "label": "Canopy Growth Corporation",
        "price": 24.89
    }, {
        "symbol": "CPRI",
        "label": "Capri Holdings Limited",
        "price": 36.79
    }, {
        "symbol": "CBS-A",
        "label": "ViacomCBS Inc.",
        "price": 43.12
    }, {
        "symbol": "EBR-B",
        "label": "Centrais Eletricas Brasileiras S.A. - Eletrobras",
        "price": 9.81
    }, {
        "symbol": "CDAY",
        "label": "Ceridian HCM Holding Inc.",
        "price": 72.73
    }, {
        "symbol": "CHAP",
        "label": "Chaparral Energy, Inc.",
        "price": 1.3
    }, {
        "symbol": "CHRA",
        "label": "Charah Solutions, Inc.",
        "price": 2.81
    }, {
        "symbol": "CHWY",
        "label": "Chewy, Inc.",
        "price": 29.37
    }, {
        "symbol": "CCX",
        "label": "Churchill Capital Corp II",
        "price": 10.6
    }, {
        "symbol": "CCC",
        "label": "Clarivate Analytics Plc",
        "price": 18.9
    }, {
        "symbol": "CWEN",
        "label": "Clearway Energy, Inc.",
        "price": 21.61
    }, {
        "symbol": "CWEN-A",
        "label": "Clearway Energy, Inc.",
        "price": 20.7
    }, {
        "symbol": "CNF",
        "label": "CNFinance Holdings Limited",
        "price": 4.63
    }, {
        "symbol": "CCEP",
        "label": "Coca-Cola European Partners plc",
        "price": 53.54
    }, {
        "symbol": "CFXA",
        "label": "Colfax Corporation",
        "price": 162.87
    }, {
        "symbol": "CCH",
        "label": "Collier Creek Holdings",
        "price": 10.66
    }, {
        "symbol": "CLNY",
        "label": "Colony Capital, Inc.",
        "price": 4.86
    }, {
        "symbol": "CIG-C",
        "label": "Companhia Energetica de Minas Gerais",
        "price": 4.18
    }, {
        "symbol": "STZ-B",
        "label": "Constellation Brands, Inc.",
        "price": 190.86
    }, {
        "symbol": "CTRA",
        "label": "Contura Energy, Inc.",
        "price": 7.49
    }, {
        "symbol": "CTK",
        "label": "CooTek (Cayman) Inc.",
        "price": 5.43
    }, {
        "symbol": "CPLG",
        "label": "CorePoint Lodging Inc.",
        "price": 10.56
    }, {
        "symbol": "CNR",
        "label": "Cornerstone Building Brands, Inc.",
        "price": 9.0
    }, {
        "symbol": "CTVA",
        "label": "Corteva, Inc.",
        "price": 28.3
    }, {
        "symbol": "CVIA",
        "label": "Covia Holdings Corporation",
        "price": 1.91
    }, {
        "symbol": "CRD-A",
        "label": "Crawford & Company",
        "price": 10.26
    }, {
        "symbol": "CRD-B",
        "label": "Crawford & Company",
        "price": 9.5
    }, {
        "symbol": "CWK",
        "label": "Cushman & Wakefield plc",
        "price": 19.68
    }, {
        "symbol": "DELL",
        "label": "Dell Technologies Inc.",
        "price": 50.34
    }, {
        "symbol": "DBI",
        "label": "Designer Brands Inc.",
        "price": 15.78
    }, {
        "symbol": "DSSI",
        "label": "Diamond S Shipping Inc.",
        "price": 13.73
    }, {
        "symbol": "DCUE",
        "label": "Dominion Energy, Inc.",
        "price": 108.06
    }, {
        "symbol": "DOW",
        "label": "Dow Inc.",
        "price": 53.34
    }, {
        "symbol": "DD",
        "label": "DuPont de Nemours, Inc.",
        "price": 61.56
    }, {
        "symbol": "DT",
        "label": "Dynatrace, Inc.",
        "price": 26.82
    }, {
        "symbol": "ELAN",
        "label": "Elanco Animal Health Incorporated",
        "price": 30.0
    }, {
        "symbol": "ESTC",
        "label": "Elastic N.V.",
        "price": 70.47
    }, {
        "symbol": "ESI",
        "label": "Element Solutions Inc",
        "price": 12.48
    }, {
        "symbol": "AKO-A",
        "label": "Embotelladora Andina S.A.",
        "price": 14.5
    }, {
        "symbol": "AKO-B",
        "label": "Embotelladora Andina S.A.",
        "price": 16.87
    }, {
        "symbol": "DAVA",
        "label": "Endava plc",
        "price": 45.82
    }, {
        "symbol": "ET",
        "label": "Energy Transfer LP",
        "price": 13.56
    }, {
        "symbol": "EQNR",
        "label": "Equinor ASA",
        "price": 20.39
    }, {
        "symbol": "ETRN",
        "label": "Equitrans Midstream Corporation",
        "price": 13.35
    }, {
        "symbol": "EPRT",
        "label": "Essential Properties Realty Trust, Inc.",
        "price": 25.8
    }, {
        "symbol": "EB",
        "label": "Eventbrite, Inc.",
        "price": 20.72
    }, {
        "symbol": "EVRG",
        "label": "Evergy, Inc.",
        "price": 67.33
    }, {
        "symbol": "XAN",
        "label": "Exantas Capital Corp.",
        "price": 11.87
    }, {
        "symbol": "FPAC",
        "label": "Far Point Acquisition Corporation",
        "price": 11.24
    }, {
        "symbol": "FTCH",
        "label": "Farfetch Limited",
        "price": 12.9
    }, {
        "symbol": "FSLY",
        "label": "Fastly, Inc.",
        "price": 24.41
    }, {
        "symbol": "AGM-A",
        "label": "Federal Agricultural Mortgage Corporation",
        "price": 71.97
    }, {
        "symbol": "FVRR",
        "label": "Fiverr International Ltd.",
        "price": 26.95
    }, {
        "symbol": "FLNG",
        "label": "Flex LNG Ltd.",
        "price": 10.0
    }, {
        "symbol": "FSK",
        "label": "FS KKR Capital Corp.",
        "price": 6.3
    }, {
        "symbol": "GTX",
        "label": "Garrett Motion Inc.",
        "price": 10.0
    }, {
        "symbol": "GIX",
        "label": "GigCapital2, Inc.",
        "price": 9.9999
    }, {
        "symbol": "GL",
        "label": "Globe Life Inc.",
        "price": 104.45
    }, {
        "symbol": "GRAF",
        "label": "Graf Industrial Corp.",
        "price": 10.11
    }, {
        "symbol": "EAF",
        "label": "GrafTech International Ltd.",
        "price": 12.01
    }, {
        "symbol": "GTN-A",
        "label": "Gray Television, Inc.",
        "price": 21.3
    }, {
        "symbol": "GHG",
        "label": "GreenTree Hospitality Group Ltd.",
        "price": 13.6
    }, {
        "symbol": "GEF-B",
        "label": "Greif, Inc.",
        "price": 51.98
    }, {
        "symbol": "GSAH",
        "label": "GS Acquisition Holdings Corp",
        "price": 11.4
    }, {
        "symbol": "GSX",
        "label": "GSX Techedu Inc.",
        "price": 32.91
    }, {
        "symbol": "HNGR",
        "label": "Hanger, Inc.",
        "price": 26.25
    }, {
        "symbol": "HVT-A",
        "label": "Haverty Furniture Companies, Inc.",
        "price": 20.49
    }, {
        "symbol": "HEI-A",
        "label": "HEICO Corporation",
        "price": 95.21
    }, {
        "symbol": "PSV",
        "label": "Hermitage Offshore Services Ltd.",
        "price": 1.05
    }, {
        "symbol": "HEXO",
        "label": "HEXO Corp.",
        "price": 1.62
    }, {
        "symbol": "HCR",
        "label": "Hi-Crush Inc.",
        "price": 0.86
    }, {
        "symbol": "HGLB",
        "label": "Highland Global Allocation Fund",
        "price": 9.36
    }, {
        "symbol": "HPR",
        "label": "HighPoint Resources Corporation",
        "price": 1.39
    }, {
        "symbol": "HCFT",
        "label": "Hunt Companies Finance Trust, Inc.",
        "price": 3.28
    }, {
        "symbol": "HUYA",
        "label": "HUYA Inc.",
        "price": 20.43
    }, {
        "symbol": "IAA",
        "label": "IAA, Inc.",
        "price": 49.71
    }, {
        "symbol": "INSP",
        "label": "Inspire Medical Systems, Inc.",
        "price": 82.47
    }, {
        "symbol": "IFS",
        "label": "Intercorp Financial Services Inc.",
        "price": 43.05
    }, {
        "symbol": "JEF",
        "label": "Jefferies Financial Group Inc.",
        "price": 22.41
    }, {
        "symbol": "JW-A",
        "label": "John Wiley & Sons, Inc.",
        "price": 49.17
    }, {
        "symbol": "JW-B",
        "label": "John Wiley & Sons, Inc.",
        "price": 49.41
    }, {
        "symbol": "JMIA",
        "label": "Jumia Technologies AG",
        "price": 7.24
    }, {
        "symbol": "KDP",
        "label": "Keurig Dr Pepper Inc.",
        "price": 27.67
    }, {
        "symbol": "KTB",
        "label": "Kontoor Brands, Inc.",
        "price": 41.73
    }, {
        "symbol": "LHX",
        "label": "L3Harris Technologies, Inc.",
        "price": 219.38
    }, {
        "symbol": "LAIX",
        "label": "LAIX Inc.",
        "price": 6.2
    }, {
        "symbol": "LEAF",
        "label": "Leaf Group Ltd.",
        "price": 2.81
    }, {
        "symbol": "LEN-B",
        "label": "Lennar Corporation",
        "price": 50.47
    }, {
        "symbol": "LHC",
        "label": "Leo Holdings Corp.",
        "price": 10.3485
    }, {
        "symbol": "LEVI",
        "label": "Levi Strauss & Co.",
        "price": 19.98
    }, {
        "symbol": "LIN",
        "label": "Linde plc",
        "price": 214.1
    }, {
        "symbol": "LINX",
        "label": "Linx S.A.",
        "price": 9.04
    }, {
        "symbol": "LGF-A",
        "label": "Lions Gate Entertainment Corp.",
        "price": 10.97
    }, {
        "symbol": "LGF-B",
        "label": "Lions Gate Entertainment Corp.",
        "price": 10.32
    }, {
        "symbol": "LTHM",
        "label": "Livent Corporation",
        "price": 8.89
    }, {
        "symbol": "RAMP",
        "label": "LiveRamp Holdings, Inc.",
        "price": 45.76
    }, {
        "symbol": "MGY",
        "label": "Magnolia Oil & Gas Corporation",
        "price": 12.36
    }, {
        "symbol": "MEC",
        "label": "Mayville Engineering Company, Inc.",
        "price": 9.11
    }, {
        "symbol": "MKC-V",
        "label": "McCormick & Company, Incorporated",
        "price": 168.25
    }, {
        "symbol": "MDLA",
        "label": "Medallia, Inc.",
        "price": 31.77
    }, {
        "symbol": "MFAC",
        "label": "Megalith Financial Acquisition Corp.",
        "price": 10.34
    }, {
        "symbol": "AMPY",
        "label": "Amplify Energy Corp.",
        "price": 7.25
    }, {
        "symbol": "MUFG",
        "label": "Mitsubishi UFJ Financial Group, Inc.",
        "price": 5.33
    }, {
        "symbol": "MOGU",
        "label": "MOGU Inc.",
        "price": 2.47
    }, {
        "symbol": "TAP-A",
        "label": "Molson Coors Brewing Company",
        "price": 63.1654
    }, {
        "symbol": "MR",
        "label": "Montage Resources Corporation",
        "price": 5.32
    }, {
        "symbol": "MOG-A",
        "label": "Moog Inc.",
        "price": 93.03
    }, {
        "symbol": "MOG-B",
        "label": "Moog Inc.",
        "price": 93.1134
    }, {
        "symbol": "NSCO",
        "label": "Nesco Holdings, Inc.",
        "price": 5.11
    }, {
        "symbol": "NMFX",
        "label": "New Mountain Finance Corporation",
        "price": 25.93
    }, {
        "symbol": "NIO",
        "label": "NIO Limited",
        "price": 4.67
    }, {
        "symbol": "JHAA",
        "label": "Nuveen High Income 2023 Target Term Fund",
        "price": 10.89
    }, {
        "symbol": "NVT",
        "label": "nVent Electric plc",
        "price": 25.57
    }, {
        "symbol": "OAC",
        "label": "Oaktree Acquisition Corp.",
        "price": 10.05
    }, {
        "symbol": "ONE",
        "label": "OneSmart International Education Group Limited",
        "price": 6.8
    }, {
        "symbol": "ORCC",
        "label": "Owl Rock Capital Corporation",
        "price": 15.85
    }, {
        "symbol": "PACD",
        "label": "Pacific Drilling S.A.",
        "price": 2.6
    }, {
        "symbol": "PD",
        "label": "PagerDuty, Inc.",
        "price": 25.23
    }, {
        "symbol": "PSN",
        "label": "Parsons Corporation",
        "price": 42.03
    }, {
        "symbol": "PVL",
        "label": "Permianville Royalty Trust",
        "price": 2.0
    }, {
        "symbol": "PRT",
        "label": "PermRock Royalty Trust",
        "price": 6.11
    }, {
        "symbol": "PRSP",
        "label": "Perspecta Inc.",
        "price": 28.34
    }, {
        "symbol": "PBR-A",
        "label": "Petroleo Brasileiro S.A. - Petrobras",
        "price": 14.26
    }, {
        "symbol": "PHR",
        "label": "Phreesia, Inc.",
        "price": 27.83
    }, {
        "symbol": "NRGX",
        "label": "PIMCO Energy and Tactical Credit Opportunities Fund",
        "price": 17.8
    }, {
        "symbol": "PINS",
        "label": "Pinterest, Inc.",
        "price": 23.0
    }, {
        "symbol": "PIC",
        "label": "Pivotal Investment Corporation II",
        "price": 10.01
    }, {
        "symbol": "PVTL",
        "label": "Pivotal Software, Inc.",
        "price": 15.0
    }, {
        "symbol": "PSTL",
        "label": "Postal Realty Trust, Inc.",
        "price": 16.82
    }, {
        "symbol": "PROS",
        "label": "ProSight Global, Inc.",
        "price": 16.44
    }, {
        "symbol": "NEW",
        "label": "Puxin Limited",
        "price": 9.87
    }, {
        "symbol": "PYX",
        "label": "Pyxus International, Inc.",
        "price": 9.33
    }, {
        "symbol": "RMED",
        "label": "Ra Medical Systems, Inc.",
        "price": 1.75
    }, {
        "symbol": "PACK",
        "label": "Ranpak Holdings Corp.",
        "price": 8.01
    }, {
        "symbol": "RC",
        "label": "Ready Capital Corporation",
        "price": 15.99
    }, {
        "symbol": "RPLA",
        "label": "Replay Acquisition Corp.",
        "price": 10.08
    }, {
        "symbol": "REZI",
        "label": "Resideo Technologies, Inc.",
        "price": 12.55
    }, {
        "symbol": "RVI",
        "label": "Retail Value Inc.",
        "price": 35.43
    }, {
        "symbol": "RVLV",
        "label": "Revolve Group, Inc.",
        "price": 20.88
    }, {
        "symbol": "RMM",
        "label": "Rivernorth Managed Duration Municipal Income Fund Inc.",
        "price": 20.1
    }, {
        "symbol": "RSF",
        "label": "RiverNorth Marketplace Lending Corporation",
        "price": 18.2
    }, {
        "symbol": "RMI",
        "label": "RiverNorth Opportunistic Municipal Income Fund, Inc.",
        "price": 22.86
    }, {
        "symbol": "RMG",
        "label": "RMG Acquisition Corp.",
        "price": 10.06
    }, {
        "symbol": "RDS-A",
        "label": "Royal Dutch Shell plc",
        "price": 58.54
    }, {
        "symbol": "RDS-B",
        "label": "Royal Dutch Shell plc",
        "price": 59.79
    }, {
        "symbol": "RTW",
        "label": "RTW Retailwinds, Inc.",
        "price": 0.5967
    }, {
        "symbol": "SCPE",
        "label": "SC Health Corporation",
        "price": 10.02
    }, {
        "symbol": "SRL",
        "label": "Scully Royalty Ltd.",
        "price": 11.3
    }, {
        "symbol": "SITC",
        "label": "SITE Centers Corp.",
        "price": 13.32
    }, {
        "symbol": "WORK",
        "label": "Slack Technologies, Inc.",
        "price": 22.46
    }, {
        "symbol": "SMAR",
        "label": "Smartsheet Inc.",
        "price": 45.26
    }, {
        "symbol": "SWI",
        "label": "SolarWinds Corporation",
        "price": 18.89
    }, {
        "symbol": "SPAQ",
        "label": "Spartan Energy Acquisition Corp.",
        "price": 10.19
    }, {
        "symbol": "SMTA",
        "label": "Spirit MTA REIT",
        "price": 0.7676
    }, {
        "symbol": "SPOT",
        "label": "Spotify Technology S.A.",
        "price": 148.34
    }, {
        "symbol": "MSC",
        "label": "Studio City International Holdings Limited",
        "price": 20.5
    }, {
        "symbol": "STG",
        "label": "Sunlands Technology Group",
        "price": 2.7
    }, {
        "symbol": "NOVA",
        "label": "Sunnova Energy International Inc.",
        "price": 12.32
    }, {
        "symbol": "SUZ",
        "label": "Suzano S.A.",
        "price": 10.48
    }, {
        "symbol": "TAK",
        "label": "Takeda Pharmaceutical Company Limited",
        "price": 20.01
    }, {
        "symbol": "TGE",
        "label": "Tallgrass Energy, LP",
        "price": 22.29
    }, {
        "symbol": "TALO",
        "label": "Talos Energy Inc.",
        "price": 28.67
    }, {
        "symbol": "TME",
        "label": "Tencent Music Entertainment Group",
        "price": 14.08
    }, {
        "symbol": "VAM",
        "label": "Vivaldi Opportunities Fund",
        "price": 13.69
    }, {
        "symbol": "SHLL",
        "label": "Tortoise Acquisition Corp.",
        "price": 10.04
    }, {
        "symbol": "TEAF",
        "label": "Tortoise Essential Assets Income Term Fund",
        "price": 17.1
    }, {
        "symbol": "TRNE",
        "label": "Trine Acquisition Corp.",
        "price": 10.04
    }, {
        "symbol": "TUFN",
        "label": "Tufin Software Technologies Ltd.",
        "price": 14.41
    }, {
        "symbol": "TRWH",
        "label": "Twin River Worldwide Holdings, Inc.",
        "price": 25.81
    }, {
        "symbol": "USX",
        "label": "U.S. Xpress Enterprises, Inc.",
        "price": 6.18
    }, {
        "symbol": "UBER",
        "label": "Uber Technologies, Inc.",
        "price": 35.13
    }, {
        "symbol": "UI",
        "label": "Ubiquiti Inc.",
        "price": 170.58
    }, {
        "symbol": "VAL",
        "label": "Valaris plc",
        "price": 5.68
    }, {
        "symbol": "VAPO",
        "label": "Vapotherm, Inc.",
        "price": 12.28
    }, {
        "symbol": "VNE",
        "label": "Veoneer, Inc.",
        "price": 13.68
    }, {
        "symbol": "VCIF",
        "label": "Vertical Capital Income Fund",
        "price": 10.55
    }, {
        "symbol": "VIST",
        "label": "Vista Oil & Gas, S.A.B. de C.V.",
        "price": 7.5
    }, {
        "symbol": "WSO-B",
        "label": "Watsco, Inc.",
        "price": 181.5
    }, {
        "symbol": "WEI",
        "label": "Weidai Ltd.",
        "price": 2.77
    }, {
        "symbol": "WYND",
        "label": "Wyndham Destinations, Inc.",
        "price": 52.82
    }, {
        "symbol": "WH",
        "label": "Wyndham Hotels & Resorts, Inc.",
        "price": 61.31
    }, {
        "symbol": "XYF",
        "label": "X Financial",
        "price": 1.77
    }, {
        "symbol": "YETI",
        "label": "YETI Holdings, Inc.",
        "price": 34.25
    }, {
        "symbol": "ZUO",
        "label": "Zuora, Inc.",
        "price": 15.35
    }, {
        "symbol": "YI",
        "label": "111, Inc.",
        "price": 6.63
    }, {
        "symbol": "PIHPP",
        "label": "1347 Property Insurance Holdings, Inc.",
        "price": 26.71
    }, {
        "symbol": "BCOW",
        "label": "1895 Bancorp of Wisconsin, Inc.",
        "price": 10.7
    }, {
        "symbol": "QFIN",
        "label": "360 Finance, Inc.",
        "price": 9.0
    }, {
        "symbol": "JFK",
        "label": "8i Enterprises Acquisition Corp.",
        "price": 10.1428
    }, {
        "symbol": "JFKKU",
        "label": "8i Enterprises Acquisition Corp.",
        "price": 10.72
    }, {
        "symbol": "JFU",
        "label": "9F Inc.",
        "price": 9.4
    }, {
        "symbol": "ACAM",
        "label": "Acamar Partners Acquisition Corp.",
        "price": 10.04
    }, {
        "symbol": "ACAMU",
        "label": "Acamar Partners Acquisition Corp.",
        "price": 10.32
    }, {
        "symbol": "ACTT",
        "label": "Act II Global Acquisition Corp.",
        "price": 10.15
    }, {
        "symbol": "ACTTU",
        "label": "Act II Global Acquisition Corp.",
        "price": 10.61
    }, {
        "symbol": "ADPT",
        "label": "Adaptive Biotechnologies Corporation",
        "price": 28.21
    }, {
        "symbol": "ADIL",
        "label": "Adial Pharmaceuticals, Inc.",
        "price": 2.12
    }, {
        "symbol": "DWMC",
        "label": "AdvisorShares Dorsey Wright Micro-Cap ETF",
        "price": 24.4
    }, {
        "symbol": "DWSH",
        "label": "AdvisorShares Dorsey Wright Short ETF",
        "price": 22.7002
    }, {
        "symbol": "BKCH",
        "label": "AdvisorShares Sabretooth ETF",
        "price": 24.9
    }, {
        "symbol": "ARPO",
        "label": "Aerpio Pharmaceuticals, Inc.",
        "price": 0.6103
    }, {
        "symbol": "AFYA",
        "label": "Afya Limited",
        "price": 28.75
    }, {
        "symbol": "AGBA",
        "label": "AGBA Acquisition Limited",
        "price": 10.2
    }, {
        "symbol": "AGBAU",
        "label": "AGBA Acquisition Limited",
        "price": 10.3
    }, {
        "symbol": "AGMH",
        "label": "AGM Group Holdings Inc.",
        "price": 15.46
    }, {
        "symbol": "AGNCM",
        "label": "AGNC Investment Corp.",
        "price": 26.03
    }, {
        "symbol": "AIRTP",
        "label": "Air T, Inc.",
        "price": 25.08
    }, {
        "symbol": "ANTE",
        "label": "AirNet Technology Inc.",
        "price": 1.05
    }, {
        "symbol": "KERN",
        "label": "Akerna Corp.",
        "price": 8.84
    }, {
        "symbol": "AKRO",
        "label": "Akero Therapeutics, Inc.",
        "price": 20.18
    }, {
        "symbol": "ALAC",
        "label": "Alberton Acquisition Corporation",
        "price": 10.3646
    }, {
        "symbol": "ALACU",
        "label": "Alberton Acquisition Corporation",
        "price": 10.52
    }, {
        "symbol": "ALACW",
        "label": "Alberton Acquisition Corporation",
        "price": 0.0597
    }, {
        "symbol": "ALEC",
        "label": "Alector, Inc.",
        "price": 21.38
    }, {
        "symbol": "ALYA",
        "label": "Alithya Group Inc.",
        "price": 2.82
    }, {
        "symbol": "ALLK",
        "label": "Allakos Inc.",
        "price": 83.07
    }, {
        "symbol": "ALGR",
        "label": "Allegro Merger Corp.",
        "price": 10.278
    }, {
        "symbol": "ALGRU",
        "label": "Allegro Merger Corp.",
        "price": 10.57
    }, {
        "symbol": "AESE",
        "label": "Allied Esports Entertainment Inc.",
        "price": 3.15
    }, {
        "symbol": "ALLO",
        "label": "Allogene Therapeutics, Inc.",
        "price": 25.32
    }, {
        "symbol": "ATHE",
        "label": "Alterity Therapeutics Limited",
        "price": 0.75
    }, {
        "symbol": "ALTM",
        "label": "Altus Midstream Company",
        "price": 2.65
    }, {
        "symbol": "AMAL",
        "label": "Amalgamated Bank",
        "price": 18.95
    }, {
        "symbol": "AMCI",
        "label": "AMCI Acquisition Corp.",
        "price": 10.18
    }, {
        "symbol": "AMCIU",
        "label": "AMCI Acquisition Corp.",
        "price": 10.57
    }, {
        "symbol": "AMTB",
        "label": "Amerant Bancorp Inc.",
        "price": 20.26
    }, {
        "symbol": "AMTBB",
        "label": "Amerant Bancorp Inc.",
        "price": 15.5
    }, {
        "symbol": "AFIN",
        "label": "American Finance Trust, Inc.",
        "price": 13.215
    }, {
        "symbol": "AFINP",
        "label": "American Finance Trust, Inc.",
        "price": 25.25
    }, {
        "symbol": "AREC",
        "label": "American Resources Corporation",
        "price": 0.57
    }, {
        "symbol": "ANCN",
        "label": "Anchiano Therapeutics Ltd",
        "price": 1.58
    }, {
        "symbol": "ANDA",
        "label": "Andina Acquisition Corp. III",
        "price": 10.14
    }, {
        "symbol": "ANDAU",
        "label": "Andina Acquisition Corp. III",
        "price": 10.46
    }, {
        "symbol": "ANIX",
        "label": "Anixa Biosciences, Inc.",
        "price": 3.51
    }, {
        "symbol": "ATEX",
        "label": "Anterix Inc.",
        "price": 48.07
    }, {
        "symbol": "APEX",
        "label": "Apex Global Brands Inc.",
        "price": 0.8225
    }, {
        "symbol": "APLT",
        "label": "Applied Therapeutics, Inc.",
        "price": 48.86
    }, {
        "symbol": "APTX",
        "label": "Aptinyx Inc.",
        "price": 4.6
    }, {
        "symbol": "APM",
        "label": "Aptorum Group Limited",
        "price": 14.45
    }, {
        "symbol": "APYX",
        "label": "Apyx Medical Corporation",
        "price": 8.12
    }, {
        "symbol": "AQST",
        "label": "Aquestive Therapeutics, Inc.",
        "price": 4.1
    }, {
        "symbol": "ARAV",
        "label": "Aravive, Inc.",
        "price": 12.53
    }, {
        "symbol": "ARCE",
        "label": "Arco Platform Limited",
        "price": 50.43
    }, {
        "symbol": "ARDS",
        "label": "Aridis Pharmaceuticals, Inc.",
        "price": 7.7
    }, {
        "symbol": "ARTL",
        "label": "Artelo Biosciences, Inc.",
        "price": 2.31
    }, {
        "symbol": "ARVN",
        "label": "Arvinas, Inc.",
        "price": 50.91
    }, {
        "symbol": "ARYA",
        "label": "ARYA Sciences Acquisition Corp.",
        "price": 10.6
    }, {
        "symbol": "ARYAU",
        "label": "ARYA Sciences Acquisition Corp.",
        "price": 11.6
    }, {
        "symbol": "ASLN",
        "label": "ASLAN Pharmaceuticals Limited",
        "price": 2.63
    }, {
        "symbol": "ASRT",
        "label": "Assertio Therapeutics, Inc.",
        "price": 1.24
    }, {
        "symbol": "ATIF",
        "label": "ATIF Holdings Limited",
        "price": 1.83
    }, {
        "symbol": "AUB",
        "label": "Atlantic Union Bankshares Corporation",
        "price": 36.8
    }, {
        "symbol": "BCEL",
        "label": "Atreca, Inc.",
        "price": 14.84
    }, {
        "symbol": "ATIS",
        "label": "Attis Industries, Inc.",
        "price": 0.36
    }, {
        "symbol": "AEYE",
        "label": "AudioEye, Inc.",
        "price": 4.65
    }, {
        "symbol": "JG",
        "label": "Aurora Mobile Limited",
        "price": 2.65
    }, {
        "symbol": "AUTL",
        "label": "Autolus Therapeutics plc",
        "price": 12.55
    }, {
        "symbol": "AVCO",
        "label": "Avalon GloboCare Corp.",
        "price": 1.9
    }, {
        "symbol": "AVRO",
        "label": "AVROBIO, Inc.",
        "price": 22.78
    }, {
        "symbol": "AXLA",
        "label": "Axcella Health Inc.",
        "price": 4.98
    }, {
        "symbol": "AXNX",
        "label": "Axonics Modulation Technologies, Inc.",
        "price": 31.5
    }, {
        "symbol": "AXGT",
        "label": "Axovant Gene Therapies Ltd.",
        "price": 4.43
    }, {
        "symbol": "BFC",
        "label": "Bank First Corporation",
        "price": 66.7
    }, {
        "symbol": "OZK",
        "label": "Bank OZK",
        "price": 29.25
    }, {
        "symbol": "BSVN",
        "label": "Bank7 Corp.",
        "price": 18.66
    }, {
        "symbol": "GLG",
        "label": "Bat Group, Inc.",
        "price": 1.62
    }, {
        "symbol": "BCML",
        "label": "BayCom Corp",
        "price": 22.55
    }, {
        "symbol": "BLU",
        "label": "BELLUS Health Inc.",
        "price": 8.77
    }, {
        "symbol": "BNTCW",
        "label": "Benitec Biopharma Limited",
        "price": 0.0971
    }, {
        "symbol": "BRY",
        "label": "Berry Petroleum Corporation",
        "price": 8.07
    }, {
        "symbol": "XAIR",
        "label": "Beyond Air, Inc.",
        "price": 6.65
    }, {
        "symbol": "BYND",
        "label": "Beyond Meat, Inc.",
        "price": 109.12
    }, {
        "symbol": "BCYC",
        "label": "Bicycle Therapeutics plc",
        "price": 11.2
    }, {
        "symbol": "BILI",
        "label": "Bilibili Inc.",
        "price": 22.8
    }, {
        "symbol": "BCDA",
        "label": "BioCardia, Inc.",
        "price": 5.14
    }, {
        "symbol": "BHTG",
        "label": "BioHiTech Global, Inc.",
        "price": 1.96
    }, {
        "symbol": "BNGO",
        "label": "Bionano Genomics, Inc.",
        "price": 1.2
    }, {
        "symbol": "BNKL",
        "label": "Bionik Laboratories Corp.",
        "price": 1.55
    }, {
        "symbol": "BSGM",
        "label": "BioSig Technologies, Inc.",
        "price": 5.3
    }, {
        "symbol": "BIVI",
        "label": "BioVie Inc.",
        "price": 2.8
    }, {
        "symbol": "BHAT",
        "label": "Fujian Blue Hat Interactive Entertainment Technology Ltd.",
        "price": 1.97
    }, {
        "symbol": "BWMC",
        "label": "Boxwood Merger Corp.",
        "price": 10.18
    }, {
        "symbol": "BWMCU",
        "label": "Boxwood Merger Corp.",
        "price": 10.6
    }, {
        "symbol": "BWAY",
        "label": "Brainsway Ltd.",
        "price": 11.71
    }, {
        "symbol": "BBI",
        "label": "Brickell Biotech, Inc.",
        "price": 1.63
    }, {
        "symbol": "BBIO",
        "label": "BridgeBio Pharma, Inc.",
        "price": 34.4
    }, {
        "symbol": "BHFAP",
        "label": "Brighthouse Financial, Inc.",
        "price": 27.69
    }, {
        "symbol": "BPYPO",
        "label": "Brookfield Property Partners L.P.",
        "price": 26.22
    }, {
        "symbol": "BPYPP",
        "label": "Brookfield Property Partners L.P.",
        "price": 26.21
    }, {
        "symbol": "BPR",
        "label": "Brookfield Property REIT Inc.",
        "price": 19.83
    }, {
        "symbol": "BPRAP",
        "label": "Brookfield Property REIT Inc.",
        "price": 25.36
    }, {
        "symbol": "DOOO",
        "label": "BRP Inc.",
        "price": 47.85
    }, {
        "symbol": "BFST",
        "label": "Business First Bancshares, Inc.",
        "price": 24.73
    }, {
        "symbol": "CMBM",
        "label": "Cambium Networks Corporation",
        "price": 8.54
    }, {
        "symbol": "CBNK",
        "label": "Capital Bancorp, Inc.",
        "price": 14.44
    }, {
        "symbol": "CARE",
        "label": "Carter Bank & Trust",
        "price": 21.02
    }, {
        "symbol": "SAVA",
        "label": "Cassava Sciences, Inc.",
        "price": 8.25
    }, {
        "symbol": "CSTL",
        "label": "Castle Biosciences, Inc.",
        "price": 32.39
    }, {
        "symbol": "CTRM",
        "label": "Castor Maritime Inc.",
        "price": 1.86
    }, {
        "symbol": "CBAT",
        "label": "CBAK Energy Technology, Inc.",
        "price": 0.85
    }, {
        "symbol": "CBMB",
        "label": "CBM Bancorp, Inc.",
        "price": 14.17
    }, {
        "symbol": "CTRC",
        "label": "Centric Brands Inc.",
        "price": 2.09
    }, {
        "symbol": "CFFA",
        "label": "CF Finance Acquisition Corp.",
        "price": 10.38
    }, {
        "symbol": "CFFAU",
        "label": "CF Finance Acquisition Corp.",
        "price": 10.97
    }, {
        "symbol": "CHNG",
        "label": "Change Healthcare Inc.",
        "price": 16.77
    }, {
        "symbol": "CTAC",
        "label": "ChaSerg Technology Acquisition Corp.",
        "price": 11.0
    }, {
        "symbol": "CTACU",
        "label": "ChaSerg Technology Acquisition Corp.",
        "price": 12.15
    }, {
        "symbol": "CSSEP",
        "label": "Chicken Soup for the Soul Entertainment, Inc.",
        "price": 26.01
    }, {
        "symbol": "CIH",
        "label": "China Index Holdings Limited",
        "price": 3.1
    }, {
        "symbol": "SXTC",
        "label": "China SXT Pharmaceuticals, Inc.",
        "price": 0.88
    }, {
        "symbol": "PLIN",
        "label": "China Xiangtai Food Co., Ltd.",
        "price": 4.13
    }, {
        "symbol": "CMCTP",
        "label": "CIM Commercial Trust Corporation",
        "price": 26.39
    }, {
        "symbol": "CLPS",
        "label": "CLPS Incorporation",
        "price": 4.01
    }, {
        "symbol": "CCB",
        "label": "Coastal Financial Corporation",
        "price": 17.33
    }, {
        "symbol": "CLBK",
        "label": "Columbia Financial, Inc.",
        "price": 16.88
    }, {
        "symbol": "SCOR",
        "label": "comScore, Inc.",
        "price": 5.18
    }, {
        "symbol": "BBCP",
        "label": "Concrete Pumping Holdings, Inc.",
        "price": 4.845
    }, {
        "symbol": "CNST",
        "label": "Constellation Pharmaceuticals, Inc.",
        "price": 41.0
    }, {
        "symbol": "ROAD",
        "label": "Construction Partners, Inc.",
        "price": 17.42
    }, {
        "symbol": "CPAA",
        "label": "Conyers Park II Acquisition Corp.",
        "price": 10.6
    }, {
        "symbol": "CPAAU",
        "label": "Conyers Park II Acquisition Corp.",
        "price": 11.11
    }, {
        "symbol": "AWSM",
        "label": "Cool Holdings, Inc.",
        "price": 0.061875
    }, {
        "symbol": "CORV",
        "label": "Correvio Pharma Corp.",
        "price": 0.3761
    }, {
        "symbol": "CRTX",
        "label": "Cortexyme, Inc.",
        "price": 58.64
    }, {
        "symbol": "CLDB",
        "label": "Cortland Bancorp",
        "price": 20.1999
    }, {
        "symbol": "CVET",
        "label": "Covetrus, Inc.",
        "price": 15.01
    }, {
        "symbol": "CREX",
        "label": "Creative Realities, Inc.",
        "price": 1.57
    }, {
        "symbol": "CRSA",
        "label": "Crescent Acquisition Corp.",
        "price": 10.07
    }, {
        "symbol": "CRSAU",
        "label": "Crescent Acquisition Corp.",
        "price": 10.45
    }, {
        "symbol": "CRNX",
        "label": "Crinetics Pharmaceuticals, Inc.",
        "price": 23.79
    }, {
        "symbol": "CFB",
        "label": "CrossFirst Bankshares, Inc.",
        "price": 13.08
    }, {
        "symbol": "CRWD",
        "label": "CrowdStrike Holdings, Inc.",
        "price": 63.45
    }, {
        "symbol": "CMLS",
        "label": "Cumulus Media Inc.",
        "price": 15.41
    }, {
        "symbol": "CYCN",
        "label": "Cyclerion Therapeutics, Inc.",
        "price": 3.24
    }, {
        "symbol": "DTSS",
        "label": "Datasea Inc.",
        "price": 2.0
    }, {
        "symbol": "DDMX",
        "label": "DD3 Acquisition Corp.",
        "price": 10.21
    }, {
        "symbol": "DMTK",
        "label": "DermTech, Inc.",
        "price": 11.41
    }, {
        "symbol": "DMAC",
        "label": "DiaMedica Therapeutics Inc.",
        "price": 4.4
    }, {
        "symbol": "DEAC",
        "label": "Diamond Eagle Acquisition Corp.",
        "price": 13.57
    }, {
        "symbol": "DEACU",
        "label": "Diamond Eagle Acquisition Corp.",
        "price": 14.79
    }, {
        "symbol": "DPHC",
        "label": "DiamondPeak Holdings Corp.",
        "price": 10.04
    }, {
        "symbol": "DPHCU",
        "label": "DiamondPeak Holdings Corp.",
        "price": 10.11
    }, {
        "symbol": "DOCU",
        "label": "DocuSign, Inc.",
        "price": 72.53
    }, {
        "symbol": "DOMO",
        "label": "Domo, Inc.",
        "price": 24.11
    }, {
        "symbol": "DOYU",
        "label": "DouYu International Holdings Limited",
        "price": 9.37
    }, {
        "symbol": "DBX",
        "label": "Dropbox, Inc.",
        "price": 18.02
    }, {
        "symbol": "DYAI",
        "label": "Dyadic International, Inc.",
        "price": 5.83
    }, {
        "symbol": "EDSA",
        "label": "Edesa Biotech, Inc.",
        "price": 3.58
    }, {
        "symbol": "EDNT",
        "label": "Edison Nation, Inc.",
        "price": 2.39
    }, {
        "symbol": "EDTX",
        "label": "EdtechX Holdings Acquisition Corp.",
        "price": 10.29
    }, {
        "symbol": "EDTXU",
        "label": "EdtechX Holdings Acquisition Corp.",
        "price": 10.85
    }, {
        "symbol": "EIDX",
        "label": "Eidos Therapeutics, Inc.",
        "price": 55.39
    }, {
        "symbol": "SOLO",
        "label": "Electrameccanica Vehicles Corp.",
        "price": 2.3
    }, {
        "symbol": "ECOR",
        "label": "electroCore, Inc.",
        "price": 1.38
    }, {
        "symbol": "ELOX",
        "label": "Eloxx Pharmaceuticals, Inc.",
        "price": 4.47
    }, {
        "symbol": "EMMA",
        "label": "Emmaus Life Sciences, Inc.",
        "price": 1.86
    }, {
        "symbol": "ENLV",
        "label": "Enlivex Therapeutics Ltd.",
        "price": 8.928
    }, {
        "symbol": "ENOB",
        "label": "Enochian Biosciences Inc.",
        "price": 5.19
    }, {
        "symbol": "ESGRO",
        "label": "Enstar Group Limited",
        "price": 27.69
    }, {
        "symbol": "ESGRP",
        "label": "Enstar Group Limited",
        "price": 28.63
    }, {
        "symbol": "ENTX",
        "label": "Entera Bio Ltd.",
        "price": 2.8
    }, {
        "symbol": "EVSI",
        "label": "Envision Solar International, Inc.",
        "price": 6.45
    }, {
        "symbol": "EPSN",
        "label": "Epsilon Energy Ltd.",
        "price": 3.0125
    }, {
        "symbol": "EQ",
        "label": "Equillium, Inc.",
        "price": 4.62
    }, {
        "symbol": "ESTA",
        "label": "Establishment Labs Holdings Inc.",
        "price": 27.41
    }, {
        "symbol": "ETON",
        "label": "Eton Pharmaceuticals, Inc.",
        "price": 7.14
    }, {
        "symbol": "EDRY",
        "label": "EuroDry Ltd.",
        "price": 6.9625
    }, {
        "symbol": "EVLO",
        "label": "Evelo Biosciences, Inc.",
        "price": 4.34
    }, {
        "symbol": "EVER",
        "label": "EverQuote, Inc.",
        "price": 32.1
    }, {
        "symbol": "EVOP",
        "label": "EVO Payments, Inc.",
        "price": 28.385
    }, {
        "symbol": "XCUR",
        "label": "Exicure, Inc.",
        "price": 2.49
    }, {
        "symbol": "EXPI",
        "label": "eXp World Holdings, Inc.",
        "price": 10.83
    }, {
        "symbol": "EYPT",
        "label": "EyePoint Pharmaceuticals, Inc.",
        "price": 1.7
    }, {
        "symbol": "FLMN",
        "label": "Falcon Minerals Corporation",
        "price": 6.76
    }, {
        "symbol": "FLLCU",
        "label": "Fellazo Inc.",
        "price": 10.12
    }, {
        "symbol": "FITBP",
        "label": "Fifth Third Bancorp",
        "price": 27.29
    }, {
        "symbol": "FTAC",
        "label": "FinTech Acquisition Corp. III",
        "price": 10.2
    }, {
        "symbol": "FTACU",
        "label": "FinTech Acquisition Corp. III",
        "price": 10.9
    }, {
        "symbol": "FTACW",
        "label": "FinTech Acquisition Corp. III",
        "price": 1.35
    }, {
        "symbol": "FCBP",
        "label": "First Choice Bancorp",
        "price": 25.88
    }, {
        "symbol": "FXNC",
        "label": "First National Corporation",
        "price": 20.52
    }, {
        "symbol": "FSEA",
        "label": "First Seacoast Bancorp",
        "price": 9.43
    }, {
        "symbol": "DDIV",
        "label": "First Trust Dorsey Wright Momentum & Dividend ETF",
        "price": 27.2837
    }, {
        "symbol": "DVOL",
        "label": "First Trust Dorsey Wright Momentum & Low Volatility ETF",
        "price": 22.9801
    }, {
        "symbol": "DVLU",
        "label": "First Trust Dorsey Wright Momentum & Value ETF",
        "price": 20.0888
    }, {
        "symbol": "DALI",
        "label": "First Trust Dorsey Wright DALI 1 ETF",
        "price": 21.8809
    }, {
        "symbol": "FDNI",
        "label": "First Trust Dow Jones International Internet ETF",
        "price": 26.19
    }, {
        "symbol": "NFTY",
        "label": "First Trust India NIFTY 50 Equal Weight ETF",
        "price": 35.6588
    }, {
        "symbol": "NXTG",
        "label": "First Trust IndXX NextG ETF",
        "price": 56.98
    }, {
        "symbol": "FPXE",
        "label": "First Trust IPOX Europe Equity Opportunities ETF",
        "price": 22.18
    }, {
        "symbol": "LDSF",
        "label": "First Trust Low Duration Strategic Focus ETF",
        "price": 20.58
    }, {
        "symbol": "FID",
        "label": "First Trust S&P International Dividend Aristocrats ETF",
        "price": 18.4461
    }, {
        "symbol": "MYFW",
        "label": "First Western Financial, Inc.",
        "price": 16.6
    }, {
        "symbol": "FLNT",
        "label": "Fluent, Inc.",
        "price": 2.95
    }, {
        "symbol": "FLUX",
        "label": "Flux Power Holdings, Inc.",
        "price": 9.0
    }, {
        "symbol": "FOCS",
        "label": "Focus Financial Partners Inc.",
        "price": 28.26
    }, {
        "symbol": "FTSV",
        "label": "Forty Seven, Inc.",
        "price": 45.74
    }, {
        "symbol": "FMCI",
        "label": "Forum Merger II Corporation",
        "price": 10.3
    }, {
        "symbol": "FMCIU",
        "label": "Forum Merger II Corporation",
        "price": 10.98
    }, {
        "symbol": "FRAF",
        "label": "Franklin Financial Services Corporation",
        "price": 37.5
    }, {
        "symbol": "FTDR",
        "label": "frontdoor, inc.",
        "price": 48.09
    }, {
        "symbol": "FULC",
        "label": "Fulcrum Therapeutics, Inc.",
        "price": 19.89
    }, {
        "symbol": "FVCB",
        "label": "FVCBankcorp, Inc.",
        "price": 16.89
    }, {
        "symbol": "GMDA",
        "label": "Gamida Cell Ltd.",
        "price": 4.58
    }, {
        "symbol": "GNFT",
        "label": "Genfit SA",
        "price": 20.37
    }, {
        "symbol": "GMAB",
        "label": "Genmab A/S",
        "price": 22.75
    }, {
        "symbol": "GNPX",
        "label": "Genprex, Inc.",
        "price": 0.36
    }, {
        "symbol": "GAINL",
        "label": "Gladstone Investment Corporation",
        "price": 26.3
    }, {
        "symbol": "GBLIZ",
        "label": "Global Indemnity Limited",
        "price": 26.1102
    }, {
        "symbol": "DRIV",
        "label": "Global X Autonomous & Electric Vehicles ETF",
        "price": 15.55
    }, {
        "symbol": "CLOU",
        "label": "Global X Cloud Computing ETF Global X Cloud Computing ETF",
        "price": 17.2033
    }, {
        "symbol": "EBIZ",
        "label": "Global X E-commerce ETF",
        "price": 19.675
    }, {
        "symbol": "AIQ",
        "label": "Global X Future Analytics Tech ETF",
        "price": 18.98
    }, {
        "symbol": "GNOM",
        "label": "Global X Genomics & Biotechnology ETF",
        "price": 16.2851
    }, {
        "symbol": "GSHD",
        "label": "Goosehead Insurance, Inc",
        "price": 45.89
    }, {
        "symbol": "GRSH",
        "label": "Gores Holdings III, Inc.",
        "price": 10.8
    }, {
        "symbol": "GRSHU",
        "label": "Gores Holdings III, Inc.",
        "price": 11.45
    }, {
        "symbol": "GMHI",
        "label": "Gores Metropoulos, Inc.",
        "price": 10.55
    }, {
        "symbol": "GMHIU",
        "label": "Gores Metropoulos, Inc.",
        "price": 11.1
    }, {
        "symbol": "GOSS",
        "label": "Gossamer Bio, Inc.",
        "price": 14.34
    }, {
        "symbol": "GNLN",
        "label": "Greenlane Holdings, Inc.",
        "price": 3.11
    }, {
        "symbol": "GRNQ",
        "label": "Greenpro Capital Corp.",
        "price": 0.58
    }, {
        "symbol": "GSKY",
        "label": "GreenSky, Inc.",
        "price": 8.98
    }, {
        "symbol": "GRIN",
        "label": "Grindrod Shipping Holdings Ltd.",
        "price": 6.8
    }, {
        "symbol": "GRTS",
        "label": "Gritstone Oncology, Inc.",
        "price": 11.23
    }, {
        "symbol": "GO",
        "label": "Grocery Outlet Holding Corp.",
        "price": 34.44
    }, {
        "symbol": "GH",
        "label": "Guardant Health, Inc.",
        "price": 84.6
    }, {
        "symbol": "GHSI",
        "label": "Guardion Health Sciences, Inc.",
        "price": 0.3125
    }, {
        "symbol": "GXGX",
        "label": "GX Acquisition Corp.",
        "price": 10.04
    }, {
        "symbol": "GXGXU",
        "label": "GX Acquisition Corp.",
        "price": 10.45
    }, {
        "symbol": "HJLI",
        "label": "Hancock Jaffe Laboratories, Inc.",
        "price": 0.598
    }, {
        "symbol": "HWC",
        "label": "Hancock Whitney Corporation",
        "price": 43.56
    }, {
        "symbol": "HARP",
        "label": "Harpoon Therapeutics, Inc.",
        "price": 14.5
    }, {
        "symbol": "HROW",
        "label": "Harrow Health, Inc.",
        "price": 7.06
    }, {
        "symbol": "HHR",
        "label": "HeadHunter Group PLC",
        "price": 23.32
    }, {
        "symbol": "HCAT",
        "label": "Health Catalyst, Inc.",
        "price": 34.12
    }, {
        "symbol": "HLIO",
        "label": "Helios Technologies, Inc.",
        "price": 47.38
    }, {
        "symbol": "HSDT",
        "label": "Helius Medical Technologies, Inc.",
        "price": 1.12
    }, {
        "symbol": "HCACU",
        "label": "Hennessy Capital Acquisition Corp. IV",
        "price": 10.9
    }, {
        "symbol": "HCACW",
        "label": "Hennessy Capital Acquisition Corp. IV",
        "price": 0.92
    }, {
        "symbol": "HEPA",
        "label": "Hepion Pharmaceuticals, Inc.",
        "price": 5.74
    }, {
        "symbol": "HFFG",
        "label": "HF Foods Group Inc.",
        "price": 16.49
    }, {
        "symbol": "HCCH",
        "label": "HL Acquisition Corp.",
        "price": 10.5
    }, {
        "symbol": "HCCHU",
        "label": "HL Acquisition Corp.",
        "price": 10.95
    }, {
        "symbol": "FIXX",
        "label": "Homology Medicines, Inc.",
        "price": 17.34
    }, {
        "symbol": "HOOK",
        "label": "HOOKIPA Pharma Inc.",
        "price": 11.19
    }, {
        "symbol": "TWNKW",
        "label": "Hostess Brands, Inc.",
        "price": 1.62
    }, {
        "symbol": "HOTH",
        "label": "Hoth Therapeutics, Inc.",
        "price": 5.05
    }, {
        "symbol": "HHT",
        "label": "Huitao Technology Co., Ltd.",
        "price": 1.47
    }, {
        "symbol": "HYRE",
        "label": "HyreCar Inc.",
        "price": 3.36
    }, {
        "symbol": "IIIV",
        "label": "i3 Verticals, Inc.",
        "price": 31.8
    }, {
        "symbol": "IBKCN",
        "label": "IBERIABANK Corporation",
        "price": 26.82
    }, {
        "symbol": "IDEX",
        "label": "Ideanomics, Inc.",
        "price": 0.6983
    }, {
        "symbol": "IDYA",
        "label": "IDEAYA Biosciences, Inc.",
        "price": 10.03
    }, {
        "symbol": "IHRT",
        "label": "iHeartMedia, Inc.",
        "price": 17.99
    }, {
        "symbol": "IMAC",
        "label": "IMAC Holdings, Inc.",
        "price": 1.34
    }, {
        "symbol": "IMBI",
        "label": "iMedia Brands, Inc.",
        "price": 4.48
    }, {
        "symbol": "IMUX",
        "label": "Immunic, Inc.",
        "price": 9.53
    }, {
        "symbol": "IMV",
        "label": "IMV Inc.",
        "price": 4.0
    }, {
        "symbol": "IEA",
        "label": "Infrastructure and Energy Alternatives, Inc.",
        "price": 3.69
    }, {
        "symbol": "INMD",
        "label": "InMode Ltd.",
        "price": 45.05
    }, {
        "symbol": "INMB",
        "label": "INmune Bio Inc.",
        "price": 5.65
    }, {
        "symbol": "INSU",
        "label": "Insurance Acquisition Corp.",
        "price": 10.05
    }, {
        "symbol": "INSUU",
        "label": "Insurance Acquisition Corp.",
        "price": 10.55
    }, {
        "symbol": "IMXI",
        "label": "International Money Express, Inc.",
        "price": 12.26
    }, {
        "symbol": "IPLDP",
        "label": "Interstate Power and Light Company",
        "price": 25.55
    }, {
        "symbol": "ISDX",
        "label": "Invesco RAFI Strategic Developed ex-US ETF",
        "price": 26.61
    }, {
        "symbol": "ISDS",
        "label": "Invesco RAFI Strategic Developed ex-US Small Company ETF",
        "price": 24.82
    }, {
        "symbol": "ISEM",
        "label": "Invesco RAFI Strategic Emerging Markets ETF",
        "price": 27.665
    }, {
        "symbol": "IUS",
        "label": "Invesco RAFI Strategic US ETF",
        "price": 28.4173
    }, {
        "symbol": "IUSS",
        "label": "Invesco RAFI Strategic US Small Company ETF",
        "price": 25.335
    }, {
        "symbol": "ICMB",
        "label": "Investcorp Credit Management BDC, Inc.",
        "price": 7.01
    }, {
        "symbol": "IQ",
        "label": "iQIYI, Inc.",
        "price": 23.91
    }, {
        "symbol": "USIG",
        "label": "iShares Broad USD Investment Grade Corporate Bond ETF",
        "price": 58.71
    }, {
        "symbol": "SUSL",
        "label": "iShares ESG MSCI USA Leaders ETF",
        "price": 57.5176
    }, {
        "symbol": "BGRN",
        "label": "iShares Global Green Bond ETF",
        "price": 54.04
    }, {
        "symbol": "IGIB",
        "label": "iShares Intermediate-Term Corporate Bond ETF",
        "price": 58.39
    }, {
        "symbol": "SDG",
        "label": "iShares MSCI Global Impact ETF",
        "price": 67.84
    }, {
        "symbol": "EWJE",
        "label": "iShares MSCI Japan Equal Weighted ETF",
        "price": 35.895
    }, {
        "symbol": "EWJV",
        "label": "iShares MSCI Japan Value ETF",
        "price": 25.955
    }, {
        "symbol": "IGSB",
        "label": "iShares Short-Term Corporate Bond ETF",
        "price": 53.785
    }, {
        "symbol": "ITMR",
        "label": "Itamar Medical Ltd.",
        "price": 15.4
    }, {
        "symbol": "ITRM",
        "label": "Iterum Therapeutics plc",
        "price": 3.3
    }, {
        "symbol": "ISEE",
        "label": "IVERIC bio, Inc.",
        "price": 6.78
    }, {
        "symbol": "JRSH",
        "label": "Jerash Holdings (US), Inc.",
        "price": 6.21
    }, {
        "symbol": "JFIN",
        "label": "Jiayin Group Inc.",
        "price": 5.33
    }, {
        "symbol": "KXIN",
        "label": "Kaixin Auto Holdings",
        "price": 1.25
    }, {
        "symbol": "KLDO",
        "label": "Kaleido BioSciences, Inc.",
        "price": 4.92
    }, {
        "symbol": "KRTX",
        "label": "Karuna Therapeutics, Inc.",
        "price": 104.52
    }, {
        "symbol": "KZR",
        "label": "Kezar Life Sciences, Inc.",
        "price": 3.27
    }, {
        "symbol": "KNSA",
        "label": "Kiniksa Pharmaceuticals, Ltd.",
        "price": 15.45
    }, {
        "symbol": "KLXE",
        "label": "KLX Energy Services Holdings, Inc.",
        "price": 5.3
    }, {
        "symbol": "KOD",
        "label": "Kodiak Sciences Inc.",
        "price": 66.06
    }, {
        "symbol": "KRUS",
        "label": "Kura Sushi USA, Inc.",
        "price": 24.24
    }, {
        "symbol": "LEGH",
        "label": "Legacy Housing Corporation",
        "price": 14.96
    }, {
        "symbol": "LEVL",
        "label": "Level One Bancorp, Inc.",
        "price": 25.38
    }, {
        "symbol": "LFAC",
        "label": "LF Capital Acquisition Corp.",
        "price": 10.4
    }, {
        "symbol": "LFACU",
        "label": "LF Capital Acquisition Corp.",
        "price": 10.58
    }, {
        "symbol": "LMST",
        "label": "Limestone Bancorp, Inc.",
        "price": 17.15
    }, {
        "symbol": "YVR",
        "label": "Liquid Media Group Ltd.",
        "price": 1.72
    }, {
        "symbol": "LQDA",
        "label": "Liquidia Technologies, Inc.",
        "price": 6.42
    }, {
        "symbol": "LVGO",
        "label": "Livongo Health, Inc.",
        "price": 29.45
    }, {
        "symbol": "LOGC",
        "label": "LogicBio Therapeutics, Inc.",
        "price": 7.71
    }, {
        "symbol": "CHNA",
        "label": "Loncar China BioPharma ETF",
        "price": 26.4214
    }, {
        "symbol": "LOAC",
        "label": "Longevity Acquisition Corporation",
        "price": 10.5
    }, {
        "symbol": "LOACU",
        "label": "Longevity Acquisition Corporation",
        "price": 10.65
    }, {
        "symbol": "LK",
        "label": "Luckin Coffee Inc.",
        "price": 50.02
    }, {
        "symbol": "LKCO",
        "label": "Luokung Technology Corp.",
        "price": 1.29
    }, {
        "symbol": "LYFT",
        "label": "Lyft, Inc.",
        "price": 46.52
    }, {
        "symbol": "MGTA",
        "label": "Magenta Therapeutics, Inc.",
        "price": 14.12
    }, {
        "symbol": "MNSB",
        "label": "MainStreet Bancshares, Inc.",
        "price": 22.96
    }, {
        "symbol": "MRKR",
        "label": "Marker Therapeutics, Inc.",
        "price": 3.2
    }, {
        "symbol": "MDJH",
        "label": "MDJM Ltd.",
        "price": 3.1658
    }, {
        "symbol": "MGTX",
        "label": "MeiraGTx Holdings plc",
        "price": 20.38
    }, {
        "symbol": "MBINO",
        "label": "Merchants Bancorp",
        "price": 26.55
    }, {
        "symbol": "MBINP",
        "label": "Merchants Bancorp",
        "price": 27.44
    }, {
        "symbol": "MREO",
        "label": "Mereo BioPharma Group plc",
        "price": 2.175
    }, {
        "symbol": "MESA",
        "label": "Mesa Air Group, Inc.",
        "price": 9.27
    }, {
        "symbol": "MSVB",
        "label": "Mid-Southern Bancorp, Inc.",
        "price": 13.42
    }, {
        "symbol": "MIST",
        "label": "Milestone Pharmaceuticals Inc.",
        "price": 18.47
    }, {
        "symbol": "MLND",
        "label": "Millendo Therapeutics, Inc.",
        "price": 7.86
    }, {
        "symbol": "TIGO",
        "label": "Millicom International Cellular S.A.",
        "price": 48.39
    }, {
        "symbol": "MIRM",
        "label": "Mirum Pharmaceuticals, Inc.",
        "price": 20.23
    }, {
        "symbol": "MTC",
        "label": "Mmtec, Inc.",
        "price": 2.35
    }, {
        "symbol": "MRNA",
        "label": "Moderna, Inc.",
        "price": 20.62
    }, {
        "symbol": "MOGO",
        "label": "Mogo Inc.",
        "price": 2.76
    }, {
        "symbol": "MWK",
        "label": "Mohawk Group Holdings, Inc.",
        "price": 5.65
    }, {
        "symbol": "MNCL",
        "label": "Monocle Acquisition Corporation",
        "price": 10.18
    }, {
        "symbol": "MNCLU",
        "label": "Monocle Acquisition Corporation",
        "price": 10.8
    }, {
        "symbol": "MORF",
        "label": "Morphic Holding, Inc.",
        "price": 19.69
    }, {
        "symbol": "MOR",
        "label": "MorphoSys AG",
        "price": 32.88
    }, {
        "symbol": "COOP",
        "label": "Mr. Cooper Group Inc.",
        "price": 13.63
    }, {
        "symbol": "NRC",
        "label": "National Research Corporation",
        "price": 66.65
    }, {
        "symbol": "NTUS",
        "label": "Natus Medical Incorporated",
        "price": 34.33
    }, {
        "symbol": "NMCI",
        "label": "Navios Maritime Containers L.P.",
        "price": 1.83
    }, {
        "symbol": "NLTX",
        "label": "Neoleukin Therapeutics, Inc.",
        "price": 10.56
    }, {
        "symbol": "NTGN",
        "label": "Neon Therapeutics, Inc.",
        "price": 1.69
    }, {
        "symbol": "NEPH",
        "label": "Nephros, Inc.",
        "price": 9.49
    }, {
        "symbol": "NFIN",
        "label": "Netfin Acquisition Corp.",
        "price": 10.0
    }, {
        "symbol": "NFINU",
        "label": "Netfin Acquisition Corp.",
        "price": 10.3618
    }, {
        "symbol": "NBSE",
        "label": "NeuBase Therapeutics, Inc.",
        "price": 6.8
    }, {
        "symbol": "NUROW",
        "label": "NeuroMetrix, Inc.",
        "price": 0.3
    }, {
        "symbol": "STIM",
        "label": "Neuronetics, Inc.",
        "price": 4.39
    }, {
        "symbol": "NFE",
        "label": "New Fortress Energy LLC",
        "price": 15.62
    }, {
        "symbol": "NXTC",
        "label": "NextCure, Inc.",
        "price": 50.12
    }, {
        "symbol": "NXGN",
        "label": "NextGen Healthcare, Inc.",
        "price": 15.9
    }, {
        "symbol": "BIMI",
        "label": "NF Energy Saving Corporation",
        "price": 2.53
    }, {
        "symbol": "NGM",
        "label": "NGM Biopharmaceuticals, Inc.",
        "price": 18.9
    }, {
        "symbol": "NIU",
        "label": "Niu Technologies",
        "price": 9.1
    }, {
        "symbol": "LASR",
        "label": "nLIGHT, Inc.",
        "price": 21.52
    }, {
        "symbol": "OPI",
        "label": "Office Properties Income Trust",
        "price": 34.26
    }, {
        "symbol": "OPINI",
        "label": "Office Properties Income Trust",
        "price": 26.02
    }, {
        "symbol": "OCCI",
        "label": "OFS Credit Company, Inc.",
        "price": 17.2
    }, {
        "symbol": "OCCIP",
        "label": "OFS Credit Company, Inc.",
        "price": 25.98
    }, {
        "symbol": "ONCY",
        "label": "Oncolytics Biotech Inc.",
        "price": 3.1
    }, {
        "symbol": "ONCT",
        "label": "Oncternal Therapeutics, Inc.",
        "price": 4.18
    }, {
        "symbol": "OSPN",
        "label": "OneSpan Inc.",
        "price": 20.045
    }, {
        "symbol": "OSW",
        "label": "OneSpaWorld Holdings Limited",
        "price": 16.0
    }, {
        "symbol": "OPBK",
        "label": "OP Bancorp",
        "price": 9.9
    }, {
        "symbol": "OPRA",
        "label": "Opera Limited",
        "price": 7.055
    }, {
        "symbol": "OPES",
        "label": "Opes Acquisition Corp.",
        "price": 10.55
    }, {
        "symbol": "OPRX",
        "label": "OptimizeRx Corporation",
        "price": 10.91
    }, {
        "symbol": "ORTX",
        "label": "Orchard Therapeutics plc",
        "price": 14.31
    }, {
        "symbol": "OGI",
        "label": "OrganiGram Holdings Inc.",
        "price": 3.26
    }, {
        "symbol": "ORGO",
        "label": "Organogenesis Holdings Inc.",
        "price": 5.29
    }, {
        "symbol": "OBNK",
        "label": "Origin Bancorp, Inc.",
        "price": 36.67
    }, {
        "symbol": "ORSNU",
        "label": "Orisun Acquisition Corp.",
        "price": 10.18
    }, {
        "symbol": "OSMT",
        "label": "Osmotica Pharmaceuticals plc",
        "price": 5.855
    }, {
        "symbol": "OTLK",
        "label": "Outlook Therapeutics, Inc.",
        "price": 1.04
    }, {
        "symbol": "OXSQ",
        "label": "Oxford Square Capital Corp.",
        "price": 5.94
    }, {
        "symbol": "HERD",
        "label": "Pacer Cash Cows Fund of Funds ETF",
        "price": 26.455
    }, {
        "symbol": "ECOW",
        "label": "Pacer Emerging Markets Cash Cows 100 ETF",
        "price": 26.1597
    }, {
        "symbol": "VETS",
        "label": "Pacer Military Times Best Employers ETF",
        "price": 33.0784
    }, {
        "symbol": "PLMR",
        "label": "Palomar Holdings, Inc.",
        "price": 48.56
    }, {
        "symbol": "PNRL",
        "label": "Paringa Resources Limited",
        "price": 1.44
    }, {
        "symbol": "PAYS",
        "label": "PaySign, Inc.",
        "price": 9.39
    }, {
        "symbol": "PCB",
        "label": "PCB Bancorp",
        "price": 15.68
    }, {
        "symbol": "PDSB",
        "label": "PDS Biotechnology Corporation",
        "price": 2.54
    }, {
        "symbol": "PEER",
        "label": "PeerStream, Inc.",
        "price": 0.8
    }, {
        "symbol": "PSNL",
        "label": "Personalis, Inc.",
        "price": 11.76
    }, {
        "symbol": "PHAS",
        "label": "PhaseBio Pharmaceuticals, Inc.",
        "price": 5.85
    }, {
        "symbol": "PHIO",
        "label": "Phio Pharmaceuticals Corp.",
        "price": 10.25
    }, {
        "symbol": "PHIOW",
        "label": "Phio Pharmaceuticals Corp.",
        "price": 0.053
    }, {
        "symbol": "PHUN",
        "label": "Phunware, Inc.",
        "price": 1.16
    }, {
        "symbol": "PLL",
        "label": "Piedmont Lithium Limited",
        "price": 9.45
    }, {
        "symbol": "PDD",
        "label": "Pinduoduo Inc.",
        "price": 39.43
    }, {
        "symbol": "PT",
        "label": "Pintec Technology Holdings Limited",
        "price": 2.95
    }, {
        "symbol": "PBFS",
        "label": "Pioneer Bancorp, Inc.",
        "price": 15.0
    }, {
        "symbol": "PS",
        "label": "Pluralsight, Inc.",
        "price": 19.76
    }, {
        "symbol": "PSTV",
        "label": "Plus Therapeutics, Inc.",
        "price": 2.71
    }, {
        "symbol": "PTE",
        "label": "PolarityTE, Inc.",
        "price": 3.98
    }, {
        "symbol": "PPHI",
        "label": "Positive Physicians Holdings, Inc.",
        "price": 11.4
    }, {
        "symbol": "PBTS",
        "label": "Powerbridge Technologies Co., Ltd.",
        "price": 3.8
    }, {
        "symbol": "DTIL",
        "label": "Precision BioSciences, Inc.",
        "price": 10.68
    }, {
        "symbol": "POAI",
        "label": "Predictive Oncology Inc.",
        "price": 3.54
    }, {
        "symbol": "PBIO",
        "label": "Pressure BioSciences, Inc.",
        "price": 0.8925
    }, {
        "symbol": "PRVL",
        "label": "Prevail Therapeutics Inc.",
        "price": 18.53
    }, {
        "symbol": "PLC",
        "label": "Principal U.S. Large-Cap Multi-Factor Core Index ETF",
        "price": 27.4074
    }, {
        "symbol": "PSM",
        "label": "Principal U.S. Small-MidCap Multi-Factor Core Index ETF",
        "price": 27.127
    }, {
        "symbol": "PRNB",
        "label": "Principia Biopharma Inc.",
        "price": 63.13
    }, {
        "symbol": "PRTH",
        "label": "Priority Technology Holdings, Inc.",
        "price": 3.01
    }, {
        "symbol": "PAAC",
        "label": "Proficient Alpha Acquisition Corp.",
        "price": 10.07
    }, {
        "symbol": "PAACU",
        "label": "Proficient Alpha Acquisition Corp.",
        "price": 10.34
    }, {
        "symbol": "PTVCA",
        "label": "Protective Insurance Corporation",
        "price": 15.71
    }, {
        "symbol": "PTVCB",
        "label": "Protective Insurance Corporation",
        "price": 16.22
    }, {
        "symbol": "PRVB",
        "label": "Provention Bio, Inc.",
        "price": 16.84
    }, {
        "symbol": "PHCF",
        "label": "Puhui Wealth Investment Management Co., Ltd.",
        "price": 2.17
    }, {
        "symbol": "PACQ",
        "label": "Pure Acquisition Corp.",
        "price": 10.38
    }, {
        "symbol": "PACQU",
        "label": "Pure Acquisition Corp.",
        "price": 10.538
    }, {
        "symbol": "PUYI",
        "label": "Puyi Inc.",
        "price": 5.67
    }, {
        "symbol": "QTT",
        "label": "Qutoutiao Inc.",
        "price": 4.17
    }, {
        "symbol": "RTLR",
        "label": "Rattler Midstream LP",
        "price": 17.08
    }, {
        "symbol": "BCNA",
        "label": "Reality Shares Nasdaq NexGen Economy China ETF",
        "price": 20.83
    }, {
        "symbol": "RRBI",
        "label": "Red River Bancshares, Inc.",
        "price": 55.29
    }, {
        "symbol": "RDVT",
        "label": "Red Violet, Inc.",
        "price": 20.79
    }, {
        "symbol": "RBZ",
        "label": "Reebonz Holding Limited",
        "price": 1.4
    }, {
        "symbol": "REKR",
        "label": "Rekor Systems, Inc.",
        "price": 3.96
    }, {
        "symbol": "RPAY",
        "label": "Repay Holdings Corporation",
        "price": 15.97
    }, {
        "symbol": "REPL",
        "label": "Replimune Group, Inc.",
        "price": 18.93
    }, {
        "symbol": "REXN",
        "label": "Rexahn Pharmaceuticals, Inc.",
        "price": 2.34
    }, {
        "symbol": "RBKB",
        "label": "Rhinebeck Bancorp, Inc.",
        "price": 11.21
    }, {
        "symbol": "RMBI",
        "label": "Richmond Mutual Bancorporation, Inc.",
        "price": 15.0
    }, {
        "symbol": "RIVE",
        "label": "Riverview Financial Corporation",
        "price": 12.79
    }, {
        "symbol": "RUBY",
        "label": "Rubius Therapeutics, Inc.",
        "price": 8.92
    }, {
        "symbol": "RUHN",
        "label": "Ruhnn Holding Limited",
        "price": 8.58
    }, {
        "symbol": "SFET",
        "label": "Safe-T Group Ltd",
        "price": 3.2
    }, {
        "symbol": "SLRX",
        "label": "Salarius Pharmaceuticals, Inc.",
        "price": 2.9
    }, {
        "symbol": "SRRK",
        "label": "Scholar Rock Holding Corporation",
        "price": 10.89
    }, {
        "symbol": "SAMA",
        "label": "Schultze Special Purpose Acquisition Corp.",
        "price": 10.1412
    }, {
        "symbol": "SAMAU",
        "label": "Schultze Special Purpose Acquisition Corp.",
        "price": 10.52
    }, {
        "symbol": "SCPL",
        "label": "SciPlay Corporation",
        "price": 12.13
    }, {
        "symbol": "WORX",
        "label": "SCWorx Corp.",
        "price": 2.98
    }, {
        "symbol": "SEEL",
        "label": "Seelos Therapeutics, Inc.",
        "price": 1.52
    }, {
        "symbol": "SIC",
        "label": "Select Interior Concepts, Inc.",
        "price": 9.13
    }, {
        "symbol": "SESN",
        "label": "Sesen Bio, Inc.",
        "price": 0.9701
    }, {
        "symbol": "SWAV",
        "label": "ShockWave Medical, Inc.",
        "price": 44.5
    }, {
        "symbol": "SIBN",
        "label": "SI-BONE, Inc.",
        "price": 21.76
    }, {
        "symbol": "SIGA",
        "label": "SIGA Technologies, Inc.",
        "price": 5.2
    }, {
        "symbol": "SILK",
        "label": "Silk Road Medical, Inc",
        "price": 42.82
    }, {
        "symbol": "SSPKU",
        "label": "Silver Spike Acquisition Corp.",
        "price": 10.18
    }, {
        "symbol": "SINT",
        "label": "Sintx Technologies, Inc.",
        "price": 1.58
    }, {
        "symbol": "SG",
        "label": "Sirius International Insurance Group, Ltd.",
        "price": 8.875
    }, {
        "symbol": "GIGE",
        "label": "SoFi Gig Economy ETF",
        "price": 20.14
    }, {
        "symbol": "SOLY",
        "label": "Soliton, Inc.",
        "price": 13.5
    }, {
        "symbol": "SONM",
        "label": "Sonim Technologies, Inc.",
        "price": 3.69
    }, {
        "symbol": "SONO",
        "label": "Sonos, Inc.",
        "price": 15.12
    }, {
        "symbol": "SOHON",
        "label": "Sotherly Hotels Inc.",
        "price": 26.44
    }, {
        "symbol": "SMMC",
        "label": "South Mountain Merger Corp.",
        "price": 10.0
    }, {
        "symbol": "SMMCU",
        "label": "South Mountain Merger Corp.",
        "price": 10.32
    }, {
        "symbol": "SPFI",
        "label": "South Plains Financial, Inc.",
        "price": 20.93
    }, {
        "symbol": "SY",
        "label": "So-Young International Inc.",
        "price": 14.77
    }, {
        "symbol": "STXB",
        "label": "Spirit of Texas Bancshares, Inc.",
        "price": 22.64
    }, {
        "symbol": "SLNG",
        "label": "Stabilis Energy, Inc.",
        "price": 4.35
    }, {
        "symbol": "STND",
        "label": "Standard AVB Financial Corp.",
        "price": 30.6
    }, {
        "symbol": "MITO",
        "label": "Stealth BioTherapeutics Corp",
        "price": 3.08
    }, {
        "symbol": "STOK",
        "label": "Stoke Therapeutics, Inc.",
        "price": 23.55
    }, {
        "symbol": "STNE",
        "label": "StoneCo Ltd.",
        "price": 42.04
    }, {
        "symbol": "WISA",
        "label": "Summit Wireless Technologies, Inc.",
        "price": 0.495
    }, {
        "symbol": "SNDL",
        "label": "Sundial Growers Inc.",
        "price": 3.46
    }, {
        "symbol": "SLGG",
        "label": "Super League Gaming, Inc.",
        "price": 3.49
    }, {
        "symbol": "SURF",
        "label": "Surface Oncology, Inc.",
        "price": 3.37
    }, {
        "symbol": "STRO",
        "label": "Sutro Biopharma, Inc.",
        "price": 11.95
    }, {
        "symbol": "SSSS",
        "label": "Sutter Rock Capital Corp.",
        "price": 6.74
    }, {
        "symbol": "SVMK",
        "label": "SVMK Inc.",
        "price": 19.7
    }, {
        "symbol": "THOR",
        "label": "Synthorx, Inc.",
        "price": 68.03
    }, {
        "symbol": "TLC",
        "label": "Taiwan Liposome Company, Ltd.",
        "price": 5.301
    }, {
        "symbol": "TAOP",
        "label": "Taoping Inc.",
        "price": 0.7999
    }, {
        "symbol": "TH",
        "label": "Target Hospitality Corp.",
        "price": 5.32
    }, {
        "symbol": "TRNX",
        "label": "Taronis Technologies, Inc.",
        "price": 1.13
    }, {
        "symbol": "TCFCP",
        "label": "TCF Financial Corporation",
        "price": 26.6
    }, {
        "symbol": "TCRR",
        "label": "TCR2 Therapeutics Inc.",
        "price": 14.08
    }, {
        "symbol": "TECTP",
        "label": "Tectonic Financial, Inc.",
        "price": 10.4
    }, {
        "symbol": "TENB",
        "label": "Tenable Holdings, Inc.",
        "price": 26.68
    }, {
        "symbol": "TZAC",
        "label": "Tenzing Acquisition Corp.",
        "price": 10.45
    }, {
        "symbol": "TZACU",
        "label": "Tenzing Acquisition Corp.",
        "price": 10.35
    }, {
        "symbol": "WTER",
        "label": "The Alkaline Water Company Inc.",
        "price": 1.58
    }, {
        "symbol": "BPRN",
        "label": "The Bank of Princeton",
        "price": 31.1
    }, {
        "symbol": "LOVE",
        "label": "The Lovesac Company",
        "price": 12.75
    }, {
        "symbol": "PECK",
        "label": "The Peck Company Holdings, Inc.",
        "price": 5.26
    }, {
        "symbol": "REAL",
        "label": "The RealReal, Inc.",
        "price": 17.44
    }, {
        "symbol": "THBRU",
        "label": "Thunder Bridge Acquisition II, Ltd.",
        "price": 10.6
    }, {
        "symbol": "TIBR",
        "label": "Tiberius Acquisition Corporation",
        "price": 10.58
    }, {
        "symbol": "TLRY",
        "label": "Tilray, Inc.",
        "price": 20.97
    }, {
        "symbol": "TMDI",
        "label": "Titan Medical Inc.",
        "price": 0.51
    }, {
        "symbol": "TLSA",
        "label": "Tiziana Life Sciences PLC",
        "price": 2.77
    }, {
        "symbol": "TKKS",
        "label": "TKK Symphony Acquisition Corporation",
        "price": 10.17
    }, {
        "symbol": "TKKSU",
        "label": "TKK Symphony Acquisition Corporation",
        "price": 11.03
    }, {
        "symbol": "TOTA",
        "label": "Tottenham Acquisition I Limited",
        "price": 10.45
    }, {
        "symbol": "TOTAU",
        "label": "Tottenham Acquisition I Limited",
        "price": 10.53
    }, {
        "symbol": "TBLT",
        "label": "ToughBuilt Industries, Inc.",
        "price": 0.3326
    }, {
        "symbol": "TW",
        "label": "Tradeweb Markets Inc.",
        "price": 45.75
    }, {
        "symbol": "TBIO",
        "label": "Translate Bio, Inc.",
        "price": 8.04
    }, {
        "symbol": "TMDX",
        "label": "TransMedics Group, Inc.",
        "price": 19.71
    }, {
        "symbol": "TRVI",
        "label": "Trevi Therapeutics, Inc.",
        "price": 5.33
    }, {
        "symbol": "TPCO",
        "label": "Tribune Publishing Company",
        "price": 13.03
    }, {
        "symbol": "TCDA",
        "label": "Tricida, Inc.",
        "price": 34.86
    }, {
        "symbol": "TDAC",
        "label": "Trident Acquisitions Corp.",
        "price": 10.52
    }, {
        "symbol": "TDACU",
        "label": "Trident Acquisitions Corp.",
        "price": 10.53
    }, {
        "symbol": "TSCAP",
        "label": "TriState Capital Holdings, Inc.",
        "price": 26.85
    }, {
        "symbol": "TSCBP",
        "label": "TriState Capital Holdings, Inc.",
        "price": 26.75
    }, {
        "symbol": "TC",
        "label": "TuanChe Limited",
        "price": 1.55
    }, {
        "symbol": "TPTX",
        "label": "Turning Point Therapeutics, Inc.",
        "price": 59.95
    }, {
        "symbol": "THCB",
        "label": "Tuscan Holdings Corp.",
        "price": 10.03
    }, {
        "symbol": "THCBU",
        "label": "Tuscan Holdings Corp.",
        "price": 10.5668
    }, {
        "symbol": "THCA",
        "label": "Tuscan Holdings Corp. II",
        "price": 9.89
    }, {
        "symbol": "THCAU",
        "label": "Tuscan Holdings Corp. II",
        "price": 10.14
    }, {
        "symbol": "BROG",
        "label": "Brooge Holdings Limited",
        "price": 8.79
    }, {
        "symbol": "BROGW",
        "label": "Twelve Seas Investment Company",
        "price": 0.64
    }, {
        "symbol": "TWST",
        "label": "Twist Bioscience Corporation",
        "price": 22.74
    }, {
        "symbol": "USWS",
        "label": "U.S. Well Services, Inc.",
        "price": 1.83
    }, {
        "symbol": "UBX",
        "label": "Unity Biotechnology, Inc.",
        "price": 7.39
    }, {
        "symbol": "UMRX",
        "label": "Unum Therapeutics Inc.",
        "price": 0.939
    }, {
        "symbol": "TTTN",
        "label": "TigerShares UP Fintech China-U.S. Internet Titans ETF",
        "price": 31.684
    }, {
        "symbol": "TIGR",
        "label": "UP Fintech Holding Limited",
        "price": 4.1
    }, {
        "symbol": "UPWK",
        "label": "Upwork Inc.",
        "price": 10.05
    }, {
        "symbol": "MYT",
        "label": "Urban Tea, Inc.",
        "price": 0.565
    }, {
        "symbol": "UROV",
        "label": "Urovant Sciences Ltd.",
        "price": 13.55
    }, {
        "symbol": "USIO",
        "label": "Usio, Inc.",
        "price": 1.72
    }, {
        "symbol": "UXIN",
        "label": "Uxin Limited",
        "price": 2.51
    }, {
        "symbol": "VCNX",
        "label": "Vaccinex, Inc.",
        "price": 5.05
    }, {
        "symbol": "VLYPO",
        "label": "Valley National Bancorp",
        "price": 26.87
    }, {
        "symbol": "VLYPP",
        "label": "Valley National Bancorp",
        "price": 28.7
    }, {
        "symbol": "BNDW",
        "label": "Vanguard Total World Bond ETF",
        "price": 79.015
    }, {
        "symbol": "VTIQ",
        "label": "VectoIQ Acquisition Corp.",
        "price": 10.34
    }, {
        "symbol": "VTIQU",
        "label": "VectoIQ Acquisition Corp.",
        "price": 11.0
    }, {
        "symbol": "VERB",
        "label": "Verb Technology Company, Inc.",
        "price": 1.44
    }, {
        "symbol": "VERY",
        "label": "Vericity, Inc.",
        "price": 12.5
    }, {
        "symbol": "VRRM",
        "label": "Verra Mobility Corporation",
        "price": 15.25
    }, {
        "symbol": "VRCA",
        "label": "Verrica Pharmaceuticals Inc.",
        "price": 15.91
    }, {
        "symbol": "VMD",
        "label": "Viemed Healthcare, Inc.",
        "price": 5.5
    }, {
        "symbol": "VFF",
        "label": "Village Farms International, Inc.",
        "price": 6.14
    }, {
        "symbol": "VIOT",
        "label": "Viomi Technology Co., Ltd",
        "price": 8.38
    }, {
        "symbol": "VTSI",
        "label": "VirTra, Inc.",
        "price": 4.27
    }, {
        "symbol": "VISL",
        "label": "Vislink Technologies, Inc.",
        "price": 0.33
    }, {
        "symbol": "WAFU",
        "label": "Wah Fu Education Group Limited",
        "price": 2.45
    }, {
        "symbol": "HLAL",
        "label": "Wahed FTSE USA Shariah ETF",
        "price": 28.29
    }, {
        "symbol": "WTRH",
        "label": "Waitr Holdings Inc.",
        "price": 0.4172
    }, {
        "symbol": "WSG",
        "label": "Wanda Sports Group Company Limited",
        "price": 3.14
    }, {
        "symbol": "WTRE",
        "label": "Watford Holdings Ltd.",
        "price": 25.68
    }, {
        "symbol": "WTREP",
        "label": "Watford Holdings Ltd.",
        "price": 26.0
    }, {
        "symbol": "HHHH",
        "label": "Wealthbridge Acquisition Limited",
        "price": 10.256
    }, {
        "symbol": "HHHHU",
        "label": "Wealthbridge Acquisition Limited",
        "price": 10.62
    }, {
        "symbol": "WW",
        "label": "WW International, Inc.",
        "price": 41.93
    }, {
        "symbol": "WINC",
        "label": "Western Asset Short Duration Income ETF",
        "price": 25.945
    }, {
        "symbol": "WBND",
        "label": "Western Asset Total Return ETF",
        "price": 27.06
    }, {
        "symbol": "WRTC",
        "label": "Wrap Technologies, Inc.",
        "price": 5.99
    }, {
        "symbol": "XFOR",
        "label": "X4 Pharmaceuticals, Inc.",
        "price": 10.32
    }, {
        "symbol": "XERS",
        "label": "Xeris Pharmaceuticals, Inc.",
        "price": 6.19
    }, {
        "symbol": "XPEL",
        "label": "XPEL, Inc.",
        "price": 14.92
    }, {
        "symbol": "YMAB",
        "label": "Y-mAbs Therapeutics, Inc.",
        "price": 32.97
    }, {
        "symbol": "YJ",
        "label": "Yunji Inc.",
        "price": 5.66
    }, {
        "symbol": "ZM",
        "label": "Zoom Video Communications, Inc.",
        "price": 75.56
    }, {
        "symbol": "ZVO",
        "label": "Zovio Inc",
        "price": 1.79
    }, {
        "symbol": "ZYXI",
        "label": "Zynex, Inc.",
        "price": 10.69
    }, {
        "symbol": "AEF",
        "label": "Aberdeen Emerging Markets Equity Income Fund, Inc.",
        "price": 8.01
    }, {
        "symbol": "UAVS",
        "label": "AgEagle Aerial Systems, Inc.",
        "price": 0.59
    }, {
        "symbol": "AGE",
        "label": "AgeX Therapeutics, Inc.",
        "price": 1.85
    }, {
        "symbol": "AMBO",
        "label": "Ambow Education Holding Ltd.",
        "price": 1.7679
    }, {
        "symbol": "ARMP",
        "label": "Armata Pharmaceuticals, Inc.",
        "price": 2.81
    }, {
        "symbol": "BIOX",
        "label": "Bioceres Crop Solutions Corp.",
        "price": 5.3
    }, {
        "symbol": "BKTI",
        "label": "BK Technologies Corporation",
        "price": 2.85
    }, {
        "symbol": "YCBD",
        "label": "cbdMD, Inc.",
        "price": 1.16
    }, {
        "symbol": "AIM",
        "label": "AIM ImmunoTech Inc.",
        "price": 0.73
    }, {
        "symbol": "ITP",
        "label": "IT Tech Packaging, Inc.",
        "price": 0.8802
    }, {
        "symbol": "LCTX",
        "label": "Lineage Cell Therapeutics, Inc.",
        "price": 0.8087
    }, {
        "symbol": "MMX",
        "label": "Maverix Metals Inc.",
        "price": 5.18
    }, {
        "symbol": "MRIC",
        "label": "MRI Interventions, Inc.",
        "price": 5.67
    }, {
        "symbol": "PLAG",
        "label": "Planet Green Holdings Corp.",
        "price": 2.4366
    }, {
        "symbol": "SILV",
        "label": "SilverCrest Metals Inc.",
        "price": 6.57
    }, {
        "symbol": "SDI",
        "label": "Standard Diversified Inc.",
        "price": 14.27
    }, {
        "symbol": "STXS",
        "label": "Stereotaxis, Inc.",
        "price": 5.21
    }, {
        "symbol": "VOLT",
        "label": "Volt Information Sciences, Inc.",
        "price": 2.71
    }, {
        "symbol": "ALMIL.PA",
        "label": "1000mercis",
        "price": 23.1
    }, {
        "symbol": "2CRSI.PA",
        "label": "2CRSI S.A.",
        "price": 4.87
    }, {
        "symbol": "MLATV.PA",
        "label": "A Toute Vitesse SA",
        "price": 1.35
    }, {
        "symbol": "ASP.PA",
        "label": "AST Groupe",
        "price": 3.61
    }, {
        "symbol": "AALB.AS",
        "label": "Aalberts NV",
        "price": 40.82
    }, {
        "symbol": "ABI.BR",
        "label": "Anheuser-Busch InBev SA/NV",
        "price": 71.34
    }, {
        "symbol": "AB.PA",
        "label": "AB Science S.A.",
        "price": 7.33
    }, {
        "symbol": "ABCA.PA",
        "label": "ABC arbitrage SA",
        "price": 6.7
    }, {
        "symbol": "ABEO.PA",
        "label": "Abeo SA",
        "price": 20.3
    }, {
        "symbol": "ABVX.PA",
        "label": "ABIVAX Societe Anonyme",
        "price": 21.05
    }, {
        "symbol": "ABN.AS",
        "label": "ABN AMRO Bank N.V.",
        "price": 16.235
    }, {
        "symbol": "ABO.BR",
        "label": "ABO-Group Environment NV",
        "price": 2.38
    }, {
        "symbol": "ACPH.BR",
        "label": "Acacia Pharma Group plc",
        "price": 2.715
    }, {
        "symbol": "ACAN.PA",
        "label": "Acanthe Developpement",
        "price": 0.489
    }, {
        "symbol": "ACCEL.AS",
        "label": "Accell Group N.V.",
        "price": 26.5
    }, {
        "symbol": "ACCB.BR",
        "label": "Accentis N.V.",
        "price": 0.053
    }, {
        "symbol": "AC.PA",
        "label": "Accor SA",
        "price": 39.85
    }, {
        "symbol": "AXS.AS",
        "label": "Accsys Technologies PLC",
        "price": 1.205
    }, {
        "symbol": "ALALO.PA",
        "label": "Acheter-Louer.Fr SA",
        "price": 0.0249
    }, {
        "symbol": "ACKB.BR",
        "label": "Ackermans & Van Haaren NV",
        "price": 147.8
    }, {
        "symbol": "EOS.PA",
        "label": "ACTEOS S.A.",
        "price": 1.67
    }, {
        "symbol": "ATI.PA",
        "label": "ACTIA Group S.A.",
        "price": 4.59
    }, {
        "symbol": "ALACT.PA",
        "label": "Groupe Actiplay SA",
        "price": 0.172
    }, {
        "symbol": "MLACT.PA",
        "label": "Activium Group SAS",
        "price": 3.08
    }, {
        "symbol": "ALADA.PA",
        "label": "ADA Societe Anonyme",
        "price": 10.4
    }, {
        "symbol": "ALDV.PA",
        "label": "Alliance Developpement Capital SIIC",
        "price": 0.128
    }, {
        "symbol": "ALARF.PA",
        "label": "Adeunis Societe anonyme",
        "price": 4.2
    }, {
        "symbol": "ALP.PA",
        "label": "ADLPartner",
        "price": 14.1
    }, {
        "symbol": "ADOC.PA",
        "label": "Adocia SA",
        "price": 10.94
    }, {
        "symbol": "ALADO.PA",
        "label": "Adomos S.A.",
        "price": 0.729
    }, {
        "symbol": "ADP.PA",
        "label": "Aeroports de Paris SA",
        "price": 176.3
    }, {
        "symbol": "ALADM.PA",
        "label": "ADThink Media Societe Anonyme",
        "price": 0.7
    }, {
        "symbol": "ADUX.PA",
        "label": "AdUX SA",
        "price": 1.36
    }, {
        "symbol": "ADV.PA",
        "label": "Advenis SA",
        "price": 1.36
    }, {
        "symbol": "ADVIC.PA",
        "label": "Advicenne S.A.",
        "price": 10.8
    }, {
        "symbol": "ADVI.PA",
        "label": "AdVini S.A.",
        "price": 26.0
    }, {
        "symbol": "ADYEN.AS",
        "label": "Adyen N.V.",
        "price": 779.8
    }, {
        "symbol": "AED.BR",
        "label": "Aedifica SA",
        "price": 119.0
    }, {
        "symbol": "AGN.AS",
        "label": "Aegon N.V.",
        "price": 3.965
    }, {
        "symbol": "AKOM.PA",
        "price": 36.0
    }, {
        "symbol": "AFO.PA",
        "label": "Afone S.A.",
        "price": 13.0
    }, {
        "symbol": "MLAGI.PA",
        "label": "SAS AG3i",
        "price": 3.46
    }, {
        "symbol": "AGS.BR",
        "label": "ageas SA/NV",
        "price": 51.28
    }, {
        "symbol": "AGFB.BR",
        "label": "Agfa-Gevaert NV",
        "price": 4.656
    }, {
        "symbol": "ALAGR.PA",
        "label": "AgroGeneration SA",
        "price": 0.076
    }, {
        "symbol": "AGTA.PA",
        "label": "agta record ag",
        "price": 68.5
    }, {
        "symbol": "AD.AS",
        "label": "Koninklijke Ahold Delhaize N.V.",
        "price": 22.495
    }, {
        "symbol": "AF.PA",
        "label": "Air France-KLM SA",
        "price": 9.528
    }, {
        "symbol": "AI.PA",
        "label": "L'Air Liquide S.A.",
        "price": 128.75
    }, {
        "symbol": "MLAIM.PA",
        "label": "Air Marine SA",
        "price": 2.8
    }, {
        "symbol": "AIR.PA",
        "label": "Airbus SE",
        "price": 137.58
    }, {
        "symbol": "AJAX.AS",
        "label": "AFC Ajax NV",
        "price": 20.3
    }, {
        "symbol": "AKW.PA",
        "label": "AKWEL",
        "price": 19.98
    }, {
        "symbol": "AKZA.AS",
        "label": "Akzo Nobel N.V.",
        "price": 88.06
    }, {
        "symbol": "ABIO.PA",
        "label": "Albioma",
        "price": 28.05
    }, {
        "symbol": "ALD.PA",
        "label": "ALD S.A.",
        "price": 13.82
    }, {
        "symbol": "ALPHY.PA",
        "label": "Ales Groupe S.A.",
        "price": 2.41
    }, {
        "symbol": "ALFEN.AS",
        "label": "Alfen N.V.",
        "price": 23.95
    }, {
        "symbol": "CDA.PA",
        "label": "Compagnie des Alpes SA",
        "price": 29.95
    }, {
        "symbol": "ALM.PA",
        "label": "Alpha MOS S.A.",
        "price": 1.17
    }, {
        "symbol": "ALO.PA",
        "label": "Alstom SA",
        "price": 45.42
    }, {
        "symbol": "ALTA.PA",
        "label": "Altarea SCA",
        "price": 203.0
    }, {
        "symbol": "AREIT.PA",
        "label": "Altareit SCA",
        "price": 530.0
    }, {
        "symbol": "ATE.PA",
        "label": "Alten SA",
        "price": 117.3
    }, {
        "symbol": "ATCB.AS",
        "label": "Altice Europe N.V.",
        "price": 6.17
    }, {
        "symbol": "ATC.AS",
        "label": "Altice Europe N.V.",
        "price": 6.172
    }, {
        "symbol": "ALT.PA",
        "label": "Altran Technologies S.A.",
        "price": 14.43
    }, {
        "symbol": "ALTR.LS",
        "label": "Altri, S.G.P.S., S.A.",
        "price": 6.335
    }, {
        "symbol": "ALTUR.PA",
        "label": "Altur Investissement SCA, SCR",
        "price": 6.12
    }, {
        "symbol": "ALX.AS",
        "label": "Alumexx N.V.",
        "price": 0.56
    }, {
        "symbol": "MLALV.PA",
        "label": "Alveen S.A.",
        "price": 0.75
    }, {
        "symbol": "MLAAH.PA",
        "label": "Amatheon Agri Holding N.V.",
        "price": 0.0905
    }, {
        "symbol": "AMG.AS",
        "label": "AMG Advanced Metallurgical Group N.V.",
        "price": 22.32
    }, {
        "symbol": "AMEBA.PA",
        "label": "Amoeba S.A.",
        "price": 0.71
    }, {
        "symbol": "AMPLI.PA",
        "label": "Amplitude Surgical SA",
        "price": 1.625
    }, {
        "symbol": "ACOMO.AS",
        "label": "Amsterdam Commodities N.V.",
        "price": 20.25
    }, {
        "symbol": "AMUN.PA",
        "label": "Amundi",
        "price": 70.45
    }, {
        "symbol": "AND.AS",
        "label": "AND International Publishers N.V.",
        "price": 2.58
    }, {
        "symbol": "ALANV.PA",
        "label": "Anevia Societe Anonyme",
        "price": 2.09
    }, {
        "symbol": "ANTA.PA",
        "label": "Antalis Societe anonyme",
        "price": 0.752
    }, {
        "symbol": "ANT.BR",
        "label": "Immo-Antares NV",
        "price": 49.5
    }, {
        "symbol": "ALANT.PA",
        "label": "Antevenio, S.A.",
        "price": 8.0
    }, {
        "symbol": "APAM.AS",
        "label": "Aperam S.A.",
        "price": 27.54
    }, {
        "symbol": "APR.PA",
        "label": "April SA",
        "price": 21.9
    }, {
        "symbol": "ALAQU.PA",
        "label": "Aquila SA",
        "price": 7.0
    }, {
        "symbol": "ARCAD.AS",
        "label": "Arcadis NV",
        "price": 20.56
    }, {
        "symbol": "MT.AS",
        "label": "ArcelorMittal",
        "price": 14.624
    }, {
        "symbol": "JXR.PA",
        "label": "Archos S.A.",
        "price": 0.0948
    }, {
        "symbol": "ALCUR.PA",
        "label": "Arcure S.A.",
        "price": 4.3
    }, {
        "symbol": "MLARD.PA",
        "label": "Ardoin ST Amand A",
        "price": 102.0
    }, {
        "symbol": "ARG.PA",
        "label": "Argan SA",
        "price": 80.0
    }, {
        "symbol": "ARGX.BR",
        "label": "argenx SE",
        "price": 136.6
    }, {
        "symbol": "AKE.PA",
        "label": "Arkema S.A.",
        "price": 87.64
    }, {
        "symbol": "ALATF.PA",
        "label": "Artefact SA",
        "price": 1.124
    }, {
        "symbol": "ARTO.PA",
        "label": "La Societe Industrielle et Financiere de l'Artois",
        "price": 4200.0
    }, {
        "symbol": "PRC.PA",
        "label": "Artprice.com",
        "price": 9.63
    }, {
        "symbol": "ASC.BR",
        "label": "Ascencio SCA",
        "price": 57.0
    }, {
        "symbol": "MLAEM.PA",
        "label": "Ashler et Manson SA",
        "price": 1.68
    }, {
        "symbol": "ASIT.BR",
        "label": "ASIT Biotech S.A.",
        "price": 0.2815
    }, {
        "symbol": "ASM.AS",
        "label": "ASM International NV",
        "price": 119.65
    }, {
        "symbol": "ASML.AS",
        "label": "ASML Holding N.V.",
        "price": 271.3
    }, {
        "symbol": "ASRNL.AS",
        "label": "ASR Nederland N.V.",
        "price": 34.74
    }, {
        "symbol": "GEN.BR",
        "label": "Assicurazioni Generali S.p.A.",
        "price": 18.165
    }, {
        "symbol": "ALSIM.PA",
        "label": "Assima PLC",
        "price": 0.57
    }, {
        "symbol": "ASY.PA",
        "label": "Assystem S.A.",
        "price": 34.35
    }, {
        "symbol": "ATA.PA",
        "label": "Atari SA",
        "price": 0.329
    }, {
        "symbol": "ATEME.PA",
        "label": "ATEME SA",
        "price": 10.86
    }, {
        "symbol": "ATEB.BR",
        "label": "Atenor SA",
        "price": 75.8
    }, {
        "symbol": "ATO.PA",
        "label": "Atos SE",
        "price": 78.08
    }, {
        "symbol": "ATRS.AS",
        "label": "Atrium European Real Estate Limited",
        "price": 3.55
    }, {
        "symbol": "AUB.PA",
        "label": "Aubay Societe Anonyme",
        "price": 32.95
    }, {
        "symbol": "ALAVY.PA",
        "label": "AudioValley SA",
        "price": 3.91
    }, {
        "symbol": "AUGR.PA",
        "label": "Augros Cosmetic Packaging SA",
        "price": 6.5
    }, {
        "symbol": "ALAMG.PA",
        "label": "Auplata Mining Group",
        "price": 0.1773
    }, {
        "symbol": "AURE.PA",
        "label": "Aurea SA",
        "price": 6.7
    }, {
        "symbol": "AURS.PA",
        "label": "AURES Technologies S.A.",
        "price": 21.7
    }, {
        "symbol": "AVTX.AS",
        "label": "Avantium N.V.",
        "price": 4.22
    }, {
        "symbol": "AVT.PA",
        "label": "Avenir Telecom S.A.",
        "price": 0.015
    }, {
        "symbol": "AWOX.PA",
        "label": "AwoX S.A.",
        "price": 0.99
    }, {
        "symbol": "CS.PA",
        "label": "AXA SA",
        "price": 24.48
    }, {
        "symbol": "AXW.PA",
        "label": "Axway Software SA",
        "price": 11.35
    }, {
        "symbol": "MLAZL.PA",
        "label": "AZ Leasing SPA",
        "price": 0.444
    }, {
        "symbol": "MLAAT.PA",
        "label": "Azorean - Aquatic Technologies, S.A.",
        "price": 0.199
    }, {
        "symbol": "BSGR.AS",
        "label": "B&S Group S.A.",
        "price": 10.16
    }, {
        "symbol": "BCP.LS",
        "label": "Banco Comercial Portugues, S.A.",
        "price": 0.1901
    }, {
        "symbol": "BCRA.PA",
        "label": "Baccarat S.A.",
        "price": 214.0
    }, {
        "symbol": "ALBKK.PA",
        "label": "Baikowski SAS",
        "price": 13.398
    }, {
        "symbol": "BAIN.PA",
        "label": "Societe Anonyme des Bains de Mer et du Cercle des Etrangers a Monaco",
        "price": 59.4
    }, {
        "symbol": "BALTA.BR",
        "label": "Balta Group NV",
        "price": 2.745
    }, {
        "symbol": "BALYO.PA",
        "label": "Balyo SA",
        "price": 1.73
    }, {
        "symbol": "BAMNB.AS",
        "label": "Koninklijke BAM Groep nv",
        "price": 2.638
    }, {
        "symbol": "BBV.BR",
        "label": "Banco Bilbao Vizcaya Argentaria, S.A.",
        "price": 5.121
    }, {
        "symbol": "SANTA.BR",
        "label": "Banco Santander, S.A.",
        "price": 3.68
    }, {
        "symbol": "BANI.BR",
        "label": "Banimmo SA",
        "price": 3.44
    }, {
        "symbol": "BUI.PA",
        "label": "Barbara Bui SA",
        "price": 6.7
    }, {
        "symbol": "BAR.BR",
        "label": "Barco NV",
        "price": 227.0
    }, {
        "symbol": "BFIT.AS",
        "label": "Basic-Fit N.V.",
        "price": 34.4
    }, {
        "symbol": "BAS.BR",
        "label": "Basilix Cert",
        "price": 15.502
    }, {
        "symbol": "BLC.PA",
        "label": "Bastide Le Confort Medical SA",
        "price": 37.05
    }, {
        "symbol": "MLBAT.PA",
        "label": "Batla Minerals SA",
        "price": 0.52
    }, {
        "symbol": "ALBDM.PA",
        "label": "BD Multimedia SA",
        "price": 1.8
    }, {
        "symbol": "BESI.AS",
        "label": "BE Semiconductor Industries N.V.",
        "price": 38.4
    }, {
        "symbol": "BEAB.BR",
        "label": "Immo-Beaulieu NV",
        "price": 87.0
    }, {
        "symbol": "BEFB.BR",
        "label": "Befimmo SA",
        "price": 53.9
    }, {
        "symbol": "BEKB.BR",
        "label": "NV Bekaert SA",
        "price": 25.24
    }, {
        "symbol": "FBEL.PA",
        "label": "Fromageries Bel SA",
        "price": 306.0
    }, {
        "symbol": "BELI.PA",
        "label": "Le Belier SA",
        "price": 36.15
    }, {
        "symbol": "BELR.BR",
        "label": "Belgian Resources and Capital Company",
        "price": 105.0
    }, {
        "symbol": "BELU.BR",
        "label": "Beluga NV",
        "price": 2.9
    }, {
        "symbol": "BEN.PA",
        "label": "Beneteau S.A.",
        "price": 10.17
    }, {
        "symbol": "SLBEN.LS",
        "label": "Sport Lisboa e Benfica - Futebol, SAD",
        "price": 4.66
    }, {
        "symbol": "ALDBL.PA",
        "label": "Bernard Loiseau S.A.",
        "price": 3.48
    }, {
        "symbol": "BBED.AS",
        "label": "Beter Bed Holding N.V.",
        "price": 2.7
    }, {
        "symbol": "BEVER.AS",
        "label": "Bever Holding NV",
        "price": 4.72
    }, {
        "symbol": "BB.PA",
        "label": "Societe BIC SA",
        "price": 60.05
    }, {
        "symbol": "BIG.PA",
        "label": "BigBen Interactive",
        "price": 15.78
    }, {
        "symbol": "ALBLD.PA",
        "label": "Bilendi SA",
        "price": 10.65
    }, {
        "symbol": "ALTUV.PA",
        "label": "BIO-UV Group S.A.",
        "price": 3.68
    }, {
        "symbol": "BCART.BR",
        "label": "Biocartis Group NV",
        "price": 6.72
    }, {
        "symbol": "ALCOR.PA",
        "label": "Biocorp Production",
        "price": 12.7
    }, {
        "symbol": "BUP.PA",
        "label": "Biom'Up S.A.",
        "price": 0.288
    }, {
        "symbol": "BIM.PA",
        "label": "bioMerieux S.A.",
        "price": 86.15
    }, {
        "symbol": "ALBPS.PA",
        "label": "Biophytis SA",
        "price": 0.204
    }, {
        "symbol": "ALBIO.PA",
        "label": "Biosynex SA",
        "price": 2.94
    }, {
        "symbol": "BLEE.PA",
        "label": "Bleecker Societe Anonyme",
        "price": 149.0
    }, {
        "symbol": "BLUE.PA",
        "label": "Blue Solutions Societe Anonyme",
        "price": 17.0
    }, {
        "symbol": "MLBSP.PA",
        "label": "Blue Shark Power System",
        "price": 5.9
    }, {
        "symbol": "ALBLU.PA",
        "label": "Bluelinea Societe Anonyme",
        "price": 5.1
    }, {
        "symbol": "BNP.PA",
        "label": "BNP Paribas SA",
        "price": 50.64
    }, {
        "symbol": "MLONE.PA",
        "label": "Body One SA",
        "price": 0.23
    }, {
        "symbol": "BOEI.BR",
        "label": "The Boeing Company",
        "price": 372.0
    }, {
        "symbol": "BOI.PA",
        "label": "Boiron SA",
        "price": 34.75
    }, {
        "symbol": "BOL.PA",
        "label": "Bollore",
        "price": 3.798
    }, {
        "symbol": "BON.PA",
        "label": "Bonduelle SA",
        "price": 21.65
    }, {
        "symbol": "BOTHE.BR",
        "label": "Bone Therapeutics SA",
        "price": 3.655
    }, {
        "symbol": "BOKA.AS",
        "label": "Royal Boskalis Westminster N.V.",
        "price": 22.63
    }, {
        "symbol": "GBB.PA",
        "label": "Bourbon Corporation SA",
        "price": 3.675
    }, {
        "symbol": "ALBOU.PA",
        "label": "Bourrelier Group SA",
        "price": 45.6
    }, {
        "symbol": "BSD.PA",
        "label": "Bourse Direct SA",
        "price": 1.15
    }, {
        "symbol": "BGHL.AS",
        "label": "Boussard & Gavaudan Holding Limited",
        "price": 17.65
    }, {
        "symbol": "EN.PA",
        "label": "Bouygues SA",
        "price": 38.21
    }, {
        "symbol": "BPOST.BR",
        "label": "bpost SA/NV",
        "price": 9.64
    }, {
        "symbol": "BNB.BR",
        "label": "Banque nationale de Belgique Societe anonyme",
        "price": 2340.0
    }, {
        "symbol": "BCAM.PA",
        "label": "Societe Anonyme des Brasseries du Cameroun",
        "price": 90.0
    }, {
        "symbol": "BREB.BR",
        "label": "Brederode SA",
        "price": 78.0
    }, {
        "symbol": "BRILL.AS",
        "label": "Koninklijke Brill NV",
        "price": 20.9
    }, {
        "symbol": "BRNL.AS",
        "label": "Brunel International N.V.",
        "price": 8.84
    }, {
        "symbol": "ALBUD.PA",
        "label": "Budget Telecom S.A.",
        "price": 4.73
    }, {
        "symbol": "BVI.PA",
        "label": "Bureau Veritas SA",
        "price": 24.96
    }, {
        "symbol": "BUR.PA",
        "label": "Burelle SA",
        "price": 788.0
    }, {
        "symbol": "BND.PA",
        "label": "Business & Decision SA",
        "price": 7.6
    }, {
        "symbol": "CAT31.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel Toulouse 31",
        "price": 134.92
    }, {
        "symbol": "CAFO.PA",
        "label": "Centrale d'Achat Francaise pour l'Outre-Mer Societe Anonyme",
        "price": 5.25
    }, {
        "symbol": "MLAAE.PA",
        "label": "Caire",
        "price": 14.0
    }, {
        "symbol": "CBDG.PA",
        "label": "Compagnie du Cambodge",
        "price": 6000.0
    }, {
        "symbol": "CAMB.BR",
        "label": "Campine NV",
        "price": 29.0
    }, {
        "symbol": "CAND.BR",
        "label": "Candela Invest SA",
        "price": 3.66
    }, {
        "symbol": "CAPLI.PA",
        "label": "Groupe Capelli",
        "price": 32.6
    }, {
        "symbol": "CAP.PA",
        "label": "Capgemini SE",
        "price": 116.25
    }, {
        "symbol": "ALCRB.PA",
        "label": "Carbios SAS",
        "price": 9.12
    }, {
        "symbol": "CPINV.BR",
        "label": "Care Property Invest NV",
        "price": 30.9
    }, {
        "symbol": "ALCAR.PA",
        "label": "Carmat SA",
        "price": 19.2
    }, {
        "symbol": "CARM.PA",
        "label": "Carmila S.A.",
        "price": 18.16
    }, {
        "symbol": "CARP.PA",
        "label": "Carpinienne de Participations",
        "price": 33.4
    }, {
        "symbol": "CA.PA",
        "label": "Carrefour SA",
        "price": 14.695
    }, {
        "symbol": "CO.PA",
        "label": "Casino, Guichard-Perrachon Societe Anonyme",
        "price": 37.46
    }, {
        "symbol": "CAS.PA",
        "label": "Cast SA",
        "price": 2.68
    }, {
        "symbol": "CATG.PA",
        "label": "SA Catana Group",
        "price": 4.13
    }, {
        "symbol": "CTRG.PA",
        "label": "Catering International & Services Societe Anonyme",
        "price": 13.0
    }, {
        "symbol": "CATR.PA",
        "label": "Caterpillar Inc.",
        "price": 133.0
    }, {
        "symbol": "CBOT.PA",
        "label": "CBo Territoria Societe Anonyme",
        "price": 3.83
    }, {
        "symbol": "MLCEC.PA",
        "label": "SA Cecurity.com",
        "price": 0.5
    }, {
        "symbol": "CGM.PA",
        "label": "Cegedim SA",
        "price": 31.45
    }, {
        "symbol": "CGR.PA",
        "label": "CeGeREAL",
        "price": 43.0
    }, {
        "symbol": "ALCLS.PA",
        "label": "Cellectis S.A.",
        "price": 16.67
    }, {
        "symbol": "CLNV.PA",
        "label": "Cellnovo Group SA",
        "price": 0.128
    }, {
        "symbol": "CYAD.BR",
        "label": "Celyad SA",
        "price": 10.82
    }, {
        "symbol": "CENER.BR",
        "label": "Cenergy Holdings SA",
        "price": 1.46
    }, {
        "symbol": "ALPCV.PA",
        "label": "Cerinnov Group SA",
        "price": 2.14
    }, {
        "symbol": "ALCES.PA",
        "label": "Cesar SA",
        "price": 0.185
    }, {
        "symbol": "CFEB.BR",
        "label": "Compagnie d'Entreprises CFE SA",
        "price": 101.8
    }, {
        "symbol": "CFI.PA",
        "label": "CFI-Compagnie Fonciere Internationale",
        "price": 0.5
    }, {
        "symbol": "MLCFM.PA",
        "label": "CFM Indosuez Wealth Societe anonyme",
        "price": 825.0
    }, {
        "symbol": "CGG.PA",
        "label": "CGG",
        "price": 2.93
    }, {
        "symbol": "MLCFD.PA",
        "label": "Compagnie de Chemins de Fer Departementaux Societe Anonyme",
        "price": 695.0
    }, {
        "symbol": "MLCVG.PA",
        "label": "Societe des Chemins de Fer et Tramways du Var et du Gard",
        "price": 3980.0
    }, {
        "symbol": "CRI.PA",
        "label": "Chargeurs SA",
        "price": 18.46
    }, {
        "symbol": "CHSR.PA",
        "label": "Chausseria",
        "price": 4.1
    }, {
        "symbol": "MLCHE.PA",
        "label": "Cheops Technology France SA",
        "price": 34.0
    }, {
        "symbol": "CHTEX.BR",
        "label": "Chevron Corporation",
        "price": 118.04
    }, {
        "symbol": "CDI.PA",
        "label": "Christian Dior SE",
        "price": 476.0
    }, {
        "symbol": "CIB.PA",
        "label": "Cibox Inter@ctive",
        "price": 0.1376
    }, {
        "symbol": "COMB.BR",
        "label": "Compagnie du Bois Sauvage SA",
        "price": 379.0
    }, {
        "symbol": "MLCMB.PA",
        "label": "Compagnie Du Mont-Blanc",
        "price": 135.0
    }, {
        "symbol": "MLCIO.PA",
        "label": "Groupe CIOA",
        "price": 1.0
    }, {
        "symbol": "CIS.BR",
        "label": "Cisco Systems, Inc.",
        "price": 48.0
    }, {
        "symbol": "CLA.PA",
        "label": "Claranova SE",
        "price": 7.708
    }, {
        "symbol": "ALCLA.PA",
        "label": "Clasquin SA",
        "price": 36.0
    }, {
        "symbol": "COM.PA",
        "label": "CNIM Group S.A.",
        "price": 17.8
    }, {
        "symbol": "CNV.PA",
        "label": "Cnova N.V.",
        "price": 2.4
    }, {
        "symbol": "CNP.PA",
        "label": "CNP Assurances SA",
        "price": 17.26
    }, {
        "symbol": "COBH.BR",
        "label": "Brouwerij Handelsmaatschappij NV",
        "price": 3980.0
    }, {
        "symbol": "CCEP.AS",
        "label": "Coca-Cola European Partners plc",
        "price": 48.45
    }, {
        "symbol": "COFA.PA",
        "label": "COFACE SA",
        "price": 11.78
    }, {
        "symbol": "ALCOF.PA",
        "label": "Cofidur S.A.",
        "price": 310.0
    }, {
        "symbol": "CFN.LS",
        "label": "Cofina, SGPS, S.A.",
        "price": 0.465
    }, {
        "symbol": "COFB.BR",
        "label": "Cofinimmo SA",
        "price": 136.0
    }, {
        "symbol": "COGEC.PA",
        "label": "Cogelec SA",
        "price": 6.38
    }, {
        "symbol": "ALCOG.PA",
        "label": "Cogra 48 Societe Anonyme",
        "price": 5.44
    }, {
        "symbol": "COH.PA",
        "label": "Coheris SA",
        "price": 2.01
    }, {
        "symbol": "ALCOI.PA",
        "label": "Coil S.A./N.V.",
        "price": 13.3
    }, {
        "symbol": "RE.PA",
        "label": "Colas SA",
        "price": 136.4
    }, {
        "symbol": "MLCLP.PA",
        "label": "Colipays, S.A.",
        "price": 3.0
    }, {
        "symbol": "COLR.BR",
        "label": "Etn. Fr. Colruyt NV",
        "price": 44.99
    }, {
        "symbol": "MLMFI.PA",
        "label": "Condor Technologies NV",
        "price": 15.0
    }, {
        "symbol": "CDU.LS",
        "label": "Conduril - Engenharia, S.A.",
        "price": 33.4
    }, {
        "symbol": "MLCNT.PA",
        "label": "Consort NT SA",
        "price": 20.2
    }, {
        "symbol": "CPA.LS",
        "label": "COPAM - Companhia Portuguesa de Amidos, S.A.",
        "price": 15.0
    }, {
        "symbol": "CRBN.AS",
        "label": "Corbion N.V.",
        "price": 28.98
    }, {
        "symbol": "CLB.AS",
        "label": "Core Laboratories N.V.",
        "price": 36.6
    }, {
        "symbol": "MLCOR.PA",
        "label": "Corep Lighting",
        "price": 9.2
    }, {
        "symbol": "COR.LS",
        "label": "Corticeira Amorim, S.G.P.S., S.A.",
        "price": 11.46
    }, {
        "symbol": "MLCOU.PA",
        "label": "Courbet Sa",
        "price": 1.45
    }, {
        "symbol": "COUR.PA",
        "label": "Courtois S.A.",
        "price": 130.0
    }, {
        "symbol": "COV.PA",
        "label": "Covivio",
        "price": 105.4
    }, {
        "symbol": "COVH.PA",
        "label": "Covivio Hotels Societe anonyme",
        "price": 29.9
    }, {
        "symbol": "CRAP.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel Alpes Provence Societe cooperative",
        "price": 127.02
    }, {
        "symbol": "CRAV.PA",
        "label": "Caisse regionale de Credit Agricole Mutuel Atlantique Vendee",
        "price": 163.22
    }, {
        "symbol": "CRBP2.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel Brie Picardie Societe cooperative",
        "price": 31.21
    }, {
        "symbol": "CIV.PA",
        "label": "Caisse regionale de Credit Agricole Mutuel d'Ille-et-Vilaine Societe cooperative",
        "price": 117.52
    }, {
        "symbol": "CRLA.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel du Languedoc Societe cooperative",
        "price": 83.14
    }, {
        "symbol": "CRLO.PA",
        "label": "Caisse regionale de Credit Agricole Mutuel Loire Haute-Loire - Societe cooperati" +
                "ve",
        "price": 104.48
    }, {
        "symbol": "CMO.PA",
        "label": "Caisse Regionale de Credit Agricole du Morbihan",
        "price": 102.0
    }, {
        "symbol": "CNF.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel Nord de France Societe cooperative",
        "price": 28.795
    }, {
        "symbol": "CCN.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel de Normandie-Seine Societe cooperativ" +
                "e",
        "price": 157.0
    }, {
        "symbol": "CAF.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel de Paris et d'Ile-de-France",
        "price": 99.0
    }, {
        "symbol": "CRSU.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel Sud Rhone Alpes",
        "price": 229.55
    }, {
        "symbol": "CRTO.PA",
        "label": "Caisse Regionale de Credit Agricole Mutuel de La Touraine et du Poitou Societe C" +
                "ooperative",
        "price": 126.64
    }, {
        "symbol": "ACA.PA",
        "label": "Credit Agricole S.A.",
        "price": 12.845
    }, {
        "symbol": "OPTI.BR",
        "label": "Crescent NV",
        "price": 0.0284
    }, {
        "symbol": "ALCJ.PA",
        "label": "Crossject Societe Anonyme",
        "price": 2.1
    }, {
        "symbol": "CROS.PA",
        "label": "Crosswood SA",
        "price": 4.8
    }, {
        "symbol": "SX.PA",
        "label": "CS Communication & Systemes SA",
        "price": 4.8
    }, {
        "symbol": "MLCTA.PA",
        "label": "CTA Holdings",
        "price": 19.5
    }, {
        "symbol": "CTAC.AS",
        "label": "Ctac N.V.",
        "price": 2.68
    }, {
        "symbol": "CTT.LS",
        "label": "CTT - Correios De Portugal, S.A.",
        "price": 3.2
    }, {
        "symbol": "CURE.AS",
        "label": "Curetis N.V.",
        "price": 0.707
    }, {
        "symbol": "ALCYB.PA",
        "label": "Cybergun S.A.",
        "price": 0.0027
    }, {
        "symbol": "DIE.BR",
        "label": "D'Ieteren SA",
        "price": 59.9
    }, {
        "symbol": "ALDLS.PA",
        "label": "DLSI",
        "price": 18.4
    }, {
        "symbol": "MLGEL.PA",
        "label": "Gel Groupe SA",
        "price": 6.2
    }, {
        "symbol": "DLT.PA",
        "label": "Dalet S.A.",
        "price": 12.4
    }, {
        "symbol": "ALDAR.PA",
        "label": "Damartex",
        "price": 15.9
    }, {
        "symbol": "BN.PA",
        "label": "Danone S.A.",
        "price": 73.3
    }, {
        "symbol": "AM.PA",
        "label": "Dassault Aviation SA",
        "price": 1138.0
    }, {
        "symbol": "DSY.PA",
        "label": "Dassault Systemes SE",
        "price": 157.45
    }, {
        "symbol": "MLDTB.PA",
        "label": "datBIM SA",
        "price": 1.88
    }, {
        "symbol": "ALDBT.PA",
        "label": "DBT SA",
        "price": 0.42
    }, {
        "symbol": "DBV.PA",
        "label": "DBV Technologies S.A.",
        "price": 23.42
    }, {
        "symbol": "DECB.BR",
        "label": "Deceuninck NV",
        "price": 2.0
    }, {
        "symbol": "DEDAL.PA",
        "label": "Dedalus France S.A.",
        "price": 0.406
    }, {
        "symbol": "ALDEI.PA",
        "label": "Deinove SA",
        "price": 0.9
    }, {
        "symbol": "ALDEL.PA",
        "label": "Delfingen Industry S.A.",
        "price": 20.5
    }, {
        "symbol": "ALDR.PA",
        "label": "Delta Drone SA",
        "price": 0.0443
    }, {
        "symbol": "DLTA.PA",
        "label": "Delta Plus Group",
        "price": 50.0
    }, {
        "symbol": "DBG.PA",
        "label": "Derichebourg",
        "price": 3.568
    }, {
        "symbol": "DTEL.BR",
        "label": "Deutsche Telekom AG",
        "price": 14.612
    }, {
        "symbol": "ALDEV.PA",
        "label": "Devernois S.A.",
        "price": 59.5
    }, {
        "symbol": "DVT.PA",
        "label": "Devoteam SA",
        "price": 86.2
    }, {
        "symbol": "DGB.AS",
        "label": "DGB Group N.V.",
        "price": 0.788
    }, {
        "symbol": "DGE.PA",
        "label": "Diageo plc",
        "price": 38.61
    }, {
        "symbol": "DGM.PA",
        "label": "Diagnostic Medical Systems S.A.",
        "price": 1.26
    }, {
        "symbol": "DIEG.BR",
        "label": "Immolease Trust NV",
        "price": 134.14
    }, {
        "symbol": "ALDIE.PA",
        "label": "Dietswell S.A.",
        "price": 0.822
    }, {
        "symbol": "DIG.PA",
        "label": "Digigram S.A.",
        "price": 0.61
    }, {
        "symbol": "DISL.BR",
        "label": "Immobiliere Distri-Land NV",
        "price": 180.02
    }, {
        "symbol": "DPAM.PA",
        "label": "Les Docks des Petroles d'Ambes SA",
        "price": 450.0
    }, {
        "symbol": "ALDNE.PA",
        "label": "Don't Nod Entertainment S.A.",
        "price": 16.1
    }, {
        "symbol": "DPA.AS",
        "label": "DPA Group N.V.",
        "price": 1.44
    }, {
        "symbol": "ALDRV.PA",
        "label": "Drone Volt SA",
        "price": 0.1125
    }, {
        "symbol": "DSM.AS",
        "label": "Koninklijke DSM N.V.",
        "price": 111.15
    }, {
        "symbol": "DSC1.AS",
        "label": "Dutch Star Companies One N.V.",
        "price": 12.2
    }, {
        "symbol": "MLDYN.PA",
        "label": "Dynafond Societe Anonyme",
        "price": 2.98
    }, {
        "symbol": "MLDYX.PA",
        "label": "Dynex Energy S.A.",
        "price": 3.4
    }, {
        "symbol": "EON.BR",
        "label": "E.ON SE",
        "price": 10.218
    }, {
        "symbol": "EAS2P.AS",
        "label": "Ease2pay N.V.",
        "price": 1.216
    }, {
        "symbol": "MLEAS.PA",
        "label": "Easson Telecom Limited",
        "price": 0.75
    }, {
        "symbol": "ALEZV.PA",
        "label": "EasyVista S.A.",
        "price": 82.0
    }, {
        "symbol": "MLEDR.PA",
        "label": "Compagnie des Eaux de Royan",
        "price": 132.0
    }, {
        "symbol": "ECASA.PA",
        "label": "ECA SA",
        "price": 31.0
    }, {
        "symbol": "ECONB.BR",
        "label": "Econocom Group SE",
        "price": 2.264
    }, {
        "symbol": "ALESA.PA",
        "label": "Ecoslops S.A.",
        "price": 12.4
    }, {
        "symbol": "EDEN.PA",
        "label": "Edenred SA",
        "price": 48.94
    }, {
        "symbol": "EDF.PA",
        "label": "Electricite de France S.A.",
        "price": 11.205
    }, {
        "symbol": "ALEAC.PA",
        "label": "EdiliziAcrobatica S.p.A.",
        "price": 6.95
    }, {
        "symbol": "MLEDS.PA",
        "label": "Editions Du Signe SA",
        "price": 0.74
    }, {
        "symbol": "EDP.LS",
        "label": "EDP - Energias de Portugal, S.A.",
        "price": 4.087
    }, {
        "symbol": "EDPR.LS",
        "label": "EDP Renovaveis, S.A.",
        "price": 10.76
    }, {
        "symbol": "MLEES.PA",
        "label": "Energie Europe Service",
        "price": 0.0205
    }, {
        "symbol": "GID.PA",
        "label": "Egide S.A.",
        "price": 0.86
    }, {
        "symbol": "FGR.PA",
        "label": "Eiffage SA",
        "price": 104.85
    }, {
        "symbol": "EKI.PA",
        "label": "EKINOPS S.A.",
        "price": 5.91
    }, {
        "symbol": "ELEC.PA",
        "label": "Electricite de Strasbourg Societe Anonyme",
        "price": 120.0
    }, {
        "symbol": "EEM.PA",
        "label": "Electricite et Eaux de Madagascar Societe Anonyme",
        "price": 3.34
    }, {
        "symbol": "EPS.PA",
        "label": "ENGIE Eps S.A.",
        "price": 9.15
    }, {
        "symbol": "ELI.BR",
        "label": "Elia System Operator SA",
        "price": 83.9
    }, {
        "symbol": "ELIOR.PA",
        "label": "Elior Group S.A.",
        "price": 13.08
    }, {
        "symbol": "ELIS.PA",
        "label": "Elis SA",
        "price": 18.42
    }, {
        "symbol": "ALEMK.BR",
        "label": "Emakina Group SA",
        "price": 12.0
    }, {
        "symbol": "ALDUB.PA",
        "label": "Encres Dubuit",
        "price": 4.4
    }, {
        "symbol": "ALNN6.PA",
        "label": "ENENSYS Technologies SA",
        "price": 0.84
    }, {
        "symbol": "ALENE.PA",
        "label": "Enertime SA",
        "price": 1.17
    }, {
        "symbol": "ENGI.PA",
        "label": "ENGIE SA",
        "price": 15.145
    }, {
        "symbol": "ENI.BR",
        "label": "Eni S.p.A.",
        "price": 13.8
    }, {
        "symbol": "ALENT.PA",
        "label": "SA Entreparticuliers.com",
        "price": 0.995
    }, {
        "symbol": "ALENR.PA",
        "label": "Entreprendre S.A.",
        "price": 10.0
    }, {
        "symbol": "ALTEV.PA",
        "label": "Envea Societe anonyme",
        "price": 99.6
    }, {
        "symbol": "ENVI.AS",
        "label": "Envipco Holding N.V.",
        "price": 10.9
    }, {
        "symbol": "ALEO2.PA",
        "label": "Eo2 Societe Anonyme",
        "price": 4.1
    }, {
        "symbol": "EOSI.PA",
        "label": "EOS imaging SA",
        "price": 2.135
    }, {
        "symbol": "ERA.PA",
        "label": "ERAMET S.A.",
        "price": 44.8
    }, {
        "symbol": "ERYP.PA",
        "label": "ERYTECH Pharma S.A.",
        "price": 6.53
    }, {
        "symbol": "ESI.PA",
        "label": "ESI Group SA",
        "price": 30.3
    }, {
        "symbol": "ALESK.PA",
        "label": "Esker SA",
        "price": 101.8
    }, {
        "symbol": "ESP.AS",
        "label": "Esperite N.V.",
        "price": 0.0606
    }, {
        "symbol": "EL.PA",
        "label": "EssilorLuxottica Societe anonyme",
        "price": 143.0
    }, {
        "symbol": "ES.PA",
        "label": "Esso S.A.F.",
        "price": 21.7
    }, {
        "symbol": "ESON.LS",
        "label": "Estoril Sol, SGPS, S.A.",
        "price": 9.35
    }, {
        "symbol": "EFI.PA",
        "label": "Eurasia Fonciere Investissements Societe Anonyme",
        "price": 0.191
    }, {
        "symbol": "ALEUA.PA",
        "label": "Eurasia Groupe SA",
        "price": 7.2
    }, {
        "symbol": "RF.PA",
        "label": "Eurazeo SE",
        "price": 62.65
    }, {
        "symbol": "EUR.PA",
        "label": "Euro Ressources S.A.",
        "price": 3.04
    }, {
        "symbol": "ALERS.PA",
        "label": "Eurobio Scientific Societe anonyme",
        "price": 5.14
    }, {
        "symbol": "ECT.AS",
        "label": "Eurocastle Investment Limited",
        "price": 7.84
    }, {
        "symbol": "ECMPA.AS",
        "label": "Eurocommercial Properties N.V.",
        "price": 23.28
    }, {
        "symbol": "ALECR.PA",
        "label": "Eurofins-Cerep SA",
        "price": 6200.0
    }, {
        "symbol": "ERF.PA",
        "label": "Eurofins Scientific SE",
        "price": 482.0
    }, {
        "symbol": "ALGEM.PA",
        "label": "Eurogerm SA",
        "price": 30.8
    }, {
        "symbol": "MLERO.PA",
        "label": "Euroland Corporate Societe anonyme",
        "price": 0.77
    }, {
        "symbol": "EMG.PA",
        "label": "Euromedis Groupe",
        "price": 4.46
    }, {
        "symbol": "EURN.BR",
        "label": "Euronav NV",
        "price": 10.54
    }, {
        "symbol": "ENX.PA",
        "label": "Euronext N.V.",
        "price": 77.75
    }, {
        "symbol": "ECP.PA",
        "label": "EuropaCorp",
        "price": 0.817
    }, {
        "symbol": "EUCAR.PA",
        "label": "Europcar Mobility Group S.A.",
        "price": 4.46
    }, {
        "symbol": "ALEUP.PA",
        "label": "Europlasma S.A.",
        "price": 0.004
    }, {
        "symbol": "ALPBS.BR",
        "label": "EuroPubliDis SA",
        "price": 0.13
    }, {
        "symbol": "ETL.PA",
        "label": "Eutelsat Communications S.A.",
        "price": 14.75
    }, {
        "symbol": "ALEVA.BR",
        "label": "Evadix Sa",
        "price": 0.11
    }, {
        "symbol": "MLEVE.PA",
        "label": "Everset",
        "price": 0.11
    }, {
        "symbol": "ALTVO.PA",
        "label": "Evolis SA",
        "price": 30.1
    }, {
        "symbol": "EVS.BR",
        "label": "EVS Broadcast Equipment S.A.",
        "price": 21.1
    }, {
        "symbol": "EXAC.PA",
        "label": "Exacompta Clairefontaine S.A.",
        "price": 117.0
    }, {
        "symbol": "EXE.PA",
        "label": "Exel Industries Societe Anonyme",
        "price": 47.4
    }, {
        "symbol": "EXM.BR",
        "label": "Exmar NV",
        "price": 5.51
    }, {
        "symbol": "EPCP.PA",
        "label": "EPC Groupe",
        "price": 830.0
    }, {
        "symbol": "EXPL.PA",
        "label": "EPC Groupe",
        "price": 765.0
    }, {
        "symbol": "FAGR.BR",
        "label": "Fagron NV",
        "price": 20.48
    }, {
        "symbol": "MLFMV.LS",
        "label": "Farminveste - SGPS, S.A.",
        "price": 4.6
    }, {
        "symbol": "ALFBA.PA",
        "label": "Fashion Bel Air S.A.",
        "price": 0.48
    }, {
        "symbol": "FAST.AS",
        "label": "Fastned B.V.",
        "price": 13.0
    }, {
        "symbol": "EO.PA",
        "label": "Faurecia S.E.",
        "price": 46.05
    }, {
        "symbol": "FAUV.PA",
        "label": "Fauvet-Girel",
        "price": 31.4
    }, {
        "symbol": "FAYE.PA",
        "label": "Fayenceries de Sarreguemines, Digoin & Vitry-le-Francois Societe Anonyme",
        "price": 30.0
    }, {
        "symbol": "MLFDV.PA",
        "label": "FD",
        "price": 2.86
    }, {
        "symbol": "FCMC.PA",
        "label": "Societe Fermiere du Casino Municipal de Cannes",
        "price": 1740.0
    }, {
        "symbol": "FALG.PA",
        "label": "Fermentalg Societe Anonyme",
        "price": 1.828
    }, {
        "symbol": "FFP.PA",
        "label": "FFP Societe anonyme",
        "price": 102.4
    }, {
        "symbol": "SACI.PA",
        "label": "Fiducial Office Solutions",
        "price": 28.0
    }, {
        "symbol": "ORIA.PA",
        "label": "Fiducial Real Estate SA",
        "price": 196.0
    }, {
        "symbol": "FGA.PA",
        "label": "Figeac Aero Societe Anonyme",
        "price": 8.75
    }, {
        "symbol": "ALFIL.PA",
        "label": "Filae S.A.",
        "price": 6.65
    }, {
        "symbol": "BERR.PA",
        "label": "Financiere et Immobiliere de l'Etang de Berre et de la Mediterranee S.A.",
        "price": 5.3
    }, {
        "symbol": "EBPF.PA",
        "label": "Financiere et Immobiliere de l'Etang de Berre et de la Mediterranee S.A.",
        "price": 104.0
    }, {
        "symbol": "FOAF.PA",
        "label": "Fin.Ouest Africain",
        "price": 7.35
    }, {
        "symbol": "FINM.PA",
        "label": "Financiere Marjos SA",
        "price": 0.23
    }, {
        "symbol": "ODET.PA",
        "label": "Financiere de l'Odet SA",
        "price": 796.0
    }, {
        "symbol": "FNTS.PA",
        "label": "Finatis Societe Anonyme",
        "price": 29.2
    }, {
        "symbol": "FIPP.PA",
        "label": "FIPP S.A.",
        "price": 0.278
    }, {
        "symbol": "MLFIR.PA",
        "label": "FirstCaution SA",
        "price": 7.0
    }, {
        "symbol": "FLE.PA",
        "label": "Fleury Michon SA",
        "price": 30.8
    }, {
        "symbol": "FLEXD.LS",
        "label": "Flexdeal Simfe S.A.",
        "price": 5.0
    }, {
        "symbol": "FLEX.BR",
        "label": "FleXos Societe anonyme",
        "price": 1.99
    }, {
        "symbol": "FLOB.BR",
        "label": "Floridienne S.A.",
        "price": 220.0
    }, {
        "symbol": "FLOW.AS",
        "label": "Flow Traders N.V.",
        "price": 21.18
    }, {
        "symbol": "FLUX.BR",
        "label": "Fluxys Belgium SA",
        "price": 29.0
    }, {
        "symbol": "FNAC.PA",
        "label": "Fnac Darty SA",
        "price": 46.08
    }, {
        "symbol": "FNG.BR",
        "label": "FNG NV",
        "price": 15.6
    }, {
        "symbol": "ALFOC.PA",
        "label": "Focus Home Interactive Societe anonyme",
        "price": 23.8
    }, {
        "symbol": "FPN.PA",
        "label": "Fonciere Paris Nord",
        "price": 0.0298
    }, {
        "symbol": "LEBL.PA",
        "label": "Fonciere 7 investissement Societe Anonyme",
        "price": 1.02
    }, {
        "symbol": "FATL.PA",
        "label": "Fonciere Atland",
        "price": 177.0
    }, {
        "symbol": "EURS.PA",
        "label": "Fonciere Euris SA",
        "price": 16.5
    }, {
        "symbol": "INEA.PA",
        "label": "Fonciere Inea S.A.",
        "price": 41.8
    }, {
        "symbol": "FLY.PA",
        "label": "Societe Fonciere Lyonnaise SA",
        "price": 78.4
    }, {
        "symbol": "MLVIN.PA",
        "label": "Fonciere Vindi Societe Anonyme",
        "price": 2.48
    }, {
        "symbol": "SPEL.PA",
        "label": "Fonciere Volta",
        "price": 7.6
    }, {
        "symbol": "FORE.PA",
        "label": "La Forestiere Equatoriale SA",
        "price": 680.0
    }, {
        "symbol": "FFARM.AS",
        "label": "ForFarmers N.V.",
        "price": 6.11
    }, {
        "symbol": "FOU.BR",
        "label": "Fountain S.A.",
        "price": 1.25
    }, {
        "symbol": "ALFPC.PA",
        "label": "Fountaine Pajot SA",
        "price": 114.5
    }, {
        "symbol": "LFDE.PA",
        "label": "La Francaise de l'Energie S.A.",
        "price": 17.4
    }, {
        "symbol": "MLFTI.PA",
        "label": "France Tourisme Immobilier SA",
        "price": 0.03
    }, {
        "symbol": "ALFRE.PA",
        "label": "Freelance.com SA",
        "price": 2.88
    }, {
        "symbol": "FREY.PA",
        "label": "Frey SA",
        "price": 33.7
    }, {
        "symbol": "FUR.AS",
        "label": "Fugro N.V.",
        "price": 9.91
    }, {
        "symbol": "FCP.LS",
        "label": "Futebol Clube do Porto - Futebol, S.A.D.",
        "price": 0.72
    }, {
        "symbol": "MLGAI.PA",
        "label": "Grande Armee Investissement SA",
        "price": 70.0
    }, {
        "symbol": "GLPG.AS",
        "label": "Galapagos NV",
        "price": 208.6
    }, {
        "symbol": "MLGAL.PA",
        "label": "Galeo Concept SA",
        "price": 0.2
    }, {
        "symbol": "GALIM.PA",
        "label": "Galimmo SCA",
        "price": 18.2
    }, {
        "symbol": "GALP.LS",
        "label": "Galp Energia, SGPS, S.A.",
        "price": 15.045
    }, {
        "symbol": "ALBI.PA",
        "label": "Gascogne SA",
        "price": 3.38
    }, {
        "symbol": "GAM.PA",
        "label": "Gaumont SA",
        "price": 127.5
    }, {
        "symbol": "ALGAU.PA",
        "label": "Gaussin SA",
        "price": 0.1948
    }, {
        "symbol": "GBLB.BR",
        "label": "Groupe Bruxelles Lambert SA",
        "price": 96.06
    }, {
        "symbol": "GEA.PA",
        "label": "Grenobloise d'Electronique et d'Automatismes Societe Anonyme",
        "price": 104.0
    }, {
        "symbol": "GECP.PA",
        "label": "GECI International S.A.",
        "price": 0.0604
    }, {
        "symbol": "GFC.PA",
        "label": "Gecina SA",
        "price": 165.6
    }, {
        "symbol": "GNE.PA",
        "label": "General Electric Company",
        "price": 10.62
    }, {
        "symbol": "GENX.PA",
        "label": "Generix SA",
        "price": 6.48
    }, {
        "symbol": "GNRO.PA",
        "label": "GeNeuro SA",
        "price": 3.27
    }, {
        "symbol": "GNFT.PA",
        "label": "Genfit SA",
        "price": 18.7
    }, {
        "symbol": "GENK.BR",
        "label": "Genk Logistics",
        "price": 49.56
    }, {
        "symbol": "GKTX.PA",
        "label": "Genkyotex SA",
        "price": 2.045
    }, {
        "symbol": "GV.PA",
        "label": "Genomic Vision Societe Anonyme",
        "price": 0.472
    }, {
        "symbol": "ALGEN.PA",
        "label": "genOway S.A.",
        "price": 1.645
    }, {
        "symbol": "SIGHT.PA",
        "label": "GenSight Biologics S.A.",
        "price": 4.52
    }, {
        "symbol": "MLGEQ.PA",
        "label": "Gentlemen's Equity S.A.",
        "price": 0.05
    }, {
        "symbol": "GET.PA",
        "label": "Getlink SE",
        "price": 15.59
    }, {
        "symbol": "ALGEV.PA",
        "label": "Gevelot SA",
        "price": 194.0
    }, {
        "symbol": "GIMB.BR",
        "label": "GIMV NV",
        "price": 56.3
    }, {
        "symbol": "GLO.PA",
        "label": "GL Events SA",
        "price": 24.15
    }, {
        "symbol": "GLINT.LS",
        "label": "Glintt - Global Intelligent Technologies, S.A.",
        "price": 0.13
    }, {
        "symbol": "ALGBE.PA",
        "label": "Global Bioenergies SA",
        "price": 4.02
    }, {
        "symbol": "ALGEP.PA",
        "label": "Global EcoPower Societe Anonyme",
        "price": 1.7
    }, {
        "symbol": "GLOG.BR",
        "label": "Global Graphics PLC",
        "price": 3.58
    }, {
        "symbol": "ALGLD.PA",
        "label": "Gold By Gold SA",
        "price": 1.4
    }, {
        "symbol": "MLGML.PA",
        "label": "Gour Medical SA",
        "price": 0.44
    }, {
        "symbol": "GPE.PA",
        "label": "Groupe Pizzorno Environnement",
        "price": 13.3
    }, {
        "symbol": "ALPAR.PA",
        "label": "Groupe Parot SA",
        "price": 4.44
    }, {
        "symbol": "GRVO.PA",
        "label": "Graines Voltz",
        "price": 62.0
    }, {
        "symbol": "GVNV.AS",
        "label": "GrandVision N.V.",
        "price": 27.8
    }, {
        "symbol": "MLCMG.PA",
        "label": "Compagnie Miniere Grecemar SA",
        "price": 6.05
    }, {
        "symbol": "MLGES.PA",
        "label": "Green Energy 4 Seasons",
        "price": 5.95
    }, {
        "symbol": "GREEN.BR",
        "label": "Greenyard NV",
        "price": 4.54
    }, {
        "symbol": "MLGRC.PA",
        "label": "Groupe Carnivor Societe Anonyme",
        "price": 16.9
    }, {
        "symbol": "CEN.PA",
        "label": "Groupe CRIT SA",
        "price": 69.6
    }, {
        "symbol": "FLO.PA",
        "label": "Groupe Flo SA",
        "price": 0.21
    }, {
        "symbol": "GOE.PA",
        "label": "Groupe Gorge SA",
        "price": 17.92
    }, {
        "symbol": "ALGIL.PA",
        "label": "Groupe Guillin S.A.",
        "price": 15.76
    }, {
        "symbol": "IRD.PA",
        "label": "Groupe IRD SA",
        "price": 26.9
    }, {
        "symbol": "GJAJ.PA",
        "label": "Groupe JAJ",
        "price": 1.03
    }, {
        "symbol": "ALLDL.PA",
        "label": "Groupe LDLC societe anonyme",
        "price": 14.3
    }, {
        "symbol": "OPN.PA",
        "label": "Groupe Open",
        "price": 12.78
    }, {
        "symbol": "PARP.PA",
        "label": "Groupe Partouche SA",
        "price": 28.0
    }, {
        "symbol": "SFPI.PA",
        "label": "GROUPE SFPI SA",
        "price": 1.79
    }, {
        "symbol": "ALGTR.PA",
        "label": "Groupe Tera SA",
        "price": 4.1995
    }, {
        "symbol": "ALIMO.PA",
        "label": "Groupimo S.A.",
        "price": 0.1
    }, {
        "symbol": "GROWN.BR",
        "label": "Growners SA",
        "price": 2.1
    }, {
        "symbol": "GTT.PA",
        "label": "Gaztransport & Technigaz SA",
        "price": 97.05
    }, {
        "symbol": "MLGDI.PA",
        "label": "Guandao Puer Investment Co., Ltd S.A.",
        "price": 1.2
    }, {
        "symbol": "GBT.PA",
        "label": "Guerbet SA",
        "price": 38.5
    }, {
        "symbol": "GUI.PA",
        "label": "Guillemot Corporation S.A.",
        "price": 2.36
    }, {
        "symbol": "ALHEO.PA",
        "label": "H2O Innovation Inc.",
        "price": 0.685
    }, {
        "symbol": "HAL.AS",
        "label": "HAL Trust",
        "price": 145.6
    }, {
        "symbol": "HAMO.BR",
        "label": "Hamon & Cie (International) SA",
        "price": 1.2
    }, {
        "symbol": "ALHVS.PA",
        "label": "Harvest S.A.",
        "price": 89.5
    }, {
        "symbol": "PIG.PA",
        "label": "Haulotte Group SA",
        "price": 5.25
    }, {
        "symbol": "MLAHC.PA",
        "label": "@Health SA",
        "price": 2.0
    }, {
        "symbol": "HEIJM.AS",
        "label": "Heijmans N.V.",
        "price": 7.92
    }, {
        "symbol": "HEIA.AS",
        "label": "Heineken N.V.",
        "price": 103.4
    }, {
        "symbol": "HEIO.AS",
        "label": "Heineken Holding N.V.",
        "price": 94.0
    }, {
        "symbol": "ALHRG.PA",
        "label": "Herige",
        "price": 29.8
    }, {
        "symbol": "RMS.PA",
        "label": "Hermes International Societe en commandite par actions",
        "price": 720.0
    }, {
        "symbol": "HEXA.PA",
        "label": "Hexaom S.A.",
        "price": 36.85
    }, {
        "symbol": "HF.PA",
        "label": "HF Company Societe Anonyme",
        "price": 5.0
    }, {
        "symbol": "HCO.PA",
        "label": "High Co. SA",
        "price": 6.04
    }, {
        "symbol": "ALHIO.PA",
        "label": "Hiolle Industries S.A.",
        "price": 4.74
    }, {
        "symbol": "HIPAY.PA",
        "label": "HiPay Group SA",
        "price": 5.02
    }, {
        "symbol": "ALHIT.PA",
        "label": "HiTechPros SA",
        "price": 17.0
    }, {
        "symbol": "MLHK.PA",
        "label": "H&K AG",
        "price": 160.0
    }, {
        "symbol": "MLHBB.PA",
        "label": "Financiere Hoche Bains-Les-Bains SA",
        "price": 15.2
    }, {
        "symbol": "HOLCO.AS",
        "label": "Holland Colours NV",
        "price": 105.0
    }, {
        "symbol": "MLHCF.PA",
        "label": "Home Concept",
        "price": 14.2
    }, {
        "symbol": "HOMI.BR",
        "label": "Home Invest Belgium S.A.",
        "price": 114.0
    }, {
        "symbol": "MLHPE.PA",
        "label": "Hopening SA",
        "price": 3.5
    }, {
        "symbol": "HOP.PA",
        "label": "Hopscotch Global PR Group",
        "price": 8.82
    }, {
        "symbol": "ALHSW.PA",
        "label": "Horizontal Software S.A.",
        "price": 0.95
    }, {
        "symbol": "MLHMC.PA",
        "label": "Hotel Majestic Cannes",
        "price": 3980.0
    }, {
        "symbol": "MLHOT.PA",
        "label": "Hotelim Societe Anonyme",
        "price": 35.4
    }, {
        "symbol": "HDP.PA",
        "label": "Les Hotels de Paris SA",
        "price": 4.22
    }, {
        "symbol": "MLHIN.PA",
        "label": "Societe Hoteliere et Immobiliere de Nice S.A.",
        "price": 258.0
    }, {
        "symbol": "HSB.PA",
        "label": "HSBC Holdings plc",
        "price": 6.97
    }, {
        "symbol": "HDG.AS",
        "label": "Hunter Douglas N.V.",
        "price": 61.0
    }, {
        "symbol": "ALHYG.PA",
        "label": "Hybrigenics Societe Anonyme",
        "price": 0.0924
    }, {
        "symbol": "HYDRA.AS",
        "label": "Hydratec Industries NV",
        "price": 66.5
    }, {
        "symbol": "MLHYD.PA",
        "label": "Hydraulique P.B Societe anonyme",
        "price": 500.0
    }, {
        "symbol": "MLHYE.PA",
        "label": "Hydro Exploitations SA",
        "price": 47.4
    }, {
        "symbol": "ALICR.PA",
        "label": "I.CERAM SA",
        "price": 1.91
    }, {
        "symbol": "ALI2S.PA",
        "label": "i2S SA",
        "price": 3.86
    }, {
        "symbol": "IBAB.BR",
        "label": "Ion Beam Applications SA",
        "price": 12.9
    }, {
        "symbol": "IBS.LS",
        "label": "Ibersol, S.G.P.S., S.A.",
        "price": 8.9
    }, {
        "symbol": "IBMA.BR",
        "label": "International Business Machines Corporation",
        "price": 138.0
    }, {
        "symbol": "ICAD.PA",
        "label": "Icade",
        "price": 98.3
    }, {
        "symbol": "ICT.AS",
        "label": "ICT Group N.V.",
        "price": 11.4
    }, {
        "symbol": "IDL.PA",
        "label": "ID Logistics Group SA",
        "price": 171.6
    }, {
        "symbol": "IDIP.PA",
        "label": "IDI",
        "price": 45.1
    }, {
        "symbol": "MLIDS.PA",
        "label": "IDS France SAS",
        "price": 0.88
    }, {
        "symbol": "ALIDS.PA",
        "label": "Idsud",
        "price": 61.5
    }, {
        "symbol": "IEP.BR",
        "label": "Iep Invest, NV",
        "price": 7.8
    }, {
        "symbol": "IEX.AS",
        "label": "IEX Group N.V.",
        "price": 3.0
    }, {
        "symbol": "IFF.PA",
        "label": "International Flavors & Fragrances Inc.",
        "price": 124.0
    }, {
        "symbol": "IGE.PA",
        "label": "IGE+XAO Group",
        "price": 165.0
    }, {
        "symbol": "ILD.PA",
        "label": "Iliad SA",
        "price": 121.3
    }, {
        "symbol": "MLIML.PA",
        "label": "ImAlliance SA",
        "price": 0.138
    }, {
        "symbol": "IMCD.AS",
        "label": "IMCD N.V.",
        "price": 80.0
    }, {
        "symbol": "NK.PA",
        "label": "Imerys S.A.",
        "price": 41.26
    }, {
        "symbol": "MLIPP.PA",
        "label": "Societe Anonyme Immobilier Parisienne De La Perle Et Des Pierres Precieuses",
        "price": 51.5
    }, {
        "symbol": "ALIMR.PA",
        "label": "Immersion SA",
        "price": 1.0
    }, {
        "symbol": "MCC.BR",
        "label": "Immo Mcc NV",
        "price": 761.8
    }, {
        "symbol": "IMMOU.BR",
        "label": "Immo Moury SCA",
        "price": 49.2
    }, {
        "symbol": "IMDA.PA",
        "label": "Immobiliere Dassault SA",
        "price": 73.5
    }, {
        "symbol": "IMMO.BR",
        "label": "IMMOBEL SA",
        "price": 66.4
    }, {
        "symbol": "GPA.LS",
        "label": "Imobiliaria Construtora Grao Para, S.A.",
        "price": 0.199
    }, {
        "symbol": "ALIMP.PA",
        "label": "Implanet S.A.",
        "price": 0.081
    }, {
        "symbol": "IPR.LS",
        "label": "Impresa - Sociedade Gestora de Participacoes Sociais, S.A.",
        "price": 0.216
    }, {
        "symbol": "MLIMP.PA",
        "label": "Imprimerie Chirat Societe Anonyme",
        "price": 13.1
    }, {
        "symbol": "INA.LS",
        "label": "Inapa - Investimentos, Participacoes e Gestao, S.A.",
        "price": 0.0408
    }, {
        "symbol": "INFE.PA",
        "label": "Compagnie Industrielle et Financiere d'Entreprises SA",
        "price": 50.0
    }, {
        "symbol": "MLIFC.PA",
        "label": "Infoclip S.A.",
        "price": 1.18
    }, {
        "symbol": "INF.PA",
        "label": "Infotel SA",
        "price": 40.35
    }, {
        "symbol": "INGA.AS",
        "label": "ING Groep N.V.",
        "price": 10.544
    }, {
        "symbol": "ING.PA",
        "label": "Ingenico Group - GCS",
        "price": 104.3
    }, {
        "symbol": "IPH.PA",
        "label": "Innate Pharma S.A.",
        "price": 6.945
    }, {
        "symbol": "INN.PA",
        "label": "Innelec Multimedia SA",
        "price": 4.9
    }, {
        "symbol": "STAL.PA",
        "label": "Installux S.A.",
        "price": 356.0
    }, {
        "symbol": "ALINT.PA",
        "label": "IntegraGen SA",
        "price": 1.185
    }, {
        "symbol": "INCO.BR",
        "label": "Intel Corporation",
        "price": 58.89
    }, {
        "symbol": "ITP.PA",
        "label": "Interparfums SA",
        "price": 38.8
    }, {
        "symbol": "INTER.AS",
        "label": "Intertrust N.V.",
        "price": 17.04
    }, {
        "symbol": "INTO.BR",
        "label": "Intervest Offices & Warehouses nv",
        "price": 27.0
    }, {
        "symbol": "ITXT.PA",
        "label": "Intexa S.A.",
        "price": 2.5
    }, {
        "symbol": "ALINS.PA",
        "label": "Intrasense Societe anonyme",
        "price": 0.2625
    }, {
        "symbol": "IVA.PA",
        "label": "Inventiva S.A.",
        "price": 4.52
    }, {
        "symbol": "ALINV.PA",
        "label": "Invibes Advertising SA",
        "price": 5.1
    }, {
        "symbol": "MLIOC.PA",
        "label": "Indoor and Outdoor Communication Holding SA",
        "price": 0.163
    }, {
        "symbol": "IPN.PA",
        "label": "Ipsen S.A.",
        "price": 81.55
    }, {
        "symbol": "IPS.PA",
        "label": "Ipsos SA",
        "price": 30.45
    }, {
        "symbol": "ITL.PA",
        "label": "IT Link SA",
        "price": 16.05
    }, {
        "symbol": "MLITN.PA",
        "label": "Italy Innovazioni S.P.A.",
        "price": 6.5
    }, {
        "symbol": "ITE.PA",
        "label": "Itesoft S.A.",
        "price": 2.97
    }, {
        "symbol": "ITS.PA",
        "label": "ITS Group",
        "price": 6.62
    }, {
        "symbol": "JMT.LS",
        "label": "Jeronimo Martins, SGPS, S.A.",
        "price": 15.525
    }, {
        "symbol": "JBOG.PA",
        "label": "Jacques Bogart S.A.",
        "price": 11.25
    }, {
        "symbol": "JCQ.PA",
        "label": "Jacquet Metal Service SA",
        "price": 15.38
    }, {
        "symbol": "DEC.PA",
        "label": "JCDecaux SA",
        "price": 26.54
    }, {
        "symbol": "JEN.BR",
        "label": "Jensen-Group NV",
        "price": 33.9
    }, {
        "symbol": "ALKAL.PA",
        "label": "Kalray S.A.",
        "price": 19.0
    }, {
        "symbol": "KARD.AS",
        "label": "Kardan N.V.",
        "price": 0.04
    }, {
        "symbol": "KOF.PA",
        "label": "Kaufman & Broad S.A.",
        "price": 38.34
    }, {
        "symbol": "KBC.BR",
        "label": "KBC Group NV",
        "price": 67.92
    }, {
        "symbol": "KBCA.BR",
        "label": "KBC Ancora SCA",
        "price": 45.4
    }, {
        "symbol": "KENDR.AS",
        "label": "Kendrion N.V.",
        "price": 20.85
    }, {
        "symbol": "KER.PA",
        "label": "Kering SA",
        "price": 605.6
    }, {
        "symbol": "ALKLK.PA",
        "label": "Kerlink SA",
        "price": 7.7
    }, {
        "symbol": "KEY.PA",
        "label": "Keyrus S.A.",
        "price": 3.0
    }, {
        "symbol": "KEYW.BR",
        "label": "Keyware Technologies NV",
        "price": 0.875
    }, {
        "symbol": "KDS.AS",
        "label": "Kiadis Pharma N.V.",
        "price": 2.032
    }, {
        "symbol": "KIN.BR",
        "label": "Kinepolis Group NV",
        "price": 55.7
    }, {
        "symbol": "ALKKO.PA",
        "label": "KKO International SA",
        "price": 0.108
    }, {
        "symbol": "LI.PA",
        "label": "Klepierre SA",
        "price": 32.72
    }, {
        "symbol": "KORI.PA",
        "label": "Korian",
        "price": 42.38
    }, {
        "symbol": "KPN.AS",
        "label": "Koninklijke KPN N.V.",
        "price": 2.759
    }, {
        "symbol": "MLKRI.PA",
        "label": "Bernard Krief Consultants SA",
        "price": 1.42
    }, {
        "symbol": "MLVAP.PA",
        "label": "Kumulus Vape S.A.",
        "price": 3.9995
    }, {
        "symbol": "OR.PA",
        "label": "L'Oreal S.A.",
        "price": 273.5
    }, {
        "symbol": "LFVE.PA",
        "label": "La Fonciere Verte",
        "price": 84.0
    }, {
        "symbol": "ALPER.PA",
        "label": "La Perla Fashion Holding N.V.",
        "price": 4.3495
    }, {
        "symbol": "LACR.PA",
        "label": "Lacroix SA",
        "price": 29.0
    }, {
        "symbol": "LHN.PA",
        "label": "LafargeHolcim Ltd",
        "price": 49.62
    }, {
        "symbol": "LAF.PA",
        "label": "Lafuma SA",
        "price": 18.5
    }, {
        "symbol": "MMB.PA",
        "label": "Lagardere SCA",
        "price": 19.11
    }, {
        "symbol": "ALLAN.PA",
        "label": "Lanson-BCC",
        "price": 25.6
    }, {
        "symbol": "LAT.PA",
        "label": "Latecoere S.A.",
        "price": 3.66
    }, {
        "symbol": "LPE.PA",
        "label": "Laurent-Perrier S.A.",
        "price": 83.0
    }, {
        "symbol": "LVIDE.AS",
        "label": "Lavide Holding N.V.",
        "price": 0.2
    }, {
        "symbol": "LOUP.PA",
        "label": "L.D.C. S.A.",
        "price": 109.0
    }, {
        "symbol": "ALTAN.PA",
        "label": "Le Tanneur & Cie Societe anonyme",
        "price": 3.52
    }, {
        "symbol": "LEAS.BR",
        "label": "Leasinvest Real Estate SCA",
        "price": 115.5
    }, {
        "symbol": "LBON.PA",
        "label": "Compagnie Lebon",
        "price": 127.0
    }, {
        "symbol": "LSS.PA",
        "label": "Lectra SA",
        "price": 23.8
    }, {
        "symbol": "LR.PA",
        "label": "Legrand SA",
        "price": 73.32
    }, {
        "symbol": "ALLHB.PA",
        "label": "Les Hotels Baverez S.A.",
        "price": 62.5
    }, {
        "symbol": "LNC.PA",
        "label": "Les Nouveaux Constructeurs SA",
        "price": 44.9
    }, {
        "symbol": "ALLEX.PA",
        "label": "Lexibook - Linguistic Electronic System Societe anonyme",
        "price": 1.7
    }, {
        "symbol": "LIN.PA",
        "label": "Linedata Services S.A.",
        "price": 30.0
    }, {
        "symbol": "LIG.LS",
        "label": "Lisgrafica - Impressao e Artes Graficas, S.A.",
        "price": 0.0115
    }, {
        "symbol": "FII.PA",
        "label": "Lisi S.A.",
        "price": 28.05
    }, {
        "symbol": "LIT.LS",
        "label": "Litho Formas, S.A.",
        "price": 0.081
    }, {
        "symbol": "ALLLN.PA",
        "label": "LleidaNetworks Serveis Telematics, S.A.",
        "price": 1.26
    }, {
        "symbol": "LNA.PA",
        "label": "LNA Sante SA",
        "price": 47.9
    }, {
        "symbol": "ALLOG.PA",
        "label": "Logic Instrument S.A.",
        "price": 0.658
    }, {
        "symbol": "MLCAC.PA",
        "label": "Lombard et Medot SA",
        "price": 13.0
    }, {
        "symbol": "LOTB.BR",
        "label": "Lotus Bakeries NV",
        "price": 2640.0
    }, {
        "symbol": "BOLS.AS",
        "label": "Lucas Bols N.V.",
        "price": 13.75
    }, {
        "symbol": "ALUCI.PA",
        "label": "Lucibel SA",
        "price": 0.872
    }, {
        "symbol": "LBIRD.PA",
        "label": "Lumibird SA",
        "price": 14.96
    }, {
        "symbol": "LUXA.BR",
        "label": "Immo Lux-Airport SA",
        "price": 7.03
    }, {
        "symbol": "MLV4S.PA",
        "label": "Les Verandas 4 Saisons",
        "price": 2.9
    }, {
        "symbol": "MC.PA",
        "label": "LVMH Moet Hennessy - Louis Vuitton, Societe Europeenne",
        "price": 434.4
    }, {
        "symbol": "LYS.PA",
        "label": "Lysogene S.A.",
        "price": 4.19
    }, {
        "symbol": "MRM.PA",
        "label": "MRM",
        "price": 1.44
    }, {
        "symbol": "ALMII.PA",
        "label": "Maison Internationale de l'Informatique S.A.S.",
        "price": 5.45
    }, {
        "symbol": "MLMAD.PA",
        "label": "Made SA",
        "price": 5.3
    }, {
        "symbol": "ALMNG.PA",
        "label": "madvertise",
        "price": 0.411
    }, {
        "symbol": "MLMGL.PA",
        "label": "Magillem Design Services SA",
        "price": 16.0
    }, {
        "symbol": "MSTY.PA",
        "label": "Mainstay Medical International plc",
        "price": 2.8
    }, {
        "symbol": "MLMAB.PA",
        "label": "Maison Antoine Baud Societe Anonyme",
        "price": 120.0
    }, {
        "symbol": "MLCLI.PA",
        "label": "Maison Clio Blue SA",
        "price": 2.02
    }, {
        "symbol": "MLMAI.PA",
        "label": "Maisons D'aujourd'hui",
        "price": 0.86
    }, {
        "symbol": "MDM.PA",
        "label": "Maisons du Monde S.A.",
        "price": 11.94
    }, {
        "symbol": "ALMAK.PA",
        "label": "Makheia Group Societe Anonyme",
        "price": 0.885
    }, {
        "symbol": "MALT.PA",
        "label": "Malteries Franco-Belges Societe Anonyme",
        "price": 610.0
    }, {
        "symbol": "MTU.PA",
        "label": "Manitou BF SA",
        "price": 18.54
    }, {
        "symbol": "MAN.PA",
        "label": "Manutan International SA",
        "price": 64.4
    }, {
        "symbol": "MAREL.AS",
        "label": "Marel hf.",
        "price": 4.49
    }, {
        "symbol": "IAM.PA",
        "label": "Itissalat Al-Maghrib (IAM) S.A.",
        "price": 14.5
    }, {
        "symbol": "MAR.LS",
        "label": "Martifer SGPS, S.A.",
        "price": 0.422
    }, {
        "symbol": "ALMAS.PA",
        "label": "Mastrad Societe Anonyme",
        "price": 0.469
    }, {
        "symbol": "MKEA.PA",
        "label": "Mauna Kea Technologies SA",
        "price": 1.68
    }, {
        "symbol": "MAU.PA",
        "label": "Etablissements Maurel & Prom S.A.",
        "price": 2.845
    }, {
        "symbol": "MBWS.PA",
        "label": "Marie Brizard Wine & Spirits SA",
        "price": 1.61
    }, {
        "symbol": "MCPHY.PA",
        "label": "McPhy Energy S.A.",
        "price": 3.845
    }, {
        "symbol": "MDXH.BR",
        "label": "MDxHealth SA",
        "price": 0.992
    }, {
        "symbol": "ALMEC.PA",
        "label": "Mecelec Composites SA",
        "price": 2.4
    }, {
        "symbol": "EDI.PA",
        "label": "Media 6 SA",
        "price": 10.9
    }, {
        "symbol": "MCP.LS",
        "label": "Grupo Media Capital, SGPS, S.A.",
        "price": 2.14
    }, {
        "symbol": "MLLAB.PA",
        "label": "Media Lab S.p.A.",
        "price": 3.0
    }, {
        "symbol": "ALKER.PA",
        "label": "Media-Maker S.p.A.",
        "price": 10.0
    }, {
        "symbol": "ALMDT.PA",
        "label": "Median Technologies SA",
        "price": 1.84
    }, {
        "symbol": "MDW.PA",
        "label": "Mediawan SA",
        "price": 10.74
    }, {
        "symbol": "ALMED.PA",
        "label": "Medicrea International SA",
        "price": 3.82
    }, {
        "symbol": "MEDCL.PA",
        "label": "MedinCell S.A.",
        "price": 6.64
    }, {
        "symbol": "MELE.BR",
        "label": "Melexis NV",
        "price": 67.4
    }, {
        "symbol": "MEMS.PA",
        "label": "MEMSCAP, S.A.",
        "price": 1.805
    }, {
        "symbol": "MERY.PA",
        "label": "Mercialys",
        "price": 11.87
    }, {
        "symbol": "MRK.PA",
        "label": "Merck & Co., Inc.",
        "price": 81.4
    }, {
        "symbol": "MRN.PA",
        "label": "Mersen S.A.",
        "price": 32.5
    }, {
        "symbol": "METEX.PA",
        "label": "METabolic EXplorer S.A.",
        "price": 1.586
    }, {
        "symbol": "MLETA.PA",
        "label": "Metalliance Societe Anonyme",
        "price": 14.6
    }, {
        "symbol": "ALMET.PA",
        "label": "Methanor SCA",
        "price": 5.05
    }, {
        "symbol": "MLMIB.PA",
        "label": "Metrics in Balance N.V.",
        "price": 7.5
    }, {
        "symbol": "MMT.PA",
        "label": "Metropole Television S.A.",
        "price": 16.19
    }, {
        "symbol": "ALMGI.PA",
        "label": "MG International",
        "price": 3.34
    }, {
        "symbol": "ALMDG.PA",
        "label": "MGI Digital Technology Societe Anonyme",
        "price": 52.0
    }, {
        "symbol": "ML.PA",
        "label": "Compagnie Generale des Etablissements Michelin",
        "price": 110.2
    }, {
        "symbol": "MUN.PA",
        "label": "Micropole S.A.",
        "price": 1.25
    }, {
        "symbol": "MSF.BR",
        "label": "Microsoft Corporation",
        "price": 167.2
    }, {
        "symbol": "ALMIC.PA",
        "label": "Microwave Vision S.A.",
        "price": 17.8
    }, {
        "symbol": "MLNMA.PA",
        "label": "Nicolas Miguet et Associes SA",
        "price": 3.28
    }, {
        "symbol": "MIKO.BR",
        "label": "Miko NV",
        "price": 109.0
    }, {
        "symbol": "ALMLB.PA",
        "label": "Miliboo Societe anonyme",
        "price": 1.91
    }, {
        "symbol": "MITRA.BR",
        "label": "Mithra Pharmaceuticals S.A.",
        "price": 25.54
    }, {
        "symbol": "NEDSE.AS",
        "label": "MKB Nedsense NV",
        "price": 0.15
    }, {
        "symbol": "ALMND.PA",
        "label": "Montagne et Neige Developpement SA",
        "price": 0.31
    }, {
        "symbol": "MONC.PA",
        "label": "Financiere Moncey SA",
        "price": 5000.0
    }, {
        "symbol": "MLMON.PA",
        "label": "MonFinancier SAS",
        "price": 0.33
    }, {
        "symbol": "MONT.BR",
        "label": "Montea Comm. VA",
        "price": 84.8
    }, {
        "symbol": "MOP.BR",
        "label": "Palmboomen Cultuur Maatschappij Mopoli (Palmeraies De Mopoli) N.V.",
        "price": 290.0
    }, {
        "symbol": "MOPF.BR",
        "label": "Mopoli Fond",
        "price": 8000.0
    }, {
        "symbol": "MORE.AS",
        "label": "Morefield Group N.V.",
        "price": 0.29
    }, {
        "symbol": "EGL.LS",
        "label": "Mota-Engil, SGPS, S.A.",
        "price": 1.805
    }, {
        "symbol": "ALMOU.PA",
        "label": "Moulinvest SA",
        "price": 5.34
    }, {
        "symbol": "MOUR.BR",
        "label": "Moury Construct SA",
        "price": 159.0
    }, {
        "symbol": "MRB.PA",
        "label": "Mr. Bricolage SA",
        "price": 3.35
    }, {
        "symbol": "MLMUL.PA",
        "label": "Mulann SA",
        "price": 0.05
    }, {
        "symbol": "MLMMC.PA",
        "label": "Multimicrocloud",
        "price": 0.197
    }, {
        "symbol": "MEURV.BR",
        "label": "Munchener Ruckversicherungs-Gesellschaft Aktiengesellschaft",
        "price": 268.2
    }, {
        "symbol": "GREV.PA",
        "label": "Musee Grevin SA",
        "price": 66.5
    }, {
        "symbol": "ALMBG.PA",
        "label": "MyBest Group S.p.A.",
        "price": 4.16
    }, {
        "symbol": "NANO.PA",
        "label": "Nanobiotix S.A.",
        "price": 8.935
    }, {
        "symbol": "KN.PA",
        "label": "Natixis S.A.",
        "price": 3.941
    }, {
        "symbol": "MLNAT.PA",
        "label": "Nature et Logis SA",
        "price": 10.5
    }, {
        "symbol": "NAVYA.PA",
        "label": "Navya SA",
        "price": 0.96
    }, {
        "symbol": "NEDAP.AS",
        "label": "N.V. Nederlandsche Apparatenfabriek Nedap",
        "price": 48.7
    }, {
        "symbol": "MLNEO.PA",
        "label": "Neocom Multimedia SA",
        "price": 3.96
    }, {
        "symbol": "NEOEN.PA",
        "label": "Neoen S.A.",
        "price": 31.1
    }, {
        "symbol": "ALNLF.PA",
        "label": "Neolife SA",
        "price": 0.157
    }, {
        "symbol": "ALNEV.PA",
        "label": "Neovacs S.A.",
        "price": 0.058
    }, {
        "symbol": "NRP.AS",
        "label": "NEPI Rockcastle plc",
        "price": 7.95
    }, {
        "symbol": "NTG.PA",
        "label": "Netgem SA",
        "price": 0.908
    }, {
        "symbol": "NEU.BR",
        "label": "Compagnie Financiere de Neufcour S.A.",
        "price": 13.5
    }, {
        "symbol": "NRO.PA",
        "label": "Neurones S.A.",
        "price": 20.6
    }, {
        "symbol": "NSE.AS",
        "label": "New Sources Energy NV",
        "price": 0.131
    }, {
        "symbol": "NEWAY.AS",
        "label": "Neways Electronics International N.V.",
        "price": 9.28
    }, {
        "symbol": "MLNEI.PA",
        "label": "News Invest",
        "price": 0.89
    }, {
        "symbol": "NEWT.BR",
        "label": "Newtree S.A.",
        "price": 1.52
    }, {
        "symbol": "NEX.PA",
        "label": "Nexans S.A.",
        "price": 46.12
    }, {
        "symbol": "NXI.PA",
        "label": "Nexity SA",
        "price": 44.72
    }, {
        "symbol": "ALNXT.PA",
        "label": "Nextedia S.A.",
        "price": 0.72
    }, {
        "symbol": "NEXTS.PA",
        "label": "NextStage - S.C.A.",
        "price": 95.0
    }, {
        "symbol": "NIBC.AS",
        "label": "NIBC Holding N.V.",
        "price": 7.76
    }, {
        "symbol": "COX.PA",
        "label": "Nicox S.A.",
        "price": 5.14
    }, {
        "symbol": "NN.AS",
        "label": "NN Group N.V.",
        "price": 32.89
    }, {
        "symbol": "NOKIA.PA",
        "label": "Nokia Corporation",
        "price": 3.733
    }, {
        "symbol": "NOS.LS",
        "label": "NOS, S.G.P.S., S.A.",
        "price": 4.952
    }, {
        "symbol": "NBA.LS",
        "label": "Novabase S.G.P.S., S.A.",
        "price": 2.67
    }, {
        "symbol": "ALNOV.PA",
        "label": "Novacyt S.A.",
        "price": 0.177
    }, {
        "symbol": "MLNOV.PA",
        "label": "Novatech Industries",
        "price": 5.1
    }, {
        "symbol": "NOVI.AS",
        "label": "Novisource N.V.",
        "price": 1.05
    }, {
        "symbol": "ALNOX.PA",
        "label": "NOXXON Pharma N.V.",
        "price": 0.59
    }, {
        "symbol": "NR21.PA",
        "label": "NR21 Societe Anonyme",
        "price": 0.92
    }, {
        "symbol": "NRG.PA",
        "label": "NRJ Group SA",
        "price": 6.46
    }, {
        "symbol": "ALNSC.PA",
        "label": "NSC Groupe SA",
        "price": 74.0
    }, {
        "symbol": "ALNSE.PA",
        "label": "NSE SA",
        "price": 10.7
    }, {
        "symbol": "NSI.AS",
        "label": "NSI N.V.",
        "price": 44.1
    }, {
        "symbol": "NYR.BR",
        "label": "Nyrstar NV",
        "price": 0.1793
    }, {
        "symbol": "MLOSA.PA",
        "label": "O Sorbet D'amour SA",
        "price": 3.48
    }, {
        "symbol": "ALODI.PA",
        "label": "O2i Societe Anonyme",
        "price": 1.205
    }, {
        "symbol": "ALOBR.PA",
        "label": "Ober SA",
        "price": 9.5
    }, {
        "symbol": "OCI.AS",
        "label": "OCI N.V.",
        "price": 18.145
    }, {
        "symbol": "MLOCT.PA",
        "label": "Octopus Robots SA",
        "price": 0.8
    }, {
        "symbol": "SBT.PA",
        "label": "Oeneo SA",
        "price": 13.66
    }, {
        "symbol": "OLG.PA",
        "label": "Olympique Lyonnais Groupe SA",
        "price": 3.05
    }, {
        "symbol": "MLOLM.PA",
        "label": "Olmix Societe Anonyme",
        "price": 10.4
    }, {
        "symbol": "ALONC.PA",
        "label": "Oncodesign Societe Anonyme",
        "price": 11.55
    }, {
        "symbol": "MLOEX.PA",
        "label": "One Experience SA",
        "price": 3.1
    }, {
        "symbol": "MLONL.PA",
        "label": "Onlineformapro S.A.",
        "price": 3.3
    }, {
        "symbol": "ONTEX.BR",
        "label": "Ontex Group NV",
        "price": 16.03
    }, {
        "symbol": "ONXEO.PA",
        "label": "Onxeo SA",
        "price": 0.6
    }, {
        "symbol": "ORA.PA",
        "label": "Orange S.A.",
        "price": 13.02
    }, {
        "symbol": "OBEL.BR",
        "label": "Orange Belgium S.A.",
        "price": 19.86
    }, {
        "symbol": "ORANW.AS",
        "label": "Oranjewoud N.V.",
        "price": 6.1
    }, {
        "symbol": "ORAP.PA",
        "label": "Orapi SA",
        "price": 3.7
    }, {
        "symbol": "KAZI.PA",
        "label": "Orchestra-Premaman S.A.",
        "price": 0.296
    }, {
        "symbol": "ORDI.AS",
        "label": "Ordina N.V.",
        "price": 2.015
    }, {
        "symbol": "ALORD.PA",
        "label": "Ordissimo SA",
        "price": 3.0
    }, {
        "symbol": "OREGE.PA",
        "label": "Orege Societe Anonyme",
        "price": 1.225
    }, {
        "symbol": "ORE.LS",
        "label": "Sociedade Comercial Orey Antunes, S.A.",
        "price": 0.095
    }, {
        "symbol": "ORP.PA",
        "label": "ORPEA Societe Anonyme",
        "price": 117.6
    }, {
        "symbol": "OSE.PA",
        "label": "OSE Immunotherapeutics SA",
        "price": 4.15
    }, {
        "symbol": "ALOSM.PA",
        "label": "Osmozis SA",
        "price": 8.3
    }, {
        "symbol": "ALOXA.PA",
        "label": "Oxatis SA",
        "price": 0.598
    }, {
        "symbol": "OXUR.BR",
        "label": "Oxurion NV",
        "price": 3.62
    }, {
        "symbol": "MLPAC.PA",
        "label": "Pacte Novation",
        "price": 3.8
    }, {
        "symbol": "PID.PA",
        "label": "Paragon ID SA",
        "price": 33.4
    }, {
        "symbol": "PAR.PA",
        "label": "Paref SA",
        "price": 87.0
    }, {
        "symbol": "MLPFX.PA",
        "label": "Parfex SA",
        "price": 10.6
    }, {
        "symbol": "PARRO.PA",
        "label": "Parrot S.A.",
        "price": 2.825
    }, {
        "symbol": "MLHOP.PA",
        "label": "Participation Industrielle Miniere Societe Anonyme",
        "price": 13.3
    }, {
        "symbol": "MLPRX.PA",
        "label": "Parx Plastics N.V.",
        "price": 2.4
    }, {
        "symbol": "PSAT.PA",
        "label": "Passat Societe Anonyme",
        "price": 5.18
    }, {
        "symbol": "PAT.PA",
        "label": "Patrimoine et Commerce SA",
        "price": 19.3
    }, {
        "symbol": "PAY.BR",
        "label": "Payton Planar Magnetics Ltd.",
        "price": 6.45
    }, {
        "symbol": "PCA.PA",
        "label": "PCAS SA",
        "price": 13.1
    }, {
        "symbol": "PEP.BR",
        "label": "PepsiCo, Inc.",
        "price": 105.0
    }, {
        "symbol": "RI.PA",
        "label": "Pernod Ricard SA",
        "price": 169.1
    }, {
        "symbol": "PERR.PA",
        "label": "Gerard Perrier Industrie S.A.",
        "price": 64.2
    }, {
        "symbol": "PSH.AS",
        "label": "Pershing Square Holdings, Ltd.",
        "price": 20.55
    }, {
        "symbol": "PHA.PA",
        "label": "Pharmagest Interactive SA",
        "price": 65.4
    }, {
        "symbol": "ALPHS.PA",
        "label": "Pharmasimple SA",
        "price": 5.6
    }, {
        "symbol": "PHARM.AS",
        "label": "Pharming Group N.V.",
        "price": 1.4335
    }, {
        "symbol": "ALPHA.PA",
        "label": "Pharnext SA",
        "price": 5.64
    }, {
        "symbol": "PHR.LS",
        "label": "Pharol, SGPS S.A.",
        "price": 0.1018
    }, {
        "symbol": "PHIA.AS",
        "label": "Koninklijke Philips N.V.",
        "price": 45.04
    }, {
        "symbol": "MLPHW.PA",
        "label": "Phone Web SA",
        "price": 1.15
    }, {
        "symbol": "MLPHO.PA",
        "label": "Photonike Capital SA",
        "price": 11.8
    }, {
        "symbol": "PIC.BR",
        "label": "Picanol NV",
        "price": 65.0
    }, {
        "symbol": "VAC.PA",
        "label": "Pierre & Vacances SA",
        "price": 19.76
    }, {
        "symbol": "ALPDX.PA",
        "label": "Piscines Desjoyaux SA",
        "price": 14.9
    }, {
        "symbol": "PIX.PA",
        "label": "Pixium Vision SA",
        "price": 1.1
    }, {
        "symbol": "MLPLC.PA",
        "label": "Placoplatre SA",
        "price": 456.0
    }, {
        "symbol": "ALPLA.PA",
        "label": "PlanetMedia SA",
        "price": 1.97
    }, {
        "symbol": "ALPAT.PA",
        "label": "Plant Advanced Technologies SA",
        "price": 19.6
    }, {
        "symbol": "PVL.PA",
        "label": "Plastiques du Val de Loire",
        "price": 6.76
    }, {
        "symbol": "POM.PA",
        "label": "Compagnie Plastic Omnium SA",
        "price": 25.4
    }, {
        "symbol": "PORF.AS",
        "label": "B.V. Delftsch Aardewerkfabriek 'De Porceleyne Fles Anno 1653'",
        "price": 9.85
    }, {
        "symbol": "PNL.AS",
        "label": "PostNL N.V.",
        "price": 1.813
    }, {
        "symbol": "ALPJT.PA",
        "label": "Poujoulat SA",
        "price": 25.4
    }, {
        "symbol": "ALPOU.PA",
        "label": "Poulaillon SA",
        "price": 5.5
    }, {
        "symbol": "POXEL.PA",
        "label": "Poxel SA",
        "price": 11.8
    }, {
        "symbol": "PREC.PA",
        "label": "Precia SA",
        "price": 202.0
    }, {
        "symbol": "ALPRE.PA",
        "label": "Predilife S.A.",
        "price": 7.95
    }, {
        "symbol": "ALPCI.PA",
        "label": "Primecity Investment Plc",
        "price": 4.66
    }, {
        "symbol": "ALPRI.PA",
        "label": "Prismaflex International, S.A.",
        "price": 7.0
    }, {
        "symbol": "PROAC.PA",
        "label": "Proactis SA",
        "price": 0.124
    }, {
        "symbol": "ALPRO.PA",
        "label": "Prodware",
        "price": 7.6
    }, {
        "symbol": "PWG.PA",
        "label": "Prodways Group SA",
        "price": 3.015
    }, {
        "symbol": "PROL.PA",
        "label": "Prologue S.A.",
        "price": 0.385
    }, {
        "symbol": "MLPRI.PA",
        "label": "Societe Nationale de Propriete d'Immeubles",
        "price": 13.8
    }, {
        "symbol": "MLPRO.PA",
        "label": "ProVenture Capital Inc.",
        "price": 3.0
    }, {
        "symbol": "PROX.BR",
        "label": "Proximus PLC",
        "price": 25.08
    }, {
        "symbol": "PSB.PA",
        "label": "PSB Industries",
        "price": 18.3
    }, {
        "symbol": "PUB.PA",
        "label": "Publicis Groupe S.A.",
        "price": 41.89
    }, {
        "symbol": "QRF.BR",
        "label": "Qrf Comm. VA",
        "price": 16.8
    }, {
        "symbol": "ALQGC.PA",
        "label": "Quantum Genomics Societe Anonyme",
        "price": 3.9
    }, {
        "symbol": "QFG.BR",
        "label": "Quest for Growth Belgium",
        "price": 6.08
    }, {
        "symbol": "ALQWA.PA",
        "label": "Qwamplify",
        "price": 5.45
    }, {
        "symbol": "MLRZE.LS",
        "label": "Raize Servicos de Gestao, S.A.",
        "price": 1.36
    }, {
        "symbol": "RAL.PA",
        "label": "Rallye SA",
        "price": 9.25
    }, {
        "symbol": "RAM.LS",
        "label": "Ramada Investimentos e Industria, S.A.",
        "price": 5.82
    }, {
        "symbol": "GDS.PA",
        "label": "Ramsay Generale de Sante SA",
        "price": 17.3
    }, {
        "symbol": "RAND.AS",
        "label": "Randstad N.V.",
        "price": 54.9
    }, {
        "symbol": "ML350.PA",
        "label": "Rapidopret SAS",
        "price": 0.23
    }, {
        "symbol": "REAL.BR",
        "label": "Realco S.A.",
        "price": 23.0
    }, {
        "symbol": "ALREA.PA",
        "label": "Realites",
        "price": 24.2
    }, {
        "symbol": "REC.BR",
        "label": "Recticel SA/NV",
        "price": 8.21
    }, {
        "symbol": "RX.PA",
        "label": "Recylex S.A.",
        "price": 3.25
    }, {
        "symbol": "RED.LS",
        "label": "Reditus, Sociedade Gestora de Participacoes Sociais, S.A.",
        "price": 0.0505
    }, {
        "symbol": "REI.BR",
        "label": "Reibel S.A.",
        "price": 0.33
    }, {
        "symbol": "REINA.AS",
        "label": "Reinet Investments S.C.A.",
        "price": 18.36
    }, {
        "symbol": "REN.AS",
        "label": "RELX PLC",
        "price": 23.34
    }, {
        "symbol": "RCO.PA",
        "label": "Remy Cointreau SA",
        "price": 115.0
    }, {
        "symbol": "RENE.LS",
        "label": "REN - Redes Energeticas Nacionais, SGPS, S.A.",
        "price": 2.77
    }, {
        "symbol": "RNO.PA",
        "label": "Renault SA",
        "price": 39.43
    }, {
        "symbol": "RES.BR",
        "label": "Resilux NV",
        "price": 146.0
    }, {
        "symbol": "RET.BR",
        "label": "Retail Estates N.V.",
        "price": 86.0
    }, {
        "symbol": "ALREW.PA",
        "label": "Reworld Media Societe Anonyme",
        "price": 2.67
    }, {
        "symbol": "RXL.PA",
        "label": "Rexel S.A.",
        "price": 11.7
    }, {
        "symbol": "RIB.PA",
        "label": "Riber S.A.",
        "price": 2.29
    }, {
        "symbol": "RBT.PA",
        "label": "Robertet SA",
        "price": 940.0
    }, {
        "symbol": "CBE.PA",
        "label": "Robertet SA",
        "price": 730.0
    }, {
        "symbol": "RBO.PA",
        "label": "Roche Bobois S.A.",
        "price": 18.9
    }, {
        "symbol": "ALROC.PA",
        "label": "RocTool S.A.",
        "price": 3.06
    }, {
        "symbol": "ROOD.AS",
        "label": "RoodMicrotec N.V.",
        "price": 0.261
    }, {
        "symbol": "ENGB.BR",
        "label": "Rosier SA",
        "price": 101.0
    }, {
        "symbol": "ROTH.PA",
        "label": "Rothschild & Co SCA",
        "price": 25.7
    }, {
        "symbol": "ROU.BR",
        "label": "Roularta Media Group NV",
        "price": 14.5
    }, {
        "symbol": "MLROU.PA",
        "label": "Rousselet Centrifugation S.A.",
        "price": 25.2
    }, {
        "symbol": "RDSA.AS",
        "label": "Royal Dutch Shell plc",
        "price": 26.215
    }, {
        "symbol": "RDSB.AS",
        "label": "Royal Dutch Shell plc",
        "price": 26.795
    }, {
        "symbol": "RUI.PA",
        "label": "Rubis",
        "price": 56.55
    }, {
        "symbol": "SCB.LS",
        "label": "Sporting Clube de Braga - Futebol, SAD",
        "price": 1.21
    }, {
        "symbol": "SK.PA",
        "label": "SEB SA",
        "price": 133.8
    }, {
        "symbol": "SAB.BR",
        "label": "Societe Anonyme Belge de Constructions Aeronautiques",
        "price": 20.8
    }, {
        "symbol": "ALSAF.PA",
        "label": "Safe Orthopaedics SA",
        "price": 2.37
    }, {
        "symbol": "SAF.PA",
        "label": "Safran SA",
        "price": 141.55
    }, {
        "symbol": "SGO.PA",
        "label": "Compagnie de Saint-Gobain S.A.",
        "price": 35.325
    }, {
        "symbol": "SABE.PA",
        "label": "Saint Jean Groupe Societe anonyme",
        "price": 23.4
    }, {
        "symbol": "SAMS.PA",
        "label": "Samse SA",
        "price": 162.5
    }, {
        "symbol": "SAN.PA",
        "label": "Sanofi",
        "price": 91.81
    }, {
        "symbol": "ALMER.PA",
        "label": "Sapmer SA",
        "price": 16.5
    }, {
        "symbol": "DIM.PA",
        "label": "Sartorius Stedim Biotech S.A.",
        "price": 162.0
    }, {
        "symbol": "SAVE.PA",
        "label": "Savencia SA",
        "price": 64.0
    }, {
        "symbol": "MLSDN.PA",
        "label": "La Savonnerie De Nyons",
        "price": 15.8
    }, {
        "symbol": "SBMO.AS",
        "label": "SBM Offshore N.V.",
        "price": 16.505
    }, {
        "symbol": "MLSBT.PA",
        "label": "Scientific Brain Training SA",
        "price": 5.45
    }, {
        "symbol": "CBSM.PA",
        "label": "Societe Centrale des Bois et des Scieries de la Manche S.A.",
        "price": 9.55
    }, {
        "symbol": "MLCMI.PA",
        "label": "Societe De Conseil En Externalisation Et En Marketing Internet Societe Anonyme",
        "price": 1.69
    }, {
        "symbol": "SCHD.BR",
        "label": "Scheerders van Kerchove's Verenigde fabrieken nv",
        "price": 460.0
    }, {
        "symbol": "SLB.PA",
        "label": "Schlumberger Limited",
        "price": 34.6
    }, {
        "symbol": "SU.PA",
        "label": "Schneider Electric S.E.",
        "price": 94.08
    }, {
        "symbol": "SCR.PA",
        "label": "SCOR SE",
        "price": 38.63
    }, {
        "symbol": "SCHP.PA",
        "label": "Seche Environnement SA",
        "price": 38.0
    }, {
        "symbol": "MLSEC.PA",
        "label": "Securinfor Societe anonyme",
        "price": 32.2
    }, {
        "symbol": "ALSEI.PA",
        "label": "Societa Editoriale Il Fatto S.p.A.",
        "price": 0.71
    }, {
        "symbol": "SLCO.PA",
        "label": "Selcodis SA",
        "price": 0.29
    }, {
        "symbol": "SELER.PA",
        "label": "Selectirente",
        "price": 88.5
    }, {
        "symbol": "SEM.LS",
        "label": "Semapa - Sociedade de Investimento e Gestao, SGPS, S.A.",
        "price": 13.24
    }, {
        "symbol": "ALSEN.PA",
        "label": "Sensorion SA",
        "price": 0.832
    }, {
        "symbol": "MLSEQ.PA",
        "label": "Sequa Petroleum N.V.",
        "price": 0.013
    }, {
        "symbol": "SEQUA.BR",
        "label": "Sequana Medical NV",
        "price": 7.02
    }, {
        "symbol": "SEFER.PA",
        "label": "SergeFerrari Group SA",
        "price": 6.3
    }, {
        "symbol": "ALSER.PA",
        "label": "Serma Group Societe anonyme",
        "price": 260.0
    }, {
        "symbol": "SESG.PA",
        "label": "SES S.A.",
        "price": 12.875
    }, {
        "symbol": "SESL.PA",
        "label": "SES-imagotag Societe Anonyme",
        "price": 35.2
    }, {
        "symbol": "SRP.PA",
        "label": "SRP Groupe S.A.",
        "price": 1.31
    }, {
        "symbol": "SHUR.BR",
        "label": "Shurgard Self Storage S.A.",
        "price": 34.25
    }, {
        "symbol": "ALSIP.PA",
        "label": "SI Participations S.C.A, SCR",
        "price": 8.9
    }, {
        "symbol": "ALBFR.PA",
        "label": "Sidetrade SA",
        "price": 64.4
    }, {
        "symbol": "SIA.BR",
        "label": "Siemens Aktiengesellschaft",
        "price": 117.34
    }, {
        "symbol": "SIFG.AS",
        "label": "Sif Holding N.V.",
        "price": 12.64
    }, {
        "symbol": "GIRO.PA",
        "label": "Signaux Girod S.A.",
        "price": 11.6
    }, {
        "symbol": "LIGHT.AS",
        "label": "Signify N.V.",
        "price": 29.03
    }, {
        "symbol": "SII.PA",
        "label": "SII (Societe pour l'Informatique Industrielle) Societe Anonyme",
        "price": 28.2
    }, {
        "symbol": "MLSIL.PA",
        "label": "Sejours Internat. Linguistique Culture",
        "price": 0.484
    }, {
        "symbol": "MLARI.PA",
        "label": "Silkan RT SA",
        "price": 0.925
    }, {
        "symbol": "MLMAT.PA",
        "label": "Simat",
        "price": 2.2
    }, {
        "symbol": "MLSIM.PA",
        "label": "Simo International Societe Anonyme",
        "price": 0.4
    }, {
        "symbol": "SIOE.BR",
        "label": "Sioen Industries NV",
        "price": 22.5
    }, {
        "symbol": "SIP.BR",
        "label": "Sipef NV",
        "price": 55.3
    }, {
        "symbol": "SLIGR.AS",
        "label": "Sligro Food Group N.V.",
        "price": 24.8
    }, {
        "symbol": "MLSML.PA",
        "label": "Smalto Holding SA",
        "price": 0.0475
    }, {
        "symbol": "SMAR.BR",
        "label": "Smartphoto Group NV",
        "price": 16.0
    }, {
        "symbol": "SMCP.PA",
        "label": "SMCP S.A.",
        "price": 9.14
    }, {
        "symbol": "SMTPC.PA",
        "label": "Societe Marseillaise du Tunnel Prado Carenage",
        "price": 21.4
    }, {
        "symbol": "SNOW.AS",
        "label": "SnowWorld N.V.",
        "price": 15.4
    }, {
        "symbol": "SFCA.PA",
        "label": "Societe Francaise de Casinos Societe Anonyme",
        "price": 1.6
    }, {
        "symbol": "GLE.PA",
        "label": "Societe Generale Societe anonyme",
        "price": 30.525
    }, {
        "symbol": "SW.PA",
        "label": "Sodexo S.A.",
        "price": 100.4
    }, {
        "symbol": "SOA.PA",
        "label": "Sodifrance SA",
        "price": 16.9
    }, {
        "symbol": "SEC.PA",
        "label": "Soditech SA",
        "price": 0.83
    }, {
        "symbol": "SFBS.PA",
        "label": "Sofibus Patrimoine S.A.",
        "price": 220.0
    }, {
        "symbol": "SOF.BR",
        "label": "Sofina Societe Anonyme",
        "price": 206.5
    }, {
        "symbol": "SOFR.PA",
        "label": "Societe Francaise de Gestion et d'Investissement",
        "price": 1610.0
    }, {
        "symbol": "SOFT.BR",
        "label": "Softimat S.A.",
        "price": 4.36
    }, {
        "symbol": "SOG.PA",
        "label": "Sogeclair SA",
        "price": 31.0
    }, {
        "symbol": "SOI.PA",
        "label": "Soitec S.A.",
        "price": 99.7
    }, {
        "symbol": "LOCAL.PA",
        "label": "Solocal Group S.A.",
        "price": 0.5095
    }, {
        "symbol": "ALS30.PA",
        "label": "Solutions 30 S.E.",
        "price": 10.7
    }, {
        "symbol": "SOLV.BR",
        "label": "Solvac S.A.",
        "price": 118.0
    }, {
        "symbol": "SOLB.BR",
        "label": "Solvay SA",
        "price": 94.76
    }, {
        "symbol": "SO.PA",
        "label": "Somfy SA",
        "price": 91.0
    }, {
        "symbol": "SON.LS",
        "label": "Sonae, SGPS, S.A.",
        "price": 0.881
    }, {
        "symbol": "SONC.LS",
        "label": "Sonae Capital, SGPS, SA",
        "price": 0.81
    }, {
        "symbol": "SONI.LS",
        "label": "Sonae Industria, S.G.P.S., S.A.",
        "price": 0.902
    }, {
        "symbol": "SNC.LS",
        "label": "Sonaecom, SGPS, S.A.",
        "price": 2.05
    }, {
        "symbol": "SNG.LS",
        "label": "Sonagi, S.G.P.S., S.A.",
        "price": 3.12
    }, {
        "symbol": "SOP.PA",
        "label": "Sopra Steria Group SA",
        "price": 147.9
    }, {
        "symbol": "SPA.BR",
        "label": "Societe de Services, de Participations, de Direction et d''Elaboration",
        "price": 202.0
    }, {
        "symbol": "MLSRP.PA",
        "label": "Speed Rabbit Pizza SA",
        "price": 2.5
    }, {
        "symbol": "SPIE.PA",
        "label": "SPIE SA",
        "price": 17.99
    }, {
        "symbol": "ALSGD.PA",
        "label": "SpineGuard SA",
        "price": 0.271
    }, {
        "symbol": "ALSPW.PA",
        "label": "Spineway Societe Anonyme",
        "price": 0.0074
    }, {
        "symbol": "SPI.PA",
        "label": "Spir Communication",
        "price": 4.5
    }, {
        "symbol": "SCP.LS",
        "label": "Sporting Clube de Portugal - Futebol, SAD",
        "price": 0.78
    }, {
        "symbol": "SQI.PA",
        "label": "SQLI SA",
        "price": 22.9
    }, {
        "symbol": "DPT.PA",
        "label": "S.T. Dupont S.A.",
        "price": 0.117
    }, {
        "symbol": "STF.PA",
        "label": "STEF S.A.",
        "price": 82.8
    }, {
        "symbol": "STNT.PA",
        "label": "STENTYS S.A.",
        "price": 0.46
    }, {
        "symbol": "STRN.AS",
        "label": "Stern Groep N.V.",
        "price": 14.1
    }, {
        "symbol": "STM.PA",
        "label": "STMicroelectronics N.V.",
        "price": 25.36
    }, {
        "symbol": "ALSAS.PA",
        "label": "Stradim Espace Finances SA",
        "price": 7.0
    }, {
        "symbol": "ALSTW.PA",
        "label": "Streamwide S.A.",
        "price": 10.8
    }, {
        "symbol": "MLSTR.PA",
        "label": "Streit Mecanique SA",
        "price": 12.7
    }, {
        "symbol": "SUCR.BR",
        "label": "Sucraf NV",
        "price": 0.5801
    }, {
        "symbol": "SEV.PA",
        "label": "Suez SA",
        "price": 14.545
    }, {
        "symbol": "MLSUM.PA",
        "label": "Sumo Resources Plc",
        "price": 0.019
    }, {
        "symbol": "SSI.PA",
        "label": "SuperSonic Imagine SA",
        "price": 1.444
    }, {
        "symbol": "SWP.PA",
        "label": "Sword Group S.E.",
        "price": 34.0
    }, {
        "symbol": "SDG.PA",
        "label": "Synergie SE",
        "price": 29.7
    }, {
        "symbol": "TKWY.AS",
        "label": "Takeaway.com N.V.",
        "price": 85.8
    }, {
        "symbol": "TKTT.PA",
        "label": "Tarkett S.A.",
        "price": 13.6
    }, {
        "symbol": "TAYN.PA",
        "label": "Societe de Tayninh SA",
        "price": 1.2
    }, {
        "symbol": "MLTEA.PA",
        "label": "Team Societe anonyme",
        "price": 326.0
    }, {
        "symbol": "TCH.PA",
        "label": "Technicolor SA",
        "price": 0.6745
    }, {
        "symbol": "FTI.PA",
        "label": "TechnipFMC plc",
        "price": 17.915
    }, {
        "symbol": "TDSA.LS",
        "label": "Teixeira Duarte, S.A.",
        "price": 0.159
    }, {
        "symbol": "TIT.BR",
        "label": "Telecom Italia S.p.A.",
        "price": 0.51
    }, {
        "symbol": "TFA.BR",
        "label": "Telefonica, S.A.",
        "price": 6.251
    }, {
        "symbol": "TNET.BR",
        "label": "Telenet Group Holding NV",
        "price": 40.24
    }, {
        "symbol": "TEP.PA",
        "label": "Teleperformance SE",
        "price": 226.2
    }, {
        "symbol": "TVRB.PA",
        "label": "Televerbier SA",
        "price": 52.0
    }, {
        "symbol": "MLVST.PA",
        "label": "Televista SA",
        "price": 5.75
    }, {
        "symbol": "TERB.BR",
        "label": "Ter Beke NV",
        "price": 118.0
    }, {
        "symbol": "TESB.BR",
        "label": "Tessenderlo Group NV",
        "price": 30.65
    }, {
        "symbol": "TES.PA",
        "label": "Tessi SA",
        "price": 129.5
    }, {
        "symbol": "TEXF.BR",
        "label": "Texaf S.A.",
        "price": 39.6
    }, {
        "symbol": "TFI.PA",
        "label": "Television Francaise 1 Societe anonyme",
        "price": 7.295
    }, {
        "symbol": "TFF.PA",
        "label": "TFF Group",
        "price": 34.9
    }, {
        "symbol": "HO.PA",
        "label": "Thales S.A.",
        "price": 99.22
    }, {
        "symbol": "ALTBG.PA",
        "label": "The Blockchain Group",
        "price": 0.321
    }, {
        "symbol": "NVG.LS",
        "label": "The Navigator Company, S.A.",
        "price": 3.358
    }, {
        "symbol": "ALTHE.PA",
        "label": "Theraclion SA",
        "price": 1.465
    }, {
        "symbol": "ALTER.PA",
        "label": "Theradiag SA",
        "price": 1.415
    }, {
        "symbol": "ALTHX.PA",
        "label": "Theranexus Societe Anonyme",
        "price": 2.5
    }, {
        "symbol": "THEP.PA",
        "label": "Thermador Groupe SA",
        "price": 54.4
    }, {
        "symbol": "TBIRD.AS",
        "label": "Thunderbird Resorts, Inc.",
        "price": 0.092
    }, {
        "symbol": "TIE.AS",
        "label": "TIE Kinetix N.V.",
        "price": 7.75
    }, {
        "symbol": "TKO.PA",
        "label": "Tikehau Capital",
        "price": 22.8
    }, {
        "symbol": "TINC.BR",
        "label": "TINC Comm. VA",
        "price": 13.6
    }, {
        "symbol": "TIPI.PA",
        "label": "Tipiak Societe Anonyme",
        "price": 66.0
    }, {
        "symbol": "TISN.BR",
        "label": "Tiscali Spa",
        "price": 0.011
    }, {
        "symbol": "TITC.BR",
        "label": "Titan Cement International S.A.",
        "price": 18.9
    }, {
        "symbol": "TVLY.PA",
        "label": "Tivoly S.A.",
        "price": 23.2
    }, {
        "symbol": "TWEKA.AS",
        "label": "TKH Group N.V.",
        "price": 50.45
    }, {
        "symbol": "TOM2.AS",
        "label": "TomTom N.V.",
        "price": 10.164
    }, {
        "symbol": "MLTBM.PA",
        "label": "Les Toques Blanches du Monde Societe Anonyme",
        "price": 4.2
    }, {
        "symbol": "FP.PA",
        "label": "TOTAL S.A.",
        "price": 48.55
    }, {
        "symbol": "EC.PA",
        "label": "Total Gabon",
        "price": 133.5
    }, {
        "symbol": "TOUP.PA",
        "label": "Touax SCA",
        "price": 5.16
    }, {
        "symbol": "TOU.PA",
        "label": "Toupargel Groupe SA",
        "price": 0.64
    }, {
        "symbol": "EIFF.PA",
        "label": "Societe de la Tour Eiffel",
        "price": 39.5
    }, {
        "symbol": "MLABO.PA",
        "label": "Toutabo SA",
        "price": 1.1
    }, {
        "symbol": "SCT.LS",
        "label": "Toyota Caetano Portugal, S.A.",
        "price": 3.44
    }, {
        "symbol": "MLTRA.PA",
        "label": "Compagnie des Tramways de Rouen",
        "price": 2760.0
    }, {
        "symbol": "TNG.PA",
        "label": "Transgene SA",
        "price": 1.55
    }, {
        "symbol": "ALTRA.PA",
        "label": "Traqueur SA",
        "price": 1.7
    }, {
        "symbol": "TRI.PA",
        "label": "Trigano S.A.",
        "price": 89.95
    }, {
        "symbol": "ALTRI.PA",
        "label": "Trilogiq S.A.",
        "price": 4.9
    }, {
        "symbol": "MLTRO.PA",
        "label": "TROC DE L'ILE SA",
        "price": 4.76
    }, {
        "symbol": "MLTRC.PA",
        "label": "Laboratoire les 3 Chenes",
        "price": 9.5
    }, {
        "symbol": "ALTRO.PA",
        "label": "Tronic's Microsystems SA",
        "price": 6.1
    }, {
        "symbol": "ALTTI.PA",
        "label": "Travel Technology Interactive",
        "price": 2.5
    }, {
        "symbol": "TUB.BR",
        "label": "Financiere de Tubize SA",
        "price": 70.8
    }, {
        "symbol": "ALTXC.PA",
        "label": "TXCOM Societe Anonyme",
        "price": 8.0
    }, {
        "symbol": "UNI.BR",
        "label": "U&I Learning NV",
        "price": 1.75
    }, {
        "symbol": "ALU10.PA",
        "label": "U10 SA",
        "price": 1.94
    }, {
        "symbol": "UBI.PA",
        "label": "Ubisoft Entertainment SA",
        "price": 64.02
    }, {
        "symbol": "ALUCR.PA",
        "label": "Ucar SA",
        "price": 14.4
    }, {
        "symbol": "PNSB.BR",
        "label": "Personalized Nursing Services Societe Anonyme",
        "price": 1.0
    }, {
        "symbol": "UCB.BR",
        "label": "UCB SA",
        "price": 79.92
    }, {
        "symbol": "MLUMG.PA",
        "label": "Umalis Group",
        "price": 3.4
    }, {
        "symbol": "ALUMS.PA",
        "label": "Umanis SA",
        "price": 5.6
    }, {
        "symbol": "UMI.BR",
        "label": "Umicore SA",
        "price": 43.68
    }, {
        "symbol": "MLUTP.PA",
        "label": "Un Toit Pour Toi Societe anonyme",
        "price": 9.0
    }, {
        "symbol": "MLUMH.PA",
        "label": "Union Metallurgique de la Haute-Seine S.A.",
        "price": 45.0
    }, {
        "symbol": "URW.AS",
        "label": "Unibail-Rodamco-Westfield",
        "price": 132.75
    }, {
        "symbol": "UNBL.PA",
        "label": "Unibel S.A.",
        "price": 580.0
    }, {
        "symbol": "UNA.AS",
        "label": "The Unilever Group",
        "price": 50.98
    }, {
        "symbol": "UFF.PA",
        "label": "Union Financiere de France Banque SA",
        "price": 20.3
    }, {
        "symbol": "FPG.PA",
        "label": "Union Technologies Informatique Group S.A.",
        "price": 0.528
    }, {
        "symbol": "ALUNT.PA",
        "label": "Uniti S.A",
        "price": 2.32
    }, {
        "symbol": "ALUPG.PA",
        "label": "UPERGY Societe Anonyme",
        "price": 5.1
    }, {
        "symbol": "ALUVI.PA",
        "label": "UV GERMI, Societe anonyme",
        "price": 4.14
    }, {
        "symbol": "VLK.AS",
        "label": "Van Lanschot Kempen N.V.",
        "price": 19.58
    }, {
        "symbol": "VAF.LS",
        "label": "Vista Alegre Atlantis, SGPS, S.A.",
        "price": 1.42
    }, {
        "symbol": "ALVAL.PA",
        "label": "Valbiotis SA",
        "price": 2.51
    }, {
        "symbol": "FR.PA",
        "label": "Valeo SA",
        "price": 30.67
    }, {
        "symbol": "VK.PA",
        "label": "Vallourec S.A.",
        "price": 2.535
    }, {
        "symbol": "VLA.PA",
        "label": "Valneva SE",
        "price": 2.89
    }, {
        "symbol": "VLAP.PA",
        "label": "Valneva SE",
        "price": 0.121
    }, {
        "symbol": "VALUE.AS",
        "label": "Value8 NV",
        "price": 5.58
    }, {
        "symbol": "PREVA.AS",
        "label": "Value8 N.V.",
        "price": 28.6
    }, {
        "symbol": "VAN.BR",
        "label": "Van de Velde NV",
        "price": 27.7
    }, {
        "symbol": "VASTN.AS",
        "label": "Vastned Retail N.V.",
        "price": 24.95
    }, {
        "symbol": "VASTB.BR",
        "label": "Vastned Retail Belgium SA",
        "price": 45.9
    }, {
        "symbol": "ALVEL.PA",
        "label": "Velcan Holdings S.A.",
        "price": 7.06
    }, {
        "symbol": "ALVU.PA",
        "label": "Vente-Unique.com SA",
        "price": 4.69
    }, {
        "symbol": "VIE.PA",
        "label": "Veolia Environnement S.A.",
        "price": 25.66
    }, {
        "symbol": "VEON.AS",
        "label": "VEON Ltd.",
        "price": 2.4
    }, {
        "symbol": "ALVER.PA",
        "label": "Vergnet SA",
        "price": 0.277
    }, {
        "symbol": "VMX.PA",
        "label": "Verimatrix Societe anonyme",
        "price": 1.988
    }, {
        "symbol": "VRNL.PA",
        "label": "Verneuil Finance Societe anonyme",
        "price": 1.68
    }, {
        "symbol": "MLVER.PA",
        "label": "Verney-Carron S.A.",
        "price": 9.8
    }, {
        "symbol": "VETO.PA",
        "label": "Vetoquinol SA",
        "price": 61.8
    }, {
        "symbol": "VGP.BR",
        "label": "VGP NV",
        "price": 90.2
    }, {
        "symbol": "VCT.PA",
        "label": "Vicat SA",
        "price": 39.6
    }, {
        "symbol": "VDLO.PA",
        "label": "Videlio SA",
        "price": 1.93
    }, {
        "symbol": "VIL.PA",
        "label": "VIEL & Cie, societe anonyme",
        "price": 4.82
    }, {
        "symbol": "RIN.PA",
        "label": "Vilmorin & Cie SA",
        "price": 46.9
    }, {
        "symbol": "DG.PA",
        "label": "VINCI SA",
        "price": 102.3
    }, {
        "symbol": "VIO.BR",
        "label": "Viohalco S.A.",
        "price": 3.77
    }, {
        "symbol": "VIRP.PA",
        "label": "Virbac SA",
        "price": 223.0
    }, {
        "symbol": "ALVIV.PA",
        "label": "Visiativ SA",
        "price": 28.45
    }, {
        "symbol": "MLVIS.PA",
        "label": "Visio Nerf",
        "price": 1.15
    }, {
        "symbol": "SDT.PA",
        "label": "Visiodent S.A.",
        "price": 2.5
    }, {
        "symbol": "ALVMG.PA",
        "label": "Visiomed Group SA",
        "price": 0.0293
    }, {
        "symbol": "VIV.PA",
        "label": "Vivendi SA",
        "price": 25.07
    }, {
        "symbol": "ALVGO.PA",
        "label": "VOGO Societe anonyme",
        "price": 10.3
    }, {
        "symbol": "KVW.AS",
        "label": "Royal VolkerWessels nv",
        "price": 21.9
    }, {
        "symbol": "VWA.BR",
        "label": "Volkswagen AG",
        "price": 181.25
    }, {
        "symbol": "VWAP.BR",
        "label": "Volkswagen AG",
        "price": 177.0
    }, {
        "symbol": "VLTSA.PA",
        "label": "Voltalia SA",
        "price": 12.95
    }, {
        "symbol": "VTX.PA",
        "label": "Voluntis S.A.",
        "price": 2.34
    }, {
        "symbol": "VPK.AS",
        "label": "Koninklijke Vopak N.V.",
        "price": 49.77
    }, {
        "symbol": "ALVDM.PA",
        "label": "Voyageurs du Monde SA",
        "price": 118.0
    }, {
        "symbol": "VRAP.PA",
        "label": "Vranken-Pommery Monopole - Societe Anonyme",
        "price": 20.0
    }, {
        "symbol": "ALLIX.PA",
        "label": "WALLIX GROUP SA",
        "price": 13.08
    }, {
        "symbol": "WEB.BR",
        "label": "Warehouses Estates Belgium SCA",
        "price": 60.5
    }, {
        "symbol": "WAVE.PA",
        "label": "Wavestone SA",
        "price": 26.6
    }, {
        "symbol": "WDP.BR",
        "label": "Warehouses De Pauw",
        "price": 24.22
    }, {
        "symbol": "ALWEC.PA",
        "label": "We.Connect SA",
        "price": 13.25
    }, {
        "symbol": "MLWEA.PA",
        "label": "Weaccess Group, Societe Anonyme",
        "price": 2.04
    }, {
        "symbol": "ALWEB.PA",
        "label": "Weborama SA",
        "price": 5.35
    }, {
        "symbol": "ALWED.PA",
        "label": "Wedia SA",
        "price": 27.2
    }, {
        "symbol": "MF.PA",
        "label": "Wendel",
        "price": 122.4
    }, {
        "symbol": "WHA.AS",
        "label": "Wereldhave N.V.",
        "price": 18.29
    }, {
        "symbol": "WEHB.BR",
        "label": "Wereldhave Belgium",
        "price": 88.0
    }, {
        "symbol": "MLWEY.PA",
        "label": "Weya SA",
        "price": 3.8
    }, {
        "symbol": "MLWRC.PA",
        "label": "Whiteni Rcajal Socimi, S.A.",
        "price": 3.0
    }, {
        "symbol": "ALWIT.PA",
        "label": "Witbe SA",
        "price": 2.63
    }, {
        "symbol": "WOLE.BR",
        "label": "Woluwe Extension SA",
        "price": 34.0
    }, {
        "symbol": "WOLS.BR",
        "label": "Woluwe Shopping Center",
        "price": 43.2
    }, {
        "symbol": "WKL.AS",
        "label": "Wolters Kluwer N.V.",
        "price": 67.06
    }, {
        "symbol": "WLN.PA",
        "label": "Worldline S.A.",
        "price": 65.5
    }, {
        "symbol": "XFAB.PA",
        "label": "X-FAB Silicon Foundries SE",
        "price": 4.72
    }, {
        "symbol": "XIL.PA",
        "label": "Xilam Animation",
        "price": 50.6
    }, {
        "symbol": "XIOR.BR",
        "label": "Xior Student Housing NV",
        "price": 53.0
    }, {
        "symbol": "XPO.PA",
        "label": "XPO Logistics Europe SA",
        "price": 260.0
    }, {
        "symbol": "YATRA.AS",
        "label": "Yatra Capital Limited",
        "price": 3.8
    }, {
        "symbol": "MAGIS.PA",
        "label": "Ymagis Societe Anonyme",
        "price": 1.945
    }, {
        "symbol": "MLZAM.PA",
        "label": "ZCCM Investments Holdings Plc",
        "price": 1.14
    }, {
        "symbol": "ZENT.BR",
        "label": "Zenitel NV",
        "price": 15.8
    }, {
        "symbol": "ZEN.BR",
        "label": "Immo-Zenobe Gramme SA",
        "price": 195.0
    }, {
        "symbol": "VNP.TO",
        "label": "5N Plus Inc.",
        "price": 2.33
    }, {
        "symbol": "AW-UN.TO",
        "label": "A&W Revenue Royalties Income Fund",
        "price": 38.15
    }, {
        "symbol": "FAP.TO",
        "label": "Aberdeen Asia-Pacific Income Investment Company Limited",
        "price": 3.59
    }, {
        "symbol": "AAB.TO",
        "label": "Aberdeen International Inc.",
        "price": 0.035
    }, {
        "symbol": "ABT.TO",
        "label": "Absolute Software Corporation",
        "price": 9.05
    }, {
        "symbol": "ADN.TO",
        "label": "Acadian Timber Corp.",
        "price": 16.61
    }, {
        "symbol": "ACD.TO",
        "label": "Accord Financial Corp.",
        "price": 9.82
    }, {
        "symbol": "ASP.TO",
        "label": "Acerus Pharmaceuticals Corporation",
        "price": 0.075
    }, {
        "symbol": "DRX.TO",
        "label": "ADF Group Inc.",
        "price": 1.32
    }, {
        "symbol": "AAV.TO",
        "label": "Advantage Oil & Gas Ltd.",
        "price": 2.33
    }, {
        "symbol": "ARE.TO",
        "label": "Aecon Group Inc.",
        "price": 17.74
    }, {
        "symbol": "AOI.TO",
        "label": "Africa Oil Corp.",
        "price": 1.58
    }, {
        "symbol": "AFN.TO",
        "label": "Ag Growth International Inc.",
        "price": 44.51
    }, {
        "symbol": "AGF-B.TO",
        "label": "AGF Management Limited",
        "price": 6.71
    }, {
        "symbol": "AJX.TO",
        "label": "AgJunction Inc.",
        "price": 0.325
    }, {
        "symbol": "AEM.TO",
        "label": "Agnico Eagle Mines Limited",
        "price": 78.88
    }, {
        "symbol": "AIM.TO",
        "label": "Aimia Inc.",
        "price": 3.74
    }, {
        "symbol": "AC.TO",
        "label": "Air Canada",
        "price": 51.08
    }, {
        "symbol": "BOS.TO",
        "label": "AirBoss of America Corp.",
        "price": 9.28
    }, {
        "symbol": "AKT-A.TO",
        "label": "AKITA Drilling Ltd.",
        "price": 1.0
    }, {
        "symbol": "ASR.TO",
        "label": "Alacer Gold Corp.",
        "price": 6.62
    }, {
        "symbol": "AGI.TO",
        "label": "Alamos Gold Inc.",
        "price": 7.35
    }, {
        "symbol": "AD.TO",
        "label": "Alaris Royalty Corp.",
        "price": 22.28
    }, {
        "symbol": "ALC.TO",
        "label": "Algoma Central Corporation",
        "price": 12.66
    }, {
        "symbol": "AQN.TO",
        "label": "Algonquin Power & Utilities Corp.",
        "price": 19.33
    }, {
        "symbol": "ATD-B.TO",
        "label": "Alimentation Couche-Tard Inc.",
        "price": 44.43
    }, {
        "symbol": "ALB.TO",
        "label": "Allbanc Split Corp. II",
        "price": 28.09
    }, {
        "symbol": "AP-UN.TO",
        "label": "Allied Properties Real Estate Investment Trust",
        "price": 54.46
    }, {
        "symbol": "AMM.TO",
        "label": "Almaden Minerals Ltd.",
        "price": 0.75
    }, {
        "symbol": "ALA.TO",
        "label": "AltaGas Ltd.",
        "price": 20.0
    }, {
        "symbol": "ALS.TO",
        "label": "Altius Minerals Corporation",
        "price": 11.57
    }, {
        "symbol": "AIF.TO",
        "label": "Altus Group Limited",
        "price": 42.08
    }, {
        "symbol": "HOT-UN.TO",
        "label": "American Hotel Income Properties REIT LP",
        "price": 7.38
    }, {
        "symbol": "USA.TO",
        "label": "Americas Gold and Silver Corporation",
        "price": 3.83
    }, {
        "symbol": "ARG.TO",
        "label": "Amerigo Resources Ltd.",
        "price": 0.56
    }, {
        "symbol": "ANX.TO",
        "label": "Anaconda Mining Inc.",
        "price": 0.225
    }, {
        "symbol": "ADW-A.TO",
        "label": "Andrew Peller Limited",
        "price": 11.36
    }, {
        "symbol": "APY.TO",
        "label": "Anglo Pacific Group plc",
        "price": 3.13
    }, {
        "symbol": "APS.TO",
        "label": "Aptose Biosciences Inc.",
        "price": 7.68
    }, {
        "symbol": "AQA.TO",
        "label": "Aquila Resources Inc.",
        "price": 0.155
    }, {
        "symbol": "ARX.TO",
        "label": "ARC Resources Ltd.",
        "price": 7.72
    }, {
        "symbol": "AR.TO",
        "label": "Argonaut Gold Inc.",
        "price": 1.68
    }, {
        "symbol": "ATZ.TO",
        "label": "Aritzia Inc.",
        "price": 24.71
    }, {
        "symbol": "AX-UN.TO",
        "label": "Artis Real Estate Investment Trust",
        "price": 12.0
    }, {
        "symbol": "AKG.TO",
        "label": "Asanko Gold Inc.",
        "price": 1.14
    }, {
        "symbol": "AYM.TO",
        "label": "Atalaya Mining Plc",
        "price": 3.6
    }, {
        "symbol": "ACO-X.TO",
        "label": "ATCO Ltd.",
        "price": 51.43
    }, {
        "symbol": "ATH.TO",
        "label": "Athabasca Oil Corporation",
        "price": 0.49
    }, {
        "symbol": "ATP.TO",
        "label": "Atlantic Power Corporation",
        "price": 3.17
    }, {
        "symbol": "AI.TO",
        "label": "Atrium Mortgage Investment Corporation",
        "price": 14.75
    }, {
        "symbol": "ATA.TO",
        "label": "ATS Automation Tooling Systems Inc.",
        "price": 21.63
    }, {
        "symbol": "ORA.TO",
        "label": "Aura Minerals Inc.",
        "price": 26.09
    }, {
        "symbol": "AUP.TO",
        "label": "Aurinia Pharmaceuticals Inc.",
        "price": 26.25
    }, {
        "symbol": "AUG.TO",
        "label": "Auryn Resources Inc.",
        "price": 1.8
    }, {
        "symbol": "HRR-UN.TO",
        "label": "Australian REIT Income Fund",
        "price": 12.84
    }, {
        "symbol": "ACQ.TO",
        "label": "AutoCanada Inc.",
        "price": 11.1
    }, {
        "symbol": "APR-UN.TO",
        "label": "Automotive Properties Real Estate Investment Trust",
        "price": 12.76
    }, {
        "symbol": "AVL.TO",
        "label": "Avalon Advanced Materials Inc.",
        "price": 0.055
    }, {
        "symbol": "AVP.TO",
        "label": "Avcorp Industries Inc.",
        "price": 0.06
    }, {
        "symbol": "AZZ.TO",
        "label": "Azarga Uranium Corp.",
        "price": 0.205
    }, {
        "symbol": "BTO.TO",
        "label": "B2Gold Corp.",
        "price": 5.38
    }, {
        "symbol": "BAD.TO",
        "label": "Badger Daylighting Ltd.",
        "price": 35.85
    }, {
        "symbol": "BAR.TO",
        "label": "Balmoral Resources Ltd",
        "price": 0.34
    }, {
        "symbol": "BMO.TO",
        "label": "Bank of Montreal",
        "price": 103.8
    }, {
        "symbol": "BNS.TO",
        "label": "The Bank of Nova Scotia",
        "price": 72.48
    }, {
        "symbol": "ABX.TO",
        "label": "Barrick Gold Corporation",
        "price": 23.46
    }, {
        "symbol": "BYL.TO",
        "label": "Baylin Technologies Inc.",
        "price": 2.04
    }, {
        "symbol": "BTE.TO",
        "label": "Baytex Energy Corp.",
        "price": 1.77
    }, {
        "symbol": "BCE.TO",
        "label": "BCE Inc.",
        "price": 62.1
    }, {
        "symbol": "BEK-B.TO",
        "label": "The Becker Milk Company Limited",
        "price": 13.8
    }, {
        "symbol": "BLU.TO",
        "label": "BELLUS Health Inc.",
        "price": 11.49
    }, {
        "symbol": "BSX.TO",
        "label": "Belo Sun Mining Corp",
        "price": 0.52
    }, {
        "symbol": "BNG.TO",
        "label": "Bengal Energy Ltd.",
        "price": 0.075
    }, {
        "symbol": "BR.TO",
        "label": "Big Rock Brewery Inc.",
        "price": 4.54
    }, {
        "symbol": "BIR.TO",
        "label": "Birchcliff Energy Ltd.",
        "price": 2.11
    }, {
        "symbol": "BDT.TO",
        "label": "Bird Construction Inc.",
        "price": 7.1
    }, {
        "symbol": "BDI.TO",
        "label": "Black Diamond Group Limited",
        "price": 2.01
    }, {
        "symbol": "BKI.TO",
        "label": "Black Iron Inc.",
        "price": 0.085
    }, {
        "symbol": "BB.TO",
        "label": "BlackBerry Limited",
        "price": 8.78
    }, {
        "symbol": "BLB-UN.TO",
        "label": "Bloom Select Income Fund",
        "price": 9.88
    }, {
        "symbol": "BUA-UN.TO",
        "label": "Bloom U.S. Income & Growth Fund",
        "price": 7.94
    }, {
        "symbol": "RBN-UN.TO",
        "label": "Blue Ribbon Income Fund",
        "price": 8.54
    }, {
        "symbol": "ZAG.TO",
        "label": "BMO Aggregate Bond Index ETF",
        "price": 15.98
    }, {
        "symbol": "ZDV.TO",
        "label": "BMO Canadian Dividend ETF",
        "price": 18.27
    }, {
        "symbol": "ZCH.TO",
        "label": "BMO China Equity Index ETF",
        "price": 26.6
    }, {
        "symbol": "ZWB.TO",
        "label": "BMO Covered Call Canadian Banks ETF",
        "price": 18.82
    }, {
        "symbol": "ZWA.TO",
        "label": "BMO Covered Call Dow Jones Industrial Average Hedged to CAD ETF",
        "price": 24.67
    }, {
        "symbol": "ZWU.TO",
        "label": "BMO Covered Call Utilities ETF",
        "price": 14.34
    }, {
        "symbol": "ZDB.TO",
        "label": "BMO Discount Bond Index ETF",
        "price": 16.47
    }, {
        "symbol": "ZDJ.TO",
        "label": "BMO Dow Jones Industrial Average Hedged to CAD Index ETF",
        "price": 48.51
    }, {
        "symbol": "ZEF.TO",
        "label": "BMO Emerging Market Bond Hedged to CAD Index ETF",
        "price": 15.68
    }, {
        "symbol": "ZRE.TO",
        "label": "BMO Equal Weight REITs Index ETF",
        "price": 25.8
    }, {
        "symbol": "ZUB.TO",
        "label": "BMO Equal Weight US Banks Hedged to CAD Index ETF",
        "price": 30.06
    }, {
        "symbol": "ZBK.TO",
        "label": "BMO Equal Weight US Banks Index ETF",
        "price": 28.81
    }, {
        "symbol": "ZUH.TO",
        "label": "BMO Equal Weight US Health Care Hedged to CAD Index ETF",
        "price": 61.75
    }, {
        "symbol": "ZUT.TO",
        "label": "BMO Equal Weight Utilities Index ETF",
        "price": 21.0
    }, {
        "symbol": "ZWE.TO",
        "label": "BMO Europe High Dividend Covered Call Hedged to CAD ETF",
        "price": 21.38
    }, {
        "symbol": "ZFH.TO",
        "label": "BMO Floating Rate High Yield ETF",
        "price": 15.84
    }, {
        "symbol": "ZGI.TO",
        "label": "BMO Global Infrastructure Index ETF",
        "price": 42.04
    }, {
        "symbol": "ZHY.TO",
        "label": "BMO High Yield US Corporate Bond Hedged to CAD Index ETF",
        "price": 13.78
    }, {
        "symbol": "ZID.TO",
        "label": "BMO India Equity Index ETF",
        "price": 28.67
    }, {
        "symbol": "ZDI.TO",
        "label": "BMO International Dividend ETF",
        "price": 21.67
    }, {
        "symbol": "ZDH.TO",
        "label": "BMO International Dividend Hedged to CAD ETF",
        "price": 23.35
    }, {
        "symbol": "ZJN.TO",
        "label": "BMO Junior Gas Index ETF",
        "price": 32.77
    }, {
        "symbol": "ZJG.TO",
        "label": "BMO Junior Gold Index ETF",
        "price": 59.06
    }, {
        "symbol": "ZJO.TO",
        "label": "BMO Junior Oil Index ETF",
        "price": 36.48
    }, {
        "symbol": "ZPR.TO",
        "label": "BMO Laddered Preferred Share Index ETF",
        "price": 10.0
    }, {
        "symbol": "ZLC.TO",
        "label": "BMO Long Corporate Bond Index ETF",
        "price": 19.67
    }, {
        "symbol": "ZFL.TO",
        "label": "BMO Long Federal Bond Index ETF",
        "price": 18.54
    }, {
        "symbol": "ZPL.TO",
        "label": "BMO Long Provincial Bond Index ETF",
        "price": 17.08
    }, {
        "symbol": "ZLB.TO",
        "label": "BMO Low Volatility Canadian Equity ETF",
        "price": 35.66
    }, {
        "symbol": "ZLE.TO",
        "label": "BMO Low Volatility Emerging Markets Equity ETF",
        "price": 22.34
    }, {
        "symbol": "ZLI.TO",
        "label": "BMO Low Volatility International Equity ETF",
        "price": 26.12
    }, {
        "symbol": "ZLD.TO",
        "label": "BMO Low Volatility International Equity Hedged to CAD ETF",
        "price": 26.61
    }, {
        "symbol": "ZLU.TO",
        "label": "BMO Low Volatility US Equity ETF (CAD)",
        "price": 40.06
    }, {
        "symbol": "ZLH.TO",
        "label": "BMO Low Volatility US Equity Hedged to CAD ETF",
        "price": 28.46
    }, {
        "symbol": "ZCM.TO",
        "label": "BMO Mid Corporate Bond Index ETF",
        "price": 16.69
    }, {
        "symbol": "ZFM.TO",
        "label": "BMO Mid Federal Bond Index ETF",
        "price": 16.43
    }, {
        "symbol": "ZMP.TO",
        "label": "BMO Mid Provincial Bond Index ETF",
        "price": 15.245
    }, {
        "symbol": "ZMU.TO",
        "label": "BMO Mid-Term US IG Corporate Bond Hedged to CAD Index ETF",
        "price": 15.39
    }, {
        "symbol": "ZIC.TO",
        "label": "BMO Mid-Term US IG Corporate Bond Index ETF (CAD)",
        "price": 19.65
    }, {
        "symbol": "ZMI.TO",
        "label": "BMO Monthly Income ETF",
        "price": 16.73
    }, {
        "symbol": "ZGQ.TO",
        "label": "BMO MSCI All Country World High Quality Index ETF",
        "price": 38.77
    }, {
        "symbol": "ZDM.TO",
        "label": "BMO MSCI EAFE Hedged to CAD Index ETF",
        "price": 23.37
    }, {
        "symbol": "ZEA.TO",
        "label": "BMO MSCI EAFE Index ETF",
        "price": 19.69
    }, {
        "symbol": "ZEM.TO",
        "label": "BMO MSCI Emerging Markets Index ETF",
        "price": 21.33
    }, {
        "symbol": "ZEQ.TO",
        "label": "BMO MSCI Europe High Quality Hedged to CAD Index ETF",
        "price": 24.7
    }, {
        "symbol": "ZUQ.TO",
        "label": "BMO MSCI USA High Quality Index ETF",
        "price": 43.89
    }, {
        "symbol": "ZQQ.TO",
        "label": "BMO Nasdaq 100 Equity Hedged to CAD Index ETF",
        "price": 67.86
    }, {
        "symbol": "ZRR.TO",
        "label": "BMO Real Return Bond Index ETF",
        "price": 18.1
    }, {
        "symbol": "ZUE.TO",
        "label": "BMO S&amp;P 500 Hedged to CAD Index ETF",
        "price": 49.11
    }, {
        "symbol": "ZSP.TO",
        "label": "BMO S&amp;P 500 Index ETF (CAD)",
        "price": 47.8
    }, {
        "symbol": "ZCN.TO",
        "label": "BMO S&amp;P/TSX Capped Composite Index ETF",
        "price": 23.7
    }, {
        "symbol": "ZEB.TO",
        "label": "BMO Equal Weight Banks Index ETF",
        "price": 29.9
    }, {
        "symbol": "ZMT.TO",
        "label": "BMO Equal Weight Global Base Metals Hedged to CAD Index ETF",
        "price": 42.75
    }, {
        "symbol": "ZGD.TO",
        "label": "BMO Equal Weight Global Gold Index ETF",
        "price": 60.26
    }, {
        "symbol": "ZIN.TO",
        "label": "BMO Equal Weight Industrials Index ETF",
        "price": 29.74
    }, {
        "symbol": "ZEO.TO",
        "label": "BMO Equal Weight Oil &amp; Gas Index ETF",
        "price": 43.56
    }, {
        "symbol": "ZCS.TO",
        "label": "BMO Short Corporate Bond Index ETF",
        "price": 14.24
    }, {
        "symbol": "ZFS.TO",
        "label": "BMO Short Federal Bond Index ETF",
        "price": 14.22
    }, {
        "symbol": "ZPS.TO",
        "label": "BMO Short Provincial Bond Index ETF",
        "price": 13.23
    }, {
        "symbol": "ZSU.TO",
        "label": "BMO Short-Term US IG Corporate Bond Hedged to CAD Index ETF",
        "price": 14.72
    }, {
        "symbol": "ZST.TO",
        "label": "BMO Ultra Short-Term Bond ETF",
        "price": 50.88
    }, {
        "symbol": "ZDY.TO",
        "label": "BMO US Dividend ETF (CAD)",
        "price": 34.54
    }, {
        "symbol": "ZUD.TO",
        "label": "BMO US Dividend Hedged to CAD ETF",
        "price": 26.19
    }, {
        "symbol": "ZWH.TO",
        "label": "BMO US High Dividend Covered Call ETF",
        "price": 21.78
    }, {
        "symbol": "ZPW.TO",
        "label": "BMO US Put Write ETF",
        "price": 16.23
    }, {
        "symbol": "GBT.TO",
        "label": "BMTC Group Inc.",
        "price": 10.35
    }, {
        "symbol": "BKX.TO",
        "label": "BNK Petroleum Inc.",
        "price": 0.095
    }, {
        "symbol": "BSC.TO",
        "label": "BNS Split Corp II",
        "price": 21.23
    }, {
        "symbol": "BEI-UN.TO",
        "label": "Boardwalk Real Estate Investment Trust",
        "price": 48.3
    }, {
        "symbol": "BBD-B.TO",
        "label": "Bombardier Inc.",
        "price": 1.12
    }, {
        "symbol": "BNP.TO",
        "label": "Bonavista Energy Corporation",
        "price": 0.6
    }, {
        "symbol": "BNE.TO",
        "label": "Bonterra Energy Corp.",
        "price": 4.05
    }, {
        "symbol": "BLX.TO",
        "label": "Boralex Inc.",
        "price": 26.38
    }, {
        "symbol": "BPF-UN.TO",
        "label": "Boston Pizza Royalties Income Fund",
        "price": 14.46
    }, {
        "symbol": "BYD-UN.TO",
        "label": "Boyd Group Income Fund",
        "price": 202.0
    }, {
        "symbol": "BOY.TO",
        "label": "Boyuan Construction Group, Inc.",
        "price": 0.32
    }, {
        "symbol": "BBL-A.TO",
        "label": "Brampton Brick Limited",
        "price": 7.07
    }, {
        "symbol": "HBL-UN.TO",
        "label": "Brand Leaders Income Fund",
        "price": 11.82
    }, {
        "symbol": "HBF.TO",
        "label": "Harvest Brand Leaders Plus Income ETF",
        "price": 10.2
    }, {
        "symbol": "BRY.TO",
        "label": "Bri-Chem Corp.",
        "price": 0.12
    }, {
        "symbol": "LCS.TO",
        "label": "Brompton Lifeco Split Corp.",
        "price": 6.76
    }, {
        "symbol": "OSP.TO",
        "label": "Brompton Oil Split Corp.",
        "price": 1.37
    }, {
        "symbol": "SBC.TO",
        "label": "Brompton Split Banc Corp.",
        "price": 13.35
    }, {
        "symbol": "BAM-A.TO",
        "label": "Brookfield Asset Management Inc.",
        "price": 81.38
    }, {
        "symbol": "BBU-UN.TO",
        "label": "Brookfield Business Partners L.P.",
        "price": 55.58
    }, {
        "symbol": "BGI-UN.TO",
        "label": "Brookfield Global Infrastructure Securities Income Fund",
        "price": 7.48
    }, {
        "symbol": "BIP-UN.TO",
        "label": "Brookfield Infrastructure Partners L.P.",
        "price": 69.7
    }, {
        "symbol": "BPY-UN.TO",
        "label": "Brookfield Property Partners L.P.",
        "price": 25.95
    }, {
        "symbol": "BRE.TO",
        "label": "Bridgemarq Real Estate Services Inc.",
        "price": 15.14
    }, {
        "symbol": "BEP-UN.TO",
        "label": "Brookfield Renewable Partners L.P.",
        "price": 60.47
    }, {
        "symbol": "BSO-UN.TO",
        "label": "Brookfield Select Opportunities Income Fund",
        "price": 3.6
    }, {
        "symbol": "BSD-UN.TO",
        "label": "Soundvest Split Trust",
        "price": 0.5
    }, {
        "symbol": "DOO.TO",
        "label": "BRP Inc.",
        "price": 62.51
    }, {
        "symbol": "BTB-UN.TO",
        "label": "BTB Real Estate Investment Trust",
        "price": 5.32
    }, {
        "symbol": "BUI.TO",
        "label": "Buhler Industries Inc.",
        "price": 2.79
    }, {
        "symbol": "BU.TO",
        "label": "Burcon NutraScience Corporation",
        "price": 1.53
    }, {
        "symbol": "CAE.TO",
        "label": "CAE Inc.",
        "price": 38.73
    }, {
        "symbol": "CWL.TO",
        "label": "The Caldwell Partners International Inc.",
        "price": 1.28
    }, {
        "symbol": "CAL.TO",
        "label": "Caledonia Mining Corporation Plc",
        "price": 10.82
    }, {
        "symbol": "CFW.TO",
        "label": "Calfrac Well Services Ltd.",
        "price": 1.18
    }, {
        "symbol": "CGY.TO",
        "label": "Calian Group Ltd.",
        "price": 40.5
    }, {
        "symbol": "CBL.TO",
        "label": "Callidus Capital Corporation",
        "price": 0.74
    }, {
        "symbol": "CCO.TO",
        "label": "Cameco Corporation",
        "price": 11.6
    }, {
        "symbol": "CF.TO",
        "label": "Canaccord Genuity Group Inc.",
        "price": 5.0
    }, {
        "symbol": "CNE.TO",
        "label": "Canacol Energy Ltd",
        "price": 4.34
    }, {
        "symbol": "CAR-UN.TO",
        "label": "Canadian Apartment Properties Real Estate Investment Trust",
        "price": 55.66
    }, {
        "symbol": "BK.TO",
        "label": "Canadian Banc Corp.",
        "price": 10.41
    }, {
        "symbol": "CCX.TO",
        "label": "Canadian Crude Oil Index ETF",
        "price": 9.48
    }, {
        "symbol": "CEU.TO",
        "label": "CES Energy Solutions Corp.",
        "price": 2.02
    }, {
        "symbol": "CIQ-UN.TO",
        "label": "Canadian High Income Equity Fund",
        "price": 7.94
    }, {
        "symbol": "CM.TO",
        "label": "Canadian Imperial Bank of Commerce",
        "price": 108.77
    }, {
        "symbol": "LFE.TO",
        "label": "Canadian Life Companies Split Corp.",
        "price": 4.72
    }, {
        "symbol": "CNR.TO",
        "label": "Canadian National Railway Company",
        "price": 125.62
    }, {
        "symbol": "GAS.TO",
        "label": "Canadian Natural Gas ETF",
        "price": 4.05
    }, {
        "symbol": "CNQ.TO",
        "label": "Canadian Natural Resources Limited",
        "price": 41.41
    }, {
        "symbol": "CP.TO",
        "label": "Canadian Pacific Railway Limited",
        "price": 343.08
    }, {
        "symbol": "PFT-UN.TO",
        "label": "Canadian Preferred Share Trust",
        "price": 7.9
    }, {
        "symbol": "CTC-A.TO",
        "label": "Canadian Tire Corporation, Limited",
        "price": 145.7
    }, {
        "symbol": "UTE-UN.TO",
        "label": "Canadian Utilities & Telecom Income Fund",
        "price": 10.0
    }, {
        "symbol": "CU.TO",
        "label": "Canadian Utilities Limited",
        "price": 40.07
    }, {
        "symbol": "CWB.TO",
        "label": "Canadian Western Bank",
        "price": 33.5
    }, {
        "symbol": "CCM.TO",
        "label": "Canarc Resource Corp.",
        "price": 0.06
    }, {
        "symbol": "DNT.TO",
        "label": "Candente Copper Corp.",
        "price": 0.035
    }, {
        "symbol": "CFP.TO",
        "label": "Canfor Corporation",
        "price": 13.65
    }, {
        "symbol": "CFX.TO",
        "label": "Canfor Pulp Products Inc.",
        "price": 9.41
    }, {
        "symbol": "ICE.TO",
        "label": "Canlan Ice Sports Corp.",
        "price": 5.25
    }, {
        "symbol": "EIT-UN.TO",
        "label": "Canoe EIT Income Fund",
        "price": 10.76
    }, {
        "symbol": "PBY-UN.TO",
        "label": "Canso Credit Trust - Canso Credit Income Fund",
        "price": 11.21
    }, {
        "symbol": "CWX.TO",
        "label": "CanWel Building Materials Group Ltd.",
        "price": 5.4
    }, {
        "symbol": "CPX.TO",
        "label": "Capital Power Corporation",
        "price": 35.48
    }, {
        "symbol": "CS.TO",
        "label": "Capstone Mining Corp.",
        "price": 0.88
    }, {
        "symbol": "CJ.TO",
        "label": "Cardinal Energy Ltd.",
        "price": 2.77
    }, {
        "symbol": "CJT.TO",
        "label": "Cargojet Inc.",
        "price": 113.02
    }, {
        "symbol": "CAS.TO",
        "label": "Cascades Inc.",
        "price": 11.76
    }, {
        "symbol": "CET.TO",
        "label": "Cathedral Energy Services Ltd.",
        "price": 0.27
    }, {
        "symbol": "CCL-B.TO",
        "label": "CCL Industries Inc.",
        "price": 57.54
    }, {
        "symbol": "CLS.TO",
        "label": "Celestica Inc.",
        "price": 11.55
    }, {
        "symbol": "CVE.TO",
        "label": "Cenovus Energy Inc.",
        "price": 12.11
    }, {
        "symbol": "CEE.TO",
        "label": "Centamin plc",
        "price": 2.2
    }, {
        "symbol": "CG.TO",
        "label": "Centerra Gold Inc.",
        "price": 9.46
    }, {
        "symbol": "CHH.TO",
        "label": "Centric Health Corporation",
        "price": 0.16
    }, {
        "symbol": "CNT.TO",
        "label": "Century Global Commodities Corporation",
        "price": 0.085
    }, {
        "symbol": "CQE.TO",
        "label": "Cequence Energy Ltd.",
        "price": 0.355
    }, {
        "symbol": "CRP.TO",
        "label": "Ceres Global Ag Corp.",
        "price": 3.8
    }, {
        "symbol": "GIB-A.TO",
        "label": "CGI Inc.",
        "price": 113.35
    }, {
        "symbol": "CXN.TO",
        "label": "Chalice Gold Mines Limited",
        "price": 0.185
    }, {
        "symbol": "CIA.TO",
        "label": "Champion Iron Limited",
        "price": 2.56
    }, {
        "symbol": "CSH-UN.TO",
        "label": "Chartwell Retirement Residences",
        "price": 14.45
    }, {
        "symbol": "CHE-UN.TO",
        "label": "Chemtrade Logistics Income Fund",
        "price": 11.24
    }, {
        "symbol": "CHW.TO",
        "label": "Chesswood Group Limited",
        "price": 10.7
    }, {
        "symbol": "CGG.TO",
        "label": "China Gold International Resources Corp. Ltd.",
        "price": 1.14
    }, {
        "symbol": "CKE.TO",
        "label": "Chinook Energy Inc.",
        "price": 0.055
    }, {
        "symbol": "CHP-UN.TO",
        "label": "Choice Properties Real Estate Investment Trust",
        "price": 14.77
    }, {
        "symbol": "CHR.TO",
        "label": "Chorus Aviation Inc.",
        "price": 8.4
    }, {
        "symbol": "CIX.TO",
        "label": "CI Financial Corp",
        "price": 23.15
    }, {
        "symbol": "MBA.TO",
        "label": "CIBT Education Group Inc.",
        "price": 0.67
    }, {
        "symbol": "CGX.TO",
        "label": "Cineplex Inc.",
        "price": 33.93
    }, {
        "symbol": "CPH.TO",
        "label": "Cipher Pharmaceuticals Inc.",
        "price": 1.32
    }, {
        "symbol": "CTF-UN.TO",
        "label": "Citadel Income Fund",
        "price": 3.26
    }, {
        "symbol": "CVG.TO",
        "label": "Clairvest Group Inc.",
        "price": 51.75
    }, {
        "symbol": "CKI.TO",
        "label": "Clarke Inc.",
        "price": 13.29
    }, {
        "symbol": "CSM.TO",
        "label": "ClearStream Energy Services Inc.",
        "price": 0.045
    }, {
        "symbol": "CLR.TO",
        "label": "Clearwater Seafoods Incorporated",
        "price": 5.83
    }, {
        "symbol": "CNU.TO",
        "label": "CNOOC Limited",
        "price": 223.78
    }, {
        "symbol": "CCA.TO",
        "label": "Cogeco Communications Inc.",
        "price": 104.05
    }, {
        "symbol": "CGO.TO",
        "label": "Cogeco Inc.",
        "price": 93.72
    }, {
        "symbol": "GCL.TO",
        "label": "Colabor Group Inc.",
        "price": 0.52
    }, {
        "symbol": "CIGI.TO",
        "label": "Colliers International Group Inc.",
        "price": 109.34
    }, {
        "symbol": "CGT.TO",
        "label": "Columbus Gold Corp.",
        "price": 0.165
    }, {
        "symbol": "CUF-UN.TO",
        "label": "Cominar Real Estate Investment Trust",
        "price": 14.66
    }, {
        "symbol": "YCM.TO",
        "label": "New Commerce Split Fund",
        "price": 1.6
    }, {
        "symbol": "CMG.TO",
        "label": "Computer Modelling Group Ltd.",
        "price": 8.24
    }, {
        "symbol": "CPI.TO",
        "label": "Condor Petroleum Inc.",
        "price": 0.66
    }, {
        "symbol": "CFF.TO",
        "label": "Conifex Timber Inc.",
        "price": 0.77
    }, {
        "symbol": "CSU.TO",
        "label": "Constellation Software Inc.",
        "price": 1349.99
    }, {
        "symbol": "CNL.TO",
        "label": "Continental Gold Inc.",
        "price": 5.44
    }, {
        "symbol": "CSW-A.TO",
        "label": "Corby Spirit and Wine Limited",
        "price": 15.42
    }, {
        "symbol": "CDD-UN.TO",
        "label": "Core Canadian Dividend Trust",
        "price": 5.7
    }, {
        "symbol": "COP.TO",
        "label": "Coro Mining Corp.",
        "price": 0.065
    }, {
        "symbol": "CDH.TO",
        "label": "Corridor Resources Inc.",
        "price": 1.33
    }, {
        "symbol": "CJR-B.TO",
        "label": "Corus Entertainment Inc.",
        "price": 5.51
    }, {
        "symbol": "KOR.TO",
        "label": "Corvus Gold Inc.",
        "price": 1.99
    }, {
        "symbol": "BCB.TO",
        "label": "Cott Corporation",
        "price": 19.23
    }, {
        "symbol": "PATH.TO",
        "label": "CounterPath Corporation",
        "price": 3.33
    }, {
        "symbol": "CPG.TO",
        "label": "Crescent Point Energy Corp.",
        "price": 5.5
    }, {
        "symbol": "CTX.TO",
        "label": "Crescita Therapeutics Inc.",
        "price": 0.9
    }, {
        "symbol": "CR.TO",
        "label": "Crew Energy Inc.",
        "price": 0.52
    }, {
        "symbol": "CRH.TO",
        "label": "CRH Medical Corporation",
        "price": 5.3
    }, {
        "symbol": "CCZ.TO",
        "label": "Critical Control Energy Services Corp.",
        "price": 0.08
    }, {
        "symbol": "KWH-UN.TO",
        "label": "Crius Energy Trust",
        "price": 8.8
    }, {
        "symbol": "CRR-UN.TO",
        "label": "Crombie Real Estate Investment Trust",
        "price": 16.36
    }, {
        "symbol": "CWI.TO",
        "label": "Crosswinds Holdings Inc.",
        "price": 0.12
    }, {
        "symbol": "CRT-UN.TO",
        "label": "CT Real Estate Investment Trust",
        "price": 16.37
    }, {
        "symbol": "CXI.TO",
        "label": "Currency Exchange International, Corp.",
        "price": 17.5
    }, {
        "symbol": "CYB.TO",
        "label": "Cymbria Corporation",
        "price": 54.41
    }, {
        "symbol": "DBO.TO",
        "label": "D-BOX Technologies Inc.",
        "price": 0.11
    }, {
        "symbol": "DCM.TO",
        "label": "DATA Communications Management Corp.",
        "price": 0.31
    }, {
        "symbol": "DEE.TO",
        "label": "Delphi Energy Corp.",
        "price": 0.72
    }, {
        "symbol": "DSG.TO",
        "label": "The Descartes Systems Group Inc",
        "price": 60.38
    }, {
        "symbol": "DGC.TO",
        "label": "Detour Gold Corporation",
        "price": 24.62
    }, {
        "symbol": "DRT.TO",
        "label": "DIRTT Environmental Solutions Ltd.",
        "price": 4.12
    }, {
        "symbol": "DIV.TO",
        "label": "Diversified Royalty Corp.",
        "price": 3.2
    }, {
        "symbol": "DFN.TO",
        "label": "Dividend 15 Split Corp.",
        "price": 8.73
    }, {
        "symbol": "DF.TO",
        "label": "Dividend 15 Split Corp. II",
        "price": 5.32
    }, {
        "symbol": "DGS.TO",
        "label": "Dividend Growth Split Corp.",
        "price": 5.76
    }, {
        "symbol": "DS.TO",
        "label": "Dividend Select 15 Corp.",
        "price": 7.56
    }, {
        "symbol": "DOL.TO",
        "label": "Dollarama Inc.",
        "price": 45.74
    }, {
        "symbol": "UFS.TO",
        "label": "Domtar Corporation",
        "price": 52.13
    }, {
        "symbol": "DII-B.TO",
        "label": "Dorel Industries Inc.",
        "price": 5.75
    }, {
        "symbol": "DRG-UN.TO",
        "label": "Dream Global Real Estate Investment Trust",
        "price": 16.79
    }, {
        "symbol": "DRA-UN.TO",
        "label": "Dream Hard Asset Alternatives Trust",
        "price": 7.77
    }, {
        "symbol": "DIR-UN.TO",
        "label": "Dream Industrial Real Estate Investment Trust",
        "price": 13.9
    }, {
        "symbol": "D-UN.TO",
        "label": "Dream Office Real Estate Investment Trust",
        "price": 32.43
    }, {
        "symbol": "DRM.TO",
        "label": "Dream Unlimited Corp.",
        "price": 11.68
    }, {
        "symbol": "DC-A.TO",
        "label": "Dundee Corporation",
        "price": 1.26
    }, {
        "symbol": "DPM.TO",
        "label": "Dundee Precious Metals Inc.",
        "price": 5.95
    }, {
        "symbol": "DXI.TO",
        "label": "DXI Energy Inc.",
        "price": 0.03
    }, {
        "symbol": "DNG.TO",
        "label": "Dynacor Gold Mines Inc.",
        "price": 1.71
    }, {
        "symbol": "ELF.TO",
        "label": "E-L Financial Corporation Limited",
        "price": 827.0
    }, {
        "symbol": "ECF-UN.TO",
        "label": "East Coast Investment Grade Income Fund",
        "price": 9.07
    }, {
        "symbol": "ELR.TO",
        "label": "Eastern Platinum Limited",
        "price": 0.335
    }, {
        "symbol": "ER.TO",
        "label": "Eastmain Resources Inc.",
        "price": 0.105
    }, {
        "symbol": "EFH.TO",
        "label": "Echelon Financial Holdings Inc.",
        "price": 6.01
    }, {
        "symbol": "ECN.TO",
        "label": "ECN Capital Corp.",
        "price": 4.96
    }, {
        "symbol": "EVT.TO",
        "label": "Economic Investment Trust Limited",
        "price": 110.5
    }, {
        "symbol": "ECO.TO",
        "label": "EcoSynthetix Inc.",
        "price": 2.6
    }, {
        "symbol": "ELD.TO",
        "label": "Eldorado Gold Corporation",
        "price": 9.5
    }, {
        "symbol": "EFL.TO",
        "label": "Electrovaya Inc.",
        "price": 0.215
    }, {
        "symbol": "EFN.TO",
        "label": "Element Fleet Management Corp.",
        "price": 12.65
    }, {
        "symbol": "EMA.TO",
        "label": "Emera Incorporated",
        "price": 58.15
    }, {
        "symbol": "EMP-A.TO",
        "label": "Empire Company Limited",
        "price": 31.06
    }, {
        "symbol": "ENB.TO",
        "label": "Enbridge Inc.",
        "price": 52.97
    }, {
        "symbol": "ECA.TO",
        "label": "Encana Corporation",
        "price": 5.33
    }, {
        "symbol": "EDV.TO",
        "label": "Endeavour Mining Corporation",
        "price": 25.09
    }, {
        "symbol": "EDR.TO",
        "label": "Endeavour Silver Corp.",
        "price": 2.79
    }, {
        "symbol": "EFX.TO",
        "label": "Enerflex Ltd.",
        "price": 11.44
    }, {
        "symbol": "EFR.TO",
        "label": "Energy Fuels Inc.",
        "price": 2.26
    }, {
        "symbol": "ENI-UN.TO",
        "label": "Energy Income Fund",
        "price": 1.72
    }, {
        "symbol": "HPF.TO",
        "label": "Harvest Energy Leaders Plus Income ETF",
        "price": 4.16
    }, {
        "symbol": "ERF.TO",
        "label": "Enerplus Corporation",
        "price": 8.44
    }, {
        "symbol": "ENGH.TO",
        "label": "Enghouse Systems Limited",
        "price": 51.75
    }, {
        "symbol": "ESI.TO",
        "label": "Ensign Energy Services Inc.",
        "price": 2.6
    }, {
        "symbol": "ENT.TO",
        "label": "ENTREC Corporation",
        "price": 0.07
    }, {
        "symbol": "ETG.TO",
        "label": "Entree Resources Ltd.",
        "price": 0.375
    }, {
        "symbol": "EPS.TO",
        "label": "Epsilon Energy Ltd.",
        "price": 5.46
    }, {
        "symbol": "EQB.TO",
        "label": "Equitable Group Inc.",
        "price": 107.93
    }, {
        "symbol": "ERD.TO",
        "label": "Erdene Resource Development Corporation",
        "price": 0.205
    }, {
        "symbol": "ESN.TO",
        "label": "Essential Energy Services Ltd.",
        "price": 0.395
    }, {
        "symbol": "ETX.TO",
        "label": "Etrion Corporation",
        "price": 0.215
    }, {
        "symbol": "ESM.TO",
        "label": "Euro Sun Mining Inc.",
        "price": 0.295
    }, {
        "symbol": "EOX.TO",
        "label": "Euromax Resources Ltd.",
        "price": 0.035
    }, {
        "symbol": "ET.TO",
        "label": "Evertz Technologies Limited",
        "price": 18.08
    }, {
        "symbol": "XCT.TO",
        "label": "exactEarth Ltd.",
        "price": 0.31
    }, {
        "symbol": "EXN.TO",
        "label": "Excellon Resources Inc.",
        "price": 0.98
    }, {
        "symbol": "EIF.TO",
        "label": "Exchange Income Corporation",
        "price": 44.09
    }, {
        "symbol": "XTC.TO",
        "label": "Exco Technologies Limited",
        "price": 7.82
    }, {
        "symbol": "EXF.TO",
        "label": "EXFO Inc.",
        "price": 5.3
    }, {
        "symbol": "EXE.TO",
        "label": "Extendicare Inc.",
        "price": 8.77
    }, {
        "symbol": "FFH.TO",
        "label": "Fairfax Financial Holdings Limited",
        "price": 612.66
    }, {
        "symbol": "FIH-U.TO",
        "label": "Fairfax India Holdings Corporation",
        "price": 13.5
    }, {
        "symbol": "FRX.TO",
        "label": "Fennec Pharmaceuticals Inc.",
        "price": 8.23
    }, {
        "symbol": "FSZ.TO",
        "label": "Fiera Capital Corporation",
        "price": 12.55
    }, {
        "symbol": "FTN.TO",
        "label": "Financial 15 Split Corp.",
        "price": 7.21
    }, {
        "symbol": "FTT.TO",
        "label": "Finning International Inc.",
        "price": 24.86
    }, {
        "symbol": "FTG.TO",
        "label": "Firan Technology Group Corporation",
        "price": 4.14
    }, {
        "symbol": "FC.TO",
        "label": "Firm Capital Mortgage Investment Corporation",
        "price": 14.88
    }, {
        "symbol": "BXF.TO",
        "label": "CI First Asset 1-5 Year Laddered Government Strip Bond Index ETF Common",
        "price": 10.28
    }, {
        "symbol": "FDV.TO",
        "label": "CI First Asset Active Canadian Dividend ETF Common",
        "price": 10.48
    }, {
        "symbol": "FAI.TO",
        "label": "CI First Asset Active Utility &amp; Infrastructure ETF Common Units",
        "price": 12.68
    }, {
        "symbol": "MXF.TO",
        "label": "CI First Asset Can-Materials Covered Call ETF Common",
        "price": 12.06
    }, {
        "symbol": "FBE.TO",
        "label": "CI First Asset Canadian Buyback Index ETF",
        "price": 25.82
    }, {
        "symbol": "CXF.TO",
        "label": "CI First Asset Canadian Convertible Bond ETF Common",
        "price": 10.08
    }, {
        "symbol": "FDL.TO",
        "label": "First Asset Canadian Dividend Low Volatility Index ETF",
        "price": 19.78
    }, {
        "symbol": "RIT.TO",
        "label": "CI First Asset Canadian REIT ETF Common",
        "price": 19.32
    }, {
        "symbol": "CIC.TO",
        "label": "CI First Asset CanBanc Income Class ETF",
        "price": 11.59
    }, {
        "symbol": "CED.TO",
        "label": "CI First Asset Core Canadian Equity ETF Common Units",
        "price": 22.21
    }, {
        "symbol": "CSY.TO",
        "label": "CI First Asset Core Canadian Equity Income Class ETF",
        "price": 21.52
    }, {
        "symbol": "CES.TO",
        "label": "CI First Asset Core U.S. Equity ETF (CAD Hedged) Common Units",
        "price": 24.5
    }, {
        "symbol": "NXF.TO",
        "label": "CI First Asset Energy Giants Covered Call ETF Common Units (CAD Hedged)",
        "price": 6.71
    }, {
        "symbol": "FHB.TO",
        "label": "CI CI First Asset European Bank ETF Common",
        "price": 7.36
    }, {
        "symbol": "FSF.TO",
        "label": "CI First Asset Global Financial Sector ETF",
        "price": 19.99
    }, {
        "symbol": "FLB.TO",
        "label": "CI First Asset Long Duration Fixed Income ETF",
        "price": 20.93
    }, {
        "symbol": "DXM.TO",
        "label": "CI First Asset Morningstar Canada Dividend Target 30 Index ETF Common",
        "price": 10.65
    }, {
        "symbol": "WXM.TO",
        "label": "CI First Asset Morningstar Canada Momentum Index ETF Common",
        "price": 20.36
    }, {
        "symbol": "FXM.TO",
        "label": "CI First Asset Morningstar Canada Value Index ETF Common",
        "price": 15.51
    }, {
        "symbol": "ZXM.TO",
        "label": "CI First Asset Morningstar International Momentum Index ETF Common Units (CAD He" +
                "dged)",
        "price": 31.76
    }, {
        "symbol": "VXM.TO",
        "label": "CI First Asset Morningstar International Value Index ETF Common Units (CAD Hedge" +
                "d)",
        "price": 25.27
    }, {
        "symbol": "QXM.TO",
        "label": "CI First Asset Morningstar National Bank Qu??bec Index ETF Common",
        "price": 23.0
    }, {
        "symbol": "UXM.TO",
        "label": "CI First Asset Morningstar US Dividend Target 50 Index ETF (CAD Hedged) Common",
        "price": 16.06
    }, {
        "symbol": "YXM.TO",
        "label": "CI First Asset Morningstar US Momentum Index ETF (CAD Hedged) Common",
        "price": 15.19
    }, {
        "symbol": "XXM.TO",
        "label": "CI First Asset Morningstar US Value Index ETF (CAD Hedged) Common",
        "price": 11.16
    }, {
        "symbol": "RWC.TO",
        "label": "CI First Asset MSCI Canada Low Risk Weighted ETF Common",
        "price": 12.95
    }, {
        "symbol": "FQC.TO",
        "label": "CI First Asset MSCI Canada Quality Index Class ETF",
        "price": 28.03
    }, {
        "symbol": "RWE.TO",
        "label": "CI First Asset MSCI Europe Low Risk Weighted ETF Common Units",
        "price": 31.14
    }, {
        "symbol": "RWU.TO",
        "label": "CI First Asset MSCI USA Low Risk Weighted ETF (CAD Hedged) Common",
        "price": 18.65
    }, {
        "symbol": "RWW.TO",
        "label": "CI First Asset MSCI World Low Risk Weighted ETF Common Units (CAD Hedged)",
        "price": 33.6
    }, {
        "symbol": "FPR.TO",
        "label": "CI First Asset Preferred Share ETF",
        "price": 20.75
    }, {
        "symbol": "FGB.TO",
        "label": "CI First Asset Short Term Government Bond Index Class ETF",
        "price": 18.97
    }, {
        "symbol": "TXF.TO",
        "label": "CI First Asset Tech Giants Covered Call ETF Common Units",
        "price": 17.45
    }, {
        "symbol": "FLI.TO",
        "label": "CI First Asset U.S. &amp; Canada Lifeco Income ETF Common",
        "price": 11.68
    }, {
        "symbol": "FBU.TO",
        "label": "CI First Asset U.S. Buyback Index ETF",
        "price": 31.88
    }, {
        "symbol": "FUT.TO",
        "label": "CI First Asset U.S. Tactical Sector Allocation Index ETF",
        "price": 24.79
    }, {
        "symbol": "FCR.TO",
        "label": "First Capital Realty Inc.",
        "price": 20.86
    }, {
        "symbol": "FR.TO",
        "label": "First Majestic Silver Corp.",
        "price": 13.77
    }, {
        "symbol": "FN.TO",
        "label": "First National Financial Corporation",
        "price": 38.95
    }, {
        "symbol": "FM.TO",
        "label": "First Quantum Minerals Ltd.",
        "price": 13.19
    }, {
        "symbol": "FDE.TO",
        "label": "First Trust AlphaDEX Emerging Market Dividend ETF CAD Hedged Common",
        "price": 17.12
    }, {
        "symbol": "EUR.TO",
        "label": "First Trust AlphaDEX European Dividendidend Index ETF Common",
        "price": 23.29
    }, {
        "symbol": "FHD.TO",
        "label": "First Trust AlphaDEX U.S. Consumer Discretionary Sector Index ETF",
        "price": 28.26
    }, {
        "symbol": "FHC.TO",
        "label": "FT AlphaDEX U.S. Consumer Staples Sector Index ETF",
        "price": 25.6
    }, {
        "symbol": "FUD.TO",
        "label": "First Trust Value Line Dividend ETF Common",
        "price": 31.33
    }, {
        "symbol": "FHE.TO",
        "label": "First Trust AlphaDEX U.S. Energy Sector Index ETF",
        "price": 9.16
    }, {
        "symbol": "FHF.TO",
        "label": "First Trust AlphaDEX U.S. Financial Sector Index ETF",
        "price": 31.1
    }, {
        "symbol": "FHH.TO",
        "label": "FT AlphaDEX U.S. Health Care Sector Index ETF",
        "price": 30.3
    }, {
        "symbol": "FHG.TO",
        "label": "FT AlphaDEX U.S. Industrials Sector Index ETF",
        "price": 32.0
    }, {
        "symbol": "FHM.TO",
        "label": "First Trust AlphaDEX U.S. Materials Sector Index ETF",
        "price": 24.0
    }, {
        "symbol": "FHQ.TO",
        "label": "FT AlphaDEX U.S. Technology Sector Index ETF",
        "price": 48.25
    }, {
        "symbol": "FHU.TO",
        "label": "First Trust AlphaDEX U.S. Utilities Sector Index ETF",
        "price": 26.44
    }, {
        "symbol": "FST.TO",
        "label": "First Trust Canadian Capital Strength ETF Common",
        "price": 35.79
    }, {
        "symbol": "FSR.TO",
        "label": "First Trust Dorsey Wright U.S. Sector Rotation Index ETF (CAD-Hedged)",
        "price": 25.43
    }, {
        "symbol": "ETP.TO",
        "label": "First Trust Global Risk Managed Income Index ETF Common",
        "price": 19.29
    }, {
        "symbol": "FSL.TO",
        "label": "First Trust Senior Loan ETF (CAD-Hedged) Common",
        "price": 18.73
    }, {
        "symbol": "FTB.TO",
        "label": "First Trust Tactical Bond Index ETF",
        "price": 18.41
    }, {
        "symbol": "FSV.TO",
        "label": "FirstService Corporation",
        "price": 128.31
    }, {
        "symbol": "FCU.TO",
        "label": "Fission Uranium Corp.",
        "price": 0.29
    }, {
        "symbol": "FFI-UN.TO",
        "label": "Flaherty & Crumrine Investment Grade Preferred Income Fund",
        "price": 13.16
    }, {
        "symbol": "FAR.TO",
        "label": "Foraco International SA",
        "price": 0.43
    }, {
        "symbol": "FSY.TO",
        "label": "Forsys Metals Corp.",
        "price": 0.125
    }, {
        "symbol": "FTS.TO",
        "label": "Fortis Inc.",
        "price": 56.59
    }, {
        "symbol": "FVI.TO",
        "label": "Fortuna Silver Mines Inc.",
        "price": 5.05
    }, {
        "symbol": "FT.TO",
        "label": "Fortune Minerals Limited",
        "price": 0.08
    }, {
        "symbol": "FNV.TO",
        "label": "Franco-Nevada Corporation",
        "price": 138.0
    }, {
        "symbol": "FVL.TO",
        "label": "Freegold Ventures Limited",
        "price": 0.045
    }, {
        "symbol": "FRU.TO",
        "label": "Freehold Royalties Ltd.",
        "price": 7.87
    }, {
        "symbol": "GH.TO",
        "label": "Gamehost Inc.",
        "price": 8.46
    }, {
        "symbol": "GDI.TO",
        "label": "GDI Integrated Facility Services Inc.",
        "price": 33.49
    }, {
        "symbol": "GXE.TO",
        "label": "Gear Energy Ltd.",
        "price": 0.45
    }, {
        "symbol": "GMO.TO",
        "label": "General Moly, Inc.",
        "price": 0.295
    }, {
        "symbol": "GDC.TO",
        "label": "Genesis Land Development Corp.",
        "price": 2.2
    }, {
        "symbol": "MIC.TO",
        "label": "Genworth MI Canada Inc.",
        "price": 60.91
    }, {
        "symbol": "GEO.TO",
        "label": "Geodrill Limited",
        "price": 1.61
    }, {
        "symbol": "WN.TO",
        "label": "George Weston Limited",
        "price": 105.74
    }, {
        "symbol": "GEI.TO",
        "label": "Gibson Energy Inc.",
        "price": 27.66
    }, {
        "symbol": "GIL.TO",
        "label": "Gildan Activewear Inc.",
        "price": 38.81
    }, {
        "symbol": "GVC.TO",
        "label": "Glacier Media Inc.",
        "price": 0.55
    }, {
        "symbol": "GLG.TO",
        "label": "GLG Life Tech Corporation",
        "price": 0.415
    }, {
        "symbol": "GDG-UN.TO",
        "label": "Global Dividend Growers Income Fund",
        "price": 13.13
    }, {
        "symbol": "HGI-UN.TO",
        "label": "Global Telecom & Utilities Income Fund",
        "price": 9.25
    }, {
        "symbol": "GMX.TO",
        "label": "Globex Mining Enterprises Inc.",
        "price": 0.37
    }, {
        "symbol": "GMP.TO",
        "label": "GMP Capital Inc.",
        "price": 2.0
    }, {
        "symbol": "GSY.TO",
        "label": "goeasy Ltd.",
        "price": 73.05
    }, {
        "symbol": "GGD.TO",
        "label": "GoGold Resources Inc.",
        "price": 0.74
    }, {
        "symbol": "GSC.TO",
        "label": "Golden Star Resources Ltd.",
        "price": 4.06
    }, {
        "symbol": "GGA.TO",
        "label": "Goldgroup Mining Inc.",
        "price": 0.025
    }, {
        "symbol": "XAU.TO",
        "label": "Goldmoney Inc.",
        "price": 1.93
    }, {
        "symbol": "GDL.TO",
        "label": "Goodfellow Inc.",
        "price": 4.85
    }, {
        "symbol": "GCM.TO",
        "label": "Gran Colombia Gold Corp.",
        "price": 5.57
    }, {
        "symbol": "GTE.TO",
        "label": "Gran Tierra Energy Inc.",
        "price": 1.52
    }, {
        "symbol": "GXO.TO",
        "label": "Granite Oil Corp.",
        "price": 0.59
    }, {
        "symbol": "GRT-UN.TO",
        "label": "Granite Real Estate Investment Trust",
        "price": 69.65
    }, {
        "symbol": "GC.TO",
        "label": "Great Canadian Gaming Corporation",
        "price": 42.97
    }, {
        "symbol": "GPR.TO",
        "label": "Great Panther Mining Limited",
        "price": 0.82
    }, {
        "symbol": "GWO.TO",
        "label": "Great-West Lifeco Inc.",
        "price": 34.07
    }, {
        "symbol": "GCG-A.TO",
        "label": "Guardian Capital Group Limited",
        "price": 27.46
    }, {
        "symbol": "GUY.TO",
        "label": "Guyana Goldfields Inc.",
        "price": 0.65
    }, {
        "symbol": "GCT.TO",
        "label": "GVIC Communications Corp.",
        "price": 0.19
    }, {
        "symbol": "HR-UN.TO",
        "label": "H&R Real Estate Investment Trust",
        "price": 21.5
    }, {
        "symbol": "HMM-A.TO",
        "label": "Hammond Manufacturing Company Limited",
        "price": 1.9
    }, {
        "symbol": "HPS-A.TO",
        "label": "Hammond Power Solutions Inc.",
        "price": 7.5
    }, {
        "symbol": "HE.TO",
        "label": "Hanwei Energy Services Corp.",
        "price": 0.015
    }, {
        "symbol": "HRT.TO",
        "label": "Harte Gold Corp.",
        "price": 0.17
    }, {
        "symbol": "HHL.TO",
        "label": "Harvest Healthcare Leaders Income ETF",
        "price": 8.21
    }, {
        "symbol": "MDS-UN.TO",
        "label": "Healthcare Special Opportunities Fund",
        "price": 12.77
    }, {
        "symbol": "HBP.TO",
        "label": "Helix BioPharma Corp.",
        "price": 1.36
    }, {
        "symbol": "HRX.TO",
        "label": "Heroux-Devtek Inc.",
        "price": 20.8
    }, {
        "symbol": "HWO.TO",
        "label": "High Arctic Energy Services Inc",
        "price": 2.02
    }, {
        "symbol": "HLF.TO",
        "label": "High Liner Foods Incorporated",
        "price": 8.41
    }, {
        "symbol": "HLC.TO",
        "label": "Holloway Lodging Corporation",
        "price": 8.15
    }, {
        "symbol": "HCG.TO",
        "label": "Home Capital Group Inc.",
        "price": 33.3
    }, {
        "symbol": "HNL.TO",
        "label": "Horizon North Logistics Inc.",
        "price": 1.19
    }, {
        "symbol": "HAD.TO",
        "label": "Horizons Active Cdn Bond ETF Common",
        "price": 10.34
    }, {
        "symbol": "HAL.TO",
        "label": "Horizons Active Cdn Dividend ETF Common",
        "price": 18.76
    }, {
        "symbol": "HMP.TO",
        "label": "Horizons Active Cdn Municipal Bond ETF E",
        "price": 9.87
    }, {
        "symbol": "HAB.TO",
        "label": "Horizons Active Corporate Bond ETF Common",
        "price": 11.09
    }, {
        "symbol": "HAJ.TO",
        "label": "Horizons Active Emerging Markets Dividend ETF Common",
        "price": 13.45
    }, {
        "symbol": "HFR.TO",
        "label": "Horizons Active Floating Rate Bond ETF Common",
        "price": 10.11
    }, {
        "symbol": "HFP.TO",
        "label": "Horizons Active Floating Rate Preferred Share ETF Common",
        "price": 8.21
    }, {
        "symbol": "HSL.TO",
        "label": "Horizons Active Floating Rate Senior Loan ETF Class E",
        "price": 9.55
    }, {
        "symbol": "HAZ.TO",
        "label": "Horizons Active Global Dividend ETF Common",
        "price": 24.19
    }, {
        "symbol": "HAF.TO",
        "label": "Horizons Active Global Fixed Income ETF",
        "price": 7.82
    }, {
        "symbol": "HYI.TO",
        "label": "Horizons Active High Yield Bond ETF Common",
        "price": 9.76
    }, {
        "symbol": "HPR.TO",
        "label": "Horizons Active Preferred Share ETF Common",
        "price": 8.27
    }, {
        "symbol": "HUF.TO",
        "label": "Horizons Active US Floating Rate Bond (USD) ETF Common",
        "price": 13.14
    }, {
        "symbol": "HBD.TO",
        "label": "BetaPro Gold Bullion -2x Daily Bear ETF",
        "price": 9.96
    }, {
        "symbol": "HBU.TO",
        "label": "BetaPro Gold Bullion 2x Daily Bull ETF",
        "price": 10.42
    }, {
        "symbol": "HZD.TO",
        "label": "BetaPro Silver -2x Daily Bear ETF",
        "price": 5.01
    }, {
        "symbol": "HZU.TO",
        "label": "BetaPro Silver 2x Daily Bull ETF",
        "price": 13.18
    }, {
        "symbol": "HQU.TO",
        "label": "BetaPro NASDAQ-100 2x Daily Bull ETF",
        "price": 97.8
    }, {
        "symbol": "HOD.TO",
        "label": "BetaPro Crude Oil -2x Daily Bear ETF",
        "price": 3.925
    }, {
        "symbol": "HOU.TO",
        "label": "BetaPro Crude Oil 2x Daily Bull ETF",
        "price": 6.05
    }, {
        "symbol": "HND.TO",
        "label": "BetaPro Natural Gas -2x Daily Bear ETF",
        "price": 11.99
    }, {
        "symbol": "HNU.TO",
        "label": "BetaPro Natural Gas 2x Daily Bull ETF",
        "price": 5.19
    }, {
        "symbol": "HSD.TO",
        "label": "BetaPro S&amp;P 500 -2x Daily Bear ETF",
        "price": 10.22
    }, {
        "symbol": "HSU.TO",
        "label": "BetaPro S&amp;P 500 2x Daily Bull ETF",
        "price": 73.63
    }, {
        "symbol": "HIU.TO",
        "label": "BetaPro S&amp;P 500 Daily Inverse ETF",
        "price": 21.98
    }, {
        "symbol": "HUV.TO",
        "label": "BetaPro S&amp;P 500 VIX Short-Term Futures ETF",
        "price": 2.35
    }, {
        "symbol": "HXD.TO",
        "label": "BetaPro S&amp;P/TSX 60 -2x Daily Bear ETF",
        "price": 4.28
    }, {
        "symbol": "HXU.TO",
        "label": "BetaPro S&amp;P/TSX 60 2x Daily Bull ETF",
        "price": 44.92
    }, {
        "symbol": "HIX.TO",
        "label": "BetaPro S&amp;P/TSX 60 Daily Inverse ETF",
        "price": 5.38
    }, {
        "symbol": "HED.TO",
        "label": "BetaPro S&amp;P/TSX Capped Energy -2x Daily Bear ETF",
        "price": 10.2
    }, {
        "symbol": "HEU.TO",
        "label": "BetaPro S&amp;P/TSX Capped Energy 2x Daily Bull ETF",
        "price": 4.98
    }, {
        "symbol": "HFD.TO",
        "label": "BetaPro S&amp;P/TSX Capped Financials -2x Daily Bear ETF",
        "price": 3.91
    }, {
        "symbol": "HFU.TO",
        "label": "BetaPro S&amp;P/TSX Capped Financials 2x Daily Bull ETF",
        "price": 46.94
    }, {
        "symbol": "HGD.TO",
        "label": "BetaPro Canadian Gold Miners -2x Daily Bear ETF",
        "price": 3.76
    }, {
        "symbol": "HGU.TO",
        "label": "BetaPro Canadian Gold Miners 2x Daily Bull ETF",
        "price": 17.79
    }, {
        "symbol": "CAN.TO",
        "label": "Horizons Canadian Dollar Currency ETF",
        "price": 9.75
    }, {
        "symbol": "HOG.TO",
        "label": "Horizons Canadian Midstream Oil &amp; Gas Index ETF",
        "price": 8.79
    }, {
        "symbol": "HXH.TO",
        "label": "Horizons Cdn High Dividend Index ETF",
        "price": 33.44
    }, {
        "symbol": "HII.TO",
        "label": "Horizons Cdn Insider Index ETF",
        "price": 11.85
    }, {
        "symbol": "HBB.TO",
        "label": "Horizons CDN Select Universe Bond ETF",
        "price": 48.28
    }, {
        "symbol": "HCN.TO",
        "label": "Horizons China High Dividend Yield Index ETF",
        "price": 24.52
    }, {
        "symbol": "HUG.TO",
        "label": "Horizons Gold ETF",
        "price": 13.56
    }, {
        "symbol": "HUZ.TO",
        "label": "Horizons Silver ETF",
        "price": 9.4
    }, {
        "symbol": "HEE.TO",
        "label": "Horizons Enhanced Income Energy ETF Common",
        "price": 7.59
    }, {
        "symbol": "HEX.TO",
        "label": "Horizons Enhanced Income Equity ETF Common",
        "price": 6.48
    }, {
        "symbol": "HEF.TO",
        "label": "Horizons Enhanced Income Financials ETF Common",
        "price": 8.77
    }, {
        "symbol": "HEP.TO",
        "label": "Horizons Enhanced Income Gold Producers ETF Common",
        "price": 29.42
    }, {
        "symbol": "HEJ.TO",
        "label": "Horizons Enhanced Income International Equity ETF Common",
        "price": 5.96
    }, {
        "symbol": "HEA.TO",
        "label": "Horizons Enhanced Income US Equity (USD) ETF Common",
        "price": 16.32
    }, {
        "symbol": "HRA.TO",
        "label": "Horizons Global Risk Parity ETF",
        "price": 10.82
    }, {
        "symbol": "HGY.TO",
        "label": "Horizons Gold Yield ETF Common",
        "price": 5.14
    }, {
        "symbol": "HGM.TO",
        "label": "Horizons Managed Global Opportunities ETF E",
        "price": 11.18
    }, {
        "symbol": "HHF.TO",
        "label": "Horizons Morningstar Hedge Fund Index ETF Common",
        "price": 14.81
    }, {
        "symbol": "HXQ.TO",
        "label": "Horizons NASDAQ-100 Index ETF",
        "price": 67.06
    }, {
        "symbol": "HNY.TO",
        "label": "Horizons Natural Gas Yield ETF Common",
        "price": 9.08
    }, {
        "symbol": "HUC.TO",
        "label": "Horizons Crude Oil ETF",
        "price": 12.91
    }, {
        "symbol": "HUN.TO",
        "label": "Horizons Natural Gas ETF",
        "price": 6.75
    }, {
        "symbol": "HSH.TO",
        "label": "Horizons S&amp;P 500 CAD Hedged Index ETF",
        "price": 79.95
    }, {
        "symbol": "HEW.TO",
        "label": "Horizons S&amp;P/TSX 60 Equal Weight Index ETF",
        "price": 15.27
    }, {
        "symbol": "HXE.TO",
        "label": "Horizons S&amp;P/TSX Capped Energy Index ETF",
        "price": 16.05
    }, {
        "symbol": "HXF.TO",
        "label": "Horizons S&amp;P/TSX Capped Financials Index ETF",
        "price": 47.5
    }, {
        "symbol": "HAC.TO",
        "label": "Horizons Seasonal Rotation ETF Common",
        "price": 23.02
    }, {
        "symbol": "HTH.TO",
        "label": "Horizons US 7-10 Year Treasury Bond CAD Hedged ETF",
        "price": 51.88
    }, {
        "symbol": "HTB.TO",
        "label": "Horizons US 7-10 Year Treasury Bond ETF",
        "price": 58.34
    }, {
        "symbol": "DLR.TO",
        "label": "Horizons U.S. Dollar Currency ETF",
        "price": 13.21
    }, {
        "symbol": "HZM.TO",
        "label": "Horizonte Minerals Plc",
        "price": 0.065
    }, {
        "symbol": "HBM.TO",
        "label": "Hudbay Minerals Inc.",
        "price": 4.93
    }, {
        "symbol": "HBC.TO",
        "label": "Hudson's Bay Company",
        "price": 10.88
    }, {
        "symbol": "HSE.TO",
        "label": "Husky Energy Inc.",
        "price": 9.62
    }, {
        "symbol": "H.TO",
        "label": "Hydro One Limited",
        "price": 26.19
    }, {
        "symbol": "IMG.TO",
        "label": "IAMGOLD Corporation",
        "price": 3.94
    }, {
        "symbol": "IBG.TO",
        "label": "IBI Group Inc.",
        "price": 5.63
    }, {
        "symbol": "IFA.TO",
        "label": "iFabric Corp.",
        "price": 1.0
    }, {
        "symbol": "IGM.TO",
        "label": "IGM Financial Inc.",
        "price": 39.41
    }, {
        "symbol": "IMV.TO",
        "label": "IMV Inc.",
        "price": 5.2
    }, {
        "symbol": "III.TO",
        "label": "Imperial Metals Corporation",
        "price": 2.17
    }, {
        "symbol": "IMO.TO",
        "label": "Imperial Oil Limited",
        "price": 35.45
    }, {
        "symbol": "INC-UN.TO",
        "label": "Income Financial Trust",
        "price": 9.05
    }, {
        "symbol": "IDG.TO",
        "label": "Indigo Books & Music Inc.",
        "price": 3.88
    }, {
        "symbol": "IAG.TO",
        "label": "iA Financial Corporation Inc.",
        "price": 73.76
    }, {
        "symbol": "ISV.TO",
        "label": "Information Services Corporation",
        "price": 14.69
    }, {
        "symbol": "INE.TO",
        "label": "Innergex Renewable Energy Inc.",
        "price": 18.22
    }, {
        "symbol": "INO-UN.TO",
        "label": "Inovalis Real Estate Investment Trust",
        "price": 10.88
    }, {
        "symbol": "IPO.TO",
        "label": "InPlay Oil Corp.",
        "price": 0.62
    }, {
        "symbol": "INQ.TO",
        "label": "Inscape Corporation",
        "price": 1.19
    }, {
        "symbol": "IFC.TO",
        "label": "Intact Financial Corporation",
        "price": 143.54
    }, {
        "symbol": "IPL.TO",
        "label": "Inter Pipeline Ltd.",
        "price": 22.52
    }, {
        "symbol": "IFP.TO",
        "label": "Interfor Corporation",
        "price": 15.74
    }, {
        "symbol": "IMP.TO",
        "label": "Intermap Technologies Corporation",
        "price": 0.31
    }, {
        "symbol": "ITH.TO",
        "label": "International Tower Hill Mines Ltd.",
        "price": 0.69
    }, {
        "symbol": "IIP-UN.TO",
        "label": "InterRent Real Estate Investment Trust",
        "price": 16.51
    }, {
        "symbol": "ITP.TO",
        "label": "Intertape Polymer Group Inc.",
        "price": 16.8
    }, {
        "symbol": "ITC.TO",
        "label": "Intrinsyc Technologies Corporation",
        "price": 1.72
    }, {
        "symbol": "INV.TO",
        "label": "INV Metals Inc.",
        "price": 0.355
    }, {
        "symbol": "CBH.TO",
        "label": "iShares 1-10 Year Laddered Corporate Bond Index ETF Common Class",
        "price": 18.88
    }, {
        "symbol": "CLG.TO",
        "label": "iShares 1-10 Year Laddered Government Bond Index ETF Common Class",
        "price": 18.47
    }, {
        "symbol": "CBO.TO",
        "label": "iShares 1-5 Year Laddered Corporate Bond Index ETF Common Class",
        "price": 18.65
    }, {
        "symbol": "CLF.TO",
        "label": "iShares 1-5 Year Laddered Government Bond Index ETF Common Class",
        "price": 17.82
    }, {
        "symbol": "XCB.TO",
        "label": "iShares Canadian Corporate Bond Index ETF",
        "price": 21.87
    }, {
        "symbol": "FIE.TO",
        "label": "iShares Canadian Financial Monthly Income ETF Common Class",
        "price": 7.41
    }, {
        "symbol": "XGB.TO",
        "label": "iShares Canadian Government Bond Index ETF",
        "price": 22.2
    }, {
        "symbol": "XCG.TO",
        "label": "iShares Canadian Growth Index ETF",
        "price": 38.18
    }, {
        "symbol": "XHB.TO",
        "label": "iShares Canadian HYBrid Corporate Bond Index ETF",
        "price": 21.09
    }, {
        "symbol": "XRB.TO",
        "label": "iShares Canadian Real Return Bond Index ETF",
        "price": 25.19
    }, {
        "symbol": "XDV.TO",
        "label": "iShares Canadian Select Dividend Index ETF",
        "price": 26.32
    }, {
        "symbol": "XSB.TO",
        "label": "iShares Core Canadian Short Term Bond Index ETF",
        "price": 27.51
    }, {
        "symbol": "XBB.TO",
        "label": "iShares Core Canadian Universe Bond Index ETF",
        "price": 31.97
    }, {
        "symbol": "XCV.TO",
        "label": "iShares Canadian Value Index ETF",
        "price": 26.95
    }, {
        "symbol": "XCH.TO",
        "label": "iShares China Index ETF",
        "price": 28.19
    }, {
        "symbol": "XSC.TO",
        "label": "iShares Conservative Short Term Strategic Fixed Income ETF",
        "price": 19.94
    }, {
        "symbol": "XSE.TO",
        "label": "iShares Conservative Strategic Fixed Income ETF",
        "price": 20.72
    }, {
        "symbol": "CVD.TO",
        "label": "iShares Convertible Bond Index ETF Common",
        "price": 18.43
    }, {
        "symbol": "XLB.TO",
        "label": "iShares Core Canadian Long Term Bond Index ETF",
        "price": 25.81
    }, {
        "symbol": "XSH.TO",
        "label": "iShares Core Canadian Short Term Corporate + Maple Bond Index ETF",
        "price": 19.34
    }, {
        "symbol": "XQB.TO",
        "label": "iShares High Quality Canadian Bond Index ETF Common Class",
        "price": 21.11
    }, {
        "symbol": "XAW.TO",
        "label": "iShares Core MSCI All Country World ex Canada Index ETF",
        "price": 28.7
    }, {
        "symbol": "XFH.TO",
        "label": "iShares Core MSCI EAFE IMI Index ETF (CAD-Hedged)",
        "price": 24.84
    }, {
        "symbol": "XEC.TO",
        "label": "iShares Core MSCI Emerging Markets IMI Index ETF",
        "price": 27.93
    }, {
        "symbol": "XSP.TO",
        "label": "iShares Core S&amp;P 500 Index ETF (CAD-Hedged)",
        "price": 36.67
    }, {
        "symbol": "XUU.TO",
        "label": "iShares Core S&amp;P U.S. Total Market Index ETF",
        "price": 32.89
    }, {
        "symbol": "XUH.TO",
        "label": "iShares Core S&amp;P U.S. Total Market Index ETF (CAD-Hedged)",
        "price": 29.9
    }, {
        "symbol": "XIC.TO",
        "label": "iShares Core S&amp;P/TSX Capped Composite Index ETF",
        "price": 27.95
    }, {
        "symbol": "XEI.TO",
        "label": "iShares S&amp;P/TSX Composite High Dividend Index ETF",
        "price": 22.88
    }, {
        "symbol": "XSQ.TO",
        "label": "iShares Short Term High Quality Canadian Bond Index ETF",
        "price": 19.49
    }, {
        "symbol": "XTR.TO",
        "label": "iShares Diversified Monthly Income ETF",
        "price": 11.32
    }, {
        "symbol": "XMV.TO",
        "label": "iShares Edge MSCI Min Vol Canada Index ETF",
        "price": 33.24
    }, {
        "symbol": "XMI.TO",
        "label": "iShares Edge MSCI Min Vol EAFE Index ETF",
        "price": 37.79
    }, {
        "symbol": "XML.TO",
        "label": "iShares Edge MSCI Min Vol EAFE Index ETF (CAD-Hedged)",
        "price": 24.77
    }, {
        "symbol": "XMM.TO",
        "label": "iShares Edge MSCI Min Vol Emerging Markets Index ETF",
        "price": 28.02
    }, {
        "symbol": "XMW.TO",
        "label": "iShares Edge MSCI Min Vol Global Index ETF",
        "price": 45.62
    }, {
        "symbol": "XMY.TO",
        "label": "iShares Edge MSCI Min Vol Global Index ETF (CAD-Hedged)",
        "price": 26.62
    }, {
        "symbol": "XMU.TO",
        "label": "iShares Edge MSCI Min Vol USA Index ETF",
        "price": 59.94
    }, {
        "symbol": "XMS.TO",
        "label": "iShares Edge MSCI Min Vol USA Index ETF (CAD-Hedged)",
        "price": 29.86
    }, {
        "symbol": "XFC.TO",
        "label": "iShares Edge MSCI Multifactor Canada Index ETF",
        "price": 25.31
    }, {
        "symbol": "XFI.TO",
        "label": "iShares Edge MSCI Multifactor EAFE Index ETF",
        "price": 24.01
    }, {
        "symbol": "XFF.TO",
        "label": "iShares Edge MSCI Multifactor EAFE Index ETF (CAD-Hedged)",
        "price": 25.91
    }, {
        "symbol": "XFS.TO",
        "label": "iShares Edge MSCI Multifactor USA Index ETF",
        "price": 29.78
    }, {
        "symbol": "XFA.TO",
        "label": "iShares Edge MSCI Multifactor USA Index ETF (CAD-Hedged)",
        "price": 29.45
    }, {
        "symbol": "CEW.TO",
        "label": "iShares Equal Weight Banc &amp; Lifeco ETF Common Class",
        "price": 13.89
    }, {
        "symbol": "XFR.TO",
        "label": "iShares Floating Rate Index ETF",
        "price": 20.14
    }, {
        "symbol": "COW.TO",
        "label": "iShares Global Agriculture Index ETF Common Class",
        "price": 43.16
    }, {
        "symbol": "XHC.TO",
        "label": "iShares Global Healthcare Index ETF (CAD-Hedged)",
        "price": 55.75
    }, {
        "symbol": "CIF.TO",
        "label": "iShares Global Infrastructure Index ETF Common Class",
        "price": 29.34
    }, {
        "symbol": "CYH.TO",
        "label": "iShares Global Monthly Dividend Index ETF (CAD-Hedged) Common Class",
        "price": 21.3
    }, {
        "symbol": "CGR.TO",
        "label": "iShares Global Real Estate Index ETF Common Class",
        "price": 32.32
    }, {
        "symbol": "CWW.TO",
        "label": "iShares Global Water Index ETF Common Class",
        "price": 41.62
    }, {
        "symbol": "CGL.TO",
        "label": "iShares Gold Bullion ETF (CAD-Hedged)",
        "price": 13.08
    }, {
        "symbol": "XID.TO",
        "label": "iShares India Index ETF",
        "price": 39.32
    }, {
        "symbol": "XEB.TO",
        "label": "iShares J.P. Morgan USD Emerging Markets Bond Index ETF (CAD-Hedged)",
        "price": 21.24
    }, {
        "symbol": "XEN.TO",
        "label": "iShares Jantzi Social Index ETF",
        "price": 26.47
    }, {
        "symbol": "XIN.TO",
        "label": "iShares MSCI EAFE Index ETF (CAD-Hedged)",
        "price": 28.21
    }, {
        "symbol": "XEM.TO",
        "label": "iShares MSCI Emerging Markets Index ETF",
        "price": 33.56
    }, {
        "symbol": "XEU.TO",
        "label": "iShares MSCI Europe IMI Index ETF",
        "price": 25.05
    }, {
        "symbol": "XEH.TO",
        "label": "iShares MSCI Europe IMI Index ETF (CAD-Hedged)",
        "price": 27.04
    }, {
        "symbol": "XWD.TO",
        "label": "iShares MSCI World Index ETF",
        "price": 57.13
    }, {
        "symbol": "XQQ.TO",
        "label": "iShares NASDAQ 100 Index ETF (CAD-Hedged)",
        "price": 73.28
    }, {
        "symbol": "CMR.TO",
        "label": "iShares Premium Money Market ETF Common Class",
        "price": 50.04
    }, {
        "symbol": "XCD.TO",
        "label": "iShares S&amp;P Global Consumer Discretionary Index ETF (CAD-Hedged)",
        "price": 40.38
    }, {
        "symbol": "XGI.TO",
        "label": "iShares S&amp;P Global Industrials Index ETF (CAD-Hedged)",
        "price": 35.98
    }, {
        "symbol": "XMC.TO",
        "label": "iShares S&amp;P U.S. Mid-Cap Index ETF",
        "price": 20.91
    }, {
        "symbol": "XMH.TO",
        "label": "iShares S&amp;P U.S. Mid-Cap Index ETF (CAD-Hedged)",
        "price": 20.07
    }, {
        "symbol": "XIU.TO",
        "label": "iShares S&amp;P/TSX 60 Index ETF",
        "price": 26.37
    }, {
        "symbol": "CDZ.TO",
        "label": "iShares S&amp;P/TSX Canadian Dividend Aristocrats Index ETF Common Class",
        "price": 29.38
    }, {
        "symbol": "CPD.TO",
        "label": "iShares S&amp;P/TSX Canadian Preferred Share Index ETF Common Class",
        "price": 12.46
    }, {
        "symbol": "XST.TO",
        "label": "iShares S&amp;P/TSX Capped Consumer Staples Index ETF",
        "price": 64.78
    }, {
        "symbol": "XEG.TO",
        "label": "iShares S&amp;P/TSX Capped Energy Index ETF",
        "price": 9.16
    }, {
        "symbol": "XFN.TO",
        "label": "iShares S&amp;P/TSX Capped Financials Index ETF",
        "price": 40.89
    }, {
        "symbol": "XIT.TO",
        "label": "iShares S&amp;P/TSX Capped Information Technology Index ETF",
        "price": 31.0
    }, {
        "symbol": "XMA.TO",
        "label": "iShares S&amp;P/TSX Capped Materials Index ETF",
        "price": 14.52
    }, {
        "symbol": "XRE.TO",
        "label": "iShares S&amp;P/TSX Capped REIT Index ETF",
        "price": 20.26
    }, {
        "symbol": "XUT.TO",
        "label": "iShares S&amp;P/TSX Capped Utilities Index ETF",
        "price": 27.76
    }, {
        "symbol": "XMD.TO",
        "label": "iShares S&amp;P/TSX Completion Index ETF",
        "price": 27.58
    }, {
        "symbol": "XBM.TO",
        "label": "iShares S&amp;P/TSX Global Base Metals Index ETF",
        "price": 11.52
    }, {
        "symbol": "XGD.TO",
        "label": "iShares S&amp;P/TSX Global Gold Index ETF",
        "price": 15.93
    }, {
        "symbol": "XPF.TO",
        "label": "iShares S&amp;P/TSX North American Preferred Stock Index ETF (CAD-Hedged)",
        "price": 17.11
    }, {
        "symbol": "XCS.TO",
        "label": "iShares S&amp;P/TSX Small Cap Index ETF",
        "price": 15.44
    }, {
        "symbol": "CSD.TO",
        "label": "iShares Short Duration High Income ETF (CAD-Hedged) Common Class",
        "price": 17.71
    }, {
        "symbol": "XSI.TO",
        "label": "iShares Short Term Strategic Fixed Income ETF",
        "price": 19.11
    }, {
        "symbol": "SVR.TO",
        "label": "iShares Silver Bullion ETF (CAD-Hedged)",
        "price": 9.54
    }, {
        "symbol": "XHU.TO",
        "label": "iShares U.S. High Dividend Equity Index ETF",
        "price": 26.4
    }, {
        "symbol": "XHD.TO",
        "label": "iShares U.S. High Dividend Equity Index ETF (CAD-Hedged)",
        "price": 30.94
    }, {
        "symbol": "XHY.TO",
        "label": "iShares U.S. High Yield Bond Index ETF (CAD-Hedged)",
        "price": 19.43
    }, {
        "symbol": "CHB.TO",
        "label": "iShares U.S. High Yield Fixed Income Index ETF (CADHedged) Common Class",
        "price": 18.7
    }, {
        "symbol": "XIG.TO",
        "label": "iShares U.S. IG Corporate Bond Index ETF (CAD-Hedged)",
        "price": 24.63
    }, {
        "symbol": "XSU.TO",
        "label": "iShares U.S. Small Cap Index ETF (CAD-Hedged)",
        "price": 35.1
    }, {
        "symbol": "CUD.TO",
        "label": "iShares US Dividend Growers Index ETF (CAD-Hedged) Common Class",
        "price": 46.15
    }, {
        "symbol": "IVN.TO",
        "label": "Ivanhoe Mines Ltd.",
        "price": 4.03
    }, {
        "symbol": "JAG.TO",
        "label": "Jaguar Mining Inc.",
        "price": 0.235
    }, {
        "symbol": "JFS-UN.TO",
        "label": "JFT Strategies Fund",
        "price": 14.43
    }, {
        "symbol": "JOY.TO",
        "label": "Journey Energy Inc.",
        "price": 1.35
    }, {
        "symbol": "CBT-UN.TO",
        "label": "June 2020 Corporate Bond Trust",
        "price": 9.21
    }, {
        "symbol": "JE.TO",
        "label": "Just Energy Group Inc.",
        "price": 2.12
    }, {
        "symbol": "KBL.TO",
        "label": "K-Bro Linen Inc.",
        "price": 41.64
    }, {
        "symbol": "KRN.TO",
        "label": "Karnalyte Resources Inc.",
        "price": 0.19
    }, {
        "symbol": "KAT.TO",
        "label": "Katanga Mining Limited",
        "price": 0.115
    }, {
        "symbol": "KEG-UN.TO",
        "label": "The Keg Royalties Income Fund",
        "price": 15.7
    }, {
        "symbol": "KLS.TO",
        "label": "Kelso Technologies Inc.",
        "price": 0.94
    }, {
        "symbol": "KEL.TO",
        "label": "Kelt Exploration Ltd.",
        "price": 4.26
    }, {
        "symbol": "KER.TO",
        "label": "Kerr Mines Inc.",
        "price": 0.15
    }, {
        "symbol": "KEY.TO",
        "label": "Keyera Corp.",
        "price": 34.84
    }, {
        "symbol": "KMP-UN.TO",
        "label": "Killam Apartment Real Estate Investment Trust",
        "price": 19.75
    }, {
        "symbol": "KXS.TO",
        "label": "Kinaxis Inc.",
        "price": 107.05
    }, {
        "symbol": "K.TO",
        "label": "Kinross Gold Corporation",
        "price": 5.89
    }, {
        "symbol": "GUD.TO",
        "label": "Knight Therapeutics Inc.",
        "price": 8.05
    }, {
        "symbol": "KPT.TO",
        "label": "KP Tissue Inc.",
        "price": 9.83
    }, {
        "symbol": "LIF.TO",
        "label": "Labrador Iron Ore Royalty Corporation",
        "price": 23.61
    }, {
        "symbol": "LAM.TO",
        "label": "Laramide Resources Ltd.",
        "price": 0.195
    }, {
        "symbol": "LGO.TO",
        "label": "Largo Resources Ltd.",
        "price": 1.05
    }, {
        "symbol": "LAS-A.TO",
        "label": "Lassonde Industries Inc.",
        "price": 154.69
    }, {
        "symbol": "LB.TO",
        "label": "Laurentian Bank of Canada",
        "price": 44.64
    }, {
        "symbol": "LNF.TO",
        "label": "Leon's Furniture Limited",
        "price": 16.9
    }, {
        "symbol": "LBS.TO",
        "label": "Life & Banc Split Corp.",
        "price": 8.38
    }, {
        "symbol": "LNR.TO",
        "label": "Linamar Corporation",
        "price": 47.99
    }, {
        "symbol": "LAC.TO",
        "label": "Lithium Americas Corp.",
        "price": 5.44
    }, {
        "symbol": "L.TO",
        "label": "Loblaw Companies Limited",
        "price": 68.38
    }, {
        "symbol": "LGT-B.TO",
        "label": "Logistec Corporation",
        "price": 38.75
    }, {
        "symbol": "LN.TO",
        "label": "Loncor Resources Inc.",
        "price": 0.415
    }, {
        "symbol": "LUC.TO",
        "label": "Lucara Diamond Corp.",
        "price": 0.87
    }, {
        "symbol": "LUG.TO",
        "label": "Lundin Gold Inc.",
        "price": 9.07
    }, {
        "symbol": "LUN.TO",
        "label": "Lundin Mining Corporation",
        "price": 7.84
    }, {
        "symbol": "LYD.TO",
        "label": "Lydian International Limited",
        "price": 0.075
    }, {
        "symbol": "PR.TO",
        "label": "Lysander-Slater Preferred Share ActivETF",
        "price": 9.0
    }, {
        "symbol": "XMF-A.TO",
        "label": "M Split Corp.",
        "price": 0.23
    }, {
        "symbol": "MKB.TO",
        "label": "Mackenzie Core Plus Canadian Fixed Income ETF",
        "price": 20.87
    }, {
        "symbol": "MGB.TO",
        "label": "Mackenzie Core Plus Global Fixed Income ETF",
        "price": 19.36
    }, {
        "symbol": "MFT.TO",
        "label": "Mackenzie Floating Rate Income ETF",
        "price": 20.4
    }, {
        "symbol": "MKZ-UN.TO",
        "label": "Mackenzie Master Limited Partnership",
        "price": 0.97
    }, {
        "symbol": "MXU.TO",
        "label": "Mackenzie Maximum Diversification All World Developed ex North America Index ETF",
        "price": 22.72
    }, {
        "symbol": "MWD.TO",
        "label": "Mackenzie Maximum Diversification All World Developed Index ETF",
        "price": 25.82
    }, {
        "symbol": "MKC.TO",
        "label": "Mackenzie Maximum Diversification Canada Index ETF",
        "price": 25.4
    }, {
        "symbol": "MEU.TO",
        "label": "Mackenzie Maximum Diversification Developed Markets Index ETF",
        "price": 22.6
    }, {
        "symbol": "MUS.TO",
        "label": "Mackenzie Maximum Diversification US Index ETF",
        "price": 29.54
    }, {
        "symbol": "MUB.TO",
        "label": "Mackenzie Unconstrained Bond ETF",
        "price": 20.72
    }, {
        "symbol": "MPC.TO",
        "label": "Madison Pacific Properties Inc.",
        "price": 3.62
    }, {
        "symbol": "MAG.TO",
        "label": "MAG Silver Corp.",
        "price": 13.8
    }, {
        "symbol": "MAL.TO",
        "label": "Magellan Aerospace Corporation",
        "price": 14.28
    }, {
        "symbol": "MG.TO",
        "label": "Magna International Inc.",
        "price": 71.64
    }, {
        "symbol": "MEQ.TO",
        "label": "Mainstreet Equity Corp.",
        "price": 75.24
    }, {
        "symbol": "MDI.TO",
        "label": "Major Drilling Group International Inc.",
        "price": 5.77
    }, {
        "symbol": "MND.TO",
        "label": "Mandalay Resources Corporation",
        "price": 1.24
    }, {
        "symbol": "MFC.TO",
        "label": "Manulife Financial Corporation",
        "price": 27.61
    }, {
        "symbol": "MFR-UN.TO",
        "label": "Manulife Floating Rate Senior Loan Fund",
        "price": 7.39
    }, {
        "symbol": "MBK-UN.TO",
        "label": "Manulife U.S. Regional Bank Trust",
        "price": 12.38
    }, {
        "symbol": "MFI.TO",
        "label": "Maple Leaf Foods Inc.",
        "price": 24.78
    }, {
        "symbol": "MOZ.TO",
        "label": "Marathon Gold Corporation",
        "price": 1.55
    }, {
        "symbol": "MRE.TO",
        "label": "Martinrea International Inc.",
        "price": 14.58
    }, {
        "symbol": "MAW.TO",
        "label": "Mawson Resources Limited",
        "price": 0.27
    }, {
        "symbol": "MXG.TO",
        "label": "Maxim Power Corp.",
        "price": 1.82
    }, {
        "symbol": "MBN.TO",
        "label": "MBN Corporation",
        "price": 6.5
    }, {
        "symbol": "MKP.TO",
        "label": "MCAN Mortgage Corporation",
        "price": 16.59
    }, {
        "symbol": "MCB.TO",
        "label": "McCoy Global Inc.",
        "price": 0.61
    }, {
        "symbol": "MUX.TO",
        "label": "McEwen Mining Inc.",
        "price": 1.53
    }, {
        "symbol": "MDF.TO",
        "label": "Mediagrif Interactive Technologies Inc.",
        "price": 6.73
    }, {
        "symbol": "DR.TO",
        "label": "Medical Facilities Corporation",
        "price": 4.83
    }, {
        "symbol": "MEG.TO",
        "label": "MEG Energy Corp.",
        "price": 7.59
    }, {
        "symbol": "MGA.TO",
        "label": "Mega Uranium Ltd.",
        "price": 0.085
    }, {
        "symbol": "MRD.TO",
        "label": "Melcor Developments Ltd.",
        "price": 13.01
    }, {
        "symbol": "MR-UN.TO",
        "label": "Melcor Real Estate Investment Trust",
        "price": 8.2
    }, {
        "symbol": "MX.TO",
        "label": "Methanex Corporation",
        "price": 52.79
    }, {
        "symbol": "MRU.TO",
        "label": "Metro Inc.",
        "price": 54.47
    }, {
        "symbol": "MBX.TO",
        "label": "Microbix Biosystems Inc.",
        "price": 0.22
    }, {
        "symbol": "MAX.TO",
        "label": "Midas Gold Corp.",
        "price": 0.63
    }, {
        "symbol": "RCO-UN.TO",
        "label": "Middlefield Can-Global REIT Income Fund",
        "price": 12.34
    }, {
        "symbol": "MSV.TO",
        "label": "Minco Silver Corporation",
        "price": 0.71
    }, {
        "symbol": "MID-UN.TO",
        "label": "MINT Income Fund",
        "price": 6.32
    }, {
        "symbol": "MOGO.TO",
        "label": "Mogo Inc.",
        "price": 3.63
    }, {
        "symbol": "TPX-B.TO",
        "label": "Molson Coors Canada Inc.",
        "price": 75.75
    }, {
        "symbol": "MLD-UN.TO",
        "label": "Moneda LatAm Corporate Bond Fund",
        "price": 9.46
    }, {
        "symbol": "ME.TO",
        "label": "Moneta Porcupine Mines Inc.",
        "price": 0.115
    }, {
        "symbol": "MRC.TO",
        "label": "Morguard Corporation",
        "price": 202.23
    }, {
        "symbol": "MRG-UN.TO",
        "label": "Morguard North American Residential Real Estate Investment Trust",
        "price": 19.03
    }, {
        "symbol": "MRT-UN.TO",
        "label": "Morguard Real Estate Investment Trust",
        "price": 11.82
    }, {
        "symbol": "MSI.TO",
        "label": "Morneau Shepell Inc.",
        "price": 34.41
    }, {
        "symbol": "MTY.TO",
        "label": "MTY Food Group Inc.",
        "price": 58.95
    }, {
        "symbol": "MTL.TO",
        "label": "Mullen Group Ltd.",
        "price": 9.53
    }, {
        "symbol": "NA.TO",
        "label": "National Bank of Canada",
        "price": 73.52
    }, {
        "symbol": "NMX.TO",
        "label": "Nemaska Lithium Inc.",
        "price": 0.165
    }, {
        "symbol": "NEPT.TO",
        "label": "Neptune Wellness Solutions Inc.",
        "price": 3.96
    }, {
        "symbol": "NCU.TO",
        "label": "Nevada Copper Corp.",
        "price": 0.36
    }, {
        "symbol": "NFI.TO",
        "label": "NFI Group Inc.",
        "price": 29.02
    }, {
        "symbol": "NGD.TO",
        "label": "New Gold Inc.",
        "price": 1.29
    }, {
        "symbol": "BCI.TO",
        "label": "New Look Vision Group Inc.",
        "price": 32.48
    }, {
        "symbol": "NML.TO",
        "label": "New Millennium Iron Corp",
        "price": 0.075
    }, {
        "symbol": "NXE.TO",
        "label": "NexGen Energy Ltd.",
        "price": 1.66
    }, {
        "symbol": "NXJ.TO",
        "label": "NexJ Systems Inc.",
        "price": 0.9
    }, {
        "symbol": "NB.TO",
        "label": "NioCorp Developments Ltd.",
        "price": 0.8
    }, {
        "symbol": "NIF-UN.TO",
        "label": "Noranda Income Fund",
        "price": 2.56
    }, {
        "symbol": "OSB.TO",
        "label": "Norbord Inc.",
        "price": 39.81
    }, {
        "symbol": "FFN.TO",
        "label": "North American Financial 15 Split Corp.",
        "price": 7.61
    }, {
        "symbol": "NWC.TO",
        "label": "The North West Company Inc.",
        "price": 28.05
    }, {
        "symbol": "NCF.TO",
        "label": "Northcliff Resources Ltd.",
        "price": 0.055
    }, {
        "symbol": "NDM.TO",
        "label": "Northern Dynasty Minerals Ltd.",
        "price": 0.54
    }, {
        "symbol": "NPI.TO",
        "label": "Northland Power Inc.",
        "price": 28.36
    }, {
        "symbol": "NVU-UN.TO",
        "label": "Northview Apartment Real Estate Investment Trust",
        "price": 31.16
    }, {
        "symbol": "NWH-UN.TO",
        "label": "NorthWest Healthcare Properties Real Estate Investment Trust",
        "price": 12.24
    }, {
        "symbol": "NG.TO",
        "label": "NovaGold Resources Inc.",
        "price": 11.45
    }, {
        "symbol": "NVA.TO",
        "label": "NuVista Energy Ltd.",
        "price": 2.87
    }, {
        "symbol": "NRI.TO",
        "label": "Nuvo Pharmaceuticals Inc.",
        "price": 0.53
    }, {
        "symbol": "SFD.TO",
        "label": "NXT Energy Solutions Inc.",
        "price": 0.47
    }, {
        "symbol": "OGC.TO",
        "label": "OceanaGold Corporation",
        "price": 2.45
    }, {
        "symbol": "OCS-UN.TO",
        "label": "OCP Credit Strategy Fund",
        "price": 5.34
    }, {
        "symbol": "OSL-UN.TO",
        "label": "OCP Senior Credit Fund",
        "price": 9.37
    }, {
        "symbol": "OLY.TO",
        "label": "Olympia Financial Group Inc.",
        "price": 50.22
    }, {
        "symbol": "ONC.TO",
        "label": "Oncolytics Biotech Inc.",
        "price": 4.05
    }, {
        "symbol": "ONEX.TO",
        "label": "Onex Corporation",
        "price": 88.2
    }, {
        "symbol": "OGD.TO",
        "label": "Orbit Garant Drilling Inc.",
        "price": 0.89
    }, {
        "symbol": "ORL.TO",
        "label": "Orocobre Limited",
        "price": 3.28
    }, {
        "symbol": "OMI.TO",
        "label": "Orosur Mining Inc.",
        "price": 0.05
    }, {
        "symbol": "ORV.TO",
        "label": "Orvana Minerals Corp.",
        "price": 0.22
    }, {
        "symbol": "OXC.TO",
        "label": "Oryx Petroleum Corporation Limited",
        "price": 0.23
    }, {
        "symbol": "OR.TO",
        "label": "Osisko Gold Royalties Ltd",
        "price": 12.4
    }, {
        "symbol": "OSK.TO",
        "label": "Osisko Mining Inc.",
        "price": 3.89
    }, {
        "symbol": "POU.TO",
        "label": "Paramount Resources Ltd.",
        "price": 7.44
    }, {
        "symbol": "PXT.TO",
        "label": "Parex Resources Inc.",
        "price": 23.39
    }, {
        "symbol": "PLC.TO",
        "label": "Park Lawn Corporation",
        "price": 30.15
    }, {
        "symbol": "PKI.TO",
        "label": "Parkland Fuel Corporation",
        "price": 48.56
    }, {
        "symbol": "PSI.TO",
        "label": "Pason Systems Inc.",
        "price": 13.23
    }, {
        "symbol": "PCD-UN.TO",
        "label": "Pathfinder Income Fund",
        "price": 8.07
    }, {
        "symbol": "PPL.TO",
        "label": "Pembina Pipeline Corporation",
        "price": 50.42
    }, {
        "symbol": "PGF.TO",
        "label": "Pengrowth Energy Corporation",
        "price": 0.055
    }, {
        "symbol": "PMT.TO",
        "label": "Perpetual Energy Inc.",
        "price": 0.16
    }, {
        "symbol": "PRU.TO",
        "label": "Perseus Mining Limited",
        "price": 0.95
    }, {
        "symbol": "PGLC.TO",
        "label": "Pershing Gold Corporation",
        "price": 1.68
    }, {
        "symbol": "PRQ.TO",
        "label": "Petrus Resources Ltd.",
        "price": 0.27
    }, {
        "symbol": "PEY.TO",
        "label": "Peyto Exploration & Development Corp.",
        "price": 3.4
    }, {
        "symbol": "PFB.TO",
        "label": "PFB Corporation",
        "price": 12.96
    }, {
        "symbol": "PHX.TO",
        "label": "PHX Energy Services Corp.",
        "price": 2.76
    }, {
        "symbol": "PMB-UN.TO",
        "label": "Picton Mahoney Tactical Income Fund",
        "price": 7.78
    }, {
        "symbol": "PGI-UN.TO",
        "label": "PIMCO Global Income Opportunities Fund",
        "price": 10.87
    }, {
        "symbol": "PNE.TO",
        "label": "Pine Cliff Energy Ltd.",
        "price": 0.155
    }, {
        "symbol": "PNP.TO",
        "label": "Pinetree Capital Ltd.",
        "price": 1.48
    }, {
        "symbol": "PZA.TO",
        "label": "Pizza Pizza Royalty Corp.",
        "price": 9.97
    }, {
        "symbol": "PTM.TO",
        "label": "Platinum Group Metals Ltd.",
        "price": 3.3
    }, {
        "symbol": "PLZ-UN.TO",
        "label": "Plaza Retail REIT",
        "price": 4.69
    }, {
        "symbol": "PTS.TO",
        "label": "Points International Ltd.",
        "price": 19.9
    }, {
        "symbol": "PIF.TO",
        "label": "Polaris Infrastructure Inc.",
        "price": 13.86
    }, {
        "symbol": "PBL.TO",
        "label": "Pollard Banknote Limited",
        "price": 20.44
    }, {
        "symbol": "POM.TO",
        "label": "PolyMet Mining Corp.",
        "price": 0.39
    }, {
        "symbol": "PAY.TO",
        "label": "Posera Ltd.",
        "price": 0.12
    }, {
        "symbol": "PNC-B.TO",
        "label": "Postmedia Network Canada Corp.",
        "price": 1.25
    }, {
        "symbol": "POW.TO",
        "label": "Power Corporation of Canada",
        "price": 33.54
    }, {
        "symbol": "PWF.TO",
        "label": "Power Financial Corporation",
        "price": 34.79
    }, {
        "symbol": "PFL.TO",
        "label": "Invesco 1-3 Year Laddered Floating Rate Note Index ETF",
        "price": 19.83
    }, {
        "symbol": "PSB.TO",
        "label": "Invesco 1-5 Year Laddered Investment Grade Corporate Bond Index ETF",
        "price": 18.11
    }, {
        "symbol": "PDC.TO",
        "label": "Invesco Canadian Dividend Index ETF",
        "price": 29.59
    }, {
        "symbol": "PPS.TO",
        "label": "Invesco Canadian Preferred Share Index ETF",
        "price": 14.34
    }, {
        "symbol": "PXC.TO",
        "label": "Invesco FTSE RAFI Canadian Index ETF",
        "price": 28.52
    }, {
        "symbol": "PZC.TO",
        "label": "Invesco FTSE RAFI Canadian Small-Mid Index ETF",
        "price": 20.67
    }, {
        "symbol": "PZW.TO",
        "label": "Invesco FTSE RAFI Global Small-Mid ETF CAD",
        "price": 26.25
    }, {
        "symbol": "PXG.TO",
        "label": "Invesco FTSE RAFI Global+ Index ETF CAD",
        "price": 24.42
    }, {
        "symbol": "PXS.TO",
        "label": "Invesco FTSE RAFI U.S. Index ETF II CAD",
        "price": 28.98
    }, {
        "symbol": "PSY.TO",
        "label": "Invesco Global Shareholder Yield ETF CAD",
        "price": 26.49
    }, {
        "symbol": "USB.TO",
        "label": "Invesco LadderRite U.S. 0-5 Year Corporate Bond Index ETF CAD",
        "price": 22.81
    }, {
        "symbol": "PLV.TO",
        "label": "Invesco Low Volatility Portfolio ETF",
        "price": 23.8
    }, {
        "symbol": "ELV.TO",
        "label": "Invesco S&amp;P Emerging Markets Low Volatility Index ETF",
        "price": 19.34
    }, {
        "symbol": "ILV.TO",
        "label": "Invesco S&amp;P International Developed Low Volatility Index ETF",
        "price": 25.0
    }, {
        "symbol": "TLV.TO",
        "label": "Invesco S&amp;P/TSX Composite Low Volatility Index ETF",
        "price": 31.3
    }, {
        "symbol": "PTB.TO",
        "label": "Invesco Tactical Bond ETF",
        "price": 19.26
    }, {
        "symbol": "PPR.TO",
        "label": "Prairie Provident Resources Inc.",
        "price": 0.045
    }, {
        "symbol": "PSK.TO",
        "label": "PrairieSky Royalty Ltd.",
        "price": 15.58
    }, {
        "symbol": "MMP-UN.TO",
        "label": "Precious Metals and Mining Trust",
        "price": 1.53
    }, {
        "symbol": "PD.TO",
        "label": "Precision Drilling Corporation",
        "price": 1.95
    }, {
        "symbol": "PG.TO",
        "label": "Premier Gold Mines Limited",
        "price": 1.72
    }, {
        "symbol": "PBH.TO",
        "label": "Premium Brands Holdings Corporation",
        "price": 93.77
    }, {
        "symbol": "PIC-A.TO",
        "label": "Premium Income Corporation",
        "price": 6.44
    }, {
        "symbol": "PVG.TO",
        "label": "Pretium Resources Inc.",
        "price": 13.69
    }, {
        "symbol": "PDV.TO",
        "label": "Prime Dividend Corp.",
        "price": 7.32
    }, {
        "symbol": "PMN.TO",
        "label": "ProMIS Neurosciences, Inc.",
        "price": 0.23
    }, {
        "symbol": "PCY.TO",
        "label": "Prophecy Development Corp.",
        "price": 0.36
    }, {
        "symbol": "PSD.TO",
        "label": "Pulse Seismic Inc.",
        "price": 1.9
    }, {
        "symbol": "PBI.TO",
        "label": "Purpose Best Ideas Fund Series ETF",
        "price": 33.76
    }, {
        "symbol": "BNC.TO",
        "label": "Purpose Canadian Financial Income Fund Series ETF",
        "price": 23.33
    }, {
        "symbol": "PRP.TO",
        "label": "Purpose Conservative Income Fund Series ETF",
        "price": 20.9
    }, {
        "symbol": "PDF.TO",
        "label": "Purpose Core Dividend Fund Series ETF",
        "price": 28.31
    }, {
        "symbol": "PRA.TO",
        "label": "Purpose Diversified Real Asset Fund Series ETF",
        "price": 19.16
    }, {
        "symbol": "PHR.TO",
        "label": "Purpose Duration Hedged Real Estate Fund Series ETF",
        "price": 22.26
    }, {
        "symbol": "PEU.TO",
        "label": "Purpose Enhanced US Equity Fund Series ETF",
        "price": 27.3
    }, {
        "symbol": "PSA.TO",
        "label": "Purpose High Interest Savings ETF",
        "price": 50.055
    }, {
        "symbol": "PID.TO",
        "label": "Purpose International Dividend Fund Series ETF",
        "price": 22.39
    }, {
        "symbol": "PHW.TO",
        "label": "Purpose International Tactical Hedged Equity Fd Ser ETF",
        "price": 19.15
    }, {
        "symbol": "PIN.TO",
        "label": "Purpose Monthly Income Fund Series ETF",
        "price": 18.4
    }, {
        "symbol": "PMM.TO",
        "label": "Purpose Multi-Strategy Market Neutral Fund Series ETF",
        "price": 22.47
    }, {
        "symbol": "PYF.TO",
        "label": "Purpose Premium Yield Fund Series ETF",
        "price": 18.89
    }, {
        "symbol": "PHE.TO",
        "label": "Purpose Tactical Hedged Equity Fund Series ETF",
        "price": 26.06
    }, {
        "symbol": "BND.TO",
        "label": "Purpose Global Bond Fund Series ETF",
        "price": 18.98
    }, {
        "symbol": "PBD.TO",
        "label": "Purpose Total Return Bond Fund Series ETF",
        "price": 18.38
    }, {
        "symbol": "PSU-U.TO",
        "label": "Purpose US Cash ETF",
        "price": 100.09
    }, {
        "symbol": "PUD.TO",
        "label": "Purpose US Dividend Fund Series ETF",
        "price": 23.81
    }, {
        "symbol": "QBR-B.TO",
        "label": "Quebecor Inc.",
        "price": 33.36
    }, {
        "symbol": "QEC.TO",
        "label": "Questerre Energy Corporation",
        "price": 0.18
    }, {
        "symbol": "RAV-UN.TO",
        "label": "Ravensource Fund",
        "price": 17.85
    }, {
        "symbol": "RLB.TO",
        "label": "RBC 1-5 Year Laddered Canadian Bond ETF",
        "price": 19.32
    }, {
        "symbol": "RBO.TO",
        "label": "RBC 1-5 Year Laddered Corporate Bond ETF",
        "price": 19.15
    }, {
        "symbol": "RPF.TO",
        "label": "RBC Canadian Preferred Share ETF",
        "price": 20.42
    }, {
        "symbol": "RCD.TO",
        "label": "RBC Quant Canadian Dividend Leaders ETF",
        "price": 21.83
    }, {
        "symbol": "RCE.TO",
        "label": "RBC Quant Canadian Equity Leaders ETF",
        "price": 23.23
    }, {
        "symbol": "RID.TO",
        "label": "RBC Quant EAFE Dividend Leaders ETF (CAD)",
        "price": 23.93
    }, {
        "symbol": "RIE.TO",
        "label": "RBC Quant EAFE Equity Leaders ETF (CAD)",
        "price": 22.42
    }, {
        "symbol": "RXD.TO",
        "label": "RBC Quant Emerging Markets Dividend Leaders ETF",
        "price": 21.55
    }, {
        "symbol": "RPD.TO",
        "label": "RBC Quant European Dividend Leaders ETF",
        "price": 22.1
    }, {
        "symbol": "RUD.TO",
        "label": "RBC Quant U.S. Dividend Leaders ETF (CAD)",
        "price": 38.79
    }, {
        "symbol": "RUE.TO",
        "label": "RBC Quant US Equity Leaders ETF (CAD)",
        "price": 31.68
    }, {
        "symbol": "RLD.TO",
        "label": "RBC Strategic Global Dividend Leaders ETF",
        "price": 26.01
    }, {
        "symbol": "RLE.TO",
        "label": "RBC Strategic Global Equity Leaders ETF",
        "price": 27.7
    }, {
        "symbol": "RQG.TO",
        "label": "RBC Target 2019 Corporate Bond Index ETF",
        "price": 18.48
    }, {
        "symbol": "RQH.TO",
        "label": "RBC Target 2020 Corporate Bond Index ETF",
        "price": 19.75
    }, {
        "symbol": "RQI.TO",
        "label": "RBC Target 2021 Corporate Bond Index ETF",
        "price": 19.81
    }, {
        "symbol": "RQJ.TO",
        "label": "RBC Target 2022 Corporate Bond Index ETF",
        "price": 19.71
    }, {
        "symbol": "RQK.TO",
        "label": "RBC Target 2023 Corporate Bond Index ETF",
        "price": 19.5
    }, {
        "symbol": "RDL.TO",
        "label": "Redline Communications Group Inc.",
        "price": 1.19
    }, {
        "symbol": "RET-A.TO",
        "label": "Reitmans (Canada) Limited",
        "price": 1.14
    }, {
        "symbol": "RFP.TO",
        "label": "Resolute Forest Products Inc.",
        "price": 5.86
    }, {
        "symbol": "QSR.TO",
        "label": "Restaurant Brands International Inc.",
        "price": 83.78
    }, {
        "symbol": "QSP-UN.TO",
        "label": "Restaurant Brands International Limited Partnership",
        "price": 83.78
    }, {
        "symbol": "RVX.TO",
        "label": "Resverlogix Corp.",
        "price": 1.21
    }, {
        "symbol": "RPI-UN.TO",
        "label": "Richards Packaging Income Fund",
        "price": 48.54
    }, {
        "symbol": "RCH.TO",
        "label": "Richelieu Hardware Ltd.",
        "price": 28.9
    }, {
        "symbol": "RIB-UN.TO",
        "label": "Ridgewood Canadian Investment Grade Bond Fund",
        "price": 16.5
    }, {
        "symbol": "REI-UN.TO",
        "label": "RioCan Real Estate Investment Trust",
        "price": 27.43
    }, {
        "symbol": "RBA.TO",
        "label": "Ritchie Bros. Auctioneers Incorporated",
        "price": 57.94
    }, {
        "symbol": "RME.TO",
        "label": "Rocky Mountain Dealerships Inc.",
        "price": 6.9
    }, {
        "symbol": "RCI-B.TO",
        "label": "Rogers Communications Inc.",
        "price": 64.94
    }, {
        "symbol": "RSI.TO",
        "label": "Rogers Sugar Inc.",
        "price": 4.91
    }, {
        "symbol": "RY.TO",
        "label": "Royal Bank of Canada",
        "price": 106.55
    }, {
        "symbol": "RNX.TO",
        "label": "Royal Nickel Corporation",
        "price": 0.46
    }, {
        "symbol": "RTG.TO",
        "label": "RTG Mining Inc.",
        "price": 0.085
    }, {
        "symbol": "RMX.TO",
        "label": "Rubicon Minerals Corporation",
        "price": 1.1
    }, {
        "symbol": "RUS.TO",
        "label": "Russel Metals Inc.",
        "price": 22.68
    }, {
        "symbol": "SBN.TO",
        "label": "Mulvihill S Split Corp.",
        "price": 5.41
    }, {
        "symbol": "SBB.TO",
        "label": "Sabina Gold & Silver Corp.",
        "price": 1.79
    }, {
        "symbol": "SSL.TO",
        "label": "Sandstorm Gold Ltd.",
        "price": 9.04
    }, {
        "symbol": "SAP.TO",
        "label": "Saputo Inc.",
        "price": 39.88
    }, {
        "symbol": "SIS.TO",
        "label": "Savaria Corporation",
        "price": 14.0
    }, {
        "symbol": "SCY.TO",
        "label": "Scandium International Mining Corp.",
        "price": 0.085
    }, {
        "symbol": "SEA.TO",
        "label": "Seabridge Gold Inc.",
        "price": 17.38
    }, {
        "symbol": "SCU.TO",
        "label": "The Second Cup Ltd",
        "price": 1.74
    }, {
        "symbol": "SES.TO",
        "label": "Secure Energy Services Inc.",
        "price": 4.94
    }, {
        "symbol": "SMF.TO",
        "label": "SEMAFO Inc.",
        "price": 2.64
    }, {
        "symbol": "FRL-UN.TO",
        "label": "Crescent Senior Secured Floating Rate Loan Fund, LLC",
        "price": 8.13
    }, {
        "symbol": "PME.TO",
        "label": "Sentry Select Primary Metals Corp.",
        "price": 2.02
    }, {
        "symbol": "SEC.TO",
        "label": "Senvest Capital Inc.",
        "price": 180.0
    }, {
        "symbol": "SBI.TO",
        "label": "Serabi Gold plc",
        "price": 1.45
    }, {
        "symbol": "VII.TO",
        "label": "Seven Generations Energy Ltd.",
        "price": 7.92
    }, {
        "symbol": "SJR-B.TO",
        "label": "Shaw Communications Inc.",
        "price": 26.69
    }, {
        "symbol": "SCL.TO",
        "label": "Shawcor Ltd.",
        "price": 11.68
    }, {
        "symbol": "S.TO",
        "label": "Sherritt International Corporation",
        "price": 0.2
    }, {
        "symbol": "SHOP.TO",
        "label": "Shopify Inc.",
        "price": 594.57
    }, {
        "symbol": "SIA.TO",
        "label": "Sienna Senior Living Inc.",
        "price": 19.1
    }, {
        "symbol": "SMT.TO",
        "label": "Sierra Metals Inc.",
        "price": 2.23
    }, {
        "symbol": "SW.TO",
        "label": "Sierra Wireless, Inc.",
        "price": 13.44
    }, {
        "symbol": "SBR.TO",
        "label": "Silver Bear Resources Plc",
        "price": 0.135
    }, {
        "symbol": "SVM.TO",
        "label": "Silvercorp Metals Inc.",
        "price": 7.13
    }, {
        "symbol": "SRV-UN.TO",
        "label": "SIR Royalty Income Fund",
        "price": 8.54
    }, {
        "symbol": "SOT-UN.TO",
        "label": "Slate Office REIT",
        "price": 5.88
    }, {
        "symbol": "SRT-UN.TO",
        "label": "Slate Retail REIT",
        "price": 13.39
    }, {
        "symbol": "ZZZ.TO",
        "label": "Sleep Country Canada Holdings Inc.",
        "price": 20.98
    }, {
        "symbol": "SRU-UN.TO",
        "label": "SmartCentres Real Estate Investment Trust",
        "price": 32.33
    }, {
        "symbol": "SNC.TO",
        "label": "SNC-Lavalin Group Inc.",
        "price": 31.06
    }, {
        "symbol": "SLR.TO",
        "label": "Solitario Zinc Corp.",
        "price": 0.375
    }, {
        "symbol": "SGQ.TO",
        "label": "SouthGobi Resources Ltd.",
        "price": 0.095
    }, {
        "symbol": "SEV.TO",
        "label": "Spectra7 Microsystems Inc.",
        "price": 0.025
    }, {
        "symbol": "EDT.TO",
        "label": "Spectral Medical Inc.",
        "price": 0.75
    }, {
        "symbol": "SHC.TO",
        "label": "Sphere FTSE Canada Sustainable Yield Index ETF",
        "price": 12.1
    }, {
        "symbol": "SHZ.TO",
        "label": "Sphere FTSE Emerging Markets Yield Index ETF",
        "price": 9.96
    }, {
        "symbol": "SHE.TO",
        "label": "Sphere FTSE Europe Sustainable Yield Index ETF",
        "price": 10.3
    }, {
        "symbol": "TOY.TO",
        "label": "Spin Master Corp.",
        "price": 37.92
    }, {
        "symbol": "SII.TO",
        "label": "Sprott Inc.",
        "price": 3.04
    }, {
        "symbol": "SAU.TO",
        "label": "St. Augustine Gold and Copper Limited",
        "price": 0.02
    }, {
        "symbol": "STN.TO",
        "label": "Stantec Inc.",
        "price": 38.98
    }, {
        "symbol": "SAM.TO",
        "label": "Starcore International Mines Ltd.",
        "price": 0.08
    }, {
        "symbol": "SJ.TO",
        "label": "Stella-Jones Inc.",
        "price": 38.25
    }, {
        "symbol": "RAY-B.TO",
        "label": "Stingray Group Inc.",
        "price": 6.76
    }, {
        "symbol": "SDY.TO",
        "label": "Strad Inc.",
        "price": 1.79
    }, {
        "symbol": "SQP.TO",
        "label": "Strongco Corporation",
        "price": 1.76
    }, {
        "symbol": "SOX.TO",
        "label": "Stuart Olson Inc.",
        "price": 2.14
    }, {
        "symbol": "SMC.TO",
        "label": "Sulliden Mining Capital Inc.",
        "price": 0.055
    }, {
        "symbol": "SMU-UN.TO",
        "label": "Summit Industrial Income REIT",
        "price": 12.34
    }, {
        "symbol": "SLF.TO",
        "label": "Sun Life Financial Inc.",
        "price": 63.55
    }, {
        "symbol": "SU.TO",
        "label": "Suncor Energy Inc.",
        "price": 43.9
    }, {
        "symbol": "SOY.TO",
        "label": "SunOpta Inc.",
        "price": 3.98
    }, {
        "symbol": "SPB.TO",
        "label": "Superior Plus Corp.",
        "price": 12.84
    }, {
        "symbol": "SXP.TO",
        "label": "Supremex Inc.",
        "price": 2.38
    }, {
        "symbol": "SGY.TO",
        "label": "Surge Energy Inc.",
        "price": 1.07
    }, {
        "symbol": "SSF-UN.TO",
        "label": "Symphony Floating Rate Senior Loan Fund",
        "price": 8.3
    }, {
        "symbol": "SXI.TO",
        "label": "Synex International Inc.",
        "price": 0.22
    }, {
        "symbol": "TAO.TO",
        "label": "TAG Oil Ltd.",
        "price": 0.4
    }, {
        "symbol": "TBL.TO",
        "label": "Taiga Building Products Ltd.",
        "price": 1.17
    }, {
        "symbol": "TLO.TO",
        "label": "Talon Metals Corp.",
        "price": 0.135
    }, {
        "symbol": "TVE.TO",
        "label": "Tamarack Valley Energy Ltd",
        "price": 2.02
    }, {
        "symbol": "TNX.TO",
        "label": "Tanzanian Gold Corporation",
        "price": 0.71
    }, {
        "symbol": "TKO.TO",
        "label": "Taseko Mines Limited",
        "price": 0.8
    }, {
        "symbol": "TDB.TO",
        "label": "TD Canadian Aggregate Bond Index ETF",
        "price": 14.89
    }, {
        "symbol": "THE.TO",
        "label": "TD International Equity CAD Hedged Index ETF",
        "price": 19.85
    }, {
        "symbol": "TPE.TO",
        "label": "TD International Equity Index ETF",
        "price": 18.74
    }, {
        "symbol": "THU.TO",
        "label": "TD U.S. Equity CAD Hedged Index ETF",
        "price": 23.55
    }, {
        "symbol": "TPU.TO",
        "label": "TD U.S. Equity Index ETF",
        "price": 24.47
    }, {
        "symbol": "TTP.TO",
        "label": "TD Canadian Equity Index ETF",
        "price": 19.72
    }, {
        "symbol": "XTD.TO",
        "label": "TDb Split Corp.",
        "price": 6.13
    }, {
        "symbol": "TCS.TO",
        "label": "Tecsys Inc.",
        "price": 22.19
    }, {
        "symbol": "T.TO",
        "label": "TELUS Corporation",
        "price": 51.3
    }, {
        "symbol": "TPH.TO",
        "label": "Temple Hotels Inc.",
        "price": 2.09
    }, {
        "symbol": "TGO.TO",
        "label": "TeraGo Inc.",
        "price": 8.19
    }, {
        "symbol": "TGZ.TO",
        "label": "Teranga Gold Corporation",
        "price": 7.17
    }, {
        "symbol": "TVK.TO",
        "label": "TerraVest Industries Inc.",
        "price": 14.35
    }, {
        "symbol": "TH.TO",
        "label": "Theratechnologies Inc.",
        "price": 3.74
    }, {
        "symbol": "TRI.TO",
        "label": "Thomson Reuters Corporation",
        "price": 103.09
    }, {
        "symbol": "TF.TO",
        "label": "Timbercreek Financial Corporation",
        "price": 10.0
    }, {
        "symbol": "TMD.TO",
        "label": "Titan Medical Inc.",
        "price": 0.67
    }, {
        "symbol": "TMR.TO",
        "label": "TMAC Resources Inc.",
        "price": 3.0
    }, {
        "symbol": "X.TO",
        "label": "TMX Group Limited",
        "price": 117.02
    }, {
        "symbol": "TCT-UN.TO",
        "label": "Top 10 Canadian Financial Trust",
        "price": 9.06
    }, {
        "symbol": "TXT-UN.TO",
        "label": "Top 10 Split Trust",
        "price": 3.95
    }, {
        "symbol": "TUT-UN.TO",
        "label": "Top 20 U.S. Dividend Trust",
        "price": 12.15
    }, {
        "symbol": "TOG.TO",
        "label": "TORC Oil & Gas Ltd.",
        "price": 4.37
    }, {
        "symbol": "TXG.TO",
        "label": "Torex Gold Resources Inc",
        "price": 17.56
    }, {
        "symbol": "TIH.TO",
        "label": "Toromont Industries Ltd.",
        "price": 72.2
    }, {
        "symbol": "TD.TO",
        "label": "The Toronto-Dominion Bank",
        "price": 74.12
    }, {
        "symbol": "TS-B.TO",
        "label": "Torstar Corporation",
        "price": 0.43
    }, {
        "symbol": "TEI.TO",
        "label": "Toscana Energy Income Corporation",
        "price": 0.01
    }, {
        "symbol": "TOT.TO",
        "label": "Total Energy Services Inc.",
        "price": 5.96
    }, {
        "symbol": "TXP.TO",
        "label": "Touchstone Exploration Inc.",
        "price": 0.395
    }, {
        "symbol": "TOU.TO",
        "label": "Tourmaline Oil Corp.",
        "price": 14.61
    }, {
        "symbol": "TA.TO",
        "label": "TransAlta Corporation",
        "price": 9.31
    }, {
        "symbol": "RNW.TO",
        "label": "TransAlta Renewables Inc.",
        "price": 16.14
    }, {
        "symbol": "TRZ.TO",
        "label": "Transat A.T. Inc.",
        "price": 16.28
    }, {
        "symbol": "TNP.TO",
        "label": "TransAtlantic Petroleum Ltd.",
        "price": 0.54
    }, {
        "symbol": "TRP.TO",
        "label": "TC Energy Corporation",
        "price": 70.85
    }, {
        "symbol": "TCL-A.TO",
        "label": "Transcontinental Inc.",
        "price": 15.64
    }, {
        "symbol": "TGL.TO",
        "label": "TransGlobe Energy Corporation",
        "price": 1.83
    }, {
        "symbol": "TML.TO",
        "label": "Treasury Metals Inc.",
        "price": 0.27
    }, {
        "symbol": "TSL.TO",
        "label": "Tree Island Steel Ltd.",
        "price": 1.97
    }, {
        "symbol": "TV.TO",
        "label": "Trevali Mining Corporation",
        "price": 0.255
    }, {
        "symbol": "TZS.TO",
        "label": "Trez Capital Senior Mortgage Investment Corporation",
        "price": 2.37
    }, {
        "symbol": "TCW.TO",
        "label": "Trican Well Service Ltd.",
        "price": 1.19
    }, {
        "symbol": "TCN.TO",
        "label": "Tricon Capital Group Inc.",
        "price": 11.04
    }, {
        "symbol": "TMQ.TO",
        "label": "Trilogy Metals Inc.",
        "price": 3.27
    }, {
        "symbol": "TNT-UN.TO",
        "label": "True North Commercial Real Estate Investment Trust",
        "price": 7.48
    }, {
        "symbol": "TC.TO",
        "label": "Tucows Inc.",
        "price": 85.6
    }, {
        "symbol": "TRQ.TO",
        "label": "Turquoise Hill Resources Ltd.",
        "price": 0.92
    }, {
        "symbol": "TVA-B.TO",
        "label": "TVA Group Inc.",
        "price": 2.59
    }, {
        "symbol": "TWC.TO",
        "label": "TWC Enterprises Limited",
        "price": 13.26
    }, {
        "symbol": "USF-UN.TO",
        "label": "U.S. Financials Income Fund",
        "price": 7.28
    }, {
        "symbol": "UWE.TO",
        "label": "U3O8 Corp.",
        "price": 0.095
    }, {
        "symbol": "UEX.TO",
        "label": "UEX Corporation",
        "price": 0.12
    }, {
        "symbol": "UNS.TO",
        "label": "Uni-Select Inc.",
        "price": 11.83
    }, {
        "symbol": "UNC.TO",
        "label": "United Corporations Limited",
        "price": 100.26
    }, {
        "symbol": "URE.TO",
        "label": "Ur-Energy Inc.",
        "price": 0.74
    }, {
        "symbol": "U.TO",
        "label": "Uranium Participation Corporation",
        "price": 4.02
    }, {
        "symbol": "URB.TO",
        "label": "Urbana Corporation",
        "price": 2.71
    }, {
        "symbol": "UR.TO",
        "label": "UrtheCast Corp.",
        "price": 0.115
    }, {
        "symbol": "HUL.TO",
        "label": "Harvest US Equity Plus Income ETF",
        "price": 9.5
    }, {
        "symbol": "FTU.TO",
        "label": "US Financial 15 Split Corp",
        "price": 0.51
    }, {
        "symbol": "VLE.TO",
        "label": "Valeura Energy Inc.",
        "price": 0.55
    }, {
        "symbol": "VAB.TO",
        "label": "Vanguard Canadian Aggregate Bond Index ETF",
        "price": 26.07
    }, {
        "symbol": "VSB.TO",
        "label": "Vanguard Canadian Short-Term Bond Index ETF",
        "price": 23.88
    }, {
        "symbol": "VSC.TO",
        "label": "Vanguard Canadian Short-Term Corporate Bond Index ETF",
        "price": 24.43
    }, {
        "symbol": "VCN.TO",
        "label": "Vanguard FTSE Canada All Cap Index ETF",
        "price": 35.41
    }, {
        "symbol": "VCE.TO",
        "label": "Vanguard FTSE Canada Index ETF",
        "price": 37.71
    }, {
        "symbol": "VRE.TO",
        "label": "Vanguard FTSE Canadian Capped REIT Index ETF",
        "price": 36.31
    }, {
        "symbol": "VDY.TO",
        "label": "Vanguard FTSE Canadian High Dividend Yield Index ETF",
        "price": 35.81
    }, {
        "symbol": "VDU.TO",
        "label": "Vanguard FTSE Developed All Cap ex U.S. Index ETF",
        "price": 36.67
    }, {
        "symbol": "VEF.TO",
        "label": "Vanguard FTSE Developed All Cap ex U.S. Index ETF (CAD-hedged)",
        "price": 43.79
    }, {
        "symbol": "VIU.TO",
        "label": "Vanguard FTSE Developed All Cap ex North Amer Idx ETF",
        "price": 29.49
    }, {
        "symbol": "VI.TO",
        "label": "Vanguard FTSE Developed All Cap ex Nrth Amer Idx ETF (CAD-Hdd)",
        "price": 30.69
    }, {
        "symbol": "VA.TO",
        "label": "Vanguard FTSE Developed Asia Pacific All Cap Index ETF",
        "price": 35.13
    }, {
        "symbol": "VAH.TO",
        "label": "Vanguard FTSE Developed Asia Pacific All Cap Index ETF (CAD-hedged)",
        "price": 31.54
    }, {
        "symbol": "VE.TO",
        "label": "Vanguard FTSE Developed Europe All Cap Index ETF",
        "price": 30.15
    }, {
        "symbol": "VEH.TO",
        "label": "Vanguard FTSE Developed Europe All Cap Index ETF (CAD-hedged)",
        "price": 31.88
    }, {
        "symbol": "VEE.TO",
        "label": "Vanguard FTSE Emerging Markets All Cap Index ETF",
        "price": 36.08
    }, {
        "symbol": "VXC.TO",
        "label": "Vanguard FTSE Global All Cap ex Canada Index ETF",
        "price": 41.37
    }, {
        "symbol": "VBG.TO",
        "label": "Vanguard Global ex-U.S. Aggregate Bond Index ETF (CAD-hedged)",
        "price": 27.36
    }, {
        "symbol": "VLQ.TO",
        "label": "Vanguard Global Liquidity Factor ETF CAD",
        "price": 35.0
    }, {
        "symbol": "VVO.TO",
        "label": "Vanguard Global Minimum Volatility ETF CAD",
        "price": 34.46
    }, {
        "symbol": "VMO.TO",
        "label": "Vanguard Global Momentum Factor ETF CAD",
        "price": 36.46
    }, {
        "symbol": "VVL.TO",
        "label": "Vanguard Global Value Factor ETF CAD",
        "price": 33.35
    }, {
        "symbol": "VFV.TO",
        "label": "Vanguard S&amp;P 500 Index ETF",
        "price": 77.23
    }, {
        "symbol": "VSP.TO",
        "label": "Vanguard S&amp;P 500 Index ETF (CAD-hedged)",
        "price": 57.17
    }, {
        "symbol": "VBU.TO",
        "label": "Vanguard U.S. Aggregate Bond Index ETF (CAD-hedged)",
        "price": 25.58
    }, {
        "symbol": "VGG.TO",
        "label": "Vanguard U.S. Dividend Appreciation Index ETF",
        "price": 57.28
    }, {
        "symbol": "VGH.TO",
        "label": "Vanguard U.S. Dividend Appreciation Index ETF (CAD-hedged)",
        "price": 44.11
    }, {
        "symbol": "VUN.TO",
        "label": "Vanguard U.S. Total Market Index ETF",
        "price": 60.0
    }, {
        "symbol": "VUS.TO",
        "label": "Vanguard U.S. Total Market Index ETF (CAD-hedged)",
        "price": 63.53
    }, {
        "symbol": "VCM.TO",
        "label": "Vecima Networks Inc.",
        "price": 10.46
    }, {
        "symbol": "VLN.TO",
        "label": "Velan Inc.",
        "price": 8.04
    }, {
        "symbol": "NPK.TO",
        "label": "Verde Agritech Plc",
        "price": 0.4
    }, {
        "symbol": "VET.TO",
        "label": "Vermilion Energy Inc.",
        "price": 21.3
    }, {
        "symbol": "VB.TO",
        "label": "VersaBank",
        "price": 7.63
    }, {
        "symbol": "VFF.TO",
        "label": "Village Farms International, Inc.",
        "price": 8.05
    }, {
        "symbol": "VGZ.TO",
        "label": "Vista Gold Corp.",
        "price": 0.88
    }, {
        "symbol": "WJX.TO",
        "label": "Wajax Corporation",
        "price": 15.85
    }, {
        "symbol": "WFC.TO",
        "label": "Wall Financial Corporation",
        "price": 35.9
    }, {
        "symbol": "WM.TO",
        "label": "Wallbridge Mining Company Limited",
        "price": 0.81
    }, {
        "symbol": "WDO.TO",
        "label": "Wesdome Gold Mines Ltd.",
        "price": 8.88
    }, {
        "symbol": "WFT.TO",
        "label": "West Fraser Timber Co. Ltd.",
        "price": 61.85
    }, {
        "symbol": "WRN.TO",
        "label": "Western Copper and Gold Corporation",
        "price": 0.98
    }, {
        "symbol": "WRG.TO",
        "label": "Western Energy Services Corp.",
        "price": 0.305
    }, {
        "symbol": "WEF.TO",
        "label": "Western Forest Products Inc.",
        "price": 1.22
    }, {
        "symbol": "WPRT.TO",
        "label": "Westport Fuel Systems Inc.",
        "price": 3.52
    }, {
        "symbol": "WTE.TO",
        "label": "Westshore Terminals Investment Corporation",
        "price": 17.67
    }, {
        "symbol": "WCP.TO",
        "label": "Whitecap Resources Inc.",
        "price": 5.33
    }, {
        "symbol": "WCM-A.TO",
        "label": "Wilmington Capital Management Inc.",
        "price": 4.8
    }, {
        "symbol": "WPK.TO",
        "label": "Winpak Ltd.",
        "price": 49.38
    }, {
        "symbol": "EHE.TO",
        "label": "WisdomTree Europe Hedged Equity Index ETF",
        "price": 27.71
    }, {
        "symbol": "DQI.TO",
        "label": "WisdomTree International Quality Dividend Growth Variably Hedged Index ETF",
        "price": 26.26
    }, {
        "symbol": "IQD.TO",
        "label": "WisdomTree International Quality Dividend Growth Index ETF",
        "price": 27.64
    }, {
        "symbol": "HID.TO",
        "label": "WisdomTree U.S. High Dividend Index ETF",
        "price": 21.63
    }, {
        "symbol": "DQD.TO",
        "label": "WisdomTree U.S. Quality Dividend Growth Variably Hedged Index ETF",
        "price": 30.45
    }, {
        "symbol": "DGR.TO",
        "label": "WisdomTree U.S. Quality Dividend Growth Index ETF",
        "price": 29.15
    }, {
        "symbol": "WFS.TO",
        "label": "World Financial Split Corp.",
        "price": 3.0
    }, {
        "symbol": "WIR-U.TO",
        "label": "WPT Industrial Real Estate Investment Trust",
        "price": 14.42
    }, {
        "symbol": "WSP.TO",
        "label": "WSP Global Inc.",
        "price": 94.01
    }, {
        "symbol": "YRI.TO",
        "label": "Yamana Gold Inc.",
        "price": 4.82
    }, {
        "symbol": "YGR.TO",
        "label": "Yangarra Resources Ltd.",
        "price": 1.39
    }, {
        "symbol": "Y.TO",
        "label": "Yellow Pages Limited",
        "price": 9.97
    }, {
        "symbol": "ZAR.TO",
        "label": "Zargon Oil & Gas Ltd.",
        "price": 0.205
    }, {
        "symbol": "RCGTX",
        "label": "AllianzGI Technology Fund Class C",
        "price": 38.23
    }, {
        "symbol": "LDVAX",
        "label": "Leland Thomson Reuters Venture Capital Index Fund Class A",
        "price": 23.63
    }, {
        "symbol": "INPIX",
        "label": "ProFunds Internet UltraSector Fund Investor Class",
        "price": 100.06
    }, {
        "symbol": "INPSX",
        "label": "ProFunds Internet UltraSector Fund Service Class",
        "price": 77.08
    }, {
        "symbol": "SCATX",
        "label": "Virtus Zevenbergen Innovative Growth Stock Fund Class I",
        "price": 33.06
    }, {
        "symbol": "CBAIX",
        "label": "Calvert Balanced Fund Class I",
        "price": 36.6
    }, {
        "symbol": "BFOCX",
        "label": "Berkshire Focus Fund",
        "price": 29.41
    }, {
        "symbol": "CSGCX",
        "label": "Calvert Balanced Fund Class C",
        "price": 34.6
    }, {
        "symbol": "USNQX",
        "label": "USAA NASDAQ-100 Index Fund",
        "price": 25.58
    }, {
        "symbol": "MMCFX",
        "label": "AMG Managers Emerging Opportunities Fund Class N",
        "price": 40.6
    }, {
        "symbol": "CGMRX",
        "label": "CGM Realty Fund",
        "price": 25.71
    }, {
        "symbol": "CAMOX",
        "label": "Cambiar Opportunity Fund Class Investor",
        "price": 22.64
    }, {
        "symbol": "OTPIX",
        "label": "ProFunds NASDAQ-100 Fund Investor Class",
        "price": 88.64
    }, {
        "symbol": "USMAX",
        "label": "Natixis ASG Tactical U.S. Market Fund Class A",
        "price": 16.8
    }, {
        "symbol": "MGOAX",
        "label": "Victory Munder Mid-Cap Core Growth Fund Class A",
        "price": 0.0
    }, {
        "symbol": "TEGYX",
        "label": "Touchstone Mid Cap Growth Fund Class Y",
        "price": 35.07
    }, {
        "symbol": "OTPSX",
        "label": "ProFunds NASDAQ-100 Fund Service Class",
        "price": 72.03
    }, {
        "symbol": "MMSRX",
        "label": "Victory Munder Mid-Cap Core Growth Fund Class R",
        "price": 0.0
    }, {
        "symbol": "SASWX",
        "label": "AIG Small-Cap Fund Class W",
        "price": 17.67
    }, {
        "symbol": "RYCOX",
        "label": "Rydex NASDAQ-100 Fund Class C",
        "price": 36.75
    }, {
        "symbol": "FGDKX",
        "label": "Fidelity Growth Discovery Fund Class K",
        "price": 41.94
    }, {
        "symbol": "TRLGX",
        "label": "T. Rowe Price Institutional Large Cap Growth Fund",
        "price": 46.33
    }, {
        "symbol": "NEAGX",
        "label": "Needham Aggressive Growth Fund Retail Class",
        "price": 23.19
    }, {
        "symbol": "RBRCX",
        "label": "RBC Small Cap Core Fund Class R6",
        "price": 32.48
    }, {
        "symbol": "FDSVX",
        "label": "Fidelity Growth Discovery Fund",
        "price": 41.92
    }, {
        "symbol": "TARKX",
        "label": "Tarkio Fund",
        "price": 20.45
    }, {
        "symbol": "ATACX",
        "label": "ATAC Rotation Fund Investor Class",
        "price": 31.22
    }, {
        "symbol": "DSMLX",
        "label": "Touchstone Large Company Growth Fund Class Institutional",
        "price": 46.6
    }, {
        "symbol": "QLSCX",
        "label": "Federated MDT Small Cap Core Fund Class R6",
        "price": 20.37
    }, {
        "symbol": "GGGAX",
        "label": "Gabelli Global Growth Fund Class A",
        "price": 37.27
    }, {
        "symbol": "TEGIX",
        "label": "Touchstone Mid Cap Growth Fund Institutional Class",
        "price": 35.43
    }, {
        "symbol": "WSMNX",
        "label": "William Blair Small-Mid Cap Growth Fund Class N",
        "price": 26.6
    }, {
        "symbol": "TSNIX",
        "label": "T. Rowe Price Science and Technology Fund I Class",
        "price": 45.88
    }, {
        "symbol": "PRSCX",
        "label": "T. Rowe Price Science and Technology Fund",
        "price": 45.89
    }, {
        "symbol": "TEGAX",
        "label": "Touchstone Mid Cap Growth Fund Class A",
        "price": 33.52
    }, {
        "symbol": "GTCSX",
        "label": "Glenmede Small Cap Equity Portfolio Class Advisor",
        "price": 26.54
    }, {
        "symbol": "VSSCX",
        "label": "JPMorgan Small Cap Core Fund Class R5",
        "price": 52.57
    }, {
        "symbol": "WSMDX",
        "label": "William Blair Small-Mid Cap Growth Fund Class I",
        "price": 28.26
    }, {
        "symbol": "TOECX",
        "label": "Touchstone Mid Cap Growth Fund Class C",
        "price": 19.64
    }, {
        "symbol": "ZSCIX",
        "label": "Zacks Small-Cap Core Fund Institutional Class",
        "price": 28.07
    }, {
        "symbol": "PRUFX",
        "label": "T. Rowe Price Growth Stock Fund I Class",
        "price": 77.44
    }, {
        "symbol": "TSAGX",
        "label": "Touchstone Large Company Growth Fund Class A",
        "price": 45.97
    }, {
        "symbol": "TILHX",
        "label": "TIAA-CREF Large Cap Growth Fund Advisor Class",
        "price": 23.08
    }, {
        "symbol": "PRGFX",
        "label": "T. Rowe Price Growth Stock Fund",
        "price": 77.39
    }, {
        "symbol": "ADCCX",
        "label": "American Century Disciplined Growth Fund Class C",
        "price": 20.7
    }, {
        "symbol": "TILPX",
        "label": "TIAA-CREF Large-Cap Growth Fund Premier Class",
        "price": 23.08
    }, {
        "symbol": "GTSCX",
        "label": "Glenmede Small Cap Equity Portfolio Institutional Class",
        "price": 28.12
    }, {
        "symbol": "TRSAX",
        "label": "T. Rowe Price Growth Stock Fund Advisor Class",
        "price": 75.44
    }, {
        "symbol": "TIRTX",
        "label": "TIAA-CREF Large-Cap Growth Fund Retail Class",
        "price": 23.04
    }, {
        "symbol": "NEAIX",
        "label": "Needham Aggressive Growth Fund Institutional Class",
        "price": 23.69
    }, {
        "symbol": "RRGSX",
        "label": "T. Rowe Price Growth Stock Fund R Class",
        "price": 72.54
    }, {
        "symbol": "TILRX",
        "label": "TIAA-CREF Large-Cap Growth Fund Retirement Class",
        "price": 22.95
    }, {
        "symbol": "HRCUX",
        "label": "Carillon ClariVest Capital Appreciation Fund Class R6",
        "price": 49.87
    }, {
        "symbol": "FLRYX",
        "label": "Nuveen Large Cap Select Fund Class I",
        "price": 34.2
    }, {
        "symbol": "LSSNX",
        "label": "Loomis Sayles Small Cap Growth Fund Class N",
        "price": 27.99
    }, {
        "symbol": "ZSCCX",
        "label": "Zacks Small-Cap Core Fund Investor Class",
        "price": 27.63
    }, {
        "symbol": "QMORX",
        "label": "AQR Large Cap Momentum Style Fund Class R6",
        "price": 22.46
    }, {
        "symbol": "JMCRX",
        "label": "James Micro Cap Fund",
        "price": 16.65
    }, {
        "symbol": "DISIX",
        "label": "BNY Mellon Small Cap Stock Index Fund Class I",
        "price": 29.04
    }, {
        "symbol": "DISSX",
        "label": "BNY Mellon Small Cap Stock Index Fund Investor Shares",
        "price": 29.11
    }, {
        "symbol": "FLRAX",
        "label": "Nuveen Large Cap Select Fund Class A",
        "price": 33.95
    }, {
        "symbol": "HRCMX",
        "label": "Carillon ClariVest Capital Appreciation Fund Class R5",
        "price": 50.09
    }, {
        "symbol": "FLYCX",
        "label": "Nuveen Large Cap Select Fund Class C",
        "price": 31.43
    }, {
        "symbol": "VMGMX",
        "label": "Vanguard Mid-Cap Growth Index Fund Admiral Shares",
        "price": 71.27
    }, {
        "symbol": "USAAX",
        "label": "USAA Growth Fund",
        "price": 27.46
    }, {
        "symbol": "FTQGX",
        "label": "Fidelity Focused Stock Fund",
        "price": 27.25
    }, {
        "symbol": "QLSGX",
        "label": "Federated MDT Small Cap Growth Fund Class R6",
        "price": 25.67
    }, {
        "symbol": "QISGX",
        "label": "Federated MDT Small Cap Growth Fund Institutional Shares",
        "price": 25.66
    }, {
        "symbol": "FSRFX",
        "label": "Fidelity Select Transportation Portfolio",
        "price": 98.46
    }, {
        "symbol": "QCSGX",
        "label": "Federated MDT Small Cap Growth Fund Class C Shares",
        "price": 21.3
    }, {
        "symbol": "FOCWX",
        "label": "AIG Focused Growth Fund Class W",
        "price": 26.41
    }, {
        "symbol": "VMGIX",
        "label": "Vanguard Mid-Cap Growth Index Fund Investor Shares",
        "price": 65.11
    }, {
        "symbol": "DSEEX",
        "label": "DoubleLine Shiller Enhanced CAPE Class I",
        "price": 16.75
    }, {
        "symbol": "FOCCX",
        "label": "AIG Focused Growth Fund Class C",
        "price": 23.66
    }, {
        "symbol": "DSENX",
        "label": "DoubleLine Shiller Enhanced CAPE Class N",
        "price": 16.73
    }, {
        "symbol": "CCALX",
        "label": "Conestoga Small Cap Institutional Class",
        "price": 62.83
    }, {
        "symbol": "PFPWX",
        "label": "Parnassus Endeavor Fund Institutional Shares",
        "price": 39.45
    }, {
        "symbol": "QASGX",
        "label": "Federated MDT Small Cap Growth Fund Class A Shares",
        "price": 24.5
    }, {
        "symbol": "EGFIX",
        "label": "Edgewood Growth Fund Class Institutional",
        "price": 40.29
    }, {
        "symbol": "VTSIX",
        "label": "Vanguard Tax-Managed Small-Cap Fund Institutional Shares",
        "price": 69.21
    }, {
        "symbol": "POGRX",
        "label": "PRIMECAP Odyssey Growth Fund",
        "price": 42.36
    }, {
        "symbol": "HACAX",
        "label": "Harbor Capital Appreciation Fund Institutional Class",
        "price": 80.41
    }, {
        "symbol": "CCASX",
        "label": "Conestoga Small Cap Fund Investors Class",
        "price": 62.07
    }, {
        "symbol": "UIGRX",
        "label": "USAA Growth Fund Institutional Shares",
        "price": 27.39
    }, {
        "symbol": "HRCAX",
        "label": "Harbor Capital Appreciation Fund Administrative Class",
        "price": 78.54
    }, {
        "symbol": "HCAIX",
        "label": "Harbor Capital Appreciation Fund Investor Class",
        "price": 76.59
    }, {
        "symbol": "FLGEX",
        "label": "Fidelity Large Cap Growth Enhanced Index Fund",
        "price": 22.51
    }, {
        "symbol": "TPLGX",
        "label": "T. Rowe Price Institutional Large Cap Core Growth Fund",
        "price": 51.35
    }, {
        "symbol": "VTMSX",
        "label": "Vanguard Tax-Managed Small Cap Fund Admiral Shares",
        "price": 69.05
    }, {
        "symbol": "CSXCX",
        "label": "Calvert US Large Cap Core Responsible Index Fund Class C",
        "price": 26.06
    }, {
        "symbol": "PARWX",
        "label": "Parnassus Endeavor Fund Investor Shares",
        "price": 39.44
    }, {
        "symbol": "EGFFX",
        "label": "Edgewood Growth Fund Retail Class",
        "price": 38.45
    }, {
        "symbol": "PJFBX",
        "label": "PGIM Jennison Growth Fund- Class B",
        "price": 33.36
    }, {
        "symbol": "CYPIX",
        "label": "ProFunds Consumer Services UltraSector Fund Investor Class",
        "price": 49.9
    }, {
        "symbol": "FBGRX",
        "label": "Fidelity Blue Chip Growth Fund",
        "price": 114.33
    }, {
        "symbol": "TSCIX",
        "label": "AMG TimesSquare Small Cap Growth Fund Class Z",
        "price": 0.0
    }, {
        "symbol": "MECAX",
        "label": "AMG Managers Cadence Emerging Companies Fund - Class N",
        "price": 51.83
    }, {
        "symbol": "WBSIX",
        "label": "William Blair Small Cap Growth Fund Class I",
        "price": 32.32
    }, {
        "symbol": "MECIX",
        "label": "AMG Managers Cadence Emerging Companies Fund - Class I",
        "price": 56.73
    }, {
        "symbol": "PABGX",
        "label": "T. Rowe Price Blue Chip Growth Fund Advisor Class",
        "price": 128.78
    }, {
        "symbol": "TRBCX",
        "label": "T. Rowe Price Blue Chip Growth Fund",
        "price": 131.2
    }, {
        "symbol": "FBGKX",
        "label": "Fidelity Blue Chip Growth Fund Class K",
        "price": 114.6
    }, {
        "symbol": "RRBGX",
        "label": "T. Rowe Price Blue Chip Growth Fund Class R",
        "price": 123.41
    }, {
        "symbol": "FSHOX",
        "label": "Fidelity Select Construction & Housing Portfolio",
        "price": 64.25
    }, {
        "symbol": "WSMGX",
        "label": "Wilshire Small Company Growth Portfolio Fund Class Institutional",
        "price": 30.98
    }, {
        "symbol": "ATSMX",
        "label": "AQR TM Small Cap Momentum Style Fund Class I",
        "price": 20.38
    }, {
        "symbol": "TBCIX",
        "label": "T. Rowe Price Blue Chip Growth Fund I Class",
        "price": 131.33
    }, {
        "symbol": "QTSRX",
        "label": "AQR TM Small Cap Momentum Style Fund Class R6",
        "price": 20.36
    }, {
        "symbol": "CPECX",
        "label": "Catalyst Dynamic Alpha Fund Class C",
        "price": 19.16
    }, {
        "symbol": "WBSNX",
        "label": "William Blair Small Cap Growth Fund Class N",
        "price": 28.75
    }, {
        "symbol": "CYPSX",
        "label": "ProFunds Consumer Services UltraSector Fund Service Class",
        "price": 42.67
    }, {
        "symbol": "SFLWX",
        "label": "AIG Focused Alpha Large-Cap Fund Class W",
        "price": 28.3
    }, {
        "symbol": "DTSGX",
        "label": "Wilshire Small Company Growth Portfolio Investment Class Shares",
        "price": 28.25
    }, {
        "symbol": "CPEAX",
        "label": "Catalyst Dynamic Alpha Fund Class A",
        "price": 20.57
    }, {
        "symbol": "JLGAX",
        "label": "JAG Large Cap Growth Fund Class A",
        "price": 17.62
    }, {
        "symbol": "GURIX",
        "label": "Guggenheim Risk Managed Real Estate Fund Institutional",
        "price": 33.38
    }, {
        "symbol": "ROGSX",
        "label": "Red Oak Technology Select Fund",
        "price": 32.56
    }, {
        "symbol": "CVMIX",
        "label": "Calvert Emerging Markets Equity Fund Class I",
        "price": 18.41
    }, {
        "symbol": "BIPSX",
        "label": "ProFunds Biotechnology UltraSector Fund Service Class",
        "price": 48.13
    }, {
        "symbol": "BIPIX",
        "label": "ProFunds Biotechnology UltraSector Fund Investor Class",
        "price": 62.87
    }, {
        "symbol": "ASMOX",
        "label": "AQR Small Cap Momentum Style Fund Class I",
        "price": 20.04
    }, {
        "symbol": "FMPFX",
        "label": "Nuveen Small Cap Growth Opportunities Fund Class R6",
        "price": 29.44
    }, {
        "symbol": "TSCPX",
        "label": "AMG TimesSquare Small Cap Growth Fund Class N",
        "price": 0.0
    }, {
        "symbol": "GURAX",
        "label": "Guggenheim Risk Managed Real Estate Fund Class A",
        "price": 32.97
    }, {
        "symbol": "FFGRX",
        "label": "Emerald Growth Fund Investor Class",
        "price": 27.39
    }, {
        "symbol": "FIMPX",
        "label": "Nuveen Small Cap Growth Opportunities Fund Class I",
        "price": 29.23
    }, {
        "symbol": "CPEIX",
        "label": "Catalyst Dynamic Alpha Fund Class I",
        "price": 20.75
    }, {
        "symbol": "WSCGX",
        "label": "Wells Fargo Small Company Growth Fund - Class Inst",
        "price": 57.12
    }, {
        "symbol": "NVSCX",
        "label": "Wells Fargo Small Company Growth Fund - Class Admin",
        "price": 55.36
    }, {
        "symbol": "SGPIX",
        "label": "ProFunds Small Cap Growth Fund Investor Class",
        "price": 96.29
    }, {
        "symbol": "GLVNX",
        "label": "Invesco Oppenheimer Global Focus Fund Class R",
        "price": 53.71
    }, {
        "symbol": "FKKSX",
        "label": "Federated Kaufmann Small Cap Fund Class R Shares",
        "price": 45.94
    }, {
        "symbol": "BMGCX",
        "label": "BlackRock Mid-Cap Growth Equity Portfolio Investor C Shares",
        "price": 21.42
    }, {
        "symbol": "FTRNX",
        "label": "Fidelity Trend Fund",
        "price": 114.07
    }, {
        "symbol": "HSPGX",
        "label": "Emerald Growth Fund Class A",
        "price": 27.53
    }, {
        "symbol": "DFDMX",
        "label": "DF Dent Midcap Growth Fund Investor Shares",
        "price": 28.67
    }, {
        "symbol": "HSPCX",
        "label": "Emerald Growth Fund Class C",
        "price": 22.68
    }, {
        "symbol": "ALGRX",
        "label": "Alger Focus Equity Fund Class I",
        "price": 42.17
    }, {
        "symbol": "FSAIX",
        "label": "Fidelity Select Portfolios Air Transportation Portfolio",
        "price": 75.23
    }, {
        "symbol": "JARTX",
        "label": "Janus Henderson Forty Fund Class S",
        "price": 37.78
    }, {
        "symbol": "JFRNX",
        "label": "Janus Henderson Forty Fund Class N",
        "price": 41.4
    }, {
        "symbol": "SGTTX",
        "label": "Columbia Seligman Global Technology Fund Institutional 2 Class",
        "price": 48.31
    }, {
        "symbol": "BMRRX",
        "label": "BlackRock Mid-Cap Growth Equity Portfolio Class R",
        "price": 27.2
    }, {
        "symbol": "JCAPX",
        "label": "Janus Henderson Forty Fund Class I",
        "price": 41.23
    }, {
        "symbol": "FMPCX",
        "label": "Nuveen Small Cap Growth Opportunities Fund Class C",
        "price": 17.15
    }, {
        "symbol": "SHTCX",
        "label": "Columbia Seligman Global Technology Fund Class C",
        "price": 33.95
    }, {
        "symbol": "JDCAX",
        "label": "Janus Henderson Forty Fund Class A",
        "price": 39.55
    }, {
        "symbol": "ALZFX",
        "label": "Alger Focus Equity Fund Class Z",
        "price": 42.87
    }, {
        "symbol": "CSGZX",
        "label": "Columbia Seligman Global Technology Fund Institutional Class",
        "price": 48.01
    }, {
        "symbol": "JDCRX",
        "label": "Janus Henderson Forty Fund Class R",
        "price": 34.75
    }, {
        "symbol": "SGTRX",
        "label": "Columbia Seligman Global Technology Fund Class R",
        "price": 44.87
    }, {
        "symbol": "KMKYX",
        "label": "Kinetics Market Opportunities Fund Class Institutional",
        "price": 27.88
    }, {
        "symbol": "JACCX",
        "label": "Janus Henderson Forty Fund Class C",
        "price": 31.54
    }, {
        "symbol": "ALCFX",
        "label": "Alger Focus Equity Fund Class C",
        "price": 39.58
    }, {
        "symbol": "BMGAX",
        "label": "BlackRock Mid-Cap Growth Equity Portfolio Investor A Shares",
        "price": 27.71
    }, {
        "symbol": "ALAFX",
        "label": "Alger Focus Equity Fund Class A",
        "price": 41.94
    }, {
        "symbol": "JLGMX",
        "label": "JPMorgan Large Cap Growth Fund Class R6",
        "price": 44.54
    }, {
        "symbol": "CAMAX",
        "label": "Cambiar Global Ultra Focus Fund Investor Class",
        "price": 20.44
    }, {
        "symbol": "CCHRX",
        "label": "Columbia Seligman Global Technology Fund Advisor Class",
        "price": 48.92
    }, {
        "symbol": "POAGX",
        "label": "PRIMECAP Odyssey Aggressive Growth Fund",
        "price": 46.92
    }, {
        "symbol": "WAAEX",
        "label": "Wasatch Small Cap Growth Fund Investor Class",
        "price": 40.82
    }, {
        "symbol": "KMKNX",
        "label": "Kinetics Market Opportunities Fund No Load Class",
        "price": 27.46
    }, {
        "symbol": "MIGPX",
        "label": "Morgan Stanley Institutional Fund, Inc. Global Advantage Portfolio Class A",
        "price": 18.53
    }, {
        "symbol": "VWUAX",
        "label": "Vanguard U.S. Growth Fund Admiral Shares",
        "price": 117.83
    }, {
        "symbol": "RIVBX",
        "label": "Riverbridge Growth Fund Institutional Class",
        "price": 23.71
    }, {
        "symbol": "UMPIX",
        "label": "ProFunds UltraMid Cap Fund Investor Class",
        "price": 48.63
    }, {
        "symbol": "BGLUX",
        "label": "Baron Global Advantage Fund R6 Shares",
        "price": 30.93
    }, {
        "symbol": "TTMIX",
        "label": "T. Rowe Price Communications & Technology Fund I Class",
        "price": 129.9
    }, {
        "symbol": "PRMTX",
        "label": "T. Rowe Price Communications & Technology Fund Investor Class",
        "price": 129.64
    }, {
        "symbol": "TCMSX",
        "label": "TCM Small Cap Growth Fund",
        "price": 37.8
    }, {
        "symbol": "MSPTX",
        "label": "Morgan Stanley Institutional Fund, Inc. Global Advantage Portfolio Class C",
        "price": 17.43
    }, {
        "symbol": "MIGIX",
        "label": "Morgan Stanley Institutional Fund, Inc. Global Advantage Portfolio Class I",
        "price": 18.94
    }, {
        "symbol": "UMPSX",
        "label": "ProFunds UltraMid Cap Fund Service Class",
        "price": 39.6
    }, {
        "symbol": "FSCSX",
        "label": "Fidelity Select Software & IT Services Portfolio",
        "price": 21.12
    }, {
        "symbol": "PAREX",
        "label": "T. Rowe Price Real Estate Fund Advisor Class",
        "price": 26.51
    }, {
        "symbol": "SCMIX",
        "label": "Columbia Seligman Communications and Information Fund Institutional 2 Class",
        "price": 94.43
    }, {
        "symbol": "SCIOX",
        "label": "Columbia Seligman Communications and Information Fund Advisor Class",
        "price": 81.32
    }, {
        "symbol": "SLMCX",
        "label": "Columbia Seligman Communications and Information Fund Class A",
        "price": 84.38
    }, {
        "symbol": "SCIRX",
        "label": "Columbia Seligman Communications and Information Fund Class R",
        "price": 79.21
    }, {
        "symbol": "CISGX",
        "label": "Touchstone Sands Capital Institutional Growth Fund",
        "price": 23.61
    }, {
        "symbol": "CCIZX",
        "label": "Columbia Seligman Communications and Information Fund Institutional Class",
        "price": 93.93
    }, {
        "symbol": "FZAHX",
        "label": "Fidelity Advisor Growth Opportunities Fund Class Z",
        "price": 103.18
    }, {
        "symbol": "GSXIX",
        "label": "Aberdeen U.S. Small Cap Equity Fund Institutional Service Class",
        "price": 35.61
    }, {
        "symbol": "PRJIX",
        "label": "T. Rowe Price New Horizons Fund I Class",
        "price": 62.44
    }, {
        "symbol": "PVIVX",
        "label": "Paradigm Micro-Cap Fund",
        "price": 32.45
    }, {
        "symbol": "REICX",
        "label": "West Loop Realty Fund Class C Shares",
        "price": 0.0
    }, {
        "symbol": "NWJFX",
        "label": "Nationwide Ziegler NYSE Arca Tech 100 Index Fund Institutional Service Class",
        "price": 103.27
    }, {
        "symbol": "BDFIX",
        "label": "Baron Discovery Fund Institutional Shares",
        "price": 22.2
    }, {
        "symbol": "FSPTX",
        "label": "Fidelity Select Technology Portfolio",
        "price": 21.19
    }, {
        "symbol": "FATEX",
        "label": "Fidelity Advisor Technology Fund Class M",
        "price": 58.8
    }, {
        "symbol": "PRNHX",
        "label": "T. Rowe Price New Horizons Fund",
        "price": 62.27
    }, {
        "symbol": "FSRNX",
        "label": "Fidelity Real Estate Index Fund",
        "price": 17.28
    }, {
        "symbol": "FELTX",
        "label": "Fidelity Advisor Semiconductors Fund Class M",
        "price": 27.26
    }, {
        "symbol": "GSXCX",
        "label": "Aberdeen U.S. Small Cap Equity Fund Class C",
        "price": 27.33
    }, {
        "symbol": "FADTX",
        "label": "Fidelity Advisor Technology Fund Class A",
        "price": 62.82
    }, {
        "symbol": "FTHCX",
        "label": "Fidelity Advisor Technology Fund Class C",
        "price": 50.95
    }, {
        "symbol": "TGCNX",
        "label": "TCW Select Equities Fund Class N",
        "price": 25.27
    }, {
        "symbol": "BLYYX",
        "label": "American Beacon Bridgeway Large Cap Growth Fund Y Class",
        "price": 30.87
    }, {
        "symbol": "FELIX",
        "label": "Fidelity Advisor Semiconductors Fund Class I",
        "price": 30.48
    }, {
        "symbol": "MNREX",
        "label": "Manning & Napier Real Estate Series Class S",
        "price": 16.68
    }, {
        "symbol": "USSCX",
        "label": "USAA Science & Technology Fund",
        "price": 24.98
    }, {
        "symbol": "BLYAX",
        "label": "American Beacon Bridgeway Large Cap Growth Fund A Class",
        "price": 30.64
    }, {
        "symbol": "PRJQX",
        "label": "PGIM Jennison Global Opportunities Fund-Class R6",
        "price": 29.1
    }, {
        "symbol": "FELAX",
        "label": "Fidelity Advisor Semiconductors Fund Class A",
        "price": 28.71
    }, {
        "symbol": "FRESX",
        "label": "Fidelity Real Estate Investment Portfolio",
        "price": 45.97
    }, {
        "symbol": "FELCX",
        "label": "Fidelity Advisor Semiconductors Fund Class C",
        "price": 24.35
    }, {
        "symbol": "ORECX",
        "label": "Invesco Oppenheimer Real Estate Fund Class C",
        "price": 26.46
    }, {
        "symbol": "CGTUX",
        "label": "Columbia Global Technology Growth Fund Institutional 3 Class",
        "price": 44.69
    }, {
        "symbol": "CTCAX",
        "label": "Columbia Global Technology Growth Fund Class A",
        "price": 41.83
    }, {
        "symbol": "CTHRX",
        "label": "Columbia Global Technology Growth Fund Institutional 2 Class",
        "price": 44.55
    }, {
        "symbol": "PXSGX",
        "label": "Virtus KAR Small-Cap Growth Fund Class I",
        "price": 42.41
    }, {
        "symbol": "FREAX",
        "label": "Nuveen Real Estate Securities Fund Class A",
        "price": 20.68
    }, {
        "symbol": "PSGCX",
        "label": "Virtus KAR Small-Cap Growth Fund Class C",
        "price": 36.88
    }, {
        "symbol": "CMTFX",
        "label": "Columbia Global Technology Growth Fund Institutional Class",
        "price": 43.63
    }, {
        "symbol": "CTYRX",
        "label": "Columbia Global Technology Growth Fund Advisor Class",
        "price": 44.24
    }, {
        "symbol": "CTHCX",
        "label": "Columbia Global Technology Growth Fund Class C",
        "price": 37.2
    }, {
        "symbol": "PSGAX",
        "label": "Virtus KAR Small-Cap Growth Fund Class A",
        "price": 41.43
    }, {
        "symbol": "ORENX",
        "label": "Invesco Oppenheimer Real Estate Fund Class R",
        "price": 27.15
    }, {
        "symbol": "FARCX",
        "label": "Nuveen Real Estate Securities Fund Class I",
        "price": 21.07
    }, {
        "symbol": "HGOAX",
        "label": "The Hartford Growth Opportunities Fund Class A",
        "price": 41.2
    }, {
        "symbol": "FRSSX",
        "label": "Nuveen Real Estate Securities Fund Class R3",
        "price": 21.06
    }, {
        "symbol": "HGOSX",
        "label": "The Hartford Growth Opportunities Fund Class R4",
        "price": 44.32
    }, {
        "symbol": "FBSOX",
        "label": "Fidelity Select IT Services Portfolio",
        "price": 80.29
    }, {
        "symbol": "ARYCX",
        "label": "American Century Real Estate Fund C Class",
        "price": 29.57
    }, {
        "symbol": "AIGYX",
        "label": "Aberdeen Realty Income & Growth Fund Institutional Class",
        "price": 15.71
    }, {
        "symbol": "AIAGX",
        "label": "Aberdeen Realty Income & Growth Fund Class A",
        "price": 15.67
    }, {
        "symbol": "SGRHX",
        "label": "Wells Fargo Growth Fund - Class R6",
        "price": 46.15
    }, {
        "symbol": "SGRNX",
        "label": "Wells Fargo Growth Fund - Class Inst",
        "price": 45.98
    }, {
        "symbol": "SGRKX",
        "label": "Wells Fargo Growth Fund - Class Admin",
        "price": 41.37
    }, {
        "symbol": "SGRAX",
        "label": "Wells Fargo Growth Fund - Class A",
        "price": 34.68
    }, {
        "symbol": "MGLBX",
        "label": "Marsico Global Fund",
        "price": 18.38
    }, {
        "symbol": "NWSAX",
        "label": "Nationwide Small Company Growth Fund Class A",
        "price": 20.15
    }, {
        "symbol": "CSRSX",
        "label": "Cohen & Steers Realty Shares Fund Class L",
        "price": 67.52
    }, {
        "symbol": "CSRIX",
        "label": "Cohen & Steers Institutional Realty Shares",
        "price": 47.85
    }, {
        "symbol": "BCSIX",
        "label": "Brown Capital Management Small Company Fund Investor Shares",
        "price": 103.99
    }, {
        "symbol": "BCSSX",
        "label": "Brown Capital Management Small Company Fund Institutional Shares",
        "price": 106.27
    }, {
        "symbol": "PRGTX",
        "label": "T. Rowe Price Global Technology Fund",
        "price": 17.62
    }, {
        "symbol": "BFTIX",
        "label": "Baron Fifth Avenue Growth Fund Institutional Class",
        "price": 36.39
    }, {
        "symbol": "GSOFX",
        "label": "GMO Special Opportunities Fund Class VI",
        "price": 18.38
    }, {
        "symbol": "SSETX",
        "label": "BNY Mellon Growth Fund Class I",
        "price": 34.62
    }, {
        "symbol": "FSDAX",
        "label": "Fidelity Select Defense & Aerospace Portfolio",
        "price": 19.34
    }, {
        "symbol": "QUAYX",
        "label": "AB Small Cap Growth Portfolio Advisor Class",
        "price": 62.36
    }, {
        "symbol": "AGOZX",
        "label": "Alger Small Cap Focus Fund Class Z",
        "price": 22.64
    }, {
        "symbol": "WHOSX",
        "label": "Wasatch-Hoisington U.S. Treasury Fund",
        "price": 18.44
    }, {
        "symbol": "CSXAX",
        "label": "Calvert US Large Cap Core Responsible Index Fund Class A",
        "price": 27.73
    }, {
        "symbol": "GLFOX",
        "label": "Lazard Global Listed Infrastructure Portfolio Open Shares",
        "price": 16.31
    }, {
        "symbol": "GLIFX",
        "label": "Lazard Global Listed Infrastructure Portfolio Institutional Shares",
        "price": 16.3
    }, {
        "symbol": "RYCVX",
        "label": "Rydex Dow 2x Strategy Fund Class H",
        "price": 114.45
    }, {
        "symbol": "RYCYX",
        "label": "Rydex Dow 2x Strategy Fund Class C",
        "price": 100.15
    }, {
        "symbol": "RYLDX",
        "label": "Rydex Dow 2x Strategy Fund Class A",
        "price": 114.85
    }, {
        "symbol": "BSTSX",
        "label": "BlackRock Technology Opportunities Fund Service Shares",
        "price": 37.29
    }, {
        "symbol": "CISIX",
        "label": "Calvert US Large Cap Core Responsible Index Fund Class I",
        "price": 28.44
    }, {
        "symbol": "DXQLX",
        "label": "Direxion Monthly NASDAQ-100 Bull 2X Fund Investor Class",
        "price": 34.7746
    }, {
        "symbol": "UOPIX",
        "label": "ProFunds UltraNASDAQ-100 Fund Investor Class",
        "price": 99.27
    }, {
        "symbol": "UOPSX",
        "label": "ProFunds UltraNASDAQ-100 Fund Service Class",
        "price": 79.03
    }, {
        "symbol": "RYVLX",
        "label": "Rydex NASDAQ-100 2x Strategy Fund Class A",
        "price": 211.78
    }, {
        "symbol": "RYVYX",
        "label": "Rydex NASDAQ-100 2x Strategy Fund Class H",
        "price": 211.71
    }, {
        "symbol": "RYCCX",
        "label": "Rydex NASDAQ-100 2x Strategy Fund Class C",
        "price": 165.6
    }, {
        "symbol": "FCPIX",
        "label": "Fidelity Advisor International Capital Appreciation Fund I Class",
        "price": 24.94
    }, {
        "symbol": "CAECX",
        "label": "Columbia Acorn European Fund Class C",
        "price": 22.21
    }, {
        "symbol": "PASSX",
        "label": "T. Rowe Price Small-Cap Stock Fund Advisor Class",
        "price": 53.0
    }, {
        "symbol": "RRCOX",
        "label": "T. Rowe Price U.S. Equity Research Fund R Class",
        "price": 31.85
    }, {
        "symbol": "TRSSX",
        "label": "T. Rowe Price Institutional Small-Cap Stock Fund",
        "price": 26.22
    }, {
        "symbol": "NSCCX",
        "label": "Nuveen NWQ Small-Cap Value Fund Class C",
        "price": 38.74
    }, {
        "symbol": "VITNX",
        "label": "Vanguard Institutional Total Stock Market Index Fund Institutional Shares",
        "price": 70.81
    }, {
        "symbol": "VITPX",
        "label": "Vanguard Institutional Total Stock Market Index Fund Institutional Plus Shares",
        "price": 70.81
    }, {
        "symbol": "MFOMX",
        "label": "BNY Mellon Focused Equity Opportunities Fund Class M Shares",
        "price": 16.62
    }, {
        "symbol": "MMECX",
        "label": "Victory Integrity Discovery Fund Class C",
        "price": 23.27
    }, {
        "symbol": "OTCFX",
        "label": "T. Rowe Price Small-Cap Stock Fund",
        "price": 53.55
    }, {
        "symbol": "FSKAX",
        "label": "Fidelity Total Market Index Fund",
        "price": 93.39
    }, {
        "symbol": "NWGQX",
        "label": "Nationwide WCM Focused Small Cap Fund Class C",
        "price": 21.62
    }, {
        "symbol": "JPFAX",
        "label": "JPMorgan Equity Focus Fund Class A",
        "price": 28.69
    }, {
        "symbol": "JACRX",
        "label": "James Alpha Global Real Estate Investments Portfolio Class C Shares",
        "price": 18.81
    }, {
        "symbol": "PFPRX",
        "label": "Parnassus Fund Institutional Shares",
        "price": 52.28
    }, {
        "symbol": "NLIGX",
        "label": "Nuveen Large Cap Growth Fund Class I",
        "price": 28.52
    }, {
        "symbol": "PARNX",
        "label": "Parnassus Fund",
        "price": 52.31
    }, {
        "symbol": "FWWFX",
        "label": "Fidelity Worldwide Fund",
        "price": 29.28
    }, {
        "symbol": "NLCGX",
        "label": "Nuveen Large Cap Growth Fund Class C",
        "price": 27.39
    }, {
        "symbol": "GRISX",
        "label": "Nationwide S&P 500 Index Fund Institutional Service Class",
        "price": 16.6
    }, {
        "symbol": "FCPAX",
        "label": "Fidelity Advisor International Capital Appreciation Fund Class A",
        "price": 23.36
    }, {
        "symbol": "JAREX",
        "label": "James Alpha Global Real Estate Investments Portfolio Class A Shares",
        "price": 18.66
    }, {
        "symbol": "FWIFX",
        "label": "Fidelity Advisor Worldwide Fund I Class",
        "price": 29.1
    }, {
        "symbol": "FIVFX",
        "label": "Fidelity International Capital Appreciation Fund",
        "price": 23.72
    }, {
        "symbol": "MFOIX",
        "label": "BNY Mellon Focused Equity Opportunities Fund Investor Shares",
        "price": 16.4
    }, {
        "symbol": "TGGAX",
        "label": "Touchstone International Growth Opportunities Fund Class A",
        "price": 23.47
    }, {
        "symbol": "NEFOX",
        "label": "Natixis Funds Trust II Oakmark Fund Class A",
        "price": 23.0
    }, {
        "symbol": "TGTIX",
        "label": "T. Rowe Price Growth and Income Fund I Class",
        "price": 31.35
    }, {
        "symbol": "PAGLX",
        "label": "T. Rowe Price Global Growth Stock Fund Advisor Class",
        "price": 31.7
    }, {
        "symbol": "NEFJX",
        "label": "Natixis Funds Trust I Vaughan Nelson Small Cap Value Fund Class A",
        "price": 15.89
    }, {
        "symbol": "VLAIX",
        "label": "Value Line Asset Allocation Fund Institutional Class",
        "price": 39.85
    }, {
        "symbol": "FWCFX",
        "label": "Fidelity Advisor Worldwide Fund Class C",
        "price": 27.85
    }, {
        "symbol": "TGIHX",
        "label": "TIAA-CREF Growth & Income Fund Advisor Class",
        "price": 0.0
    }, {
        "symbol": "FWAFX",
        "label": "Fidelity Advisor Worldwide Fund Class A",
        "price": 28.91
    }, {
        "symbol": "RCLIX",
        "label": "T. Rowe Price U.S. Large-Cap Core Fund I Class",
        "price": 27.7
    }, {
        "symbol": "VLAAX",
        "label": "Value Line Asset Allocation Fund Investor Class",
        "price": 39.76
    }, {
        "symbol": "TRULX",
        "label": "T. Rowe Price U.S. Large-Cap Core Fund",
        "price": 27.68
    }, {
        "symbol": "WMLIX",
        "label": "Wilmington Large-Cap Strategy Fund Class Institutional",
        "price": 25.27
    }, {
        "symbol": "SCURX",
        "label": "Hartford Schroders US Small Cap Opportunities Fund Class SDR",
        "price": 27.99
    }, {
        "symbol": "PACOX",
        "label": "T. Rowe Price U.S. Equity Research Fund Advisor Class",
        "price": 31.97
    }, {
        "symbol": "POSKX",
        "label": "PRIMECAP Odyssey Stock Fund",
        "price": 35.33
    }, {
        "symbol": "PAULX",
        "label": "T. Rowe Price U.S. Large-Cap Core Fund Advisor Class",
        "price": 27.7
    }, {
        "symbol": "SVFYX",
        "label": "Smead Value Fund Class Y",
        "price": 50.86
    }, {
        "symbol": "SCUIX",
        "label": "Hartford Schroders US Small Cap Opportunities Fund Class I",
        "price": 27.94
    }, {
        "symbol": "HOOTX",
        "label": "Hartford Schroders US Small Cap Opportunities Fund Class R5",
        "price": 27.92
    }, {
        "symbol": "FSMDX",
        "label": "Fidelity Mid Cap Index Fund",
        "price": 24.29
    }, {
        "symbol": "HOOSX",
        "label": "Hartford Schroders US Small Cap Opportunities Fund Class R4",
        "price": 27.89
    }, {
        "symbol": "FCTGX",
        "label": "Fidelity Advisor Small Cap Growth Fund Class M",
        "price": 26.12
    }, {
        "symbol": "FWTFX",
        "label": "Fidelity Advisor Worldwide Fund Class M",
        "price": 28.7
    }, {
        "symbol": "SVFDX",
        "label": "Smead Value Fund Class R1",
        "price": 50.07
    }, {
        "symbol": "BUFOX",
        "label": "Buffalo Emerging Opportunities Fund",
        "price": 17.33
    }, {
        "symbol": "LSCRX",
        "label": "Loomis Sayles Small Capital Value Fund Retail Class",
        "price": 27.74
    }, {
        "symbol": "SSSJX",
        "label": "State Street Dynamic Small Cap Fund Class I",
        "price": 55.72
    }, {
        "symbol": "SVFKX",
        "label": "Smead Value Fund Class R2",
        "price": 51.7
    }, {
        "symbol": "JARIX",
        "label": "James Alpha Global Real Estate Investments Portfolio Class I Shares",
        "price": 19.36
    }, {
        "symbol": "JMUEX",
        "label": "JPMorgan U.S. Equity Fund Class L",
        "price": 16.77
    }, {
        "symbol": "FIDZX",
        "label": "Fidelity Advisor International Capital Appreciation Fund Class Z",
        "price": 24.96
    }, {
        "symbol": "SVFAX",
        "label": "Smead Value Fund Class A",
        "price": 50.48
    }, {
        "symbol": "SCUVX",
        "label": "Hartford Schroders US Small Cap Opportunities Fund Class A",
        "price": 26.57
    }, {
        "symbol": "FCPCX",
        "label": "Fidelity Advisor International Capital Appreciation Fund Class C",
        "price": 20.48
    }, {
        "symbol": "NEJYX",
        "label": "Natixis Funds Trust I Vaughan Nelson Small Cap Value Fund Class Y",
        "price": 16.66
    }, {
        "symbol": "MASTX",
        "label": "BMO Large-Cap Growth Fund Class Y",
        "price": 19.42
    }, {
        "symbol": "LSVAX",
        "label": "Loomis Sayles Small Capital Value Fund Admin Class",
        "price": 26.23
    }, {
        "symbol": "LSCNX",
        "label": "Loomis Sayles Small Cap Value Fund Class N",
        "price": 28.28
    }, {
        "symbol": "LSSCX",
        "label": "Loomis Sayles Small Capital Value Fund Institutional Class",
        "price": 28.28
    }, {
        "symbol": "VSEQX",
        "label": "Vanguard Strategic Equity Fund Investor Shares",
        "price": 34.31
    }, {
        "symbol": "JUEZX",
        "label": "JPMorgan U.S. Equity Fund Class R2",
        "price": 16.49
    }, {
        "symbol": "MMDEX",
        "label": "Praxis Growth Index Fund Class I",
        "price": 28.72
    }, {
        "symbol": "SMVLX",
        "label": "Smead Value Fund Investor Class",
        "price": 50.84
    }, {
        "symbol": "PAVLX",
        "label": "T. Rowe Price Value Fund Advisor Class",
        "price": 38.29
    }, {
        "symbol": "PRILX",
        "label": "Parnassus Core Equity Fund - Institutional Shares",
        "price": 48.52
    }, {
        "symbol": "PRGIX",
        "label": "T. Rowe Price Growth and Income Fund",
        "price": 31.34
    }, {
        "symbol": "FIDGX",
        "label": "Fidelity Advisor Small Cap Growth Fund Class Z",
        "price": 28.78
    }, {
        "symbol": "DSCVX",
        "label": "BNY Mellon Opportunistic Small Cap Fund Investor Shares",
        "price": 28.28
    }, {
        "symbol": "FCIGX",
        "label": "Fidelity Advisor Small Cap Growth Fund I Class",
        "price": 28.69
    }, {
        "symbol": "TRVLX",
        "label": "T. Rowe Price Value Fund",
        "price": 38.89
    }, {
        "symbol": "FTUCX",
        "label": "Fidelity Advisor Telecommunications Fund Class C",
        "price": 61.84
    }, {
        "symbol": "FSTCX",
        "label": "Fidelity Select Telecommunications Portfolio",
        "price": 62.46
    }, {
        "symbol": "TIIRX",
        "label": "TIAA-CREF Growth & Income Fund Retail Class",
        "price": 21.25
    }, {
        "symbol": "FTUIX",
        "label": "Fidelity Advisor Telecommunications Fund I Class",
        "price": 62.32
    }, {
        "symbol": "AIAFX",
        "label": "Aberdeen Global Infrastructure Fund Class A",
        "price": 22.84
    }, {
        "symbol": "HMDCX",
        "label": "The Hartford MidCap Fund Class C",
        "price": 21.04
    }, {
        "symbol": "FTUAX",
        "label": "Fidelity Advisor Telecommunications Fund Class A",
        "price": 62.08
    }, {
        "symbol": "TRGIX",
        "label": "TIAA-CREF Growth & Income Fund Retirement Class",
        "price": 15.59
    }, {
        "symbol": "ACRTX",
        "label": "AMG Managers Silvercrest Small Cap Fund Class I",
        "price": 16.74
    }, {
        "symbol": "CLCRX",
        "label": "Columbia Select Large Cap Equity Fund Institutional 2 Class",
        "price": 16.16
    }, {
        "symbol": "BUFIX",
        "label": "Buffalo International Fund",
        "price": 17.21
    }, {
        "symbol": "FCAGX",
        "label": "Fidelity Advisor Small Cap Growth Fund Class A",
        "price": 27.19
    }, {
        "symbol": "PRBLX",
        "label": "Parnassus Core Equity Fund - Investor Shares",
        "price": 48.45
    }, {
        "symbol": "CAMYX",
        "label": "Cambiar International Equity Institutional",
        "price": 26.96
    }, {
        "symbol": "CAMIX",
        "label": "Cambiar International Equity Fund Investor Class",
        "price": 26.94
    }, {
        "symbol": "DTSVX",
        "label": "Wilshire Small Company Value Portfolio Investment Class Shares",
        "price": 21.93
    }, {
        "symbol": "WEMMX",
        "label": "TETON Westwood Mighty Mites Fund Class AAA",
        "price": 26.82
    }, {
        "symbol": "SMGIX",
        "label": "Columbia Contrarian Core Fund Institutional Class",
        "price": 27.9
    }, {
        "symbol": "HFMVX",
        "label": "The Hartford MidCap Fund Class R6",
        "price": 39.06
    }, {
        "symbol": "COGRX",
        "label": "Columbia Select Global Growth Fund Class R",
        "price": 17.1
    }, {
        "symbol": "FCCGX",
        "label": "Fidelity Advisor Small Cap Growth Fund Class C",
        "price": 23.64
    }, {
        "symbol": "BLGRX",
        "label": "BMO Large-Cap Growth Fund Class R6",
        "price": 19.64
    }, {
        "symbol": "CDOFX",
        "label": "Crawford Small Cap Dividend Fund",
        "price": 42.63
    }, {
        "symbol": "LCCCX",
        "label": "Columbia Contrarian Core Fund Class C",
        "price": 24.76
    }, {
        "symbol": "ASCTX",
        "label": "AMG Managers Silvercrest Small Cap Fund Class N",
        "price": 16.54
    }, {
        "symbol": "LGRNX",
        "label": "Loomis Sayles Growth Fund Class N",
        "price": 19.15
    }, {
        "symbol": "DSCYX",
        "label": "BNY Mellon Opportunistic Small Cap Fund Class Y",
        "price": 28.48
    }, {
        "symbol": "WMMAX",
        "label": "TETON Westwood Mighty Mites Fund Class A",
        "price": 25.61
    }, {
        "symbol": "MLCIX",
        "label": "BMO Large-Cap Growth Fund Class I",
        "price": 19.61
    }, {
        "symbol": "VQSRX",
        "label": "Virtus KAR Small-Cap Value Fund Class R6",
        "price": 19.62
    }, {
        "symbol": "CADIX",
        "label": "Columbia Select Global Growth Fund Institutional 2 Class",
        "price": 18.11
    }, {
        "symbol": "TRPIX",
        "label": "T. Rowe Price Value Fund I Class",
        "price": 38.88
    }, {
        "symbol": "BARIX",
        "label": "Baron Asset Fund Institutional Class",
        "price": 94.23
    }, {
        "symbol": "DOPIX",
        "label": "BNY Mellon Opportunistic Small Cap Fund Class I",
        "price": 28.45
    }, {
        "symbol": "VSTCX",
        "label": "Vanguard Strategic Small-Cap Equity Fund Investor Shares",
        "price": 33.66
    }, {
        "symbol": "JSMTX",
        "label": "Jackson Square SMID-Cap Growth Fund Institutional Class",
        "price": 24.89
    }, {
        "symbol": "MIMSX",
        "label": "BNY Mellon Mid Cap Multi-Strategy Fund Class Investor",
        "price": 18.58
    }, {
        "symbol": "DCGTX",
        "label": "Jackson Square SMID-Cap Growth Fund IS Class",
        "price": 24.89
    }, {
        "symbol": "CDOCX",
        "label": "Crawford Small Cap Dividend Fund Class C",
        "price": 42.35
    }, {
        "symbol": "WEIMX",
        "label": "TETON Westwood Mighty Mites Class Institutional",
        "price": 27.62
    }, {
        "symbol": "CCCRX",
        "label": "Columbia Contrarian Core Fund Class R",
        "price": 27.71
    }, {
        "symbol": "JENHX",
        "label": "Johnson Enhanced Return Fund",
        "price": 17.5
    }, {
        "symbol": "CSVIX",
        "label": "Calvert Small-Cap Fund Class I",
        "price": 28.73
    }, {
        "symbol": "CSCCX",
        "label": "Calvert Small-Cap Fund Class C",
        "price": 22.55
    }, {
        "symbol": "BARAX",
        "label": "Baron Asset Fund Retail Class",
        "price": 90.37
    }, {
        "symbol": "TAREX",
        "label": "Third Avenue Real Estate Value Fund Institutional Class",
        "price": 26.28
    }, {
        "symbol": "VPCCX",
        "label": "Vanguard PRIMECAP Core Fund Investor Shares",
        "price": 28.65
    }, {
        "symbol": "BALGX",
        "label": "BMO Large-Cap Growth Fund Class A",
        "price": 19.4
    }, {
        "symbol": "COFYX",
        "label": "Columbia Contrarian Core Fund Institutional 3 Class",
        "price": 28.43
    }, {
        "symbol": "CORRX",
        "label": "Columbia Contrarian Core Fund Advisor Class",
        "price": 28.44
    }, {
        "symbol": "JSMVX",
        "label": "Jackson Square SMID-Cap Growth Fund Investor Class",
        "price": 24.67
    }, {
        "symbol": "BARUX",
        "label": "Baron Asset Fund Class R6",
        "price": 94.21
    }, {
        "symbol": "HFMSX",
        "label": "The Hartford MidCap Fund Class R4",
        "price": 37.21
    }, {
        "symbol": "CCVAX",
        "label": "Calvert Small-Cap Fund Class A",
        "price": 26.51
    }, {
        "symbol": "EWENX",
        "label": "Wells Fargo Intrinsic World Equity Fund - Class Inst",
        "price": 22.82
    }, {
        "symbol": "SGIEX",
        "label": "Columbia Contrarian Core Fund Class V",
        "price": 27.37
    }, {
        "symbol": "WMMCX",
        "label": "TETON Westwood Mighty Mites Fund Class C",
        "price": 22.02
    }, {
        "symbol": "FSSNX",
        "label": "Fidelity Small Cap Index Fund",
        "price": 21.43
    }, {
        "symbol": "WSMVX",
        "label": "Wilshire Small Company Value Fund Class Institutional",
        "price": 22.42
    }, {
        "symbol": "IMIDX",
        "label": "Congress Mid Cap Growth Fund Institutional Class",
        "price": 23.07
    }, {
        "symbol": "JANEX",
        "label": "Janus Henderson Enterprise Fund Class D",
        "price": 146.9
    }, {
        "symbol": "JDMAX",
        "label": "Janus Henderson Enterprise Fund Class A",
        "price": 142.74
    }, {
        "symbol": "JMGRX",
        "label": "Janus Henderson Enterprise Fund Class I",
        "price": 147.68
    }, {
        "symbol": "HFMIX",
        "label": "The Hartford MidCap Fund Class I",
        "price": 32.7
    }, {
        "symbol": "JDMNX",
        "label": "Janus Henderson Enterprise Fund Class N",
        "price": 148.24
    }, {
        "symbol": "SSPSX",
        "label": "State Street Institutional Premier Growth Equity Fund Service Class",
        "price": 0.0
    }, {
        "symbol": "JGRTX",
        "label": "Janus Henderson Enterprise Fund Class S",
        "price": 141.92
    }, {
        "symbol": "JAENX",
        "label": "Janus Henderson Enterprise Fund Class T",
        "price": 145.89
    }, {
        "symbol": "JDMRX",
        "label": "Janus Henderson Enterprise Fund Class R",
        "price": 137.43
    }, {
        "symbol": "JGRCX",
        "label": "Janus Henderson Enterprise Fund Class C",
        "price": 130.81
    }, {
        "symbol": "CMIDX",
        "label": "Congress Mid Cap Growth Fund Retail Class",
        "price": 22.76
    }, {
        "symbol": "NEFSX",
        "label": "Natixis Funds Trust I U.S. Equity Opportunities Fund Class A",
        "price": 37.83
    }, {
        "symbol": "EAFGX",
        "label": "Eaton Vance Focused Growth Opportunities Fund Class A",
        "price": 24.12
    }, {
        "symbol": "FSPCX",
        "label": "Fidelity Select Insurance Portfolio",
        "price": 66.04
    }, {
        "symbol": "JMCEX",
        "label": "JPMorgan Mid Cap Equity Fund Class R2",
        "price": 50.49
    }, {
        "symbol": "NSDVX",
        "label": "North Star Dividend Fund Class I",
        "price": 20.22
    }, {
        "symbol": "PRGSX",
        "label": "T. Rowe Price Global Stock Fund",
        "price": 47.9
    }, {
        "symbol": "PRDMX",
        "label": "T. Rowe Price Diversified Mid Cap Growth Fund",
        "price": 38.12
    }, {
        "symbol": "LGRCX",
        "label": "Loomis Sayles Growth Fund Class C",
        "price": 16.18
    }, {
        "symbol": "TVRVX",
        "label": "Third Avenue Real Estate Value Fund Investor Class",
        "price": 26.18
    }, {
        "symbol": "JCMAX",
        "label": "JPMorgan Mid Cap Equity Fund Class A",
        "price": 51.04
    }, {
        "symbol": "VSNGX",
        "label": "JPMorgan Mid Cap Equity Fund Class I",
        "price": 51.93
    }, {
        "symbol": "TWCAX",
        "label": "American Century Select Fund A Class",
        "price": 81.76
    }, {
        "symbol": "LSGRX",
        "label": "Loomis Sayles Growth Fund Class Y",
        "price": 19.16
    }, {
        "symbol": "ASDEX",
        "label": "American Century Select Fund R6 Class",
        "price": 85.82
    }, {
        "symbol": "TWSIX",
        "label": "American Century Select Fund I Class",
        "price": 85.77
    }, {
        "symbol": "NESYX",
        "label": "Natixis Funds Trust I U.S. Equity Opportunities Fund Class Y",
        "price": 45.12
    }, {
        "symbol": "HILFX",
        "label": "Hennessy Large Cap Financial Fund Institutional Class",
        "price": 25.36
    }, {
        "symbol": "PAGSX",
        "label": "T. Rowe Price Global Stock Fund Advisor Class",
        "price": 47.37
    }, {
        "symbol": "CFIMX",
        "label": "Clipper Fund",
        "price": 126.64
    }, {
        "symbol": "CCSGX",
        "label": "Conestoga SMid Cap Fund Institutional Class",
        "price": 18.56
    }, {
        "symbol": "BUFTX",
        "label": "Buffalo Discovery Fund",
        "price": 25.2
    }, {
        "symbol": "TWCIX",
        "label": "American Century Select Fund Investor Class",
        "price": 84.06
    }, {
        "symbol": "GWETX",
        "label": "AMG GW&K Small Cap Core Fund Class N",
        "price": 26.75
    }, {
        "symbol": "RAAIX",
        "label": "Altegris/AACA Opportunistic Real Estate Fund I",
        "price": 18.7
    }, {
        "symbol": "ACSLX",
        "label": "American Century Select Fund C Class",
        "price": 71.45
    }, {
        "symbol": "ASERX",
        "label": "American Century Select Fund R Class",
        "price": 80.73
    }, {
        "symbol": "PNAIX",
        "label": "T. Rowe Price New America Growth Fund I Class",
        "price": 57.84
    }, {
        "symbol": "AULDX",
        "label": "American Century Ultra Fund R6 Class",
        "price": 57.36
    }, {
        "symbol": "PRWAX",
        "label": "T. Rowe Price New America Growth Fund",
        "price": 57.86
    }, {
        "symbol": "SSPGX",
        "label": "State Street Institutional Premier Growth Equity Fund Investment Class",
        "price": 0.0
    }, {
        "symbol": "LGRRX",
        "label": "Loomis Sayles Growth Fund Class A",
        "price": 17.87
    }, {
        "symbol": "RAANX",
        "label": "Altegris/AACA Opportunistic Real Estate Fund N",
        "price": 18.59
    }, {
        "symbol": "RAAAX",
        "label": "Altegris/AACA Opportunistic Real Estate Fund A",
        "price": 18.59
    }, {
        "symbol": "IWIRX",
        "label": "Guinness Atkinson Global Innovators Fund Investor Class",
        "price": 47.75
    }, {
        "symbol": "PGIUX",
        "label": "Virtus Duff & Phelps Global Infrastructure Fund Class I",
        "price": 16.57
    }, {
        "symbol": "PGUAX",
        "label": "Virtus Duff & Phelps Global Infrastructure Fund Class A",
        "price": 16.56
    }, {
        "symbol": "PAWAX",
        "label": "T. Rowe Price New America Growth Fund Advisor Class",
        "price": 56.4
    }, {
        "symbol": "BREFX",
        "label": "Baron Real Estate Fund Retail Shares",
        "price": 26.97
    }, {
        "symbol": "PEXMX",
        "label": "T. Rowe Price Extended Equity Market Index Fund",
        "price": 30.12
    }, {
        "symbol": "PGUCX",
        "label": "Virtus Duff & Phelps Global Infrastructure Fund Class C",
        "price": 16.49
    }, {
        "symbol": "GWEIX",
        "label": "AMG GW&K Small Cap Core Fund Class I",
        "price": 27.24
    }, {
        "symbol": "AWEIX",
        "label": "CIBC Atlas Disciplined Equity Fund Institutional Class",
        "price": 23.51
    }, {
        "symbol": "JPDEX",
        "label": "JPMorgan Tax Aware Equity Fund Class I",
        "price": 38.8
    }, {
        "symbol": "JVTNX",
        "label": "Janus Henderson Venture Fund Class N",
        "price": 0.0
    }, {
        "symbol": "JVTSX",
        "label": "Janus Henderson Venture Fund Class S",
        "price": 0.0
    }, {
        "symbol": "JPEAX",
        "label": "JPMorgan Tax Aware Equity Fund Class A",
        "price": 38.55
    }, {
        "symbol": "CVMAX",
        "label": "Calvert Emerging Markets Equity Fund Class A",
        "price": 18.26
    }, {
        "symbol": "GINNX",
        "label": "Guinness Atkinson Global Innovators Fund Institutional Class",
        "price": 47.94
    }, {
        "symbol": "JAVTX",
        "label": "Janus Henderson Venture Fund Class T",
        "price": 83.61
    }, {
        "symbol": "TWCCX",
        "label": "American Century Ultra Fund C Class",
        "price": 42.73
    }, {
        "symbol": "ICSCX",
        "label": "ICM Small Company Portfolio",
        "price": 29.2
    }, {
        "symbol": "JVTIX",
        "label": "Janus Henderson Venture Fund Class I",
        "price": 85.38
    }, {
        "symbol": "POLRX",
        "label": "Polen Growth Fund Investor Class",
        "price": 36.03
    }, {
        "symbol": "FSRPX",
        "label": "Fidelity Select Retailing Portfolio",
        "price": 17.03
    }, {
        "symbol": "JANVX",
        "label": "Janus Henderson Venture Fund Class D",
        "price": 84.93
    }, {
        "symbol": "MPGIX",
        "label": "Meritage Growth Equity Fund Institutional Shares",
        "price": 17.91
    }, {
        "symbol": "HLFNX",
        "label": "Hennessy Large Cap Financial Fund Investor Class",
        "price": 25.29
    }, {
        "symbol": "JVTCX",
        "label": "Janus Henderson Venture Fund Class C",
        "price": 75.03
    }, {
        "symbol": "NNLEX",
        "label": "Nicholas Limited Edition Fund Class N",
        "price": 27.29
    }, {
        "symbol": "SCGVX",
        "label": "Sands Capital Global Growth Fund Investor Shares",
        "price": 27.71
    }, {
        "symbol": "MPGEX",
        "label": "Meritage Growth Equity Fund Investor Shares",
        "price": 17.66
    }, {
        "symbol": "TLIIX",
        "label": "TIAA-CREF Quant Large-Cap Growth Fund Institutional Class",
        "price": 15.58
    }, {
        "symbol": "JIGZX",
        "label": "JPMorgan Intrepid Growth Fund Class R2",
        "price": 55.62
    }, {
        "symbol": "QASCX",
        "label": "Federated MDT Small Cap Core Fund Class A",
        "price": 19.62
    }, {
        "symbol": "JPGSX",
        "label": "JPMorgan Intrepid Growth Fund Class I",
        "price": 58.2
    }, {
        "symbol": "JIGAX",
        "label": "JPMorgan Intrepid Growth Fund Class A",
        "price": 57.1
    }, {
        "symbol": "QISCX",
        "label": "Federated MDT Small Cap Core Fund Institutional Class",
        "price": 20.36
    }, {
        "symbol": "SCMGX",
        "label": "Sands Capital Global Growth Fund Institutional Shares",
        "price": 28.17
    }, {
        "symbol": "VHCOX",
        "label": "Vanguard Capital Opportunity Fund Investor Shares",
        "price": 70.83
    }, {
        "symbol": "BDSKX",
        "label": "BlackRock Advantage Small Cap Core Fund Class K",
        "price": 15.82
    }, {
        "symbol": "JCICX",
        "label": "JPMorgan Intrepid Growth Fund Class C",
        "price": 55.88
    }, {
        "symbol": "BDSIX",
        "label": "BlackRock Advantage Small Cap Core Fund Institutional Shares",
        "price": 15.81
    }, {
        "symbol": "KLCIX",
        "label": "Federated Kaufmann Large Cap Fund Institutional Shares",
        "price": 30.14
    }, {
        "symbol": "JGISX",
        "label": "JPMorgan Intrepid Growth Fund Class R6",
        "price": 57.23
    }, {
        "symbol": "JGIRX",
        "label": "JPMorgan Intrepid Growth Fund Class R5",
        "price": 57.26
    }, {
        "symbol": "VGSAX",
        "label": "Virtus Duff & Phelps Global Real Estate Securities Fund Class A",
        "price": 34.4
    }, {
        "symbol": "WGROX",
        "label": "Wasatch Core Growth Fund",
        "price": 74.39
    }, {
        "symbol": "AKREX",
        "label": "Akre Focus Fund Retail Class",
        "price": 46.6
    }, {
        "symbol": "RYOCX",
        "label": "Rydex NASDAQ-100 Fund Investor Class",
        "price": 46.66
    }, {
        "symbol": "RYATX",
        "label": "Rydex NASDAQ-100 Fund Class A",
        "price": 42.69
    }, {
        "symbol": "VHCAX",
        "label": "Vanguard Capital Opportunity Fund Admiral Shares",
        "price": 163.53
    }, {
        "symbol": "FGSKX",
        "label": "Federated MDT Mid Cap Growth Fund Class R6 Shares",
        "price": 38.4
    }, {
        "symbol": "RYHOX",
        "label": "Rydex NASDAQ-100 Fund Class H",
        "price": 42.69
    }, {
        "symbol": "TEEAX",
        "label": "RBC Small Cap Core Fund Class A",
        "price": 30.71
    }, {
        "symbol": "NWKCX",
        "label": "Nationwide Geneva Small Cap Growth Fund Class R6",
        "price": 68.26
    }, {
        "symbol": "VPMAX",
        "label": "Vanguard PRIMECAP Fund Admiral Shares",
        "price": 148.42
    }, {
        "symbol": "VPMCX",
        "label": "Vanguard PRIMECAP Fund Investor Shares",
        "price": 143.22
    }, {
        "symbol": "NWHZX",
        "label": "Nationwide Geneva Small Cap Growth Fund Class A",
        "price": 65.39
    }, {
        "symbol": "SEUPX",
        "label": "Guggenheim StylePlus - Mid Growth Fund Class P",
        "price": 42.86
    }, {
        "symbol": "TMDPX",
        "label": "AMG TimesSquare Mid Cap Growth Fund Class N",
        "price": 18.58
    }, {
        "symbol": "VRGEX",
        "label": "Virtus Duff & Phelps Global Real Estate Securities Fund Class R6",
        "price": 33.53
    }, {
        "symbol": "AKRSX",
        "label": "Akre Focus Fund Supra Institutional Class",
        "price": 48.0
    }, {
        "symbol": "AVEGX",
        "label": "Ave Maria Growth Fund",
        "price": 39.53
    }, {
        "symbol": "FNCMX",
        "label": "Fidelity NASDAQ Composite Index Fund",
        "price": 117.81
    }, {
        "symbol": "VGISX",
        "label": "Virtus Duff & Phelps Global Real Estate Securities Fund Class I",
        "price": 33.45
    }, {
        "symbol": "LCGFX",
        "label": "William Blair Large Cap Growth Fund Class I",
        "price": 16.98
    }, {
        "symbol": "AKRIX",
        "label": "Akre Focus Fund Institutional Class",
        "price": 47.8
    }, {
        "symbol": "QCSCX",
        "label": "Federated MDT Small Cap Core Fund Class C",
        "price": 17.22
    }, {
        "symbol": "VGSCX",
        "label": "Virtus Duff & Phelps Global Real Estate Securities Fund Class C",
        "price": 32.74
    }, {
        "symbol": "TMDIX",
        "label": "AMG TimesSquare Mid Cap Growth Fund Class Z",
        "price": 19.33
    }, {
        "symbol": "USMYX",
        "label": "Natixis ASG Tactical U.S. Market Fund Class Y",
        "price": 16.85
    }, {
        "symbol": "OMSIX",
        "label": "Invesco Oppenheimer Main Street Fund Class R6",
        "price": 49.15
    }, {
        "symbol": "TIQIX",
        "label": "Touchstone Global ESG Equity Fund - Class Y",
        "price": 23.03
    }, {
        "symbol": "NWWOX",
        "label": "Virtus Vontobel Global Opportunities Fund Class A",
        "price": 17.81
    }, {
        "symbol": "PARDX",
        "label": "T. Rowe Price Retirement 2040 Fund Advisor Class",
        "price": 27.52
    }, {
        "symbol": "NOLCX",
        "label": "Northern Large Cap Core Fund",
        "price": 21.6
    }, {
        "symbol": "PYVSX",
        "label": "Payden Equity Income Fund SI Class",
        "price": 18.7
    }, {
        "symbol": "GASFX",
        "label": "Hennessy Gas Utility Fund Investor Class",
        "price": 29.33
    }, {
        "symbol": "PYVLX",
        "label": "Payden Equity Income Fund",
        "price": 18.68
    }, {
        "symbol": "RRTFX",
        "label": "T. Rowe Price Retirement 2050 Fund Class R",
        "price": 15.78
    }, {
        "symbol": "SIBAX",
        "label": "Sit Balanced Fund",
        "price": 25.96
    }, {
        "symbol": "DGLCX",
        "label": "BNY Mellon Global Stock Fund - Class C",
        "price": 22.6
    }, {
        "symbol": "FABLX",
        "label": "Fidelity Advisor Balanced Fund Class A",
        "price": 22.96
    }, {
        "symbol": "FMIJX",
        "label": "FMI International Fund",
        "price": 32.92
    }, {
        "symbol": "DROYX",
        "label": "Diamond Hill Research Opportunities Fund Class Y",
        "price": 24.22
    }, {
        "symbol": "SGACX",
        "label": "Virtus SGA Global Growth C Class",
        "price": 22.33
    }, {
        "symbol": "AVEDX",
        "label": "Ave Maria Rising Dividend Fund",
        "price": 19.02
    }, {
        "symbol": "TRRJX",
        "label": "T. Rowe Price Retirement 2035 Fund",
        "price": 19.5
    }, {
        "symbol": "TFIFX",
        "label": "T. Rowe Price Financial Services Fund I Class",
        "price": 28.41
    }, {
        "symbol": "AUEIX",
        "label": "AQR Large Cap Defensive Style Fund Class I",
        "price": 24.5
    }, {
        "symbol": "RRTDX",
        "label": "T. Rowe Price Retirement 2040 Fund Class R",
        "price": 27.33
    }, {
        "symbol": "QUERX",
        "label": "AQR Large Cap Defensive Style Fund Class R6",
        "price": 24.46
    }, {
        "symbol": "DGLRX",
        "label": "BNY Mellon Global Stock Fund - Class I",
        "price": 23.73
    }, {
        "symbol": "AUENX",
        "label": "AQR Large Cap Defensive Style Fund Class N",
        "price": 24.47
    }, {
        "symbol": "SGAPX",
        "label": "Virtus SGA Global Growth I Class",
        "price": 23.58
    }, {
        "symbol": "VWILX",
        "label": "Vanguard International Growth Fund Admiral Shares",
        "price": 106.42
    }, {
        "symbol": "TRACX",
        "label": "PGIM QMA Small-Cap Value Fund- Class C",
        "price": 16.64
    }, {
        "symbol": "SDVGX",
        "label": "Sit Dividend Growth Fund Class I",
        "price": 0.0
    }, {
        "symbol": "PARKX",
        "label": "T. Rowe Price Retirement 2035 Fund Advisor Class",
        "price": 19.41
    }, {
        "symbol": "MGGIX",
        "label": "Morgan Stanley Institutional Fund, Inc. Global Opportunity Portfolio Class I",
        "price": 30.77
    }, {
        "symbol": "SDVSX",
        "label": "Sit Dividend Growth Fund Class S",
        "price": 0.0
    }, {
        "symbol": "SMDVX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class A",
        "price": 16.03
    }, {
        "symbol": "CSSZX",
        "label": "Columbia Select Small Cap Value Fund Institutional Class",
        "price": 20.48
    }, {
        "symbol": "VASVX",
        "label": "Vanguard Selected Value Fund Investor Shares",
        "price": 27.38
    }, {
        "symbol": "DGIFX",
        "label": "The Disciplined Growth Investors Fund",
        "price": 22.02
    }, {
        "symbol": "BPSIX",
        "label": "Boston Partners Small Cap Value Fund II Institutional Class",
        "price": 25.97
    }, {
        "symbol": "TGIPX",
        "label": "T. Rowe Price Personal Strategy Growth Fund I Class",
        "price": 36.91
    }, {
        "symbol": "FPVSX",
        "label": "Frontier Phocas Small Cap Value Fund Service Class",
        "price": 33.02
    }, {
        "symbol": "SSCVX",
        "label": "Columbia Select Small Cap Value Fund Class A",
        "price": 17.73
    }, {
        "symbol": "DGLAX",
        "label": "BNY Mellon Global Stock Fund - Class A",
        "price": 23.38
    }, {
        "symbol": "TRSGX",
        "label": "T. Rowe Price Personal Strategy Growth Fund",
        "price": 36.83
    }, {
        "symbol": "FEYIX",
        "label": "Fidelity Advisor Asset Manager 85% Fund I Class",
        "price": 20.55
    }, {
        "symbol": "RPTIX",
        "label": "T. Rowe Price Mid-Cap Growth Fund I Class",
        "price": 98.39
    }, {
        "symbol": "DHROX",
        "label": "Diamond Hill Research Opportunities Fund Class A",
        "price": 23.75
    }, {
        "symbol": "CVTRX",
        "label": "Calamos Growth & Income Fund Class A",
        "price": 35.51
    }, {
        "symbol": "NLGIX",
        "label": "Columbia Global Strategic Equity Fund Class A",
        "price": 15.74
    }, {
        "symbol": "TEQAX",
        "label": "Touchstone Global ESG Equity Fund - Class A",
        "price": 22.19
    }, {
        "symbol": "MVALX",
        "label": "Meridian Contrarian Fund Legacy Class",
        "price": 35.92
    }, {
        "symbol": "TRRKX",
        "label": "T. Rowe Price Retirement 2045 Fund",
        "price": 19.02
    }, {
        "symbol": "HWLCX",
        "label": "Hotchkis & Wiley Large Cap Value Fund Class C",
        "price": 35.36
    }, {
        "symbol": "CGIIX",
        "label": "Calamos Growth & Income Fund Institutional Class",
        "price": 33.98
    }, {
        "symbol": "PAMCX",
        "label": "T. Rowe Price Mid-Cap Growth Fund Advisor Class",
        "price": 95.03
    }, {
        "symbol": "JDNAX",
        "label": "Janus Henderson Growth And Income Fund Class A",
        "price": 62.56
    }, {
        "symbol": "ABASX",
        "label": "AB Discovery Value Fund Class A",
        "price": 20.01
    }, {
        "symbol": "NLCDX",
        "label": "Nuveen Large Cap Core Fund Class C",
        "price": 33.17
    }, {
        "symbol": "FCAZX",
        "label": "Franklin Corefolio Allocation Fund Advisor Class",
        "price": 20.69
    }, {
        "symbol": "GILIX",
        "label": "Guggenheim StylePlus - Large Core Fund Institutional",
        "price": 22.41
    }, {
        "symbol": "HFCSX",
        "label": "Hennessy Focus Fund Investor Class",
        "price": 82.65
    }, {
        "symbol": "MIEIX",
        "label": "MFS Institutional International Equity Fund",
        "price": 28.45
    }, {
        "symbol": "FMGIX",
        "label": "Frontier MFG Core Infrastructure Fund Institutional Class",
        "price": 18.81
    }, {
        "symbol": "HLMGX",
        "label": "Harding Loevner Global Equity Portfolio Advisor Class",
        "price": 39.11
    }, {
        "symbol": "NMIAX",
        "label": "Columbia Large Cap Enhanced Core Fund Class A",
        "price": 26.23
    }, {
        "symbol": "NMIMX",
        "label": "Columbia Large Cap Enhanced Core Fund Institutional Class",
        "price": 26.18
    }, {
        "symbol": "RPMGX",
        "label": "T. Rowe Price Mid-Cap Growth Fund",
        "price": 98.41
    }, {
        "symbol": "MFCIX",
        "label": "Meridian Contrarian Fund Investor Class",
        "price": 35.63
    }, {
        "symbol": "FCVIX",
        "label": "Fidelity Advisor Small Cap Value Fund Class I",
        "price": 15.63
    }, {
        "symbol": "PARLX",
        "label": "T. Rowe Price Retirement 2045 Fund Advisor Class",
        "price": 18.9
    }, {
        "symbol": "SFEPX",
        "label": "Guggenheim StylePlus - Large Core Fund Class P",
        "price": 22.34
    }, {
        "symbol": "ABSIX",
        "label": "AB Discovery Value Fund Class I",
        "price": 19.83
    }, {
        "symbol": "RRMGX",
        "label": "T. Rowe Price Mid-Cap Growth Fund R Class",
        "price": 91.62
    }, {
        "symbol": "NNTWX",
        "label": "Nicholas II Fund Class N",
        "price": 28.42
    }, {
        "symbol": "ABSZX",
        "label": "AB Discovery Value Fund Class Z",
        "price": 19.79
    }, {
        "symbol": "AOVIX",
        "label": "American Century Investments One Choice Portfolio: Very Aggressive Investor Clas" +
                "s",
        "price": 18.24
    }, {
        "symbol": "MFCCX",
        "label": "Meridian Contrarian Fund Class C",
        "price": 34.37
    }, {
        "symbol": "HWAIX",
        "label": "Hotchkis & Wiley Value Opportunities Fund Class Institutional",
        "price": 29.29
    }, {
        "symbol": "HFCIX",
        "label": "Hennessy Focus Fund Institutional Class",
        "price": 85.35
    }, {
        "symbol": "TAAIX",
        "label": "Thrivent Aggressive Allocation Fund Class S",
        "price": 16.07
    }, {
        "symbol": "SFDYX",
        "label": "SEI Institutional Managed Trust Mid-Cap Fund Class Y",
        "price": 26.66
    }, {
        "symbol": "SEMCX",
        "label": "SEI Institutional Managed Trust Mid-Cap Portfolio Fund Class F",
        "price": 26.65
    }, {
        "symbol": "FBIOX",
        "label": "Fidelity Select Biotechnology Portfolio",
        "price": 22.45
    }, {
        "symbol": "SIPIX",
        "label": "SEI Institutional Managed Trust Mid-Cap Portfolio Fund Class I",
        "price": 26.62
    }, {
        "symbol": "PRISX",
        "label": "T. Rowe Price Financial Services Fund",
        "price": 28.42
    }, {
        "symbol": "NLACX",
        "label": "Nuveen Large Cap Core Fund Class A",
        "price": 33.76
    }, {
        "symbol": "ABSKX",
        "label": "AB Discovery Value Fund Class K",
        "price": 19.69
    }, {
        "symbol": "HWSAX",
        "label": "Hotchkis & Wiley Small Cap Value Fund Class A",
        "price": 53.54
    }, {
        "symbol": "NLCIX",
        "label": "Nuveen Large Cap Core Fund Class I",
        "price": 33.65
    }, {
        "symbol": "PASVX",
        "label": "T. Rowe Price Small-Cap Value Fund Advisor Class",
        "price": 48.34
    }, {
        "symbol": "RRTRX",
        "label": "T. Rowe Price Retirement 2045 Fund Class R",
        "price": 18.67
    }, {
        "symbol": "SMDRX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class SDR",
        "price": 16.7
    }, {
        "symbol": "PAROX",
        "label": "T. Rowe Price Retirement 2055 Fund Advisor Class",
        "price": 16.22
    }, {
        "symbol": "MGPIX",
        "label": "ProFunds Mid Cap Growth Fund Investor Class",
        "price": 99.76
    }, {
        "symbol": "FPSVX",
        "label": "Frontier Phocas Small Cap Value Fund Institutional Class",
        "price": 33.14
    }, {
        "symbol": "HFDYX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class Y",
        "price": 16.66
    }, {
        "symbol": "SMDIX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class I",
        "price": 16.67
    }, {
        "symbol": "CVTCX",
        "label": "Calamos Growth & Income Fund Class C",
        "price": 35.8
    }, {
        "symbol": "NCTWX",
        "label": "Nicholas II Fund Class I",
        "price": 29.12
    }, {
        "symbol": "TAAAX",
        "label": "Thrivent Aggressive Allocation Fund Class A",
        "price": 15.91
    }, {
        "symbol": "HWSCX",
        "label": "Hotchkis & Wiley Small Cap Value Fund Class C",
        "price": 43.07
    }, {
        "symbol": "HFDTX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class R5",
        "price": 16.64
    }, {
        "symbol": "MINDX",
        "label": "Matthews India Fund Investor Class",
        "price": 24.08
    }, {
        "symbol": "SDLAX",
        "label": "SEI Institutional Investments Trust Dynamic Asset Allocation Fund Class A",
        "price": 20.82
    }, {
        "symbol": "MFCAX",
        "label": "Meridian Contrarian Fund A Class",
        "price": 35.04
    }, {
        "symbol": "FTCLX",
        "label": "Franklin Corefolio Allocation Fund Class C",
        "price": 20.35
    }, {
        "symbol": "PMEGX",
        "label": "T. Rowe Price Institutional Mid-Cap Equity Growth Fund",
        "price": 63.14
    }, {
        "symbol": "PJOQX",
        "label": "PGIM Jennison Focused Value Fund- Class R6",
        "price": 16.76
    }, {
        "symbol": "FAMWX",
        "label": "FAM Value Fund Institutional Class",
        "price": 82.74
    }, {
        "symbol": "FAMVX",
        "label": "FAM Value Fund Investor Class",
        "price": 82.42
    }, {
        "symbol": "ABYSX",
        "label": "AB Discovery Value Fund Advisor Class",
        "price": 20.54
    }, {
        "symbol": "HWSIX",
        "label": "Hotchkis & Wiley Small Cap Value Fund Class I",
        "price": 53.75
    }, {
        "symbol": "CAAPX",
        "label": "Ariel Appreciation Fund Investor Class",
        "price": 45.49
    }, {
        "symbol": "BPSCX",
        "label": "Boston Partners Small Cap Value Fund II Investor Class",
        "price": 24.78
    }, {
        "symbol": "HLMVX",
        "label": "Harding Loevner Global Equity Portfolio Institutional Class",
        "price": 39.08
    }, {
        "symbol": "GTLIX",
        "label": "Glenmede Quantitative U.S. Large Cap Core Equity Portfolio Institutional Shares",
        "price": 28.16
    }, {
        "symbol": "HGGAX",
        "label": "Harbor Global Leaders Fund Institutional Class",
        "price": 32.13
    }, {
        "symbol": "MSFLX",
        "label": "Morgan Stanley Institutional Fund, Inc. Global Franchise Portfolio Class L",
        "price": 28.94
    }, {
        "symbol": "PARFX",
        "label": "T. Rowe Price Retirement 2050 Fund Advisor Class",
        "price": 15.92
    }, {
        "symbol": "ABSRX",
        "label": "AB Discovery Value Fund Class R",
        "price": 19.33
    }, {
        "symbol": "HFDRX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class R3",
        "price": 16.46
    }, {
        "symbol": "PRVIX",
        "label": "T. Rowe Price Small-Cap Value Fund I Class",
        "price": 48.44
    }, {
        "symbol": "PRSVX",
        "label": "T. Rowe Price Small-Cap Value Fund",
        "price": 48.46
    }, {
        "symbol": "PDGIX",
        "label": "T. Rowe Price Dividend Growth Fund I Class",
        "price": 54.84
    }, {
        "symbol": "JPIEX",
        "label": "JPMorgan U.S. Research Enhanced Equity Fund Class L",
        "price": 28.4
    }, {
        "symbol": "POGSX",
        "label": "Pin Oak Equity Fund",
        "price": 76.09
    }, {
        "symbol": "ODMCX",
        "label": "JPMorgan Intrepid Mid Cap Fund Class C",
        "price": 0.0
    }, {
        "symbol": "ACMYX",
        "label": "American Beacon Mid-Cap Value Fund Class Y",
        "price": 16.31
    }, {
        "symbol": "APGAX",
        "label": "AB Large Cap Growth Fund Class A",
        "price": 59.94
    }, {
        "symbol": "ALCKX",
        "label": "AB Large Cap Growth Fund Class K",
        "price": 61.03
    }, {
        "symbol": "SIVIX",
        "label": "State Street Institutional Small-Cap Equity Fund Investment Class",
        "price": 17.6
    }, {
        "symbol": "HFDCX",
        "label": "Hartford Schroders US MidCap Opportunities Fund Class C",
        "price": 16.23
    }, {
        "symbol": "SECEX",
        "label": "Guggenheim StylePlus - Large Core Fund Class A",
        "price": 22.63
    }, {
        "symbol": "APGYX",
        "label": "AB Large Cap Growth Fund Advisor Class",
        "price": 66.09
    }, {
        "symbol": "SSQSX",
        "label": "State Street Institutional Small-Cap Equity Fund Service Class",
        "price": 17.61
    }, {
        "symbol": "HWAAX",
        "label": "Hotchkis & Wiley Value Opportunities Fund Class A",
        "price": 29.39
    }, {
        "symbol": "APGZX",
        "label": "AB Large Cap Growth Fund Class Z",
        "price": 65.88
    }, {
        "symbol": "AUUCX",
        "label": "AB Select US Equity Portfolio Class C",
        "price": 16.46
    }, {
        "symbol": "APGCX",
        "label": "AB Large Cap Growth Fund Class C",
        "price": 42.65
    }, {
        "symbol": "PLBBX",
        "label": "Plumb Balanced Fund",
        "price": 33.77
    }, {
        "symbol": "MIDNX",
        "label": "Matthews India Fund Institutional Class",
        "price": 24.37
    }, {
        "symbol": "PRDGX",
        "label": "T. Rowe Price Dividend Growth Fund",
        "price": 54.86
    }, {
        "symbol": "VDIGX",
        "label": "Vanguard Dividend Growth Fund Investor Shares",
        "price": 31.51
    }, {
        "symbol": "ABMAX",
        "label": "American Beacon Mid-Cap Value Fund Class A",
        "price": 16.15
    }, {
        "symbol": "SICWX",
        "label": "Touchstone Large Cap Focused Fund Class Y",
        "price": 44.26
    }, {
        "symbol": "TADGX",
        "label": "T. Rowe Price Dividend Growth Fund Advisor Class",
        "price": 54.79
    }, {
        "symbol": "VQNPX",
        "label": "Vanguard Growth and Income Fund Investor Shares",
        "price": 53.59
    }, {
        "symbol": "HASCX",
        "label": "Harbor Small Cap Value Fund Institutional Class",
        "price": 35.78
    }, {
        "symbol": "ENTIX",
        "label": "ERShares Global Fund Institutional Class",
        "price": 17.04
    }, {
        "symbol": "HNGIX",
        "label": "Harbor Global Leaders Fund Retirement Class",
        "price": 32.18
    }, {
        "symbol": "GRMIX",
        "label": "Nationwide S&P 500 Index Fund Class R6",
        "price": 16.66
    }, {
        "symbol": "BBHLX",
        "label": "BBH Partner Fund - International Equity Class Institutional",
        "price": 17.23
    }, {
        "symbol": "SEEKX",
        "label": "Crossmark Steward Large Cap Enhanced Index Fund Class A",
        "price": 47.76
    }, {
        "symbol": "SKSEX",
        "label": "AMG Managers Skyline Special Equities Fund Class N",
        "price": 37.74
    }, {
        "symbol": "WBVDX",
        "label": "William Blair Small Cap Value Fund Class N",
        "price": 17.64
    }, {
        "symbol": "NBGNX",
        "label": "Neuberger Berman Genesis Fund Investor Class",
        "price": 60.42
    }, {
        "symbol": "NBGEX",
        "label": "Neuberger Berman Genesis Fund Trust Class",
        "price": 60.43
    }, {
        "symbol": "NOSIX",
        "label": "Northern Stock Index Fund",
        "price": 38.23
    }, {
        "symbol": "NBGIX",
        "label": "Neuberger Berman Genesis Fund Institutional Class",
        "price": 60.29
    }, {
        "symbol": "PJIAX",
        "label": "PGIM Jennison Focused Value Fund- Class A",
        "price": 15.94
    }, {
        "symbol": "GTLOX",
        "label": "Glenmede Quantitative U.S. Large Cap Core Equity Portfolio",
        "price": 28.15
    }, {
        "symbol": "PDSIX",
        "label": "PGIM QMA Stock Index Fund- Class I",
        "price": 46.28
    }, {
        "symbol": "PSIFX",
        "label": "PGIM QMA Stock Index Fund- Class Z",
        "price": 46.29
    }, {
        "symbol": "JGMNX",
        "label": "Janus Henderson Triton Fund Class N",
        "price": 32.37
    }, {
        "symbol": "NINDX",
        "label": "Columbia Large Cap Index Fund Institutional Class",
        "price": 53.36
    }, {
        "symbol": "VGIAX",
        "label": "Vanguard Growth and Income Fund Admiral Shares",
        "price": 87.48
    }, {
        "symbol": "NWKEX",
        "label": "Nationwide WCM Focused Small Cap Fund Class R6",
        "price": 26.63
    }, {
        "symbol": "SVSPX",
        "label": "State Street S&P 500 Index Fund Class N",
        "price": 40.26
    }, {
        "symbol": "GQETX",
        "label": "GMO Quality Fund Class III",
        "price": 25.56
    }, {
        "symbol": "GRMAX",
        "label": "Nationwide S&P 500 Index Fund Class A",
        "price": 16.48
    }, {
        "symbol": "WOOPX",
        "label": "JPMorgan Intrepid Mid Cap Fund Class I",
        "price": 19.64
    }, {
        "symbol": "MYSPX",
        "label": "MainStay MacKay S&P 500 Index Fund Investor Class",
        "price": 46.06
    }, {
        "symbol": "PSIAX",
        "label": "PGIM QMA Stock Index Fund- Class A",
        "price": 46.09
    }, {
        "symbol": "PREIX",
        "label": "T. Rowe Price Equity Index 500 Fund",
        "price": 88.51
    }, {
        "symbol": "FXAIX",
        "label": "Fidelity 500 Index Fund",
        "price": 115.55
    }, {
        "symbol": "FTHSX",
        "label": "Fuller & Thaler Behavioral Small-Cap Equity Fund Institutional Shares",
        "price": 27.71
    }, {
        "symbol": "VIIIX",
        "label": "Vanguard Institutional Index Fund Institutional Plus Shares",
        "price": 299.37
    }, {
        "symbol": "VINIX",
        "label": "Vanguard Institutional Index Fund Institutional Shares",
        "price": 299.35
    }, {
        "symbol": "JSMGX",
        "label": "Janus Henderson Triton Fund Class I",
        "price": 32.18
    }, {
        "symbol": "GEQYX",
        "label": "GuideStone Funds Equity Index Fund Institutional Class",
        "price": 36.56
    }, {
        "symbol": "SENCX",
        "label": "Touchstone Large Cap Focused Fund Class A",
        "price": 44.44
    }, {
        "symbol": "PTEZX",
        "label": "PGIM QMA Large-Cap Core Equity Fund- Class Z",
        "price": 16.49
    }, {
        "symbol": "SXPAX",
        "label": "DWS S&P 500 Index Fund - Class A",
        "price": 34.69
    }, {
        "symbol": "GEQZX",
        "label": "GuideStone Funds Equity Index Fund Investor Class",
        "price": 36.58
    }, {
        "symbol": "BTIEX",
        "label": "DWS Equity 500 Index Fund - Class S",
        "price": 200.73
    }, {
        "symbol": "BTIIX",
        "label": "DWS Equity 500 Index Fund - Class Inst",
        "price": 204.41
    }, {
        "symbol": "CLXRX",
        "label": "Columbia Large Cap Index Fund Institutional 2 Class",
        "price": 54.25
    }, {
        "symbol": "DSPIX",
        "label": "BNY Mellon Institutional S&P 500 Stock Index Fund Class I",
        "price": 61.83
    }, {
        "symbol": "NWGSX",
        "label": "Nationwide WCM Focused Small Cap Fund Institutional Service Class",
        "price": 26.45
    }, {
        "symbol": "HWCCX",
        "label": "Hotchkis & Wiley Diversified Value Fund Class C",
        "price": 20.43
    }, {
        "symbol": "SPIIX",
        "label": "SEI Institutional Managed Trust S&P 500 Index Fund Class I",
        "price": 75.27
    }, {
        "symbol": "NEIAX",
        "label": "Columbia Large Cap Index Fund Class A",
        "price": 53.04
    }, {
        "symbol": "MMEYX",
        "label": "Victory Integrity Discovery Fund Class Y",
        "price": 37.82
    }, {
        "symbol": "NWGPX",
        "label": "Nationwide WCM Focused Small Cap Fund Class A",
        "price": 25.2
    }, {
        "symbol": "DSMGX",
        "label": "Touchstone International Growth Opportunities Fund Institutional Class",
        "price": 23.71
    }, {
        "symbol": "SXPCX",
        "label": "DWS S&P 500 Index Fund - Class C",
        "price": 34.6
    }, {
        "symbol": "CAEEX",
        "label": "Columbia Acorn European Fund Institutional 2 Class",
        "price": 22.91
    }, {
        "symbol": "JANIX",
        "label": "Janus Henderson Triton Fund Class D",
        "price": 31.94
    }, {
        "symbol": "GVMCX",
        "label": "Government Street Mid-Cap Fund",
        "price": 30.1
    }, {
        "symbol": "TYGGX",
        "label": "Touchstone International Growth Opportunities Fund Class Y",
        "price": 23.62
    }, {
        "symbol": "SSPIX",
        "label": "SEI Institutional Managed Trust S&P 500 Index Fund Class F",
        "price": 74.76
    }, {
        "symbol": "JGMIX",
        "label": "Janus Henderson Triton Fund Class S",
        "price": 30.66
    }, {
        "symbol": "FZAMX",
        "label": "Fidelity Advisor Mid Cap II Fund Class Z",
        "price": 20.36
    }, {
        "symbol": "JDEAX",
        "label": "JPMorgan U.S. Research Enhanced Equity Fund Class A",
        "price": 28.13
    }, {
        "symbol": "HISVX",
        "label": "Harbor Small Cap Value Fund Investor Class",
        "price": 34.83
    }, {
        "symbol": "VMCCX",
        "label": "Virtus KAR Mid-Cap Core Fund Class C",
        "price": 36.0
    }, {
        "symbol": "FMFAX",
        "label": "Fidelity Advisor Materials Fund Class A",
        "price": 68.35
    }, {
        "symbol": "PSICX",
        "label": "PGIM QMA Stock Index Fund- Class C",
        "price": 45.6
    }, {
        "symbol": "VTCIX",
        "label": "Vanguard Tax-Managed Capital Appreciation Fund Institutional Shares",
        "price": 85.13
    }, {
        "symbol": "VIMCX",
        "label": "Virtus KAR Mid-Cap Core Fund Class I",
        "price": 39.61
    }, {
        "symbol": "PRUIX",
        "label": "T. Rowe Price Equity Index 500 Fund I Class",
        "price": 88.54
    }, {
        "symbol": "VTCLX",
        "label": "Vanguard Tax-Managed Capital Appreciation Fund Admiral Shares",
        "price": 171.31
    }, {
        "symbol": "BPTRX",
        "label": "Baron Partners Fund Retail Shares",
        "price": 75.1
    }, {
        "symbol": "HNVRX",
        "label": "Harbor Small Cap Value Fund Retirement Class",
        "price": 35.78
    }, {
        "symbol": "FITIX",
        "label": "Fidelity Advisor Mid Cap II Fund Class M",
        "price": 19.14
    }, {
        "symbol": "AMPAX",
        "label": "American Beacon Mid-Cap Value Fund Investor Class",
        "price": 16.65
    }, {
        "symbol": "NESIX",
        "label": "Needham Small Cap Growth Fund Institutional Class",
        "price": 18.59
    }, {
        "symbol": "BVDIX",
        "label": "William Blair Small Cap Value Fund Class I",
        "price": 18.2
    }, {
        "symbol": "SCSCX",
        "label": "Touchstone Large Cap Focused Fund Class C",
        "price": 41.28
    }, {
        "symbol": "JUSRX",
        "label": "JPMorgan U.S. Equity Fund Class R5",
        "price": 16.77
    }, {
        "symbol": "HSVRX",
        "label": "Harbor Small Cap Value Fund Administrative Class",
        "price": 35.59
    }, {
        "symbol": "BPTIX",
        "label": "Baron Partners Fund Institutional Shares",
        "price": 77.04
    }, {
        "symbol": "BPTUX",
        "label": "Baron Partners Fund R6 Shares",
        "price": 77.04
    }, {
        "symbol": "FMFTX",
        "label": "Fidelity Advisor Materials Fund Class M",
        "price": 67.74
    }, {
        "symbol": "FSDPX",
        "label": "Fidelity Select Materials Portfolio",
        "price": 68.61
    }, {
        "symbol": "JUESX",
        "label": "JPMorgan U.S. Equity Fund Class I",
        "price": 16.73
    }, {
        "symbol": "FMFEX",
        "label": "Fidelity Advisor Materials Fund Class I",
        "price": 68.47
    }, {
        "symbol": "GRMCX",
        "label": "Nationwide S&P 500 Index Fund Class C",
        "price": 16.02
    }, {
        "symbol": "USPRX",
        "label": "USAA 500 Index Fund Reward Shares",
        "price": 45.42
    }, {
        "symbol": "USSPX",
        "label": "USAA 500 Index Fund Member Shares",
        "price": 45.4
    }, {
        "symbol": "POMIX",
        "label": "T. Rowe Price Total Equity Market Index Fund",
        "price": 36.65
    }, {
        "symbol": "JUEAX",
        "label": "JPMorgan U.S. Equity Fund Class A",
        "price": 16.67
    }, {
        "symbol": "FACSX",
        "label": "Nuveen Mid Cap Value Fund Class C",
        "price": 41.2
    }, {
        "symbol": "LCIAX",
        "label": "SEI Institutional Investments Trust Large Cap Index Fund Class A",
        "price": 178.66
    }, {
        "symbol": "NSCFX",
        "label": "Nuveen NWQ Small-Cap Value Fund Class R6",
        "price": 46.46
    }, {
        "symbol": "JUEPX",
        "label": "JPMorgan U.S. Equity Fund Class R3",
        "price": 16.61
    }, {
        "symbol": "OTIIX",
        "label": "T. Rowe Price Small-Cap Stock Fund I Class",
        "price": 53.56
    }, {
        "symbol": "VMACX",
        "label": "Virtus KAR Mid-Cap Core Fund Class A",
        "price": 38.84
    }, {
        "symbol": "NOSGX",
        "label": "Northern Small Cap Value Fund",
        "price": 20.75
    }, {
        "symbol": "WESCX",
        "label": "TETON Westwood SmallCap Equity Fund Class AAA",
        "price": 19.38
    }, {
        "symbol": "MMERX",
        "label": "Victory Integrity Discovery Fund Class R",
        "price": 31.95
    }, {
        "symbol": "PCCOX",
        "label": "T. Rowe Price U.S. Equity Research Fund I Class",
        "price": 32.03
    }, {
        "symbol": "JGMRX",
        "label": "Janus Henderson Triton Fund Class R",
        "price": 29.87
    }, {
        "symbol": "SPINX",
        "label": "SEI Institutional Investments Trust S&P 500 Index Fund Class A",
        "price": 17.62
    }, {
        "symbol": "PRCOX",
        "label": "T. Rowe Price U.S. Equity Research Fund",
        "price": 32.03
    }, {
        "symbol": "FMFCX",
        "label": "Fidelity Advisor Materials Fund Class C",
        "price": 66.01
    }, {
        "symbol": "FIATX",
        "label": "Fidelity Advisor International Capital Appreciation Fund Class M",
        "price": 22.77
    }, {
        "symbol": "DODGX",
        "label": "Dodge & Cox Stock Fund",
        "price": 198.66
    }, {
        "symbol": "MMEAX",
        "label": "Victory Integrity Discovery Fund Class A",
        "price": 34.13
    }, {
        "symbol": "BUFEX",
        "label": "Buffalo Large Cap Fund",
        "price": 36.59
    }, {
        "symbol": "FMEIX",
        "label": "Fidelity Mid Cap Enhanced Index Fund",
        "price": 15.72
    }, {
        "symbol": "FOSBX",
        "label": "Tributary Small Company Fund Institutional Plus Class",
        "price": 30.12
    }, {
        "symbol": "NSCRX",
        "label": "Nuveen NWQ Small-Cap Value Fund Class I",
        "price": 45.93
    }, {
        "symbol": "TGGCX",
        "label": "Touchstone International Growth Opportunities Fund Class C",
        "price": 22.81
    }, {
        "symbol": "ABINX",
        "label": "American Century Balanced Fund I Class",
        "price": 19.52
    }, {
        "symbol": "TWBIX",
        "label": "American Century Balanced Fund Investor Class",
        "price": 19.5
    }, {
        "symbol": "PABFX",
        "label": "PGIM Balanced Fund- Class Z",
        "price": 16.52
    }, {
        "symbol": "CHTTX",
        "label": "AMG Managers Fairpointe Mid Cap Fund Class N",
        "price": 35.42
    }, {
        "symbol": "PABCX",
        "label": "PGIM Balanced Fund- Class C",
        "price": 16.5
    }, {
        "symbol": "PBFBX",
        "label": "PGIM Balanced Fund- Class B",
        "price": 16.5
    }, {
        "symbol": "TPPAX",
        "label": "T. Rowe Price Personal Strategy Balanced Fund I Class",
        "price": 24.9
    }, {
        "symbol": "TRPBX",
        "label": "T. Rowe Price Personal Strategy Balanced Fund",
        "price": 24.89
    }, {
        "symbol": "RRTNX",
        "label": "T. Rowe Price Retirement 2025 Fund Class R",
        "price": 17.86
    }, {
        "symbol": "VASGX",
        "label": "Vanguard LifeStrategy Growth Fund Investor Shares",
        "price": 36.93
    }, {
        "symbol": "VEIRX",
        "label": "Vanguard Equity-Income Fund Admiral Shares",
        "price": 80.79
    }, {
        "symbol": "FBTIX",
        "label": "Fidelity Advisor Biotechnology Fund I Class",
        "price": 30.28
    }, {
        "symbol": "VTRIX",
        "label": "Vanguard International Value Fund Investor Shares",
        "price": 37.96
    }, {
        "symbol": "POPFX",
        "label": "Prospector Opportunity Fund",
        "price": 22.5
    }, {
        "symbol": "LGMCX",
        "label": "Loomis Sayles Global Allocation Fund Class C",
        "price": 24.89
    }, {
        "symbol": "FCHKX",
        "label": "Fidelity Advisor China Region Fund Class C",
        "price": 40.14
    }, {
        "symbol": "DODFX",
        "label": "Dodge & Cox International Stock Fund",
        "price": 43.96
    }, {
        "symbol": "WHGIX",
        "label": "Westwood Income Opportunity Fund Institutional Class",
        "price": 0.0
    }, {
        "symbol": "TIBIX",
        "label": "Thornburg Investment Income Builder Fund Class I",
        "price": 23.16
    }, {
        "symbol": "ASPGX",
        "label": "Astor Sector Allocation Fund Class A",
        "price": 0.0
    }, {
        "symbol": "TIBMX",
        "label": "Thornburg Investment Income Builder Fund Class R5",
        "price": 23.14
    }, {
        "symbol": "WSBFX",
        "label": "Walden Balanced Fund",
        "price": 21.24
    }, {
        "symbol": "TIBGX",
        "label": "Thornburg Investment Income Builder Fund Class R4",
        "price": 23.01
    }, {
        "symbol": "GIOTX",
        "label": "GMO International Developed Equity Allocation Fund Class III",
        "price": 16.29
    }, {
        "symbol": "TIBAX",
        "label": "Thornburg Investment Income Builder Fund Class A",
        "price": 22.99
    }, {
        "symbol": "TCVIX",
        "label": "Touchstone Mid Cap Value Institutional Class",
        "price": 19.75
    }, {
        "symbol": "ATERX",
        "label": "AB Sustainable Global Thematic Fund Class R",
        "price": 124.94
    }, {
        "symbol": "HSFNX",
        "label": "Hennessy Small Cap Financial Fund Investor Class",
        "price": 21.63
    }, {
        "symbol": "VEIPX",
        "label": "Vanguard Equity-Income Fund Investor Shares",
        "price": 38.55
    }, {
        "symbol": "CSERX",
        "label": "Columbia Select Large Cap Value Fund Advisor Class",
        "price": 27.72
    }, {
        "symbol": "CIVIX",
        "label": "Causeway International Value Fund Class Institutional",
        "price": 15.66
    }, {
        "symbol": "TGVIX",
        "label": "Thornburg International Value Fund Class I",
        "price": 24.88
    }, {
        "symbol": "GSMFX",
        "label": "GMO SGM Major Markets Fund Class III",
        "price": 31.65
    }, {
        "symbol": "TIVRX",
        "label": "Thornburg International Value Fund Class R5",
        "price": 24.85
    }, {
        "symbol": "AREVX",
        "label": "American Century Investments One Choice 2055 Portfolio Investor Class",
        "price": 0.0
    }, {
        "symbol": "VUVLX",
        "label": "Vanguard U.S. Value Fund Investor Shares",
        "price": 18.96
    }, {
        "symbol": "SBACX",
        "label": "Touchstone Balanced Fund Class C",
        "price": 21.95
    }, {
        "symbol": "TGIRX",
        "label": "Thornburg International Value Fund Class R6",
        "price": 24.81
    }, {
        "symbol": "ADGYX",
        "label": "AB Core Opportunities Fund Advisor Class",
        "price": 21.41
    }, {
        "symbol": "SEBLX",
        "label": "Touchstone Balanced Fund Class A",
        "price": 21.85
    }, {
        "symbol": "DODBX",
        "label": "Dodge & Cox Balanced Fund",
        "price": 103.48
    }, {
        "symbol": "JVAAX",
        "label": "JPMorgan Value Advantage Fund Class A",
        "price": 37.39
    }, {
        "symbol": "RBAIX",
        "label": "T. Rowe Price Balanced I Class",
        "price": 25.49
    }, {
        "symbol": "CIVVX",
        "label": "Causeway International Value Fund Class Investor",
        "price": 15.54
    }, {
        "symbol": "VBAIX",
        "label": "Vanguard Balanced Index Fund Institutional Shares",
        "price": 40.08
    }, {
        "symbol": "VBIAX",
        "label": "Vanguard Balanced Index Fund Admiral Shares",
        "price": 40.07
    }, {
        "symbol": "BTBFX",
        "label": "Boston Trust Asset Management Fund",
        "price": 56.52
    }, {
        "symbol": "VBINX",
        "label": "Vanguard Balanced Index Fund Investor Shares",
        "price": 40.06
    }, {
        "symbol": "DHLAX",
        "label": "Diamond Hill Large Cap Fund Class A",
        "price": 29.52
    }, {
        "symbol": "CSVZX",
        "label": "Columbia Select Large Cap Value Fund Institutional Class",
        "price": 27.33
    }, {
        "symbol": "SIBLX",
        "label": "Touchstone Balanced Fund Class Y",
        "price": 21.67
    }, {
        "symbol": "FASGX",
        "label": "Fidelity Asset Manager 70% Fund",
        "price": 23.2
    }, {
        "symbol": "FMSTX",
        "label": "Federated MDT Large Cap Value Fund Institutional Shares",
        "price": 28.59
    }, {
        "symbol": "LSWWX",
        "label": "Loomis Sayles Global Allocation Fund Class Y",
        "price": 25.33
    }, {
        "symbol": "HAVLX",
        "label": "Harbor Large Cap Value Fund Institutional Class",
        "price": 17.7
    }, {
        "symbol": "HNLVX",
        "label": "Harbor Large Cap Value Fund Retirement Class",
        "price": 17.7
    }, {
        "symbol": "IBALX",
        "label": "Transamerica Multi-Managed Balanced Fund Class A",
        "price": 29.59
    }, {
        "symbol": "FTASX",
        "label": "Fidelity Advisor Asset Manager 70% Fund Class M",
        "price": 23.2
    }, {
        "symbol": "HRLVX",
        "label": "Harbor Large Cap Value Fund Administrative Class",
        "price": 17.71
    }, {
        "symbol": "NBQFX",
        "label": "Nuveen International Growth Fund Class R6",
        "price": 49.07
    }, {
        "symbol": "EGOIX",
        "label": "Wells Fargo Large Cap Core Fund - Class Inst",
        "price": 17.09
    }, {
        "symbol": "EGORX",
        "label": "Wells Fargo Large Cap Core Fund - Class R6",
        "price": 17.07
    }, {
        "symbol": "LGMAX",
        "label": "Loomis Sayles Global Allocation Fund Class A",
        "price": 25.19
    }, {
        "symbol": "RRTCX",
        "label": "T. Rowe Price Retirement 2030 Fund Class R",
        "price": 26.04
    }, {
        "symbol": "FOSFX",
        "label": "Fidelity Overseas Fund",
        "price": 52.65
    }, {
        "symbol": "FOSKX",
        "label": "Fidelity Overseas Fund Class K",
        "price": 52.53
    }, {
        "symbol": "SXMAX",
        "label": "SEI Asset Allocation Trust Moderate Strategy Allocation Fund Class F",
        "price": 20.79
    }, {
        "symbol": "CIPIX",
        "label": "Champlain Mid Cap Fund Institutional Class",
        "price": 21.22
    }, {
        "symbol": "MCNVX",
        "label": "MainStay MacKay Convertible Fund Class I",
        "price": 18.96
    }, {
        "symbol": "PAITX",
        "label": "T. Rowe Price International Stock Fund Advisor Class",
        "price": 19.17
    }, {
        "symbol": "ADGKX",
        "label": "AB Core Opportunities Fund Class K",
        "price": 20.89
    }, {
        "symbol": "BPAIX",
        "label": "Boston Partners All Cap Value Fund Institutional Class",
        "price": 27.74
    }, {
        "symbol": "TGVAX",
        "label": "Thornburg International Value Fund Class A",
        "price": 23.98
    }, {
        "symbol": "TGVRX",
        "label": "Thornburg International Value Fund Class R3",
        "price": 23.94
    }, {
        "symbol": "VWNDX",
        "label": "Vanguard Windsor Fund Investor Share",
        "price": 21.78
    }, {
        "symbol": "DHTCX",
        "label": "Diamond Hill All Cap Select Fund Class C",
        "price": 0.0
    }, {
        "symbol": "TRLCX",
        "label": "TIAA-CREF Large-Cap Value Fund Retirement Class",
        "price": 18.59
    }, {
        "symbol": "FZABX",
        "label": "Fidelity Advisor Diversified International Fund Class Z",
        "price": 25.31
    }, {
        "symbol": "JVAIX",
        "label": "JPMorgan Value Advantage Fund Class L",
        "price": 37.59
    }, {
        "symbol": "FADCX",
        "label": "Fidelity Advisor Diversified International Fund Class C",
        "price": 23.99
    }, {
        "symbol": "JVAYX",
        "label": "JPMorgan Value Advantage Fund Class R6",
        "price": 37.54
    }, {
        "symbol": "JVASX",
        "label": "JPMorgan Value Advantage Fund Class I",
        "price": 37.58
    }, {
        "symbol": "JVARX",
        "label": "JPMorgan Value Advantage Fund Class R5",
        "price": 37.51
    }, {
        "symbol": "BPAVX",
        "label": "Boston Partners All Cap Value Fund Investor Class",
        "price": 27.63
    }, {
        "symbol": "RRITX",
        "label": "T. Rowe Price International Stock Fund Class R",
        "price": 18.9
    }, {
        "symbol": "CIPSX",
        "label": "Champlain Small Company Fund Class Advisor",
        "price": 19.96
    }, {
        "symbol": "DHTYX",
        "label": "Diamond Hill All Cap Select Fund Class Y",
        "price": 16.72
    }, {
        "symbol": "CSRYX",
        "label": "Columbia Select Large Cap Value Fund Institutional 3 Class",
        "price": 27.8
    }, {
        "symbol": "QNTIX",
        "label": "Meeder Quantex Fund Institutional Class",
        "price": 38.31
    }, {
        "symbol": "QNTAX",
        "label": "Meeder Quantex Fund Adviser Class",
        "price": 38.31
    }, {
        "symbol": "DHLTX",
        "label": "Diamond Hill All Cap Select Fund Class I",
        "price": 16.67
    }, {
        "symbol": "DHLYX",
        "label": "Diamond Hill Large Cap Fund Class Y",
        "price": 29.74
    }, {
        "symbol": "SLVAX",
        "label": "Columbia Select Large Cap Value Fund Class A",
        "price": 26.24
    }, {
        "symbol": "DHLRX",
        "label": "Diamond Hill Large Cap Fund Class I",
        "price": 29.71
    }, {
        "symbol": "CIPMX",
        "label": "Champlain Mid Cap Fund Advisor Class",
        "price": 20.74
    }, {
        "symbol": "FLCGX",
        "label": "Meeder Quantex Fund Retail Class",
        "price": 38.21
    }, {
        "symbol": "LMVYX",
        "label": "Lord Abbett Focused Small Cap Value Fund Class I",
        "price": 24.94
    }, {
        "symbol": "JVACX",
        "label": "JPMorgan Value Advantage Fund Class C",
        "price": 37.29
    }, {
        "symbol": "FDVAX",
        "label": "Fidelity Advisor Diversified International Fund Class A",
        "price": 24.94
    }, {
        "symbol": "FPRAX",
        "label": "FPA Paramount Fund",
        "price": 22.32
    }, {
        "symbol": "ADGZX",
        "label": "AB Core Opportunities Fund Class Z",
        "price": 21.77
    }, {
        "symbol": "HSSAX",
        "label": "Emerald Banking and Finance Fund Class A",
        "price": 34.72
    }, {
        "symbol": "TRRCX",
        "label": "T. Rowe Price Retirement 2030 Fund",
        "price": 26.5
    }, {
        "symbol": "VINEX",
        "label": "Vanguard International Explorer Fund Investor Shares",
        "price": 18.01
    }, {
        "symbol": "KMDVX",
        "label": "Keeley Mid Cap Dividend Value Fund Class A",
        "price": 24.11
    }, {
        "symbol": "FSTLX",
        "label": "Federated MDT Large Cap Value Fund Class R6",
        "price": 28.61
    }, {
        "symbol": "RPBAX",
        "label": "T. Rowe Price Balanced Fund",
        "price": 25.49
    }, {
        "symbol": "FSTKX",
        "label": "Federated MDT Large Cap Value Fund Service Shares",
        "price": 28.61
    }, {
        "symbol": "FFBFX",
        "label": "Emerald Banking and Finance Fund Investor Class",
        "price": 32.85
    }, {
        "symbol": "HSSIX",
        "label": "Emerald Banking and Finance Fund Institutional Class",
        "price": 35.85
    }, {
        "symbol": "FSTRX",
        "label": "Federated MDT Large Cap Value Fund Class A",
        "price": 28.57
    }, {
        "symbol": "PARCX",
        "label": "T. Rowe Price Retirement 2030 Fund Advisor Class",
        "price": 26.27
    }, {
        "symbol": "PESPX",
        "label": "BNY Mellon MidCap Index Fund Investor Shares",
        "price": 33.13
    }, {
        "symbol": "BRLVX",
        "label": "American Beacon Bridgeway Large Cap Value Fund Institutional Class",
        "price": 27.46
    }, {
        "symbol": "MYIMX",
        "label": "Victory Integrity Mid-Cap Value Fund Class Y",
        "price": 19.67
    }, {
        "symbol": "FSCWX",
        "label": "Nuveen Small Cap Value Fund Class R6",
        "price": 23.79
    }, {
        "symbol": "NWXQX",
        "label": "Nationwide Mid Cap Market Index Fund Institutional Service Class",
        "price": 15.93
    }, {
        "symbol": "IUESX",
        "label": "JPMorgan International Unconstrained Equity Fund Class I",
        "price": 23.32
    }, {
        "symbol": "FAAIX",
        "label": "Fidelity Advisor Asset Manager 70% Fund I Class",
        "price": 23.21
    }, {
        "symbol": "BLVAX",
        "label": "BMO Low Volatility Equity Fund Class A",
        "price": 16.18
    }, {
        "symbol": "FSCCX",
        "label": "Nuveen Small Cap Value Fund Class I",
        "price": 23.7
    }, {
        "symbol": "PRDSX",
        "label": "T. Rowe Price QM U.S. Small-Cap Growth Equity Fund",
        "price": 40.83
    }, {
        "symbol": "FBAKX",
        "label": "Fidelity Balanced Fund Class K",
        "price": 25.29
    }, {
        "symbol": "FAASX",
        "label": "Fidelity Advisor Asset Manager 70% Fund Class A",
        "price": 23.18
    }, {
        "symbol": "HQIIX",
        "label": "The Hartford Equity Income Fund Class I",
        "price": 19.84
    }, {
        "symbol": "DPSCX",
        "label": "BNY Mellon Structured Midcap Fund Class C",
        "price": 22.82
    }, {
        "symbol": "LYRIX",
        "label": "Lyrical U.S. Value Equity Fund Institutional Class",
        "price": 16.85
    }, {
        "symbol": "HSSCX",
        "label": "Emerald Banking and Finance Fund Class C",
        "price": 29.44
    }, {
        "symbol": "USWGX",
        "label": "USAA World Growth Fund Adviser Shares",
        "price": 26.1
    }, {
        "symbol": "TCLCX",
        "label": "TIAA-CREF Large-Cap Value Fund Retail Class",
        "price": 17.77
    }, {
        "symbol": "SMTIX",
        "label": "JPMorgan SmartRetirement 2040 Fund Class R5",
        "price": 21.96
    }, {
        "symbol": "SMTYX",
        "label": "JPMorgan SmartRetirement 2040 Fund Class R6",
        "price": 21.96
    }, {
        "symbol": "FSCVX",
        "label": "Nuveen Small Cap Value Fund Class C",
        "price": 19.33
    }, {
        "symbol": "UBVSX",
        "label": "Undiscovered Managers Behavioral Value Fund Class I",
        "price": 62.26
    }, {
        "symbol": "CRMSX",
        "label": "CRM Small Cap Value Fund Class Investor",
        "price": 15.34
    }, {
        "symbol": "SMTSX",
        "label": "JPMorgan SmartRetirement 2040 Fund Class I",
        "price": 21.9
    }, {
        "symbol": "NGPAX",
        "label": "Columbia Global Strategic Equity Fund Institutional Class",
        "price": 16.28
    }, {
        "symbol": "LYRBX",
        "label": "Lyrical U.S. Value Equity Fund Investor Class",
        "price": 16.77
    }, {
        "symbol": "SSGAX",
        "label": "SEI Asset Allocation Trust Aggressive Strategy Fund Class F",
        "price": 15.77
    }, {
        "symbol": "FCASX",
        "label": "Fidelity Advisor Asset Manager 70% Fund Class C",
        "price": 23.14
    }, {
        "symbol": "SKTAX",
        "label": "SEI Asset Allocation Trust Core Market Strategy Allocation Fund Class F",
        "price": 20.26
    }, {
        "symbol": "SMTAX",
        "label": "JPMorgan SmartRetirement 2040 Fund Class A",
        "price": 21.82
    }, {
        "symbol": "TILCX",
        "label": "T. Rowe Price Institutional Large Cap Value Fund",
        "price": 24.04
    }, {
        "symbol": "HILVX",
        "label": "Harbor Large Cap Value Fund Investor Class",
        "price": 17.89
    }, {
        "symbol": "UBVRX",
        "label": "Undiscovered Managers Behavioral Value Fund Class R2",
        "price": 60.2
    }, {
        "symbol": "GMXAX",
        "label": "Nationwide Mid Cap Market Index Fund Class A",
        "price": 15.59
    }, {
        "symbol": "FMCLX",
        "label": "Federated Mid-Cap Index Fund Class R6 Shares",
        "price": 20.2
    }, {
        "symbol": "FMDCX",
        "label": "Federated Mid-Cap Index Fund Service Shares",
        "price": 20.19
    }, {
        "symbol": "FDVIX",
        "label": "Fidelity Advisor Diversified International Fund I Class",
        "price": 25.36
    }, {
        "symbol": "HDGCX",
        "label": "The Hartford Dividend and Growth Fund Class C",
        "price": 26.07
    }, {
        "symbol": "WTMCX",
        "label": "Segall Bryant & Hamill Mid Cap Value Dividend Fund Retail Class",
        "price": 25.57
    }, {
        "symbol": "JENYX",
        "label": "Jensen Quality Growth Fund Class Y",
        "price": 53.35
    }, {
        "symbol": "FMCRX",
        "label": "Federated Mid-Cap Index Fund Institutional Shares",
        "price": 20.17
    }, {
        "symbol": "TFSIX",
        "label": "Franklin Mutual Financial Services Fund Class A",
        "price": 22.96
    }, {
        "symbol": "VHGEX",
        "label": "Vanguard Global Equity Fund Investor Shares",
        "price": 32.64
    }, {
        "symbol": "UBVLX",
        "label": "Undiscovered Managers Behavioral Value Fund Class L",
        "price": 62.45
    }, {
        "symbol": "CIPNX",
        "label": "Champlain Small Company Fund Institutional Class",
        "price": 20.18
    }, {
        "symbol": "FZAJX",
        "label": "Fidelity Advisor International Growth Fund Class Z",
        "price": 16.4
    }, {
        "symbol": "FIGFX",
        "label": "Fidelity International Growth Fund",
        "price": 16.42
    }, {
        "symbol": "WPSIX",
        "label": "AB Concentrated Growth Fund Class I",
        "price": 45.33
    }, {
        "symbol": "CLREX",
        "label": "Columbia Balanced Fund Institutional 2 Class",
        "price": 44.62
    }, {
        "symbol": "CBALX",
        "label": "Columbia Balanced Fund Institutional Class",
        "price": 44.59
    }, {
        "symbol": "SMTCX",
        "label": "JPMorgan SmartRetirement 2040 Fund Class C",
        "price": 21.46
    }, {
        "symbol": "PFPMX",
        "label": "Parnassus Mid Cap Fund Institutional Shares",
        "price": 36.49
    }, {
        "symbol": "UBVAX",
        "label": "Undiscovered Managers Behavioral Value Fund Class A",
        "price": 60.86
    }, {
        "symbol": "SISAX",
        "label": "SEI Asset Allocation Trust Tax-Managed Aggressive Strategy Fund Class F",
        "price": 23.19
    }, {
        "symbol": "PARMX",
        "label": "Parnassus Mid Cap Fund",
        "price": 36.45
    }, {
        "symbol": "TPVIX",
        "label": "Timothy Plan Small Cap Value Fund Class I",
        "price": 18.09
    }, {
        "symbol": "HGIYX",
        "label": "Hartford Core Equity Fund Class Y",
        "price": 36.58
    }, {
        "symbol": "SGOAX",
        "label": "SEI Asset Allocation Trust Market Growth Strategy Allocation Fund Class F",
        "price": 25.82
    }, {
        "symbol": "DMIDX",
        "label": "BNY Mellon MidCap Index Fund Class I",
        "price": 32.99
    }, {
        "symbol": "TQAIX",
        "label": "T. Rowe Price QM U.S. Small-Cap Growth Equity Fund I Class",
        "price": 40.95
    }, {
        "symbol": "MCINX",
        "label": "MainStay MacKay Convertible Fund Investor Class",
        "price": 18.91
    }, {
        "symbol": "TRLIX",
        "label": "TIAA-CREF Large-Cap Value Fund Institutional Class",
        "price": 18.64
    }, {
        "symbol": "TRLHX",
        "label": "TIAA-CREF Large Cap Value Fund Advisor Class",
        "price": 18.63
    }, {
        "symbol": "FFNOX",
        "label": "Fidelity Four-in-One Index Fund",
        "price": 50.12
    }, {
        "symbol": "FSCAX",
        "label": "Nuveen Small Cap Value Fund Class A",
        "price": 22.91
    }, {
        "symbol": "GMXRX",
        "label": "Nationwide Mid Cap Market Index Fund Class R",
        "price": 15.32
    }, {
        "symbol": "HGITX",
        "label": "Hartford Core Equity Fund Class R5",
        "price": 36.43
    }, {
        "symbol": "KIFCX",
        "label": "Salient Select Income Fund Class C",
        "price": 21.02
    }, {
        "symbol": "DPSYX",
        "label": "BNY Mellon Structured MidCap Fund Class Y",
        "price": 27.6
    }, {
        "symbol": "SEAIX",
        "label": "SEI Asset Allocation Trust Aggressive Strategy Fund Class I",
        "price": 15.32
    }, {
        "symbol": "TMMAX",
        "label": "SEI Institutional Managed Trust Tax-Managed Volatility Fund Class F",
        "price": 18.4
    }, {
        "symbol": "STVYX",
        "label": "SEI Institutional Managed Trust Tax-Managed Managed Volatility Fund Class Y",
        "price": 18.4
    }, {
        "symbol": "JORCX",
        "label": "Janus Henderson Global Select Fund Class C",
        "price": 15.74
    }, {
        "symbol": "MPACX",
        "label": "Matthews Asia Growth Fund Investor Class",
        "price": 29.0
    }, {
        "symbol": "TQAAX",
        "label": "T. Rowe Price QM U.S. Small-Cap Growth Equity Fund Advisor Class",
        "price": 40.59
    }, {
        "symbol": "MSCFX",
        "label": "Mairs & Power Small Cap Fund",
        "price": 26.89
    }, {
        "symbol": "MCSVX",
        "label": "MainStay MacKay Convertible Fund Class B",
        "price": 18.78
    }, {
        "symbol": "FOVAX",
        "label": "First Trust/Confluence Small Cap Value Fund Class A",
        "price": 33.53
    }, {
        "symbol": "USAWX",
        "label": "USAA World Growth Fund",
        "price": 26.05
    }, {
        "symbol": "JTSYX",
        "label": "JPMorgan SmartRetirement 2050 Fund Class R6",
        "price": 21.48
    }, {
        "symbol": "JTSIX",
        "label": "JPMorgan SmartRetirement 2050 Fund Class R5",
        "price": 21.47
    }, {
        "symbol": "JENIX",
        "label": "Jensen Quality Growth Fund Class I",
        "price": 53.36
    }, {
        "symbol": "SSHVX",
        "label": "Sound Shore Fund Institutional Class",
        "price": 43.61
    }, {
        "symbol": "JTSSX",
        "label": "JPMorgan SmartRetirement 2050 Fund Class I",
        "price": 21.43
    }, {
        "symbol": "CBDYX",
        "label": "Columbia Balanced Fund Institutional 3 Class",
        "price": 45.06
    }, {
        "symbol": "CBDRX",
        "label": "Columbia Balanced Fund Advisor Class",
        "price": 45.05
    }, {
        "symbol": "SGIDX",
        "label": "Crossmark Steward Global Equity Income Fund Class A",
        "price": 32.33
    }, {
        "symbol": "FSPHX",
        "label": "Fidelity Select Health Care Portfolio",
        "price": 29.37
    }, {
        "symbol": "FABCX",
        "label": "Fidelity Advisor Balanced Fund Class C",
        "price": 22.79
    }, {
        "symbol": "FCPVX",
        "label": "Fidelity Small Cap Value Fund",
        "price": 15.62
    }, {
        "symbol": "NOMIX",
        "label": "Northern Mid Cap Index Fund",
        "price": 19.65
    }, {
        "symbol": "CBLAX",
        "label": "Columbia Balanced Fund Class A",
        "price": 44.68
    }, {
        "symbol": "RRTPX",
        "label": "T. Rowe Price Retirement 2035 Fund Class R",
        "price": 19.18
    }, {
        "symbol": "FBALX",
        "label": "Fidelity Balanced Fund",
        "price": 25.29
    }, {
        "symbol": "FAMRX",
        "label": "Fidelity Asset Manager 85% Fund",
        "price": 20.6
    }, {
        "symbol": "BWLAX",
        "label": "American Beacon Bridgeway Large Cap Value Fund A Class",
        "price": 27.23
    }, {
        "symbol": "FCIVX",
        "label": "Frontier MFG Core Infrastructure Fund Service Class",
        "price": 18.85
    }, {
        "symbol": "FMIYX",
        "label": "FMI International Fund Institutional Class",
        "price": 32.94
    }, {
        "symbol": "HQISX",
        "label": "The Hartford Equity Income Fund Class R4",
        "price": 20.03
    }, {
        "symbol": "JTSCX",
        "label": "JPMorgan SmartRetirement 2050 Fund Class C",
        "price": 21.09
    }, {
        "symbol": "IILGX",
        "label": "Thrivent Global Stock Fund Class S",
        "price": 25.43
    }, {
        "symbol": "FHCIX",
        "label": "Fidelity Advisor Health Care Fund I Class",
        "price": 62.35
    }, {
        "symbol": "FSMEX",
        "label": "Fidelity Select Medical Technology and Devices Portfolio",
        "price": 61.89
    }, {
        "symbol": "AASCX",
        "label": "Thrivent Mid Cap Stock Fund Class A",
        "price": 24.91
    }, {
        "symbol": "TRRNX",
        "label": "T. Rowe Price Retirement 2055 Fund",
        "price": 16.33
    }, {
        "symbol": "FEYAX",
        "label": "Fidelity Advisor Asset Manager 85% Fund Class A",
        "price": 20.41
    }, {
        "symbol": "ARTGX",
        "label": "Artisan Global Value Fund Investor Class",
        "price": 18.62
    }, {
        "symbol": "FHCCX",
        "label": "Fidelity Advisor Health Care Fund Class C",
        "price": 44.69
    }, {
        "symbol": "APHGX",
        "label": "Artisan Global Value Fund Institutional Class",
        "price": 18.62
    }, {
        "symbol": "CAGIX",
        "label": "Calvert Growth Allocation Fund Class I",
        "price": 22.05
    }, {
        "symbol": "APDGX",
        "label": "Artisan Global Value Fund Advisor Class",
        "price": 18.58
    }, {
        "symbol": "BRLIX",
        "label": "Bridgeway Blue Chip Fund",
        "price": 0.0
    }, {
        "symbol": "WPSKX",
        "label": "AB Concentrated Growth Fund Class K",
        "price": 44.53
    }, {
        "symbol": "VWIGX",
        "label": "Vanguard International Growth Fund Investor Shares",
        "price": 33.46
    }, {
        "symbol": "FEYTX",
        "label": "Fidelity Advisor Asset Manager 85% Fund Class M",
        "price": 20.29
    }, {
        "symbol": "FACTX",
        "label": "Fidelity Advisor Health Care Fund Class M",
        "price": 52.98
    }, {
        "symbol": "MPGFX",
        "label": "Mairs & Power Growth Fund Investor Class",
        "price": 130.47
    }, {
        "symbol": "TBGVX",
        "label": "Tweedy, Browne Global Value Fund",
        "price": 28.42
    }, {
        "symbol": "OMGNX",
        "label": "Invesco Oppenheimer Main Street Fund Class R",
        "price": 48.49
    }, {
        "symbol": "TRRMX",
        "label": "T. Rowe Price Retirement 2050 Fund",
        "price": 16.07
    }, {
        "symbol": "LVOLX",
        "label": "SGI U.S. Large Cap Equity Fund Class A",
        "price": 19.16
    }, {
        "symbol": "WHGSX",
        "label": "Westwood SmallCap Fund Institutional Class",
        "price": 17.7
    }, {
        "symbol": "FACDX",
        "label": "Fidelity Advisor Health Care Fund Class A",
        "price": 57.07
    }, {
        "symbol": "FZAAX",
        "label": "Fidelity Advisor Balanced Fund Class Z",
        "price": 23.46
    }, {
        "symbol": "DROCX",
        "label": "Diamond Hill Research Opportunities Fund Class C",
        "price": 22.17
    }, {
        "symbol": "FAIOX",
        "label": "Fidelity Advisor Balanced Fund I Class",
        "price": 23.45
    }, {
        "symbol": "SILVX",
        "label": "SGI U.S. Large Cap Equity Fund Class I",
        "price": 19.09
    }, {
        "symbol": "RRTVX",
        "label": "T. Rowe Price Retirement 2055 Fund Class R",
        "price": 16.12
    }, {
        "symbol": "TMSIX",
        "label": "Thrivent Mid Cap Stock Fund Class S",
        "price": 28.55
    }, {
        "symbol": "TRRDX",
        "label": "T. Rowe Price Retirement 2040 Fund",
        "price": 27.78
    }, {
        "symbol": "BTMFX",
        "label": "Boston Trust Midcap Fund",
        "price": 19.96
    }, {
        "symbol": "CLDIX",
        "label": "Calvert Long-Term Income Fund Class I",
        "price": 18.71
    }, {
        "symbol": "FEYCX",
        "label": "Fidelity Advisor Asset Manager 85% Fund Class C",
        "price": 20.1
    }, {
        "symbol": "FAIGX",
        "label": "Fidelity Advisor Balanced Fund Class M",
        "price": 23.26
    }, {
        "symbol": "CLNCX",
        "label": "Columbia Large Cap Enhanced Core Fund Institutional 2 Class",
        "price": 26.06
    }, {
        "symbol": "MIGYX",
        "label": "Invesco Oppenheimer Main Street Fund Class Y",
        "price": 49.21
    }, {
        "symbol": "WWOIX",
        "label": "Virtus Vontobel Global Opportunities Fund Class I",
        "price": 17.81
    }, {
        "symbol": "TROCX",
        "label": "Touchstone Global ESG Equity Fund - Class I",
        "price": 23.06
    }, {
        "symbol": "CALCX",
        "label": "Calvert Conservative Allocation Fund Class C",
        "price": 17.62
    }, {
        "symbol": "DPDEX",
        "label": "Macquarie Pooled Trust Large Cap Value Portfolio",
        "price": 17.69
    }, {
        "symbol": "CFWCX",
        "label": "Calvert Global Water Fund Class C",
        "price": 20.84
    }, {
        "symbol": "RYLIX",
        "label": "Rydex Leisure Fund Class Investor",
        "price": 79.3
    }, {
        "symbol": "FARFX",
        "label": "Fidelity Advisor Managed Retirement 2025 Fund Class A",
        "price": 54.83
    }, {
        "symbol": "THORX",
        "label": "Thornburg Global Opportunities Fund Class R3",
        "price": 31.72
    }, {
        "symbol": "DHFCX",
        "label": "Diamond Hill Long Short Fund Class C",
        "price": 23.29
    }, {
        "symbol": "FERIX",
        "label": "Fidelity Advisor Emerging Asia Fund I Class",
        "price": 44.26
    }, {
        "symbol": "CFWIX",
        "label": "Calvert Global Water Fund Class I",
        "price": 22.78
    }, {
        "symbol": "FEATX",
        "label": "Fidelity Advisor Emerging Asia Fund Class M",
        "price": 41.56
    }, {
        "symbol": "CFWAX",
        "label": "Calvert Global Water Fund Class A",
        "price": 22.65
    }, {
        "symbol": "BINIX",
        "label": "Baron International Growth Fund Institutional Shares",
        "price": 25.35
    }, {
        "symbol": "RYLCX",
        "label": "Rydex Leisure Fund Class C",
        "price": 63.61
    }, {
        "symbol": "THOCX",
        "label": "Thornburg Global Opportunities Fund Class C",
        "price": 30.79
    }, {
        "symbol": "BIGFX",
        "label": "Baron International Growth Fund Retail Shares",
        "price": 24.96
    }, {
        "symbol": "FFFDX",
        "label": "Fidelity Freedom 2020 Fund",
        "price": 16.45
    }, {
        "symbol": "LCORX",
        "label": "Leuthold Core Investment Fund Retail Class",
        "price": 19.41
    }, {
        "symbol": "FIRFX",
        "label": "Fidelity Advisor Managed Retirement 2025 Fund Class I",
        "price": 54.98
    }, {
        "symbol": "SPVZX",
        "label": "PGIM QMA Mid-Cap Value Fund- Class Z",
        "price": 18.94
    }, {
        "symbol": "HAFCX",
        "label": "Hartford Multi-Asset Income and Growth Fund Class C",
        "price": 20.71
    }, {
        "symbol": "PMVQX",
        "label": "PGIM QMA Mid-Cap Value Fund- Class R6",
        "price": 18.89
    }, {
        "symbol": "SDVRX",
        "label": "PGIM QMA Mid-Cap Value Fund- Class R",
        "price": 18.93
    }, {
        "symbol": "GLRIX",
        "label": "James Balanced: Golden Rainbow Fund Institutional Class",
        "price": 21.26
    }, {
        "symbol": "VASIX",
        "label": "Vanguard LifeStrategy Income Fund Investor Shares",
        "price": 16.36
    }, {
        "symbol": "SPHY",
        "label": "SPDR Portfolio High Yield Bond ETF",
        "price": 26.5
    }, {
        "symbol": "SPGP",
        "label": "Invesco S&P 500 GARP ETF",
        "price": 64.64
    }, {
        "symbol": "XSVM",
        "label": "Invesco S&P SmallCap Value with Momentum ETF",
        "price": 33.7809
    }, {
        "symbol": "SPIP",
        "label": "SPDR Portfolio TIPS ETF",
        "price": 28.69
    }, {
        "symbol": "SPMB",
        "label": "SPDR Portfolio Mortgage Backed Bond ETF",
        "price": 26.22
    }, {
        "symbol": "IGBH",
        "label": "iShares Interest Rate Hedged Long-Term Corporate Bond ETF",
        "price": 25.3749
    }, {
        "symbol": "IGLB",
        "label": "iShares Long-Term Corporate Bond ETF",
        "price": 67.71
    }, {
        "symbol": "SPGM",
        "label": "SPDR Portfolio MSCI Global Stock Market ETF",
        "price": 43.9524
    }, {
        "symbol": "EUMF",
        "label": "WisdomTree Europe Multifactor Fund",
        "price": 30.8061
    }, {
        "symbol": "XMMO",
        "label": "Invesco S&P MidCap Momentum ETF",
        "price": 63.46
    }, {
        "symbol": "MFMPX",
        "label": "Morgan Stanley Institutional Fund, Inc. Frontier Markets Portfolio Class A",
        "price": 17.48
    }, {
        "symbol": "MSRFX",
        "label": "Morgan Stanley Institutional Fund, Inc. Frontier Markets Portfolio Class IS",
        "price": 17.43
    }, {
        "symbol": "MBESX",
        "label": "AMG Chicago Equity Partners Balanced Fund Class I",
        "price": 17.49
    }, {
        "symbol": "MBEAX",
        "label": "AMG Chicago Equity Partners Balanced Fund Class N",
        "price": 17.31
    }, {
        "symbol": "EKGCX",
        "label": "Wells Fargo Global Small Cap Fund Class C",
        "price": 27.35
    }, {
        "symbol": "AMDVX",
        "label": "American Century Mid Cap Value Fund R6 Class",
        "price": 17.24
    }, {
        "symbol": "ACMVX",
        "label": "American Century Mid Cap Value Fund Investor Class",
        "price": 17.23
    }, {
        "symbol": "EKGYX",
        "label": "Wells Fargo Global Small Cap Fund Class Admin",
        "price": 43.29
    }, {
        "symbol": "FSCRX",
        "label": "Fidelity Small Cap Discovery Fund",
        "price": 23.53
    }, {
        "symbol": "EKGIX",
        "label": "Wells Fargo Global Small Cap Fund Class Inst",
        "price": 43.06
    }, {
        "symbol": "MICDX",
        "label": "Matthews China Dividend Fund Institutional Class",
        "price": 16.97
    }, {
        "symbol": "MCDFX",
        "label": "Matthews China Dividend Fund Investor Class",
        "price": 16.97
    }, {
        "symbol": "GPRIX",
        "label": "Grandeur Peak Global Reach Fund Institutional Class",
        "price": 16.53
    }, {
        "symbol": "SAOIX",
        "label": "Guggenheim Alpha Opportunity Fund Institutional Class",
        "price": 25.3
    }, {
        "symbol": "EKGAX",
        "label": "Wells Fargo Global Small Cap Fund Class A",
        "price": 41.11
    }, {
        "symbol": "FPBFX",
        "label": "Fidelity Pacific Basin Fund",
        "price": 35.37
    }, {
        "symbol": "HEIFX",
        "label": "Hennessy Equity and Income Fund Investor Class",
        "price": 15.46
    }, {
        "symbol": "SAOCX",
        "label": "Guggenheim Alpha Opportunity Fund Class C",
        "price": 15.21
    }, {
        "symbol": "FGTRX",
        "label": "Franklin Growth Allocation Fund Class R",
        "price": 18.32
    }, {
        "symbol": "SGIIX",
        "label": "First Eagle Global Fund Class I",
        "price": 58.58
    }, {
        "symbol": "FESOX",
        "label": "First Eagle Overseas Fund Class C",
        "price": 23.08
    }, {
        "symbol": "SAOAX",
        "label": "Guggenheim Alpha Opportunity Fund Class A",
        "price": 17.37
    }, {
        "symbol": "FESGX",
        "label": "First Eagle Global Fund Class C",
        "price": 56.22
    }, {
        "symbol": "SGENX",
        "label": "First Eagle Global Fund Class A",
        "price": 58.31
    }, {
        "symbol": "QFVOX",
        "label": "Pear Tree Polaris Foreign Value Fund Class Ordinary",
        "price": 21.89
    }, {
        "symbol": "QFVIX",
        "label": "Pear Tree Polaris Foreign Value Fund Class Institutional",
        "price": 21.81
    }, {
        "symbol": "SGOIX",
        "label": "First Eagle Overseas Fund Class I",
        "price": 24.52
    }, {
        "symbol": "TRAOX",
        "label": "T. Rowe Price Asia Opportunities Fund",
        "price": 17.02
    }, {
        "symbol": "TESRX",
        "label": "Franklin Mutual Shares Fund Class R",
        "price": 27.7
    }, {
        "symbol": "CNPIX",
        "label": "ProFunds Consumer Goods Ultra Sector Fund Investor Class",
        "price": 119.54
    }, {
        "symbol": "CNPSX",
        "label": "ProFunds Consumer Goods Ultra Sector Fund Service Class",
        "price": 107.91
    }, {
        "symbol": "SGOVX",
        "label": "First Eagle Overseas Fund Class A",
        "price": 23.97
    }, {
        "symbol": "CMAAX",
        "label": "Calvert Moderate Allocation Fund Class A",
        "price": 20.27
    }, {
        "symbol": "TESIX",
        "label": "Franklin Mutual Shares Fund Class A",
        "price": 27.79
    }, {
        "symbol": "ENPSX",
        "label": "ProFunds UltraSector Oil & Gas Fund Service Class",
        "price": 23.82
    }, {
        "symbol": "NELAX",
        "label": "Nuveen Equity Long/Short Fund Class A",
        "price": 41.92
    }, {
        "symbol": "FSBBX",
        "label": "Federated Global Allocation Fund Class B Shares",
        "price": 19.79
    }, {
        "symbol": "WIIGX",
        "label": "Wasatch International Growth Fund Institutional Class",
        "price": 31.91
    }, {
        "symbol": "HHHSX",
        "label": "Hartford Schroders Emerging Markets Equity Fund Class R4",
        "price": 17.25
    }, {
        "symbol": "ENPIX",
        "label": "ProFunds UltraSector Oil & Gas Fund Investor Class",
        "price": 27.65
    }, {
        "symbol": "TFSCX",
        "label": "Templeton Institutional Foreign Smaller Companies Series Advisor",
        "price": 21.18
    }, {
        "symbol": "SEMTX",
        "label": "Hartford Schroders Emerging Markets Equity Fund Class SDR",
        "price": 17.2
    }, {
        "symbol": "SEMNX",
        "label": "Hartford Schroders Emerging Markets Equity Fund Class I",
        "price": 17.17
    }, {
        "symbol": "NELIX",
        "label": "Nuveen Equity Long/Short Fund Class I",
        "price": 43.17
    }, {
        "symbol": "NELCX",
        "label": "Nuveen Equity Long/Short Fund Class C",
        "price": 38.22
    }, {
        "symbol": "HHHCX",
        "label": "Hartford Schroders Emerging Markets Equity Fund Class C",
        "price": 17.0
    }, {
        "symbol": "RMBKX",
        "label": "RMB Mendon Financial Services Fund Class A",
        "price": 41.16
    }, {
        "symbol": "PWGAX",
        "label": "PACE International Equity Investments Class A",
        "price": 15.95
    }, {
        "symbol": "PWIYX",
        "label": "PACE International Equity Investments Class Y",
        "price": 15.86
    }, {
        "symbol": "PCIEX",
        "label": "PACE International Equity Investments Class P",
        "price": 15.82
    }, {
        "symbol": "RMBNX",
        "label": "RMB Mendon Financial Services Fund Class C",
        "price": 36.9
    }, {
        "symbol": "RYBAX",
        "label": "Rydex Basic Materials Fund Class H",
        "price": 53.83
    }, {
        "symbol": "SEMVX",
        "label": "Hartford Schroders Emerging Markets Equity Fund Class A",
        "price": 17.21
    }, {
        "symbol": "RYBCX",
        "label": "Rydex Basic Materials Fund Class C",
        "price": 48.28
    }, {
        "symbol": "DHMYX",
        "label": "Diamond Hill Small-Mid Cap Fund Class Y",
        "price": 22.85
    }, {
        "symbol": "DHMIX",
        "label": "Diamond Hill Small Mid Cap Fund Class I",
        "price": 22.8
    }, {
        "symbol": "DHMAX",
        "label": "Diamond Hill Small Mid Cap Fund Class A",
        "price": 22.57
    }, {
        "symbol": "FERCX",
        "label": "Fidelity Advisor Emerging Asia Fund Class C",
        "price": 38.44
    }, {
        "symbol": "RYBIX",
        "label": "Rydex Basic Materials Fund Class Investor",
        "price": 59.79
    }, {
        "symbol": "RYBMX",
        "label": "Rydex Basic Materials Fund Class A",
        "price": 55.61
    }, {
        "symbol": "DHMCX",
        "label": "Diamond Hill Small Mid Cap Fund Class C",
        "price": 20.37
    }, {
        "symbol": "WILJX",
        "label": "William Blair International Leaders Fund R6 Class Shares",
        "price": 18.66
    }, {
        "symbol": "WILNX",
        "label": "William Blair International Leaders Fund Class N Shares",
        "price": 18.62
    }, {
        "symbol": "BPLEX",
        "label": "Boston Partners Long/Short Equity Fund Investor Class",
        "price": 15.35
    }, {
        "symbol": "BPLSX",
        "label": "Boston Partners Long/Short Equity Fund Institutional Class",
        "price": 17.03
    }, {
        "symbol": "RLUEX",
        "label": "Lazard US Equity Concentrated Portfolio R6 Shares",
        "price": 17.59
    }, {
        "symbol": "TDMTX",
        "label": "Templeton Developing Markets Trust Class C",
        "price": 22.02
    }, {
        "symbol": "RMBIX",
        "label": "RMB Mendon Financial Long/Short Fund Class I",
        "price": 16.06
    }, {
        "symbol": "MAPTX",
        "label": "Matthews Pacific Tiger Fund Investor Class",
        "price": 29.58
    }, {
        "symbol": "MIPTX",
        "label": "Matthews Pacific Tiger Fund Institutional Class",
        "price": 29.54
    }, {
        "symbol": "PRASX",
        "label": "T. Rowe Price New Asia Fund",
        "price": 20.33
    }, {
        "symbol": "WIGTX",
        "label": "Seven Canyons World Innovators Fund Institutional Class",
        "price": 18.15
    }, {
        "symbol": "RMBFX",
        "label": "RMB Mendon Financial Long/Short Fund Class A",
        "price": 15.83
    }, {
        "symbol": "WAGTX",
        "label": "Seven Canyons World Innovators Fund Investor Class",
        "price": 17.9
    }, {
        "symbol": "RLITX",
        "label": "Lazard International Strategic Equity Portfolio R6 Shares",
        "price": 15.73
    }, {
        "symbol": "GBFAX",
        "label": "VanEck Emerging Markets Fund Class A",
        "price": 18.64
    }, {
        "symbol": "ACDHX",
        "label": "AC Alternatives Disciplined Long Short Fund C Class",
        "price": 16.28
    }, {
        "symbol": "BPIRX",
        "label": "Boston Partners Long/Short Research Fund Institutional Class",
        "price": 15.88
    }, {
        "symbol": "LZOEX",
        "label": "Lazard Emerging Markets Equity Portfolio Open Shares",
        "price": 19.26
    }, {
        "symbol": "SHSCX",
        "label": "BlackRock Health Sciences Opportunities Portfolio Investor C Shares",
        "price": 57.27
    }, {
        "symbol": "SHSKX",
        "label": "BlackRock Health Sciences Opportunities Portfolio Class K",
        "price": 69.91
    }, {
        "symbol": "SHSSX",
        "label": "BlackRock Health Sciences Opportunities Portfolio Institutional Shares",
        "price": 69.85
    }, {
        "symbol": "SHISX",
        "label": "BlackRock Health Sciences Opportunities Portfolio Service Shares",
        "price": 66.52
    }, {
        "symbol": "BHSRX",
        "label": "BlackRock Health Sciences Opportunities Portfolio Class R",
        "price": 64.71
    }, {
        "symbol": "SHSAX",
        "label": "BlackRock Health Sciences Opportunities Portfolio Investor A Shares",
        "price": 66.26
    }, {
        "symbol": "RLEMX",
        "label": "Lazard Emerging Markets Equity Portfolio R6 Shares",
        "price": 18.7
    }, {
        "symbol": "GBATX",
        "label": "GMO Strategic Opportunities Allocation Fund Class III",
        "price": 20.92
    }, {
        "symbol": "RISAX",
        "label": "Manning & Napier Rainier International Discovery Series Class S",
        "price": 22.46
    }, {
        "symbol": "TCMIX",
        "label": "AMG TimesSquare International Small Cap Fund Class Z",
        "price": 16.45
    }, {
        "symbol": "TCMPX",
        "label": "AMG TimesSquare International Small Cap Fund Class N",
        "price": 16.42
    }, {
        "symbol": "JEMSX",
        "label": "JPMorgan Emerging Markets Equity Fund Class I",
        "price": 33.76
    }, {
        "symbol": "NIMEX",
        "label": "Nuveen Equity Market Neutral Fund Class I",
        "price": 22.69
    }, {
        "symbol": "NMAEX",
        "label": "Nuveen Equity Market Neutral Fund Class A",
        "price": 22.36
    }, {
        "symbol": "DREGX",
        "label": "Driehaus Emerging Markets Growth Fund Investor Class",
        "price": 40.78
    }, {
        "symbol": "RAIIX",
        "label": "Manning & Napier Rainier International Discovery Series Class I",
        "price": 22.67
    }, {
        "symbol": "HLEMX",
        "label": "Harding Loevner Emerging Markets Portfolio Advisor Class",
        "price": 61.6
    }, {
        "symbol": "HLMEX",
        "label": "Harding Loevner Institutional Emerging Markets Portfolio Class I",
        "price": 23.49
    }, {
        "symbol": "SBYEX",
        "label": "BNY Mellon Diversified Emerging Markets Fund Class Y",
        "price": 25.28
    }, {
        "symbol": "DBECX",
        "label": "BNY Mellon Diversified Emerging Markets Fund Class C",
        "price": 23.96
    }, {
        "symbol": "IEMFX",
        "label": "T. Rowe Price Institutional Emerging Markets Equity Fund",
        "price": 43.97
    }, {
        "symbol": "IHOAX",
        "label": "The Hartford International Opportunities Fund Class A",
        "price": 16.82
    }, {
        "symbol": "AILCX",
        "label": "American Beacon International Equity Fund Class C",
        "price": 17.63
    }, {
        "symbol": "CLIFX",
        "label": "Clifford Capital Partners Fund Institutional Class",
        "price": 15.45
    }, {
        "symbol": "AAISX",
        "label": "American Beacon International Equity Fund Advisor Class",
        "price": 18.73
    }, {
        "symbol": "PRZIX",
        "label": "T. Rowe Price Emerging Markets Stock Fund I Class",
        "price": 48.46
    }, {
        "symbol": "IHOIX",
        "label": "The Hartford International Opportunities Fund Class I",
        "price": 16.7
    }, {
        "symbol": "PRMSX",
        "label": "T. Rowe Price Emerging Markets Stock Fund",
        "price": 48.35
    }, {
        "symbol": "SBCEX",
        "label": "BNY Mellon Diversified Emerging Markets Fund Class I",
        "price": 25.26
    }, {
        "symbol": "AAIEX",
        "label": "American Beacon International Equity Fund Institutional Class",
        "price": 18.4
    }, {
        "symbol": "IHOTX",
        "label": "The Hartford International Opportunities Fund Class R5",
        "price": 17.57
    }, {
        "symbol": "AIEAX",
        "label": "American Beacon International Equity Fund Class A",
        "price": 18.24
    }, {
        "symbol": "IHOSX",
        "label": "The Hartford International Opportunities Fund Class R4",
        "price": 17.44
    }, {
        "symbol": "IHORX",
        "label": "The Hartford International Opportunities Fund Class R3",
        "price": 17.14
    }, {
        "symbol": "SABTX",
        "label": "SA U.S. Value Fund Investor Class",
        "price": 18.0
    }, {
        "symbol": "SVUBX",
        "label": "PGIM QMA Mid-Cap Value Fund- Class B",
        "price": 15.8
    }, {
        "symbol": "NCBVX",
        "label": "PGIM QMA Mid-Cap Value Fund- Class C",
        "price": 15.72
    }, {
        "symbol": "SPRAX",
        "label": "PGIM QMA Mid-Cap Value Fund- Class A",
        "price": 18.74
    }, {
        "symbol": "HLMNX",
        "label": "Harding Loevner International Equity Portfolio Investor Class",
        "price": 24.34
    }, {
        "symbol": "HLMIX",
        "label": "Harding Loevner International Equity Portfolio Institutional Class",
        "price": 24.34
    }, {
        "symbol": "HJPIX",
        "label": "Hennessy Japan Fund Institutional Class",
        "price": 39.47
    }, {
        "symbol": "HJPNX",
        "label": "Hennessy Japan Fund Investor Class",
        "price": 38.33
    }, {
        "symbol": "AEMZX",
        "label": "Acadian Emerging Markets Portfolio Class I",
        "price": 21.31
    }, {
        "symbol": "GEDTX",
        "label": "GMO Emerging Domestic Opportunities Fund Class II",
        "price": 23.68
    }, {
        "symbol": "HJPSX",
        "label": "Hennessy Japan Small Cap Fund Investor Class",
        "price": 15.67
    }, {
        "symbol": "HJSIX",
        "label": "Hennessy Japan Small Cap Fund Institutional Class",
        "price": 15.48
    }, {
        "symbol": "PRLAX",
        "label": "T. Rowe Price Latin America Fund",
        "price": 25.16
    }, {
        "symbol": "WESJX",
        "label": "William Blair Emerging Markets Small Cap Growth Fund R6 Class",
        "price": 17.76
    }, {
        "symbol": "WESNX",
        "label": "William Blair Emerging Markets Small Cap Growth Fund Class N",
        "price": 17.63
    }, {
        "symbol": "BESIX",
        "label": "William Blair Emerging Markets Small Cap Growth Fund Class I",
        "price": 17.74
    }, {
        "symbol": "FFFCX",
        "label": "Fidelity Freedom 2010 Fund",
        "price": 15.78
    }, {
        "symbol": "CTFAX",
        "label": "Columbia Thermostat Fund Class A",
        "price": 15.26
    }, {
        "symbol": "FZAEX",
        "label": "Fidelity Advisor Emerging Markets Fund Class Z",
        "price": 29.61
    }, {
        "symbol": "VWINX",
        "label": "Vanguard Wellesley Income Fund Investor Shares",
        "price": 27.65
    }, {
        "symbol": "GIBRX",
        "label": "Guggenheim Total Return Bond Fund Class R6",
        "price": 27.26
    }, {
        "symbol": "MGBIX",
        "label": "AMG Managers Loomis Sayles Bond Fund Class I",
        "price": 27.33
    }, {
        "symbol": "MGFIX",
        "label": "AMG Managers Loomis Sayles Bond Fund Class N",
        "price": 27.32
    }, {
        "symbol": "GIBAX",
        "label": "Guggenheim Total Return Bond Fund Class A",
        "price": 27.22
    }, {
        "symbol": "GMCDX",
        "label": "GMO Emerging Country Debt Fund Class III",
        "price": 27.44
    }, {
        "symbol": "GMDFX",
        "label": "GMO Emerging Country Debt Fund Class IV",
        "price": 27.39
    }, {
        "symbol": "FMCKX",
        "label": "Fidelity Advisor Emerging Markets Fund Class C",
        "price": 27.66
    }, {
        "symbol": "BTTRX",
        "label": "American Century Zero Coupon 2025 Fund Investor Class",
        "price": 107.23
    }, {
        "symbol": "VWIAX",
        "label": "Vanguard Wellesley Income Fund Admiral Shares",
        "price": 66.99
    }, {
        "symbol": "TIDDX",
        "label": "T. Rowe Price International Discovery Fund I Class",
        "price": 70.09
    }, {
        "symbol": "TIBRX",
        "label": "Thornburg Investment Income Builder Fund Class R3",
        "price": 22.98
    }, {
        "symbol": "TIBCX",
        "label": "Thornburg Investment Income Builder Fund Class C",
        "price": 22.96
    }, {
        "symbol": "SAISX",
        "label": "SA International Small Company Fund Investor Class",
        "price": 20.54
    }, {
        "symbol": "PRIDX",
        "label": "T. Rowe Price International Discovery Fund",
        "price": 69.99
    }, {
        "symbol": "VMNVX",
        "label": "Vanguard Global Minimum Volatility Fund Admiral Shares",
        "price": 29.78
    }, {
        "symbol": "FIMKX",
        "label": "Fidelity Advisor Emerging Markets Fund I Class",
        "price": 29.69
    }, {
        "symbol": "FAMKX",
        "label": "Fidelity Advisor Emerging Markets Fund Class A",
        "price": 29.57
    }, {
        "symbol": "FTMKX",
        "label": "Fidelity Advisor Emerging Markets Fund Class M",
        "price": 29.28
    }, {
        "symbol": "APHKX",
        "label": "Artisan International Value Fund Institutional Class",
        "price": 37.46
    }, {
        "symbol": "ARTKX",
        "label": "Artisan International Value Fund Investor Class",
        "price": 37.39
    }, {
        "symbol": "SVOAX",
        "label": "SEI Institutional Managed Trust U.S. Managed Volatility Fund Class F",
        "price": 18.34
    }, {
        "symbol": "MIPIX",
        "label": "Matthews Asia Dividend Fund Institutional Class",
        "price": 17.89
    }, {
        "symbol": "DNINX",
        "label": "Dunham International Stock Fund Class N",
        "price": 16.37
    }, {
        "symbol": "RWDYX",
        "label": "Redwood Managed Volatility Fund Class Y",
        "price": 15.47
    }, {
        "symbol": "SIIDX",
        "label": "Bernstein Intermediate Duration Institutional Portfolio",
        "price": 15.41
    }, {
        "symbol": "RWDIX",
        "label": "Redwood Managed Volatility Fund Class I",
        "price": 15.35
    }, {
        "symbol": "APDKX",
        "label": "Artisan International Value Fund Advisor Class",
        "price": 37.31
    }, {
        "symbol": "SGGDX",
        "label": "First Eagle Gold Fund Class A",
        "price": 19.5
    }, {
        "symbol": "VRISX",
        "label": "Virtus KAR International Small-Cap Fund Class R6",
        "price": 19.24
    }, {
        "symbol": "VIISX",
        "label": "Virtus KAR International Small-Cap Fund Class I",
        "price": 19.23
    }, {
        "symbol": "FEGOX",
        "label": "First Eagle Gold Fund Class C",
        "price": 17.85
    }, {
        "symbol": "HFSAX",
        "label": "Hundredfold Select Alternative Fund Investor Class",
        "price": 22.68
    }, {
        "symbol": "JIBFX",
        "label": "Johnson Institutional Core Bond Fund",
        "price": 16.5
    }, {
        "symbol": "HRMVX",
        "label": "Harbor Mid Cap Value Fund Administrative Class",
        "price": 21.7
    }, {
        "symbol": "TFESX",
        "label": "Templeton Institutional Fund International Equity Series Service Shares",
        "price": 15.88
    }, {
        "symbol": "HIMVX",
        "label": "Harbor Mid Cap Value Fund Investor Class",
        "price": 21.48
    }, {
        "symbol": "HAMVX",
        "label": "Harbor Mid Cap Value Fund Institutional Class",
        "price": 21.46
    }, {
        "symbol": "HNMVX",
        "label": "Harbor Mid Cap Value Fund Retirement Class",
        "price": 21.45
    }, {
        "symbol": "TFEQX",
        "label": "Templeton Institutional Fund International Equity Series Primary Shares",
        "price": 15.63
    }, {
        "symbol": "FEGIX",
        "label": "First Eagle Gold Fund Class I",
        "price": 20.08
    }, {
        "symbol": "FHKIX",
        "label": "Fidelity Advisor China Region Fund I Class",
        "price": 41.49
    }, {
        "symbol": "FHKAX",
        "label": "Fidelity Advisor China Region Fund Class A",
        "price": 41.37
    }, {
        "symbol": "RINPX",
        "label": "Royce International Premier Fund Consultant Class",
        "price": 19.59
    }, {
        "symbol": "NMMTX",
        "label": "Nuveen Large-Cap Value Fund Class R3",
        "price": 24.11
    }, {
        "symbol": "NNGRX",
        "label": "Nuveen Large-Cap Value Fund Class I",
        "price": 23.93
    }, {
        "symbol": "DAMDX",
        "label": "Dunham Monthly Distribution Fund Class A",
        "price": 33.08
    }, {
        "symbol": "NNGAX",
        "label": "Nuveen Large-Cap Value Fund Class A",
        "price": 23.83
    }, {
        "symbol": "PRIUX",
        "label": "T. Rowe Price International Stock Fund I Class",
        "price": 19.11
    }, {
        "symbol": "IFPUX",
        "label": "Independent Franchise Partners US Equity Fund",
        "price": 19.22
    }, {
        "symbol": "FSMVX",
        "label": "Fidelity Mid Cap Value Fund",
        "price": 23.28
    }, {
        "symbol": "FMPOX",
        "label": "Fidelity Advisor Mid Cap Value Fund Class I",
        "price": 23.07
    }, {
        "symbol": "THOIX",
        "label": "Thornburg Global Opportunities Fund Class I",
        "price": 32.25
    }, {
        "symbol": "MBEYX",
        "label": "AMG Chicago Equity Partners Balanced Fund Class Z",
        "price": 17.49
    }, {
        "symbol": "DCMDX",
        "label": "Dunham Monthly Distribution Fund Class C",
        "price": 25.79
    }, {
        "symbol": "DNMDX",
        "label": "Dunham Monthly Distribution Fund Class N",
        "price": 34.29
    }, {
        "symbol": "AVUAX",
        "label": "American Century Mid Cap Value Fund I Class",
        "price": 17.24
    }, {
        "symbol": "AMVRX",
        "label": "American Century Mid Cap Value Fund R Class",
        "price": 17.13
    }, {
        "symbol": "ACCLX",
        "label": "American Century Mid Cap Value Fund C Class",
        "price": 16.98
    }, {
        "symbol": "LVAEX",
        "label": "LSV Value Equity Fund Investor Class",
        "price": 27.49
    }, {
        "symbol": "LCRIX",
        "label": "Leuthold Core Investment Fund Class Institutional",
        "price": 19.45
    }, {
        "symbol": "VAIPX",
        "label": "Vanguard Inflation-Protected Securities Fund Admiral Shares",
        "price": 26.0
    }, {
        "symbol": "SAOSX",
        "label": "Guggenheim Alpha Opportunity Fund Class P",
        "price": 17.54
    }, {
        "symbol": "SSIRX",
        "label": "Sierra Tactical Core Income Fund Institutional Class",
        "price": 21.63
    }, {
        "symbol": "RRTBX",
        "label": "T. Rowe Price Retirement 2020 Fund Class R",
        "price": 22.11
    }, {
        "symbol": "PARBX",
        "label": "T. Rowe Price Retirement 2020 Fund Advisor Class",
        "price": 22.32
    }, {
        "symbol": "TRRBX",
        "label": "T. Rowe Price Retirement 2020 Fund",
        "price": 22.48
    }, {
        "symbol": "FIOTX",
        "label": "Fidelity Advisor Managed Retirement 2005 Fund Class M",
        "price": 58.72
    }, {
        "symbol": "FNORX",
        "label": "Fidelity Nordic Fund",
        "price": 48.38
    }, {
        "symbol": "GILPX",
        "label": "Guggenheim Limited Duration Fund Class P",
        "price": 24.61
    }, {
        "symbol": "GMODX",
        "label": "GMO Opportunistic Income Fund Class VI",
        "price": 25.79
    }, {
        "symbol": "PKSAX",
        "label": "Virtus KAR Small-Cap Core Fund Class A",
        "price": 40.06
    }, {
        "symbol": "RRMVX",
        "label": "T. Rowe Price Mid-Cap Value Fund R Class",
        "price": 27.69
    }, {
        "symbol": "PKSFX",
        "label": "Virtus KAR Small-Cap Core Fund Class I",
        "price": 42.42
    }, {
        "symbol": "VSCRX",
        "label": "Virtus KAR Small-Cap Core Fund Class R6",
        "price": 42.58
    }, {
        "symbol": "TAMVX",
        "label": "T. Rowe Price Mid-Cap Value Fund Advisor Class",
        "price": 28.19
    }, {
        "symbol": "TRMIX",
        "label": "T. Rowe Price Mid-Cap Value Fund I Class",
        "price": 28.27
    }, {
        "symbol": "TRMCX",
        "label": "T. Rowe Price Mid-Cap Value Fund",
        "price": 28.28
    }, {
        "symbol": "FRNCX",
        "label": "Fidelity Advisor Simplicity RMD Income Fund Class C",
        "price": 58.17
    }, {
        "symbol": "CUBAX",
        "label": "Calvert Absolute Return Bond Fund Class A",
        "price": 15.19
    }, {
        "symbol": "TSSCX",
        "label": "Thornburg Strategic Municipal Income Fund Class C",
        "price": 15.43
    }, {
        "symbol": "FDLSX",
        "label": "Fidelity Select Leisure Portfolio",
        "price": 16.39
    }, {
        "symbol": "AADBX",
        "label": "American Beacon Balanced Fund Institutional Class",
        "price": 15.96
    }, {
        "symbol": "PKSCX",
        "label": "Virtus KAR Small-Cap Core Fund Class C",
        "price": 32.99
    }, {
        "symbol": "BPGIX",
        "label": "Boston Partners Global Equity Institutional Class",
        "price": 17.48
    }, {
        "symbol": "NPSFX",
        "label": "Nuveen Preferred Securities & Income Fund Class R6",
        "price": 17.72
    }, {
        "symbol": "FFFEX",
        "label": "Fidelity Freedom 2030 Fund",
        "price": 18.15
    }, {
        "symbol": "RRTAX",
        "label": "T. Rowe Price Retirement 2010 Fund Class R",
        "price": 18.09
    }, {
        "symbol": "PARAX",
        "label": "T. Rowe Price Retirement 2010 Fund Advisor Class",
        "price": 18.2
    }, {
        "symbol": "SUSYX",
        "label": "SEI Institutional Managed Trust U.S. Managed Volatility Fund Class Y",
        "price": 18.34
    }, {
        "symbol": "JIBBX",
        "label": "JPMorgan SmartRetirement Blend Income Fund Class R5",
        "price": 18.22
    }, {
        "symbol": "JIJSX",
        "label": "JPMorgan SmartRetirement Blend Income Fund Class I",
        "price": 18.23
    }, {
        "symbol": "TRRAX",
        "label": "T. Rowe Price Retirement 2010 Fund",
        "price": 18.28
    }, {
        "symbol": "NFRFX",
        "label": "Nuveen Symphony Floating Rate Income Fund Class R6",
        "price": 19.26
    }, {
        "symbol": "FSBCX",
        "label": "Federated Global Allocation Fund Class C Shares",
        "price": 19.71
    }, {
        "symbol": "FSBKX",
        "label": "Federated Global Allocation Fund Class R Shares",
        "price": 20.17
    }, {
        "symbol": "FSTBX",
        "label": "Federated Global Allocation Fund Class A Shares",
        "price": 20.32
    }, {
        "symbol": "PRSIX",
        "label": "T. Rowe Price Personal Strategy Income Fund",
        "price": 20.3
    }, {
        "symbol": "SBFIX",
        "label": "Federated Global Allocation Fund Institutional Shares",
        "price": 20.44
    }, {
        "symbol": "RYHHX",
        "label": "Rydex High Yield Strategy Fund Class C",
        "price": 21.51
    }, {
        "symbol": "IPFPX",
        "label": "Poplar Forest Partners Fund Institutional Class",
        "price": 44.9
    }, {
        "symbol": "PFPFX",
        "label": "Poplar Forest Partners Fund Class A",
        "price": 44.91
    }, {
        "symbol": "KWICX",
        "label": "Kinetics Alternative Income Fund Advisor Class C",
        "price": 93.45
    }, {
        "symbol": "KWIAX",
        "label": "Kinetics Alternative Income Fund Advisor Class A",
        "price": 97.11
    }, {
        "symbol": "GILDX",
        "label": "Guggenheim Limited Duration Fund A-Class",
        "price": 24.61
    }, {
        "symbol": "RYHGX",
        "label": "Rydex High Yield Strategy Fund Class H",
        "price": 24.81
    }, {
        "symbol": "RYHDX",
        "label": "Rydex High Yield Strategy Fund Class A",
        "price": 24.84
    }, {
        "symbol": "GIFCX",
        "label": "Guggenheim Floating Rate Strategies Fund Class C",
        "price": 25.44
    }, {
        "symbol": "GIFAX",
        "label": "Guggenheim Floating Rate Strategies Fund Class A",
        "price": 25.45
    }, {
        "symbol": "GIFPX",
        "label": "Guggenheim Floating Rate Strategies Fund Class P",
        "price": 25.46
    }, {
        "symbol": "GIFIX",
        "label": "Guggenheim Floating Rate Strategies Fund Institutional Class",
        "price": 25.47
    }, {
        "symbol": "GIOCX",
        "label": "Guggenheim Macro Opportunities Fund Class C",
        "price": 25.73
    }, {
        "symbol": "GIOAX",
        "label": "Guggenheim Macro Opportunities Fund Class A",
        "price": 25.74
    }, {
        "symbol": "GIOPX",
        "label": "Guggenheim Macro Opportunities Fund Class P",
        "price": 25.75
    }, {
        "symbol": "GIOIX",
        "label": "Guggenheim Macro Opportunities Fund Institutional Class",
        "price": 25.78
    }, {
        "symbol": "MIOPX",
        "label": "Morgan Stanley Institutional Fund, Inc. International Opportunity Portfolio Clas" +
                "s A",
        "price": 27.38
    }, {
        "symbol": "FIIVX",
        "label": "Fidelity Advisor Managed Retirement 2020 Fund Class I",
        "price": 53.32
    }, {
        "symbol": "FARVX",
        "label": "Fidelity Advisor Managed Retirement 2020 Fund Class A",
        "price": 53.31
    }, {
        "symbol": "BTTTX",
        "label": "American Century Zero Coupon 2020 Fund Investor Class",
        "price": 107.18
    }, {
        "symbol": "FIRVX",
        "label": "Fidelity Managed Retirement 2020 Fund",
        "price": 53.31
    }, {
        "symbol": "FIRSX",
        "label": "Fidelity Managed Retirement 2015 Fund",
        "price": 54.71
    }, {
        "symbol": "NFFCX",
        "label": "Nuveen Symphony Floating Rate Income Fund Class C",
        "price": 19.17
    }, {
        "symbol": "NFRIX",
        "label": "Nuveen Symphony Floating Rate Income Fund Class I",
        "price": 19.19
    }, {
        "symbol": "NCOIX",
        "label": "Nuveen Symphony High Yield Income Fund Class I",
        "price": 20.34
    }, {
        "symbol": "NFRAX",
        "label": "Nuveen Symphony Floating Rate Income Fund Class A",
        "price": 19.17
    }, {
        "symbol": "ACTEX",
        "label": "American Century Zero Coupon 2020 Fund Advisor Class",
        "price": 101.72
    }, {
        "symbol": "FRQIX",
        "label": "Fidelity Advisor Managed Retirement 2010 Fund Class I",
        "price": 57.69
    }, {
        "symbol": "FIRRX",
        "label": "Fidelity Simplicity RMD 2010 Fund",
        "price": 54.75
    }, {
        "symbol": "SEGPX",
        "label": "Guggenheim Large Cap Value Fund Class P",
        "price": 44.39
    }, {
        "symbol": "CBMSX",
        "label": "Wells Fargo C&B Mid Cap Value Fund - Class Inst",
        "price": 42.48
    }, {
        "symbol": "VEVRX",
        "label": "Victory Sycamore Established Value Fund Class R6",
        "price": 40.63
    }, {
        "symbol": "VEVIX",
        "label": "Victory Sycamore Established Value Fund Class I",
        "price": 40.62
    }, {
        "symbol": "FIROX",
        "label": "Fidelity Managed Retirement 2005 Fund",
        "price": 59.81
    }, {
        "symbol": "FIOAX",
        "label": "Fidelity Advisor Managed Retirement 2005 Fund Class A",
        "price": 59.8
    }, {
        "symbol": "FIOIX",
        "label": "Fidelity Advisor Managed Retirement 2005 Fund Class I",
        "price": 59.79
    }, {
        "symbol": "FRIMX",
        "label": "Fidelity Advisor Managed Retirement Income Fund Class I",
        "price": 58.96
    }, {
        "symbol": "FIRMX",
        "label": "Fidelity Managed Retirement Income Fund",
        "price": 58.96
    }, {
        "symbol": "FIXIX",
        "label": "Fidelity Advisor International Small Cap Fund Class I",
        "price": 28.13
    }, {
        "symbol": "FISMX",
        "label": "Fidelity International Small Cap Fund",
        "price": 27.95
    }, {
        "symbol": "FIASX",
        "label": "Fidelity Advisor International Small Cap Fund Class A",
        "price": 27.43
    }, {
        "symbol": "FTISX",
        "label": "Fidelity Advisor International Small Cap Fund Class M",
        "price": 27.35
    }, {
        "symbol": "GIBIX",
        "label": "Guggenheim Total Return Bond Fund Institutional Class",
        "price": 27.24
    }, {
        "symbol": "GIBCX",
        "label": "Guggenheim Total Return Bond Fund Class C",
        "price": 27.22
    }, {
        "symbol": "GIBLX",
        "label": "Guggenheim Total Return Bond Fund Class P",
        "price": 27.21
    }, {
        "symbol": "FICSX",
        "label": "Fidelity Advisor International Small Cap Fund Class C",
        "price": 26.54
    }, {
        "symbol": "PTIAX",
        "label": "Performance Trust Strategic Bond Fund",
        "price": 22.93
    }, {
        "symbol": "NQWFX",
        "label": "Nuveen NWQ Flexible Income Fund Class R6",
        "price": 22.61
    }, {
        "symbol": "NWQIX",
        "label": "Nuveen NWQ Flexible Income Fund Class I",
        "price": 22.48
    }, {
        "symbol": "NWQAX",
        "label": "Nuveen NWQ Flexible Income Fund Class A",
        "price": 22.46
    }, {
        "symbol": "NWQCX",
        "label": "Nuveen NWQ Flexible Income Fund Class C",
        "price": 22.41
    }, {
        "symbol": "GILCX",
        "label": "Guggenheim Large Cap Value Fund Class Institutional",
        "price": 43.91
    }, {
        "symbol": "VSCGX",
        "label": "Vanguard LifeStrategy Conservative Growth Fund Investor Shares",
        "price": 21.32
    }, {
        "symbol": "FGADX",
        "label": "Franklin Gold and Precious Metals Fund Advisor Class",
        "price": 20.22
    }, {
        "symbol": "ITTIX",
        "label": "Hartford Multi-Asset Income and Growth Fund Class I",
        "price": 20.67
    }, {
        "symbol": "MAPOX",
        "label": "Mairs & Power Balanced Fund Investor Class",
        "price": 101.34
    }, {
        "symbol": "FIRNX",
        "label": "Fidelity Simplicity RMD Income Fund",
        "price": 59.68
    }, {
        "symbol": "MTINX",
        "label": "MainStay Income Builder Fund Investor Class",
        "price": 19.88
    }, {
        "symbol": "MTXVX",
        "label": "MainStay Income Builder Fund Class R3",
        "price": 19.87
    }, {
        "symbol": "GETGX",
        "label": "Victory Sycamore Established Value Fund Class R",
        "price": 40.01
    }, {
        "symbol": "VEVCX",
        "label": "Victory Sycamore Established Value Fund Class C",
        "price": 39.62
    }, {
        "symbol": "SIUPX",
        "label": "Guggenheim Investment Grade Bond Fund Class P",
        "price": 18.81
    }, {
        "symbol": "SIUSX",
        "label": "Guggenheim Investment Grade Bond Fund Class A",
        "price": 18.8
    }, {
        "symbol": "GIUSX",
        "label": "Guggenheim Investment Grade Bond Fund Institutional Class",
        "price": 18.77
    }, {
        "symbol": "SDICX",
        "label": "Guggenheim Investment Grade Bond Fund Class C",
        "price": 18.72
    }, {
        "symbol": "QGRNX",
        "label": "Invesco Oppenheimer Global Allocation Fund Class R",
        "price": 18.83
    }, {
        "symbol": "SEVIX",
        "label": "SEI Institutional Managed Trust U.S. Managed Volatility Fund Class I",
        "price": 18.34
    }, {
        "symbol": "QGRCX",
        "label": "Invesco Oppenheimer Global Allocation Fund Class C",
        "price": 18.26
    }, {
        "symbol": "CFAIX",
        "label": "Calvert Conservative Allocation Fund Class I",
        "price": 17.86
    }, {
        "symbol": "CCLAX",
        "label": "Calvert Conservative Allocation Fund Class A",
        "price": 17.84
    }, {
        "symbol": "SBFCX",
        "label": "Victory INCORE Investment Grade Convertible Fund Class A",
        "price": 17.65
    }, {
        "symbol": "HAOYX",
        "label": "The Hartford International Opportunities Fund Class Y",
        "price": 17.67
    }, {
        "symbol": "IVWAX",
        "label": "IVA Worldwide Fund Class A",
        "price": 16.79
    }, {
        "symbol": "VGIVX",
        "label": "Vanguard Emerging Markets Government Bond Index Fund Institutional Shares",
        "price": 32.96
    }, {
        "symbol": "JBFRX",
        "label": "John Hancock Bond Fund Class R4",
        "price": 16.21
    }, {
        "symbol": "JHNBX",
        "label": "John Hancock Bond Fund Class A",
        "price": 16.19
    }, {
        "symbol": "CDICX",
        "label": "Calvert Short Duration Income Fund Class C",
        "price": 16.11
    }, {
        "symbol": "DAINX",
        "label": "Dunham International Stock Fund Class A",
        "price": 16.26
    }, {
        "symbol": "JMBRX",
        "label": "JPMorgan SmartRetirement Blend 2045 Fund Class R5",
        "price": 25.62
    }, {
        "symbol": "JMYAX",
        "label": "JPMorgan SmartRetirement Blend 2045 Fund Class R6",
        "price": 25.65
    }, {
        "symbol": "FTSDX",
        "label": "Fidelity Advisor Strategic Dividend & Income Fund Class M",
        "price": 15.62
    }, {
        "symbol": "FASDX",
        "label": "Fidelity Advisor Strategic Dividend & Income Fund Class A",
        "price": 15.63
    }, {
        "symbol": "FSIDX",
        "label": "Fidelity Advisor Strategic Dividend & Income Fund I Class",
        "price": 15.69
    }, {
        "symbol": "FSDIX",
        "label": "Fidelity Strategic Dividend & Income Fund",
        "price": 15.74
    }, {
        "symbol": "RYPDX",
        "label": "Rydex Consumer Products Fund Class A",
        "price": 58.57
    }, {
        "symbol": "ABLOX",
        "label": "Alger Balanced Portfolio Class I-2",
        "price": 16.04
    }, {
        "symbol": "SIIEX",
        "label": "Touchstone International Equity Fund Class Y",
        "price": 16.0
    }, {
        "symbol": "RYCPX",
        "label": "Rydex Consumer Products Fund Class C",
        "price": 49.1
    }, {
        "symbol": "SWRLX",
        "label": "Touchstone International Equity Fund Class A",
        "price": 16.25
    }, {
        "symbol": "SLVIX",
        "label": "Columbia Select Large Cap Value Fund Institutional 2 Class",
        "price": 27.33
    }, {
        "symbol": "EGOAX",
        "label": "Wells Fargo Large Cap Core Fund - Class A",
        "price": 17.0
    }, {
        "symbol": "EGOHX",
        "label": "Wells Fargo Large Cap Core Fund - Class R",
        "price": 17.01
    }, {
        "symbol": "WFLLX",
        "label": "Wells Fargo Large Cap Core Fund - Class Admin",
        "price": 17.26
    }, {
        "symbol": "BIECX",
        "label": "Brandes International Equity Fund Class C",
        "price": 16.83
    }, {
        "symbol": "WPLCX",
        "label": "WP Large Cap Income Plus Fund Institutional Class",
        "price": 17.52
    }, {
        "symbol": "HCYAX",
        "label": "Hilton Tactical Income Fund Investor Class",
        "price": 17.89
    }, {
        "symbol": "HCYIX",
        "label": "Hilton Tactical Income Fund Institutional Class",
        "price": 17.91
    }, {
        "symbol": "FCRSX",
        "label": "Fidelity Advisor Managed Retirement 2015 Fund Class C",
        "price": 53.03
    }, {
        "symbol": "JOBBX",
        "label": "JPMorgan SmartRetirement Blend 2040 Fund Class R5",
        "price": 25.3
    }, {
        "symbol": "OMOIX",
        "label": "Vivaldi Multi-Strategy Fund Class I",
        "price": 26.23
    }, {
        "symbol": "LOGBX",
        "label": "Scharf Multi-Asset Opportunity Fund Retail Class",
        "price": 33.73
    }, {
        "symbol": "FDIGX",
        "label": "Fidelity Advisor Consumer Staples Fund I Class",
        "price": 88.78
    }, {
        "symbol": "FDFAX",
        "label": "Fidelity Select Consumer Staples Portfolio",
        "price": 88.99
    }, {
        "symbol": "FPACX",
        "label": "FPA Crescent Fund",
        "price": 34.37
    }, {
        "symbol": "ITTAX",
        "label": "Hartford Multi-Asset Income and Growth Fund Class A",
        "price": 20.69
    }, {
        "symbol": "IHAYX",
        "label": "Hartford Multi-Asset Income and Growth Fund Class Y",
        "price": 21.06
    }, {
        "symbol": "FDCGX",
        "label": "Fidelity Advisor Consumer Staples Fund Class C",
        "price": 85.61
    }, {
        "symbol": "FGFRX",
        "label": "Federated International Leaders Fund Class R",
        "price": 35.79
    }, {
        "symbol": "FGRSX",
        "label": "Federated International Leaders Fund Class R6 Shares",
        "price": 35.9
    }, {
        "symbol": "FGFAX",
        "label": "Federated International Leaders Fund Class A Shares",
        "price": 35.96
    }, {
        "symbol": "FSPSX",
        "label": "Fidelity International Index Fund",
        "price": 43.5
    }, {
        "symbol": "SSIZX",
        "label": "Sierra Tactical Core Income Fund Class A",
        "price": 21.73
    }, {
        "symbol": "CYYYX",
        "label": "Columbia Thermostat Fund Institutional 3 Class",
        "price": 15.1
    }, {
        "symbol": "CTORX",
        "label": "Columbia Thermostat Fund Advisor Class",
        "price": 15.11
    }, {
        "symbol": "RSMRX",
        "label": "RBC SMID Cap Growth Fund Class R6",
        "price": 15.54
    }, {
        "symbol": "FCSDX",
        "label": "Fidelity Advisor Strategic Dividend & Income Fund Class C",
        "price": 15.56
    }, {
        "symbol": "JRBYX",
        "label": "JPMorgan SmartRetirement Blend 2030 Fund Class R6",
        "price": 23.35
    }, {
        "symbol": "JPBRX",
        "label": "JPMorgan SmartRetirement Blend 2035 Fund Class R5",
        "price": 24.61
    }, {
        "symbol": "JPYRX",
        "label": "JPMorgan SmartRetirement Blend 2035 Fund Class R6",
        "price": 24.62
    }, {
        "symbol": "LKBAX",
        "label": "LKCM Balanced Fund",
        "price": 24.72
    }, {
        "symbol": "PALRX",
        "label": "PGIM Balanced Fund- Class R",
        "price": 16.41
    }, {
        "symbol": "JDVAX",
        "label": "JPMorgan Diversified Fund Class A",
        "price": 16.49
    }, {
        "symbol": "CDSIX",
        "label": "Calvert Short Duration Income Fund Class I",
        "price": 16.28
    }, {
        "symbol": "JPDVX",
        "label": "JPMorgan Diversified Fund Class L",
        "price": 16.57
    }, {
        "symbol": "JDVSX",
        "label": "JPMorgan Diversified Fund Class I",
        "price": 16.6
    }, {
        "symbol": "GILHX",
        "label": "Guggenheim Limited Duration Fund Institutional Class",
        "price": 24.6
    }, {
        "symbol": "VWENX",
        "label": "Vanguard Wellington Fund Admiral Shares",
        "price": 76.34
    }, {
        "symbol": "PTIMX",
        "label": "Performance Trust Municipal Bond Fund - Institutional Class",
        "price": 25.32
    }, {
        "symbol": "PTRMX",
        "label": "Performance Trust Municipal Bond Fund - A Class",
        "price": 25.34
    }, {
        "symbol": "FJSCX",
        "label": "Fidelity Japan Smaller Companies Fund",
        "price": 16.96
    }, {
        "symbol": "OMOAX",
        "label": "Vivaldi Multi-Strategy Fund Class A",
        "price": 25.73
    }, {
        "symbol": "VWELX",
        "label": "Vanguard Wellington Fund Investor Shares",
        "price": 44.21
    }, {
        "symbol": "PARJX",
        "label": "T. Rowe Price Retirement 2025 Fund Advisor Class",
        "price": 18.05
    }, {
        "symbol": "TRRHX",
        "label": "T. Rowe Price Retirement 2025 Fund",
        "price": 18.15
    }, {
        "symbol": "DHLSX",
        "label": "Diamond Hill Long Short Fund Class I",
        "price": 27.23
    }, {
        "symbol": "DIAYX",
        "label": "Diamond Hill Long-Short Fund Class Y",
        "price": 27.35
    }, {
        "symbol": "FFTMX",
        "label": "Fidelity Advisor Asset Manager 50% Fund Class M",
        "price": 18.87
    }, {
        "symbol": "PPIPX",
        "label": "T. Rowe Price Personal Strategy Income Fund I Class",
        "price": 20.29
    }, {
        "symbol": "BSPIX",
        "label": "iShares S&P 500 Index Fund Institutional Shares",
        "price": 394.63
    }, {
        "symbol": "MRAGX",
        "label": "Meridian Growth Fund A Class",
        "price": 37.95
    }, {
        "symbol": "BSPAX",
        "label": "iShares S&P 500 Index Fund Investor A Shares",
        "price": 394.47
    }, {
        "symbol": "MSGCX",
        "label": "Meridian Small Cap Growth Fund Class C",
        "price": 15.24
    }, {
        "symbol": "MIJFX",
        "label": "Matthews Japan Fund Institutional Class",
        "price": 21.66
    }, {
        "symbol": "LISIX",
        "label": "Lazard International Strategic Equity Portfolio Institutional Shares",
        "price": 15.72
    }, {
        "symbol": "SMMOX",
        "label": "Western Asset Managed Municipals Fund Class 1",
        "price": 16.52
    }, {
        "symbol": "SHMMX",
        "label": "Western Asset Managed Municipals Fund Class A",
        "price": 16.58
    }, {
        "symbol": "SMMCX",
        "label": "Western Asset Managed Municipals Fund Class C",
        "price": 16.59
    }, {
        "symbol": "FLCSX",
        "label": "Fidelity Large Cap Stock Fund",
        "price": 34.3
    }, {
        "symbol": "FLCCX",
        "label": "Fidelity Advisor Large Cap Fund Class C",
        "price": 28.01
    }, {
        "symbol": "FALAX",
        "label": "Fidelity Advisor Large Cap Fund Class A",
        "price": 31.69
    }, {
        "symbol": "PRWCX",
        "label": "T. Rowe Price Capital Appreciation Fund",
        "price": 32.09
    }, {
        "symbol": "TRAIX",
        "label": "T. Rowe Price Capital Appreciation Fund I Class",
        "price": 32.12
    }, {
        "symbol": "MJFOX",
        "label": "Matthews Japan Fund Investor Class",
        "price": 21.61
    }, {
        "symbol": "NHCCX",
        "label": "Nuveen High Yield Municipal Bond Fund Class C",
        "price": 18.22
    }, {
        "symbol": "NHMCX",
        "label": "Nuveen High Yield Municipal Bond Fund Class C2",
        "price": 18.23
    }, {
        "symbol": "NHMAX",
        "label": "Nuveen High Yield Municipal Bond Fund Class A",
        "price": 18.24
    }, {
        "symbol": "NHMRX",
        "label": "Nuveen High Yield Municipal Bond Fund Class I",
        "price": 18.25
    }, {
        "symbol": "NHMFX",
        "label": "Nuveen High Yield Municipal Bond Fund Class R6",
        "price": 18.26
    }, {
        "symbol": "FIDLX",
        "label": "Fidelity Advisor Large Cap Fund Class Z",
        "price": 33.51
    }, {
        "symbol": "FALIX",
        "label": "Fidelity Advisor Large Cap Fund I Class",
        "price": 33.56
    }, {
        "symbol": "FETKX",
        "label": "Fidelity Equity Dividend Income Fund Class K",
        "price": 26.06
    }, {
        "symbol": "FVDKX",
        "label": "Fidelity Value Discovery Fund Class K",
        "price": 30.47
    }, {
        "symbol": "PACLX",
        "label": "T. Rowe Price Capital Appreciation Fund Advisor Class",
        "price": 31.74
    }, {
        "symbol": "MSGAX",
        "label": "Meridian Small Cap Growth Fund A Class",
        "price": 15.73
    }, {
        "symbol": "MSGGX",
        "label": "Meridian Small Cap Growth Fund Legacy Class",
        "price": 16.12
    }, {
        "symbol": "MSGRX",
        "label": "Meridian Small Cap Growth Fund Institutional Class",
        "price": 16.18
    }, {
        "symbol": "NRIAX",
        "label": "Nuveen Real Asset Income Fund Class A",
        "price": 25.13
    }, {
        "symbol": "NRIIX",
        "label": "Nuveen Real Asset Income Fund Class I",
        "price": 25.13
    }, {
        "symbol": "NRICX",
        "label": "Nuveen Real Asset Income Fund Class C",
        "price": 25.14
    }, {
        "symbol": "NRIFX",
        "label": "Nuveen Real Asset Income Fund Class R6",
        "price": 25.26
    }, {
        "symbol": "JHQCX",
        "label": "JPMorgan Hedged Equity Fund Class C",
        "price": 21.45
    }, {
        "symbol": "LEVIX",
        "label": "Lazard US Equity Concentrated Portfolio Institutional Shares",
        "price": 17.54
    }, {
        "symbol": "LEVOX",
        "label": "Lazard US Equity Concentrated Portfolio Open Shares",
        "price": 17.68
    }, {
        "symbol": "JHQPX",
        "label": "JPMorgan Hedged Equity Fund Class R5",
        "price": 21.69
    }, {
        "symbol": "JHQRX",
        "label": "JPMorgan Hedged Equity Fund Class R6",
        "price": 21.7
    }, {
        "symbol": "FTRVX",
        "label": "Fidelity Advisor Managed Retirement 2020 Fund Class M",
        "price": 51.57
    }, {
        "symbol": "BIEAX",
        "label": "Brandes International Equity Fund A",
        "price": 17.14
    }, {
        "symbol": "BIIEX",
        "label": "Brandes International Equity Fund Class I",
        "price": 17.21
    }, {
        "symbol": "USBLX",
        "label": "USAA Growth and Tax Strategy Fund",
        "price": 22.35
    }, {
        "symbol": "MXXVX",
        "label": "Matthew 25 Fund",
        "price": 33.09
    }, {
        "symbol": "GMOKX",
        "label": "GMO Risk Premium Fund Class VI",
        "price": 27.96
    }, {
        "symbol": "BVEFX",
        "label": "Becker Value Equity Fund Retail Class",
        "price": 18.89
    }, {
        "symbol": "BVEIX",
        "label": "Becker Value Equity Fund Institutional Class",
        "price": 18.94
    }, {
        "symbol": "GMRPX",
        "label": "GMO Risk Premium Fund Class III",
        "price": 28.22
    }, {
        "symbol": "FAMFX",
        "label": "FAM Small Cap Fund Investor Class",
        "price": 19.11
    }, {
        "symbol": "RYCIX",
        "label": "Rydex Consumer Products Fund Class Investor",
        "price": 64.65
    }, {
        "symbol": "VTMFX",
        "label": "Vanguard Tax-Managed Balanced Fund Admiral Shares",
        "price": 34.94
    }, {
        "symbol": "JOBEX",
        "label": "JPMorgan SmartRetirement Blend 2040 Fund Class I",
        "price": 25.28
    }, {
        "symbol": "VGENX",
        "label": "Vanguard Energy Fund Investor Shares",
        "price": 47.26
    }, {
        "symbol": "VGELX",
        "label": "Vanguard Energy Fund Admiral Shares",
        "price": 88.66
    }, {
        "symbol": "FANIX",
        "label": "Fidelity Advisor Energy Fund I Class",
        "price": 28.59
    }, {
        "symbol": "FAGNX",
        "label": "Fidelity Advisor Energy Fund Class M",
        "price": 27.84
    }, {
        "symbol": "FSENX",
        "label": "Fidelity Select Energy Portfolio",
        "price": 35.11
    }, {
        "symbol": "RYOIX",
        "label": "Rydex Biotechnology Fund Class Investor",
        "price": 84.12
    }, {
        "symbol": "PRHSX",
        "label": "T. Rowe Price Health Sciences Fund",
        "price": 83.31
    }, {
        "symbol": "THISX",
        "label": "T. Rowe Price Health Sciences Fund I Class",
        "price": 83.31
    }, {
        "symbol": "JFNSX",
        "label": "Janus Henderson Global Life Sciences Fund Class S",
        "price": 59.87
    }, {
        "symbol": "JAGLX",
        "label": "Janus Henderson Global Life Sciences Fund Class T",
        "price": 61.77
    }, {
        "symbol": "JNGLX",
        "label": "Janus Henderson Global Life Sciences Fund Class D",
        "price": 62.06
    }, {
        "symbol": "JFNIX",
        "label": "Janus Henderson Global Life Sciences Fund Class I",
        "price": 62.16
    }, {
        "symbol": "JFNAX",
        "label": "Janus Henderson Global Life Sciences Fund Class A",
        "price": 60.96
    }, {
        "symbol": "JFNCX",
        "label": "Janus Henderson Global Life Sciences Fund Class C",
        "price": 55.37
    }, {
        "symbol": "FBTTX",
        "label": "Fidelity Advisor Biotechnology Fund Class M",
        "price": 26.62
    }, {
        "symbol": "FBTAX",
        "label": "Fidelity Advisor Biotechnology Fund Class A",
        "price": 28.37
    }, {
        "symbol": "DDDAX",
        "label": "13D Activist Fund Class A",
        "price": 22.2
    }, {
        "symbol": "DDDIX",
        "label": "13D Activist Fund Class I",
        "price": 22.73
    }, {
        "symbol": "DDDCX",
        "label": "13D Activist Fund Class C",
        "price": 21.81
    }, {
        "symbol": "ADKSX",
        "label": "Adirondack Small Cap Fund",
        "price": 17.39
    }, {
        "symbol": "GMDZX",
        "label": "GuideStone Funds Medium-Duration Bond Fund Investor Class",
        "price": 15.11
    }, {
        "symbol": "MRIGX",
        "label": "Meridian Growth Fund Investor Class",
        "price": 39.0
    }
];