export const ACTIVITIES = [
    {
        label: "Predatory Lending",
        value: "predatory_lending",
        icon: "iconsminds-coins"
    }, {
        label: "Tobacco",
        value: "tobacco",
        icon: "iconsminds-smoking-pipe"
    }, {
        label: "Adult Entertainment",
        value: "adult_entertainment",
        icon: "iconsminds-heels-2"
    }, {
        label: "Weapons Involvement",
        value: "weapons_involvement",
        icon: "iconsminds-target"
    }, {
        label: "Gambling",
        value: "gambling",
        icon: "iconsminds-dice"
    }, {
        label: "Nuclear Power",
        value: "nuclear_power",
        icon: "iconsminds-bio-hazard"
    }, {
        label: "Alcohol",
        value: "alcohol",
        icon: "iconsminds-beer-glass"
    }, {
        label: "GMO",
        value: "gmo",
        icon: "iconsminds-chemical"
    }
];

export const THEMES = [
    {
        label: "Clean Water",
        value: "clean_water",
        icon: "iconsminds-glass-water"
    }, {
        label: "Healthy Living",
        value: "healthy_living",
        icon: "iconsminds-apple"
    }, {
        label: "Artificial Intelligence",
        value: "artificial_intelligence",
        icon: "iconsminds-brain"
    }, {
        label: "Fintech",
        value: "fintech",
        icon: "iconsminds-dollar-sign-2"
    }, {
        label: "Biotechnology",
        value: "biotechnology",
        icon: "iconsminds-dna-2"
    }, {
        label: "Battery Tech.",
        value: "battery_technology",
        icon: "iconsminds-battery-charge"
    }, {
        label: "Cloud Tech.",
        value: "cloud_technology",
        icon: "iconsminds-data-cloud"
    }, {
        label: "Climate Action",
        value: "climate_action",
        icon: "iconsminds-sunrise"
    }, {
        label: "Renewable Energy",
        value: "renewable_energy",
        icon: "iconsminds-green-energy"
    }, {
        label: "Better Health",
        value: "better_health",
        icon: "iconsminds-heart"
    }, {
        label: "Smart Cities",
        value: "smart_cities",
        icon: "iconsminds-building"
    }
];

export const RISK_OPTIONS = [
    {
        label: 'Very low',
        value: 1
    }, {
        label: 'Low',
        value: 2
    }, {
        label: 'Medium',
        value: 3
    }, {
        label: 'High',
        value: 4
    }, {
        label: 'Very high',
        value: 5
    }
];

export const PRIORITY_OPTIONS = [
    {
        label: 'Keep the risk to a minimum',
        value: 'low_risk'
    }, {
        label: 'Balance risk and return',
        value: 'medium_risk'
    }, {
        label: 'Aim for higher return',
        value: 'high_risk'
    }
];

export const ACLASSES = [
    {
        label: 'US Equities - Market Indices',
        id: 'market',
        selected: false,
        disabled: false
    },
    {
        label: 'US Equities - Thematic Indices',
        id: 'thematic',
        selected: false,
        disabled: false,
    },
    {
        label: 'US Equities - Sectoral Indices',
        id: 'sectoral',
        selected: false,
        disabled: false
    },
    {
        label: 'International Equities',
        id: 'international',
        selected: false,
        disabled: true
    },
    {
        label: 'Real Estate',
        id: 'real_estate',
        selected: false,
        disabled: true
    },
    {
        label: 'Commodities',
        id: 'commodities',
        selected: false,
        disabled: true
    }
]

export const SECTORS = [
    {
        label: 'Energy',
        id: 'energy',
        selected: false
    }, {
        label: 'Materials',
        id: 'materials',
        selected: false
    }, {
        label: 'Industrials',
        id: 'industrials',
        selected: false
    }, {
        label: 'Consumer Discretionary',
        id: 'consumer_discretionary',
        selected: false
    }, {
        label: 'Consumer Staples',
        id: 'consumer_staples',
        selected: false
    }, {
        label: 'Health Care',
        id: 'healthcare',
        selected: false
    }, {
        label: 'Financials',
        id: 'financials',
        selected: false
    }, {
        label: 'Information Technology',
        id: 'information_technology',
        selected: false
    }, {
        label: 'Telecommunication Services',
        id: 'telecommunication_services',
        selected: false
    }, {
        label: 'Utilities',
        id: 'utilities',
        selected: false
    }, {
        label: 'Real Estate',
        id: 'real_estate',
        selected: false
    }
];