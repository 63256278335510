import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    Button,
    Icon,
    Row,
    Col,
    Whisper,
    Tooltip,
    Progress,
    Drawer,
    Modal
} from 'rsuite';
import moment from 'moment';
import ReactPDF, { PDFViewer, PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import PerformanceChart from './../../components/PerformanceChart';
import PortfolioIntelligence from './../../components/PortfolioIntelligence';
import MatchSuggestion from './../../components/MatchSuggestion';
import ScoreBreakdown from './../../components/ScoreBreakdown';
import ETFDetails from './../../components/ETFDetails';
import HoldingsTable from './../../components/HoldingsTable';
import { PDFDownload } from './../../components/PDFDownload';
import ClientAPI from './../../api/client';
import { getColorByScore } from './../../service/util/score';
import PDFSuggestions from './../../components/PDFSuggestions';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import FinanceAPI from './../../api/financial';

class Detail extends Component {
    state = {
        client: {},
        pdfData: {},
        isLoadingClient: false,
        isLoadingStats: false,
        generatingPDF: false,
        currentStats: {},
        suggestions: {},
        isLoadingSuggestions: false,
        selectedSuggestionIndex: 0,
        areMoreSuggestionsVisible: false,
        isScoreBreakdownVisible: false,
        areHoldingsVisible: false,
        isAnalysisVisible: false,
        areDetailsVisible: false,
        mode: 'client'
    }

    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if(this.props.match.params.id==='analyse'){
            this.setState({mode:"analyse"}, ()=>{
                let params = JSON.parse(localStorage.getItem("analyse"));
                params.id = 1;

                let currAdv = JSON.parse(localStorage.getItem("t__demo-user"))

                if(currAdv.auth_key==='c4ca4238a0b923820dcc509a6f75849b'){
                    params.id=17;
                }

                console.log("SINEK", params);
                if(!params){
                    this.props.history.push('/analyse');
                }
                this.getSuggestion(params);
            });
        } else {
            this.getClient(this.props.match.params.id);
        }
    }

    getClient = async (id) => {
        try {
            this.setState({ isLoadingClient: true });
            let res = await ClientAPI.details({ id: parseInt(id) });
            this.setState({
                client: res.data.client
            }, () => {
                this.getSuggestion(res.data.client);
            });
        } catch (error) {
            console.log("error")
            alert("There has been an error...")
        } finally {
            this.setState({ isLoadingClient: false });
            return;
        }
    }

    getSuggestion = async (client) => {
        const suggestionRequest = {
            id: client.id,
            suggestion_types: ['etf'],
            ideas_to_support: client.default_portfolio_params.ideas_to_support,
            index_types: client.default_portfolio_params.index_types,
            sector_restrictions: client.default_portfolio_params.sector_restrictions,
            etf_pool: client.default_portfolio_params.etf_pool,
            security_restrictions: client.default_portfolio_params.security_restrictions,
            budget: client.budget,
            risk_level: client.default_portfolio_params.risk_level
        };

        try {
            this.setState({ isLoadingSuggestions: true });
            let res = await ClientAPI.fetchSuggestion(suggestionRequest);
            this.setState({ suggestions: res.data });
        } catch (error) {
            console.log("error")
            alert("There has been an error...")
        } finally {
            this.setState({ isLoadingSuggestions: false });
            return;
        }
    }

    handleEdit = () => {
        this
            .props
            .history
            .push('/clients/edit/' + this.state.client.id);
    }

    handleExportForAnalysis = async (currentSuggestion) => {
        if (this.state.currentStats.ticker !== currentSuggestion.etf.ticker) {
            let stats = await this.getStockStats(currentSuggestion.etf.ticker);
            this.setState({ currentStats: stats })
        }

        this.setState({ isAnalysisVisible: true });
    }

    handleHideExportForAnalysis = () => {
        this.setState({ isAnalysisVisible: false });
    }

    handleShowMoreSuggestions = () => {
        this.setState({ areMoreSuggestionsVisible: true });
    }

    handleHideMoreSuggestions = () => {
        this.setState({ areMoreSuggestionsVisible: false });
    }


    getStockStats = async (ticker) => {
        this.setState({ isLoadingStats: true });
        let stats = {};

        try {
            let res = await FinanceAPI.fetchStatsIEX({ symbol: ticker });
            stats = {
                ticker: ticker,
                year5ChangePercent: (res.data.year5ChangePercent * 100).toFixed(2),
                year2ChangePercent: (res.data.year2ChangePercent * 100).toFixed(2),
                year1ChangePercent: (res.data.year1ChangePercent * 100).toFixed(2),
                ytdChangePercent: (res.data.ytdChangePercent * 100).toFixed(2),
                month6ChangePercent: (res.data.month6ChangePercent * 100).toFixed(2)
            }
        } catch (error) {
            alert("Problem getting stock stats for " + ticker, '2017-01-01');
        }

        this.setState({ isLoadingStats: false });
        return stats
    }

    handleOpenWithMorningstar = (currentSuggestion) => {
        const morningstarSE = {
            "ARCA": "arcx",
            "NASDAQ": "xnas",
            "BATS": "bats"
        }

        const ticker = currentSuggestion.etf.ticker.toLowerCase();
        const stockExchange = currentSuggestion.etf.stock_exchange;

        window.open("https://www.morningstar.com/etfs/" + morningstarSE[stockExchange] + "/" + ticker + "/quote", "_blank")

        this.handleHideExportForAnalysis();
    }

    handleSelectMatch = (selectedSuggestionIndex) => {
        this.setState({
            selectedSuggestionIndex,
            areMoreSuggestionsVisible: false
        }, () => {
            const el = document.getElementById('bestMatchingResult');
            el.scrollIntoView();
        });
    }

    handleShowScoreBreakdown = () => {
        this.setState({ isScoreBreakdownVisible: true });
    }

    handleHideScoreBreakdown = () => {
        this.setState({ isScoreBreakdownVisible: false });
    }

    handleShowDetails = () => {
        this.setState({ areDetailsVisible: true });
    }

    handleHideDetails = () => {
        this.setState({ areDetailsVisible: false });
    }

    handleShowHoldings = () => {
        this.setState({ areHoldingsVisible: true });
    }

    handleHideHoldings = () => {
        this.setState({ areHoldingsVisible: false });
    }

    handleGeneratePDFDocument = async (top5) => {
        this.setState({ generatingPDF: true })
        let documentData = await this.setPDFData(top5);
        const blob = await pdf((
            <PDFSuggestions data={documentData} />
        )).toBlob();
        let docName = this.state.client.first_name + "_" + this.state.client.last_name + "_"
        if (top5) {
            docName += "top_5.pdf"
        } else {
            docName += documentData.suggestions[0].ticker + ".pdf"
        }
        saveAs(blob, docName);
        this.setState({ generatingPDF: false })
    }

    setPDFData = async (top5) => {
        let pdfData = {
            clientInfo: {},
            portfolioInfo: {},
            keyPreferences: {},
            suggestion: {},
            performanceStats: {}
        };

        let birthDate = new Date(this.state.client.date_of_birth);
        let retirementDate = new Date(this.state.client.retirement_date);

        pdfData.clientInfo = {
            name: this.state.client.first_name + " " + this.state.client.last_name,
            address: this.state.client.address,
            dob: (birthDate.getMonth() + 1) + "/" + birthDate.getDate() + "/" + birthDate.getFullYear(),
            retirement: retirementDate.getFullYear(),
            budget: this.state.client.budget,
        }

        let risk = "";
        if (this.state.client.default_portfolio_params) {
            switch (this.state.client.default_portfolio_params.risk_level) {
                case 1:
                    risk = "Very Low"
                    break
                case 2:
                    risk = "Low"
                    break
                case 3:
                    risk = "Medium"
                    break
                case 4:
                    risk = "High"
                    break
                case 5:
                    risk = "Very High"
                    break
                default:
                    risk = "N/A"
                    break
            }
        }

        let sugTypes = []
        let sectRest = []
        let secRest = []
        let ideas = []

        if (this.state.client.default_portfolio_params) {
            sugTypes = this.state.client.default_portfolio_params.suggestion_types
            sectRest = this.state.client.default_portfolio_params.sector_restrictions
            secRest = this.state.client.default_portfolio_params.security_restrictions
            ideas = this.state.client.default_portfolio_params.ideas_to_support
        }

        pdfData.portfolioInfo = {
            suggestionTypes: sugTypes,
            assetClasses: ["US Equities"],
            risk: risk,
            sectorRestrictions: sectRest,
            securityRestrictions: secRest
        }

        let activities = []

        if (this.state.client.preferences) {
            activities = this.state.client.preferences.activities_to_avoid
        }

        pdfData.keyPreferences = {
            activitiesToAvoid: activities,
            ideasToSupport: ideas
        }

        // Ovo je situacija gdje sugestije embeddamo

        // If we did not select top 5, we want only current one to be included
        if (!top5) {
            if (this.state.suggestions.etf_suggestions) {
                if (this.state.suggestions.etf_suggestions[this.state.selectedSuggestionIndex]) {
                    let tmp = this.state.suggestions.etf_suggestions[this.state.selectedSuggestionIndex];

                    let shortSuggestion = {
                        ticker: tmp.etf.ticker,
                        name: tmp.etf.name,
                        score: tmp.score,
                        score_breakdown: tmp.score_breakdown,
                        stock_exchange: tmp.etf.stock_exchange,
                        dividend_yield: tmp.etf.dividend_yield,
                        expense_ratio: tmp.etf.expense_ratio,
                        inception_date: tmp.etf.inception_date,
                        risk_metrics: tmp.etf.risk_metrics,
                        top_holdings: tmp.etf.holdings.slice(0, 5),
                        parent: tmp.etf.parent,
                        performance_stats: this.state.currentStats
                    };

                    let inceptionDate = new Date(shortSuggestion.inception_date);
                    shortSuggestion.inception_date = (inceptionDate.getMonth() + 1) + "/" + inceptionDate.getDate() + "/" + inceptionDate.getFullYear()

                    pdfData.suggestions = [shortSuggestion];
                }
            }
        } else {
            if (this.state.suggestions.etf_suggestions) {
                let suggestions = [];
                for (let i = 0; i < 5; i++) {
                    let tmp = this.state.suggestions.etf_suggestions[i];

                    let stats = await this.getStockStats(tmp.etf.ticker)

                    let shortSuggestion = {
                        ticker: tmp.etf.ticker,
                        name: tmp.etf.name,
                        score: tmp.score,
                        score_breakdown: tmp.score_breakdown,
                        stock_exchange: tmp.etf.stock_exchange,
                        dividend_yield: tmp.etf.dividend_yield,
                        expense_ratio: tmp.etf.expense_ratio,
                        inception_date: tmp.etf.inception_date,
                        risk_metrics: tmp.etf.risk_metrics,
                        top_holdings: tmp.etf.holdings.slice(0, 5),
                        parent: tmp.etf.parent,
                        performance_stats: stats
                    };

                    let inceptionDate = new Date(shortSuggestion.inception_date);
                    shortSuggestion.inception_date = (inceptionDate.getMonth() + 1) + "/" + inceptionDate.getDate() + "/" + inceptionDate.getFullYear()

                    suggestions.push(shortSuggestion);
                }
                pdfData.suggestions = suggestions;
            }
        }

        return pdfData;
    }

    render() {
        const comingSoonTooltip = <Tooltip>Coming soon</Tooltip>;
        let currentSuggestion = {
            etf: {},
            score: 0,
            portfolio_intelligence: {}
        };
        let isScoreBreakdownVisible = false;

        if (this.state.suggestions && this.state.suggestions.etf_suggestions) {
            currentSuggestion = this.state.suggestions.etf_suggestions[this.state.selectedSuggestionIndex];
            isScoreBreakdownVisible = true;
        }

        return (
            <div>
                <span
                    className="plain-link"
                    onClick={() => this.props.history.push('/clients')}>&#60; {this.state.mode !== 'analyse'? "All clients": "Portfolio Analyser"}</span>

                <Row>
                    {
                        this.state.mode !== 'analyse' &&
                        <Col xs={20} xsOffset={2} md={18} mdOffset={3}>
                        <h1 className="page-title">Client</h1>
                        <Button
                            appearance="link"
                            className="btn --outline page-action-btn"
                            onClick={this.handleEdit}>
                            Edit
                        </Button>

                        {/* <Whisper placement="top" trigger="click" speaker={comingSoonTooltip}>
                            <Button
                                appearance="link"
                                className="btn --outline page-action-btn mr-20">
                                Contact Client
                            </Button>
                        </Whisper> */}

                        <div className="card client-info-card">
                            <Col xs={20} xsOffset={2} md={2} mdOffset={0} className="text-center">
                                <img
                                    alt="Client's profile"
                                    className="client-info-card__profile-pic"
                                    src="https://pngimage.net/wp-content/uploads/2018/05/default-user-image-png-7.png"></img>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="ml-10">
                                <p>
                                    <strong className="text-dark">{this.state.client.first_name} {this.state.client.last_name}</strong>
                                </p>
                                <p>#{this.state.client.id}</p>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p>
                                    Client since
                                </p>
                                <p className="text-dark">{new Date(this.state.client.created_on).toLocaleDateString()}</p>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p>
                                    Budget
                                </p>
                                <p className="text-dark">${this.state.client.budget}</p>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p>
                                    Recurring Deposit
                                </p>
                                <p className="text-dark">n/a</p>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p>
                                    Retirement
                                </p>
                                <p className="text-dark">In {`${- moment().diff(this.state.client.retirement_date, 'years')} years`}</p>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p>
                                    Wants to Support
                                </p>
                                <p className="text-dark">{this.state.client.default_portfolio_params
                                    ? `${this.state.client.default_portfolio_params.ideas_to_support.length} causes`
                                    : 'n/a'}</p>
                            </Col>
                            <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p>
                                    Wants to Avoid
                                </p>
                                <p className="text-dark">{this.state.client.preferences
                                    ? `${this.state.client.preferences.activities_to_avoid.length} activities`
                                    : 'n/a'}</p>
                            </Col>
                            {/* <Col xs={20} xsOffset={2} md={3} mdOffset={0} className="text-center">
                                <p className="plain-link --disabled fw-500">
                                    <Whisper placement="top" trigger="click" speaker={comingSoonTooltip}>
                                        <span>View Full Profile</span>
                                    </Whisper>
                                </p>
                            </Col> */}
                        </div>
                    </Col>
                    }

                    <Col xs={20} xsOffset={2} md={18} mdOffset={3} className="mt-50">
                        <h1 id="bestMatchingResult" className="page-title">{this.state.mode !== 'analyse' ? "ETF Tactical Exposure Suggestion": "Best Matching Constituent"}</h1>

                        <Button
                            disabled={false}
                            appearance="link"
                            onClick={this.handleShowMoreSuggestions}
                            className="btn --outline page-action-btn">
                            {this.state.mode !== 'analyse' ? "More Suggestions":"View All"}
                        </Button>

                        <Button
                            disabled={!currentSuggestion.etf.id}
                            appearance="link"
                            style={{ position: "relative" }}
                            onClick={() => { this.handleExportForAnalysis(currentSuggestion) }}
                            className="btn --outline page-action-btn mr-20">
                            <span style={{
                                position: "absolute",
                                top: -11,
                                right: -11,
                                backgroundColor: "#f84aa2",
                                borderRadius: "1000px",
                                color: "white",
                                width: "25px",
                                height: "25px"
                            }}>2</span>
                            Export
                        </Button>

                        <Col xs={20} xsOffset={2} md={24} mdOffset={0}>
                            <div className="card performance-card">
                                {(this.state.isLoadingSuggestions) && <p className="empty-state"><Icon icon="spinner" pulse />
                                    Loading suggestion for this client...</p>}

                                {(!this.state.isLoadingSuggestions && !currentSuggestion.etf.name) && <p className="empty-state">
                                    No matching result found. Try editing the parameters.</p>}

                                {(!this.state.isLoadingSuggestions && currentSuggestion.etf.name) && <div>
                                    <h4
                                        className="card-subtitle-large ml-10"
                                        style={{
                                            display: 'inline-flex',
                                            alignItems: 'center'
                                        }}>
                                        <Progress.Circle
                                            style={{
                                                width: '55px'
                                            }}
                                            className="mr-10"
                                            percent={parseInt(currentSuggestion.score * 10)}
                                            strokeColor={getColorByScore(currentSuggestion.score)} />
                                        <div>
                                            {`${currentSuggestion.etf.name} (${currentSuggestion.etf.ticker})`}

                                            <div>
                                                {/* <span
                                                    className="pink-link"
                                                    onClick={this.handleShowScoreBreakdown}
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '500',
                                                        marginLeft: '3px',
                                                        border: "1px solid",
                                                        padding: "4px"
                                                    }}>
                                                    <i className="iconsminds-puzzle"></i>
                                                    View score breakdown
                                                </span>
                                                <span
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "2px",
                                                        fontSize: "15px",
                                                        color: "#adadad"
                                                    }}>
                                                    |
                                                </span> */}
                                                <span
                                                    className="pink-link"
                                                    onClick={this.handleShowHoldings}
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '500',
                                                        marginLeft: '3px',
                                                        border: "1px solid",
                                                        padding: "4px"
                                                    }}>
                                                    <i className="iconsminds-pie-chart"></i>
                                                    View holdings
                                                </span>
                                                <span
                                                    style={{
                                                        marginLeft: "5px",
                                                        marginRight: "2px",
                                                        fontSize: "15px",
                                                        color: "#adadad"
                                                    }}>
                                                    |
                                                </span>
                                                <span
                                                    className="pink-link"
                                                    onClick={this.handleShowDetails}
                                                    style={{
                                                        fontSize: '16px',
                                                        fontWeight: '500',
                                                        marginLeft: '3px',
                                                        border: "1px solid",
                                                        padding: "4px"
                                                    }}>
                                                    <i className="iconsminds-project"></i>
                                                    View more details
                                                </span>
                                            </div>
                                        </div>

                                        {/* <div
                                            className="score-breakdown-invoker"
                                            onClick={this.handleShowScoreBreakdown}>
                                            <i className="iconsminds-pie-chart"></i>
                                            View score breakdown
                                        </div> */}
                                    </h4>

                                    <PerformanceChart suggestion={currentSuggestion.etf} />

                                    <div className="mt-10 pt-10 brdt-2-light"></div>

                                    <Col xs={20} xsOffset={2} md={4} mdOffset={0}>
                                        <div className="performance-metric brdr-2-light">
                                            <h4>Expense Ratio</h4>
                                            <h3>
                                                {(currentSuggestion.etf.expense_ratio === -1) && <span>n/a</span>}
                                                {(currentSuggestion.etf.expense_ratio !== -1) && `${currentSuggestion.etf.expense_ratio}%`}
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={20} xsOffset={2} md={4} mdOffset={0}>
                                        <div className="performance-metric brdr-2-light">
                                            <h4>Inception Date</h4>
                                            <h3>
                                                {!currentSuggestion.etf.inception_date && <span>n/a</span>}
                                                {moment(currentSuggestion.etf.inception_date).format('DD MMM YYYY')}
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={20} xsOffset={2} md={4} mdOffset={0}>
                                        <div className="performance-metric brdr-2-light">
                                            <h4>Dividend Yield</h4>
                                            <h3>
                                                {currentSuggestion.etf.dividend_yield === -10000 && <span>n/a</span>}
                                                {currentSuggestion.etf.dividend_yield !== -10000 && <span>{`${currentSuggestion.etf.dividend_yield}%`}</span>}
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={20} xsOffset={2} md={4} mdOffset={0}>
                                        <div className="performance-metric brdr-2-light">
                                            <h4>Sharpe Ratio</h4>
                                            <h3>
                                                {!currentSuggestion.etf.risk_metrics.sharpe_ratio && <span>n/a</span>}
                                                {currentSuggestion.etf.risk_metrics.sharpe_ratio && currentSuggestion.etf.risk_metrics.sharpe_ratio}
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={20} xsOffset={2} md={4} mdOffset={0}>
                                        <div className="performance-metric brdr-2-light">
                                            <h4>Sortino Ratio</h4>
                                            <h3>
                                                {!currentSuggestion.etf.risk_metrics.sortino_ratio && <span>n/a</span>}
                                                {currentSuggestion.etf.risk_metrics.sortino_ratio}
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col xs={20} xsOffset={2} md={4} mdOffset={0}>
                                        <div className="performance-metric">
                                            <h4>Beta</h4>
                                            <h3>
                                                {currentSuggestion.etf.risk_metrics.beta}
                                            </h3>
                                        </div>
                                    </Col>
                                </div>
                                }
                            </div>
                        </Col>
                    </Col>

                    {/* Portfolio Intelligence */}
                    {/* <Col xs={20} xsOffset={2} md={18} mdOffset={3} className="mt-50">
                        <h1
                            className="page-title d-block"
                            style={{
                                marginBottom: '10px',
                                lineHeight: '30px'
                            }}>Portfolio Intelligence</h1>
                        <div className="mb-20">
                            <span
                                className="pink-link --disabled fw-500"
                                style={{
                                    fontSize: '20px'
                                }}>View full portfolio intelligence report</span>
                        </div>

                        <PortfolioIntelligence data={currentSuggestion.portfolio_intelligence} />

                        <div>
                            <span
                                className="pink-link float-right fw-500 mt-25"
                                style={{
                                    fontSize: '20px'
                                }}
                                onClick={this.handleShowMoreSuggestions}>View more suggestions</span>
                        </div>
                    </Col> */}

                    {/* ETF Holdings */}
                    <Drawer
                        show={this.state.areHoldingsVisible}
                        onHide={this.handleHideHoldings}
                        size="lg">
                        <Drawer.Body>
                            {(!currentSuggestion || !currentSuggestion.etf || !currentSuggestion.etf.holdings) && <Col xs={20} xsOffset={2} md={18} mdOffset={3}>
                                <h1 className="page-title d-block">Holdings</h1>
                                <p className="empty-state">No data available at the moment.</p>
                            </Col>}

                            {(currentSuggestion && currentSuggestion.etf && currentSuggestion.etf.holdings) && <Col xs={20} xsOffset={2} md={24} mdOffset={0}>
                                <HoldingsTable holdings={currentSuggestion.etf.holdings} />
                            </Col>}
                        </Drawer.Body>

                        <Drawer.Footer>
                            <Button
                                onClick={this.handleHideHoldings}
                                appearance="subtle"
                                style={{
                                    marginTop: '-20px'
                                }}>Close</Button>
                        </Drawer.Footer>
                    </Drawer>

                    {/* Other matches / suggestions */}
                    <Drawer
                        show={this.state.areMoreSuggestionsVisible}
                        onHide={this.handleHideMoreSuggestions}>
                        <Drawer.Header>
                            <Drawer.Title>{this.state.mode !== 'analyse' ? "Top 20 best matching suggestions" : "Scored Constituents"}</Drawer.Title>
                        </Drawer.Header>
                        <Drawer.Body>
                            {(this.state.suggestions.etf_suggestions && this.state.suggestions.etf_suggestions.length === 0) && <Col xs={20} xsOffset={2} md={18} mdOffset={3}>
                                <h1 className="page-title d-block">Other Matches</h1>
                                <p className="empty-state">There are no other matches for this client at the moment.</p>
                            </Col>}

                            {(this.state.suggestions.etf_suggestions && this.state.suggestions.etf_suggestions.length > 0) && <Col xs={20} xsOffset={2} md={24} mdOffset={0}>
                                {this
                                    .state
                                    .suggestions
                                    .etf_suggestions
                                    .map((s, k) => (
                                        <Col
                                            key={k}
                                            xs={20}
                                            xsOffset={2}
                                            md={24}
                                            mdOffset={0}
                                            className="mt-10"
                                            onClick={() => this.handleSelectMatch(k)}>
                                            <MatchSuggestion data={s} />
                                        </Col>
                                    ))}
                            </Col>}
                        </Drawer.Body>
                    </Drawer>
                </Row>

                <Row>
                    <Col xs={20} xsOffset={2} md={18} mdOffset={3} className="mt-50">
                        <h1
                            className="page-title d-block"
                            style={{
                                marginBottom: '10px',
                                lineHeight: '30px'
                            }}>{this.state.mode !== 'analyse' ? "How we scored this suggestion" : "How aligned is this ETF to client's preferences?"}</h1>
                        {isScoreBreakdownVisible &&
                            <div className="card performance-card" style={{ marginTop: "20px" }}>
                                <ScoreBreakdown
                                    overallScore={currentSuggestion.score}
                                    scoreBreakdown={currentSuggestion.score_breakdown}
                                    riskMetrics={currentSuggestion.etf.risk_metrics} />
                            </div>
                        }
                    </Col>
                </Row>

                {this.state.isAnalysisVisible && <Modal
                    show={true}
                    onHide={this.handleHideExportForAnalysis}
                    size="xs"
                    className="score-breakdown"
                    style={{ textAlign: "center" }}>
                    <Modal.Header>
                        <Modal.Title
                            style={{
                                textAlign: 'center',
                                fontSize: '20px'
                            }}>
                            <p>Export <span style={{ color: "#6565DA" }}>{currentSuggestion.etf.ticker}</span></p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            !this.state.generatingPDF ?
                                <Button
                                    appearance="link"
                                    className="btn --outline mb-20"
                                    style={{ width: "80%", position: "relative", marginTop: "20px" }}
                                    onClick={() => this.handleGeneratePDFDocument()}>
                                    <span style={{
                                        position: "absolute",
                                        top: -11,
                                        right: -11,
                                        backgroundColor: "#f84aa2",
                                        borderRadius: "1000px",
                                        color: "white",
                                        width: "25px",
                                        height: "25px",
                                        paddingTop: "2px"
                                    }}>1</span>
                                    Download PDF (This suggestion)
                            </Button>
                                :
                                <Button
                                    style={{ width: "80%" }}
                                    appearance="link"
                                    className="btn --outline mb-20"
                                    disabled={true}>
                                    Please Wait, Generating PDF
                            </Button>
                        }
                        {
                            !this.state.generatingPDF ?
                                <Button
                                    appearance="link"
                                    className="btn --outline mb-20"
                                    style={{ width: "80%", position: "relative" }}
                                    onClick={() => this.handleGeneratePDFDocument(true)}>
                                    <span style={{
                                        position: "absolute",
                                        top: -11,
                                        right: -11,
                                        backgroundColor: "#f84aa2",
                                        borderRadius: "1000px",
                                        color: "white",
                                        width: "25px",
                                        height: "25px",
                                        paddingTop: "2px"
                                    }}>1</span>
                                    Download PDF (Top 5 Suggestions)
                            </Button>
                                :
                                <Button
                                    appearance="link"
                                    style={{ width: "80%" }}
                                    className="btn --outline mb-20"
                                    disabled={true}>
                                    Please Wait, Generating PDF
                            </Button>
                        }
                        <Button
                            appearance="link"
                            className="btn --outline mb-20"
                            style={{ width: "80%" }}
                            onClick={() => this.handleOpenWithMorningstar(currentSuggestion)}>
                            Open with Morningstar
                        </Button>
                        {/* <PDFDownload data={pdfData} /> */}
                        {/* <Button
                            appearance="link"
                            className="btn --outline mb-20"
                            style={{ width: "80%" }}
                            disabled={true}>
                            Open in Bloomberg Terminal
                        </Button>
                        <Button
                            appearance="link"
                            className="btn --outline mb-20"
                            style={{ width: "80%" }}
                            disabled={true}>
                            Export to CSV
                        </Button> */}
                        {/* <ETFDetails data={currentSuggestion.etf} /> */}
                    </Modal.Body>
                </Modal>}

                {this.state.areDetailsVisible && <Modal
                    show={true}
                    onHide={this.handleHideDetails}
                    size="lg"
                    className="score-breakdown">
                    <Modal.Header>
                        <Modal.Title
                            style={{
                                textAlign: 'center',
                                fontSize: '20px'
                            }}>{`${currentSuggestion.etf.name} Details`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ETFDetails data={currentSuggestion.etf} />
                    </Modal.Body>
                </Modal>}
            </div>
        );
    }
}

export default withRouter(Detail);